import { faBan, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import {
  EmptyStateContainer,
  LookalikeMediaInsights,
} from "features/mediaDataRoomV2";
import {
  useLookalikeMediaDataRoomData,
  useLookalikeMediaOverlapInsightsContext,
} from "features/mediaDataRoomV2/contexts";

const EmptyStateDisplay: React.FC<{ label: string }> = ({ label }) => {
  return (
    <EmptyStateContainer>
      <Typography
        sx={{ alignItems: "center", display: "inline-flex" }}
        variant="subtitle2"
      >
        <CircularProgress
          color="inherit"
          size={14}
          sx={{ marginRight: 1 }}
          thickness={3}
        />
        {label}
      </Typography>
    </EmptyStateContainer>
  );
};

const MediaDataRoomInsights = () => {
  const { isDeactivated, isPublisher } = usePublishedLookalikeMediaDataRoom();
  const {
    datasetsLoading,
    error: dataLoadingError,
    hasRequiredData,
    isPublisherAudienceBeingUpdated,
  } = useLookalikeMediaDataRoomData();
  const {
    overlapInsightsStatistics: overlapStatistics,
    overlapInsightsIsLoading: isInsightsLoading,
    overlapInsightsDataStatus: insightsLoadingStatus,
    overlapInsightsError: insightsLoadingError,
    retry,
  } = useLookalikeMediaOverlapInsightsContext();
  const isEmptyState = useMemo(
    () => overlapStatistics.every(({ overlapSize }) => overlapSize === 0),
    [overlapStatistics]
  );
  const [retryInProgress, setRetryInProgress] = useState(false);

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [isDeactivated, retry]);

  if (isDeactivated || insightsLoadingError || dataLoadingError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Insights could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 4 }}
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }
  if (datasetsLoading) {
    return <EmptyStateDisplay label="Connecting to the enclave" />;
  }

  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          Publisher audience is being updated.
        </Typography>
        <Typography variant="subtitle2">
          {isPublisher
            ? "Please provision a Data Lab again."
            : "Activation will be available again soon."}{" "}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (!hasRequiredData) {
    return (
      <Alert severity="info">Waiting for both parties to provision data.</Alert>
    );
  }

  if (isInsightsLoading) {
    return (
      <EmptyStateDisplay
        label={
          insightsLoadingStatus === "COMPUTING"
            ? "Computing lookalike models"
            : insightsLoadingStatus === "FETCHING"
              ? "Retrieving insights..."
              : ""
        }
      />
    );
  }

  if (isEmptyState) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          There are not enough individuals in the overlap.
        </Typography>
        <Typography variant="subtitle2">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }
  return <LookalikeMediaInsights />;
};

MediaDataRoomInsights.displayName = "MediaDataRoomInsights";

export default MediaDataRoomInsights;
