import { testIds } from "@decentriq/utils";
import { Grid } from "@mui/material";
import { memo } from "react";
import {
  ConfigurationFormDomainField,
  ConfigurationFormFeaturesField,
  ConfigurationFormMatchingIdField,
  ConfigurationFormNameField,
  ConfigurationFormParticipantsField,
} from "features/mediaPortalShared";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import {
  type ActivationConfigurationFormValues,
  publisherPortalCollaborationTypes,
} from "./model";

const ActivationConfigurationForm: React.FC = () => {
  const { organizationUsers } = usePublisherPortal();
  return (
    <Grid columnSpacing={3} container={true} rowSpacing={2}>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormNameField<ActivationConfigurationFormValues>
          fieldName="name"
          placeholder="Enter configuration name"
          testId={
            testIds.publisherPortal.createActivationConfigurationDialog.name
          }
          title="Name"
          tooltip="User defined name for the config. Not publicly visible, only within your organization."
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormParticipantsField<ActivationConfigurationFormValues>
          fieldName="publisherParticipants"
          optionTestId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .participantsSelectorOptionHelper
          }
          organizationUsers={organizationUsers}
          testId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .participantsSelector
          }
          title="Publisher participants"
          tooltip="These are the users from your organization that will be invited to newly created clean rooms using this config. Any account that wishes to upload data, download data, or view insights must be included here."
        />
      </Grid>
      <Grid container={true} item={true} xs={6}>
        <ConfigurationFormFeaturesField<ActivationConfigurationFormValues>
          featuresList={publisherPortalCollaborationTypes}
          fieldName="dataRoomTypes"
          optionTestId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .featuresSelectorOptionHelper
          }
          testId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .featuresSelector
          }
          tooltip="These are the DCR collaboration types that are available in this config. Other collaboration types will be greyed out in the publisher discovery menu, and unavailable in the clean room creation dialog."
        />
        <ConfigurationFormMatchingIdField<ActivationConfigurationFormValues>
          fieldName="matchingId"
          optionTestId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .matchingIdSelectorOptionHelper
          }
          testId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .matchingIdSelector
          }
          tooltip="The type of matching key. The advertiser will be able to match against this key, and you must provision data labs using this key to any clean rooms they create with this config. Types with a specific format (e.g. email, hashed email) have some format validation."
        />
      </Grid>
      <Grid alignItems="start" container={true} item={true} xs={6}>
        <ConfigurationFormDomainField<ActivationConfigurationFormValues>
          checkboxFieldName="matchAnyDomain"
          checkboxTestId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .matchAnyDomain
          }
          textFieldName="advertiserDomains"
          textFieldTestId={
            testIds.publisherPortal.createActivationConfigurationDialog
              .advertiserDomain
          }
        />
      </Grid>
    </Grid>
  );
};

ActivationConfigurationForm.displayName = "ActivationConfigurationForm";

export default memo(ActivationConfigurationForm);
