import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftSyntheticNodeAccuracyQuery,
  usePublishedSyntheticNodeAccuracyQuery,
} from "hooks/__generated-new";
import {
  type DraftSyntheticNode,
  type PublishedSyntheticNode,
} from "types/__generated-new";

const useSyntheticNodeAccuracy = (
  computeNodeId: string
): { accuracy: number | undefined } => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftSyntheticNodeAccuracyQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The privacy filter settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedSyntheticNodeAccuracyQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The privacy filter settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  const nodeAccuracy = shouldUseDraft
    ? (draftData?.draftNode as DraftSyntheticNode | undefined)?.accuracy
    : (publishedData?.publishedNode as PublishedSyntheticNode | undefined)
        ?.accuracy;
  const effectiveAccuracy = nodeAccuracy === 0.5 ? 0 : nodeAccuracy;
  return {
    accuracy: effectiveAccuracy || 0,
  };
};

export default useSyntheticNodeAccuracy;
