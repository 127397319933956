import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { memo } from "react";
import TimeAgo from "react-timeago";
import { useTimeFormatter } from "hooks";
import { useSubmittedRequestCreationDateQuery } from "hooks/__generated-new";

interface SubmittedRequestSubmittionStatusProps {
  requestId: string;
}

const SubmittedRequestSubmittionStatus: React.FC<
  SubmittedRequestSubmittionStatusProps
> = ({ requestId }) => {
  const { user } = useAuth0();
  const { data } = useSubmittedRequestCreationDateQuery({
    fetchPolicy: "cache-only",
    variables: {
      id: requestId,
    },
  });
  const createdAt = data?.submittedDataRoomRequest?.createdAt;
  const timeFormatter = useTimeFormatter();
  return (
    <Typography style={{ marginTop: "3px" }} variant="caption">
      {createdAt ? (
        <>
          {" "}
          Submitted by{" "}
          <strong>
            {data?.submittedDataRoomRequest?.owner.email}
            {data?.submittedDataRoomRequest?.owner.email === user?.email
              ? " (you)"
              : ""}
          </strong>{" "}
          <TimeAgo
            date={createdAt}
            formatter={timeFormatter}
            style={{ marginRight: "16px" }}
          />
        </>
      ) : (
        ""
      )}
    </Typography>
  );
};

SubmittedRequestSubmittionStatus.displayName =
  "SubmittedRequestSubmittionStatus";

export default memo(SubmittedRequestSubmittionStatus);
