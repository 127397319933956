import { useAuth0 } from "@auth0/auth0-react";
import { memo, useMemo } from "react";
import {
  type PublishedMediaDataRoomContextValue,
  PublishedMediaDataRoomProvider,
} from "contexts";
import { isNormalizedEmailIncluded } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useMediaDataRoomOrganizationsQuery,
  useMediaDataRoomQuery,
} from "hooks/__generated-new";

interface PublishedMediaDataRoomWrapperProps {
  dataRoomId: string;
  driverAttestationHash: string;
  isStopped: boolean;
  children: React.ReactNode;
  isDeactivated: boolean;
}

const PublishedMediaDataRoomWrapper: React.FC<
  PublishedMediaDataRoomWrapperProps
> = ({
  dataRoomId,
  driverAttestationHash,
  children,
  isStopped,
  isDeactivated,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};
  const { data: mediaDataRoomData, loading: isMediaDataRoomLoading } =
    useMediaDataRoomQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Media data clean room could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      variables: {
        input: {
          dataRoomId,
          driverAttestationHash,
        },
      },
    });
  const {
    mainAdvertiserEmail,
    mainPublisherEmail,
    publisherEmails: publisherUserEmails = [],
    advertiserEmails: advertiserUserEmails = [],
    agencyEmails: agencyUserEmails = [],
    observerEmails: observerUserEmails = [],
  } = mediaDataRoomData?.mediaRetrieveDataRoom?.highLevel || {};

  // Current user role
  const { isPublisher, isAdvertiser, isObserver, isAgency } = useMemo(
    () => ({
      isAdvertiser: isNormalizedEmailIncluded(
        advertiserUserEmails,
        currentUserEmail
      ),
      isAgency: isNormalizedEmailIncluded(agencyUserEmails, currentUserEmail),
      isObserver: isNormalizedEmailIncluded(
        observerUserEmails,
        currentUserEmail
      ),
      isPublisher: isNormalizedEmailIncluded(
        publisherUserEmails,
        currentUserEmail
      ),
    }),
    [
      advertiserUserEmails,
      agencyUserEmails,
      currentUserEmail,
      observerUserEmails,
      publisherUserEmails,
    ]
  );

  const { data, loading: isMediaDataRoomOrganizationsLoading } =
    useMediaDataRoomOrganizationsQuery({
      skip: !mainAdvertiserEmail || !mainPublisherEmail,
      variables: {
        advertiserEmail: mainAdvertiserEmail,
        publisherEmail: mainPublisherEmail,
      },
    });

  const publishedMediaDataRoomContextValue =
    useMemo<PublishedMediaDataRoomContextValue>(
      () => ({
        advertiserOrganization: {
          ...(data?.advertiserOrganization?.organization || {}),
          logo: data?.advertiserOrganization?.organizationLogo,
        },
        dataRoomId,
        dcrHash: dataRoomId,
        driverAttestationHash,
        isAdvertiser,
        isAgency,
        isDeactivated,
        isLoading:
          isMediaDataRoomOrganizationsLoading || isMediaDataRoomLoading,
        isObserver,
        isPublisher,
        isStopped: isStopped,
        publisherOrganization: {
          ...(data?.publisherOrganization?.organization || {}),
          logo: data?.publisherOrganization?.organizationLogo,
        },
      }),
      [
        data?.advertiserOrganization?.organization,
        data?.advertiserOrganization?.organizationLogo,
        data?.publisherOrganization?.organization,
        data?.publisherOrganization?.organizationLogo,
        dataRoomId,
        driverAttestationHash,
        isAdvertiser,
        isAgency,
        isDeactivated,
        isObserver,
        isPublisher,
        isStopped,
        isMediaDataRoomOrganizationsLoading,
        isMediaDataRoomLoading,
      ]
    );
  return (
    <PublishedMediaDataRoomProvider value={publishedMediaDataRoomContextValue}>
      {children}
    </PublishedMediaDataRoomProvider>
  );
};

export default memo(PublishedMediaDataRoomWrapper);
