import { faFileImport as faFileImportRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import {
  ImportExternalDataDialog,
  ImportExternalDataDialogWrapper,
} from "features/datasets/components/ImportExternalData";

const ImportExternalDataButton: React.FC = () => {
  const [
    isImportExternalDataDialogVisible,
    {
      setTrue: openImportExternalDataDialog,
      setFalse: closeImportExternalDataDialog,
    },
  ] = useBoolean(false);

  return (
    <>
      <Button
        color="primary"
        onClick={openImportExternalDataDialog}
        startIcon={
          <FontAwesomeIcon fixedWidth={true} icon={faFileImportRegular} />
        }
        variant="contained"
      >
        Import dataset
      </Button>
      <Portal>
        <ImportExternalDataDialogWrapper>
          <ImportExternalDataDialog
            onCancel={closeImportExternalDataDialog}
            open={isImportExternalDataDialogVisible}
          />
        </ImportExternalDataDialogWrapper>
      </Portal>
    </>
  );
};

ImportExternalDataButton.displayName = "ImportExternalDataButton";

export default memo(ImportExternalDataButton);
