import { createContext, useContext } from "react";
import { type Organization } from "types/__generated-new";

export interface PublishedMediaDataRoomContextValue {
  dataRoomId: string;
  dcrHash: string;
  driverAttestationHash: string;
  publisherOrganization?: Partial<Organization>;
  advertiserOrganization?: Partial<Organization>;
  isStopped: boolean;
  isDeactivated: boolean;
  isPublisher: boolean;
  isAdvertiser: boolean;
  isAgency: boolean;
  isObserver: boolean;
}

export const PublishedMediaDataRoomContext =
  createContext<PublishedMediaDataRoomContextValue>({
    advertiserOrganization: {},
    dataRoomId: "__default__",
    dcrHash: "__default__",
    driverAttestationHash: "__default__",
    isAdvertiser: false,
    isAgency: false,
    isDeactivated: false,
    isObserver: false,
    isPublisher: false,
    isStopped: false,
    publisherOrganization: {},
  });

export const PublishedMediaDataRoomProvider =
  PublishedMediaDataRoomContext.Provider;

export const usePublishedMediaDataRoom = () =>
  useContext(PublishedMediaDataRoomContext);
