import { useCallback } from "react";
import { usePublishedDataRoom } from "contexts";
import useDependsOnPreviewNode from "features/computeNode/components/ComputeNodeToolbar/useDependsOnPreviewNode";
import useTestDatasets from "features/dataNodes/hooks/useTestDatasets";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDevComputeNodeRunMutation } from "hooks/__generated-new";
import { type ComputeNodeTypeNames } from "models";
import { type TestDataset } from "types/__generated-new";
import { useCheckScriptingNodeInputs } from "..";

interface DevComputeNodeRunHookPayload {
  playgroundId: string;
  computeNodeId: string;
  computationType: ComputeNodeTypeNames | undefined;
}

const useDevComputeNodeRun = ({
  playgroundId,
  computeNodeId,
  computationType,
}: DevComputeNodeRunHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const checkScriptingNodeInputs = useCheckScriptingNodeInputs(
    computeNodeId,
    computationType
  );
  const {
    dataRoomId: dcrHash,
    driverAttestationHash,
    testing,
  } = usePublishedDataRoom();
  const [devComputeNodeRunMutation, { loading }] = useDevComputeNodeRunMutation(
    {
      onError: (error) => {
        enqueueSnackbar(
          ...mapDataRoomErrorToSnackbar(
            error,
            `The computation could not be run.`
          )
        );
      },
    }
  );
  const { dependsOnPreviewNode } = useDependsOnPreviewNode({ computeNodeId });

  const fetchTestDatasets = useTestDatasets();
  const runDevComputeNode = useCallback(async () => {
    checkScriptingNodeInputs();
    const testDatasets: TestDataset[] | undefined = testing
      ? await fetchTestDatasets()
      : undefined;
    return devComputeNodeRunMutation({
      variables: {
        input: {
          // Results should not be autofetched if the node depends on a preview node
          autoFetching: !dependsOnPreviewNode,
          dcrHash,
          driverAttestationHash,
          id: playgroundId,
          testDatasets,
        },
      },
    });
  }, [
    checkScriptingNodeInputs,
    testing,
    fetchTestDatasets,
    devComputeNodeRunMutation,
    dependsOnPreviewNode,
    dcrHash,
    driverAttestationHash,
    playgroundId,
  ]);
  return {
    loading,
    runDevComputeNode,
  };
};

export default useDevComputeNodeRun;
