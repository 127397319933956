import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftDataRoomDescriptionQuery,
  usePublishedDataRoomDescriptionQuery,
} from "hooks/__generated-new";

const useDataRoomDescription = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isPublished } = useDataRoom();
  // Fetch draft data room name
  const { data: draftData } = useDraftDataRoomDescriptionQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Data clean room description could not be retrieved."
        )
      );
    },
    skip: isPublished,
    variables: { dataRoomId },
  });
  // Fetch published data room name
  const { data: publishedData } = usePublishedDataRoomDescriptionQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Data clean room description could not be retrieved."
        )
      );
    },
    skip: !isPublished,
    variables: { dataRoomId },
  });
  const dataRoomDescription = isPublished
    ? publishedData?.publishedDataRoom?.description
    : draftData?.draftDataRoom?.description;
  return { description: dataRoomDescription };
};

export default useDataRoomDescription;
