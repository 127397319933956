import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { CustomBrandingLogo } from "components";
import { usePublishedDataRoomLogoQuery } from "hooks/__generated-new/index";

interface DataRoomBrandingLogoProps {
  id: string;
}

const DataRoomBrandingLogo: React.FC<DataRoomBrandingLogoProps> = ({ id }) => {
  const { user } = useAuth0();
  const ownerEmail = user?.email;
  const getDomain = useCallback(
    () => ownerEmail?.slice(ownerEmail?.indexOf("@") + 1).toLowerCase() || "",
    [ownerEmail]
  );
  const { data: logoData } = usePublishedDataRoomLogoQuery({
    variables: { dataRoomId: id },
  });
  const showLogo = logoData?.publishedDataRoom?.showOrganizationLogo;
  return showLogo ? (
    <div
      style={{
        display: "flex",
        height: "auto",
        marginLeft: 4,
        maxHeight: "3.5rem",
        transform: "scale(0.9)",
      }}
    >
      <CustomBrandingLogo
        domain={getDomain()}
        fallbackToDecentriq={false}
        logoBase64={logoData?.publishedDataRoom?.logo}
        withPoweredBy={false}
      />
    </div>
  ) : (
    <></>
  );
};

export default DataRoomBrandingLogo;
