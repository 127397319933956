import { faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, type SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { type ActivationType, type Audience } from "../../../../models";
import { useStoreAsDataset } from "./useStoreAsDataset";

type SaveAsDatasetButtonProps = {
  activationType: ActivationType;
  audienceType: string;
  reach: number;
  activatedAudiences: Audience[];
  closeDownloadButtonPopover: () => void;
  setDownloadAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const SaveAsDatasetButton: React.FC<SaveAsDatasetButtonProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  closeDownloadButtonPopover,
  setDownloadAudienceLoading,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, saveAsDataSet } = useStoreAsDataset({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });

  const savePublisherAudienceAsDataset = useCallback(() => {
    setDownloadAudienceLoading(true);
    closeDownloadButtonPopover();
    saveAsDataSet().then(() => {
      setDownloadAudienceLoading(false);
      enqueueSnackbar({
        action: (
          <Button onClick={() => navigate("/datasets")}>Go to Datasets</Button>
        ),
        message: `${audienceType} result is being stored. Please check the status in the 'Datasets' page.`,
      });
    });
  }, [
    audienceType,
    closeDownloadButtonPopover,
    enqueueSnackbar,
    navigate,
    saveAsDataSet,
    setDownloadAudienceLoading,
  ]);

  return (
    <LoadingButton
      color="inherit"
      loading={loading}
      loadingPosition="start"
      onClick={savePublisherAudienceAsDataset}
      startIcon={<FontAwesomeIcon icon={faFileImport} />}
      sx={({ spacing }) => ({
        fontSize: "16px",
        fontWeight: 400,
        padding: spacing(0.5, 2),
      })}
      variant="text"
    >
      Store as dataset
    </LoadingButton>
  );
};

SaveAsDatasetButton.displayName = "SaveAsDatasetButton";

export default memo(SaveAsDatasetButton);
