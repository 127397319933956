import { FormControlLabel, styled } from "@mui/material";
import { memo } from "react";

const SelectableControlLabel = memo(
  styled(FormControlLabel)<{ selected: boolean }>(({ selected }) => ({
    backgroundColor: selected ? "rgba(40, 144, 144, 0.08)" : "transparent",
    borderColor: selected ? "primary.main" : "rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    borderStyle: "solid",
    borderWidth: "1px",
    color: selected ? "primary.main" : "inherit",
    marginLeft: "-4px",
    marginRight: 0,
    padding: "8px 12px",
  }))
);

export default SelectableControlLabel;
