import { type DataRoomTableColumnDefinition } from "models";
import { ColumnDataType } from "types/__generated-new";
import {
  type DatasetColumnStatistics,
  type DatasetDefinitionData,
} from "../models";

export const calculateDatasetGeneralStatistics = (
  data: DatasetDefinitionData[][]
): {
  numberOfDuplicateRows: number;
  numberOfNotNullableRows: number;
} => {
  let numberOfNotNullableRows = 0;
  const uniqueRows = new Set<string>();
  data.forEach((row) => {
    if (!row.includes("")) {
      numberOfNotNullableRows++;
    }
    uniqueRows.add(row.join());
  });
  let numberOfDuplicateRows = data.length - uniqueRows.size;
  return {
    numberOfDuplicateRows,
    numberOfNotNullableRows,
  };
};

export const truncateStatisticValue = (
  value: number
): { withTooltip: boolean; value: number; fullValue: number } => {
  const truncateLimit = 3;
  if (Number.isInteger(value) || (value < 0.01 && value > -0.01)) {
    return {
      fullValue: value,
      value,
      withTooltip: false,
    };
  }
  const parts = value.toString().split(".");
  const base = parts[0];
  const decimals = parts[1];
  const truncatedDecimals =
    decimals.length > truncateLimit
      ? decimals.substring(0, truncateLimit)
      : decimals;
  return {
    fullValue: value,
    value: parseFloat(`${base}.${truncatedDecimals}`),
    withTooltip: decimals.length > truncatedDecimals.length,
  };
};

export const calculateDatasetStatisticsByColumn = (
  tableColumns: DataRoomTableColumnDefinition[],
  data: DatasetDefinitionData[][]
): {
  statistics: DatasetColumnStatistics[];
  fillRatio: number;
} => {
  const statistics: DatasetColumnStatistics[] = [];
  let nullCells = 0;

  tableColumns.forEach((columnDefinition, columnIndex) => {
    const column = data.map((row) => {
      const value = row[columnIndex] as string;
      if (columnDefinition.primitiveType === ColumnDataType.Int) {
        const parsedValue = parseInt(value);
        return isNaN(parsedValue) ? null : parsedValue;
      }
      if (columnDefinition.primitiveType === ColumnDataType.Float) {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? null : parsedValue;
      }
      if (
        columnDefinition.primitiveType === ColumnDataType.Text &&
        columnDefinition.nullable &&
        value === ""
      ) {
        return null;
      }
      return value;
    });

    const emptyValues = column.filter((v) => v === null)?.length || 0;
    nullCells += emptyValues;

    statistics.push({
      column: columnDefinition.name,
      nullable: columnDefinition.nullable,
      type: columnDefinition.primitiveType!,
    });
  });

  const fillRatio =
    (data.length * tableColumns.length - nullCells) /
    (data.length * tableColumns.length);
  return { fillRatio, statistics };
};
