import { type ApiCoreContextInterface } from "contexts";
import {
  type MutationRetrieveComputeJobResultArgs,
  type RetrieveComputeJobResultPayload,
} from "types/__generated-new";
import { parseDataRoomComputationError } from "utils";
import { fetchJobResult } from "wrappers/ApolloWrapper/helpers/fetchJobResult";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveComputeJobResultResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrieveComputeJobResultArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RetrieveComputeJobResultPayload> => {
    try {
      const computeNodeResult = await fetchJobResult(
        args,
        context,
        sessionManager
      );
      const resultReference = store.push(computeNodeResult);
      return {
        result: resultReference,
      };
    } catch (error) {
      throw parseDataRoomComputationError(error);
    }
  };
