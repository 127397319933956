import { createContext, type SetStateAction, useContext } from "react";
import { EXTERNAL_DATA_IMPORT_STEPS } from "features/datasets/components/ImportExternalData";
import { DataSourceType } from "types/__generated-new";

export const ImportExternalDataDialogContext = createContext<{
  importStep: EXTERNAL_DATA_IMPORT_STEPS;
  selectedDataSource: DataSourceType;
  setImportStep: (
    importStep: SetStateAction<EXTERNAL_DATA_IMPORT_STEPS>
  ) => void;
  setSelectedDataSource: (
    selectedDataSource: SetStateAction<DataSourceType>
  ) => void;
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status) can be fetched via datasetImport query
  datasetImportId: string | null;
  setDatasetImportId: (datasetImportId: SetStateAction<string | null>) => void;
  setIsImportCreated: (isImportCreated: SetStateAction<boolean>) => void;
  isImportCreated: boolean;
  setDatasetImportError: (error: string) => void;
  datasetImportError: string | null;
}>({
  datasetImportError: "",
  datasetImportId: null,

  importStep: EXTERNAL_DATA_IMPORT_STEPS?.SELECT_SOURCE,
  // Returns true if the first step of external import is completed (creating import itself)
  isImportCreated: false,
  selectedDataSource: DataSourceType.S3,
  setDatasetImportError: () => {},
  setDatasetImportId: () => {},
  setImportStep: () => {},
  setIsImportCreated: () => {},
  setSelectedDataSource: () => {},
});

export const ImportExternalDataDialogProvider =
  ImportExternalDataDialogContext.Provider;

export const useImportExternalDataDialog = () =>
  useContext(ImportExternalDataDialogContext);
