import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import { DraftComputeNodeTypeNames } from "models";
import {
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  ComputeJobStatus,
  DevComputeNodeJobDocument,
  type DraftComputationNodeValidaitonPayload,
  DraftComputeNodeTypenameDocument,
  type DraftComputeNodeTypenameQuery,
  type MutationTestAllComputationsArgs,
} from "types/__generated-new";
import { buildAndPublishDraftDataRoom } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeTestAllComputationsResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationTestAllComputationsArgs,
    context: LocalResolverContext,
    _info: any
  ) => {
    const sdkSession = await sessionManager.get();
    const getNodeById = (
      computeNodeId: string
    ): DraftComputeNodeTypenameQuery | null => {
      return context.cache.readQuery({
        query: DraftComputeNodeTypenameDocument,
        variables: {
          computeNodeId,
        },
      });
    };
    try {
      const id = await buildAndPublishDraftDataRoom(
        args.input.id,
        context.client,
        client,
        sdkSession,
        true
      );
      const dataScienceDataRoom =
        await sdkSession.retrieveDataScienceDataRoom(id);
      const wrapper = data_science.createDataScienceDataRoomWrapper(
        id,
        dataScienceDataRoom!,
        sdkSession
      );
      const testDatasetsInput = args.input.testDatasets;
      const testing = !!testDatasetsInput;
      const testDatasets = new Map<string, TestDataset>();
      if (testing) {
        for (const dataset of testDatasetsInput) {
          testDatasets.set(dataset.leafNodeId, {
            key: store.pop(dataset.encryptionKey)!,
            manifestHash: dataset.manifestHash,
          });
        }
      }
      const { jobId, computeNodeIds } = await wrapper.testAllComputations(
        testing ? testDatasets : undefined
      );
      let openedNodesCounter = 0;
      const results: DraftComputationNodeValidaitonPayload[] = [];
      Object.keys(computeNodeIds).forEach((computeNodeId) => {
        const node = getNodeById(computeNodeId);
        if (
          node?.draftNode.__typename !==
            DraftComputeNodeTypeNames.DraftPreviewNode &&
          (![
            DraftComputeNodeTypeNames.DraftScriptingNode,
            DraftComputeNodeTypeNames.DraftSyntheticNode,
          ].includes(node?.draftNode.__typename) ||
            testing)
        ) {
          context.client.writeQuery({
            data: {
              draftNode: {
                __typename: node?.draftNode?.__typename,
                id: node?.draftNode?.id!,
                job: {
                  __typename: "ComputeJob",
                  autoFetching:
                    openedNodesCounter < 10
                      ? testing
                        ? ComputeJobAutoFetching.AllTests
                        : ComputeJobAutoFetching.AllValidations
                      : ComputeJobAutoFetching.None,
                  createdAt: new Date().toISOString(),
                  dataRoomHash: id,
                  driverAttestationHash:
                    sdkSession.metaData.driverAttestationSpecificationHash,
                  enclaveComputeJobHandleBase64:
                    forge.util.binary.base64.encode(
                      new TextEncoder().encode(
                        JSON.stringify({ computeNodeIds, jobId })
                      )
                    ),
                  enclaveComputeJobId: jobId,
                  id: node?.draftNode.id!,
                  purpose: testing
                    ? ComputeJobPurpose.Test
                    : ComputeJobPurpose.Standard,
                  status: ComputeJobStatus.Succeded,
                },
              },
            },
            query: DevComputeNodeJobDocument,
          });
          openedNodesCounter++;
          results.push({ computeNodeId });
        }
      });
      return { results };
    } catch (err: any) {
      const node = getNodeById(err?.extraInfo?.nodeId);
      const errorMessage =
        err?.extraInfo?.nodeName || node?.draftNode?.name
          ? `${err?.extraInfo?.nodeName || node?.draftNode?.name}: ${
              err.message
            }`
          : err.message;
      throw new Error(errorMessage);
    }
  };
