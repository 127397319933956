import get from "lodash/get";
import set from "lodash/set";
import { useState } from "react";
import { useApiCore } from "contexts";
import {
  useCompleteDraftDataRoomLazyQuery,
  useDraftDataRoomDuplicateMutation,
} from "hooks/__generated-new";
import {
  buildDataScienceDataRoom,
  exportDataScienceDataRoom,
  getDcrProperties,
  getLatestEnclaveSpecsPerType,
} from "utils/apicore";

interface useDraftDataRoomDuplicateArgs {
  id: string;
}

interface useDraftDataRoomDuplicateResult {
  draftDataRoomDuplicate: () => Promise<string | undefined>;
  draftDataRoomDuplicateLoading: boolean;
}

const useDraftDataRoomDuplicate = ({
  id: dataRoomId,
}: useDraftDataRoomDuplicateArgs): useDraftDataRoomDuplicateResult => {
  const { client, sessionManager } = useApiCore();
  const [getDraftDataRoomAsJson, { loading }] =
    useCompleteDraftDataRoomLazyQuery({ variables: { id: dataRoomId } });
  const [exporting, setExporting] = useState(false);
  const [draftDataRoomDuplicateMutation, { loading: importing }] =
    useDraftDataRoomDuplicateMutation();
  const draftDataRoomDuplicate = () =>
    getDraftDataRoomAsJson().then(async (result) => {
      setExporting(true);
      try {
        const { data } = result || {};
        const { draftDataRoom } = data || {};
        if (draftDataRoom) {
          const rootCertificatePem = await client.decentriqCaRootCertificate();
          const enclaveSpecifications = await client.getEnclaveSpecifications();
          const latestEnclaveSpecifications = getLatestEnclaveSpecsPerType(
            enclaveSpecifications
          );
          const dataScienceDataRoom = buildDataScienceDataRoom(
            draftDataRoom,
            rootCertificatePem,
            latestEnclaveSpecifications,
            true
          );
          const sdkSession = await sessionManager.get();
          const exportedDataScienceDataRoom = exportDataScienceDataRoom(
            sdkSession.compiler,
            dataScienceDataRoom
          );
          // Duplicated DCR must have "Copy of ..." prefix in the name
          const { version: dataRoomVersion } =
            getDcrProperties(dataScienceDataRoom);

          const staticRoomTitle = get(
            exportedDataScienceDataRoom,
            `dataScienceDataRoom.${dataRoomVersion}.static.title`
          );
          const interactiveRoomTitle = get(
            exportedDataScienceDataRoom,
            `dataScienceDataRoom.${dataRoomVersion}.interactive.initialConfiguration.title`
          );
          const dataRoomTitle = staticRoomTitle || interactiveRoomTitle;
          set(
            exportedDataScienceDataRoom,
            staticRoomTitle
              ? `dataScienceDataRoom.${dataRoomVersion}.static.title`
              : `dataScienceDataRoom.${dataRoomVersion}.interactive.initialConfiguration.title`,
            `Copy of ${dataRoomTitle}`
          );
          return draftDataRoomDuplicateMutation({
            variables: { input: { export: exportedDataScienceDataRoom } },
          }).then(({ data }) => {
            const { draftDataRoom } = data || {};
            const { importDataScienceDataRoom } = draftDataRoom || {};
            const { record } = importDataScienceDataRoom || {};
            const { id } = record || {};
            return id;
          });
        }
      } finally {
        setExporting(false);
      }
    });
  return {
    draftDataRoomDuplicate,
    draftDataRoomDuplicateLoading: loading || exporting || importing,
  };
};

export default useDraftDataRoomDuplicate;
