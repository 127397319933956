import { type DataRoomTableColumn } from "models";
import {
  ColumnDataType,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "types/__generated-new";

export enum MediaDataRoomTab {
  data = "data",
  insights = "insights",
  activation = "activation",
  auditLog = "auditLog",
}

export const mediaDataRoomTabs = [
  MediaDataRoomTab.data,
  MediaDataRoomTab.insights,
  MediaDataRoomTab.activation,
  MediaDataRoomTab.auditLog,
];

export interface AudienceOverlapStatistics {
  type: string;
  overlap: number;
  total: number;
  notMatching?: number;
}

export interface AudienceOverlapStatisticsRaw {
  advertiserSize: number;
  audienceType: string;
  overlapSize: number;
}

export interface OverlapInsightsCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapStatistic {
  audience_type: string;
  advertiser_size: number;
  overlap_size: number;
}

export interface OverlapStatisticsCollection {
  overlap_statistics: OverlapStatistic[];
  total_publisher: number;
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapSegmentsCollection {
  audiences: OverlapSegment[];
}

export interface PublisherDatasetsHashes {
  usersDatasetHash: string | null;
  segmentsDatasetHash: string | null;
  demographicsDatasetHash: string | null;
  embeddingsDatasetHash: string | null;
}

export interface AdvertiserDataReportCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
}

export interface PublishedDatasets {
  advertiserDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  publisherSegmentsDatasetHash?: string | null;
  publisherUsersDatasetHash?: string | null;
}

export enum RawSupportedFeatures {
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
  HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS = "HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS",
  ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD = "ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD",
  ENABLE_EXTENDED_LAL_QUALITY_STATS = "ENABLE_EXTENDED_LAL_QUALITY_STATS",
}

export const getAdvertiserAudienceColumns: (
  matchingIdFormat: TableColumnFormatType,
  hashMatchingIdWith: TableColumnHashingAlgorithm | null
) => DataRoomTableColumn[] = (matchingIdFormat, hashMatchingIdWith) => [
  {
    formatType: matchingIdFormat,
    hashWith: hashMatchingIdWith ?? undefined,
    id: "matchingId",
    name: "matchingId",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
  {
    default: "All customers",
    formatType: TableColumnFormatType.String,
    id: "audienceType",
    name: "audienceType",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
];

export interface Audience {
  id: string;
  audienceType: string;
  activationType: ActivationType;
  excludeSeedAudience: boolean;
  published: boolean;
  reach: number;
}

export interface ActivatedAudience {
  activation_type: ActivationType;
  audience_type: string;
  reach: number;
  is_published: boolean;
  exclude_seed_audience: boolean;
}

export interface ActivatedAudiencesConfigWrapper {
  advertiser_manifest_hash: string;
  activated_audiences: ActivatedAudience[];
}

export type ActivationType = "retarget" | "lookalike";

export interface MediaInsightsAudienceCacheKey {
  activationType: ActivationType;
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  activatedAudiences: Audience[];
  audienceType: string;
  reach?: number;
}

export interface InsightsBasicViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
}

export interface InsightsComparisonViewFormValues {
  aggregation: string;
  audienceType: string;
}

export interface InsightsDetailedViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
  // Defines whether selected best or worst affinityRatio values
  dataSortDirection: boolean | "desc" | "asc";
}
