import { type ApiCoreContextInterface } from "contexts";
import {
  type MutationStopDataRoomArgs,
  type StopDataRoomPayload,
} from "types/__generated-new";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "../../../models";

export const makeStopDataRoomResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    parent = null,
    args: MutationStopDataRoomArgs,
    context: LocalResolverContext
  ): Promise<StopDataRoomPayload> => {
    const { dcrHash, driverAttestationHash } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    await sdkSession.stopDataRoom(dcrHash);
    // TODO: unhardcode this
    return {
      id: "123",
    };
  };
