import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDraftParticipantDeleteMutation } from "hooks/__generated-new";

interface DeleteParticipantButtonProps {
  idForParticipant: string;
}

const DeleteParticipantButton: React.FC<DeleteParticipantButtonProps> = memo(
  ({ idForParticipant }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [draftParticipantDeleteMutation] = useDraftParticipantDeleteMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Participant could not be removed."
          )
        );
      },
    });
    const handleDeleteParticipant = useCallback(() => {
      draftParticipantDeleteMutation({
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "DraftParticipant",
              id: idForParticipant,
            }),
          });
          cache.gc();
        },
        variables: {
          id: idForParticipant,
        },
      });
    }, [draftParticipantDeleteMutation, idForParticipant]);
    return (
      <IconButton onClick={handleDeleteParticipant} size="small">
        <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
      </IconButton>
    );
  }
);

DeleteParticipantButton.displayName = "DeleteParticipantButton";

export default DeleteParticipantButton;
