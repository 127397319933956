import { createContext, type SetStateAction, useContext } from "react";
import {
  DEFAULT_PARTICIPANTS_EMAILS,
  type MediaDataRoomUserRole,
} from "features/mediaDataRoom";
import { DataRoomType } from "models";
import { ActivationType } from "types/__generated-new";

export const MediaDataRoomCreateWizardContext = createContext<{
  activationDownloadByAdvertiser: boolean;
  activationDownloadByPublisher: boolean;
  activationType: ActivationType | null;
  enableOverlapInsights: boolean;
  name: string;
  setActivationDownloadByAdvertiser: Function;
  setActivationDownloadByPublisher: Function;
  setActivationType: Function;
  setWithObserver: Function;
  setWithAgency: Function;
  setEnableOverlapInsights: Function;
  setName: Function;
  withObserver: boolean;
  withAgency: boolean;
  matchingId: string;
  addParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  removeParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  participantsEmails: Map<MediaDataRoomUserRole, string[]>;
  mainPublisherUserEmail: string;
  mainAdvertiserUserEmail: string;
  setMainPublisherUserEmail: (email: SetStateAction<string>) => void;
  setMainAdvertiserUserEmail: (email: SetStateAction<string>) => void;
  setMatchingId: (matchingId: string) => void;
  dataRoomType: DataRoomType;
}>({
  activationDownloadByAdvertiser: false,
  activationDownloadByPublisher: false,
  activationType: ActivationType.Consentless,
  addParticipantEmail: () => {},
  dataRoomType: DataRoomType.Media,
  enableOverlapInsights: false,
  mainAdvertiserUserEmail: "",
  mainPublisherUserEmail: "",
  matchingId: "",
  name: "",
  participantsEmails: DEFAULT_PARTICIPANTS_EMAILS,
  removeParticipantEmail: () => {},
  setActivationDownloadByAdvertiser: () => {},
  setActivationDownloadByPublisher: () => {},
  setActivationType: () => {},
  setEnableOverlapInsights: () => {},
  setMainAdvertiserUserEmail: () => {},
  setMainPublisherUserEmail: () => {},
  setMatchingId: () => {},
  setName: () => {},
  setWithAgency: () => {},
  setWithObserver: () => {},
  withAgency: false,
  withObserver: false,
});

export const MediaDataRoomCreateWizardProvider =
  MediaDataRoomCreateWizardContext.Provider;

export const useMediaDataRoomCreateWizard = () =>
  useContext(MediaDataRoomCreateWizardContext);
