import {
  faClock as faClockRegular,
  faCopy as faCopyRegular,
  faDatabase as faDatabaseRegular,
  faFileLines as faFileLinesRegular,
  faGlobe as faGlobeRegular,
  faHardDrive as faHardDriveRegular,
  faHashtag as faHashtagRegular,
  faHashtagLock as faHashtagLockRegular,
  faInputText as faInputTextRegular,
  faTable as faTableRegular,
  faUser as faUserRegular,
  faWarning as faWarningRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KeychainItemKind } from "services/keychain";
import { DetailsGridValue } from "components";
import { useKeychainService } from "contexts";
import {
  dataSourceTypePresentation,
  DeprovisionDatasetButton,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
  formatRows,
  formatSize,
  useDatasetDetailsConfig,
} from "features/datasets";
import { useCopyToClipboard } from "hooks";
import { useDatasetByHashQuery } from "hooks/__generated-new";
import { type DataRoomTypeNames, dataRoomTypePrefix } from "models";
import { DataSourceType } from "types/__generated-new";

type DatasetInfoTabPanelProps = {
  datasetHash: string;
  visible: boolean;
};

const DatasetInfoTabPanel: React.FC<DatasetInfoTabPanelProps> = ({
  datasetHash,
  visible,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [, copyToClipboard] = useCopyToClipboard();
  const { data } = useDatasetByHashQuery({
    skip: !datasetHash || !visible,
    variables: { manifestHash: datasetHash },
  });
  const { keychain } = useKeychainService();
  const [hasSchema, setHasSchema] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (visible) {
      keychain
        .getItem(datasetHash, KeychainItemKind.DatasetMetadata)
        .then(() => setHasSchema(true))
        .catch(() => setHasSchema(false));
    }
  }, [visible, setHasSchema, datasetHash, keychain]);
  const { disableDeprovision, disableProvisionedDataRoomNavigation } =
    useDatasetDetailsConfig();
  const dataset = data?.datasetByManifestHash;
  if (!dataset) {
    return <></>;
  }
  const publications = dataset.publications?.nodes || [];
  const isOwner = !!dataset.isOwner;
  const isTabular = hasSchema;
  return (
    <Grid columnSpacing={2} container={true} rowSpacing={2}>
      <Grid item={true} sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Owner</strong>
        </Typography>
        <DetailsGridValue icon={faUserRegular} value={dataset.owner.email} />
      </Grid>
      <Grid item={true} sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Upload date</strong>
        </Typography>
        <DetailsGridValue
          icon={faClockRegular}
          value={format(new Date(dataset?.createdAt), "dd-MM-yyyy HH:mm:ss")}
        />
      </Grid>
      {isOwner && (
        <Grid item={true} sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Imported from</strong>
          </Typography>
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <ExternalConnectionsIcon
              connectionType={dataset?.sourceType!}
              size={ExternalConnectionsIconSize.xs}
            />
            <Typography variant="body2">
              {dataSourceTypePresentation.get(dataset?.sourceType!)}
            </Typography>
          </Box>
        </Grid>
      )}
      {hasSchema !== undefined && (
        <Grid item={true} sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Type</strong>
          </Typography>
          <DetailsGridValue
            icon={isTabular ? faTableRegular : faFileLinesRegular}
            value={isTabular ? "Tabular" : "File"}
          />
        </Grid>
      )}
      {dataset.sourceType === DataSourceType.S3 &&
        dataset.datasetImport?.config && (
          <Fragment>
            <Grid item={true} sm={6} xs={12}>
              <Typography component="div" gutterBottom={true} variant="body2">
                <strong>Bucket</strong>
              </Typography>
              <DetailsGridValue
                icon={faDatabaseRegular}
                value={dataset.datasetImport?.config.bucket}
              />
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <Typography component="div" gutterBottom={true} variant="body2">
                <strong>Region</strong>
              </Typography>
              <DetailsGridValue
                icon={faGlobeRegular}
                value={dataset?.datasetImport?.config?.region}
              />
            </Grid>
          </Fragment>
        )}
      {!!dataset.statistics?.numberOfRows && (
        <Grid item={true} xs={dataset.size ? 6 : 12}>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Rows</strong>
          </Typography>
          <DetailsGridValue
            icon={faHashtagRegular}
            value={formatRows(dataset.statistics.numberOfRows)}
          />
        </Grid>
      )}
      {!!dataset.size && (
        <Grid item={true} xs={dataset.statistics?.numberOfRows ? 6 : 12}>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>File size</strong>
          </Typography>
          <DetailsGridValue
            icon={faHardDriveRegular}
            value={formatSize(dataset.size)}
          />
        </Grid>
      )}
      {!!dataset.description && (
        <Grid item={true} xs={12}>
          <Typography component="div" gutterBottom={true} variant="body2">
            <strong>Description</strong>
          </Typography>
          <DetailsGridValue
            icon={faInputTextRegular}
            value={dataset.description}
          />
        </Grid>
      )}
      <Grid item={true} xs={12}>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>Provisioned to</strong>
        </Typography>
        {publications.length ? (
          <List dense={true} sx={{ p: 0 }}>
            {publications.map(({ dataRoom, leaf }) => (
              <ListItemButton
                key={dataRoom.id}
                onClick={
                  disableProvisionedDataRoomNavigation
                    ? undefined
                    : () =>
                        navigate(
                          `/datarooms/${
                            dataRoomTypePrefix[
                              dataRoom.__typename as DataRoomTypeNames
                            ]
                          }/${dataRoom.id}`
                        )
                }
                sx={{ paddingLeft: disableDeprovision ? "8px" : 0 }}
              >
                {dataset?.isOwner && !disableDeprovision ? (
                  <ListItemAvatar sx={{ minWidth: "28px" }}>
                    <DeprovisionDatasetButton
                      dataRoomId={dataRoom.id}
                      datasetId={dataset.id}
                      driverAttestationHash={dataRoom.driverAttestationHash}
                      leaf={leaf}
                    />
                  </ListItemAvatar>
                ) : null}
                <Typography variant="body2">{dataRoom.title}</Typography>
              </ListItemButton>
            ))}
          </List>
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <FontAwesomeIcon fixedWidth={true} icon={faWarningRegular} />
            <Typography color="grey.600" fontStyle="italic" variant="body2">
              This dataset is not provisioned to any data clean room
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item={true} sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} variant="body2">
          <strong>ID</strong>
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            width: "100%",
          }}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faHashtagLockRegular} />
          <Tooltip placement="top" title={datasetHash}>
            <Typography noWrap={true} variant="body2">
              {datasetHash}
            </Typography>
          </Tooltip>
          <Tooltip placement="top" title={<span>Copy dataset hash</span>}>
            <Button
              color="inherit"
              onClick={() => {
                if (datasetHash) {
                  copyToClipboard(datasetHash);
                  enqueueSnackbar("Dataset hash copied");
                }
              }}
              size="small"
              sx={{ minWidth: "14px" }}
              variant="text"
            >
              <FontAwesomeIcon fixedWidth={true} icon={faCopyRegular} />
            </Button>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

DatasetInfoTabPanel.displayName = "DatasetInfoTabPanel";

export default memo(DatasetInfoTabPanel);
