import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import saveAs from "file-saver";
import { useEffect } from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { usePublishedMediaInsightsDcrQuery } from "hooks/__generated-new";
import useModelPerformanceReportGeneration from "./useModelPerformanceReportGeneration";

interface PerformanceReportDownloadDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
}

const PerformanceReportDownloadDialog: React.FC<
  PerformanceReportDownloadDialogProps
> = ({ dataRoomId, open, onCancel }) => {
  const { store } = useApiCore();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { data: publishedMediaInsightsDcrData } =
    usePublishedMediaInsightsDcrQuery({ variables: { id: dataRoomId } });
  const driverAttestationHash =
    publishedMediaInsightsDcrData?.publishedMediaInsightsDcr
      ?.driverAttestationHash;
  const { mutateAsync: generatePerformanceReport } =
    useModelPerformanceReportGeneration({
      dataRoomId,
      driverAttestationHash,
    });
  useEffect(() => {
    if (open) {
      generatePerformanceReport()
        .then((report) => {
          if (!report) {
            throw Error("Performance report is empty");
          }
          const file = new File(
            [JSON.stringify(report, null, 2)],
            `Performance_report_${new Date().toISOString()}.json`,
            {
              type: "application/octet-stream;charset=utf-8",
            }
          );
          if (!open) {
            return;
          }
          saveAs(file!);
          onCancel();
        })
        .catch((error) => {
          if (!open) {
            return;
          }
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to fetch performance report"
            )
          );
          onCancel();
        });
    }
  }, [open, generatePerformanceReport, onCancel, enqueueSnackbar, store]);
  return (
    <Dialog
      classes={dialogClasses}
      fullWidth={true}
      onClose={onCancel}
      open={open}
    >
      <DialogTitle>Preparing performance report</DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color="inherit" size={32} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
PerformanceReportDownloadDialog.displayName = "PerformanceReportDownloadDialog";

export default PerformanceReportDownloadDialog;
