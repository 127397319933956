import { parse as parseCsv } from "papaparse";
import { useEffect, useMemo, useState } from "react";
import { useApiCore, usePublishedMediaDataRoom } from "contexts";
import { type OverlapInsight } from "features/mediaDataRoom/types";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useAudienceInsightsQuery } from "hooks/__generated-new";

type HookReturn = {
  loading: boolean;
  overlapInsights: OverlapInsight[];
};

// Returns overlap insights data formatted to numbers to easier manipulate them in tables and charts
const useAudienceOverlapInsightsData = (audienceType: string): HookReturn => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { store } = useApiCore();
  const { dcrHash, driverAttestationHash } = usePublishedMediaDataRoom();

  const [overlapInsights, setOverlapInsights] = useState<OverlapInsight[]>([]);

  const { data: insightsData, loading } = useAudienceInsightsQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Overlap insights could not be retrieved. Please try again by refreshing the page.`
        )
      );
    },
    skip: !audienceType,
    variables: {
      input: {
        audienceType,
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });

  const overlapInsightsCsvFileId =
    insightsData?.mediaCalculateOverlapInsights?.insightsCsv;
  const overlapInsightsFile = useMemo(
    () =>
      overlapInsightsCsvFileId
        ? store.get<File>(overlapInsightsCsvFileId)
        : null,
    [overlapInsightsCsvFileId, store]
  );

  useEffect(() => {
    if (!overlapInsightsFile) {
      return;
    }
    // Result is Array of Arrays
    // Each row represented by an array with 3 values in it:
    // array[0] - segment, array[1] - base propensity, array[2] - overlap propensity, array[3] - net propensity
    const { data: parsedOverlapInsightsData } =
      parseCsv<string[]>(overlapInsightsFile);
    const overlapInsightsData = parsedOverlapInsightsData
      .filter((overlapInsight) => overlapInsight.some(Boolean))
      .map(([segment, basePropensity, overlapPropensity, netPropensity]) => ({
        basePropensity: Math.round(parseFloat(basePropensity) * 100),
        netPropensity: parseFloat((1 + parseFloat(netPropensity)).toFixed(2)),
        overlapPropensity: Math.round(parseFloat(overlapPropensity) * 100),
        segment,
      }));
    setOverlapInsights(overlapInsightsData);
  }, [overlapInsightsFile]);

  return {
    loading,
    overlapInsights,
  };
};

export default useAudienceOverlapInsightsData;
