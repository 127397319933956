import { Box, Typography } from "@mui/material";
import { memo } from "react";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { dataTargetTypePresentation } from "features/datasets/models";
import { useDatasetExportQuery } from "hooks/__generated-new";
import { DataTargetType } from "types/__generated-new";

type PermutiveConnectionConfigurationProps = {
  connectionId: string;
};

const PermutiveConnectionConfiguration: React.FC<
  PermutiveConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { datasetExport: { config: datasetExportConfig = {} } = {} } = {},
  } = useDatasetExportQuery({
    variables: { id: connectionId },
  });
  const { importId, segmentCode, segmentName, s3Provider } =
    datasetExportConfig || {};

  return (
    <div>
      <Typography variant="body2">
        <strong>Import ID:</strong> {importId}
      </Typography>
      <Typography variant="body2">
        <strong>Segment name:</strong> {segmentName}
      </Typography>
      <Typography variant="body2">
        <strong>Segment code:</strong> {segmentCode}
      </Typography>
      <Typography
        sx={{ alignItems: "center", display: "inline-flex" }}
        variant="body2"
      >
        <strong>Provider:</strong>
        <Box sx={{ alignItems: "center", display: "flex", ml: 0.5 }}>
          <ExternalConnectionsIcon
            connectionType={
              s3Provider ? DataTargetType.S3 : DataTargetType.GoogleCloudStorage
            }
            size={ExternalConnectionsIconSize.xs}
          />
          <Typography sx={{ marginLeft: "4px" }} variant="body2">
            {dataTargetTypePresentation.get(
              s3Provider ? DataTargetType.S3 : DataTargetType.GoogleCloudStorage
            )}
          </Typography>
        </Box>
      </Typography>
    </div>
  );
};

PermutiveConnectionConfiguration.displayName =
  "PermutiveConnectionConfiguration";

export default memo(PermutiveConnectionConfiguration);
