import { InfoTooltip } from "@decentriq/components";
import { Alert, Box, Grid, styled, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr";
import {
  AudienceQualityStatisticsDrawer,
  PositiveCurveScoreBar,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

export const AudienceQualityStatisticsItemBox = styled(Box)(
  ({ theme: { palette, shape, spacing } }) => ({
    alignItems: "center",
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: spacing(1.5),
  })
);

const AudienceQualityStatistics: React.FC = () => {
  const {
    estimatedAudienceSize,
    selectedLookalikeAudience,
    selectedAudienceQuality,
  } = useAdvertiserAudienceGenerator();
  const {
    supportedFeatures: { enableExtendedLalQualityStats = false },
  } = usePublishedMediaInsightsDcr();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const audienceQualityScore = useMemo(
    () => (selectedAudienceQuality?.roc_curve?.score || 0).toFixed(1) * 10,
    [selectedAudienceQuality?.roc_curve?.score]
  );

  return (
    <>
      <Typography mb={1} mt={4} variant="subtitle2">
        Audience preview reflecting the applied filters
      </Typography>
      <Grid
        columnSpacing={1}
        container={true}
        item={true}
        justifyContent={
          enableExtendedLalQualityStats ? "flex-start" : "flex-end"
        }
        xs={12}
      >
        <Grid item={true} xs={enableExtendedLalQualityStats ? 2 : 5}>
          <AudienceQualityStatisticsItemBox>
            <Typography fontWeight={500} variant="subtitle2">
              Estimated audience size
            </Typography>
            <Typography fontSize={36} fontWeight={600}>
              {formatter.format(estimatedAudienceSize)}
            </Typography>
          </AudienceQualityStatisticsItemBox>
        </Grid>
        {enableExtendedLalQualityStats && (
          <>
            <Grid item={true} xs={2}>
              <AudienceQualityStatisticsItemBox>
                <Typography fontWeight={500} variant="subtitle2">
                  Quality score
                  <InfoTooltip tooltip="This is an overall measure of balanced overall performance. It is directly calculated from the Receiver Operating Characteristic (ROC) graph. A high quality ROC curve will be be near the upper left corner: 100% true postive for 0% false positive. We measure quality as the distance from the upper left corner. A higher distance is lower quality. It is normalized so that when it is a minimum distance the quality is 10, and when it is at a maximum distance (0,0 or 100,100) the quality is 0." />
                </Typography>
                <Typography color="primary.main" fontSize={36} fontWeight={600}>
                  {`${audienceQualityScore}/10`}
                </Typography>
              </AudienceQualityStatisticsItemBox>
            </Grid>
            <Grid container={true} item={true} mt={0} xs={8}>
              <PositiveCurveScoreBar />
            </Grid>
          </>
        )}
      </Grid>
      {selectedLookalikeAudience?.quality?.test_set_size < 50 && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          There were too few individuals in the overlapping audience to create a
          large holdout group for testing. The quality metric was evaluated with
          only {selectedLookalikeAudience?.quality?.test_set_size} individuals,
          which may result in a less accurate assessment of model quality
        </Alert>
      )}
      {enableExtendedLalQualityStats && <AudienceQualityStatisticsDrawer />}
    </>
  );
};

AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default memo(AudienceQualityStatistics);
