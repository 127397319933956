import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, MenuItem, Select } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useCollaboratingOrganizationsQuery } from "hooks/__generated-new";
import { type OrganizationDataRoomFilter } from "types/__generated-new";

type CollaboratingOrganizationSelectProps = {
  organizationId: string;
  setQueryFilter: (filter: OrganizationDataRoomFilter) => void;
};

const CollaboratingOrganizationSelect: React.FC<
  CollaboratingOrganizationSelectProps
> = ({ organizationId, setQueryFilter }) => {
  const [selectedCollaboratingOrganizationId, selectCollaboratingOrganization] =
    useState<string | null>(null);
  const { data } = useCollaboratingOrganizationsQuery({
    variables: { filter: {}, organizationId },
  });

  const collaboratingOrganizationsOptions = useMemo(
    () =>
      (data?.organization?.collaboratingOrganizations?.nodes || [])
        .map(({ organization }) => ({
          id: organization?.id,
          label: organization?.name,
        }))
        // array is frozen in strict mode so it has to be copied before sorting
        .slice()
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [data]
  );

  // Set backend filter whenever value is changed
  useEffect(() => {
    setQueryFilter({
      participantOrganizationIdEquals: selectedCollaboratingOrganizationId,
    });
  }, [selectedCollaboratingOrganizationId, setQueryFilter]);

  return (
    <>
      <Select
        displayEmpty={true}
        onChange={({ target }) => {
          selectCollaboratingOrganization(target.value);
        }}
        renderValue={(value) => {
          return (
            collaboratingOrganizationsOptions.find(({ id }) => id === value)
              ?.label || "Collaborating organization..."
          );
        }}
        sx={{ m: 1 }}
        value={selectedCollaboratingOrganizationId}
        variant="standard"
      >
        {collaboratingOrganizationsOptions.map(({ id, label }: any) => (
          <MenuItem dense={true} key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        TouchRippleProps={{ center: false }}
        disabled={!selectedCollaboratingOrganizationId}
        onClick={() => selectCollaboratingOrganization(null)}
        sx={{ marginLeft: 0.5 }}
      >
        <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
      </IconButton>
    </>
  );
};

CollaboratingOrganizationSelect.displayName = "CollaboratingOrganizationSelect";

export default memo(CollaboratingOrganizationSelect);
