import { faXmark as faXmarkSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataLabsWrapper } from "features/dataLabs";
import DataLabConnectionTable from "./DataLabConnectionTable";

interface DataLabConnectDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  retrieveDatasets: () => Promise<void>;
}

const DataLabConnectDialog: React.FC<DataLabConnectDialogProps> = ({
  dataRoomId,
  open,
  onCancel,
  retrieveDatasets,
}) => {
  return (
    <Dialog
      PaperProps={{ sx: { p: "10px" } }}
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      scroll="paper"
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          letterSpacing: "-0.5px",
          lineHeight: "1.5rem",
          p: 1,
        }}
      >
        <Box>Provision from existing Data Lab</Box>
        <IconButton color="inherit" onClick={onCancel} sx={{ p: 0.5 }}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmarkSolid} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DataLabsWrapper>
          <DataLabConnectionTable
            dataRoomId={dataRoomId}
            onCancel={onCancel}
            retrieveDatasets={retrieveDatasets}
          />
        </DataLabsWrapper>
      </DialogContent>
    </Dialog>
  );
};

DataLabConnectDialog.displayName = "DataLabConnectDialog";

export default DataLabConnectDialog;
