import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import { replaceDecentriqOrgName } from "features/mediaDataRoom/utils";
import {
  ActivateAudienceButton,
  ActivationInsightsButton,
  AdvertiserAudienceGenerator,
  type Audience,
  AudiencesTable,
  DownloadAudienceButton,
  EmptyStateContainer,
  OverlapInsightsExecutionContext,
  useAdvertiserAudiencesContext,
  useLookalikeMediaDataRoomData,
} from "features/mediaDataRoomV2";
import { LookalikeMediaInsightsWrapper } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/wrappers";
import useLookalikeMediaDataRoomPublishedAudiences from "features/mediaDataRoomV2/containers/MediaDataRoomActivation/useLookalikeMediaDataRoomPublishedAudiences";

interface MediaDataRoomActivationProps {
  dataRoomId: string;
}

const WITH_INSIGHTS = false;

const MediaDataRoomActivation: React.FC<MediaDataRoomActivationProps> = ({
  dataRoomId,
}) => {
  const {
    isPublisher,
    isAdvertiser,
    isObserver,
    isAgency,
    driverAttestationHash,
    publisherOrganization,
    isDeactivated,
  } = usePublishedLookalikeMediaDataRoom();
  const {
    audienceSizes,
    datasetsLoading: dataRoomDataLoading,
    hasRequiredData,
    insights,
    isPublisherAudienceBeingUpdated,
  } = useLookalikeMediaDataRoomData();

  const [retryInProgress, setRetryInProgress] = useState(false);

  const {
    activatedAudiences,
    viewAudiencesLoading: advertiserAudiencesLoading,
    viewAudiencesError: advertiserAudiencesError,
    retryViewAudiences: retryAdvertiserAudiences,
  } = useAdvertiserAudiencesContext();

  const {
    audiences: publisherAudiences,
    loading: publisherAudiencesLoading,
    error: publisherAudiencesError,
    retry: retryPublisherAudiences,
  } = useLookalikeMediaDataRoomPublishedAudiences({
    dataRoomId,
    driverAttestationHash,
    hasRequiredData,
    skip: useMemo(
      () => isDeactivated || !isPublisher,
      [isDeactivated, isPublisher]
    ),
  });

  const audiences = useMemo(() => {
    return isPublisher ? publisherAudiences : activatedAudiences;
  }, [isPublisher, publisherAudiences, activatedAudiences]);

  const loading = useMemo(() => {
    return (
      dataRoomDataLoading ||
      (isAdvertiser
        ? advertiserAudiencesLoading ||
          audienceSizes.status !== "COMPLETED" ||
          insights.status !== "COMPLETED"
        : publisherAudiencesLoading)
    );
  }, [
    dataRoomDataLoading,
    isAdvertiser,
    advertiserAudiencesLoading,
    audienceSizes.status,
    insights.status,
    publisherAudiencesLoading,
  ]);

  const retryComputations = useCallback(async () => {
    setRetryInProgress(true);
    try {
      if (isPublisher) {
        await retryPublisherAudiences();
      } else {
        await retryAdvertiserAudiences();
      }
    } finally {
      setRetryInProgress(false);
    }
  }, [isPublisher, retryAdvertiserAudiences, retryPublisherAudiences]);

  const getAudienceStatusLabel = useCallback(
    (row: Audience) => {
      if (isPublisher) {
        return row.published ? "Available for download" : "Ready to activate";
      } else {
        if (row.published) {
          return `Available for download${
            publisherOrganization?.name
              ? " by " +
                replaceDecentriqOrgName(publisherOrganization.name, "Publisher")
              : ""
          }`;
        } else {
          return "Ready to activate";
        }
      }
    },
    [isPublisher, publisherOrganization]
  );

  const rowActions = useCallback(
    (row: Audience) => {
      const { audienceType, reach, published } = row;
      if (isPublisher) {
        return (
          <>
            {WITH_INSIGHTS && (
              <LookalikeMediaInsightsWrapper
                audienceType={audienceType}
                executionContext={
                  OverlapInsightsExecutionContext.publisherInsights
                }
                reach={reach}
              >
                <ActivationInsightsButton />
              </LookalikeMediaInsightsWrapper>
            )}
            {published && (
              <Box ml={0.5}>
                <DownloadAudienceButton
                  audienceType={audienceType}
                  reach={reach}
                />
              </Box>
            )}
          </>
        );
      }
      return (
        <>
          {!isObserver && (
            <Box ml={0.5}>
              {WITH_INSIGHTS && (
                <LookalikeMediaInsightsWrapper
                  audienceType={audienceType}
                  executionContext={
                    OverlapInsightsExecutionContext.advertiserInsights
                  }
                  reach={reach}
                >
                  <ActivationInsightsButton />
                </LookalikeMediaInsightsWrapper>
              )}
              <ActivateAudienceButton
                audienceType={audienceType}
                published={published}
                reach={reach}
              />
            </Box>
          )}
        </>
      );
    },
    [isObserver, isPublisher]
  );

  const noRecordsToDisplay = useMemo(
    () =>
      isPublisher
        ? "The advertiser has not made any audience available for activation yet."
        : "No audiences found",
    [isPublisher]
  );

  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          Publisher audience is being updated.
        </Typography>
        <Typography variant="subtitle2">
          {isPublisher
            ? "Please provision a Data Lab again."
            : "Activation will be available again soon."}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (!dataRoomDataLoading && !hasRequiredData) {
    return (
      <Alert severity="info">Waiting for both parties to provision data.</Alert>
    );
  }

  if (loading) {
    return (
      <EmptyStateContainer>
        <Typography
          sx={{ alignItems: "center", display: "inline-flex" }}
          variant="subtitle2"
        >
          <CircularProgress
            color="inherit"
            size={14}
            sx={{ marginRight: 1 }}
            thickness={3}
          />
          {isAdvertiser &&
            (audienceSizes.status === "COMPUTING" ||
            insights.status === "COMPUTING"
              ? "Computing lookalike models"
              : audienceSizes.status === "FETCHING" ||
                  insights.status === "FETCHING"
                ? "Retrieving audiences..."
                : "")}
          {isPublisher && "Loading audiences..."}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (publisherAudiencesError || advertiserAudiencesError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Activated audiences could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {(isAdvertiser || isAgency) && (
        <LookalikeMediaInsightsWrapper
          executionContext={OverlapInsightsExecutionContext.insightsTab}
        >
          <AdvertiserAudienceGenerator dataRoomId={dataRoomId} />
        </LookalikeMediaInsightsWrapper>
      )}
      <AudiencesTable
        audiences={audiences}
        getAudienceStatusLabel={getAudienceStatusLabel}
        noRecordsToDisplay={noRecordsToDisplay}
        rowActions={rowActions}
      />
    </Box>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
