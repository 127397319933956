import {
  Box,
  Button,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { SelectableControlLabel } from "components";
import {
  dataTargetTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { EXPORT_DATASET_STEPS } from "features/datasets/components/ExportDataset";
import { DataTargetType } from "types/__generated-new";
import { useExportDatasetDialog } from "../../contexts";

const WITH_AZURE = true;

type ExportDatasetTargetSelectorProps = {
  onClose: () => void;
};

const ExportDatasetTargetSelector: React.FC<
  ExportDatasetTargetSelectorProps
> = ({ onClose }) => {
  const { selectedDataTarget, setSelectedDataTarget, setExportStep } =
    useExportDatasetDialog();

  const handleDataSourceChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDataTarget(target.value as DataTargetType);
    },
    [setSelectedDataTarget]
  );

  const isTargetFormStepDisabled = [
    DataTargetType.TradeDesk,
    DataTargetType.Sportradar,
  ].includes(selectedDataTarget);

  const handleNextStepClick = useCallback(() => {
    // Remove when other data source type are implemented
    if (isTargetFormStepDisabled) {
      return;
    }
    setExportStep(EXPORT_DATASET_STEPS.TARGET_FORM);
  }, [isTargetFormStepDisabled, setExportStep]);

  return (
    <>
      <Grid container={true} item={true} spacing={3} xs={12}>
        <Grid item={true} xs={5}>
          <FormControl fullWidth={true} size="small">
            <RadioGroup
              onChange={handleDataSourceChange}
              sx={{
                "& .Mui-checked": { color: "#289090 !important" },
                gap: "0.5rem",
                marginBottom: "8px",
              }}
            >
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.S3}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.S3}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(DataTargetType.S3)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.S3}
                value={DataTargetType.S3}
              />
              {WITH_AZURE && (
                <SelectableControlLabel
                  control={
                    <Radio
                      checked={selectedDataTarget === DataTargetType.Azure}
                      size="small"
                      sx={{ marginRight: "8px", padding: "2px" }}
                    />
                  }
                  disableTypography={true}
                  label={
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <ExternalConnectionsIcon
                        connectionType={DataTargetType.Azure}
                        size={ExternalConnectionsIconSize.sm}
                      />
                      <Typography sx={{ marginLeft: "4px" }}>
                        {dataTargetTypePresentation.get(DataTargetType.Azure)}
                      </Typography>
                    </Box>
                  }
                  selected={selectedDataTarget === DataTargetType.Azure}
                  value={DataTargetType.Azure}
                />
              )}
              <SelectableControlLabel
                control={
                  <Radio
                    checked={
                      selectedDataTarget === DataTargetType.GoogleCloudStorage
                    }
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.GoogleCloudStorage}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(
                        DataTargetType.GoogleCloudStorage
                      )}
                    </Typography>
                  </Box>
                }
                selected={
                  selectedDataTarget === DataTargetType.GoogleCloudStorage
                }
                value={DataTargetType.GoogleCloudStorage}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={
                      selectedDataTarget === DataTargetType.GoogleAdManager
                    }
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.GoogleAdManager}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(
                        DataTargetType.GoogleAdManager
                      )}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.GoogleAdManager}
                value={DataTargetType.GoogleAdManager}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.Meta}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.Meta}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(DataTargetType.Meta)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.Meta}
                value={DataTargetType.Meta}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.GoogleDv_360}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.GoogleDv_360}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(
                        DataTargetType.GoogleDv_360
                      )}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.GoogleDv_360}
                value={DataTargetType.GoogleDv_360}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.Permutive}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.Permutive}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(DataTargetType.Permutive)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.Permutive}
                value={DataTargetType.Permutive}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.TradeDesk}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.TradeDesk}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(DataTargetType.TradeDesk)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.TradeDesk}
                value={DataTargetType.TradeDesk}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataTarget === DataTargetType.Sportradar}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataTargetType.Sportradar}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataTargetTypePresentation.get(
                        DataTargetType.Sportradar
                      )}
                    </Typography>
                  </Box>
                }
                selected={selectedDataTarget === DataTargetType.Sportradar}
                value={DataTargetType.Sportradar}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item={true} xs={7}>
          {selectedDataTarget === DataTargetType.S3 && (
            <>
              <Typography mb={1}>
                The selected dataset will be directly exported to an Amazon S3
                bucket and stored as an object.
              </Typography>
              <Typography>
                Note: to verify the successful export, please directly access
                your S3 bucket.
              </Typography>
            </>
          )}
          {WITH_AZURE && selectedDataTarget === DataTargetType.Azure && (
            <>
              <Typography mb={1}>
                The selected dataset will be directly exported to an Azure Blob
                Storage Container and stored as an object.
              </Typography>
              <Typography>
                Note: to verify the successful export, please directly access
                your Container.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.GoogleCloudStorage && (
            <>
              <Typography mb={1}>
                The selected dataset will be directly exported to a Google Cloud
                Storage Bucket and stored as an object.
              </Typography>
              <Typography>
                Note: to verify the successful export, please directly access
                your Bucket.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.GoogleAdManager && (
            <>
              <Typography mb={1}>
                This will export the selected dataset as an Audience Segment in
                Google Ad Manager 360. The dataset should have only one column
                with the list of identifiers. The type of identifier and the
                segment can be specified in the next screen.
              </Typography>
              <Typography>
                Note: Please access your Google Ad Manager 360 account to verify
                the created audience segment.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.Meta && (
            <>
              <Typography mb={1}>
                This will export the selected dataset directly as an audience to
                Meta Ads Manager. The dataset should have only one column with
                plain email addressed that will be automatically hashed with
                SHA256.
              </Typography>
              <Typography>
                Note: Please access your Meta Ads Manager to verify the created
                audience.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.GoogleDv_360 && (
            <>
              <Typography mb={1}>
                This will export the selected dataset directly as First- and
                Third-party audience to GoogleDV360. The dataset should have
                only one column with plain email addressed that will be
                automatically hashed with SHA256.
              </Typography>
              <Typography>
                Note: Please access your GoogleDV360 account to verify the
                created audience.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.Permutive && (
            <>
              <Typography mb={1}>
                This will export the selected dataset directly as an imported
                segment on Permutive using a Cloud Storage service. The dataset
                should have only one column with Permutive IDs, and the segment
                will be added automatically to them.
              </Typography>
              <Typography>
                Note: Please access your Permutive account to verify the
                imported segment.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.TradeDesk && (
            <>
              <Typography mb={1}>
                The Trade Desk is a demand-side platform (DSP) that provides a
                technology platform for advertisers to manage and optimize
                programmatic advertising campaigns across various digital
                channels and inventory sources.
              </Typography>
              <Typography>
                Importing from The Trade Desk is coming soon.
              </Typography>
            </>
          )}
          {selectedDataTarget === DataTargetType.Sportradar && (
            <>
              <Typography mb={1}>
                Sportradar ad:s is a demand-side platform (DSP) dedicated to the
                sport and fan ecosystem. It accepts a variety of IDs to be
                targeted on different channels dedicated to sport, generic
                purpose publishers and social media. The integration supports
                the FanID service.
              </Typography>
              <Typography>
                Please contact us to enable this integration for your
                organization.
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="inherit"
          disabled={isTargetFormStepDisabled}
          onClick={handleNextStepClick}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

ExportDatasetTargetSelector.displayName = "ExportDatasetTargetSelector";

export default memo(ExportDatasetTargetSelector);
