import { Box, styled } from "@mui/material";
import { Fragment, memo } from "react";
import { NodeTooltip } from "components";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { ComputeNodeActions } from "features/computeNode";
import { WorkerTypeShortName } from "types/__generated-new";
import useComputeNodeType from "../ComputeNodeType/useComputeNodeType";
import {
  ComputeNodeExpandButton,
  ComputeNodeNameEditor,
  ComputeNodeParticipants,
  ComputeNodeType,
} from "../";

interface ComputeNodeToolbarProps {
  computeNodeId: string;
  className?: string;
}

const ComputeNodeToolbar: React.FC<ComputeNodeToolbarProps> = memo(
  ({ computeNodeId, className }) => {
    const {
      hasComputeNodeActions,
      hasComputeNodeParticipants,
      executionContext,
      readOnly,
    } = useComputeNodesVars();
    const { isPublished } = useDataRoom();
    const isInteractivity =
      executionContext === "development" || executionContext === "requests";
    const computeNodeWithType = useComputeNodeType(computeNodeId);
    const tooltip =
      computeNodeWithType?.computationType === WorkerTypeShortName.Python ||
      computeNodeWithType?.computationType === WorkerTypeShortName.R ||
      computeNodeWithType?.computationType === WorkerTypeShortName.Synthetic ||
      computeNodeWithType?.computationType === WorkerTypeShortName.Sqlite
        ? `${computeNodeId}_container`
        : computeNodeId;
    return (
      <div className={className}>
        <ComputeNodeExpandButton computeNodeId={computeNodeId} />
        <div className="leftWrapper">
          {isPublished && !isInteractivity ? (
            <NodeTooltip
              nodeType="compute"
              sx={{ display: "flex", width: "100%" }}
              value={tooltip}
            >
              <ComputeNodeType computeNodeId={computeNodeId} />
              <Box sx={{ p: 0.5 }}>
                <ComputeNodeNameEditor computeNodeId={computeNodeId} />
              </Box>
            </NodeTooltip>
          ) : (
            <Fragment>
              <ComputeNodeType computeNodeId={computeNodeId} />
              <Box sx={{ p: 0.5 }}>
                <ComputeNodeNameEditor computeNodeId={computeNodeId} />
              </Box>
            </Fragment>
          )}
        </div>
        <div className="rightWrapper">
          {hasComputeNodeParticipants ? (
            <ComputeNodeParticipants
              computeNodeId={computeNodeId}
              readOnly={readOnly}
            />
          ) : null}
          {hasComputeNodeActions ? (
            <ComputeNodeActions computeNodeId={computeNodeId} />
          ) : null}
        </div>
      </div>
    );
  }
);

ComputeNodeToolbar.displayName = "ComputeNodeToolbar";

const StyledComputeNodeToolbar = styled(ComputeNodeToolbar)(({ theme }) => ({
  "& .leftWrapper": {
    alignItems: "center",
    display: "flex",
    flex: 1,
    gap: theme.spacing(0.5),
    width: 0,
  },
  "& .rightWrapper": {
    alignItems: "center",
    display: "flex",
    flex: 0,
    gap: theme.spacing(0.5),
  },
  display: "flex",
}));

export default StyledComputeNodeToolbar;
