import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Checkbox, FormControlLabel } from "@mui/material";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useSetSyntheticNodeIncludeStatisticsMutation } from "hooks/__generated-new";
import useSyntheticNodeIncludeStatistics from "./useSyntheticNodeIncludeStatistics";

type SyntheticNodeIncludeStatisticsCheckboxProps = {
  computeNodeId: string;
  readOnly?: boolean;
};

const SyntheticNodeIncludeStatisticsCheckbox: React.FC<
  SyntheticNodeIncludeStatisticsCheckboxProps
> = ({ computeNodeId, readOnly }) => {
  const { includeReportWithStats } =
    useSyntheticNodeIncludeStatistics(computeNodeId);
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [setSyntheticNodeIncludeStatisticsMutation] =
    useSetSyntheticNodeIncludeStatisticsMutation();
  const setSyntheticNodeIncludeStatistics = useCallback(
    async (includeReportWithStats: boolean) => {
      try {
        return setSyntheticNodeIncludeStatisticsMutation({
          variables: {
            input: {
              id: computeNodeId,
              includeReportWithStats,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The quality report settings could not be updated."
          )
        );
        throw error;
      }
    },
    [setSyntheticNodeIncludeStatisticsMutation, computeNodeId, enqueueSnackbar]
  );
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={includeReportWithStats}
          data-testid={testIds.computeNode.computeNodeCreator.includeStatistics}
          disabled={readOnly}
          onChange={(_, checked) => setSyntheticNodeIncludeStatistics(checked)}
          size="small"
          sx={{
            "&.Mui-checked": { color: "#289090" },
            paddingLeft: 0,
          }}
        />
      }
      label={
        <span>
          Include original data statistics in the quality report{" "}
          <InfoTooltip tooltip="Generating synthetic data brings a quality report with charts of the distribution of each correlation. Having original data in the report facilitates comparison with synthetic data." />
        </span>
      }
      style={{ margin: "12px 0 0" }}
    />
  );
};

SyntheticNodeIncludeStatisticsCheckbox.displayName =
  "SyntheticNodeIncludeStatisticsCheckbox";

export default memo(SyntheticNodeIncludeStatisticsCheckbox);
