import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "features/datasets/components/ImportExternalData";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
} from "./components";

type GoogleCloudStorageFormProps = (
  | ImportExternalDataFormProps
  | ExportDatasetFormProps
) & {
  type: ExternalConnectionType;
};

const GoogleCloudStorageFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucketName: yup.string().trim().required("Bucket name is required"),
    objectName: yup.string().trim().required("Object name is required"),
  }),
  credentials: yup.object({
    credentialsFile: yup.mixed().required("Credentials file is required"),
  }),
  datasetName: yup.string(),
});

type GoogleCloudStorageFormValues = yup.InferType<
  typeof GoogleCloudStorageFormValidationSchema
>;

const GoogleCloudStorageForm: React.FC<GoogleCloudStorageFormProps> = ({
  submitFormHandler,
  cancelFormHandler,
  submitButtonText = "Import",
  defaultValues,
  type = ExternalConnectionType.IMPORT,
}) => {
  const cloudStorageForm = useForm({
    defaultValues: {
      configuration: {
        bucketName: "",
        objectName:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName,
      },
      credentials: {
        credentialsFile: undefined,
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(GoogleCloudStorageFormValidationSchema),
  });
  const { control, handleSubmit, reset } = cloudStorageForm;

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: GoogleCloudStorageFormValues) => {
      const {
        configuration: { bucketName = "", objectName = "" } = {},
        credentials: { credentialsFile } = {},
        datasetName = "",
      } = formValues;

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const credentialsJSON = JSON.parse(reader?.result);
          const credentials = JSON.stringify(credentialsJSON, null, 4);
          if (!credentials) {
            throw new Error("Credentials file is not valid");
          }
          submitFormHandler({
            input: {
              ...(type === ExternalConnectionType.IMPORT
                ? { datasetName: datasetName?.trim() || objectName?.trim() }
                : {}),
              gcs: {
                bucketName: bucketName.trim(),
                credentials,
                objectName: objectName.trim(),
              },
            },
          });
        };
        reader.readAsText(credentialsFile);
      } catch (error) {
        throw new Error(error as string);
      }
      reset();
    },
    [reset, submitFormHandler, type]
  );

  return (
    <Box>
      <FormProvider {...cloudStorageForm}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <GoogleCloudStorageFormBucketField />
          <Controller
            control={control}
            name="configuration.objectName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.objectName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Object name"
                  placeholder="Example: my-object-name"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          {type === ExternalConnectionType.IMPORT && (
            <Controller
              control={control}
              name="datasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasetName;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Stored dataset name (optional)"
                    placeholder="Example: DQ_dataset_name"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
          )}
        </Box>
        <Box mb={2} mt={2}>
          <GoogleCloudStorageFormCredentials />
        </Box>
      </FormProvider>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button color="inherit" onClick={handleSubmit(handleFormSubmit)}>
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

GoogleCloudStorageForm.displayName = "GoogleCloudStorageForm";

export default memo(GoogleCloudStorageForm);
