import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import { memo, useCallback } from "react";
import { Chip } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import {
  useOrganizationLicenseQuery,
  useUpdateOrganizationLicenseMutation,
} from "hooks/__generated-new";
import { OrganizationState } from "types/__generated-new";
import { getEffectiveErrorMessage } from "utils";
import OrganizationLicenseTypesTooltip from "./OrganizationLicenseTypesTooltip";

interface OrganizationLicenseEditorProps {
  organizationId: string;
}

const OrganizationLicenseEditor: React.FC<OrganizationLicenseEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const { state } = organizationLicenseData?.organization || {};

  const [updateOrganizationLicenseMutation] =
    useUpdateOrganizationLicenseMutation({
      onCompleted: () => {
        enqueueSnackbar("Organization licensing has been updated");
      },
    });

  const updateOrganizationLicense = useCallback(
    async ({ target: { value } }: SelectChangeEvent<OrganizationState>) => {
      try {
        await updateOrganizationLicenseMutation({
          update: (cache) => {
            cache.modify({
              fields: {
                state: () => {
                  return value;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            organizationId,
            state: value as OrganizationState,
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization license status could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationLicenseMutation]
  );

  const ORGANIZATION_STATE_MENU_ITEMS = [
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Subscribed,
      title: OrganizationState.Subscribed,
      value: OrganizationState.Subscribed,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Suspended ||
        state === OrganizationState.Archived,
      title: OrganizationState.Suspended,
      value: OrganizationState.Suspended,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Archived,
      title: OrganizationState.Trial,
      value: OrganizationState.Trial,
    },
    {
      disabled: true,
      title: OrganizationState.Passive,
      value: OrganizationState.Passive,
    },
  ];

  return (
    <FormControl component="fieldset" fullWidth={true} variant="standard">
      <FormLabel component="legend">
        License status
        {isSuperAdmin && <OrganizationLicenseTypesTooltip />}
      </FormLabel>
      {isSuperAdmin ? (
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          <Select<OrganizationState>
            defaultValue={state}
            displayEmpty={true}
            fullWidth={true}
            onChange={updateOrganizationLicense}
            renderValue={(value) => {
              if (state === OrganizationState.Archived) {
                return OrganizationState.Archived;
              }
              return value || state;
            }}
            style={{ background: "transparent" }}
            value={state}
            variant="standard"
          >
            {ORGANIZATION_STATE_MENU_ITEMS.map(({ disabled, title, value }) => (
              <MenuItem disabled={disabled} key={value} value={value}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      ) : (
        <Box sx={{ mt: 0.5 }}>
          <Chip label={state?.toUpperCase()} />
        </Box>
      )}
    </FormControl>
  );
};

OrganizationLicenseEditor.displayName = "OrganizationLicenseEditor";

export default memo(OrganizationLicenseEditor);
