import { data_science } from "@decentriq/core";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import {
  CompleteComputeJobFailureDocument,
  type CompleteComputeJobFailureMutation,
  type CompleteComputeJobFailureMutationVariables,
  CompleteComputeJobSuccessDocument,
  type CompleteComputeJobSuccessMutation,
  type CompleteComputeJobSuccessMutationVariables,
  GetComputeJobEnclaveHandleDocument,
  type GetComputeJobEnclaveHandleQuery,
  type GetComputeJobEnclaveHandleQueryVariables,
  type MutationPollComputeJobArgs,
} from "types/__generated-new";
import { parseDataRoomComputationError } from "utils";
import {
  getNodeById,
  maybeUseDataRoomSecret,
  parseErrorMessage,
} from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makePollComputeJobResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationPollComputeJobArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<void> => {
    const { dcrHash, driverAttestationHash, computeNodeId, jobId } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const computeJobEnclaveHandleEncoded = (
      await context.client.query<
        GetComputeJobEnclaveHandleQuery,
        GetComputeJobEnclaveHandleQueryVariables
      >({
        query: GetComputeJobEnclaveHandleDocument,
        variables: {
          id: jobId,
        },
      })
    ).data.computeJob.enclaveComputeJobHandleBase64;
    const computeJobEnclaveHandle: data_science.DataScienceJobId = JSON.parse(
      new TextDecoder().decode(
        forge.util.binary.base64.decode(computeJobEnclaveHandleEncoded)
      )
    );
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const jobStatus = await wrapper.retrieveJobStatus(
        computeJobEnclaveHandle
      );
      switch (jobStatus.status) {
        case "pending": {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          continue;
        }
        case "failed": {
          await context.client.mutate<
            CompleteComputeJobFailureMutation,
            CompleteComputeJobFailureMutationVariables
          >({
            mutation: CompleteComputeJobFailureDocument,
            variables: {
              id: jobId,
            },
          });
          const computeNode = getNodeById(context, computeNodeId, args);
          throw parseDataRoomComputationError(
            parseErrorMessage(context.cache, jobStatus.error, args),
            computeNode?.name
          );
        }
        case "completed": {
          await context.client.mutate<
            CompleteComputeJobSuccessMutation,
            CompleteComputeJobSuccessMutationVariables
          >({
            mutation: CompleteComputeJobSuccessDocument,
            variables: {
              id: jobId,
            },
          });
          return;
        }
      }
    }
  };
