import { useEffect, useMemo, useState } from "react";
import { KeychainItemKind } from "services/keychain";
import { useKeychainService } from "contexts";
import { useDatasetByHashQuery } from "hooks/__generated-new";
import { type DatasetByHashQuery } from "types/__generated-new";
import { logError } from "utils";
import {
  type DatasetMetadata,
  datasetMetadataZodSchema,
} from "utils/validation";

interface UseGetDatasetWithMetadataProps {
  skipFetching: boolean;
  datasetHash?: string;
}

interface UseGetDatasetWithMetadata {
  dataset?: DatasetByHashQuery["datasetByManifestHash"];
  metadata?: DatasetMetadata;
}

export const useGetDatasetWithMetadata = ({
  skipFetching,
  datasetHash,
}: UseGetDatasetWithMetadataProps): UseGetDatasetWithMetadata => {
  const { keychain } = useKeychainService();
  const [metadata, setMetadata] = useState<DatasetMetadata | undefined>();
  const { data: dataByHash } = useDatasetByHashQuery({
    skip: skipFetching || !datasetHash,
    variables: {
      manifestHash: datasetHash!,
    },
  });
  useEffect(() => {
    if (skipFetching || !datasetHash) {
      return;
    }
    keychain
      .getItem(datasetHash, KeychainItemKind.DatasetMetadata)
      .then(({ item: keychainItem }) => {
        const metadata = datasetMetadataZodSchema.parse(
          JSON.parse(keychainItem?.value ?? "")
        );
        setMetadata(metadata);
      })
      .catch((error) => logError("Failed to parse dataset metadata", error));
  }, [datasetHash, keychain, skipFetching]);
  return {
    dataset: useMemo(() => dataByHash?.datasetByManifestHash, [dataByHash]),
    metadata,
  };
};
