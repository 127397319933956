import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "features/datasets/components/ImportExternalData";

type AzureFormProps = (ImportExternalDataFormProps | ExportDatasetFormProps) & {
  type: ExternalConnectionType;
};

const AzureFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    blobName: yup.string().trim().required("Blob name is required"),
    storageAccount: yup.string().trim().required("Storage account is required"),
    storageContainer: yup
      .string()
      .trim()
      .required("Storage container is required"),
  }),
  credentials: yup.object({
    sasToken: yup.string().trim().required("SAS token is required"),
  }),
  datasetName: yup.string(),
});

type AzureFormValues = yup.InferType<typeof AzureFormValidationSchema>;

const AzureForm: React.FC<AzureFormProps> = ({
  submitFormHandler,
  cancelFormHandler,
  submitButtonText = "Import",
  defaultValues,
  type = ExternalConnectionType.IMPORT,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        blobName:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName || "",
        storageAccount: "",
        storageContainer: "",
      },
      credentials: {
        sasToken: "",
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(AzureFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: AzureFormValues) => {
      const {
        configuration: {
          blobName = "",
          storageAccount = "",
          storageContainer = "",
        } = {},
        credentials: { sasToken = "" } = {},
        datasetName = "",
      } = formValues;

      submitFormHandler({
        input: {
          azure: {
            credentials: {
              blobName: blobName.trim(),
              sasToken: sasToken.trim(),
              storageAccount: storageAccount.trim(),
              storageContainer: storageContainer.trim(),
            },
          },
          ...(type === ExternalConnectionType.IMPORT
            ? { datasetName: datasetName?.trim() || blobName?.trim() }
            : {}),
        },
      });
      reset();
    },
    [reset, submitFormHandler, type]
  );

  return (
    <Box>
      <form>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.storageAccount"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.storageAccount;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Storage account"
                  placeholder="Example: myazureaccount"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.storageContainer"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.storageContainer;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Storage container"
                  placeholder="Example: my-container"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.blobName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.blobName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Blob name"
                  placeholder="Example: my-file.csv"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          {type === ExternalConnectionType.IMPORT && (
            <Controller
              control={control}
              name="datasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasetName;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Stored dataset name (optional)"
                    placeholder="Example: DQ_dataset_name"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
          )}
        </Box>
        <Box mb={2} mt={2}>
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentials.sasToken"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.sasToken;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="SAS Token"
                  placeholder="Example: sp=racw&st=2023-08-02T10:27:33Z..."
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
      </form>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button color="inherit" onClick={handleSubmit(handleFormSubmit)}>
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

AzureForm.displayName = "AzureForm";

export default memo(AzureForm);
