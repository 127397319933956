import { useAuth0 } from "@auth0/auth0-react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab } from "@mui/material";
import { memo, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { DataRoomAuditLogs } from "containers";
import {
  MediaDataRoomActivation,
  MediaDataRoomAudienceInsights,
  MediaDataRoomMainbar,
} from "features/mediaDataRoom";
import { isNormalizedEmailIncluded } from "features/mediaDataRoom/utils";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useDataRoomTabsNavigationState,
} from "hooks";
import { useCompleteMediaDataRoomQuery } from "hooks/__generated-new";
import { useTabListStyles, useTabPanelStyles, useTabStyles } from "styles";
import { PublishedMediaDataRoomWrapper } from "wrappers";

interface MediaDataRoomProps {
  id: string;
  [key: string]: any;
}

const tabs = ["Insights", "Activation", "Audit"];
const defaultTab = tabs[0];

const MediaDataRoom: React.FC<MediaDataRoomProps> = memo(({ id }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabPanelClasses } = useTabPanelStyles();
  const { classes: tabClasses } = useTabStyles();
  const { user } = useAuth0();
  const currentUserEmail = user?.email || "";
  const { activeTab, setActiveTab } = useDataRoomTabsNavigationState({
    dataRoomId: id,
    defaultTab,
    tabs,
  });
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: string) =>
      setActiveTab(newValue),
    [setActiveTab]
  );
  const { data, loading, error } = useCompleteMediaDataRoomQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Media data clean room could not be retrieved. Please try again by refreshing the page.`
        )
      );
    },
    variables: {
      id,
    },
  });
  const {
    observerUserEmails = [],
    publisherUserEmails = [],
    enableAuditLogRetrieval,
    activationDownloadByPublisher,
    activationType,
  } = data?.publishedMediaDataRoom || {};
  const isObserver = isNormalizedEmailIncluded(
    observerUserEmails,
    currentUserEmail
  );
  const isPublisher = isNormalizedEmailIncluded(
    publisherUserEmails,
    currentUserEmail
  );
  const hasActivation =
    !!activationType &&
    !isObserver &&
    !(isPublisher && !activationDownloadByPublisher);
  if (loading) {
    return (
      <CircularProgress
        color="inherit"
        size="2.5rem"
        sx={{ margin: "auto" }}
        thickness={1}
      />
    );
  }
  if (error || !data) {
    return <Navigate replace={true} to="/datarooms" />;
  }
  const dataRoomId = data!.publishedMediaDataRoom.id;
  const driverAttestationHash =
    data!.publishedMediaDataRoom.driverAttestationHash;
  return (
    <PublishedMediaDataRoomWrapper
      dataRoomId={dataRoomId}
      driverAttestationHash={driverAttestationHash}
      isDeactivated={!!data?.publishedMediaDataRoom?.deactivatedAt || false}
      isStopped={data?.publishedMediaDataRoom?.isStopped || false}
    >
      <MediaDataRoomMainbar id={id} />
      <TabContext key={id} value={activeTab}>
        <Box sx={{ backgroundColor: "background.bar" }}>
          <TabList
            classes={tabListClasses}
            onChange={handleTabChange}
            style={{ marginTop: 0 }}
            textColor="inherit"
            variant="scrollable"
          >
            <Tab
              classes={tabClasses}
              label="AUDIENCE INSIGHTS"
              value="Insights"
            />
            {hasActivation && (
              <Tab classes={tabClasses} label="ACTIVATION" value="Activation" />
            )}
            {enableAuditLogRetrieval && (
              <Tab classes={tabClasses} label="AUDIT LOG" value="Audit" />
            )}
          </TabList>
        </Box>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <TabPanel classes={tabPanelClasses} value="Insights">
            <MediaDataRoomAudienceInsights id={id} />
          </TabPanel>
          {hasActivation && (
            <TabPanel classes={tabPanelClasses} value="Activation">
              <Box
                flexShrink={0}
                minHeight="100%"
                sx={{ background: "#fff", p: 2 }}
              >
                <MediaDataRoomActivation id={id} key={id} />
              </Box>
            </TabPanel>
          )}
          {enableAuditLogRetrieval && (
            <TabPanel classes={tabPanelClasses} value="Audit">
              <DataRoomAuditLogs
                dcrHash={dataRoomId!}
                driverAttestationHash={driverAttestationHash!}
              />
            </TabPanel>
          )}
        </div>
      </TabContext>
    </PublishedMediaDataRoomWrapper>
  );
});

export default MediaDataRoom;
