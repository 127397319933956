import { CircularProgress } from "@mui/material";
import { memo } from "react";
import { Navigate } from "react-router-dom";
import {
  MediaDataRoomMainbar,
  MediaInsightsDcrWrapper,
} from "features/mediaDataRoomV2";
import { usePublishedLookalikeMediaDataRoomQuery } from "hooks/__generated-new";
import MediaDataRoomErrorReport from "../MediaDataRoomErrorReport/MediaDataRoomErrorReport";
import MediaDataRoomTabs from "../MediaDataRoomTabs/MediaDataRoomTabs";

interface MediaDataRoomProps {
  id: string;
}

const MediaDataRoom: React.FC<MediaDataRoomProps> = memo(
  ({ id: dataRoomId }) => {
    // Keep this query to provision data for the `MediaDataRoomErrorReport` wrapper.
    const { data, loading, error } = usePublishedLookalikeMediaDataRoomQuery({
      variables: { id: dataRoomId },
    });
    if (loading) {
      return (
        <CircularProgress
          color="inherit"
          size="2.5rem"
          sx={{ margin: "auto" }}
          thickness={1}
        />
      );
    }
    if (error || !data) {
      return <Navigate replace={true} to="/datarooms" />;
    }
    const lookalikeData = data!.publishedLookalikeMediaDataRoom!;
    const driverAttestationHash = lookalikeData.driverAttestationHash!;
    const isDeactivated = Boolean(lookalikeData.deactivatedAt);
    const isStopped = lookalikeData.isStopped;
    const createdAt = lookalikeData.createdAt;
    return (
      <MediaDataRoomErrorReport
        createdAt={createdAt}
        dataRoomId={lookalikeData.id}
        driverAttestationHash={lookalikeData.driverAttestationHash}
      >
        <MediaInsightsDcrWrapper
          dataRoomId={dataRoomId}
          driverAttestationHash={lookalikeData.driverAttestationHash}
          isDeactivated={isDeactivated}
          isStopped={isStopped}
        >
          <MediaDataRoomMainbar id={dataRoomId} />
          <MediaDataRoomTabs
            dataRoomId={dataRoomId}
            driverAttestationHash={driverAttestationHash}
          />
        </MediaInsightsDcrWrapper>
      </MediaDataRoomErrorReport>
    );
  }
);

export default MediaDataRoom;
