import { faFileLines } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionsIcon } from "features/datasets";
import { ExternalConnectionConfigurationLabel } from "features/datasets/components/ExternalConnections";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
  type ImportExternalDataFormProps,
  S3BucketFormConfigurationFields,
  S3BucketFormCredentials,
} from "features/datasets/components/ImportExternalData";
import { dataSourceTypePresentation } from "features/datasets/models";
import {
  type DataSourceType,
  PermutiveServiceProvider,
} from "types/__generated-new";

type PermutiveFormProps = ImportExternalDataFormProps;

const PermutiveFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucketName: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.GoogleCloudStorage,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket name is required"),
      }),
    region: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Region is required"),
      }),
    serviceProvider: yup
      .mixed()
      .oneOf(Object.values(PermutiveServiceProvider))
      .required("Service provider is required"),
    url: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket URL is required"),
      }),
  }),
  credentials: yup.object().when("configuration.serviceProvider", {
    is: (serviceProvider: PermutiveServiceProvider) =>
      serviceProvider === PermutiveServiceProvider.S3,
    otherwise: () =>
      yup.object().shape({
        accessKey: yup.string(),
        credentialsFile: yup.mixed().required("Credentials file is required"),
        secretKey: yup.string(),
      }),
    then: () =>
      yup.object().shape({
        accessKey: yup.string().trim().required("Access key is required"),
        credentialsFile: yup.mixed(),
        secretKey: yup.string().trim().required("Secret Key is required"),
      }),
  }),
  datasets: yup.object({
    demographicsDatasetName: yup.string(),
    matchingDatasetName: yup
      .string()
      .trim()
      .required("Matching dataset name is required"),
    segmentsDatasetName: yup
      .string()
      .trim()
      .required("Segments dataset name is required"),
  }),
});

type PermutiveFormValues = yup.InferType<typeof PermutiveFormValidationSchema>;

const PermutiveForm: React.FC<PermutiveFormProps> = ({
  submitFormHandler,
  cancelFormHandler,
}) => {
  const permutiveHookForm = useForm({
    defaultValues: {
      configuration: {
        bucketName: "", // Cloud Storage configuration
        region: "", // S3 bucket configuration
        serviceProvider: PermutiveServiceProvider.GoogleCloudStorage,
        url: "", // S3 bucket configuration
      },
      credentials: {
        accessKey: "", // S3 bucket credentials
        credentialsFile: undefined, // Cloud Storage Credentials
        secretKey: "", // S3 bucket credentials
      },
      datasets: {
        demographicsDatasetName: "",
        matchingDatasetName: "matching.csv",
        segmentsDatasetName: "segments.csv",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(PermutiveFormValidationSchema),
  });
  const { control, handleSubmit, reset, watch } = permutiveHookForm;

  const cloudProviderValue = watch("configuration.serviceProvider");

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: PermutiveFormValues) => {
      const {
        configuration: {
          serviceProvider,
          region = "",
          url = "",
          bucketName = "",
        } = {},
        credentials: { credentialsFile, accessKey = "", secretKey = "" } = {},
        datasets,
      } = formValues;

      if (serviceProvider === PermutiveServiceProvider.S3) {
        submitFormHandler({
          input: {
            datasetName: "",
            permutive: {
              configuration: {
                aws: { bucket: url.trim(), region: region.trim() },
              },
              credentials: {
                aws: {
                  accessKey: accessKey.trim(),
                  secretKey: secretKey.trim(),
                },
              },
              datasets,
              serviceProvider,
            },
          },
        });
      }

      if (serviceProvider === PermutiveServiceProvider.GoogleCloudStorage) {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const credentialsJSON = JSON.parse(reader?.result as string);
            const credentials = JSON.stringify(credentialsJSON, null, 4);
            if (!credentials) {
              throw new Error("Credentials file is not valid");
            }
            submitFormHandler({
              input: {
                datasetName: "",
                permutive: {
                  configuration: { gcs: { bucketName: bucketName.trim() } },
                  credentials: {
                    gcs: {
                      credentials,
                    },
                  },
                  datasets,
                  serviceProvider,
                },
              },
            });
          };
          reader.readAsText(credentialsFile);
        } catch (error) {
          throw new Error(error as string);
        }
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <FormProvider {...permutiveHookForm}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.serviceProvider"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.serviceProvider;
              const withError = !isEmpty(fieldError);
              return (
                <FormControl sx={{ mb: 0.5, mt: 1, width: "260px" }}>
                  <FormLabel component="legend">
                    Cloud service Provider
                  </FormLabel>
                  <Select
                    displayEmpty={true}
                    error={withError}
                    size="small"
                    style={{ background: "transparent" }}
                    sx={{
                      "& .MuiSelect-select": {
                        alignItems: "center",
                        display: "flex",
                      },
                    }}
                    variant="standard"
                    {...field}
                  >
                    {Object.values(PermutiveServiceProvider).map((value) => (
                      <MenuItem key={value} value={value}>
                        <ExternalConnectionsIcon
                          connectionType={value as unknown as DataSourceType}
                        />
                        <ListItemText
                          primary={dataSourceTypePresentation.get(
                            value as unknown as DataSourceType
                          )}
                          secondaryTypographyProps={{
                            sx: {
                              maxWidth: "340px",
                              whiteSpace: "normal",
                            },
                          }}
                          sx={{ marginLeft: 0.5 }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  {withError && (
                    <FormHelperText error={withError}>
                      {fieldError?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
          {cloudProviderValue ===
            PermutiveServiceProvider.GoogleCloudStorage && (
            <GoogleCloudStorageFormBucketField />
          )}
          {cloudProviderValue === PermutiveServiceProvider.S3 && (
            <S3BucketFormConfigurationFields />
          )}
        </Box>
        <Box mb={2} mt={2}>
          <Typography color="inherit">
            <FontAwesomeIcon
              icon={faFileLines}
              style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
            />
            Publisher Datasets
          </Typography>
          <Controller
            control={control}
            name="datasets.matchingDatasetName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.datasets?.matchingDatasetName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Matching dataset"
                  placeholder="Example: matching.csv"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="datasets.segmentsDatasetName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.datasets?.segmentsDatasetName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Segments dataset"
                  placeholder="Example: segments.csv"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="datasets.demographicsDatasetName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.datasets?.segmentsDatasetName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Demographics dataset (optional)"
                  placeholder="Example: demographics.csv"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
        <Box mb={2} mt={2}>
          {cloudProviderValue === PermutiveServiceProvider.S3 && (
            <S3BucketFormCredentials />
          )}
          {cloudProviderValue ===
            PermutiveServiceProvider.GoogleCloudStorage && (
            <GoogleCloudStorageFormCredentials />
          )}
        </Box>
      </FormProvider>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button color="inherit" onClick={handleSubmit(handleFormSubmit)}>
          Import
        </Button>
      </Box>
    </Box>
  );
};

PermutiveForm.displayName = "PermutiveForm";

export default memo(PermutiveForm);
