import { Box, List, ListItem } from "@mui/material";
import { memo } from "react";
import { AccessTokensConstructorForm, AccessTokensTile } from "components";
import { useAccessTokensQuery } from "hooks/__generated-new";

const AccessTokensConstructor: React.FC = () => {
  // In future all the tokens going to be visible but for the revoked ones, Chip "Revoked" will be rendered
  // Hide revoked ones for now
  const { data: tokensData } = useAccessTokensQuery();
  const tokens = (tokensData?.myself?.apiTokens?.nodes || []).filter(
    ({ revoked }) => !revoked
  );
  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      {tokens.length > 0 && (
        <List>
          {tokens.map(({ id }) => (
            <div
              key={id}
              style={{ backgroundColor: "white", border: "1px solid #bbb" }}
            >
              <ListItem>
                <AccessTokensTile tokenId={id} />
              </ListItem>
            </div>
          ))}
        </List>
      )}
      <Box sx={{ mt: 1 }}>
        <AccessTokensConstructorForm />
      </Box>
    </Box>
  );
};

AccessTokensConstructor.displayName = "AccessTokensConstructor";

export default memo(AccessTokensConstructor);
