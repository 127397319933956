// TODO: This component needs to be unified with the one from media dcr creation
import { EMAIL_REGEXP } from "constants/index";
import {
  faBullhorn as faBullhornDuotone,
  faNewspaper as faNewspaperDuotone,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  alpha,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  Input,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { uniqBy } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { type AvailablePublisher } from "types/__generated-new";
import {
  type MeasurementConfigurationFormValues,
  type MeasurementPublisherConfiguration,
} from "./model";

const RoleContainer = styled(FormControl)(({ theme }) => ({
  backgroundColor: "transparent",
  borderColor: "rgba(0, 0, 0, 0.12)",
  borderRadius: "6px",
  borderStyle: "solid",
  borderWidth: "1px",
  fontWeight: 500,
  letterSpacing: -0.5,
  marginLeft: 0,
  marginRight: 0,
  padding: theme.spacing(0.5),
  width: "100%",
}));

const RoleLabel = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  color: theme.palette.primary.main,
  display: "flex",
  height: "100%",
  justifyContent: "center",
  padding: theme.spacing(1),
  width: "100%",
}));

const EmailChip = styled(Chip)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px !important`,
  fontWeight: "normal",
}));

interface RolesAndParticipantsProps {
  availablePublishers: AvailablePublisher[];
}

const RolesAndParticipants: React.FC<RolesAndParticipantsProps> = ({
  availablePublishers,
}) => {
  const [tempAdvertiserEmail, setTempAdvertiserEmail] = useState("");
  const { control, getValues, setValue, watch } =
    useFormContext<MeasurementConfigurationFormValues>();
  const advertiserUserEmails = watch("advertiserUserEmails");
  const normalizedArray: { name: string; emails: string[] }[] = useMemo(() => {
    const participants = availablePublishers.map(
      (publisher): MeasurementPublisherConfiguration => {
        const { publisherName, publisherParticipants } = publisher;
        return {
          emails: publisherParticipants ?? [],
          name: publisherName,
        };
      }
    );
    return uniqBy(participants, "name");
  }, [availablePublishers]);
  const handleDeletePublisher = useCallback(
    (option: { name: string; emails: string[] }) => {
      const currentValues = getValues("publishers");
      setValue(
        "publishers",
        currentValues.filter(
          (item: { name: string; emails: string[] }) =>
            item.name !== option.name
        )
      );
    },
    [getValues, setValue]
  );
  const handleDeleteAdvertiser = useCallback(
    (option: string) => {
      const currentValues = getValues("advertiserUserEmails");
      setValue(
        "advertiserUserEmails",
        currentValues.filter((item: string) => item !== option)
      );
    },
    [getValues, setValue]
  );
  return (
    <Grid container={true} item={true} mt={1} spacing={1}>
      <Grid item={true} sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" variant="body1">
          2. Roles and participants
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Alert severity="info" sx={{ mb: 2 }}>
          All participants need to have a Decentriq account. Invite external
          participants via the Admin portal.
        </Alert>
        <RoleContainer>
          <Grid columnSpacing={1} container={true}>
            <Grid item={true} xs={3}>
              <RoleLabel>
                <FontAwesomeIcon fontSize="1.5rem" icon={faNewspaperDuotone} />
                <Typography fontWeight={500} sx={{ ml: 1 }} variant="body2">
                  Publishers
                </Typography>
              </RoleLabel>
            </Grid>
            <Grid alignItems="center" display="flex" item={true} xs={9}>
              <Controller
                control={control}
                name="publishers"
                render={({ field }) => {
                  return (
                    <>
                      <Autocomplete
                        {...field}
                        ChipProps={{ sx: { borderRadius: 0 } }}
                        filterSelectedOptions={true}
                        fullWidth={true}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                          return option.name === value.name;
                        }}
                        multiple={true}
                        onChange={(_, value) => field.onChange(value)}
                        options={normalizedArray}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              startAdornment: (
                                <Box>
                                  {field.value.map(
                                    (
                                      option: {
                                        name: string;
                                        emails: string[];
                                      },
                                      index
                                    ) => (
                                      <EmailChip
                                        key={option.name + index}
                                        label={option.name}
                                        onDelete={() =>
                                          handleDeletePublisher(option)
                                        }
                                        sx={{ mb: 0.25, mr: 1, mt: 0.25 }}
                                        variant="outlined"
                                      />
                                    )
                                  )}
                                </Box>
                              ),
                            }}
                            placeholder="Type in or select publisher"
                            sx={{
                              "&.MuiInput-input": { p: 0, width: "250px" },
                              border: "none",
                            }}
                            variant="standard"
                          />
                        )}
                      />
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </RoleContainer>
      </Grid>
      <Grid item={true} xs={12}>
        <RoleContainer>
          <Grid columnSpacing={1} container={true}>
            <Grid item={true} xs={3}>
              <RoleLabel>
                <FontAwesomeIcon fontSize="1.5rem" icon={faBullhornDuotone} />
                <Typography fontWeight={500} sx={{ ml: 1 }} variant="body2">
                  Advertiser
                </Typography>
              </RoleLabel>
            </Grid>
            <Grid alignItems="center" display="flex" item={true} xs={9}>
              <FormControl
                fullWidth={true}
                sx={{ height: "100%", position: "relative" }}
              >
                <Input
                  autoComplete="off"
                  fullWidth={true}
                  inputProps={{
                    sx: {
                      "&.MuiInput-input": { p: 0, width: "250px" },
                    },
                  }}
                  onBlur={(event) => {
                    const value = event.target.value.trim().toLowerCase();
                    if (!value || !EMAIL_REGEXP.test(value)) return;
                    setValue("advertiserUserEmails", [
                      ...advertiserUserEmails,
                      value,
                    ]);
                    setTempAdvertiserEmail("");
                  }}
                  onChange={(event) =>
                    setTempAdvertiserEmail(event.target.value)
                  }
                  onKeyDown={(event) => {
                    const { target, keyCode } = event || {};
                    const value = target.value.trim().toLowerCase();
                    if (keyCode === 13) {
                      event.preventDefault();
                    } else {
                      return;
                    }
                    if (!value || !EMAIL_REGEXP.test(value)) return;
                    setValue("advertiserUserEmails", [
                      ...advertiserUserEmails,
                      value,
                    ]);
                    setTempAdvertiserEmail("");
                  }}
                  placeholder="Type in user email and press 'Enter'"
                  size="small"
                  startAdornment={
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: "calc(100% - 250px)",
                      }}
                    >
                      {advertiserUserEmails.map((email, index) => (
                        <EmailChip
                          key={email + index}
                          label={email}
                          onDelete={() => handleDeleteAdvertiser(email)}
                          sx={{ mb: 0.25, mr: 1, mt: 0.25 }}
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  }
                  sx={{
                    "&:after": {
                      border: "none",
                    },
                    "&:before": {
                      border: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):after": {
                      border: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      border: "none",
                    },
                    height: "100%",
                  }}
                  value={tempAdvertiserEmail}
                />
              </FormControl>
            </Grid>
          </Grid>
        </RoleContainer>
      </Grid>
    </Grid>
  );
};
RolesAndParticipants.displayName = "RolesAndParticipants";

export default memo(RolesAndParticipants);
