import {
  faPartyHorn,
  faShieldKeyhole,
  faSliders,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  type Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDocsLink } from "hooks";

const useListItemIconStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: theme.spacing(5),
  },
}));

const useWelcomePopupStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  linkButtonIcon: {},
  listIcon: {},
  title: {
    lineHeight: 1.25,
  },
}));

const useLinkButtonStyles = makeStyles()((theme: Theme) => ({
  label: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    borderRadius: 0,
    padding: theme.spacing(1, 3),
  },
}));

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup = ({ open, onClose }: WelcomePopupProps) => {
  const { classes: welcomePopupClasses } = useWelcomePopupStyles();
  const { classes: listItemIconClasses } = useListItemIconStyles();
  const { classes: linkButtonClasses } = useLinkButtonStyles();
  const docsBaseLink = useDocsLink();
  return (
    <Dialog open={open}>
      <DialogContent>
        <Box textAlign="center">
          <FontAwesomeIcon
            className={welcomePopupClasses.icon}
            fixedWidth={true}
            icon={faPartyHorn}
            size="5x"
          />
        </Box>
        <Typography
          align="center"
          className={welcomePopupClasses.title}
          color="textPrimary"
          marginTop={2}
          variant="h6"
        >
          <strong>Welcome on board!</strong>
        </Typography>

        <Typography align="left" component="div" marginTop={2} variant="body2">
          Start using the Decentriq Platform to collaborate and analyse data,
          secured by confidential computing.
          <List dense={true}>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={welcomePopupClasses.listIcon}
                  fixedWidth={true}
                  icon={faShieldKeyhole}
                />
              </ListItemIcon>
              <ListItemText primary="Create a new data clean room" />
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={welcomePopupClasses.listIcon}
                  fixedWidth={true}
                  icon={faSliders}
                />
              </ListItemIcon>
              <ListItemText primary="Define data, computations and participants" />
            </ListItem>
            <ListItem disableGutters={true}>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  className={welcomePopupClasses.listIcon}
                  fixedWidth={true}
                  icon={faUsers}
                />
              </ListItemIcon>
              <ListItemText primary="Publish the data clean room and collaborate" />
            </ListItem>
          </List>
        </Typography>
      </DialogContent>
      <Divider />
      <Button
        classes={linkButtonClasses}
        color="inherit"
        href={`${docsBaseLink}/tutorial`}
        target="_blank"
      >
        <Box display="inline" marginRight="0.5rem">
          Check out the tutorial
        </Box>
        <FontAwesomeIcon
          className={welcomePopupClasses.linkButtonIcon}
          fixedWidth={true}
          icon={faExternalLinkSquare}
        />
      </Button>
      <Divider />
      <Button
        classes={linkButtonClasses}
        color="inherit"
        href="https://www.decentriq.com/end-user-terms"
        target="_blank"
      >
        <Box display="inline" marginRight="0.5rem">
          Read the End User Terms
        </Box>
        <FontAwesomeIcon
          className={welcomePopupClasses.linkButtonIcon}
          fixedWidth={true}
          icon={faExternalLinkSquare}
        />
      </Button>
      <Divider />
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          GET STARTED
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomePopup;
