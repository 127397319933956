import {
  faBallotCheck,
  faLinkSlash,
  faSync,
  faTrashCan,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from "@mui/material";
import { useSafeState } from "ahooks";
import { memo, useMemo, useRef } from "react";
import { usePopupState } from "hooks";
import { type DataIngestionDestination, type DataType } from "models";
import DataNodeDataButton from "./DataNodeDataButton";

interface DataNodeDeprovisionButtonProps {
  id: string;
  hasValidationError?: boolean;
  label: string;
  onDelete?: (replace: boolean) => void;
  onDeprovision: () => Promise<void>;
  openValidationReport?: () => void;
  datasetHash?: string;
  dataType: DataType;
  testing?: boolean;
  ingestionDestination: DataIngestionDestination;
}

enum OptionKey {
  validationReport,
  replace,
  deprovision,
  delete,
}

interface Option {
  label: string;
  icon: IconDefinition;
  tooltip: string;
  key: OptionKey;
  hidden?: boolean;
  isRed?: boolean;
}

const DataNodeDeprovisionButton: React.FC<DataNodeDeprovisionButtonProps> = ({
  id,
  hasValidationError,
  label,
  onDelete,
  onDeprovision,
  openValidationReport,
  datasetHash,
  dataType,
  testing,
  ingestionDestination,
}) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const anchorRef = useRef<HTMLDivElement>(null);
  const [deprovisioning, setDeprovisioning] = useSafeState<boolean>(false);
  const destination =
    ingestionDestination === "dataRoom" ? "data clean room" : "data lab";
  const hasDeletion = Boolean(onDelete);
  const options = useMemo<Option[]>(
    () => [
      ...(hasValidationError
        ? [
            {
              icon: faBallotCheck,
              key: OptionKey.validationReport,
              label: "Validation report",
              tooltip:
                "This will display the validation report of this dataset according to the constraints defined by this table.",
            },
          ]
        : []),
      {
        hidden: true,
        icon: faSync,
        key: OptionKey.replace,
        label: `Replace ${label}`,
        tooltip: `This will deprovision this ${label} from all data clean rooms, delete it and open dialog to provision new one.`,
      },
      {
        icon: faLinkSlash,
        key: OptionKey.deprovision,
        label: `Deprovision ${label}`,
        tooltip: `This will deprovision the ${label} from this ${destination}. The ${label} will not be deleted. To do so, use the "Delete" option, or go to the "Datasets" page from the sidebar menu.`,
      },
      ...(hasDeletion
        ? [
            {
              icon: faTrashCan,
              isRed: true,
              key: OptionKey.delete,
              label: `Delete ${label}`,
              tooltip: `This will deprovision this ${label} from ${
                ingestionDestination === "dataLab" ? "this data lab and" : ""
              } all data clean rooms, then delete it.`,
            },
          ]
        : []),
    ],
    [hasValidationError, label, destination, hasDeletion, ingestionDestination]
  );
  const handleDeprovisioning = async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
    } finally {
      setDeprovisioning(false);
    }
  };
  const handleClick = (key: OptionKey) => {
    switch (key) {
      case OptionKey.deprovision: {
        handleDeprovisioning();
        return;
      }
      case OptionKey.replace: {
        onDelete?.(true);
        return;
      }
      case OptionKey.delete: {
        onDelete?.(false);
        return;
      }
      case OptionKey.validationReport: {
        openValidationReport?.();
        return;
      }
    }
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    key: OptionKey
  ) => {
    close();
    handleClick(key);
  };
  if (deprovisioning) {
    return <CircularProgress color="inherit" size={16} />;
  }
  return (
    <>
      <ButtonGroup
        aria-label="split button"
        disabled={deprovisioning}
        ref={anchorRef}
      >
        <DataNodeDataButton
          dataType={dataType}
          datasetHash={datasetHash}
          id={id}
          onClick={() => close()}
          testing={testing}
          withActions={false}
        />
        <Button
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="menu"
          color={testing ? "secondary" : "primary"}
          onClick={open}
          size="small"
          sx={{ "&.MuiButtonGroup-grouped": { minWidth: "auto" } }}
          variant="outlined"
        >
          <FontAwesomeIcon fixedWidth={true} icon={faCaretDownSolid} />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        marginThreshold={8}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList
          autoFocusItem={true}
          dense={true}
          disablePadding={true}
          id="split-button-menu"
        >
          {options
            .filter((option) => !option.hidden)
            .map((option, index) => (
              <Tooltip key={index} placement="left" title={option.tooltip}>
                <MenuItem
                  onClick={(event) => handleMenuItemClick(event, option.key)}
                  sx={(theme) => ({
                    color: option.isRed ? theme.palette.error.main : undefined,
                  })}
                >
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={option.icon}
                    style={{ marginRight: ".5rem" }}
                  />{" "}
                  {option.label}
                </MenuItem>
              </Tooltip>
            ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default memo(DataNodeDeprovisionButton);
