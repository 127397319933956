import { useAuth0 } from "@auth0/auth0-react";
import { useUserByEmailQuery } from "hooks/__generated-new";
import { WorkerTypeShortName } from "types/__generated-new";

// Enable post compute node for users with special flag set in auth0 app_metadata
// Enable post compute for all decentriq users
export default function useIsComputeEnabled() {
  const { user } = useAuth0();
  const { data: userData } = useUserByEmailQuery({
    skip: !user?.email,
    variables: {
      email: user!.email!,
    },
  });
  const availableWorkerTypes = (
    userData?.userByEmail?.organization?.workerTypes?.nodes || []
  ).map(({ shortName }) => shortName);
  const isPostComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Post
  );
  const isPreviewComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Preview
  );
  const isS3ComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.S3
  );
  const isMatchComputeEnabled = availableWorkerTypes.includes(
    // Match node works on Python worker. Change to .Match as soon as separate worker is introduced
    WorkerTypeShortName.Python
  );
  const isSqlComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Sql
  );
  const isSqliteComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Sqlite
  );
  const isPythonComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Python
  );
  const isRComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.R
  );
  const isSyntheticComputeEnabled = availableWorkerTypes.includes(
    WorkerTypeShortName.Synthetic
  );
  return {
    availableWorkerTypes,
    isMatchComputeEnabled,
    isPostComputeEnabled,
    isPreviewComputeEnabled,
    isPythonComputeEnabled,
    isRComputeEnabled,
    isS3ComputeEnabled,
    isSqlComputeEnabled,
    isSqliteComputeEnabled,
    isSyntheticComputeEnabled,
  };
}
