import { DqTable, InfoTooltip } from "@decentriq/components";
import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo as faCircleInfoSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { type MRT_ColumnDef } from "material-react-table";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Chip } from "components";
import { usePublishedMediaDataRoom } from "contexts";
import { useAudienceOverlapInsightsData } from "features/mediaDataRoom/hooks";
import {
  getAudiencePresentation,
  type OverlapInsight,
  sortAudiences,
} from "features/mediaDataRoom/index";
import {
  formatBasePropensity,
  formatNetPropensity,
  formatOverlapPropensity,
} from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useAudienceInsightsPublisherStatisticsQuery,
  useAudienceTypesQuery,
  useMediaDataRoomDatasetHashesQuery,
} from "hooks/__generated-new";
import AudienceOverlapInsightsCharts from "../AudienceOverlapInsightsCharts/AudienceOverlapInsightsCharts";

const NoSegmentsAlert = styled(Alert)(({ theme }) => ({
  "& .MuiAlert-icon": {
    "& > svg": {
      margin: 1,
    },
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    lineHeight: "14px",
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0),
  },
  "& .MuiAlert-message": {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    gap: "0.25rem",
    justifyContent: "space-between",
    lineHeight: "16px",
    padding: theme.spacing(0.5, 0),
  },
  borderRadius: 0,
  padding: theme.spacing(0.5, 1),
}));

const OverlapInsightsContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    [breakpoints.down("xl")]: {
      flexDirection: "column-reverse",
      gap: spacing(2),
    },
    display: "flex",
    flex: 1,
    gap: spacing(6),
    marginTop: spacing(2),
    maxHeight: "100%",
    paddingBottom: spacing(2),
    width: "100%",
  })
);

interface AudienceOverlapInsightsProps {
  id: string;
  [key: string]: any;
}

const AudienceOverlapInsights: React.FC<AudienceOverlapInsightsProps> = memo(
  ({ id }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { dcrHash, driverAttestationHash, advertiserOrganization } =
      usePublishedMediaDataRoom();
    const [selectedAudienceType, setSelectedAudienceType] =
      useState<string>("");
    const { data } = useAudienceTypesQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        input: {
          dataRoomId: dcrHash,
          driverAttestationHash,
        },
      },
    });
    const audienceTypes = useMemo(
      () =>
        sortAudiences(data?.mediaRetrieveAudienceTypes?.audienceTypes || []),
      [data]
    );
    useEffect(() => {
      setSelectedAudienceType(audienceTypes[0]);
    }, [audienceTypes]);

    const { overlapInsights, loading: isOverlapInsightsLoading } =
      useAudienceOverlapInsightsData(selectedAudienceType);

    const exportOverlapInsights = useCallback(() => {
      if (!overlapInsights.length) {
        return;
      }
      const fileContent = overlapInsights.reduce(
        (
          csv,
          { segment, basePropensity, overlapPropensity, netPropensity }
        ) => {
          return `${csv}\n${segment},${formatNetPropensity(
            netPropensity
          )},${formatBasePropensity(basePropensity)},${formatOverlapPropensity(
            overlapPropensity
          )}}`;
        },
        "Segment,Share in Publisher audience,Share in overlap,Affinity"
      );
      const fileName = `Top_Affinity_Segments_${advertiserOrganization?.name}_${selectedAudienceType}_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}.csv`;
      const file = new File([fileContent], fileName, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, [overlapInsights, advertiserOrganization?.name, selectedAudienceType]);

    const overlapInsightsColumns = useMemo<MRT_ColumnDef<OverlapInsight>[]>(
      () => [
        {
          Header: ({ column }) => (
            <div title="">
              {column.columnDef.header}
              <InfoTooltip tooltip="Segments in the Publisher audience" />
            </div>
          ),
          accessorKey: "segment",
          header: "Segment",
          id: "segment",
        },
        {
          Cell: ({ cell }) => {
            const netPropensityValue = cell.getValue() as number;
            return netPropensityValue > 1 ? (
              <Chip
                label={formatNetPropensity(netPropensityValue)}
                sx={{
                  "& .MuiChip-label": { color: "#289090", padding: "0 8px" },
                  background: "rgba(40, 144, 144, 0.08)",
                }}
              />
            ) : (
              <span style={{ marginLeft: "4px" }}>
                {formatNetPropensity(netPropensityValue)}
              </span>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="This is the ratio “Share in overlap” / “Share in Publisher audience”. The higher this value, the more likely it is that a person in this segment is interested in the Advertiser’s product compared to a random person in the Publisher’s audience." />
            </div>
          ),
          accessorKey: "netPropensity",
          header: "Affinity",
          id: "netPropensity",
        },
        {
          Cell: ({ cell }) => formatBasePropensity(cell.getValue() as number),
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="This is the share of individuals in this segment as a fraction of all individuals in the Publisher’s dataset." />
            </div>
          ),
          accessorKey: "basePropensity",
          header: "Share in Publisher audience",
          id: "basePropensity",
        },
        {
          Cell: ({ cell }) =>
            formatOverlapPropensity(cell.getValue() as number),
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <InfoTooltip tooltip="This is the share of individuals in this segment as a fraction of all individuals in the overlap." />
            </div>
          ),
          accessorKey: "overlapPropensity",
          header: "Share in overlap",
          id: "overlapPropensity",
        },
      ],
      []
    );
    const { data: datasetHashesData, loading: datasetHashesLoading } =
      useMediaDataRoomDatasetHashesQuery({
        fetchPolicy: "cache-and-network",
        onError: (error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              `Media data clean room hashes could not be determined.`
            )
          );
        },
        variables: { id },
      });
    const { publishedMediaDataRoom } = datasetHashesData || {};
    const { publisherDatasetHash, advertiserDatasetHash } =
      publishedMediaDataRoom || {};
    const {
      data: audienceInsightsPublisherStatisticsData,
      loading: audienceInsightsPublisherStatisticsLoading,
    } = useAudienceInsightsPublisherStatisticsQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Publisher audience insights could not be retrieved."
          )
        );
      },
      // TODO: Remove dataset hashes from `skip` and handle error instead
      skip:
        !id ||
        !driverAttestationHash ||
        !publisherDatasetHash ||
        !advertiserDatasetHash,
      variables: {
        input: {
          dataRoomId: id,
          driverAttestationHash,
        },
      },
    });
    const { mediaCalculateStatistics } =
      audienceInsightsPublisherStatisticsData || {};
    const { publisherNumberOfSegments } = mediaCalculateStatistics || {};
    if (
      isOverlapInsightsLoading ||
      datasetHashesLoading ||
      audienceInsightsPublisherStatisticsLoading
    ) {
      return (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" size={64} thickness={1} />
        </Box>
      );
    }
    if (overlapInsights.length) {
      return (
        <Box
          sx={{
            background: "white",
            display: "flex",
            mt: 3,
            pb: 5,
            pt: 2,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                alignItems: "flex-start",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 1,
              }}
            >
              <Typography gutterBottom={false} variant="h6">
                Top affinity segments
              </Typography>
              {publisherNumberOfSegments !== 0 && (
                <Tooltip
                  disableFocusListener={true}
                  disableTouchListener={true}
                  placement="top-start"
                  title="Export selected audience to CSV"
                >
                  <div style={{ display: "flex" }}>
                    <IconButton
                      onClick={exportOverlapInsights}
                      sx={{ padding: 0.5 }}
                    >
                      <FontAwesomeIcon
                        fixedWidth={true}
                        icon={faDownloadRegular}
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </Box>
            {publisherNumberOfSegments === 0 ? (
              <NoSegmentsAlert
                icon={
                  <FontAwesomeIcon fixedWidth={true} icon={faCircleInfoSolid} />
                }
                severity="info"
              >
                There is no segment with sufficient overlap to display. Increase
                the sizes of the segments and audiences.
              </NoSegmentsAlert>
            ) : (
              <Fragment>
                <FormControl sx={{ maxWidth: "350px", minWidth: "350px" }}>
                  <FormLabel component="legend" sx={{ paddingLeft: "2px" }}>
                    Advertiser audience
                  </FormLabel>
                  <Select
                    disabled={audienceTypes.length <= 2}
                    displayEmpty={true}
                    fullWidth={true}
                    onChange={(event) => {
                      setSelectedAudienceType(event.target.value as string);
                    }}
                    renderValue={getAudiencePresentation}
                    size="small"
                    sx={{
                      ...(audienceTypes.length <= 2
                        ? {
                            "& .MuiSelect-icon": { display: "none" },
                            "&.Mui-disabled": { background: "white" },
                          }
                        : {}),
                      background: "transparent",
                    }}
                    value={selectedAudienceType || ""}
                    variant="standard"
                  >
                    {audienceTypes.map((audience) => (
                      <MenuItem key={audience} value={audience}>
                        <ListItemText
                          primary={getAudiencePresentation(audience)}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <OverlapInsightsContainer>
                  <DqTable
                    columns={overlapInsightsColumns}
                    data={overlapInsights}
                    localization={{
                      noRecordsToDisplay:
                        "The selected Advertiser audience is too small",
                    }}
                    muiTablePaperProps={{
                      sx: { flex: 3 },
                    }}
                  />
                  <AudienceOverlapInsightsCharts
                    selectedAudienceType={selectedAudienceType}
                  />
                </OverlapInsightsContainer>
              </Fragment>
            )}
          </Box>
        </Box>
      );
    }
    return null;
  }
);

AudienceOverlapInsights.displayName = "AudienceOverlapInsights";

export default AudienceOverlapInsights;
