import {
  faCheck as faCheckSolid,
  type IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { memo } from "react";

export type StepTitleProps = {
  icon: IconDefinition;
  isComplete: boolean;
  title: string;
};

const StepTitle: React.FC<StepTitleProps> = memo(
  ({ icon, isComplete = false, title = "" }) => {
    // TODO: Maybe use `styled` for these
    const color = isComplete ? "#289090" : "inherit";
    return (
      <Typography sx={{ color, p: 1 }}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={isComplete ? faCheckSolid : icon}
          style={{ color, marginRight: "0.5em" }}
        />
        {title}
      </Typography>
    );
  }
);
StepTitle.displayName = "StepTitle";

export default StepTitle;
