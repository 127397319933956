import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useSendErrorReportMutation } from "hooks/__generated-new";
import { type ErrorReportPayloadInput } from "types/__generated-new";
import { logError } from "utils";

export type ReportErrorInput = ErrorReportPayloadInput;

type ReportErrorHookResult = (
  report: ReportErrorInput,
  options?: { silent: boolean }
) => Promise<void>;

const useReportError = (): ReportErrorHookResult => {
  const { enqueueSnackbar } = useSnackbar();
  const [sendErrorReportMutation] = useSendErrorReportMutation({
    onError: (error) =>
      enqueueSnackbar(`Unable to send error report: ${error?.message}`, {
        persist: true,
        variant: "error",
      }),
  });
  const handleReport = useCallback(
    async (report: ReportErrorInput, options?: { silent: boolean }) => {
      logError(report);
      await sendErrorReportMutation({
        variables: {
          input: {
            report: {
              ...report,
              errorContext: [
                ...report.errorContext,
                {
                  content: options?.silent ? "no" : "yes",
                  name: "user consent asked",
                },
              ],
            },
          },
        },
      }).then(() => {
        if (!options?.silent) {
          enqueueSnackbar(
            `Thank you for reporting this issue to Decentriq. Our support team will investigate it and contact you if necessary.`
          );
        }
      });
    },
    [sendErrorReportMutation, enqueueSnackbar]
  );
  return handleReport;
};

export default useReportError;
