import { useApolloClient } from "@apollo/client";
import { useSafeState } from "ahooks";
import { type SnackbarKey } from "notistack";
import { useCallback, useState } from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { CreateDatasetImportDocument } from "types/__generated-new";
import { parseMediaDataRoomError } from "utils";
import { getLookalikeAudienceCacheKey } from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

interface StoreAsDatasetHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  audienceType: string;
  reach: number;
}

export const useStoreAsDataset = ({
  dataRoomId,
  driverAttestationHash,
  audienceType,
  reach,
}: StoreAsDatasetHookPayload) => {
  const [loading, setLoading] = useSafeState(false);
  const { client, sessionManager } = useApiCore();
  const apolloClient = useApolloClient();

  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const handleSaveAsDataSet = useCallback(async () => {
    setErrorSnackbarId((snackbarId) => {
      if (snackbarId) {
        closeSnackbar(snackbarId);
      }
      return undefined;
    });
    setLoading(true);
    try {
      const cacheKey = await getLookalikeAudienceCacheKey({
        audienceType,
        client,
        dataRoomId,
        driverAttestationHash,
        // TODO remove this
        forceRecompute: false,
        reach,
        sessionManager,
      });

      if (cacheKey == null) {
        throw new Error(
          "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
        );
      }
      apolloClient.mutate({
        mutation: CreateDatasetImportDocument,
        variables: {
          input: {
            compute: {
              computeNodeId: "get_lookalike_audience",
              dataRoomId,
              driverAttestationHash,
              importFileWithName: "audience_users.csv",
              isHighLevelNode: false,
              parameters: [
                {
                  computeNodeName: "requested_audience.json",
                  content: JSON.stringify({ audienceType, reach }),
                },
              ],
              renameFileTo: `${audienceType} - ${reach}%`,
              shouldImportAllFiles: false,
              shouldImportAsRaw: false,
            },
            datasetName: `${audienceType} - ${reach}%`,
          },
        },
      });
    } catch (error) {
      setErrorSnackbarId(
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            parseMediaDataRoomError(error),
            "Unable to save audience as dataset"
          )
        )
      );
    } finally {
      setLoading(false);
    }
  }, [
    apolloClient,
    audienceType,
    client,
    closeSnackbar,
    dataRoomId,
    driverAttestationHash,
    enqueueSnackbar,
    reach,
    sessionManager,
    setErrorSnackbarId,
    setLoading,
  ]);

  return {
    loading,
    saveAsDataSet: handleSaveAsDataSet,
  };
};
