import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, type Theme } from "@mui/material";
import { memo, type SyntheticEvent, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useGetDatasetWithMetadata } from "features/datasets/hooks";
import {
  DatasetPopupInfoTabPanel,
  DatasetPopupSchemaTabPanel,
  DatasetPopupStatisticsTabPanel,
} from "./components";

const useTabListStyles = makeStyles()((theme: Theme) => ({
  indicator: {
    backgroundColor: "#289090",
  },
  root: {
    minHeight: "30px",
  },
}));

const useTabStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.Mui-selected": {
      color: "#289090",
    },
    flex: 1,
    maxWidth: "50%",
    minHeight: "30px",
    minWidth: "auto",
    padding: theme.spacing(0.5, 6),
    transition: "font-weight 0.05s",
  },
}));

type DatasetTab = "info" | "schema" | "statistics";

interface DatasetTabsProps {
  datasetHash: string;
  skipFetching: boolean;
}

const DatasetTabs: React.FC<DatasetTabsProps> = ({
  datasetHash,
  skipFetching,
}) => {
  const { classes: tabListClasses } = useTabListStyles();
  const { classes: tabClasses } = useTabStyles();
  const { dataset, metadata } = useGetDatasetWithMetadata({
    datasetHash,
    skipFetching,
  });
  const [selectedTab, setSelectedTab] = useState<DatasetTab>("info");
  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newTab: DatasetTab) => {
      setSelectedTab(newTab);
    },
    [setSelectedTab]
  );
  return (
    <TabContext value={selectedTab}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            classes={tabListClasses}
            onChange={handleChange}
            textColor="inherit"
            variant="scrollable"
          >
            <Tab classes={tabClasses} label="Details" value="info" />
            {metadata && (
              <Tab classes={tabClasses} label="Schema" value="schema" />
            )}
            {dataset?.statistics && (
              <Tab classes={tabClasses} label="Statistics" value="statistics" />
            )}
          </TabList>
        </Box>
        <Box display="flex" flex={1} flexDirection="column" overflow="auto">
          <TabPanel
            sx={{
              "&:not([hidden])": {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                p: 0,
              },
            }}
            value="info"
          >
            <Box sx={{ mt: 2 }}>
              <DatasetPopupInfoTabPanel
                datasetHash={datasetHash!}
                visible={true}
              />
            </Box>
          </TabPanel>
          {metadata && (
            <TabPanel
              sx={{
                "&:not([hidden])": {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflow: "auto",
                  p: 0,
                },
              }}
              value="schema"
            >
              <Box sx={{ mt: 2 }}>
                <DatasetPopupSchemaTabPanel metadata={metadata} />
              </Box>
            </TabPanel>
          )}
          {dataset?.statistics && (
            <TabPanel
              sx={{
                "&:not([hidden])": {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflow: "auto",
                  p: 0,
                },
              }}
              value="statistics"
            >
              <DatasetPopupStatisticsTabPanel
                statistics={dataset!.statistics}
              />
            </TabPanel>
          )}
        </Box>
      </Box>
    </TabContext>
  );
};
DatasetTabs.displayName = "DatasetTabs";

export default memo(DatasetTabs);
