import { type ExternalConnectionFormProps } from "features/datasets/components/ExternalConnections";
import { type CreateDatasetImportMutationVariables } from "types/__generated-new";

export enum EXTERNAL_DATA_IMPORT_STEPS {
  SELECT_SOURCE,
  SOURCE_FORM,
  IMPORT_STATUS,
}

export type ImportExternalDataFormProps = {
  submitFormHandler: (payload: CreateDatasetImportMutationVariables) => void;
} & ExternalConnectionFormProps;
