import { exceptions } from "@decentriq/utils";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  styled,
  Typography,
} from "@mui/material";
import { useSelections } from "ahooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JsonEditorField } from "components";
import {
  DataLabDataNodeActions,
  DataLabDownloadValidationReportButton,
  DataLabValidationButton,
  DataLabValidationDetails,
  useDataLabContext,
  useDataLabDataNodeActions,
  useDataLabSnackbar,
} from "features/dataLabs";
import {
  DataLabDataNodeType,
  dataLabNodeColumnUniqueness,
  getDataLabDataNodeColumnsMap,
} from "features/dataLabs/models";
import {
  DataNodeConstructorMode,
  DataNodeUploadDataDialog,
} from "features/dataNodes";
import { UniquenessConstructor } from "features/dataNodes/components/DataNodeConstructor/components";
import { UNIQUENESS_ENABLED } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructor";
import { DataNodeConstructorParamsWrapper } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorParamsWrapper";
import { TableNodeColumnConstructor } from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructor";
import TableNodeColumnConstructorHeader from "features/dataNodes/components/DataNodeConstructor/TableNodeColumnConstructorHeader";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { CommonSnackbarOrigin, useReportError } from "hooks";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:first-of-type:not(:last-of-type)": {
    marginBottom: 0,
  },
  alignItems: "stretch",
  background: "white",
  border: ".5px solid #bbb",
  borderRadius: theme.shape.borderRadius,
  flexDirection: "column",
  padding: theme.spacing(0.5),
}));

interface DataLabNodeItemProps {
  description?: string;
  title: string;
  toggleCollapse?: () => void;
  isCollapsed?: boolean;
  collapseContent?: React.ReactNode;
  actions: React.ReactNode;
  content?: React.ReactNode;
}

const DataLabNodeItem: React.FC<DataLabNodeItemProps> = ({
  description,
  title,
  toggleCollapse,
  isCollapsed,
  collapseContent,
  actions,
  content,
}) => {
  return (
    <Box sx={{ display: "flex", flex: 1, mb: 1, padding: 0 }}>
      <StyledListItem>
        <Box sx={{ width: "100%" }}>
          <Grid container={true} spacing={1}>
            <Grid
              alignItems="center"
              container={true}
              item={true}
              onClick={toggleCollapse}
              style={{
                cursor: "pointer",
                flexWrap: "nowrap",
              }}
              xs={12}
            >
              {collapseContent && (
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={isCollapsed ? faCaretRightSolid : faCaretDownSolid}
                  style={{ cursor: "pointer", margin: "8px 4px" }}
                />
              )}
              <Box
                sx={{
                  flex: 1,
                  marginLeft: !collapseContent ? "8px" : undefined,
                  width: 0,
                }}
              >
                <Typography component="span" variant="body1">
                  {title}
                </Typography>
                {description && (
                  <Typography component="span" variant="body2">
                    {" "}
                    {description}
                  </Typography>
                )}
              </Box>
              {actions}
            </Grid>
          </Grid>
        </Box>
        {collapseContent && (
          <Collapse
            in={!isCollapsed}
            style={{
              marginLeft: 6,
              marginRight: 6,
              marginTop: 10,
            }}
            timeout="auto"
            unmountOnExit={true}
          >
            {collapseContent}
          </Collapse>
        )}
        {content && <Box>{content}</Box>}
      </StyledListItem>
    </Box>
  );
};

const DataLabNodes: React.FC = () => {
  const { isSelected, toggle } = useSelections(
    Object.values(DataLabDataNodeType)
  );
  const {
    dataLab: { data: dataLab },
    computationError,
    datasetValidationErrors,
  } = useDataLabContext();
  const { enqueueSnackbar } = useDataLabSnackbar();
  const reportError = useReportError();
  const {
    activeDataRoomUpload,
    currentUserEmail,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    setTypeForUpload,
    typeForUpload,
    uploadings,
  } = useDataLabDataNodeActions();
  useEffect(() => {
    datasetValidationErrors.forEach((message, dataNodeType) => {
      enqueueSnackbar(`${dataNodeType} dataset failed validation`, {
        action: (
          <Button
            color="inherit"
            onClick={() => setValidationReportDialogReportType(dataNodeType)}
            variant="text"
          >
            View validation report
          </Button>
        ),
        persist: true,
        variant: "error",
      });
    });
  }, [datasetValidationErrors, enqueueSnackbar]);
  const onDataDeprovision = useCallback(
    (type: DataLabDataNodeType) => async () =>
      await handleDataDeprovision(type),
    [handleDataDeprovision]
  );
  const {
    demographicsDataset,
    embeddingsDataset,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    requireSegmentsDataset,
    matchingIdFormat,
    matchingIdHashingAlgorithm,
    numEmbeddings,
    segmentsDataset,
    usersDataset,
  } = dataLab!;
  const dataNodes: {
    type: DataLabDataNodeType;
    manifestHash: string | undefined;
    visible: boolean;
  }[] = useMemo(
    () => [
      {
        manifestHash: usersDataset?.manifestHash,
        type: DataLabDataNodeType.matching,
        visible: true,
      },
      {
        manifestHash: segmentsDataset?.manifestHash,
        type: DataLabDataNodeType.segments,
        visible: requireSegmentsDataset,
      },
      {
        manifestHash: demographicsDataset?.manifestHash,
        type: DataLabDataNodeType.demographics,
        visible: requireDemographicsDataset,
      },
      {
        manifestHash: embeddingsDataset?.manifestHash,
        type: DataLabDataNodeType.embeddings,
        visible: requireEmbeddingsDataset,
      },
    ],
    [
      usersDataset?.manifestHash,
      segmentsDataset?.manifestHash,
      demographicsDataset?.manifestHash,
      embeddingsDataset?.manifestHash,
      requireDemographicsDataset,
      requireEmbeddingsDataset,
      requireSegmentsDataset,
    ]
  );
  const dataLabDataNodeColumnsMap = getDataLabDataNodeColumnsMap({
    matchingIdFormat,
    matchingIdHashingAlgorithm,
    numEmbeddings,
  });
  useEffect(() => {
    if (computationError) {
      enqueueSnackbar(computationError.message, {
        context: computationError.detail,
        persist: true,
        variant: "error",
      });
    }
  }, [computationError, enqueueSnackbar]);
  const validationAndStatisticsDescription = useMemo(() => {
    if (datasetValidationErrors.size > 0) {
      return "One or more datasets have failed validation";
    }
    if (dataLab?.statistics?.errors.length > 0) {
      return "Data quality issue";
    }
    if (computationError?.detail) {
      return "Statistics failed to compute, please retry. If the problem persists, contact support.";
    }
    if (dataLab?.statistics && datasetValidationErrors.size === 0) {
      return "No blocking issues have been detected. This data lab can now be used in Media DCRs.";
    }
    return "";
  }, [
    datasetValidationErrors.size,
    dataLab?.statistics,
    computationError?.detail,
  ]);
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [],
          origin: CommonSnackbarOrigin.DATA_LAB,
        },
        { silent: true }
      );
    },
    [reportError]
  );
  const [
    validationReportDialogReportType,
    setValidationReportDialogReportType,
  ] = useState<DataLabDataNodeType | null>(null);
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      // NOTE: This is inconsistent with other similar `onSelect` routines
      if (!typeForUpload) {
        return;
      }

      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: typeForUpload,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          typeForUpload,
          payload.datasetKeychainItem!
        );
      }
    },
    [handleConnectFromKeychain, handleIngestData, typeForUpload]
  );
  return (
    <>
      <List sx={{ padding: 0 }}>
        {dataNodes.map(({ type, manifestHash, visible }) => {
          if (!visible) {
            return null;
          }
          const key = `${type}-${currentUserEmail}`;
          const columns = dataLabDataNodeColumnsMap.get(type)!;
          const uniqueColumnIds = dataLabNodeColumnUniqueness.get(type)!;
          const columnNameById = new Map<string, string>(
            columns.map(({ id, name }) => [id, name])
          );
          return (
            <DataLabNodeItem
              actions={
                <DataLabDataNodeActions
                  hasValidationError={datasetValidationErrors.has(type)}
                  id={type}
                  isLoading={
                    uploadings[key]?.isLoading || activeDataRoomUpload === key
                  }
                  manifestHash={manifestHash}
                  onDataDeprovision={onDataDeprovision(type)}
                  onUpload={() => setTypeForUpload(type)}
                  openValidationReport={() =>
                    setValidationReportDialogReportType(type)
                  }
                />
              }
              collapseContent={
                <DataNodeConstructorParamsWrapper
                  mode={DataNodeConstructorMode.ACTION}
                >
                  <Box sx={{ padding: (theme) => theme.spacing(1, 0) }}>
                    <TableNodeColumnConstructorHeader
                      isListEmpty={false}
                      readOnly={true}
                    />
                    <TableNodeColumnConstructor
                      columns={columns}
                      columnsOrder={columns.map(({ id }) => id)}
                      isLoading={false}
                      tableNodeId={type}
                    />
                    {UNIQUENESS_ENABLED && (
                      <>
                        <Typography mb={0.5} mt={2}>
                          Additional configuration
                        </Typography>
                        <UniquenessConstructor
                          columnNameById={columnNameById}
                          currentColumns={[]}
                          readOnly={true}
                          uniqueColumnIds={uniqueColumnIds}
                        />
                      </>
                    )}
                  </Box>
                </DataNodeConstructorParamsWrapper>
              }
              description={
                datasetValidationErrors.has(type)
                  ? "Validation failed"
                  : undefined
              }
              isCollapsed={!isSelected(type)}
              key={type}
              title={type}
              toggleCollapse={() => toggle(type)}
            />
          );
        })}
        <DataLabNodeItem
          actions={
            <>
              {dataLab?.statistics && <DataLabDownloadValidationReportButton />}
              <DataLabValidationButton />
            </>
          }
          content={dataLab?.statistics && <DataLabValidationDetails />}
          description={validationAndStatisticsDescription}
          title="Data quality statistics"
        />
      </List>
      <Dialog maxWidth="md" open={validationReportDialogReportType !== null}>
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            Validation report for {validationReportDialogReportType} table
          </span>
          <IconButton onClick={() => setValidationReportDialogReportType(null)}>
            <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <JsonEditorField
            editorOptions={{
              lineNumbers: "off",
              readOnly: true,
              resizable: false,
            }}
            height={400}
            value={
              datasetValidationErrors?.get(validationReportDialogReportType!) ||
              ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => setValidationReportDialogReportType(null)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {!!typeForUpload && (
        <DataNodeUploadDataDialog
          columns={dataLabDataNodeColumnsMap.get(typeForUpload)}
          columnsOrder={dataLabDataNodeColumnsMap
            .get(typeForUpload)!
            .map(({ id }) => id)}
          id={typeForUpload}
          name={typeForUpload}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!typeForUpload}
          uniqueColumnIds={dataLabNodeColumnUniqueness.get(typeForUpload)}
        />
      )}
    </>
  );
};

DataLabNodes.displayName = "DataLabNodes";

export default DataLabNodes;
