import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftDataRoomParticipantsQuery,
  usePublishedDataRoomParticipantsQuery,
} from "hooks/__generated-new";

const useDataRoomParticipants = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished, dataRoomId } = useDataRoom();
  // Draft
  const {
    data: draftData,
    loading: isDraftDataLoading,
    error: draftError,
  } = useDraftDataRoomParticipantsQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Data clean room participants could not be retrieved."
        )
      );
    },
    skip: isPublished,
    variables: { dataRoomId },
  });
  const draftParticipants =
    draftData?.draftDataRoom?.participants?.nodes.map(
      ({ permissions, ...participant }) => ({
        ...participant,
        permissions: permissions?.nodes?.map(({ node }) => ({
          ...node,
        })),
      })
    ) || [];

  // Published
  const {
    data: publishedData,
    loading: isPublishedDataLoading,
    error: publishedError,
  } = usePublishedDataRoomParticipantsQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Can't fetch data clean room participants"
        )
      );
    },
    skip: !isPublished,
    variables: { dataRoomId },
  });
  const publishedParticipants =
    publishedData?.publishedDataRoom?.participants?.map(
      ({ permissions, ...participant }) => ({
        ...participant,
        permissions: permissions?.map(({ node }) => ({ ...node })),
      })
    ) || [];

  return {
    error: publishedError || draftError,
    isLoading: isPublished ? isPublishedDataLoading : isDraftDataLoading,
    participants: isPublished ? publishedParticipants : draftParticipants,
  };
};

export default useDataRoomParticipants;
