import { InfoTooltip } from "@decentriq/components";
import { memo, useCallback, useMemo } from "react";
import { Checkbox } from "components";
import { type CollaboratingOrganizationFilter } from "types/__generated-new";

type ShowOwnedByOrganizationCheckboxProps = {
  setQueryFilter: (filter: CollaboratingOrganizationFilter) => void;
  checked: boolean;
};

const ShowOwnedByOrganizationCheckbox: React.FC<
  ShowOwnedByOrganizationCheckboxProps
> = ({ setQueryFilter, checked }) => {
  const handleOwnedByOrganizationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // If checkbox is not checked, value should be passed as null, not false, for proper logic
      setQueryFilter({ isOwnedByOrganization: checked || null });
    },
    [setQueryFilter]
  );

  const label = useMemo(
    () => (
      <div>
        Only DCRs created by my organization
        <InfoTooltip
          tooltip={
            <>
              If checked, this applies two filter
              <br />
              #1 This only counts organisations from data clean rooms created by
              someone from your organisation.
              <br />
              #2 This only counts data clean rooms which have been activated.
            </>
          }
        />
      </div>
    ),
    []
  );

  return (
    <Checkbox
      checked={checked}
      label={label}
      onChange={handleOwnedByOrganizationChange}
      size="small"
      sx={{ margin: 0, marginLeft: 2, width: "fit-content" }}
    />
  );
};

ShowOwnedByOrganizationCheckbox.displayName = "ShowOwnedByOrganizationCheckbox";

export default memo(ShowOwnedByOrganizationCheckbox);
