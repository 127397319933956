import { type ExternalConnectionFormProps } from "features/datasets/components/ExternalConnections";
import { type ExportDatasetInput } from "types/__generated-new";

export enum EXPORT_DATASET_STEPS {
  SELECT_TARGET,
  TARGET_FORM,
  EXPORT_STATUS,
}

export type ExportDatasetTargetFormPayload = {
  input: Omit<ExportDatasetInput, "manifestHash">;
};

export type ExportDatasetFormProps = {
  submitFormHandler: (payload: ExportDatasetTargetFormPayload) => void;
} & ExternalConnectionFormProps;
