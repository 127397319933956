import { useApolloClient } from "@apollo/client";
import { exceptions } from "@decentriq/utils";
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { type TableCellProps } from "react-virtualized";
import { VirtualizedTable } from "components";
import { useApiCore, usePublishedDataRoom } from "contexts";
import { ComputationResultPane } from "features/computeNode";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { useFetchComputeJobResultMutation } from "hooks/__generated-new";
import {
  dataRoomTablePrimitiveTypePresentation,
  PublishedComputeNodeTypeNames,
} from "models";
import {
  CompletePublishedDataRoomDocument,
  ComputeJobAutoFetching,
  type PublishedNodesTypenamesQuery,
  PublishedPreviewNodeQuotasFragment,
  WorkerTypeShortName,
} from "types/__generated-new";
import { decodeComputationResult, type TableScheme } from "utils/apicore";

interface ComputeNodeResultProps {
  computeNodeId: string;
  jobId: string;
  computationTypename: WorkerTypeShortName;
  autoFetching: ComputeJobAutoFetching;
  dcrHash?: string;
  driverAttestationHash?: string;
}

interface ComputeNodeResultPreviewProps
  extends Omit<ComputeNodeResultProps, "autoFetching"> {}

const ComputeNodeResultPreview: React.FC<ComputeNodeResultPreviewProps> = ({
  jobId,
  computationTypename,
  computeNodeId,
  ...restOfProps
}) => {
  const [result, setResult] = useState<Uint8Array | undefined>();
  const publishedDcrContextValue = usePublishedDataRoom();
  const { cache } = useApolloClient();
  const { sessionManager } = useApiCore();
  useEffect(
    () => setResult(undefined),
    [setResult, publishedDcrContextValue.testing]
  );
  const dcrHash = restOfProps.dcrHash || publishedDcrContextValue.dcrHash;
  const driverAttestationHash =
    restOfProps.driverAttestationHash ||
    publishedDcrContextValue.driverAttestationHash;
  const { store } = useApiCore();
  const { enqueueSnackbar: enqueueDataRoomSnackbarNoReport } =
    useDataRoomSnackbar({ withReportOnError: false });
  const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
  const [fetchResult, { loading }] = useFetchComputeJobResultMutation();
  useEffect(() => {
    fetchResult({
      variables: {
        input: {
          computeNodeId,
          dcrHash,
          driverAttestationHash,
          jobId,
        },
      },
    })
      .then(({ data }) => {
        const storeRef = data?.retrieveComputeJobResult?.result;
        if (!storeRef) {
          return;
        }
        setResult(store.get<Uint8Array>(storeRef));
      })
      .then(async () => {
        const data = cache.readQuery<PublishedNodesTypenamesQuery>({
          query: CompletePublishedDataRoomDocument,
          variables: { id: dcrHash },
        });
        if (
          !data?.publishedDataRoom ||
          !data.publishedDataRoom.publishedNodes.some(
            ({ __typename }) =>
              __typename === PublishedComputeNodeTypeNames.PublishedPreviewNode
          )
        ) {
          return;
        }
        const sdkSession = await sessionManager.get({
          driverAttestationHash,
        });
        const airlockQuotas =
          await sdkSession.retrieveUsedAirlockQuotas(dcrHash);
        data.publishedDataRoom.publishedNodes
          .filter(
            ({ __typename, id }) =>
              __typename ===
                PublishedComputeNodeTypeNames.PublishedPreviewNode &&
              airlockQuotas.has(id)
          )
          .forEach(({ id }) => {
            const airlockQuota = airlockQuotas.get(id);
            const { limit = 0, used = 0 } = airlockQuota!;
            cache.writeFragment<PublishedPreviewNodeQuotasFragment>({
              data: {
                id,
                remainingQuotaBytes: limit - used,
                totalQuotaBytes: limit,
                usedQuotaBytes: used,
              },
              fragment: PublishedPreviewNodeQuotasFragment,
              id: cache.identify({
                __typename: PublishedComputeNodeTypeNames.PublishedPreviewNode,
                commitId: null,
                dcrHash,
                driverAttestationHash,
                id,
              }),
            });
          });
      })
      .catch((error) => {
        if (error instanceof exceptions.DataRoomComputationOutOfQuotaError) {
          enqueueDataRoomSnackbarNoReport(
            "Airlock limit for this node has been reached",
            { persist: true, variant: "error" }
          );
        } else {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Failed to get result")
          );
        }
      });
  }, [
    computeNodeId,
    dcrHash,
    driverAttestationHash,
    store,
    fetchResult,
    enqueueDataRoomSnackbarNoReport,
    enqueueDataRoomSnackbar,
    jobId,
    sessionManager,
    cache,
  ]);
  if (loading) {
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "inline-flex",
          marginLeft: 1,
          marginTop: 1.5,
          width: "100%",
        }}
      >
        <CircularProgress size={16} sx={{ marginRight: 1 }} />
        <Typography>Retrieving computation results</Typography>
      </Box>
    );
  }
  return result ? (
    <ComputationResultPane
      bytes={result}
      computationTypename={computationTypename || "sql"}
      computeNodeId={computeNodeId}
      hasRunComputation={true}
      isLoading={false}
    />
  ) : null;
};

interface ComputeNodeSchemaPreviewProps
  extends Omit<
    ComputeNodeResultProps,
    "autoFetching" | "computationTypename"
  > {}

const ComputeNodeSchemaPreview: React.FC<ComputeNodeSchemaPreviewProps> = ({
  jobId,
  computeNodeId,
  dcrHash,
  driverAttestationHash,
}) => {
  const [schema, setSchema] = useState<TableScheme | undefined>();
  const publishedDcr = usePublishedDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { store } = useApiCore();
  const [fetchResult, { loading }] = useFetchComputeJobResultMutation();
  useEffect(() => {
    fetchResult({
      variables: {
        input: {
          computeNodeId,
          dcrHash: dcrHash || publishedDcr.dcrHash,
          driverAttestationHash:
            driverAttestationHash || publishedDcr.driverAttestationHash,
          jobId,
        },
      },
    })
      .then(async ({ data }) => {
        const storeRef = data?.retrieveComputeJobResult?.result;
        if (storeRef) {
          const schema = store.get<Uint8Array>(storeRef)!;
          const tableSchema = (await decodeComputationResult(schema))[1];
          setSchema(tableSchema);
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, "Failed to get result")
        );
      });
  }, [
    computeNodeId,
    dcrHash,
    driverAttestationHash,
    store,
    fetchResult,
    enqueueSnackbar,
    jobId,
    publishedDcr,
  ]);
  if (loading) {
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "inline-flex",
          marginLeft: 1,
          marginTop: 1.5,
          width: "100%",
        }}
      >
        <CircularProgress size={16} sx={{ marginRight: 1 }} />
        <Typography>Retrieving computation results</Typography>
      </Box>
    );
  }
  return schema && schema.namedColumns.length ? (
    <div data-testid="computation_results" style={{ marginTop: "1rem" }}>
      <VirtualizedTable
        cellRenderer={({ cellData, columnIndex }: TableCellProps) => {
          if (columnIndex === 0) {
            return cellData;
          }
          return dataRoomTablePrimitiveTypePresentation.get(
            cellData.primitiveType
          );
        }}
        columns={[
          {
            dataKey: "name",
            label: "Column name",
          },
          {
            dataKey: "columnType",
            label: "Data type",
          },
        ]}
        maxRowsShown={5}
        rowCount={schema!.namedColumns.length}
        rowGetter={({ index }) => schema!.namedColumns[index]}
        withHeader={true}
      />
    </div>
  ) : null;
};

const ComputeNodeResult: React.FC<ComputeNodeResultProps> = ({
  autoFetching,
  ...rest
}) => {
  switch (autoFetching) {
    case ComputeJobAutoFetching.Result:
    case ComputeJobAutoFetching.TestResult:
    case ComputeJobAutoFetching.AllTests:
      return <ComputeNodeResultPreview {...rest} />;
    case ComputeJobAutoFetching.Validation:
    case ComputeJobAutoFetching.AllValidations:
      return rest.computationTypename === WorkerTypeShortName.Python ||
        rest.computationTypename === WorkerTypeShortName.R ? (
        <></>
      ) : (
        <ComputeNodeSchemaPreview {...rest} />
      );
    default:
      return <></>;
  }
};

export default ComputeNodeResult;
