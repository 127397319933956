import { export_dataset } from "@decentriq/core";
import { type ApiCoreContextInterface } from "contexts";
import {
  type DatasetExport,
  type DatasetExportResult,
} from "types/__generated-new";
import { getLatestEnclaveSpecsPerType } from "utils/apicore";
import { type LocalResolverContext } from "../../models";

export function makeDatasetExportResolvers(
  client: ApiCoreContextInterface["client"],
  _sessionManager: ApiCoreContextInterface["sessionManager"],
  _store: ApiCoreContextInterface["store"]
) {
  return {
    result: async (
      parent: Partial<DatasetExport>,
      _args: null,
      _context: LocalResolverContext,
      _info: any
    ): Promise<DatasetExportResult> => {
      if (parent.id === undefined) {
        throw new Error(
          "In order to query the result of a DatasetExport, its id needs to be available"
        );
      }

      const enclaveSpecifications = await client.getEnclaveSpecifications();
      const enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
      const [auth] = await client.createAuthUsingDecentriqPki(enclaveSpecs);
      const datasetExportId = parent.id;

      try {
        await export_dataset.getDatasetExportResult(
          datasetExportId,
          client,
          auth,
          {
            additionalEnclaveSpecs: enclaveSpecifications,
          }
        );
        return {
          error: null,
          success: true,
        };
      } catch (e) {
        return {
          error: e.message,
          success: false,
        };
      }
    },
  };
}
