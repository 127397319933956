import { ClientApiError, data_lab, DataLabDataset } from "@decentriq/core";
import { Key } from "@decentriq/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import JSZip from "jszip";
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { KeychainItemKind } from "services/keychain";
import { type ValidationReport } from "validate-js";
import { useApiCore, useConfiguration, useKeychainService } from "contexts";
import { useDataLabSnackbar } from "features/dataLabs";
import {
  convertMatchingIdFormat,
  type DataLab,
  DataLabDataNodeType,
} from "features/dataLabs/models";
import {
  useDataLabQuery,
  useDeleteDataLabMutation,
  useSetDemographicsDatasetForDataLabMutation,
  useSetEmbeddingsDatasetForDataLabMutation,
  useSetJobIdsForDataLabMutation,
  useSetSegmentsDatasetForDataLabMutation,
  useSetStatisticsForDataLabMutation,
  useSetUsersDatasetForDataLabMutation,
} from "hooks/__generated-new";
import {
  DataLabsDocument,
  type SetDataLabDatasetInput,
} from "types/__generated-new";
import { logWarning } from "utils";
import { hashedIdAsArray } from "utils/apicore";

type ComputationError = { message: string; detail: string };
type ValidationErrors = Map<DataLabDataNodeType, string>;

const DataLabContext = createContext<{
  dataLab: {
    data?: DataLab;
    loading: boolean;
  };
  deleteDataLab: () => Promise<void>;
  statisticsLoading: boolean;
  computationError: ComputationError | null;
  datasetValidationErrors: ValidationErrors;
  setDataset: (
    datasetType: DataLabDataNodeType,
    manifestHash: string | null
  ) => Promise<void>;
  computeStatistics: () => Promise<void>;
}>({
  computationError: null,
  computeStatistics: async () => {},
  dataLab: {
    loading: false,
  },
  datasetValidationErrors: new Map(),
  deleteDataLab: async () => {},
  setDataset: async () => {},
  statisticsLoading: false,
});

interface DataLabWrapperProps {
  children: ReactNode;
  id: string;
}

export const DataLabWrapper: React.FC<DataLabWrapperProps> = ({
  id,
  children,
}) => {
  const { keychain } = useKeychainService();
  const { client, sessionManager } = useApiCore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataLabSnackbar();
  const dataLabResponse = useDataLabQuery({
    variables: {
      id,
    },
  });
  const [deleteDataLabMutation] = useDeleteDataLabMutation({
    update: (cache) => {
      cache.updateQuery({ query: DataLabsDocument }, (data) => ({
        dataLabs: {
          nodes: (data?.dataLabs.nodes ?? []).filter(
            ({ id: dataLabId }: { id: string }) => id !== dataLabId
          ),
        },
      }));
    },
  });
  const deleteDataLab = useCallback(async () => {
    await deleteDataLabMutation({
      variables: {
        id,
      },
    });
    navigate(`/datalabs`);
  }, [deleteDataLabMutation, id, navigate]);

  const [setDemographicsDatasetMutation] =
    useSetDemographicsDatasetForDataLabMutation();
  const [setEmbeddingsDatasetMutation] =
    useSetEmbeddingsDatasetForDataLabMutation();
  const [setSegmentsDatasetMutation] =
    useSetSegmentsDatasetForDataLabMutation();
  const [setUsersDatasetMutation] = useSetUsersDatasetForDataLabMutation();

  const [
    setStatisticsForDataLabMutation,
    setStatisticsForDataLabMutationResult,
  ] = useSetStatisticsForDataLabMutation();

  const pollValidationAndStatisticsQuery = useQuery({
    enabled:
      Boolean(dataLabResponse.data?.dataLab.validationComputeJobId) &&
      Boolean(dataLabResponse.data?.dataLab.statisticsComputeJobId),
    queryFn: async () => {
      const session = await sessionManager.get();
      const { dataLab } = dataLabResponse.data!;
      const resp = await data_lab.checkDataLabJobs(
        session,
        dataLab.validationComputeJobId!,
        dataLab.statisticsComputeJobId!,
        dataLab.requireDemographicsDataset!,
        dataLab.requireEmbeddingsDataset!
      );
      return resp;
    },

    queryKey: [
      "poll-validation-and-statistics-query",
      dataLabResponse.data?.dataLab.validationComputeJobId,
      dataLabResponse.data?.dataLab.statisticsComputeJobId,
    ],
    // refetchInterval used for polling if the query returns true, polling stops
    refetchInterval: (query) => {
      const queryData = query.state.data;
      if (
        queryData?.statistics.status === "PENDING" ||
        queryData?.validation.status === "PENDING"
      ) {
        return 1000;
      }
    },
  });

  const validationReportsQuery = useQuery({
    enabled:
      pollValidationAndStatisticsQuery.data?.statistics.status === "ERROR" &&
      pollValidationAndStatisticsQuery.data?.validation.status === "SUCCESS",
    queryFn: async () => {
      const session = await sessionManager.get();
      const reportPromises: {
        datasetType: DataLabDataNodeType;
        promiseCreator: () => Promise<Uint8Array>;
      }[] = [
        {
          datasetType: DataLabDataNodeType.matching,
          promiseCreator: () =>
            data_lab.getDataLabDatasetValidationReport(
              session,
              dataLabResponse.data?.dataLab.validationComputeJobId!,
              DataLabDataset.Users
            ),
        },
        {
          datasetType: DataLabDataNodeType.segments,
          promiseCreator: () =>
            data_lab.getDataLabDatasetValidationReport(
              session,
              dataLabResponse.data?.dataLab.validationComputeJobId!,
              DataLabDataset.Segments
            ),
        },
      ];
      if (dataLabResponse.data?.dataLab.requireDemographicsDataset!) {
        reportPromises.push({
          datasetType: DataLabDataNodeType.demographics,
          promiseCreator: () =>
            data_lab.getDataLabDatasetValidationReport(
              session,
              dataLabResponse.data?.dataLab.validationComputeJobId!,
              DataLabDataset.Demographics
            ),
        });
      }

      if (dataLabResponse.data?.dataLab.requireEmbeddingsDataset!) {
        reportPromises.push({
          datasetType: DataLabDataNodeType.embeddings,
          promiseCreator: () =>
            data_lab.getDataLabDatasetValidationReport(
              session,
              dataLabResponse.data?.dataLab.validationComputeJobId!,
              DataLabDataset.Embeddings
            ),
        });
      }

      const reports = await Promise.all(
        reportPromises.map(async ({ promiseCreator, datasetType }) => {
          try {
            const data = await promiseCreator();
            const zipResult = await JSZip.loadAsync(data);
            const validationReportJson = await zipResult
              ?.file("validation-report.json")
              ?.async("string");
            const validationReport = JSON.parse(
              validationReportJson!
            ) as ValidationReport;
            return { datasetType, validationReport };
          } catch (error) {
            enqueueSnackbar(
              `Failed to retrieve validation report for ${datasetType}`,
              {
                context:
                  error instanceof ClientApiError ? error.message : `${error}`,
                persist: true,
                variant: "error",
              }
            );
            throw error;
          }
        })
      );

      const jobErrors = reports
        .filter(
          ({ validationReport }) => validationReport.report.outcome === "FAILED"
        )
        .reduce((acc: ValidationErrors, { datasetType, validationReport }) => {
          acc.set(
            datasetType,
            JSON.stringify(validationReport["report"], null, 2)
          );
          return acc;
        }, new Map());
      return jobErrors;
    },
    queryKey: [
      "validation-reports-query",
      dataLabResponse.data?.dataLab.validationComputeJobId,
      dataLabResponse.data?.dataLab.statisticsComputeJobId,
    ],
  });

  const statisticsQueryKey = useMemo(() => {
    const {
      usersDataset,
      segmentsDataset,
      embeddingsDataset,
      demographicsDataset,
    } = dataLabResponse.data?.dataLab ?? {};
    const statisticsStatus =
      pollValidationAndStatisticsQuery.data?.statistics?.status;
    const validationStatus =
      pollValidationAndStatisticsQuery.data?.validation?.status;
    return [
      "statistics-query",
      usersDataset?.manifestHash,
      segmentsDataset?.manifestHash,
      embeddingsDataset?.manifestHash,
      demographicsDataset?.manifestHash,
      statisticsStatus,
      validationStatus,
    ];
  }, [dataLabResponse.data, pollValidationAndStatisticsQuery.data]);

  const statisticsQuery = useQuery({
    enabled:
      pollValidationAndStatisticsQuery.data?.statistics.status === "SUCCESS",
    queryFn: async () => {
      const session = await sessionManager.get();
      const statisticsResponse = await data_lab.getDataLabStatisticsReport(
        session,
        dataLabResponse.data?.dataLab.statisticsComputeJobId!
      );

      const statisticsZip = await JSZip.loadAsync(statisticsResponse);
      const statistics = JSON.parse(
        (await statisticsZip.file("statistics.json")?.async("string")) ?? ""
      );
      return statistics;
    },
    queryKey: statisticsQueryKey,
  });

  // Persist statistics report
  useEffect(() => {
    const statistics = statisticsQuery?.data;
    if (statistics) {
      setStatisticsForDataLabMutation({
        variables: {
          input: {
            id,
            statistics,
          },
        },
      });
    }
  }, [id, setStatisticsForDataLabMutation, statisticsQuery.data]);

  const setDataset = useCallback(
    async (datasetType: DataLabDataNodeType, manifestHash: string | null) => {
      const input: SetDataLabDatasetInput = {
        id,
        manifestHash,
      };
      switch (datasetType) {
        case DataLabDataNodeType.matching:
          await setUsersDatasetMutation({ variables: { input } });
          break;
        case DataLabDataNodeType.segments:
          await setSegmentsDatasetMutation({ variables: { input } });
          break;
        case DataLabDataNodeType.embeddings:
          await setEmbeddingsDatasetMutation({ variables: { input } });
          break;
        case DataLabDataNodeType.demographics:
          await setDemographicsDatasetMutation({ variables: { input } });
          break;
        default:
          logWarning(`DatasetType "${datasetType}" didn't match`);
      }
    },
    [
      id,
      setDemographicsDatasetMutation,
      setEmbeddingsDatasetMutation,
      setSegmentsDatasetMutation,
      setUsersDatasetMutation,
    ]
  );

  const [setJobIdsMutation] = useSetJobIdsForDataLabMutation();

  const {
    configuration: { insecureEnclavesEnabled },
  } = useConfiguration();

  const computeStatisticsMutation = useMutation({
    mutationFn: async () => {
      const session = await sessionManager.get();
      const keychainItems = await keychain.getItems();
      const getDatasetKey = (manifestHash: string | undefined): Key => {
        const keychainItem = keychainItems.find(
          (item) =>
            item.id === manifestHash && item.kind === KeychainItemKind.Dataset
        );
        if (keychainItem) {
          const encryptionKey = new Key(hashedIdAsArray(keychainItem.value));
          return encryptionKey;
        } else {
          throw new Error(
            `Dataset with id ${manifestHash} not found in keychain`
          );
        }
      };
      const matchingIdFormat = convertMatchingIdFormat(
        dataLabResponse.data?.dataLab?.matchingIdFormat
      );
      const matchingIdHashingAlgorithm =
        dataLabResponse.data?.dataLab?.matchingIdHashingAlgorithm ?? undefined;
      const userDatasetManifestHash =
        dataLabResponse.data?.dataLab?.usersDataset?.manifestHash;
      if (userDatasetManifestHash === undefined) {
        throw new Error("User dataset manifest hash is undefined");
      }
      const demographicsDatasetManifestHash =
        dataLabResponse.data?.dataLab?.demographicsDataset?.manifestHash;
      const segmentsDatasetManifestHash =
        dataLabResponse.data?.dataLab?.segmentsDataset?.manifestHash;
      const embeddingsDatasetManifestHash =
        dataLabResponse.data?.dataLab?.embeddingsDataset?.manifestHash;

      const demographicsDataset: data_lab.DataLabsDataset | undefined =
        demographicsDatasetManifestHash
          ? {
              key: getDatasetKey(demographicsDatasetManifestHash),
              manifestHash: demographicsDatasetManifestHash,
            }
          : undefined;
      const embeddingsDataset: data_lab.EmbeddingsDataset | undefined =
        embeddingsDatasetManifestHash
          ? {
              key: getDatasetKey(embeddingsDatasetManifestHash),
              manifestHash: embeddingsDatasetManifestHash,
              numEmbeddings: dataLabResponse.data?.dataLab?.numEmbeddings!,
            }
          : undefined;

      const segmentsDataset: data_lab.DataLabsDataset | undefined =
        segmentsDatasetManifestHash
          ? {
              key: getDatasetKey(segmentsDatasetManifestHash),
              manifestHash: segmentsDatasetManifestHash,
            }
          : undefined;

      const dataLabJobs = await data_lab.computeDataLabStatistics(
        client,
        session,
        {
          dataLabId: id,
          demographicsDataset,
          embeddingsDataset,
          includeInsecureEnclaves: insecureEnclavesEnabled,
          segmentsDataset,
          usersDataset: {
            key: getDatasetKey(userDatasetManifestHash),
            manifestHash: userDatasetManifestHash,
            matchingIdFormat,
            matchingIdHashingAlgorithm,
          },
        }
      );
      await setJobIdsMutation({
        variables: {
          input: {
            id,
            jobsDriverAttestationHash: dataLabJobs.jobsDriverAttestationHash,
            statisticsComputeJobId: dataLabJobs.statisticsJobId,
            validationComputeJobId: dataLabJobs.validationJobId,
          },
        },
      });
    },
  });

  const computationError = useMemo(() => {
    if (computeStatisticsMutation.error) {
      return {
        detail: `${computeStatisticsMutation.error}`,
        message: "Failed to run statistics and validation",
      };
    }
    if (
      pollValidationAndStatisticsQuery?.data?.statistics.status === "ERROR" &&
      pollValidationAndStatisticsQuery?.data?.statistics.error.startsWith(
        "publisher_data_statistics"
      )
    ) {
      return {
        detail: `${pollValidationAndStatisticsQuery?.data?.statistics.error}`,
        message: "Failed to run statistics and validation",
      };
    }
    if (pollValidationAndStatisticsQuery?.data?.validation.status === "ERROR") {
      return {
        detail: pollValidationAndStatisticsQuery?.data?.validation.error,
        message: "Failed to run validation",
      };
    }
    if (validationReportsQuery.error) {
      return {
        detail: `${validationReportsQuery.error}`,
        message: "Failed to retrieve validation reports",
      };
    }

    if (statisticsQuery.error) {
      return {
        detail: `${statisticsQuery.error}`,
        message: "Failed to retrieve statistics results",
      };
    }
    return null;
  }, [
    computeStatisticsMutation.error,
    pollValidationAndStatisticsQuery?.data,
    statisticsQuery.error,
    validationReportsQuery.error,
  ]);

  return (
    <DataLabContext.Provider
      value={{
        computationError,
        computeStatistics: async () => computeStatisticsMutation.mutate(),
        dataLab: {
          data: dataLabResponse.data?.dataLab,
          loading: dataLabResponse.loading,
        },
        datasetValidationErrors: useMemo(
          () => validationReportsQuery.data ?? new Map(),
          [validationReportsQuery.data]
        ),
        deleteDataLab,
        setDataset,
        statisticsLoading:
          computeStatisticsMutation.isPending ||
          pollValidationAndStatisticsQuery.isFetching ||
          pollValidationAndStatisticsQuery.data?.statistics.status ===
            "PENDING" ||
          pollValidationAndStatisticsQuery.data?.validation.status ===
            "PENDING" ||
          validationReportsQuery.isFetching ||
          statisticsQuery.isFetching ||
          setStatisticsForDataLabMutationResult.loading,
      }}
    >
      {children}
    </DataLabContext.Provider>
  );
};

export const useDataLabContext = () => useContext(DataLabContext);
