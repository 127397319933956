import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import {} from "hooks/__generated-new";
import {
  AddSubmittedDataRoomRequestSignatureDocument,
  type AddSubmittedDataRoomRequestSignatureMutation,
  type AddSubmittedDataRoomRequestSignatureMutationVariables,
  type MutationSignSubmittedDataRoomRequestArgs,
  type SignSubmittedDataRoomRequestPayload,
} from "types/__generated-new";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeSignSubmittedDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: any,
    args: MutationSignSubmittedDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<SignSubmittedDataRoomRequestPayload> => {
    const { id, commitId, driverAttestationHash, dcrHash } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const signature = await sdkSession.generateMergeApprovalSignature(commitId);
    const encodedSignature = forge.util.binary.base64.encode(signature);
    const response = await context.client.mutate<
      AddSubmittedDataRoomRequestSignatureMutation,
      AddSubmittedDataRoomRequestSignatureMutationVariables
    >({
      mutation: AddSubmittedDataRoomRequestSignatureDocument,
      variables: {
        input: {
          signature: encodedSignature,
          submittedDataRoomRequestId: id,
        },
      },
    });
    return {
      signature: encodedSignature,
      signers:
        response.data?.submittedDataRoomRequest?.addSignature?.signers?.nodes?.map(
          (s) => ({ signature: s.signature, userEmail: s.userEmail })
        ) || [],
    };
  };
