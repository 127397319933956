import { type ApiCoreContextInterface } from "contexts";
import { type AuditLog, type QueryAuditLogArgs } from "types/__generated-new";
import { maybeUseDataRoomSecret } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "../../../models";

const makeAuditLogResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    parent = null,
    args: QueryAuditLogArgs,
    context: LocalResolverContext
  ): Promise<AuditLog> => {
    const { dcrHash, driverAttestationHash } = args;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const { log } = await sdkSession.retrieveAuditLog(dcrHash);
    if (!log) {
      return {
        log: "",
      };
    }
    const parsedAuditLog = new TextDecoder().decode(log);
    return {
      log: parsedAuditLog,
    };
  };

export default makeAuditLogResolver;
