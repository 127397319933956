import { Box, CircularProgress } from "@mui/material";
import omit from "lodash/omit";
import { memo } from "react";
import { JsonEditorField } from "components";
import { useUserDetailsQuery } from "hooks/__generated-new";

type UserDetailsJSONViewProps = {
  userId: string;
};

const UserDetailsJSONView: React.FC<UserDetailsJSONViewProps> = ({
  userId,
}) => {
  const { data: { user } = {}, loading } = useUserDetailsQuery({
    variables: { userId },
  });

  const userDetailsJSON = `${JSON.stringify(
    omit(user || {}, ["logo"]),
    null,
    2
  )}`;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginBottom: 2,
      }}
    >
      {loading ? (
        <CircularProgress size={16} sx={{ marginLeft: "6px" }} />
      ) : (
        <JsonEditorField
          editorOptions={{
            lineNumbers: "off",
            readOnly: true,
            resizable: false,
          }}
          value={userDetailsJSON}
        />
      )}
    </Box>
  );
};

UserDetailsJSONView.displayName = "UserDetailsJSONView";

export default memo(UserDetailsJSONView);
