import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useAdvertiserAudiences } from "../../contexts/advertiserAudiences/advertiserAudiences";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import { type Audience } from "../../models";
import { replaceDecentriqOrgName } from "../../utils";
import ActivateAudienceButton from "../ActivateAudienceButton";
import AudiencesTable from "../AudiencesTable";
import DownloadAudienceButton from "../DownloadAudienceButton/DownloadAudienceButton";
import EmptyStateContainer from "../EmptyStateContainer/EmptyStateContainer";
import MediaDataRoomActivationSummary from "./MediaDataRoomActivationSummary";
import useMediaDataRoomPublishedAudiences from "./useMediaDataRoomPublishedAudiences";

const MediaDataRoomActivation: React.FC = () => {
  const {
    dataRoomId,
    isPublisher,
    isAdvertiser,
    isObserver,
    isAgency,
    driverAttestationHash,
    publisherOrganization,
    isDeactivated,
    supportedFeatures: { enableAdvertiserAudienceDownload = false },
  } = usePublishedMediaInsightsDcr();
  const {
    availableAudiences,
    datasetsLoading: dataRoomDataLoading,
    hasRequiredData,
    isPublisherAudienceBeingUpdated,
  } = useMediaInsightsDcrData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const {
    activatedAudiences,
    viewAudiencesLoading: advertiserAudiencesLoading,
    viewAudiencesError: advertiserAudiencesError,
    retryViewAudiences: retryAdvertiserAudiences,
  } = useAdvertiserAudiences();
  const {
    audiences: publisherAudiences,
    loading: publisherAudiencesLoading,
    error: publisherAudiencesError,
    retry: retryPublisherAudiences,
  } = useMediaDataRoomPublishedAudiences({
    dataRoomId,
    driverAttestationHash,
    hasRequiredData,
    skip: useMemo(
      () => isDeactivated || !isPublisher,
      [isDeactivated, isPublisher]
    ),
  });
  const audiences = useMemo(() => {
    return isPublisher ? publisherAudiences : activatedAudiences;
  }, [isPublisher, publisherAudiences, activatedAudiences]);
  const loading = useMemo(() => {
    return (
      dataRoomDataLoading ||
      (isAdvertiser
        ? advertiserAudiencesLoading ||
          availableAudiences.status !== "COMPLETED"
        : publisherAudiencesLoading)
    );
  }, [
    dataRoomDataLoading,
    isAdvertiser,
    advertiserAudiencesLoading,
    availableAudiences.status,
    publisherAudiencesLoading,
  ]);
  const retryComputations = useCallback(async () => {
    setRetryInProgress(true);
    try {
      if (isPublisher) {
        await retryPublisherAudiences();
      } else {
        await retryAdvertiserAudiences();
      }
    } finally {
      setRetryInProgress(false);
    }
  }, [isPublisher, retryAdvertiserAudiences, retryPublisherAudiences]);
  const getAudienceStatusLabel = useCallback(
    (row: Audience) => {
      if (isPublisher) {
        return row.published ? "Available for download" : "Ready to activate";
      } else {
        if (row.published) {
          return `Available for download${
            publisherOrganization?.name
              ? " by " +
                replaceDecentriqOrgName(publisherOrganization.name, "Publisher")
              : ""
          }`;
        } else {
          return "Ready to activate";
        }
      }
    },
    [isPublisher, publisherOrganization]
  );
  const hasActivationButton = !isObserver && !isPublisher;
  const hasDownloadButton =
    isPublisher ||
    ((isAdvertiser || isAgency) && enableAdvertiserAudienceDownload);
  const rowActions = useCallback(
    (row: Audience) => {
      const { audienceType, reach, published, activationType } = row;
      return (
        <>
          {hasActivationButton && (
            <Box ml={0.5}>
              <ActivateAudienceButton
                activationType={activationType}
                audienceType={audienceType}
                published={published}
                reach={reach}
              />
            </Box>
          )}
          {hasDownloadButton && (
            <Box ml={0.5}>
              <DownloadAudienceButton
                activatedAudiences={audiences}
                activationType={row.activationType}
                audienceType={audienceType}
                reach={reach}
              />
            </Box>
          )}
        </>
      );
    },
    [hasActivationButton, hasDownloadButton, audiences]
  );
  const noRecordsToDisplay = useMemo(
    () =>
      isPublisher
        ? "The advertiser has not made any audience available for activation yet."
        : "No audiences found",
    [isPublisher]
  );
  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          Publisher audience is being updated.
        </Typography>
        <Typography variant="subtitle2">
          {isPublisher
            ? "Please provision a Data Lab again."
            : "Activation will be available again soon."}
        </Typography>
      </EmptyStateContainer>
    );
  }
  if (!dataRoomDataLoading && !hasRequiredData) {
    return (
      <Alert severity="info">Waiting for both parties to provision data.</Alert>
    );
  }
  if (loading) {
    return (
      <EmptyStateContainer>
        <Typography
          sx={{ alignItems: "center", display: "inline-flex" }}
          variant="subtitle2"
        >
          <CircularProgress
            color="inherit"
            size={14}
            sx={{ marginRight: 1 }}
            thickness={3}
          />
          {isAdvertiser &&
            (availableAudiences.status === "COMPUTING"
              ? "Computing lookalike models"
              : availableAudiences.status === "FETCHING"
                ? "Retrieving audiences..."
                : "")}
          {isPublisher && "Loading audiences..."}
        </Typography>
      </EmptyStateContainer>
    );
  }
  if (publisherAudiencesError || advertiserAudiencesError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Activated audiences could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
            variant="outlined"
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MediaDataRoomActivationSummary />
      <AudiencesTable
        audiences={audiences}
        getAudienceStatusLabel={getAudienceStatusLabel}
        hasMutipleActions={hasActivationButton && hasDownloadButton}
        noRecordsToDisplay={noRecordsToDisplay}
        rowActions={rowActions}
      />
    </Box>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
