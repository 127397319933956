import { Box } from "@mui/material";
import { memo } from "react";
import { ComputeNodesActionsButton } from "features/computeNodes";

interface ComputeNodesToolbarProps {
  dataRoomId: string;
}

const ComputeNodesToolbar = memo<ComputeNodesToolbarProps>(({ dataRoomId }) => (
  <Box
    sx={{
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      ml: "auto",
    }}
  >
    <ComputeNodesActionsButton
      dataRoomId={dataRoomId}
      handleRunAllComputations={() => {}} // TODO: integrate
      handleValidateComputations={() => {}} // TODO: integrate
      isActionMode={false} // TODO: integrate
      isEditing={true} // TODO: integrate
      isRunAllButtonDisabled={false} // TODO: integrate
      isRunAllButtonVisible={false} // TODO: integrate
      isRunningComputations={false} // TODO: integrate
      isValidateButtonDisabled={false} // TODO: integrate
      isValidateButtonVisible={true} // TODO: integrate
      isValidatingComputations={false} // TODO: integrate
    />
  </Box>
));

export default ComputeNodesToolbar;
