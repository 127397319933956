/* eslint-disable */
import * as Types from 'types/__generated-new';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const NavigationDrawerDraftDataRoomFragment = gql`
    fragment NavigationDrawerDraftDataRoom on DraftDataRoom {
  id
  name: title
  requirePassword @client
}
    `;
export const NavigationDrawerPublishedDataRoomFragment = gql`
    fragment NavigationDrawerPublishedDataRoom on PublishedDataRoom {
  id
  name: title
  isStopped
  requirePassword
  deactivatedAt
  kind
}
    `;
export const NavigationDrawerPublishedLookalikeMediaDataRoomFragment = gql`
    fragment NavigationDrawerPublishedLookalikeMediaDataRoom on PublishedLookalikeMediaDataRoom {
  id
  name: title
  isStopped
  deactivatedAt
  requirePassword
  kind
}
    `;
export const NavigationDrawerPublishedMediaDataRoomFragment = gql`
    fragment NavigationDrawerPublishedMediaDataRoom on PublishedMediaDataRoom {
  id
  name: title
  isStopped
  requirePassword
  deactivatedAt
  kind
}
    `;
export const NavigationDrawerPublishedMediaInsightsDcrFragment = gql`
    fragment NavigationDrawerPublishedMediaInsightsDcr on PublishedMediaInsightsDcr {
  id
  name: title
  isStopped
  deactivatedAt
  requirePassword
  kind
}
    `;
export const ApiTokenFragment = gql`
    fragment ApiToken on ApiToken {
  token
  id
  name
  createdAt
  revoked
}
    `;
export const DataRoomUserSettingsFragment = gql`
    fragment DataRoomUserSettings on DataRoomUserSettings {
  id
  isArchived
  isFavorite
}
    `;
export const DataRoomOverviewFragment = gql`
    fragment DataRoomOverview on DataRoom {
  id
  name: title
  createdAt
  owner {
    id
    email
  }
  userSettings {
    ...DataRoomUserSettings
  }
}
    `;
export const DraftParticipantUserEmailFragment = gql`
    fragment DraftParticipantUserEmail on DraftParticipant {
  id
  userEmail
}
    `;
export const DraftComputationNodeNameFragment = gql`
    fragment DraftComputationNodeName on DraftComputationNode {
  id
  name
}
    `;
export const DraftLeafNodeNameFragment = gql`
    fragment DraftLeafNodeName on DraftLeafNode {
  id
  name
}
    `;
export const OrganizationFragment = gql`
    fragment Organization on Organization {
  id
  createdAt
  createdBy {
    id
    email
  }
  updatedAt
  name
  logo
  workerTypes {
    nodes {
      id
      name
      shortName
    }
  }
  domains
  state
  hasAdvertiserFeatures
  hasAnalyticsFeatures
  hasPublisherFeatures
  users {
    nodes {
      id
      email
      userRole
      isDemoUser
      createdAt
    }
    totalCount
  }
  participatingDataRooms {
    totalCount
  }
}
    `;
export const UserFragment = gql`
    fragment User on User {
  id
  email
  logo
  userRole
  createdAt
  isDemoUser
}
    `;
export const ExternalInvitationFragment = gql`
    fragment ExternalInvitation on UserInvitation {
  id
  user {
    id
    email
    logo
  }
  lastInvitedAt
  lastInvitedBy {
    id
    email
  }
}
    `;
export const AvailableDataPartnerFragment = gql`
    fragment AvailableDataPartner on AvailableDataPartner {
  id
  dataPartnerName
  dataPartnerDescription
  dataPartnerLogo
  dataPartnerId
  requestRecipientId
  allowLookalike
  allowInsights
  allowRetargeting
  allowExclusionTargeting
  marketIds
  matchingIdFormat
  matchingIdHashingAlgorithm
  participants
}
    `;
export const AvailablePublisherFragment = gql`
    fragment AvailablePublisher on AvailablePublisher {
  id
  publisherId
  publisherName
  publisherDescription
  publisherLogo
  allowLookalike
  allowInsights
  allowRetargeting
  allowExclusionTargeting
  matchingIdFormat
  matchingIdHashingAlgorithm
  publisherParticipants
  requestRecipientId
  marketIds
  allowMeasurements
}
    `;
export const MediaInsightsComputeJobCompleteFragment = gql`
    fragment MediaInsightsComputeJobComplete on MediaComputeJob {
  jobIdHex
  publishedDataRoomId
  computeNodeName
  jobType
  cacheKey
  createdAt
}
    `;
export const StaticScriptFragment = gql`
    fragment StaticScript on DraftScript {
  id
  name
  content
  isMainScript
}
    `;
export const PublishedPreviewNodeQuotasFragment = gql`
    fragment PublishedPreviewNodeQuotas on PublishedPreviewNode {
  id
  usedQuotaBytes
  remainingQuotaBytes
  totalQuotaBytes
}
    `;
export const FullDataLabFragment = gql`
    fragment FullDataLab on DataLab {
  id
  name
  highLevelRepresentationAsString
  isValidated
  requireDemographicsDataset
  requireEmbeddingsDataset
  requireSegmentsDataset
  numEmbeddings
  matchingIdFormat
  matchingIdHashingAlgorithm
  usersDataset {
    id
    manifestHash
    name
  }
  segmentsDataset {
    id
    manifestHash
    name
  }
  demographicsDataset {
    id
    manifestHash
    name
  }
  embeddingsDataset {
    id
    manifestHash
    name
  }
  createdAt
  updatedAt
  jobsDriverAttestationHash
  validationComputeJobId
  statisticsComputeJobId
  statistics
}
    `;
export const DatasetFragment = gql`
    fragment Dataset on Dataset {
  id
  createdAt
  description
  manifestHash
  name
  owner {
    id
    email
  }
  size
  statistics
}
    `;
export const DataPartnerUsageConfigurationFragment = gql`
    fragment DataPartnerUsageConfiguration on DataPartnerUsageConfiguration {
  id
  name
  matchingIdFormat
  matchingIdHashingAlgorithm
  allowInsights
  allowLookalike
  allowRetargeting
  allowExclusionTargeting
  matchAnyDomain
  participants
  advertiserDomains
}
    `;
export const DataPartnerDisplayConfigurationFragment = gql`
    fragment DataPartnerDisplayConfiguration on DataPartnerDisplayConfiguration {
  id
  name
  description
  logo
  marketIds
  collaborationRequestUsers
}
    `;
export const DraftDataRoomPasswordFragment = gql`
    fragment DraftDataRoomPassword on DraftDataRoom {
  password @client
}
    `;
export const PublishedDataRoomTestingFragmentFragment = gql`
    fragment PublishedDataRoomTestingFragment on PublishedDataRoom {
  id
  testing
}
    `;
export const FullActivationConfigurationFragment = gql`
    fragment FullActivationConfiguration on ActivationConfiguration {
  id
  name
  allowInsights
  allowLookalike
  allowRetargeting
  allowExclusionTargeting
  allowMeasurements
  matchingIdFormat
  matchingIdHashingAlgorithm
  advertiserDomains
  matchAnyDomain
  publisherParticipants
}
    `;
export const CreateDraftDataRoomFragment = gql`
    fragment CreateDraftDataRoom on DraftDataRoom {
  id
  title
  description
}
    `;
export const DraftNodeConnectionIdFragment = gql`
    fragment DraftNodeConnectionId on DraftNodeConnection {
  draftNode {
    id
  }
}
    `;
export const PublishedNodeConnectionIdFragment = gql`
    fragment PublishedNodeConnectionId on PublishedNodeConnection {
  computeNodeId
}
    `;
export const PublishedDataRoomPasswordRequirementsFragment = gql`
    fragment PublishedDataRoomPasswordRequirements on PublishedDataRoom {
  id
  requirePassword
  password @client
}
    `;
export const DatasetExportFragment = gql`
    fragment DatasetExport on DatasetExport {
  id
  name
  dataRoomId
  targetType
  status
  computeJobId
  driverAttestationHash
  computeJobNode
  config
  createdAt
  finishedAt
}
    `;
export const DatasetImportFragment = gql`
    fragment DatasetImport on DatasetImport {
  id
  dataRoomId
  sourceType
  status
  name
  computeJobId
  driverAttestationHash
  computeJobNode
  config
  createdAt
  finishedAt
}
    `;
export const LookalikeMediaComputeJobCompleteFragment = gql`
    fragment LookalikeMediaComputeJobComplete on MediaComputeJob {
  jobIdHex
  publishedDataRoomId
  computeNodeName
  jobType
  cacheKey
  createdAt
}
    `;
export const DatasetExportResultFragment = gql`
    fragment DatasetExportResult on DatasetExport {
  status
  finishedAt
  result {
    success
    error
  }
}
    `;
export const DatasetImportResultFragment = gql`
    fragment DatasetImportResult on DatasetImport {
  status
  finishedAt
  result {
    success
    error
  }
}
    `;
export const PublishedDataRoomPasswordFragment = gql`
    fragment PublishedDataRoomPassword on PublishedDataRoom {
  password @client
}
    `;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($id: String!) {
  organization {
    deleteUser(id: $id) {
      id
    }
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<Types.DeleteAccountMutation, Types.DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAccountMutation, Types.DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAccountMutation, Types.DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<Types.DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAccountMutation, Types.DeleteAccountMutationVariables>;
export const MyDataRoomsDocument = gql`
    query MyDataRooms {
  dataRooms(
    filter: {isArchived: {equalTo: false}, isOwnedByUser: {equalTo: true}}
    sortBy: {updatedAt: DESCENDING}
  ) {
    nodes {
      ... on DraftDataRoom {
        ...NavigationDrawerDraftDataRoom
      }
      ... on PublishedDataRoom {
        ...NavigationDrawerPublishedDataRoom
      }
      ... on PublishedMediaDataRoom {
        ...NavigationDrawerPublishedMediaDataRoom
      }
      ... on PublishedLookalikeMediaDataRoom {
        ...NavigationDrawerPublishedLookalikeMediaDataRoom
      }
      ... on PublishedMediaInsightsDcr {
        ...NavigationDrawerPublishedMediaInsightsDcr
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}`;

/**
 * __useMyDataRoomsQuery__
 *
 * To run a query within a React component, call `useMyDataRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDataRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDataRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDataRoomsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyDataRoomsQuery, Types.MyDataRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyDataRoomsQuery, Types.MyDataRoomsQueryVariables>(MyDataRoomsDocument, options);
      }
export function useMyDataRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyDataRoomsQuery, Types.MyDataRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyDataRoomsQuery, Types.MyDataRoomsQueryVariables>(MyDataRoomsDocument, options);
        }
export type MyDataRoomsQueryHookResult = ReturnType<typeof useMyDataRoomsQuery>;
export type MyDataRoomsLazyQueryHookResult = ReturnType<typeof useMyDataRoomsLazyQuery>;
export type MyDataRoomsQueryResult = Apollo.QueryResult<Types.MyDataRoomsQuery, Types.MyDataRoomsQueryVariables>;
export const SharedWithMeDataRoomsDocument = gql`
    query SharedWithMeDataRooms {
  dataRooms(
    filter: {isArchived: {equalTo: false}, isFavorite: {equalTo: false}, isOwnedByUser: {equalTo: false}}
    sortBy: {updatedAt: DESCENDING}
  ) {
    nodes {
      ... on DraftDataRoom {
        ...NavigationDrawerDraftDataRoom
      }
      ... on PublishedDataRoom {
        ...NavigationDrawerPublishedDataRoom
      }
      ... on PublishedMediaDataRoom {
        ...NavigationDrawerPublishedMediaDataRoom
      }
      ... on PublishedLookalikeMediaDataRoom {
        ...NavigationDrawerPublishedLookalikeMediaDataRoom
      }
      ... on PublishedMediaInsightsDcr {
        ...NavigationDrawerPublishedMediaInsightsDcr
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}`;

/**
 * __useSharedWithMeDataRoomsQuery__
 *
 * To run a query within a React component, call `useSharedWithMeDataRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedWithMeDataRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedWithMeDataRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharedWithMeDataRoomsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SharedWithMeDataRoomsQuery, Types.SharedWithMeDataRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedWithMeDataRoomsQuery, Types.SharedWithMeDataRoomsQueryVariables>(SharedWithMeDataRoomsDocument, options);
      }
export function useSharedWithMeDataRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedWithMeDataRoomsQuery, Types.SharedWithMeDataRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedWithMeDataRoomsQuery, Types.SharedWithMeDataRoomsQueryVariables>(SharedWithMeDataRoomsDocument, options);
        }
export type SharedWithMeDataRoomsQueryHookResult = ReturnType<typeof useSharedWithMeDataRoomsQuery>;
export type SharedWithMeDataRoomsLazyQueryHookResult = ReturnType<typeof useSharedWithMeDataRoomsLazyQuery>;
export type SharedWithMeDataRoomsQueryResult = Apollo.QueryResult<Types.SharedWithMeDataRoomsQuery, Types.SharedWithMeDataRoomsQueryVariables>;
export const AccessTokensDocument = gql`
    query AccessTokens {
  myself {
    id
    apiTokens {
      nodes {
        token
        revoked
        id
        name
        createdAt
      }
    }
  }
}
    `;

/**
 * __useAccessTokensQuery__
 *
 * To run a query within a React component, call `useAccessTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessTokensQuery(baseOptions?: Apollo.QueryHookOptions<Types.AccessTokensQuery, Types.AccessTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccessTokensQuery, Types.AccessTokensQueryVariables>(AccessTokensDocument, options);
      }
export function useAccessTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccessTokensQuery, Types.AccessTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccessTokensQuery, Types.AccessTokensQueryVariables>(AccessTokensDocument, options);
        }
export type AccessTokensQueryHookResult = ReturnType<typeof useAccessTokensQuery>;
export type AccessTokensLazyQueryHookResult = ReturnType<typeof useAccessTokensLazyQuery>;
export type AccessTokensQueryResult = Apollo.QueryResult<Types.AccessTokensQuery, Types.AccessTokensQueryVariables>;
export const AccessTokensNamesDocument = gql`
    query AccessTokensNames {
  myself {
    id
    apiTokens {
      nodes {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAccessTokensNamesQuery__
 *
 * To run a query within a React component, call `useAccessTokensNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokensNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokensNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessTokensNamesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AccessTokensNamesQuery, Types.AccessTokensNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccessTokensNamesQuery, Types.AccessTokensNamesQueryVariables>(AccessTokensNamesDocument, options);
      }
export function useAccessTokensNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccessTokensNamesQuery, Types.AccessTokensNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccessTokensNamesQuery, Types.AccessTokensNamesQueryVariables>(AccessTokensNamesDocument, options);
        }
export type AccessTokensNamesQueryHookResult = ReturnType<typeof useAccessTokensNamesQuery>;
export type AccessTokensNamesLazyQueryHookResult = ReturnType<typeof useAccessTokensNamesLazyQuery>;
export type AccessTokensNamesQueryResult = Apollo.QueryResult<Types.AccessTokensNamesQuery, Types.AccessTokensNamesQueryVariables>;
export const CreateAccessTokenDocument = gql`
    mutation CreateAccessToken($input: CreateApiTokenInput!) {
  apiToken {
    create(input: $input) {
      id
      token
      name
      createdAt
      revoked
    }
  }
}
    `;
export type CreateAccessTokenMutationFn = Apollo.MutationFunction<Types.CreateAccessTokenMutation, Types.CreateAccessTokenMutationVariables>;

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAccessTokenMutation, Types.CreateAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAccessTokenMutation, Types.CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, options);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = Apollo.MutationResult<Types.CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = Apollo.BaseMutationOptions<Types.CreateAccessTokenMutation, Types.CreateAccessTokenMutationVariables>;
export const AccessTokenDocument = gql`
    query AccessToken($tokenId: String!) {
  myself {
    accessTokenById(id: $tokenId) @client {
      token
      id
      name
      createdAt
      revoked
    }
  }
}
    `;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useAccessTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.AccessTokenQuery, Types.AccessTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccessTokenQuery, Types.AccessTokenQueryVariables>(AccessTokenDocument, options);
      }
export function useAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccessTokenQuery, Types.AccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccessTokenQuery, Types.AccessTokenQueryVariables>(AccessTokenDocument, options);
        }
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<typeof useAccessTokenLazyQuery>;
export type AccessTokenQueryResult = Apollo.QueryResult<Types.AccessTokenQuery, Types.AccessTokenQueryVariables>;
export const RevokeAccessTokenDocument = gql`
    mutation RevokeAccessToken($tokenId: String!) {
  apiToken {
    revoke(id: $tokenId) {
      token
      id
      name
      createdAt
      revoked
    }
  }
}
    `;
export type RevokeAccessTokenMutationFn = Apollo.MutationFunction<Types.RevokeAccessTokenMutation, Types.RevokeAccessTokenMutationVariables>;

/**
 * __useRevokeAccessTokenMutation__
 *
 * To run a mutation, you first call `useRevokeAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessTokenMutation, { data, loading, error }] = useRevokeAccessTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useRevokeAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeAccessTokenMutation, Types.RevokeAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeAccessTokenMutation, Types.RevokeAccessTokenMutationVariables>(RevokeAccessTokenDocument, options);
      }
export type RevokeAccessTokenMutationHookResult = ReturnType<typeof useRevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationResult = Apollo.MutationResult<Types.RevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationOptions = Apollo.BaseMutationOptions<Types.RevokeAccessTokenMutation, Types.RevokeAccessTokenMutationVariables>;
export const DeleteDataRoomDocument = gql`
    mutation DeleteDataRoom($dataRoomId: String!) {
  draftDataRoom {
    delete(id: $dataRoomId)
  }
}
    `;
export type DeleteDataRoomMutationFn = Apollo.MutationFunction<Types.DeleteDataRoomMutation, Types.DeleteDataRoomMutationVariables>;

/**
 * __useDeleteDataRoomMutation__
 *
 * To run a mutation, you first call `useDeleteDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataRoomMutation, { data, loading, error }] = useDeleteDataRoomMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDeleteDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDataRoomMutation, Types.DeleteDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDataRoomMutation, Types.DeleteDataRoomMutationVariables>(DeleteDataRoomDocument, options);
      }
export type DeleteDataRoomMutationHookResult = ReturnType<typeof useDeleteDataRoomMutation>;
export type DeleteDataRoomMutationResult = Apollo.MutationResult<Types.DeleteDataRoomMutation>;
export type DeleteDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDataRoomMutation, Types.DeleteDataRoomMutationVariables>;
export const PublishDraftDataRoomDocument = gql`
    mutation PublishDraftDataRoom($input: PublishDraftDataRoomLocalInput!) {
  publishDraftDataRoom(input: $input) @client {
    id
  }
}
    `;
export type PublishDraftDataRoomMutationFn = Apollo.MutationFunction<Types.PublishDraftDataRoomMutation, Types.PublishDraftDataRoomMutationVariables>;

/**
 * __usePublishDraftDataRoomMutation__
 *
 * To run a mutation, you first call `usePublishDraftDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftDataRoomMutation, { data, loading, error }] = usePublishDraftDataRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDraftDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishDraftDataRoomMutation, Types.PublishDraftDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishDraftDataRoomMutation, Types.PublishDraftDataRoomMutationVariables>(PublishDraftDataRoomDocument, options);
      }
export type PublishDraftDataRoomMutationHookResult = ReturnType<typeof usePublishDraftDataRoomMutation>;
export type PublishDraftDataRoomMutationResult = Apollo.MutationResult<Types.PublishDraftDataRoomMutation>;
export type PublishDraftDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.PublishDraftDataRoomMutation, Types.PublishDraftDataRoomMutationVariables>;
export const DraftDataRoomOverviewDocument = gql`
    query DraftDataRoomOverview($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    name: title
    createdAt
    owner {
      id
      email
    }
    userSettings {
      ...DataRoomUserSettings
    }
    participantUsers: participants {
      nodes {
        id
        userEmail
      }
    }
  }
}
    ${DataRoomUserSettingsFragment}`;

/**
 * __useDraftDataRoomOverviewQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomOverviewQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomOverviewQuery, Types.DraftDataRoomOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomOverviewQuery, Types.DraftDataRoomOverviewQueryVariables>(DraftDataRoomOverviewDocument, options);
      }
export function useDraftDataRoomOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomOverviewQuery, Types.DraftDataRoomOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomOverviewQuery, Types.DraftDataRoomOverviewQueryVariables>(DraftDataRoomOverviewDocument, options);
        }
export type DraftDataRoomOverviewQueryHookResult = ReturnType<typeof useDraftDataRoomOverviewQuery>;
export type DraftDataRoomOverviewLazyQueryHookResult = ReturnType<typeof useDraftDataRoomOverviewLazyQuery>;
export type DraftDataRoomOverviewQueryResult = Apollo.QueryResult<Types.DraftDataRoomOverviewQuery, Types.DraftDataRoomOverviewQueryVariables>;
export const PublishedDataRoomOverviewDocument = gql`
    query PublishedDataRoomOverview($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    name: title
    createdAt
    owner {
      id
      email
    }
    userSettings {
      ...DataRoomUserSettings
    }
    activatedAt
    driverAttestationHash
    isStopped
    deactivatedAt
    participantUsers {
      nodes {
        id
        userEmail: email
        organization {
          id
          name
        }
      }
    }
  }
}
    ${DataRoomUserSettingsFragment}`;

/**
 * __usePublishedDataRoomOverviewQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomOverviewQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomOverviewQuery, Types.PublishedDataRoomOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomOverviewQuery, Types.PublishedDataRoomOverviewQueryVariables>(PublishedDataRoomOverviewDocument, options);
      }
export function usePublishedDataRoomOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomOverviewQuery, Types.PublishedDataRoomOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomOverviewQuery, Types.PublishedDataRoomOverviewQueryVariables>(PublishedDataRoomOverviewDocument, options);
        }
export type PublishedDataRoomOverviewQueryHookResult = ReturnType<typeof usePublishedDataRoomOverviewQuery>;
export type PublishedDataRoomOverviewLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomOverviewLazyQuery>;
export type PublishedDataRoomOverviewQueryResult = Apollo.QueryResult<Types.PublishedDataRoomOverviewQuery, Types.PublishedDataRoomOverviewQueryVariables>;
export const PublishedLookalikeMediaDataRoomOverviewDocument = gql`
    query PublishedLookalikeMediaDataRoomOverview($dataRoomId: String!) {
  publishedLookalikeMediaDataRoom(id: $dataRoomId) {
    id
    name: title
    createdAt
    owner {
      id
      email
    }
    userSettings {
      ...DataRoomUserSettings
    }
    activatedAt
    driverAttestationHash
    isStopped
    deactivatedAt
    participantUsers {
      nodes {
        id
        userEmail: email
        organization {
          id
          name
        }
      }
    }
  }
}
    ${DataRoomUserSettingsFragment}`;

/**
 * __usePublishedLookalikeMediaDataRoomOverviewQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomOverviewQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomOverviewQuery, Types.PublishedLookalikeMediaDataRoomOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomOverviewQuery, Types.PublishedLookalikeMediaDataRoomOverviewQueryVariables>(PublishedLookalikeMediaDataRoomOverviewDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomOverviewQuery, Types.PublishedLookalikeMediaDataRoomOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomOverviewQuery, Types.PublishedLookalikeMediaDataRoomOverviewQueryVariables>(PublishedLookalikeMediaDataRoomOverviewDocument, options);
        }
export type PublishedLookalikeMediaDataRoomOverviewQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomOverviewQuery>;
export type PublishedLookalikeMediaDataRoomOverviewLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomOverviewLazyQuery>;
export type PublishedLookalikeMediaDataRoomOverviewQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomOverviewQuery, Types.PublishedLookalikeMediaDataRoomOverviewQueryVariables>;
export const PublishedMediaDataRoomOverviewDocument = gql`
    query PublishedMediaDataRoomOverview($dataRoomId: String!) {
  publishedMediaDataRoom(id: $dataRoomId) {
    id
    name: title
    createdAt
    owner {
      id
      email
    }
    userSettings {
      ...DataRoomUserSettings
    }
    activatedAt
    driverAttestationHash
    isStopped
    deactivatedAt
    participantUsers {
      nodes {
        id
        userEmail: email
        organization {
          id
          name
        }
      }
    }
  }
}
    ${DataRoomUserSettingsFragment}`;

/**
 * __usePublishedMediaDataRoomOverviewQuery__
 *
 * To run a query within a React component, call `usePublishedMediaDataRoomOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaDataRoomOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaDataRoomOverviewQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedMediaDataRoomOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaDataRoomOverviewQuery, Types.PublishedMediaDataRoomOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaDataRoomOverviewQuery, Types.PublishedMediaDataRoomOverviewQueryVariables>(PublishedMediaDataRoomOverviewDocument, options);
      }
export function usePublishedMediaDataRoomOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaDataRoomOverviewQuery, Types.PublishedMediaDataRoomOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaDataRoomOverviewQuery, Types.PublishedMediaDataRoomOverviewQueryVariables>(PublishedMediaDataRoomOverviewDocument, options);
        }
export type PublishedMediaDataRoomOverviewQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomOverviewQuery>;
export type PublishedMediaDataRoomOverviewLazyQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomOverviewLazyQuery>;
export type PublishedMediaDataRoomOverviewQueryResult = Apollo.QueryResult<Types.PublishedMediaDataRoomOverviewQuery, Types.PublishedMediaDataRoomOverviewQueryVariables>;
export const PublishedMediaInsightsDcrOverviewDocument = gql`
    query PublishedMediaInsightsDcrOverview($dataRoomId: String!) {
  publishedMediaInsightsDcr(id: $dataRoomId) {
    id
    name: title
    createdAt
    owner {
      id
      email
    }
    userSettings {
      ...DataRoomUserSettings
    }
    activatedAt
    driverAttestationHash
    isStopped
    deactivatedAt
    participantUsers {
      nodes {
        id
        userEmail: email
        organization {
          id
          name
        }
      }
    }
  }
}
    ${DataRoomUserSettingsFragment}`;

/**
 * __usePublishedMediaInsightsDcrOverviewQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrOverviewQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrOverviewQuery, Types.PublishedMediaInsightsDcrOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrOverviewQuery, Types.PublishedMediaInsightsDcrOverviewQueryVariables>(PublishedMediaInsightsDcrOverviewDocument, options);
      }
export function usePublishedMediaInsightsDcrOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrOverviewQuery, Types.PublishedMediaInsightsDcrOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrOverviewQuery, Types.PublishedMediaInsightsDcrOverviewQueryVariables>(PublishedMediaInsightsDcrOverviewDocument, options);
        }
export type PublishedMediaInsightsDcrOverviewQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrOverviewQuery>;
export type PublishedMediaInsightsDcrOverviewLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrOverviewLazyQuery>;
export type PublishedMediaInsightsDcrOverviewQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrOverviewQuery, Types.PublishedMediaInsightsDcrOverviewQueryVariables>;
export const DevComputeNodesForReportDocument = gql`
    query DevComputeNodesForReport($id: String!) {
  publishedDataRoom(id: $id) {
    id
    playgrounds {
      nodes {
        id
        owner {
          email
        }
        draftNode {
          id
          name
          permissions {
            nodes {
              ... on DraftAnalystPermission {
                participant {
                  id
                  userEmail
                }
              }
            }
          }
          ... on DraftSyntheticNode {
            id
            accuracy
            computationType
            includeReportWithStats
            columns {
              nodes {
                id
                index
                name
                maskType
                dataType
                shouldMaskColumn
              }
            }
            dependencyUpdatedAt
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftPostNode {
            id
            name
            computationType
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftPreviewNode {
            id
            name
            computationType
            quotaBytes
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftMatchNode {
            id
            config
            computationType
            dependencies {
              nodes {
                ... on DraftNodeConnection {
                  draftNode {
                    id
                    name
                  }
                }
                ... on PublishedNodeConnection {
                  computeNodeId
                }
              }
            }
          }
          ... on DraftS3SinkNode {
            computationType
          }
          ... on DraftScriptingNode {
            id
            name
            computationType
            scripts {
              nodes {
                id
                name
                content
                isMainScript
              }
            }
            dependencies {
              nodes {
                ... on DraftNodeConnection {
                  draftNode {
                    id
                    name
                  }
                }
                ... on PublishedNodeConnection {
                  computeNodeId
                  publishedDataRoomId
                }
              }
            }
            output
            scriptingLanguage
          }
          ... on DraftSqlNode {
            id
            name
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSqliteNode {
            id
            name
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSyntheticNode {
            id
            accuracy
            computationType
            includeReportWithStats
            columns {
              nodes {
                id
                index
                name
                maskType
                dataType
                shouldMaskColumn
              }
            }
            dependencyUpdatedAt
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDevComputeNodesForReportQuery__
 *
 * To run a query within a React component, call `useDevComputeNodesForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodesForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevComputeNodesForReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDevComputeNodesForReportQuery(baseOptions: Apollo.QueryHookOptions<Types.DevComputeNodesForReportQuery, Types.DevComputeNodesForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DevComputeNodesForReportQuery, Types.DevComputeNodesForReportQueryVariables>(DevComputeNodesForReportDocument, options);
      }
export function useDevComputeNodesForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DevComputeNodesForReportQuery, Types.DevComputeNodesForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DevComputeNodesForReportQuery, Types.DevComputeNodesForReportQueryVariables>(DevComputeNodesForReportDocument, options);
        }
export type DevComputeNodesForReportQueryHookResult = ReturnType<typeof useDevComputeNodesForReportQuery>;
export type DevComputeNodesForReportLazyQueryHookResult = ReturnType<typeof useDevComputeNodesForReportLazyQuery>;
export type DevComputeNodesForReportQueryResult = Apollo.QueryResult<Types.DevComputeNodesForReportQuery, Types.DevComputeNodesForReportQueryVariables>;
export const RequestsForReportDocument = gql`
    query RequestsForReport($id: String!) {
  publishedDataRoom(id: $id) {
    id
    requests {
      nodes {
        id
        draftNode {
          id
          name
          permissions {
            nodes {
              ... on DraftAnalystPermission {
                participant {
                  id
                  userEmail
                }
              }
            }
          }
          ... on DraftSyntheticNode {
            id
            accuracy
            computationType
            includeReportWithStats
            columns {
              nodes {
                id
                index
                name
                maskType
                dataType
                shouldMaskColumn
              }
            }
            dependencyUpdatedAt
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftPostNode {
            id
            name
            computationType
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftPreviewNode {
            id
            name
            computationType
            quotaBytes
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
          ... on DraftMatchNode {
            id
            config
            computationType
            dependencies {
              nodes {
                ... on DraftNodeConnection {
                  draftNode {
                    id
                    name
                  }
                }
                ... on PublishedNodeConnection {
                  computeNodeId
                }
              }
            }
          }
          ... on DraftS3SinkNode {
            computationType
          }
          ... on DraftScriptingNode {
            id
            name
            computationType
            scripts {
              nodes {
                id
                name
                content
                isMainScript
              }
            }
            dependencies {
              nodes {
                ... on DraftNodeConnection {
                  draftNode {
                    id
                    name
                  }
                }
                ... on PublishedNodeConnection {
                  computeNodeId
                  publishedDataRoomId
                }
              }
            }
            output
            scriptingLanguage
          }
          ... on DraftSqlNode {
            id
            name
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSqliteNode {
            id
            name
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSyntheticNode {
            id
            accuracy
            computationType
            includeReportWithStats
            columns {
              nodes {
                id
                index
                name
                maskType
                dataType
                shouldMaskColumn
              }
            }
            dependencyUpdatedAt
            dependency {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
              ... on PublishedNodeConnection {
                computeNodeId
                publishedDataRoomId
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRequestsForReportQuery__
 *
 * To run a query within a React component, call `useRequestsForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsForReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestsForReportQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestsForReportQuery, Types.RequestsForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestsForReportQuery, Types.RequestsForReportQueryVariables>(RequestsForReportDocument, options);
      }
export function useRequestsForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestsForReportQuery, Types.RequestsForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestsForReportQuery, Types.RequestsForReportQueryVariables>(RequestsForReportDocument, options);
        }
export type RequestsForReportQueryHookResult = ReturnType<typeof useRequestsForReportQuery>;
export type RequestsForReportLazyQueryHookResult = ReturnType<typeof useRequestsForReportLazyQuery>;
export type RequestsForReportQueryResult = Apollo.QueryResult<Types.RequestsForReportQuery, Types.RequestsForReportQueryVariables>;
export const DraftParticipantCreateDocument = gql`
    mutation DraftParticipantCreate($draftDataRoomId: String!, $userEmail: String!) {
  draftParticipant {
    createForDraftDataRoom(
      input: {userEmail: $userEmail}
      draftDataRoomId: $draftDataRoomId
    ) {
      id
      userEmail
    }
  }
}
    `;
export type DraftParticipantCreateMutationFn = Apollo.MutationFunction<Types.DraftParticipantCreateMutation, Types.DraftParticipantCreateMutationVariables>;

/**
 * __useDraftParticipantCreateMutation__
 *
 * To run a mutation, you first call `useDraftParticipantCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantCreateMutation, { data, loading, error }] = useDraftParticipantCreateMutation({
 *   variables: {
 *      draftDataRoomId: // value for 'draftDataRoomId'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useDraftParticipantCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantCreateMutation, Types.DraftParticipantCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantCreateMutation, Types.DraftParticipantCreateMutationVariables>(DraftParticipantCreateDocument, options);
      }
export type DraftParticipantCreateMutationHookResult = ReturnType<typeof useDraftParticipantCreateMutation>;
export type DraftParticipantCreateMutationResult = Apollo.MutationResult<Types.DraftParticipantCreateMutation>;
export type DraftParticipantCreateMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantCreateMutation, Types.DraftParticipantCreateMutationVariables>;
export const DraftParticipantCreateAnalystPermissionDocument = gql`
    mutation DraftParticipantCreateAnalystPermission($draftNodeId: String!, $draftParticipantId: String!) {
  draftParticipant {
    createAnalystPermission(
      input: {draftNodeId: $draftNodeId, draftParticipantId: $draftParticipantId}
    ) {
      node {
        id
        name
      }
    }
  }
}
    `;
export type DraftParticipantCreateAnalystPermissionMutationFn = Apollo.MutationFunction<Types.DraftParticipantCreateAnalystPermissionMutation, Types.DraftParticipantCreateAnalystPermissionMutationVariables>;

/**
 * __useDraftParticipantCreateAnalystPermissionMutation__
 *
 * To run a mutation, you first call `useDraftParticipantCreateAnalystPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantCreateAnalystPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantCreateAnalystPermissionMutation, { data, loading, error }] = useDraftParticipantCreateAnalystPermissionMutation({
 *   variables: {
 *      draftNodeId: // value for 'draftNodeId'
 *      draftParticipantId: // value for 'draftParticipantId'
 *   },
 * });
 */
export function useDraftParticipantCreateAnalystPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantCreateAnalystPermissionMutation, Types.DraftParticipantCreateAnalystPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantCreateAnalystPermissionMutation, Types.DraftParticipantCreateAnalystPermissionMutationVariables>(DraftParticipantCreateAnalystPermissionDocument, options);
      }
export type DraftParticipantCreateAnalystPermissionMutationHookResult = ReturnType<typeof useDraftParticipantCreateAnalystPermissionMutation>;
export type DraftParticipantCreateAnalystPermissionMutationResult = Apollo.MutationResult<Types.DraftParticipantCreateAnalystPermissionMutation>;
export type DraftParticipantCreateAnalystPermissionMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantCreateAnalystPermissionMutation, Types.DraftParticipantCreateAnalystPermissionMutationVariables>;
export const DraftParticipantDeleteAnalystPermissionDocument = gql`
    mutation DraftParticipantDeleteAnalystPermission($computeNodeId: String!, $participantId: String!) {
  draftParticipant {
    deleteAnalystPermissionByDraftNodeId(
      draftNodeId: $computeNodeId
      participantId: $participantId
    )
  }
}
    `;
export type DraftParticipantDeleteAnalystPermissionMutationFn = Apollo.MutationFunction<Types.DraftParticipantDeleteAnalystPermissionMutation, Types.DraftParticipantDeleteAnalystPermissionMutationVariables>;

/**
 * __useDraftParticipantDeleteAnalystPermissionMutation__
 *
 * To run a mutation, you first call `useDraftParticipantDeleteAnalystPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantDeleteAnalystPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantDeleteAnalystPermissionMutation, { data, loading, error }] = useDraftParticipantDeleteAnalystPermissionMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useDraftParticipantDeleteAnalystPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantDeleteAnalystPermissionMutation, Types.DraftParticipantDeleteAnalystPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantDeleteAnalystPermissionMutation, Types.DraftParticipantDeleteAnalystPermissionMutationVariables>(DraftParticipantDeleteAnalystPermissionDocument, options);
      }
export type DraftParticipantDeleteAnalystPermissionMutationHookResult = ReturnType<typeof useDraftParticipantDeleteAnalystPermissionMutation>;
export type DraftParticipantDeleteAnalystPermissionMutationResult = Apollo.MutationResult<Types.DraftParticipantDeleteAnalystPermissionMutation>;
export type DraftParticipantDeleteAnalystPermissionMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantDeleteAnalystPermissionMutation, Types.DraftParticipantDeleteAnalystPermissionMutationVariables>;
export const DraftParticipantCreateDataOwnerPermissionDocument = gql`
    mutation DraftParticipantCreateDataOwnerPermission($draftNodeId: String!, $draftParticipantId: String!) {
  draftParticipant {
    createDataOwnerPermission(
      input: {draftNodeId: $draftNodeId, draftParticipantId: $draftParticipantId}
    ) {
      node {
        id
        name
      }
    }
  }
}
    `;
export type DraftParticipantCreateDataOwnerPermissionMutationFn = Apollo.MutationFunction<Types.DraftParticipantCreateDataOwnerPermissionMutation, Types.DraftParticipantCreateDataOwnerPermissionMutationVariables>;

/**
 * __useDraftParticipantCreateDataOwnerPermissionMutation__
 *
 * To run a mutation, you first call `useDraftParticipantCreateDataOwnerPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantCreateDataOwnerPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantCreateDataOwnerPermissionMutation, { data, loading, error }] = useDraftParticipantCreateDataOwnerPermissionMutation({
 *   variables: {
 *      draftNodeId: // value for 'draftNodeId'
 *      draftParticipantId: // value for 'draftParticipantId'
 *   },
 * });
 */
export function useDraftParticipantCreateDataOwnerPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantCreateDataOwnerPermissionMutation, Types.DraftParticipantCreateDataOwnerPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantCreateDataOwnerPermissionMutation, Types.DraftParticipantCreateDataOwnerPermissionMutationVariables>(DraftParticipantCreateDataOwnerPermissionDocument, options);
      }
export type DraftParticipantCreateDataOwnerPermissionMutationHookResult = ReturnType<typeof useDraftParticipantCreateDataOwnerPermissionMutation>;
export type DraftParticipantCreateDataOwnerPermissionMutationResult = Apollo.MutationResult<Types.DraftParticipantCreateDataOwnerPermissionMutation>;
export type DraftParticipantCreateDataOwnerPermissionMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantCreateDataOwnerPermissionMutation, Types.DraftParticipantCreateDataOwnerPermissionMutationVariables>;
export const DraftParticipantDeleteDataOwnerPermissionDocument = gql`
    mutation DraftParticipantDeleteDataOwnerPermission($dataNodeId: String!, $participantId: String!) {
  draftParticipant {
    deleteDataOwnerPermissionByDraftNodeId(
      draftNodeId: $dataNodeId
      participantId: $participantId
    )
  }
}
    `;
export type DraftParticipantDeleteDataOwnerPermissionMutationFn = Apollo.MutationFunction<Types.DraftParticipantDeleteDataOwnerPermissionMutation, Types.DraftParticipantDeleteDataOwnerPermissionMutationVariables>;

/**
 * __useDraftParticipantDeleteDataOwnerPermissionMutation__
 *
 * To run a mutation, you first call `useDraftParticipantDeleteDataOwnerPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantDeleteDataOwnerPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantDeleteDataOwnerPermissionMutation, { data, loading, error }] = useDraftParticipantDeleteDataOwnerPermissionMutation({
 *   variables: {
 *      dataNodeId: // value for 'dataNodeId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useDraftParticipantDeleteDataOwnerPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantDeleteDataOwnerPermissionMutation, Types.DraftParticipantDeleteDataOwnerPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantDeleteDataOwnerPermissionMutation, Types.DraftParticipantDeleteDataOwnerPermissionMutationVariables>(DraftParticipantDeleteDataOwnerPermissionDocument, options);
      }
export type DraftParticipantDeleteDataOwnerPermissionMutationHookResult = ReturnType<typeof useDraftParticipantDeleteDataOwnerPermissionMutation>;
export type DraftParticipantDeleteDataOwnerPermissionMutationResult = Apollo.MutationResult<Types.DraftParticipantDeleteDataOwnerPermissionMutation>;
export type DraftParticipantDeleteDataOwnerPermissionMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantDeleteDataOwnerPermissionMutation, Types.DraftParticipantDeleteDataOwnerPermissionMutationVariables>;
export const DraftParticipantDeleteDocument = gql`
    mutation DraftParticipantDelete($id: String!) {
  draftParticipant {
    delete(id: $id)
  }
}
    `;
export type DraftParticipantDeleteMutationFn = Apollo.MutationFunction<Types.DraftParticipantDeleteMutation, Types.DraftParticipantDeleteMutationVariables>;

/**
 * __useDraftParticipantDeleteMutation__
 *
 * To run a mutation, you first call `useDraftParticipantDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantDeleteMutation, { data, loading, error }] = useDraftParticipantDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftParticipantDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantDeleteMutation, Types.DraftParticipantDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantDeleteMutation, Types.DraftParticipantDeleteMutationVariables>(DraftParticipantDeleteDocument, options);
      }
export type DraftParticipantDeleteMutationHookResult = ReturnType<typeof useDraftParticipantDeleteMutation>;
export type DraftParticipantDeleteMutationResult = Apollo.MutationResult<Types.DraftParticipantDeleteMutation>;
export type DraftParticipantDeleteMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantDeleteMutation, Types.DraftParticipantDeleteMutationVariables>;
export const DraftDataRoomEnableImmutabilityDocument = gql`
    query DraftDataRoomEnableImmutability($id: String!) {
  draftDataRoom(id: $id) {
    id
    enableInteractivity
    enableDevelopment
  }
}
    `;

/**
 * __useDraftDataRoomEnableImmutabilityQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomEnableImmutabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomEnableImmutabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomEnableImmutabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomEnableImmutabilityQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomEnableImmutabilityQuery, Types.DraftDataRoomEnableImmutabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomEnableImmutabilityQuery, Types.DraftDataRoomEnableImmutabilityQueryVariables>(DraftDataRoomEnableImmutabilityDocument, options);
      }
export function useDraftDataRoomEnableImmutabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomEnableImmutabilityQuery, Types.DraftDataRoomEnableImmutabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomEnableImmutabilityQuery, Types.DraftDataRoomEnableImmutabilityQueryVariables>(DraftDataRoomEnableImmutabilityDocument, options);
        }
export type DraftDataRoomEnableImmutabilityQueryHookResult = ReturnType<typeof useDraftDataRoomEnableImmutabilityQuery>;
export type DraftDataRoomEnableImmutabilityLazyQueryHookResult = ReturnType<typeof useDraftDataRoomEnableImmutabilityLazyQuery>;
export type DraftDataRoomEnableImmutabilityQueryResult = Apollo.QueryResult<Types.DraftDataRoomEnableImmutabilityQuery, Types.DraftDataRoomEnableImmutabilityQueryVariables>;
export const DraftDataRoomUpdateEnableImmutabilityDocument = gql`
    mutation DraftDataRoomUpdateEnableImmutability($id: String!, $mutable: Boolean!) {
  draftDataRoom {
    update(
      input: {id: $id, enableInteractivity: $mutable, enableDevelopment: $mutable}
    ) {
      id
      enableInteractivity
      enableDevelopment
    }
  }
}
    `;
export type DraftDataRoomUpdateEnableImmutabilityMutationFn = Apollo.MutationFunction<Types.DraftDataRoomUpdateEnableImmutabilityMutation, Types.DraftDataRoomUpdateEnableImmutabilityMutationVariables>;

/**
 * __useDraftDataRoomUpdateEnableImmutabilityMutation__
 *
 * To run a mutation, you first call `useDraftDataRoomUpdateEnableImmutabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomUpdateEnableImmutabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftDataRoomUpdateEnableImmutabilityMutation, { data, loading, error }] = useDraftDataRoomUpdateEnableImmutabilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mutable: // value for 'mutable'
 *   },
 * });
 */
export function useDraftDataRoomUpdateEnableImmutabilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftDataRoomUpdateEnableImmutabilityMutation, Types.DraftDataRoomUpdateEnableImmutabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftDataRoomUpdateEnableImmutabilityMutation, Types.DraftDataRoomUpdateEnableImmutabilityMutationVariables>(DraftDataRoomUpdateEnableImmutabilityDocument, options);
      }
export type DraftDataRoomUpdateEnableImmutabilityMutationHookResult = ReturnType<typeof useDraftDataRoomUpdateEnableImmutabilityMutation>;
export type DraftDataRoomUpdateEnableImmutabilityMutationResult = Apollo.MutationResult<Types.DraftDataRoomUpdateEnableImmutabilityMutation>;
export type DraftDataRoomUpdateEnableImmutabilityMutationOptions = Apollo.BaseMutationOptions<Types.DraftDataRoomUpdateEnableImmutabilityMutation, Types.DraftDataRoomUpdateEnableImmutabilityMutationVariables>;
export const PublishedDataRoomEnableImmutabilityDocument = gql`
    query PublishedDataRoomEnableImmutability($id: String!) {
  publishedDataRoom(id: $id) {
    id
    enableInteractivity @client
  }
}
    `;

/**
 * __usePublishedDataRoomEnableImmutabilityQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomEnableImmutabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomEnableImmutabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomEnableImmutabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomEnableImmutabilityQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomEnableImmutabilityQuery, Types.PublishedDataRoomEnableImmutabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomEnableImmutabilityQuery, Types.PublishedDataRoomEnableImmutabilityQueryVariables>(PublishedDataRoomEnableImmutabilityDocument, options);
      }
export function usePublishedDataRoomEnableImmutabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomEnableImmutabilityQuery, Types.PublishedDataRoomEnableImmutabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomEnableImmutabilityQuery, Types.PublishedDataRoomEnableImmutabilityQueryVariables>(PublishedDataRoomEnableImmutabilityDocument, options);
        }
export type PublishedDataRoomEnableImmutabilityQueryHookResult = ReturnType<typeof usePublishedDataRoomEnableImmutabilityQuery>;
export type PublishedDataRoomEnableImmutabilityLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomEnableImmutabilityLazyQuery>;
export type PublishedDataRoomEnableImmutabilityQueryResult = Apollo.QueryResult<Types.PublishedDataRoomEnableImmutabilityQuery, Types.PublishedDataRoomEnableImmutabilityQueryVariables>;
export const DraftParticipantUpdateUserEmailDocument = gql`
    mutation DraftParticipantUpdateUserEmail($id: String!, $participantEmail: String!) {
  draftParticipant {
    update(input: {id: $id, participantEmail: $participantEmail}) {
      id
      userEmail
    }
  }
}
    `;
export type DraftParticipantUpdateUserEmailMutationFn = Apollo.MutationFunction<Types.DraftParticipantUpdateUserEmailMutation, Types.DraftParticipantUpdateUserEmailMutationVariables>;

/**
 * __useDraftParticipantUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useDraftParticipantUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftParticipantUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftParticipantUpdateUserEmailMutation, { data, loading, error }] = useDraftParticipantUpdateUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      participantEmail: // value for 'participantEmail'
 *   },
 * });
 */
export function useDraftParticipantUpdateUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftParticipantUpdateUserEmailMutation, Types.DraftParticipantUpdateUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftParticipantUpdateUserEmailMutation, Types.DraftParticipantUpdateUserEmailMutationVariables>(DraftParticipantUpdateUserEmailDocument, options);
      }
export type DraftParticipantUpdateUserEmailMutationHookResult = ReturnType<typeof useDraftParticipantUpdateUserEmailMutation>;
export type DraftParticipantUpdateUserEmailMutationResult = Apollo.MutationResult<Types.DraftParticipantUpdateUserEmailMutation>;
export type DraftParticipantUpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<Types.DraftParticipantUpdateUserEmailMutation, Types.DraftParticipantUpdateUserEmailMutationVariables>;
export const DraftDataRoomParticipantsDocument = gql`
    query DraftDataRoomParticipants($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftDataOwnerPermission {
              node {
                ... on DraftRawLeafNode {
                  id
                  name
                }
                ... on DraftTableLeafNode {
                  id
                  name
                }
              }
            }
            ... on DraftAnalystPermission {
              node {
                ... on DraftScriptingNode {
                  id
                  name
                }
                ... on DraftSqlNode {
                  id
                  name
                }
                ... on DraftSqliteNode {
                  id
                  name
                }
                ... on DraftMatchNode {
                  id
                  name
                }
                ... on DraftSyntheticNode {
                  id
                  name
                }
                ... on DraftPostNode {
                  id
                  name
                }
                ... on DraftPreviewNode {
                  id
                  name
                }
                ... on DraftS3SinkNode {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftDataRoomParticipantsQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomParticipantsQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomParticipantsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomParticipantsQuery, Types.DraftDataRoomParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomParticipantsQuery, Types.DraftDataRoomParticipantsQueryVariables>(DraftDataRoomParticipantsDocument, options);
      }
export function useDraftDataRoomParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomParticipantsQuery, Types.DraftDataRoomParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomParticipantsQuery, Types.DraftDataRoomParticipantsQueryVariables>(DraftDataRoomParticipantsDocument, options);
        }
export type DraftDataRoomParticipantsQueryHookResult = ReturnType<typeof useDraftDataRoomParticipantsQuery>;
export type DraftDataRoomParticipantsLazyQueryHookResult = ReturnType<typeof useDraftDataRoomParticipantsLazyQuery>;
export type DraftDataRoomParticipantsQueryResult = Apollo.QueryResult<Types.DraftDataRoomParticipantsQuery, Types.DraftDataRoomParticipantsQueryVariables>;
export const PublishedDataRoomParticipantsDocument = gql`
    query PublishedDataRoomParticipants($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    participants @client {
      userEmail
      permissions {
        ... on PublishedAnalystPermission {
          node {
            id
          }
        }
        ... on PublishedDataOwnerPermission {
          node {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedDataRoomParticipantsQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomParticipantsQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomParticipantsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomParticipantsQuery, Types.PublishedDataRoomParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomParticipantsQuery, Types.PublishedDataRoomParticipantsQueryVariables>(PublishedDataRoomParticipantsDocument, options);
      }
export function usePublishedDataRoomParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomParticipantsQuery, Types.PublishedDataRoomParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomParticipantsQuery, Types.PublishedDataRoomParticipantsQueryVariables>(PublishedDataRoomParticipantsDocument, options);
        }
export type PublishedDataRoomParticipantsQueryHookResult = ReturnType<typeof usePublishedDataRoomParticipantsQuery>;
export type PublishedDataRoomParticipantsLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomParticipantsLazyQuery>;
export type PublishedDataRoomParticipantsQueryResult = Apollo.QueryResult<Types.PublishedDataRoomParticipantsQuery, Types.PublishedDataRoomParticipantsQueryVariables>;
export const DataRoomsListDocument = gql`
    query DataRoomsList {
  dataRooms {
    nodes {
      ... on DraftDataRoom {
        ...NavigationDrawerDraftDataRoom
      }
      ... on PublishedDataRoom {
        ...NavigationDrawerPublishedDataRoom
      }
      ... on PublishedMediaDataRoom {
        ...NavigationDrawerPublishedMediaDataRoom
      }
      ... on PublishedLookalikeMediaDataRoom {
        ...NavigationDrawerPublishedLookalikeMediaDataRoom
      }
      ... on PublishedMediaInsightsDcr {
        ...NavigationDrawerPublishedMediaInsightsDcr
      }
      createdAt
      updatedAt
      owner {
        id
        email
        organization {
          id
          name
        }
      }
      userSettings {
        ...DataRoomUserSettings
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}
${DataRoomUserSettingsFragment}`;

/**
 * __useDataRoomsListQuery__
 *
 * To run a query within a React component, call `useDataRoomsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRoomsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRoomsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataRoomsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataRoomsListQuery, Types.DataRoomsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataRoomsListQuery, Types.DataRoomsListQueryVariables>(DataRoomsListDocument, options);
      }
export function useDataRoomsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataRoomsListQuery, Types.DataRoomsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataRoomsListQuery, Types.DataRoomsListQueryVariables>(DataRoomsListDocument, options);
        }
export type DataRoomsListQueryHookResult = ReturnType<typeof useDataRoomsListQuery>;
export type DataRoomsListLazyQueryHookResult = ReturnType<typeof useDataRoomsListLazyQuery>;
export type DataRoomsListQueryResult = Apollo.QueryResult<Types.DataRoomsListQuery, Types.DataRoomsListQueryVariables>;
export const CollaboratingOrganizationsDocument = gql`
    query CollaboratingOrganizations($organizationId: String!, $filter: CollaboratingOrganizationFilter) {
  organization(id: $organizationId) {
    id
    collaboratingOrganizations(filter: $filter) {
      nodes {
        numberOfCommonDataRooms
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCollaboratingOrganizationsQuery__
 *
 * To run a query within a React component, call `useCollaboratingOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaboratingOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaboratingOrganizationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCollaboratingOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<Types.CollaboratingOrganizationsQuery, Types.CollaboratingOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollaboratingOrganizationsQuery, Types.CollaboratingOrganizationsQueryVariables>(CollaboratingOrganizationsDocument, options);
      }
export function useCollaboratingOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollaboratingOrganizationsQuery, Types.CollaboratingOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollaboratingOrganizationsQuery, Types.CollaboratingOrganizationsQueryVariables>(CollaboratingOrganizationsDocument, options);
        }
export type CollaboratingOrganizationsQueryHookResult = ReturnType<typeof useCollaboratingOrganizationsQuery>;
export type CollaboratingOrganizationsLazyQueryHookResult = ReturnType<typeof useCollaboratingOrganizationsLazyQuery>;
export type CollaboratingOrganizationsQueryResult = Apollo.QueryResult<Types.CollaboratingOrganizationsQuery, Types.CollaboratingOrganizationsQueryVariables>;
export const DeactivateDataRoomDocument = gql`
    mutation DeactivateDataRoom($dataRoomId: String!) {
  publishedDataRoom {
    deactivate(id: $dataRoomId) {
      id
      deactivatedAt
      isStopped
      isActive
    }
  }
}
    `;
export type DeactivateDataRoomMutationFn = Apollo.MutationFunction<Types.DeactivateDataRoomMutation, Types.DeactivateDataRoomMutationVariables>;

/**
 * __useDeactivateDataRoomMutation__
 *
 * To run a mutation, you first call `useDeactivateDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateDataRoomMutation, { data, loading, error }] = useDeactivateDataRoomMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDeactivateDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateDataRoomMutation, Types.DeactivateDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateDataRoomMutation, Types.DeactivateDataRoomMutationVariables>(DeactivateDataRoomDocument, options);
      }
export type DeactivateDataRoomMutationHookResult = ReturnType<typeof useDeactivateDataRoomMutation>;
export type DeactivateDataRoomMutationResult = Apollo.MutationResult<Types.DeactivateDataRoomMutation>;
export type DeactivateDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateDataRoomMutation, Types.DeactivateDataRoomMutationVariables>;
export const ArchiveOrganizationDocument = gql`
    mutation ArchiveOrganization($organizationId: String!) {
  organization {
    archive(id: $organizationId) {
      record {
        ... on Organization {
          ...Organization
        }
      }
    }
  }
}
    ${OrganizationFragment}`;
export type ArchiveOrganizationMutationFn = Apollo.MutationFunction<Types.ArchiveOrganizationMutation, Types.ArchiveOrganizationMutationVariables>;

/**
 * __useArchiveOrganizationMutation__
 *
 * To run a mutation, you first call `useArchiveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrganizationMutation, { data, loading, error }] = useArchiveOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useArchiveOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchiveOrganizationMutation, Types.ArchiveOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchiveOrganizationMutation, Types.ArchiveOrganizationMutationVariables>(ArchiveOrganizationDocument, options);
      }
export type ArchiveOrganizationMutationHookResult = ReturnType<typeof useArchiveOrganizationMutation>;
export type ArchiveOrganizationMutationResult = Apollo.MutationResult<Types.ArchiveOrganizationMutation>;
export type ArchiveOrganizationMutationOptions = Apollo.BaseMutationOptions<Types.ArchiveOrganizationMutation, Types.ArchiveOrganizationMutationVariables>;
export const OrganizationDataRoomDetailsDocument = gql`
    query OrganizationDataRoomDetails($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    title
    id
    activatedAt
    deactivatedAt
    stoppedAt
    isActive
    owner {
      id
      email
    }
    participantUsers {
      nodes {
        id
        email
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationDataRoomDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationDataRoomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDataRoomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDataRoomDetailsQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useOrganizationDataRoomDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationDataRoomDetailsQuery, Types.OrganizationDataRoomDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationDataRoomDetailsQuery, Types.OrganizationDataRoomDetailsQueryVariables>(OrganizationDataRoomDetailsDocument, options);
      }
export function useOrganizationDataRoomDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationDataRoomDetailsQuery, Types.OrganizationDataRoomDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationDataRoomDetailsQuery, Types.OrganizationDataRoomDetailsQueryVariables>(OrganizationDataRoomDetailsDocument, options);
        }
export type OrganizationDataRoomDetailsQueryHookResult = ReturnType<typeof useOrganizationDataRoomDetailsQuery>;
export type OrganizationDataRoomDetailsLazyQueryHookResult = ReturnType<typeof useOrganizationDataRoomDetailsLazyQuery>;
export type OrganizationDataRoomDetailsQueryResult = Apollo.QueryResult<Types.OrganizationDataRoomDetailsQuery, Types.OrganizationDataRoomDetailsQueryVariables>;
export const OrganizationDataRoomsDocument = gql`
    query OrganizationDataRooms($organizationId: String!, $filter: OrganizationDataRoomFilter, $sortBy: DataRoomSortBy) {
  organization(id: $organizationId) {
    id
    participatingDataRooms(filter: $filter, sortBy: $sortBy) {
      nodes {
        id
        title
        createdAt
        owner {
          email
          id
          organization {
            id
            name
          }
        }
        ... on PublishedDataRoom {
          isActive
          deactivatedAt
        }
        ... on PublishedMediaDataRoom {
          isActive
          deactivatedAt
        }
        ... on PublishedLookalikeMediaDataRoom {
          isActive
          deactivatedAt
        }
        ... on PublishedMediaInsightsDcr {
          isActive
          deactivatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationDataRoomsQuery__
 *
 * To run a query within a React component, call `useOrganizationDataRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDataRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDataRoomsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOrganizationDataRoomsQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationDataRoomsQuery, Types.OrganizationDataRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationDataRoomsQuery, Types.OrganizationDataRoomsQueryVariables>(OrganizationDataRoomsDocument, options);
      }
export function useOrganizationDataRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationDataRoomsQuery, Types.OrganizationDataRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationDataRoomsQuery, Types.OrganizationDataRoomsQueryVariables>(OrganizationDataRoomsDocument, options);
        }
export type OrganizationDataRoomsQueryHookResult = ReturnType<typeof useOrganizationDataRoomsQuery>;
export type OrganizationDataRoomsLazyQueryHookResult = ReturnType<typeof useOrganizationDataRoomsLazyQuery>;
export type OrganizationDataRoomsQueryResult = Apollo.QueryResult<Types.OrganizationDataRoomsQuery, Types.OrganizationDataRoomsQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  organization {
    create(input: $input) {
      id
      createdAt
      createdBy {
        id
        email
      }
      updatedAt
      name
      logo
      workerTypes {
        nodes {
          id
          name
          shortName
        }
      }
      state
      hasAdvertiserFeatures
      hasAnalyticsFeatures
      hasPublisherFeatures
      hasDataPartnerFeatures
      canViewDataPartners
      domains
      users {
        nodes {
          id
          email
          userRole
          isDemoUser
          createdAt
        }
        totalCount
      }
      participatingDataRooms {
        totalCount
      }
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<Types.CreateOrganizationMutation, Types.CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrganizationMutation, Types.CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrganizationMutation, Types.CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<Types.CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrganizationMutation, Types.CreateOrganizationMutationVariables>;
export const OrganizationsDocument = gql`
    query Organizations {
  organizations {
    nodes {
      id
      createdAt
      createdBy {
        id
        email
      }
      updatedAt
      name
      logo
      domains
      workerTypes {
        nodes {
          id
          name
          shortName
        }
      }
      state
      hasAdvertiserFeatures
      hasAnalyticsFeatures
      hasPublisherFeatures
      hasDataPartnerFeatures
      canViewDataPartners
      users {
        nodes {
          id
          email
          userRole
          isDemoUser
          createdAt
        }
        totalCount
      }
      participatingDataRooms {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrganizationsQuery, Types.OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationsQuery, Types.OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationsQuery, Types.OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationsQuery, Types.OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<Types.OrganizationsQuery, Types.OrganizationsQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: String!) {
  organization {
    deleteUser(id: $userId) {
      id
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;
export const UserDetailsDocument = gql`
    query UserDetails($userId: String!) {
  user(id: $userId) {
    id
    email
    userRole
    createdAt
    isDemoUser
    logo
    organization {
      id
      domains
      name
      state
    }
    createdBy {
      email
      id
      organization {
        id
        name
      }
    }
    dataRooms {
      nodes {
        id
        createdAt
        title
      }
      totalCount
    }
    datasets {
      nodes {
        id
        name
        createdAt
      }
    }
  }
}
    `;

/**
 * __useUserDetailsQuery__
 *
 * To run a query within a React component, call `useUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.UserDetailsQuery, Types.UserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserDetailsQuery, Types.UserDetailsQueryVariables>(UserDetailsDocument, options);
      }
export function useUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserDetailsQuery, Types.UserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserDetailsQuery, Types.UserDetailsQueryVariables>(UserDetailsDocument, options);
        }
export type UserDetailsQueryHookResult = ReturnType<typeof useUserDetailsQuery>;
export type UserDetailsLazyQueryHookResult = ReturnType<typeof useUserDetailsLazyQuery>;
export type UserDetailsQueryResult = Apollo.QueryResult<Types.UserDetailsQuery, Types.UserDetailsQueryVariables>;
export const UpdateUserLogoDocument = gql`
    mutation UpdateUserLogo($input: UpdateUserInput!) {
  user {
    update(input: $input) {
      record {
        id
        logo
      }
    }
  }
}
    `;
export type UpdateUserLogoMutationFn = Apollo.MutationFunction<Types.UpdateUserLogoMutation, Types.UpdateUserLogoMutationVariables>;

/**
 * __useUpdateUserLogoMutation__
 *
 * To run a mutation, you first call `useUpdateUserLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLogoMutation, { data, loading, error }] = useUpdateUserLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserLogoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserLogoMutation, Types.UpdateUserLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserLogoMutation, Types.UpdateUserLogoMutationVariables>(UpdateUserLogoDocument, options);
      }
export type UpdateUserLogoMutationHookResult = ReturnType<typeof useUpdateUserLogoMutation>;
export type UpdateUserLogoMutationResult = Apollo.MutationResult<Types.UpdateUserLogoMutation>;
export type UpdateUserLogoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserLogoMutation, Types.UpdateUserLogoMutationVariables>;
export const EditUserOrganizationDocument = gql`
    mutation EditUserOrganization($input: SetUserOrganizationInput!) {
  user {
    setOrganization(input: $input) {
      record {
        id
        organization {
          id
        }
      }
    }
  }
}
    `;
export type EditUserOrganizationMutationFn = Apollo.MutationFunction<Types.EditUserOrganizationMutation, Types.EditUserOrganizationMutationVariables>;

/**
 * __useEditUserOrganizationMutation__
 *
 * To run a mutation, you first call `useEditUserOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserOrganizationMutation, { data, loading, error }] = useEditUserOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditUserOrganizationMutation, Types.EditUserOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditUserOrganizationMutation, Types.EditUserOrganizationMutationVariables>(EditUserOrganizationDocument, options);
      }
export type EditUserOrganizationMutationHookResult = ReturnType<typeof useEditUserOrganizationMutation>;
export type EditUserOrganizationMutationResult = Apollo.MutationResult<Types.EditUserOrganizationMutation>;
export type EditUserOrganizationMutationOptions = Apollo.BaseMutationOptions<Types.EditUserOrganizationMutation, Types.EditUserOrganizationMutationVariables>;
export const EditUserRoleDocument = gql`
    mutation EditUserRole($input: SetUserRoleInput!, $userId: String!) {
  user {
    setRole(input: $input) {
      record {
        id
        userRole
      }
      query {
        user(id: $userId) {
          id
          userRole
        }
      }
    }
  }
}
    `;
export type EditUserRoleMutationFn = Apollo.MutationFunction<Types.EditUserRoleMutation, Types.EditUserRoleMutationVariables>;

/**
 * __useEditUserRoleMutation__
 *
 * To run a mutation, you first call `useEditUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserRoleMutation, { data, loading, error }] = useEditUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEditUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditUserRoleMutation, Types.EditUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditUserRoleMutation, Types.EditUserRoleMutationVariables>(EditUserRoleDocument, options);
      }
export type EditUserRoleMutationHookResult = ReturnType<typeof useEditUserRoleMutation>;
export type EditUserRoleMutationResult = Apollo.MutationResult<Types.EditUserRoleMutation>;
export type EditUserRoleMutationOptions = Apollo.BaseMutationOptions<Types.EditUserRoleMutation, Types.EditUserRoleMutationVariables>;
export const CreateDemoUserDocument = gql`
    mutation CreateDemoUser($input: CreateDemoUserInput!) {
  organization {
    createDemoUser(input: $input) {
      record {
        id
        email
        logo
        userRole
        createdAt
        isDemoUser
      }
    }
  }
}
    `;
export type CreateDemoUserMutationFn = Apollo.MutationFunction<Types.CreateDemoUserMutation, Types.CreateDemoUserMutationVariables>;

/**
 * __useCreateDemoUserMutation__
 *
 * To run a mutation, you first call `useCreateDemoUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoUserMutation, { data, loading, error }] = useCreateDemoUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDemoUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDemoUserMutation, Types.CreateDemoUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDemoUserMutation, Types.CreateDemoUserMutationVariables>(CreateDemoUserDocument, options);
      }
export type CreateDemoUserMutationHookResult = ReturnType<typeof useCreateDemoUserMutation>;
export type CreateDemoUserMutationResult = Apollo.MutationResult<Types.CreateDemoUserMutation>;
export type CreateDemoUserMutationOptions = Apollo.BaseMutationOptions<Types.CreateDemoUserMutation, Types.CreateDemoUserMutationVariables>;
export const CreateOrganizationUserDocument = gql`
    mutation CreateOrganizationUser($input: CreateInternalUserInput!) {
  organization {
    createInternalUser(input: $input) {
      record {
        id
        email
        logo
        userRole
        createdAt
        isDemoUser
      }
    }
  }
}
    `;
export type CreateOrganizationUserMutationFn = Apollo.MutationFunction<Types.CreateOrganizationUserMutation, Types.CreateOrganizationUserMutationVariables>;

/**
 * __useCreateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationUserMutation, { data, loading, error }] = useCreateOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrganizationUserMutation, Types.CreateOrganizationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrganizationUserMutation, Types.CreateOrganizationUserMutationVariables>(CreateOrganizationUserDocument, options);
      }
export type CreateOrganizationUserMutationHookResult = ReturnType<typeof useCreateOrganizationUserMutation>;
export type CreateOrganizationUserMutationResult = Apollo.MutationResult<Types.CreateOrganizationUserMutation>;
export type CreateOrganizationUserMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrganizationUserMutation, Types.CreateOrganizationUserMutationVariables>;
export const ExternalInvitationsDocument = gql`
    query ExternalInvitations($organizationId: String!) {
  organization(id: $organizationId) {
    id
    userInvitations {
      nodes {
        id
        user {
          id
          email
        }
        lastInvitedAt
        lastInvitedBy {
          id
          email
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useExternalInvitationsQuery__
 *
 * To run a query within a React component, call `useExternalInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalInvitationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useExternalInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ExternalInvitationsQuery, Types.ExternalInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExternalInvitationsQuery, Types.ExternalInvitationsQueryVariables>(ExternalInvitationsDocument, options);
      }
export function useExternalInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExternalInvitationsQuery, Types.ExternalInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExternalInvitationsQuery, Types.ExternalInvitationsQueryVariables>(ExternalInvitationsDocument, options);
        }
export type ExternalInvitationsQueryHookResult = ReturnType<typeof useExternalInvitationsQuery>;
export type ExternalInvitationsLazyQueryHookResult = ReturnType<typeof useExternalInvitationsLazyQuery>;
export type ExternalInvitationsQueryResult = Apollo.QueryResult<Types.ExternalInvitationsQuery, Types.ExternalInvitationsQueryVariables>;
export const SendExternalInvitationOrgAdminDocument = gql`
    mutation SendExternalInvitationOrgAdmin($input: CreateExternalUserInput!) {
  organization {
    createExternalUser(input: $input) {
      record {
        id
        user {
          id
          email
          logo
        }
        lastInvitedAt
        lastInvitedBy {
          id
          email
        }
      }
    }
  }
}
    `;
export type SendExternalInvitationOrgAdminMutationFn = Apollo.MutationFunction<Types.SendExternalInvitationOrgAdminMutation, Types.SendExternalInvitationOrgAdminMutationVariables>;

/**
 * __useSendExternalInvitationOrgAdminMutation__
 *
 * To run a mutation, you first call `useSendExternalInvitationOrgAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendExternalInvitationOrgAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendExternalInvitationOrgAdminMutation, { data, loading, error }] = useSendExternalInvitationOrgAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendExternalInvitationOrgAdminMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendExternalInvitationOrgAdminMutation, Types.SendExternalInvitationOrgAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendExternalInvitationOrgAdminMutation, Types.SendExternalInvitationOrgAdminMutationVariables>(SendExternalInvitationOrgAdminDocument, options);
      }
export type SendExternalInvitationOrgAdminMutationHookResult = ReturnType<typeof useSendExternalInvitationOrgAdminMutation>;
export type SendExternalInvitationOrgAdminMutationResult = Apollo.MutationResult<Types.SendExternalInvitationOrgAdminMutation>;
export type SendExternalInvitationOrgAdminMutationOptions = Apollo.BaseMutationOptions<Types.SendExternalInvitationOrgAdminMutation, Types.SendExternalInvitationOrgAdminMutationVariables>;
export const SendExternalInvitationSuperAdminDocument = gql`
    mutation SendExternalInvitationSuperAdmin($input: CreateExternalUserInput!) {
  organization {
    createExternalUser(input: $input) {
      record {
        id
        user {
          id
          email
          logo
        }
        lastInvitedAt
        lastInvitedBy {
          id
          email
        }
      }
      query {
        organizations {
          nodes {
            id
            createdAt
            createdBy {
              id
              email
            }
            updatedAt
            name
            logo
            domains
            workerTypes {
              nodes {
                id
                name
                shortName
              }
            }
            state
            hasAdvertiserFeatures
            hasAnalyticsFeatures
            hasPublisherFeatures
            users {
              nodes {
                id
                email
                logo
                userRole
                isDemoUser
                createdAt
              }
              totalCount
            }
            participatingDataRooms {
              totalCount
            }
          }
        }
        users {
          nodes {
            id
            email
            organization {
              id
              name
            }
            userRole
            isDemoUser
            createdAt
          }
        }
      }
    }
  }
}
    `;
export type SendExternalInvitationSuperAdminMutationFn = Apollo.MutationFunction<Types.SendExternalInvitationSuperAdminMutation, Types.SendExternalInvitationSuperAdminMutationVariables>;

/**
 * __useSendExternalInvitationSuperAdminMutation__
 *
 * To run a mutation, you first call `useSendExternalInvitationSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendExternalInvitationSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendExternalInvitationSuperAdminMutation, { data, loading, error }] = useSendExternalInvitationSuperAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendExternalInvitationSuperAdminMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendExternalInvitationSuperAdminMutation, Types.SendExternalInvitationSuperAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendExternalInvitationSuperAdminMutation, Types.SendExternalInvitationSuperAdminMutationVariables>(SendExternalInvitationSuperAdminDocument, options);
      }
export type SendExternalInvitationSuperAdminMutationHookResult = ReturnType<typeof useSendExternalInvitationSuperAdminMutation>;
export type SendExternalInvitationSuperAdminMutationResult = Apollo.MutationResult<Types.SendExternalInvitationSuperAdminMutation>;
export type SendExternalInvitationSuperAdminMutationOptions = Apollo.BaseMutationOptions<Types.SendExternalInvitationSuperAdminMutation, Types.SendExternalInvitationSuperAdminMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    nodes {
      id
      email
      logo
      organization {
        id
        name
      }
      userRole
      isDemoUser
      createdAt
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export const AuditLogsDocument = gql`
    query AuditLogs($dcrHash: HexString!, $driverAttestationHash: HexString!) {
  auditLog(dcrHash: $dcrHash, driverAttestationHash: $driverAttestationHash) @client {
    log
  }
}
    `;

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *   },
 * });
 */
export function useAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<Types.AuditLogsQuery, Types.AuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AuditLogsQuery, Types.AuditLogsQueryVariables>(AuditLogsDocument, options);
      }
export function useAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AuditLogsQuery, Types.AuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AuditLogsQuery, Types.AuditLogsQueryVariables>(AuditLogsDocument, options);
        }
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<typeof useAuditLogsLazyQuery>;
export type AuditLogsQueryResult = Apollo.QueryResult<Types.AuditLogsQuery, Types.AuditLogsQueryVariables>;
export const PublishedDataRoomLogoDocument = gql`
    query PublishedDataRoomLogo($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    showOrganizationLogo
    logo
  }
}
    `;

/**
 * __usePublishedDataRoomLogoQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomLogoQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomLogoQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomLogoQuery, Types.PublishedDataRoomLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomLogoQuery, Types.PublishedDataRoomLogoQueryVariables>(PublishedDataRoomLogoDocument, options);
      }
export function usePublishedDataRoomLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomLogoQuery, Types.PublishedDataRoomLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomLogoQuery, Types.PublishedDataRoomLogoQueryVariables>(PublishedDataRoomLogoDocument, options);
        }
export type PublishedDataRoomLogoQueryHookResult = ReturnType<typeof usePublishedDataRoomLogoQuery>;
export type PublishedDataRoomLogoLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomLogoLazyQuery>;
export type PublishedDataRoomLogoQueryResult = Apollo.QueryResult<Types.PublishedDataRoomLogoQuery, Types.PublishedDataRoomLogoQueryVariables>;
export const DraftDataRoomDescriptionDocument = gql`
    query DraftDataRoomDescription($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    description
  }
}
    `;

/**
 * __useDraftDataRoomDescriptionQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomDescriptionQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomDescriptionQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomDescriptionQuery, Types.DraftDataRoomDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomDescriptionQuery, Types.DraftDataRoomDescriptionQueryVariables>(DraftDataRoomDescriptionDocument, options);
      }
export function useDraftDataRoomDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomDescriptionQuery, Types.DraftDataRoomDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomDescriptionQuery, Types.DraftDataRoomDescriptionQueryVariables>(DraftDataRoomDescriptionDocument, options);
        }
export type DraftDataRoomDescriptionQueryHookResult = ReturnType<typeof useDraftDataRoomDescriptionQuery>;
export type DraftDataRoomDescriptionLazyQueryHookResult = ReturnType<typeof useDraftDataRoomDescriptionLazyQuery>;
export type DraftDataRoomDescriptionQueryResult = Apollo.QueryResult<Types.DraftDataRoomDescriptionQuery, Types.DraftDataRoomDescriptionQueryVariables>;
export const PublishedDataRoomDescriptionDocument = gql`
    query PublishedDataRoomDescription($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    description @client
  }
}
    `;

/**
 * __usePublishedDataRoomDescriptionQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomDescriptionQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomDescriptionQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomDescriptionQuery, Types.PublishedDataRoomDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomDescriptionQuery, Types.PublishedDataRoomDescriptionQueryVariables>(PublishedDataRoomDescriptionDocument, options);
      }
export function usePublishedDataRoomDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomDescriptionQuery, Types.PublishedDataRoomDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomDescriptionQuery, Types.PublishedDataRoomDescriptionQueryVariables>(PublishedDataRoomDescriptionDocument, options);
        }
export type PublishedDataRoomDescriptionQueryHookResult = ReturnType<typeof usePublishedDataRoomDescriptionQuery>;
export type PublishedDataRoomDescriptionLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomDescriptionLazyQuery>;
export type PublishedDataRoomDescriptionQueryResult = Apollo.QueryResult<Types.PublishedDataRoomDescriptionQuery, Types.PublishedDataRoomDescriptionQueryVariables>;
export const SetDataRoomDescriptionDocument = gql`
    mutation SetDataRoomDescription($input: UpdateDraftDataRoomInput!) {
  draftDataRoom {
    update(input: $input) {
      id
      description
    }
  }
}
    `;
export type SetDataRoomDescriptionMutationFn = Apollo.MutationFunction<Types.SetDataRoomDescriptionMutation, Types.SetDataRoomDescriptionMutationVariables>;

/**
 * __useSetDataRoomDescriptionMutation__
 *
 * To run a mutation, you first call `useSetDataRoomDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataRoomDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataRoomDescriptionMutation, { data, loading, error }] = useSetDataRoomDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDataRoomDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDataRoomDescriptionMutation, Types.SetDataRoomDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDataRoomDescriptionMutation, Types.SetDataRoomDescriptionMutationVariables>(SetDataRoomDescriptionDocument, options);
      }
export type SetDataRoomDescriptionMutationHookResult = ReturnType<typeof useSetDataRoomDescriptionMutation>;
export type SetDataRoomDescriptionMutationResult = Apollo.MutationResult<Types.SetDataRoomDescriptionMutation>;
export type SetDataRoomDescriptionMutationOptions = Apollo.BaseMutationOptions<Types.SetDataRoomDescriptionMutation, Types.SetDataRoomDescriptionMutationVariables>;
export const PublishedDataRoomLowLevelRepresentationDocument = gql`
    query PublishedDataRoomLowLevelRepresentation($id: String!) {
  publishedDataRoom(id: $id) {
    id
    name: title
    lowLevelRepresentation @client
  }
}
    `;

/**
 * __usePublishedDataRoomLowLevelRepresentationQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomLowLevelRepresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomLowLevelRepresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomLowLevelRepresentationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomLowLevelRepresentationQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomLowLevelRepresentationQuery, Types.PublishedDataRoomLowLevelRepresentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomLowLevelRepresentationQuery, Types.PublishedDataRoomLowLevelRepresentationQueryVariables>(PublishedDataRoomLowLevelRepresentationDocument, options);
      }
export function usePublishedDataRoomLowLevelRepresentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomLowLevelRepresentationQuery, Types.PublishedDataRoomLowLevelRepresentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomLowLevelRepresentationQuery, Types.PublishedDataRoomLowLevelRepresentationQueryVariables>(PublishedDataRoomLowLevelRepresentationDocument, options);
        }
export type PublishedDataRoomLowLevelRepresentationQueryHookResult = ReturnType<typeof usePublishedDataRoomLowLevelRepresentationQuery>;
export type PublishedDataRoomLowLevelRepresentationLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomLowLevelRepresentationLazyQuery>;
export type PublishedDataRoomLowLevelRepresentationQueryResult = Apollo.QueryResult<Types.PublishedDataRoomLowLevelRepresentationQuery, Types.PublishedDataRoomLowLevelRepresentationQueryVariables>;
export const DraftDataRoomNameDocument = gql`
    query DraftDataRoomName($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    name: title
  }
}
    `;

/**
 * __useDraftDataRoomNameQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomNameQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomNameQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomNameQuery, Types.DraftDataRoomNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomNameQuery, Types.DraftDataRoomNameQueryVariables>(DraftDataRoomNameDocument, options);
      }
export function useDraftDataRoomNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomNameQuery, Types.DraftDataRoomNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomNameQuery, Types.DraftDataRoomNameQueryVariables>(DraftDataRoomNameDocument, options);
        }
export type DraftDataRoomNameQueryHookResult = ReturnType<typeof useDraftDataRoomNameQuery>;
export type DraftDataRoomNameLazyQueryHookResult = ReturnType<typeof useDraftDataRoomNameLazyQuery>;
export type DraftDataRoomNameQueryResult = Apollo.QueryResult<Types.DraftDataRoomNameQuery, Types.DraftDataRoomNameQueryVariables>;
export const PublishedDataRoomNameDocument = gql`
    query PublishedDataRoomName($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    name: title
  }
}
    `;

/**
 * __usePublishedDataRoomNameQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomNameQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomNameQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomNameQuery, Types.PublishedDataRoomNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomNameQuery, Types.PublishedDataRoomNameQueryVariables>(PublishedDataRoomNameDocument, options);
      }
export function usePublishedDataRoomNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomNameQuery, Types.PublishedDataRoomNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomNameQuery, Types.PublishedDataRoomNameQueryVariables>(PublishedDataRoomNameDocument, options);
        }
export type PublishedDataRoomNameQueryHookResult = ReturnType<typeof usePublishedDataRoomNameQuery>;
export type PublishedDataRoomNameLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomNameLazyQuery>;
export type PublishedDataRoomNameQueryResult = Apollo.QueryResult<Types.PublishedDataRoomNameQuery, Types.PublishedDataRoomNameQueryVariables>;
export const SetDataRoomNameDocument = gql`
    mutation SetDataRoomName($input: UpdateDraftDataRoomInput!) {
  draftDataRoom {
    update(input: $input) {
      id
      name: title
    }
  }
}
    `;
export type SetDataRoomNameMutationFn = Apollo.MutationFunction<Types.SetDataRoomNameMutation, Types.SetDataRoomNameMutationVariables>;

/**
 * __useSetDataRoomNameMutation__
 *
 * To run a mutation, you first call `useSetDataRoomNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataRoomNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataRoomNameMutation, { data, loading, error }] = useSetDataRoomNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDataRoomNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDataRoomNameMutation, Types.SetDataRoomNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDataRoomNameMutation, Types.SetDataRoomNameMutationVariables>(SetDataRoomNameDocument, options);
      }
export type SetDataRoomNameMutationHookResult = ReturnType<typeof useSetDataRoomNameMutation>;
export type SetDataRoomNameMutationResult = Apollo.MutationResult<Types.SetDataRoomNameMutation>;
export type SetDataRoomNameMutationOptions = Apollo.BaseMutationOptions<Types.SetDataRoomNameMutation, Types.SetDataRoomNameMutationVariables>;
export const PublishedDataRoomDataRoomHashDocument = gql`
    query PublishedDataRoomDataRoomHash($id: String!) {
  publishedDataRoom(id: $id) {
    id
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __usePublishedDataRoomDataRoomHashQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomDataRoomHashQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomDataRoomHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomDataRoomHashQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomDataRoomHashQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomDataRoomHashQuery, Types.PublishedDataRoomDataRoomHashQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomDataRoomHashQuery, Types.PublishedDataRoomDataRoomHashQueryVariables>(PublishedDataRoomDataRoomHashDocument, options);
      }
export function usePublishedDataRoomDataRoomHashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomDataRoomHashQuery, Types.PublishedDataRoomDataRoomHashQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomDataRoomHashQuery, Types.PublishedDataRoomDataRoomHashQueryVariables>(PublishedDataRoomDataRoomHashDocument, options);
        }
export type PublishedDataRoomDataRoomHashQueryHookResult = ReturnType<typeof usePublishedDataRoomDataRoomHashQuery>;
export type PublishedDataRoomDataRoomHashLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomDataRoomHashLazyQuery>;
export type PublishedDataRoomDataRoomHashQueryResult = Apollo.QueryResult<Types.PublishedDataRoomDataRoomHashQuery, Types.PublishedDataRoomDataRoomHashQueryVariables>;
export const DraftDataRoomDocument = gql`
    query DraftDataRoom($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    name: title
    description
    enableInteractivity
    enableDevelopment
    hasImportedNativeSql
    owner {
      id
      email
    }
    isSaving @client
    isOwner @client
    draftNodes {
      nodes {
        id
        name
        ... on DraftScriptingNode {
          computationType
        }
        ... on DraftSqlNode {
          computationType
        }
        ... on DraftSqliteNode {
          computationType
        }
        ... on DraftMatchNode {
          computationType
        }
        ... on DraftSyntheticNode {
          computationType
        }
        ... on DraftPostNode {
          computationType
        }
        ... on DraftPreviewNode {
          computationType
        }
        ... on DraftS3SinkNode {
          computationType
        }
        ... on DraftRawLeafNode {
          id
          name
        }
        ... on DraftTableLeafNode {
          id
          name
        }
      }
    }
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              node {
                ... on DraftScriptingNode {
                  id
                  name
                }
                ... on DraftSqlNode {
                  id
                  name
                }
                ... on DraftSqliteNode {
                  id
                  name
                }
                ... on DraftMatchNode {
                  id
                  name
                }
                ... on DraftSyntheticNode {
                  id
                  name
                }
                ... on DraftPostNode {
                  id
                  name
                }
                ... on DraftPreviewNode {
                  id
                  name
                }
                ... on DraftS3SinkNode {
                  id
                  name
                }
              }
            }
            ... on DraftDataOwnerPermission {
              node {
                ... on DraftRawLeafNode {
                  id
                  name
                }
                ... on DraftTableLeafNode {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftDataRoomQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomQuery, Types.DraftDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomQuery, Types.DraftDataRoomQueryVariables>(DraftDataRoomDocument, options);
      }
export function useDraftDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomQuery, Types.DraftDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomQuery, Types.DraftDataRoomQueryVariables>(DraftDataRoomDocument, options);
        }
export type DraftDataRoomQueryHookResult = ReturnType<typeof useDraftDataRoomQuery>;
export type DraftDataRoomLazyQueryHookResult = ReturnType<typeof useDraftDataRoomLazyQuery>;
export type DraftDataRoomQueryResult = Apollo.QueryResult<Types.DraftDataRoomQuery, Types.DraftDataRoomQueryVariables>;
export const DraftNodeIdDocument = gql`
    query DraftNodeId($id: String!) {
  draftNode(id: $id) {
    id
  }
}
    `;

/**
 * __useDraftNodeIdQuery__
 *
 * To run a query within a React component, call `useDraftNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftNodeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftNodeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftNodeIdQuery, Types.DraftNodeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftNodeIdQuery, Types.DraftNodeIdQueryVariables>(DraftNodeIdDocument, options);
      }
export function useDraftNodeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftNodeIdQuery, Types.DraftNodeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftNodeIdQuery, Types.DraftNodeIdQueryVariables>(DraftNodeIdDocument, options);
        }
export type DraftNodeIdQueryHookResult = ReturnType<typeof useDraftNodeIdQuery>;
export type DraftNodeIdLazyQueryHookResult = ReturnType<typeof useDraftNodeIdLazyQuery>;
export type DraftNodeIdQueryResult = Apollo.QueryResult<Types.DraftNodeIdQuery, Types.DraftNodeIdQueryVariables>;
export const DraftNodesTypenamesDocument = gql`
    query DraftNodesTypenames($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    draftNodes {
      nodes {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useDraftNodesTypenamesQuery__
 *
 * To run a query within a React component, call `useDraftNodesTypenamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftNodesTypenamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftNodesTypenamesQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftNodesTypenamesQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftNodesTypenamesQuery, Types.DraftNodesTypenamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftNodesTypenamesQuery, Types.DraftNodesTypenamesQueryVariables>(DraftNodesTypenamesDocument, options);
      }
export function useDraftNodesTypenamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftNodesTypenamesQuery, Types.DraftNodesTypenamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftNodesTypenamesQuery, Types.DraftNodesTypenamesQueryVariables>(DraftNodesTypenamesDocument, options);
        }
export type DraftNodesTypenamesQueryHookResult = ReturnType<typeof useDraftNodesTypenamesQuery>;
export type DraftNodesTypenamesLazyQueryHookResult = ReturnType<typeof useDraftNodesTypenamesLazyQuery>;
export type DraftNodesTypenamesQueryResult = Apollo.QueryResult<Types.DraftNodesTypenamesQuery, Types.DraftNodesTypenamesQueryVariables>;
export const OrganizationDetailsDocument = gql`
    query OrganizationDetails($organizationId: String!) {
  organization(id: $organizationId) {
    id
    createdAt
    createdBy {
      id
      email
    }
    updatedAt
  }
}
    `;

/**
 * __useOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationDetailsQuery, Types.OrganizationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationDetailsQuery, Types.OrganizationDetailsQueryVariables>(OrganizationDetailsDocument, options);
      }
export function useOrganizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationDetailsQuery, Types.OrganizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationDetailsQuery, Types.OrganizationDetailsQueryVariables>(OrganizationDetailsDocument, options);
        }
export type OrganizationDetailsQueryHookResult = ReturnType<typeof useOrganizationDetailsQuery>;
export type OrganizationDetailsLazyQueryHookResult = ReturnType<typeof useOrganizationDetailsLazyQuery>;
export type OrganizationDetailsQueryResult = Apollo.QueryResult<Types.OrganizationDetailsQuery, Types.OrganizationDetailsQueryVariables>;
export const OrganizationDomainsDocument = gql`
    query OrganizationDomains($organizationId: String!) {
  organization(id: $organizationId) {
    id
    domains
  }
}
    `;

/**
 * __useOrganizationDomainsQuery__
 *
 * To run a query within a React component, call `useOrganizationDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDomainsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationDomainsQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationDomainsQuery, Types.OrganizationDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationDomainsQuery, Types.OrganizationDomainsQueryVariables>(OrganizationDomainsDocument, options);
      }
export function useOrganizationDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationDomainsQuery, Types.OrganizationDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationDomainsQuery, Types.OrganizationDomainsQueryVariables>(OrganizationDomainsDocument, options);
        }
export type OrganizationDomainsQueryHookResult = ReturnType<typeof useOrganizationDomainsQuery>;
export type OrganizationDomainsLazyQueryHookResult = ReturnType<typeof useOrganizationDomainsLazyQuery>;
export type OrganizationDomainsQueryResult = Apollo.QueryResult<Types.OrganizationDomainsQuery, Types.OrganizationDomainsQueryVariables>;
export const UpdateOrganizationDomainsDocument = gql`
    mutation UpdateOrganizationDomains($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      domains
    }
  }
}
    `;
export type UpdateOrganizationDomainsMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationDomainsMutation, Types.UpdateOrganizationDomainsMutationVariables>;

/**
 * __useUpdateOrganizationDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDomainsMutation, { data, loading, error }] = useUpdateOrganizationDomainsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationDomainsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationDomainsMutation, Types.UpdateOrganizationDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationDomainsMutation, Types.UpdateOrganizationDomainsMutationVariables>(UpdateOrganizationDomainsDocument, options);
      }
export type UpdateOrganizationDomainsMutationHookResult = ReturnType<typeof useUpdateOrganizationDomainsMutation>;
export type UpdateOrganizationDomainsMutationResult = Apollo.MutationResult<Types.UpdateOrganizationDomainsMutation>;
export type UpdateOrganizationDomainsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationDomainsMutation, Types.UpdateOrganizationDomainsMutationVariables>;
export const EnclaveWorkersDocument = gql`
    query EnclaveWorkers {
  workerTypes {
    nodes {
      id
      name
      shortName
    }
  }
}
    `;

/**
 * __useEnclaveWorkersQuery__
 *
 * To run a query within a React component, call `useEnclaveWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnclaveWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnclaveWorkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnclaveWorkersQuery(baseOptions?: Apollo.QueryHookOptions<Types.EnclaveWorkersQuery, Types.EnclaveWorkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EnclaveWorkersQuery, Types.EnclaveWorkersQueryVariables>(EnclaveWorkersDocument, options);
      }
export function useEnclaveWorkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EnclaveWorkersQuery, Types.EnclaveWorkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EnclaveWorkersQuery, Types.EnclaveWorkersQueryVariables>(EnclaveWorkersDocument, options);
        }
export type EnclaveWorkersQueryHookResult = ReturnType<typeof useEnclaveWorkersQuery>;
export type EnclaveWorkersLazyQueryHookResult = ReturnType<typeof useEnclaveWorkersLazyQuery>;
export type EnclaveWorkersQueryResult = Apollo.QueryResult<Types.EnclaveWorkersQuery, Types.EnclaveWorkersQueryVariables>;
export const OrganizationEnclaveWorkersDocument = gql`
    query OrganizationEnclaveWorkers($organizationId: String!) {
  organization(id: $organizationId) {
    id
    workerTypes {
      nodes {
        id
        name
        shortName
      }
    }
  }
}
    `;

/**
 * __useOrganizationEnclaveWorkersQuery__
 *
 * To run a query within a React component, call `useOrganizationEnclaveWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEnclaveWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEnclaveWorkersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationEnclaveWorkersQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationEnclaveWorkersQuery, Types.OrganizationEnclaveWorkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationEnclaveWorkersQuery, Types.OrganizationEnclaveWorkersQueryVariables>(OrganizationEnclaveWorkersDocument, options);
      }
export function useOrganizationEnclaveWorkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationEnclaveWorkersQuery, Types.OrganizationEnclaveWorkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationEnclaveWorkersQuery, Types.OrganizationEnclaveWorkersQueryVariables>(OrganizationEnclaveWorkersDocument, options);
        }
export type OrganizationEnclaveWorkersQueryHookResult = ReturnType<typeof useOrganizationEnclaveWorkersQuery>;
export type OrganizationEnclaveWorkersLazyQueryHookResult = ReturnType<typeof useOrganizationEnclaveWorkersLazyQuery>;
export type OrganizationEnclaveWorkersQueryResult = Apollo.QueryResult<Types.OrganizationEnclaveWorkersQuery, Types.OrganizationEnclaveWorkersQueryVariables>;
export const UpdateOrganizationEnclaveWorkersDocument = gql`
    mutation UpdateOrganizationEnclaveWorkers($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      workerTypes {
        nodes {
          id
          name
          shortName
        }
      }
    }
  }
}
    `;
export type UpdateOrganizationEnclaveWorkersMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationEnclaveWorkersMutation, Types.UpdateOrganizationEnclaveWorkersMutationVariables>;

/**
 * __useUpdateOrganizationEnclaveWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEnclaveWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEnclaveWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEnclaveWorkersMutation, { data, loading, error }] = useUpdateOrganizationEnclaveWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationEnclaveWorkersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationEnclaveWorkersMutation, Types.UpdateOrganizationEnclaveWorkersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationEnclaveWorkersMutation, Types.UpdateOrganizationEnclaveWorkersMutationVariables>(UpdateOrganizationEnclaveWorkersDocument, options);
      }
export type UpdateOrganizationEnclaveWorkersMutationHookResult = ReturnType<typeof useUpdateOrganizationEnclaveWorkersMutation>;
export type UpdateOrganizationEnclaveWorkersMutationResult = Apollo.MutationResult<Types.UpdateOrganizationEnclaveWorkersMutation>;
export type UpdateOrganizationEnclaveWorkersMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationEnclaveWorkersMutation, Types.UpdateOrganizationEnclaveWorkersMutationVariables>;
export const OrganizationFeaturesDocument = gql`
    query OrganizationFeatures($organizationId: String!) {
  organization(id: $organizationId) {
    id
    hasAdvertiserFeatures
    hasAnalyticsFeatures
    hasPublisherFeatures
    hasDataPartnerFeatures
    canViewDataPartners
    canViewMeasurements
    allowExcludingSeedAudience
  }
}
    `;

/**
 * __useOrganizationFeaturesQuery__
 *
 * To run a query within a React component, call `useOrganizationFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFeaturesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationFeaturesQuery, Types.OrganizationFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationFeaturesQuery, Types.OrganizationFeaturesQueryVariables>(OrganizationFeaturesDocument, options);
      }
export function useOrganizationFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationFeaturesQuery, Types.OrganizationFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationFeaturesQuery, Types.OrganizationFeaturesQueryVariables>(OrganizationFeaturesDocument, options);
        }
export type OrganizationFeaturesQueryHookResult = ReturnType<typeof useOrganizationFeaturesQuery>;
export type OrganizationFeaturesLazyQueryHookResult = ReturnType<typeof useOrganizationFeaturesLazyQuery>;
export type OrganizationFeaturesQueryResult = Apollo.QueryResult<Types.OrganizationFeaturesQuery, Types.OrganizationFeaturesQueryVariables>;
export const UpdateOrganizationFeaturesDocument = gql`
    mutation UpdateOrganizationFeatures($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      hasAdvertiserFeatures
      hasAnalyticsFeatures
      hasPublisherFeatures
      hasDataPartnerFeatures
      canViewDataPartners
      canViewMeasurements
      allowExcludingSeedAudience
    }
  }
}
    `;
export type UpdateOrganizationFeaturesMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationFeaturesMutation, Types.UpdateOrganizationFeaturesMutationVariables>;

/**
 * __useUpdateOrganizationFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationFeaturesMutation, { data, loading, error }] = useUpdateOrganizationFeaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationFeaturesMutation, Types.UpdateOrganizationFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationFeaturesMutation, Types.UpdateOrganizationFeaturesMutationVariables>(UpdateOrganizationFeaturesDocument, options);
      }
export type UpdateOrganizationFeaturesMutationHookResult = ReturnType<typeof useUpdateOrganizationFeaturesMutation>;
export type UpdateOrganizationFeaturesMutationResult = Apollo.MutationResult<Types.UpdateOrganizationFeaturesMutation>;
export type UpdateOrganizationFeaturesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationFeaturesMutation, Types.UpdateOrganizationFeaturesMutationVariables>;
export const OrganizationLicenseDocument = gql`
    query OrganizationLicense($organizationId: String!) {
  organization(id: $organizationId) {
    id
    state
  }
}
    `;

/**
 * __useOrganizationLicenseQuery__
 *
 * To run a query within a React component, call `useOrganizationLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLicenseQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationLicenseQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationLicenseQuery, Types.OrganizationLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationLicenseQuery, Types.OrganizationLicenseQueryVariables>(OrganizationLicenseDocument, options);
      }
export function useOrganizationLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationLicenseQuery, Types.OrganizationLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationLicenseQuery, Types.OrganizationLicenseQueryVariables>(OrganizationLicenseDocument, options);
        }
export type OrganizationLicenseQueryHookResult = ReturnType<typeof useOrganizationLicenseQuery>;
export type OrganizationLicenseLazyQueryHookResult = ReturnType<typeof useOrganizationLicenseLazyQuery>;
export type OrganizationLicenseQueryResult = Apollo.QueryResult<Types.OrganizationLicenseQuery, Types.OrganizationLicenseQueryVariables>;
export const UpdateOrganizationLicenseDocument = gql`
    mutation UpdateOrganizationLicense($organizationId: String!, $state: OrganizationState!) {
  organization {
    setState(id: $organizationId, state: $state) {
      record {
        id
        state
      }
    }
  }
}
    `;
export type UpdateOrganizationLicenseMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationLicenseMutation, Types.UpdateOrganizationLicenseMutationVariables>;

/**
 * __useUpdateOrganizationLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationLicenseMutation, { data, loading, error }] = useUpdateOrganizationLicenseMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateOrganizationLicenseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationLicenseMutation, Types.UpdateOrganizationLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationLicenseMutation, Types.UpdateOrganizationLicenseMutationVariables>(UpdateOrganizationLicenseDocument, options);
      }
export type UpdateOrganizationLicenseMutationHookResult = ReturnType<typeof useUpdateOrganizationLicenseMutation>;
export type UpdateOrganizationLicenseMutationResult = Apollo.MutationResult<Types.UpdateOrganizationLicenseMutation>;
export type UpdateOrganizationLicenseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationLicenseMutation, Types.UpdateOrganizationLicenseMutationVariables>;
export const OrganizationLogoDocument = gql`
    query OrganizationLogo($organizationId: String!) {
  organization(id: $organizationId) {
    id
    logo
  }
}
    `;

/**
 * __useOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLogoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationLogoQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationLogoQuery, Types.OrganizationLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationLogoQuery, Types.OrganizationLogoQueryVariables>(OrganizationLogoDocument, options);
      }
export function useOrganizationLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationLogoQuery, Types.OrganizationLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationLogoQuery, Types.OrganizationLogoQueryVariables>(OrganizationLogoDocument, options);
        }
export type OrganizationLogoQueryHookResult = ReturnType<typeof useOrganizationLogoQuery>;
export type OrganizationLogoLazyQueryHookResult = ReturnType<typeof useOrganizationLogoLazyQuery>;
export type OrganizationLogoQueryResult = Apollo.QueryResult<Types.OrganizationLogoQuery, Types.OrganizationLogoQueryVariables>;
export const UpsertOrganizationLogoDocument = gql`
    mutation UpsertOrganizationLogo($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      logo
    }
  }
}
    `;
export type UpsertOrganizationLogoMutationFn = Apollo.MutationFunction<Types.UpsertOrganizationLogoMutation, Types.UpsertOrganizationLogoMutationVariables>;

/**
 * __useUpsertOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUpsertOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrganizationLogoMutation, { data, loading, error }] = useUpsertOrganizationLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOrganizationLogoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertOrganizationLogoMutation, Types.UpsertOrganizationLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertOrganizationLogoMutation, Types.UpsertOrganizationLogoMutationVariables>(UpsertOrganizationLogoDocument, options);
      }
export type UpsertOrganizationLogoMutationHookResult = ReturnType<typeof useUpsertOrganizationLogoMutation>;
export type UpsertOrganizationLogoMutationResult = Apollo.MutationResult<Types.UpsertOrganizationLogoMutation>;
export type UpsertOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<Types.UpsertOrganizationLogoMutation, Types.UpsertOrganizationLogoMutationVariables>;
export const OrganizationMediaScopeDocument = gql`
    query OrganizationMediaScope($organizationId: String!) {
  organization(id: $organizationId) {
    id
    mediaScope
  }
}
    `;

/**
 * __useOrganizationMediaScopeQuery__
 *
 * To run a query within a React component, call `useOrganizationMediaScopeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMediaScopeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMediaScopeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationMediaScopeQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationMediaScopeQuery, Types.OrganizationMediaScopeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationMediaScopeQuery, Types.OrganizationMediaScopeQueryVariables>(OrganizationMediaScopeDocument, options);
      }
export function useOrganizationMediaScopeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationMediaScopeQuery, Types.OrganizationMediaScopeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationMediaScopeQuery, Types.OrganizationMediaScopeQueryVariables>(OrganizationMediaScopeDocument, options);
        }
export type OrganizationMediaScopeQueryHookResult = ReturnType<typeof useOrganizationMediaScopeQuery>;
export type OrganizationMediaScopeLazyQueryHookResult = ReturnType<typeof useOrganizationMediaScopeLazyQuery>;
export type OrganizationMediaScopeQueryResult = Apollo.QueryResult<Types.OrganizationMediaScopeQuery, Types.OrganizationMediaScopeQueryVariables>;
export const UpdateOrganizationMediaScopeDocument = gql`
    mutation UpdateOrganizationMediaScope($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      mediaScope
    }
  }
}
    `;
export type UpdateOrganizationMediaScopeMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationMediaScopeMutation, Types.UpdateOrganizationMediaScopeMutationVariables>;

/**
 * __useUpdateOrganizationMediaScopeMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMediaScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMediaScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMediaScopeMutation, { data, loading, error }] = useUpdateOrganizationMediaScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMediaScopeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationMediaScopeMutation, Types.UpdateOrganizationMediaScopeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationMediaScopeMutation, Types.UpdateOrganizationMediaScopeMutationVariables>(UpdateOrganizationMediaScopeDocument, options);
      }
export type UpdateOrganizationMediaScopeMutationHookResult = ReturnType<typeof useUpdateOrganizationMediaScopeMutation>;
export type UpdateOrganizationMediaScopeMutationResult = Apollo.MutationResult<Types.UpdateOrganizationMediaScopeMutation>;
export type UpdateOrganizationMediaScopeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationMediaScopeMutation, Types.UpdateOrganizationMediaScopeMutationVariables>;
export const OrganizationNameDocument = gql`
    query OrganizationName($organizationId: String!) {
  organization(id: $organizationId) {
    id
    name
  }
}
    `;

/**
 * __useOrganizationNameQuery__
 *
 * To run a query within a React component, call `useOrganizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNameQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationNameQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationNameQuery, Types.OrganizationNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationNameQuery, Types.OrganizationNameQueryVariables>(OrganizationNameDocument, options);
      }
export function useOrganizationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationNameQuery, Types.OrganizationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationNameQuery, Types.OrganizationNameQueryVariables>(OrganizationNameDocument, options);
        }
export type OrganizationNameQueryHookResult = ReturnType<typeof useOrganizationNameQuery>;
export type OrganizationNameLazyQueryHookResult = ReturnType<typeof useOrganizationNameLazyQuery>;
export type OrganizationNameQueryResult = Apollo.QueryResult<Types.OrganizationNameQuery, Types.OrganizationNameQueryVariables>;
export const UpdateOrganizationNameDocument = gql`
    mutation UpdateOrganizationName($input: UpdateOrganizationInput!) {
  organization {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type UpdateOrganizationNameMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationNameMutation, Types.UpdateOrganizationNameMutationVariables>;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationNameMutation, Types.UpdateOrganizationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationNameMutation, Types.UpdateOrganizationNameMutationVariables>(UpdateOrganizationNameDocument, options);
      }
export type UpdateOrganizationNameMutationHookResult = ReturnType<typeof useUpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationResult = Apollo.MutationResult<Types.UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationNameMutation, Types.UpdateOrganizationNameMutationVariables>;
export const PublishDatasetDocument = gql`
    mutation PublishDataset($input: PublishDatasetInput!) {
  publishDataset(input: $input) @client {
    dataset {
      datasetHash
      leafId
      timestamp
      user
    }
  }
}
    `;
export type PublishDatasetMutationFn = Apollo.MutationFunction<Types.PublishDatasetMutation, Types.PublishDatasetMutationVariables>;

/**
 * __usePublishDatasetMutation__
 *
 * To run a mutation, you first call `usePublishDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDatasetMutation, { data, loading, error }] = usePublishDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishDatasetMutation, Types.PublishDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishDatasetMutation, Types.PublishDatasetMutationVariables>(PublishDatasetDocument, options);
      }
export type PublishDatasetMutationHookResult = ReturnType<typeof usePublishDatasetMutation>;
export type PublishDatasetMutationResult = Apollo.MutationResult<Types.PublishDatasetMutation>;
export type PublishDatasetMutationOptions = Apollo.BaseMutationOptions<Types.PublishDatasetMutation, Types.PublishDatasetMutationVariables>;
export const PublishedDataRoomDocument = gql`
    query PublishedDataRoom($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    owner {
      email
    }
    driverAttestationHash
    deactivatedAt
    enableAirlock @client
    enableInteractivity @client
    enableDevelopment @client
    isStopped
    createdAt
    testing @client
    publishedNodes @client {
      __typename
      permissions {
        ... on PublishedAnalystPermission {
          participant {
            userEmail
          }
        }
        ... on PublishedDataOwnerPermission {
          participant {
            userEmail
          }
        }
      }
    }
    source @client
    enableAutomergeFeature @client
    enableTestDatasets @client
    submittedRequests {
      nodes {
        id
      }
    }
  }
}
    `;

/**
 * __usePublishedDataRoomQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomQuery, Types.PublishedDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomQuery, Types.PublishedDataRoomQueryVariables>(PublishedDataRoomDocument, options);
      }
export function usePublishedDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomQuery, Types.PublishedDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomQuery, Types.PublishedDataRoomQueryVariables>(PublishedDataRoomDocument, options);
        }
export type PublishedDataRoomQueryHookResult = ReturnType<typeof usePublishedDataRoomQuery>;
export type PublishedDataRoomLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomLazyQuery>;
export type PublishedDataRoomQueryResult = Apollo.QueryResult<Types.PublishedDataRoomQuery, Types.PublishedDataRoomQueryVariables>;
export const PublishedNodeIdDocument = gql`
    query PublishedNodeId($nodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $nodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
  }
}
    `;

/**
 * __usePublishedNodeIdQuery__
 *
 * To run a query within a React component, call `usePublishedNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNodeIdQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedNodeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedNodeIdQuery, Types.PublishedNodeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedNodeIdQuery, Types.PublishedNodeIdQueryVariables>(PublishedNodeIdDocument, options);
      }
export function usePublishedNodeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedNodeIdQuery, Types.PublishedNodeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedNodeIdQuery, Types.PublishedNodeIdQueryVariables>(PublishedNodeIdDocument, options);
        }
export type PublishedNodeIdQueryHookResult = ReturnType<typeof usePublishedNodeIdQuery>;
export type PublishedNodeIdLazyQueryHookResult = ReturnType<typeof usePublishedNodeIdLazyQuery>;
export type PublishedNodeIdQueryResult = Apollo.QueryResult<Types.PublishedNodeIdQuery, Types.PublishedNodeIdQueryVariables>;
export const PublishedNodesTypenamesDocument = gql`
    query PublishedNodesTypenames($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    publishedNodes @client {
      id
      name
    }
  }
}
    `;

/**
 * __usePublishedNodesTypenamesQuery__
 *
 * To run a query within a React component, call `usePublishedNodesTypenamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNodesTypenamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNodesTypenamesQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedNodesTypenamesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedNodesTypenamesQuery, Types.PublishedNodesTypenamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedNodesTypenamesQuery, Types.PublishedNodesTypenamesQueryVariables>(PublishedNodesTypenamesDocument, options);
      }
export function usePublishedNodesTypenamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedNodesTypenamesQuery, Types.PublishedNodesTypenamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedNodesTypenamesQuery, Types.PublishedNodesTypenamesQueryVariables>(PublishedNodesTypenamesDocument, options);
        }
export type PublishedNodesTypenamesQueryHookResult = ReturnType<typeof usePublishedNodesTypenamesQuery>;
export type PublishedNodesTypenamesLazyQueryHookResult = ReturnType<typeof usePublishedNodesTypenamesLazyQuery>;
export type PublishedNodesTypenamesQueryResult = Apollo.QueryResult<Types.PublishedNodesTypenamesQuery, Types.PublishedNodesTypenamesQueryVariables>;
export const UnlockPublishedDataRoomDocument = gql`
    mutation UnlockPublishedDataRoom($id: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $password: String!) {
  unlockDataRoom(
    input: {id: $id, dcrHash: $dcrHash, driverAttestationHash: $driverAttestationHash, password: $password}
  ) @client {
    id
  }
}
    `;
export type UnlockPublishedDataRoomMutationFn = Apollo.MutationFunction<Types.UnlockPublishedDataRoomMutation, Types.UnlockPublishedDataRoomMutationVariables>;

/**
 * __useUnlockPublishedDataRoomMutation__
 *
 * To run a mutation, you first call `useUnlockPublishedDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockPublishedDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockPublishedDataRoomMutation, { data, loading, error }] = useUnlockPublishedDataRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUnlockPublishedDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnlockPublishedDataRoomMutation, Types.UnlockPublishedDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnlockPublishedDataRoomMutation, Types.UnlockPublishedDataRoomMutationVariables>(UnlockPublishedDataRoomDocument, options);
      }
export type UnlockPublishedDataRoomMutationHookResult = ReturnType<typeof useUnlockPublishedDataRoomMutation>;
export type UnlockPublishedDataRoomMutationResult = Apollo.MutationResult<Types.UnlockPublishedDataRoomMutation>;
export type UnlockPublishedDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.UnlockPublishedDataRoomMutation, Types.UnlockPublishedDataRoomMutationVariables>;
export const MediaInsightsInviteParticipantsDocument = gql`
    mutation MediaInsightsInviteParticipants($input: InviteParticipantsInput!) {
  publishedDataRoom {
    inviteParticipants(input: $input) {
      id
    }
  }
}
    `;
export type MediaInsightsInviteParticipantsMutationFn = Apollo.MutationFunction<Types.MediaInsightsInviteParticipantsMutation, Types.MediaInsightsInviteParticipantsMutationVariables>;

/**
 * __useMediaInsightsInviteParticipantsMutation__
 *
 * To run a mutation, you first call `useMediaInsightsInviteParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaInsightsInviteParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaInsightsInviteParticipantsMutation, { data, loading, error }] = useMediaInsightsInviteParticipantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaInsightsInviteParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<Types.MediaInsightsInviteParticipantsMutation, Types.MediaInsightsInviteParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MediaInsightsInviteParticipantsMutation, Types.MediaInsightsInviteParticipantsMutationVariables>(MediaInsightsInviteParticipantsDocument, options);
      }
export type MediaInsightsInviteParticipantsMutationHookResult = ReturnType<typeof useMediaInsightsInviteParticipantsMutation>;
export type MediaInsightsInviteParticipantsMutationResult = Apollo.MutationResult<Types.MediaInsightsInviteParticipantsMutation>;
export type MediaInsightsInviteParticipantsMutationOptions = Apollo.BaseMutationOptions<Types.MediaInsightsInviteParticipantsMutation, Types.MediaInsightsInviteParticipantsMutationVariables>;
export const SendCollaborationRequestToDataPartnerDocument = gql`
    mutation SendCollaborationRequestToDataPartner($requestRecipientId: String!, $message: String!) {
  availableDataPartner {
    contactDataPartner(requestRecipientId: $requestRecipientId, message: $message)
  }
}
    `;
export type SendCollaborationRequestToDataPartnerMutationFn = Apollo.MutationFunction<Types.SendCollaborationRequestToDataPartnerMutation, Types.SendCollaborationRequestToDataPartnerMutationVariables>;

/**
 * __useSendCollaborationRequestToDataPartnerMutation__
 *
 * To run a mutation, you first call `useSendCollaborationRequestToDataPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCollaborationRequestToDataPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCollaborationRequestToDataPartnerMutation, { data, loading, error }] = useSendCollaborationRequestToDataPartnerMutation({
 *   variables: {
 *      requestRecipientId: // value for 'requestRecipientId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendCollaborationRequestToDataPartnerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendCollaborationRequestToDataPartnerMutation, Types.SendCollaborationRequestToDataPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendCollaborationRequestToDataPartnerMutation, Types.SendCollaborationRequestToDataPartnerMutationVariables>(SendCollaborationRequestToDataPartnerDocument, options);
      }
export type SendCollaborationRequestToDataPartnerMutationHookResult = ReturnType<typeof useSendCollaborationRequestToDataPartnerMutation>;
export type SendCollaborationRequestToDataPartnerMutationResult = Apollo.MutationResult<Types.SendCollaborationRequestToDataPartnerMutation>;
export type SendCollaborationRequestToDataPartnerMutationOptions = Apollo.BaseMutationOptions<Types.SendCollaborationRequestToDataPartnerMutation, Types.SendCollaborationRequestToDataPartnerMutationVariables>;
export const SendCollaborationRequestToPublisherDocument = gql`
    mutation SendCollaborationRequestToPublisher($requestRecipientId: String!, $message: String!) {
  availablePublisher {
    contactPublisher(requestRecipientId: $requestRecipientId, message: $message)
  }
}
    `;
export type SendCollaborationRequestToPublisherMutationFn = Apollo.MutationFunction<Types.SendCollaborationRequestToPublisherMutation, Types.SendCollaborationRequestToPublisherMutationVariables>;

/**
 * __useSendCollaborationRequestToPublisherMutation__
 *
 * To run a mutation, you first call `useSendCollaborationRequestToPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCollaborationRequestToPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCollaborationRequestToPublisherMutation, { data, loading, error }] = useSendCollaborationRequestToPublisherMutation({
 *   variables: {
 *      requestRecipientId: // value for 'requestRecipientId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendCollaborationRequestToPublisherMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendCollaborationRequestToPublisherMutation, Types.SendCollaborationRequestToPublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendCollaborationRequestToPublisherMutation, Types.SendCollaborationRequestToPublisherMutationVariables>(SendCollaborationRequestToPublisherDocument, options);
      }
export type SendCollaborationRequestToPublisherMutationHookResult = ReturnType<typeof useSendCollaborationRequestToPublisherMutation>;
export type SendCollaborationRequestToPublisherMutationResult = Apollo.MutationResult<Types.SendCollaborationRequestToPublisherMutation>;
export type SendCollaborationRequestToPublisherMutationOptions = Apollo.BaseMutationOptions<Types.SendCollaborationRequestToPublisherMutation, Types.SendCollaborationRequestToPublisherMutationVariables>;
export const PublishedMediaInsightsDcrActionsDocument = gql`
    query PublishedMediaInsightsDcrActions($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    driverAttestationHash
    name: title
    userSettings {
      id
      isArchived
      isFavorite
    }
    owner {
      id
      email
    }
    updatedAt
    createdAt
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrActionsQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrActionsQuery, Types.PublishedMediaInsightsDcrActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrActionsQuery, Types.PublishedMediaInsightsDcrActionsQueryVariables>(PublishedMediaInsightsDcrActionsDocument, options);
      }
export function usePublishedMediaInsightsDcrActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrActionsQuery, Types.PublishedMediaInsightsDcrActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrActionsQuery, Types.PublishedMediaInsightsDcrActionsQueryVariables>(PublishedMediaInsightsDcrActionsDocument, options);
        }
export type PublishedMediaInsightsDcrActionsQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrActionsQuery>;
export type PublishedMediaInsightsDcrActionsLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrActionsLazyQuery>;
export type PublishedMediaInsightsDcrActionsQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrActionsQuery, Types.PublishedMediaInsightsDcrActionsQueryVariables>;
export const DeprovisionDataLabFromMediaInsightsDcrDocument = gql`
    mutation DeprovisionDataLabFromMediaInsightsDcr($dataRoomId: String!) {
  dataLab {
    deprovisionDataLabFromMediaInsightsDcr(mediaInsightsDcrId: $dataRoomId) {
      id
    }
  }
}
    `;
export type DeprovisionDataLabFromMediaInsightsDcrMutationFn = Apollo.MutationFunction<Types.DeprovisionDataLabFromMediaInsightsDcrMutation, Types.DeprovisionDataLabFromMediaInsightsDcrMutationVariables>;

/**
 * __useDeprovisionDataLabFromMediaInsightsDcrMutation__
 *
 * To run a mutation, you first call `useDeprovisionDataLabFromMediaInsightsDcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprovisionDataLabFromMediaInsightsDcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprovisionDataLabFromMediaInsightsDcrMutation, { data, loading, error }] = useDeprovisionDataLabFromMediaInsightsDcrMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDeprovisionDataLabFromMediaInsightsDcrMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeprovisionDataLabFromMediaInsightsDcrMutation, Types.DeprovisionDataLabFromMediaInsightsDcrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeprovisionDataLabFromMediaInsightsDcrMutation, Types.DeprovisionDataLabFromMediaInsightsDcrMutationVariables>(DeprovisionDataLabFromMediaInsightsDcrDocument, options);
      }
export type DeprovisionDataLabFromMediaInsightsDcrMutationHookResult = ReturnType<typeof useDeprovisionDataLabFromMediaInsightsDcrMutation>;
export type DeprovisionDataLabFromMediaInsightsDcrMutationResult = Apollo.MutationResult<Types.DeprovisionDataLabFromMediaInsightsDcrMutation>;
export type DeprovisionDataLabFromMediaInsightsDcrMutationOptions = Apollo.BaseMutationOptions<Types.DeprovisionDataLabFromMediaInsightsDcrMutation, Types.DeprovisionDataLabFromMediaInsightsDcrMutationVariables>;
export const ProvisionDataLabToMediaInsightsDcrDocument = gql`
    mutation ProvisionDataLabToMediaInsightsDcr($input: ProvisionDataLabInput!) {
  dataLab {
    provisionDataLabToMediaInsightsDcr(input: $input) {
      id
    }
  }
}
    `;
export type ProvisionDataLabToMediaInsightsDcrMutationFn = Apollo.MutationFunction<Types.ProvisionDataLabToMediaInsightsDcrMutation, Types.ProvisionDataLabToMediaInsightsDcrMutationVariables>;

/**
 * __useProvisionDataLabToMediaInsightsDcrMutation__
 *
 * To run a mutation, you first call `useProvisionDataLabToMediaInsightsDcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionDataLabToMediaInsightsDcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionDataLabToMediaInsightsDcrMutation, { data, loading, error }] = useProvisionDataLabToMediaInsightsDcrMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvisionDataLabToMediaInsightsDcrMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvisionDataLabToMediaInsightsDcrMutation, Types.ProvisionDataLabToMediaInsightsDcrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvisionDataLabToMediaInsightsDcrMutation, Types.ProvisionDataLabToMediaInsightsDcrMutationVariables>(ProvisionDataLabToMediaInsightsDcrDocument, options);
      }
export type ProvisionDataLabToMediaInsightsDcrMutationHookResult = ReturnType<typeof useProvisionDataLabToMediaInsightsDcrMutation>;
export type ProvisionDataLabToMediaInsightsDcrMutationResult = Apollo.MutationResult<Types.ProvisionDataLabToMediaInsightsDcrMutation>;
export type ProvisionDataLabToMediaInsightsDcrMutationOptions = Apollo.BaseMutationOptions<Types.ProvisionDataLabToMediaInsightsDcrMutation, Types.ProvisionDataLabToMediaInsightsDcrMutationVariables>;
export const PublishedMediaInsightsDcrDocument = gql`
    query PublishedMediaInsightsDcr($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    driverAttestationHash
    isStopped
    isActive
    deactivatedAt
    createdAt
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrQuery, Types.PublishedMediaInsightsDcrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrQuery, Types.PublishedMediaInsightsDcrQueryVariables>(PublishedMediaInsightsDcrDocument, options);
      }
export function usePublishedMediaInsightsDcrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrQuery, Types.PublishedMediaInsightsDcrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrQuery, Types.PublishedMediaInsightsDcrQueryVariables>(PublishedMediaInsightsDcrDocument, options);
        }
export type PublishedMediaInsightsDcrQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrQuery>;
export type PublishedMediaInsightsDcrLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrLazyQuery>;
export type PublishedMediaInsightsDcrQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrQuery, Types.PublishedMediaInsightsDcrQueryVariables>;
export const PublishedMediaInsightsDcrDataLabDataDocument = gql`
    query PublishedMediaInsightsDcrDataLabData($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    wasDataLabPublishedBefore
    publishedDataLab {
      ...FullDataLab
    }
  }
}
    ${FullDataLabFragment}`;

/**
 * __usePublishedMediaInsightsDcrDataLabDataQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrDataLabDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrDataLabDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrDataLabDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrDataLabDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrDataLabDataQuery, Types.PublishedMediaInsightsDcrDataLabDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrDataLabDataQuery, Types.PublishedMediaInsightsDcrDataLabDataQueryVariables>(PublishedMediaInsightsDcrDataLabDataDocument, options);
      }
export function usePublishedMediaInsightsDcrDataLabDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrDataLabDataQuery, Types.PublishedMediaInsightsDcrDataLabDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrDataLabDataQuery, Types.PublishedMediaInsightsDcrDataLabDataQueryVariables>(PublishedMediaInsightsDcrDataLabDataDocument, options);
        }
export type PublishedMediaInsightsDcrDataLabDataQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrDataLabDataQuery>;
export type PublishedMediaInsightsDcrDataLabDataLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrDataLabDataLazyQuery>;
export type PublishedMediaInsightsDcrDataLabDataQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrDataLabDataQuery, Types.PublishedMediaInsightsDcrDataLabDataQueryVariables>;
export const PublishedMediaInsightsDcrOrganizationsDocument = gql`
    query PublishedMediaInsightsDcrOrganizations($advertiserEmail: String!, $publisherEmail: String!) {
  advertiserOrganization: userByEmail(email: $advertiserEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
  publisherOrganization: userByEmail(email: $publisherEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrOrganizationsQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrOrganizationsQuery({
 *   variables: {
 *      advertiserEmail: // value for 'advertiserEmail'
 *      publisherEmail: // value for 'publisherEmail'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrOrganizationsQuery, Types.PublishedMediaInsightsDcrOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrOrganizationsQuery, Types.PublishedMediaInsightsDcrOrganizationsQueryVariables>(PublishedMediaInsightsDcrOrganizationsDocument, options);
      }
export function usePublishedMediaInsightsDcrOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrOrganizationsQuery, Types.PublishedMediaInsightsDcrOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrOrganizationsQuery, Types.PublishedMediaInsightsDcrOrganizationsQueryVariables>(PublishedMediaInsightsDcrOrganizationsDocument, options);
        }
export type PublishedMediaInsightsDcrOrganizationsQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrOrganizationsQuery>;
export type PublishedMediaInsightsDcrOrganizationsLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrOrganizationsLazyQuery>;
export type PublishedMediaInsightsDcrOrganizationsQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrOrganizationsQuery, Types.PublishedMediaInsightsDcrOrganizationsQueryVariables>;
export const AdvertiserMarketFiltersDocument = gql`
    query AdvertiserMarketFilters {
  publisherMarkets {
    nodes {
      id
      rank
      name
    }
  }
  advertiserMarketFilters
}
    `;

/**
 * __useAdvertiserMarketFiltersQuery__
 *
 * To run a query within a React component, call `useAdvertiserMarketFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertiserMarketFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertiserMarketFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvertiserMarketFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdvertiserMarketFiltersQuery, Types.AdvertiserMarketFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdvertiserMarketFiltersQuery, Types.AdvertiserMarketFiltersQueryVariables>(AdvertiserMarketFiltersDocument, options);
      }
export function useAdvertiserMarketFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdvertiserMarketFiltersQuery, Types.AdvertiserMarketFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdvertiserMarketFiltersQuery, Types.AdvertiserMarketFiltersQueryVariables>(AdvertiserMarketFiltersDocument, options);
        }
export type AdvertiserMarketFiltersQueryHookResult = ReturnType<typeof useAdvertiserMarketFiltersQuery>;
export type AdvertiserMarketFiltersLazyQueryHookResult = ReturnType<typeof useAdvertiserMarketFiltersLazyQuery>;
export type AdvertiserMarketFiltersQueryResult = Apollo.QueryResult<Types.AdvertiserMarketFiltersQuery, Types.AdvertiserMarketFiltersQueryVariables>;
export const AvailableDataPartnersDocument = gql`
    query AvailableDataPartners {
  availableDataPartners {
    nodes {
      ...AvailableDataPartner
    }
    totalCount
  }
}
    ${AvailableDataPartnerFragment}`;

/**
 * __useAvailableDataPartnersQuery__
 *
 * To run a query within a React component, call `useAvailableDataPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDataPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDataPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDataPartnersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AvailableDataPartnersQuery, Types.AvailableDataPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableDataPartnersQuery, Types.AvailableDataPartnersQueryVariables>(AvailableDataPartnersDocument, options);
      }
export function useAvailableDataPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableDataPartnersQuery, Types.AvailableDataPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableDataPartnersQuery, Types.AvailableDataPartnersQueryVariables>(AvailableDataPartnersDocument, options);
        }
export type AvailableDataPartnersQueryHookResult = ReturnType<typeof useAvailableDataPartnersQuery>;
export type AvailableDataPartnersLazyQueryHookResult = ReturnType<typeof useAvailableDataPartnersLazyQuery>;
export type AvailableDataPartnersQueryResult = Apollo.QueryResult<Types.AvailableDataPartnersQuery, Types.AvailableDataPartnersQueryVariables>;
export const AvailablePublishersDocument = gql`
    query AvailablePublishers {
  availablePublishers {
    nodes {
      ...AvailablePublisher
    }
    totalCount
  }
}
    ${AvailablePublisherFragment}`;

/**
 * __useAvailablePublishersQuery__
 *
 * To run a query within a React component, call `useAvailablePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePublishersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePublishersQuery(baseOptions?: Apollo.QueryHookOptions<Types.AvailablePublishersQuery, Types.AvailablePublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailablePublishersQuery, Types.AvailablePublishersQueryVariables>(AvailablePublishersDocument, options);
      }
export function useAvailablePublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailablePublishersQuery, Types.AvailablePublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailablePublishersQuery, Types.AvailablePublishersQueryVariables>(AvailablePublishersDocument, options);
        }
export type AvailablePublishersQueryHookResult = ReturnType<typeof useAvailablePublishersQuery>;
export type AvailablePublishersLazyQueryHookResult = ReturnType<typeof useAvailablePublishersLazyQuery>;
export type AvailablePublishersQueryResult = Apollo.QueryResult<Types.AvailablePublishersQuery, Types.AvailablePublishersQueryVariables>;
export const UpdateAdvertiserMarketFiltersDocument = gql`
    mutation UpdateAdvertiserMarketFilters($input: [String!]!) {
  availablePublisher {
    updateMarketFilters(marketIds: $input)
  }
}
    `;
export type UpdateAdvertiserMarketFiltersMutationFn = Apollo.MutationFunction<Types.UpdateAdvertiserMarketFiltersMutation, Types.UpdateAdvertiserMarketFiltersMutationVariables>;

/**
 * __useUpdateAdvertiserMarketFiltersMutation__
 *
 * To run a mutation, you first call `useUpdateAdvertiserMarketFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvertiserMarketFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvertiserMarketFiltersMutation, { data, loading, error }] = useUpdateAdvertiserMarketFiltersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvertiserMarketFiltersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAdvertiserMarketFiltersMutation, Types.UpdateAdvertiserMarketFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAdvertiserMarketFiltersMutation, Types.UpdateAdvertiserMarketFiltersMutationVariables>(UpdateAdvertiserMarketFiltersDocument, options);
      }
export type UpdateAdvertiserMarketFiltersMutationHookResult = ReturnType<typeof useUpdateAdvertiserMarketFiltersMutation>;
export type UpdateAdvertiserMarketFiltersMutationResult = Apollo.MutationResult<Types.UpdateAdvertiserMarketFiltersMutation>;
export type UpdateAdvertiserMarketFiltersMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAdvertiserMarketFiltersMutation, Types.UpdateAdvertiserMarketFiltersMutationVariables>;
export const CreateMediaInsightsComputeJobDocument = gql`
    mutation CreateMediaInsightsComputeJob($input: CreateMediaComputeJobInput!) {
  mediaComputeJob {
    create(input: $input) {
      record {
        ...MediaInsightsComputeJobComplete
      }
    }
  }
}
    ${MediaInsightsComputeJobCompleteFragment}`;
export type CreateMediaInsightsComputeJobMutationFn = Apollo.MutationFunction<Types.CreateMediaInsightsComputeJobMutation, Types.CreateMediaInsightsComputeJobMutationVariables>;

/**
 * __useCreateMediaInsightsComputeJobMutation__
 *
 * To run a mutation, you first call `useCreateMediaInsightsComputeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaInsightsComputeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaInsightsComputeJobMutation, { data, loading, error }] = useCreateMediaInsightsComputeJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaInsightsComputeJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMediaInsightsComputeJobMutation, Types.CreateMediaInsightsComputeJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMediaInsightsComputeJobMutation, Types.CreateMediaInsightsComputeJobMutationVariables>(CreateMediaInsightsComputeJobDocument, options);
      }
export type CreateMediaInsightsComputeJobMutationHookResult = ReturnType<typeof useCreateMediaInsightsComputeJobMutation>;
export type CreateMediaInsightsComputeJobMutationResult = Apollo.MutationResult<Types.CreateMediaInsightsComputeJobMutation>;
export type CreateMediaInsightsComputeJobMutationOptions = Apollo.BaseMutationOptions<Types.CreateMediaInsightsComputeJobMutation, Types.CreateMediaInsightsComputeJobMutationVariables>;
export const GetMediaInsightsComputeJobDocument = gql`
    query GetMediaInsightsComputeJob($input: MediaComputeJobFilterInput!) {
  mediaComputeJob(input: $input) {
    ...LookalikeMediaComputeJobComplete
  }
}
    ${LookalikeMediaComputeJobCompleteFragment}`;

/**
 * __useGetMediaInsightsComputeJobQuery__
 *
 * To run a query within a React component, call `useGetMediaInsightsComputeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaInsightsComputeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaInsightsComputeJobQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMediaInsightsComputeJobQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMediaInsightsComputeJobQuery, Types.GetMediaInsightsComputeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMediaInsightsComputeJobQuery, Types.GetMediaInsightsComputeJobQueryVariables>(GetMediaInsightsComputeJobDocument, options);
      }
export function useGetMediaInsightsComputeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMediaInsightsComputeJobQuery, Types.GetMediaInsightsComputeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMediaInsightsComputeJobQuery, Types.GetMediaInsightsComputeJobQueryVariables>(GetMediaInsightsComputeJobDocument, options);
        }
export type GetMediaInsightsComputeJobQueryHookResult = ReturnType<typeof useGetMediaInsightsComputeJobQuery>;
export type GetMediaInsightsComputeJobLazyQueryHookResult = ReturnType<typeof useGetMediaInsightsComputeJobLazyQuery>;
export type GetMediaInsightsComputeJobQueryResult = Apollo.QueryResult<Types.GetMediaInsightsComputeJobQuery, Types.GetMediaInsightsComputeJobQueryVariables>;
export const DraftComputeNodeDocument = gql`
    query DraftComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    name
    ... on DraftMatchNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftSqlNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftSqliteNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftPostNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftPreviewNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftScriptingNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftSyntheticNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on DraftS3SinkNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
  }
}
    `;

/**
 * __useDraftComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeQuery, Types.DraftComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeQuery, Types.DraftComputeNodeQueryVariables>(DraftComputeNodeDocument, options);
      }
export function useDraftComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeQuery, Types.DraftComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeQuery, Types.DraftComputeNodeQueryVariables>(DraftComputeNodeDocument, options);
        }
export type DraftComputeNodeQueryHookResult = ReturnType<typeof useDraftComputeNodeQuery>;
export type DraftComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeLazyQuery>;
export type DraftComputeNodeQueryResult = Apollo.QueryResult<Types.DraftComputeNodeQuery, Types.DraftComputeNodeQueryVariables>;
export const PublishedComputeNodeDocument = gql`
    query PublishedComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    ... on PublishedSqlNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSqliteNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedMatchNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPostNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPreviewNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedScriptingNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSyntheticNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedS3SinkNode {
      computationType
      job {
        id
        status
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
  }
}
    `;

/**
 * __usePublishedComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeQuery, Types.PublishedComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeQuery, Types.PublishedComputeNodeQueryVariables>(PublishedComputeNodeDocument, options);
      }
export function usePublishedComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeQuery, Types.PublishedComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeQuery, Types.PublishedComputeNodeQueryVariables>(PublishedComputeNodeDocument, options);
        }
export type PublishedComputeNodeQueryHookResult = ReturnType<typeof usePublishedComputeNodeQuery>;
export type PublishedComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeLazyQuery>;
export type PublishedComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeQuery, Types.PublishedComputeNodeQueryVariables>;
export const CommittedComputeNodeActionsDocument = gql`
    query CommittedComputeNodeActions($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    permissions {
      ... on PublishedAnalystPermission {
        participant {
          userEmail
        }
      }
    }
    ... on PublishedSqlNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSqliteNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedMatchNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPostNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPreviewNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedScriptingNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSyntheticNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedS3SinkNode {
      computationType
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
  }
}
    `;

/**
 * __useCommittedComputeNodeActionsQuery__
 *
 * To run a query within a React component, call `useCommittedComputeNodeActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommittedComputeNodeActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommittedComputeNodeActionsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function useCommittedComputeNodeActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.CommittedComputeNodeActionsQuery, Types.CommittedComputeNodeActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CommittedComputeNodeActionsQuery, Types.CommittedComputeNodeActionsQueryVariables>(CommittedComputeNodeActionsDocument, options);
      }
export function useCommittedComputeNodeActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CommittedComputeNodeActionsQuery, Types.CommittedComputeNodeActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CommittedComputeNodeActionsQuery, Types.CommittedComputeNodeActionsQueryVariables>(CommittedComputeNodeActionsDocument, options);
        }
export type CommittedComputeNodeActionsQueryHookResult = ReturnType<typeof useCommittedComputeNodeActionsQuery>;
export type CommittedComputeNodeActionsLazyQueryHookResult = ReturnType<typeof useCommittedComputeNodeActionsLazyQuery>;
export type CommittedComputeNodeActionsQueryResult = Apollo.QueryResult<Types.CommittedComputeNodeActionsQuery, Types.CommittedComputeNodeActionsQueryVariables>;
export const CreateRequestFromPlaygroundDocument = gql`
    mutation CreateRequestFromPlayground($playgroundId: String!) {
  dataRoomRequest {
    createFromPlayground(playgroundId: $playgroundId) {
      id
      draftNode {
        id
        name
        ... on DraftSyntheticNode {
          computationType
        }
        ... on DraftPostNode {
          computationType
        }
        ... on DraftPreviewNode {
          computationType
        }
        ... on DraftS3SinkNode {
          computationType
        }
        ... on DraftScriptingNode {
          computationType
        }
        ... on DraftSqlNode {
          computationType
        }
        ... on DraftSqliteNode {
          computationType
        }
        ... on DraftMatchNode {
          computationType
        }
      }
    }
  }
}
    `;
export type CreateRequestFromPlaygroundMutationFn = Apollo.MutationFunction<Types.CreateRequestFromPlaygroundMutation, Types.CreateRequestFromPlaygroundMutationVariables>;

/**
 * __useCreateRequestFromPlaygroundMutation__
 *
 * To run a mutation, you first call `useCreateRequestFromPlaygroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestFromPlaygroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestFromPlaygroundMutation, { data, loading, error }] = useCreateRequestFromPlaygroundMutation({
 *   variables: {
 *      playgroundId: // value for 'playgroundId'
 *   },
 * });
 */
export function useCreateRequestFromPlaygroundMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRequestFromPlaygroundMutation, Types.CreateRequestFromPlaygroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRequestFromPlaygroundMutation, Types.CreateRequestFromPlaygroundMutationVariables>(CreateRequestFromPlaygroundDocument, options);
      }
export type CreateRequestFromPlaygroundMutationHookResult = ReturnType<typeof useCreateRequestFromPlaygroundMutation>;
export type CreateRequestFromPlaygroundMutationResult = Apollo.MutationResult<Types.CreateRequestFromPlaygroundMutation>;
export type CreateRequestFromPlaygroundMutationOptions = Apollo.BaseMutationOptions<Types.CreateRequestFromPlaygroundMutation, Types.CreateRequestFromPlaygroundMutationVariables>;
export const DeletePlaygroundDocument = gql`
    mutation DeletePlayground($id: String!) {
  playground {
    delete(id: $id)
  }
}
    `;
export type DeletePlaygroundMutationFn = Apollo.MutationFunction<Types.DeletePlaygroundMutation, Types.DeletePlaygroundMutationVariables>;

/**
 * __useDeletePlaygroundMutation__
 *
 * To run a mutation, you first call `useDeletePlaygroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaygroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaygroundMutation, { data, loading, error }] = useDeletePlaygroundMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaygroundMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlaygroundMutation, Types.DeletePlaygroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlaygroundMutation, Types.DeletePlaygroundMutationVariables>(DeletePlaygroundDocument, options);
      }
export type DeletePlaygroundMutationHookResult = ReturnType<typeof useDeletePlaygroundMutation>;
export type DeletePlaygroundMutationResult = Apollo.MutationResult<Types.DeletePlaygroundMutation>;
export type DeletePlaygroundMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlaygroundMutation, Types.DeletePlaygroundMutationVariables>;
export const DevComputeNodeActionsDocument = gql`
    query DevComputeNodeActions($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    name
    ... on DraftMatchNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftSqlNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftSqliteNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftPostNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftPreviewNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftScriptingNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftSyntheticNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
    ... on DraftS3SinkNode {
      computationType
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
      }
    }
  }
}
    `;

/**
 * __useDevComputeNodeActionsQuery__
 *
 * To run a query within a React component, call `useDevComputeNodeActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodeActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevComputeNodeActionsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDevComputeNodeActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.DevComputeNodeActionsQuery, Types.DevComputeNodeActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DevComputeNodeActionsQuery, Types.DevComputeNodeActionsQueryVariables>(DevComputeNodeActionsDocument, options);
      }
export function useDevComputeNodeActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DevComputeNodeActionsQuery, Types.DevComputeNodeActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DevComputeNodeActionsQuery, Types.DevComputeNodeActionsQueryVariables>(DevComputeNodeActionsDocument, options);
        }
export type DevComputeNodeActionsQueryHookResult = ReturnType<typeof useDevComputeNodeActionsQuery>;
export type DevComputeNodeActionsLazyQueryHookResult = ReturnType<typeof useDevComputeNodeActionsLazyQuery>;
export type DevComputeNodeActionsQueryResult = Apollo.QueryResult<Types.DevComputeNodeActionsQuery, Types.DevComputeNodeActionsQueryVariables>;
export const RequestComputeNodeActionsDocument = gql`
    query RequestComputeNodeActions($requestId: String!) {
  dataRoomRequest(id: $requestId) {
    id
    draftNode {
      id
      ... on DraftMatchNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftSqlNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftSqliteNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftSqliteNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftPostNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftPreviewNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftScriptingNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftSyntheticNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on DraftS3SinkNode {
        job @client {
          id
          enclaveComputeJobId
          status
          createdAt
          autoFetching
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
    }
  }
}
    `;

/**
 * __useRequestComputeNodeActionsQuery__
 *
 * To run a query within a React component, call `useRequestComputeNodeActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestComputeNodeActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestComputeNodeActionsQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRequestComputeNodeActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestComputeNodeActionsQuery, Types.RequestComputeNodeActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestComputeNodeActionsQuery, Types.RequestComputeNodeActionsQueryVariables>(RequestComputeNodeActionsDocument, options);
      }
export function useRequestComputeNodeActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestComputeNodeActionsQuery, Types.RequestComputeNodeActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestComputeNodeActionsQuery, Types.RequestComputeNodeActionsQueryVariables>(RequestComputeNodeActionsDocument, options);
        }
export type RequestComputeNodeActionsQueryHookResult = ReturnType<typeof useRequestComputeNodeActionsQuery>;
export type RequestComputeNodeActionsLazyQueryHookResult = ReturnType<typeof useRequestComputeNodeActionsLazyQuery>;
export type RequestComputeNodeActionsQueryResult = Apollo.QueryResult<Types.RequestComputeNodeActionsQuery, Types.RequestComputeNodeActionsQueryVariables>;
export const SubmittedRequestComputeNodeActionsDocument = gql`
    query SubmittedRequestComputeNodeActions($requestId: String!, $computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    ... on PublishedSqlNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSqliteNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedMatchNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPostNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPreviewNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedScriptingNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSyntheticNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedS3SinkNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
  }
  submittedDataRoomRequest(id: $requestId) {
    id
    signers {
      nodes {
        id
        userEmail
        user {
          id
          email
        }
      }
    }
  }
}
    `;

/**
 * __useSubmittedRequestComputeNodeActionsQuery__
 *
 * To run a query within a React component, call `useSubmittedRequestComputeNodeActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestComputeNodeActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRequestComputeNodeActionsQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function useSubmittedRequestComputeNodeActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedRequestComputeNodeActionsQuery, Types.SubmittedRequestComputeNodeActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedRequestComputeNodeActionsQuery, Types.SubmittedRequestComputeNodeActionsQueryVariables>(SubmittedRequestComputeNodeActionsDocument, options);
      }
export function useSubmittedRequestComputeNodeActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedRequestComputeNodeActionsQuery, Types.SubmittedRequestComputeNodeActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedRequestComputeNodeActionsQuery, Types.SubmittedRequestComputeNodeActionsQueryVariables>(SubmittedRequestComputeNodeActionsDocument, options);
        }
export type SubmittedRequestComputeNodeActionsQueryHookResult = ReturnType<typeof useSubmittedRequestComputeNodeActionsQuery>;
export type SubmittedRequestComputeNodeActionsLazyQueryHookResult = ReturnType<typeof useSubmittedRequestComputeNodeActionsLazyQuery>;
export type SubmittedRequestComputeNodeActionsQueryResult = Apollo.QueryResult<Types.SubmittedRequestComputeNodeActionsQuery, Types.SubmittedRequestComputeNodeActionsQueryVariables>;
export const DownloadComputeNodeResultAsCsvDocument = gql`
    mutation DownloadComputeNodeResultAsCsv($input: RetrieveComputeJobResultFileInput!) {
  retrieveComputeJobResultFileAsCsv(input: $input) @client {
    resultCsv
  }
}
    `;
export type DownloadComputeNodeResultAsCsvMutationFn = Apollo.MutationFunction<Types.DownloadComputeNodeResultAsCsvMutation, Types.DownloadComputeNodeResultAsCsvMutationVariables>;

/**
 * __useDownloadComputeNodeResultAsCsvMutation__
 *
 * To run a mutation, you first call `useDownloadComputeNodeResultAsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadComputeNodeResultAsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadComputeNodeResultAsCsvMutation, { data, loading, error }] = useDownloadComputeNodeResultAsCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadComputeNodeResultAsCsvMutation(baseOptions?: Apollo.MutationHookOptions<Types.DownloadComputeNodeResultAsCsvMutation, Types.DownloadComputeNodeResultAsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DownloadComputeNodeResultAsCsvMutation, Types.DownloadComputeNodeResultAsCsvMutationVariables>(DownloadComputeNodeResultAsCsvDocument, options);
      }
export type DownloadComputeNodeResultAsCsvMutationHookResult = ReturnType<typeof useDownloadComputeNodeResultAsCsvMutation>;
export type DownloadComputeNodeResultAsCsvMutationResult = Apollo.MutationResult<Types.DownloadComputeNodeResultAsCsvMutation>;
export type DownloadComputeNodeResultAsCsvMutationOptions = Apollo.BaseMutationOptions<Types.DownloadComputeNodeResultAsCsvMutation, Types.DownloadComputeNodeResultAsCsvMutationVariables>;
export const DownloadComputeNodeResultAsZipDocument = gql`
    mutation DownloadComputeNodeResultAsZip($input: RetrieveComputeJobResultFileInput!) {
  retrieveComputeJobResultFileAsZip(input: $input) @client {
    resultZip
  }
}
    `;
export type DownloadComputeNodeResultAsZipMutationFn = Apollo.MutationFunction<Types.DownloadComputeNodeResultAsZipMutation, Types.DownloadComputeNodeResultAsZipMutationVariables>;

/**
 * __useDownloadComputeNodeResultAsZipMutation__
 *
 * To run a mutation, you first call `useDownloadComputeNodeResultAsZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadComputeNodeResultAsZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadComputeNodeResultAsZipMutation, { data, loading, error }] = useDownloadComputeNodeResultAsZipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadComputeNodeResultAsZipMutation(baseOptions?: Apollo.MutationHookOptions<Types.DownloadComputeNodeResultAsZipMutation, Types.DownloadComputeNodeResultAsZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DownloadComputeNodeResultAsZipMutation, Types.DownloadComputeNodeResultAsZipMutationVariables>(DownloadComputeNodeResultAsZipDocument, options);
      }
export type DownloadComputeNodeResultAsZipMutationHookResult = ReturnType<typeof useDownloadComputeNodeResultAsZipMutation>;
export type DownloadComputeNodeResultAsZipMutationResult = Apollo.MutationResult<Types.DownloadComputeNodeResultAsZipMutation>;
export type DownloadComputeNodeResultAsZipMutationOptions = Apollo.BaseMutationOptions<Types.DownloadComputeNodeResultAsZipMutation, Types.DownloadComputeNodeResultAsZipMutationVariables>;
export const PreviewComputeNodeResultDocument = gql`
    query PreviewComputeNodeResult($id: String!) {
  computeJob(id: $id) {
    id
    autoFetching @client
  }
}
    `;

/**
 * __usePreviewComputeNodeResultQuery__
 *
 * To run a query within a React component, call `usePreviewComputeNodeResultQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewComputeNodeResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewComputeNodeResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewComputeNodeResultQuery(baseOptions: Apollo.QueryHookOptions<Types.PreviewComputeNodeResultQuery, Types.PreviewComputeNodeResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreviewComputeNodeResultQuery, Types.PreviewComputeNodeResultQueryVariables>(PreviewComputeNodeResultDocument, options);
      }
export function usePreviewComputeNodeResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewComputeNodeResultQuery, Types.PreviewComputeNodeResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreviewComputeNodeResultQuery, Types.PreviewComputeNodeResultQueryVariables>(PreviewComputeNodeResultDocument, options);
        }
export type PreviewComputeNodeResultQueryHookResult = ReturnType<typeof usePreviewComputeNodeResultQuery>;
export type PreviewComputeNodeResultLazyQueryHookResult = ReturnType<typeof usePreviewComputeNodeResultLazyQuery>;
export type PreviewComputeNodeResultQueryResult = Apollo.QueryResult<Types.PreviewComputeNodeResultQuery, Types.PreviewComputeNodeResultQueryVariables>;
export const ComputeNodeRunDocument = gql`
    mutation ComputeNodeRun($dcrHash: HexString!, $driverAttestationHash: HexString!, $computeNodeId: String!, $dataRoomId: String!, $autoFetching: Boolean, $testDatasets: [TestDataset!]) {
  runComputation(
    input: {dcrHash: $dcrHash, driverAttestationHash: $driverAttestationHash, computeNodeId: $computeNodeId, dataRoomId: $dataRoomId, autoFetching: $autoFetching, testDatasets: $testDatasets}
  ) @client {
    id
    enclaveComputeJobId
    status
    updatedAt
    createdAt
    autoFetching @client
  }
}
    `;
export type ComputeNodeRunMutationFn = Apollo.MutationFunction<Types.ComputeNodeRunMutation, Types.ComputeNodeRunMutationVariables>;

/**
 * __useComputeNodeRunMutation__
 *
 * To run a mutation, you first call `useComputeNodeRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComputeNodeRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [computeNodeRunMutation, { data, loading, error }] = useComputeNodeRunMutation({
 *   variables: {
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      computeNodeId: // value for 'computeNodeId'
 *      dataRoomId: // value for 'dataRoomId'
 *      autoFetching: // value for 'autoFetching'
 *      testDatasets: // value for 'testDatasets'
 *   },
 * });
 */
export function useComputeNodeRunMutation(baseOptions?: Apollo.MutationHookOptions<Types.ComputeNodeRunMutation, Types.ComputeNodeRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ComputeNodeRunMutation, Types.ComputeNodeRunMutationVariables>(ComputeNodeRunDocument, options);
      }
export type ComputeNodeRunMutationHookResult = ReturnType<typeof useComputeNodeRunMutation>;
export type ComputeNodeRunMutationResult = Apollo.MutationResult<Types.ComputeNodeRunMutation>;
export type ComputeNodeRunMutationOptions = Apollo.BaseMutationOptions<Types.ComputeNodeRunMutation, Types.ComputeNodeRunMutationVariables>;
export const DevComputeNodeRunDocument = gql`
    mutation DevComputeNodeRun($input: RunDevComputationInput!) {
  runDevComputation(input: $input) @client {
    id
    enclaveComputeJobId
    status
    createdAt
  }
}
    `;
export type DevComputeNodeRunMutationFn = Apollo.MutationFunction<Types.DevComputeNodeRunMutation, Types.DevComputeNodeRunMutationVariables>;

/**
 * __useDevComputeNodeRunMutation__
 *
 * To run a mutation, you first call `useDevComputeNodeRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodeRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devComputeNodeRunMutation, { data, loading, error }] = useDevComputeNodeRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevComputeNodeRunMutation(baseOptions?: Apollo.MutationHookOptions<Types.DevComputeNodeRunMutation, Types.DevComputeNodeRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DevComputeNodeRunMutation, Types.DevComputeNodeRunMutationVariables>(DevComputeNodeRunDocument, options);
      }
export type DevComputeNodeRunMutationHookResult = ReturnType<typeof useDevComputeNodeRunMutation>;
export type DevComputeNodeRunMutationResult = Apollo.MutationResult<Types.DevComputeNodeRunMutation>;
export type DevComputeNodeRunMutationOptions = Apollo.BaseMutationOptions<Types.DevComputeNodeRunMutation, Types.DevComputeNodeRunMutationVariables>;
export const DraftNodeTypenameDocument = gql`
    query DraftNodeTypename($id: String!) {
  draftNode(id: $id) {
    __typename
  }
}
    `;

/**
 * __useDraftNodeTypenameQuery__
 *
 * To run a query within a React component, call `useDraftNodeTypenameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftNodeTypenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftNodeTypenameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftNodeTypenameQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftNodeTypenameQuery, Types.DraftNodeTypenameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftNodeTypenameQuery, Types.DraftNodeTypenameQueryVariables>(DraftNodeTypenameDocument, options);
      }
export function useDraftNodeTypenameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftNodeTypenameQuery, Types.DraftNodeTypenameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftNodeTypenameQuery, Types.DraftNodeTypenameQueryVariables>(DraftNodeTypenameDocument, options);
        }
export type DraftNodeTypenameQueryHookResult = ReturnType<typeof useDraftNodeTypenameQuery>;
export type DraftNodeTypenameLazyQueryHookResult = ReturnType<typeof useDraftNodeTypenameLazyQuery>;
export type DraftNodeTypenameQueryResult = Apollo.QueryResult<Types.DraftNodeTypenameQuery, Types.DraftNodeTypenameQueryVariables>;
export const MatchNodeDeleteDocument = gql`
    mutation MatchNodeDelete($id: String!) {
  draftMatchNode {
    delete(id: $id)
  }
}
    `;
export type MatchNodeDeleteMutationFn = Apollo.MutationFunction<Types.MatchNodeDeleteMutation, Types.MatchNodeDeleteMutationVariables>;

/**
 * __useMatchNodeDeleteMutation__
 *
 * To run a mutation, you first call `useMatchNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchNodeDeleteMutation, { data, loading, error }] = useMatchNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMatchNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.MatchNodeDeleteMutation, Types.MatchNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MatchNodeDeleteMutation, Types.MatchNodeDeleteMutationVariables>(MatchNodeDeleteDocument, options);
      }
export type MatchNodeDeleteMutationHookResult = ReturnType<typeof useMatchNodeDeleteMutation>;
export type MatchNodeDeleteMutationResult = Apollo.MutationResult<Types.MatchNodeDeleteMutation>;
export type MatchNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.MatchNodeDeleteMutation, Types.MatchNodeDeleteMutationVariables>;
export const PostNodeDeleteDocument = gql`
    mutation PostNodeDelete($id: String!) {
  draftPostNode {
    delete(id: $id)
  }
}
    `;
export type PostNodeDeleteMutationFn = Apollo.MutationFunction<Types.PostNodeDeleteMutation, Types.PostNodeDeleteMutationVariables>;

/**
 * __usePostNodeDeleteMutation__
 *
 * To run a mutation, you first call `usePostNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postNodeDeleteMutation, { data, loading, error }] = usePostNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.PostNodeDeleteMutation, Types.PostNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PostNodeDeleteMutation, Types.PostNodeDeleteMutationVariables>(PostNodeDeleteDocument, options);
      }
export type PostNodeDeleteMutationHookResult = ReturnType<typeof usePostNodeDeleteMutation>;
export type PostNodeDeleteMutationResult = Apollo.MutationResult<Types.PostNodeDeleteMutation>;
export type PostNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.PostNodeDeleteMutation, Types.PostNodeDeleteMutationVariables>;
export const PreviewNodeDeleteDocument = gql`
    mutation PreviewNodeDelete($id: String!) {
  draftPreviewNode {
    delete(id: $id)
  }
}
    `;
export type PreviewNodeDeleteMutationFn = Apollo.MutationFunction<Types.PreviewNodeDeleteMutation, Types.PreviewNodeDeleteMutationVariables>;

/**
 * __usePreviewNodeDeleteMutation__
 *
 * To run a mutation, you first call `usePreviewNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewNodeDeleteMutation, { data, loading, error }] = usePreviewNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.PreviewNodeDeleteMutation, Types.PreviewNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PreviewNodeDeleteMutation, Types.PreviewNodeDeleteMutationVariables>(PreviewNodeDeleteDocument, options);
      }
export type PreviewNodeDeleteMutationHookResult = ReturnType<typeof usePreviewNodeDeleteMutation>;
export type PreviewNodeDeleteMutationResult = Apollo.MutationResult<Types.PreviewNodeDeleteMutation>;
export type PreviewNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.PreviewNodeDeleteMutation, Types.PreviewNodeDeleteMutationVariables>;
export const S3NodeDeleteDocument = gql`
    mutation S3NodeDelete($id: String!) {
  draftS3SinkNode {
    delete(id: $id)
  }
}
    `;
export type S3NodeDeleteMutationFn = Apollo.MutationFunction<Types.S3NodeDeleteMutation, Types.S3NodeDeleteMutationVariables>;

/**
 * __useS3NodeDeleteMutation__
 *
 * To run a mutation, you first call `useS3NodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useS3NodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [s3NodeDeleteMutation, { data, loading, error }] = useS3NodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useS3NodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.S3NodeDeleteMutation, Types.S3NodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.S3NodeDeleteMutation, Types.S3NodeDeleteMutationVariables>(S3NodeDeleteDocument, options);
      }
export type S3NodeDeleteMutationHookResult = ReturnType<typeof useS3NodeDeleteMutation>;
export type S3NodeDeleteMutationResult = Apollo.MutationResult<Types.S3NodeDeleteMutation>;
export type S3NodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.S3NodeDeleteMutation, Types.S3NodeDeleteMutationVariables>;
export const ScriptingNodeDeleteDocument = gql`
    mutation ScriptingNodeDelete($id: String!) {
  draftScriptingNode {
    delete(id: $id)
  }
}
    `;
export type ScriptingNodeDeleteMutationFn = Apollo.MutationFunction<Types.ScriptingNodeDeleteMutation, Types.ScriptingNodeDeleteMutationVariables>;

/**
 * __useScriptingNodeDeleteMutation__
 *
 * To run a mutation, you first call `useScriptingNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScriptingNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scriptingNodeDeleteMutation, { data, loading, error }] = useScriptingNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScriptingNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.ScriptingNodeDeleteMutation, Types.ScriptingNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ScriptingNodeDeleteMutation, Types.ScriptingNodeDeleteMutationVariables>(ScriptingNodeDeleteDocument, options);
      }
export type ScriptingNodeDeleteMutationHookResult = ReturnType<typeof useScriptingNodeDeleteMutation>;
export type ScriptingNodeDeleteMutationResult = Apollo.MutationResult<Types.ScriptingNodeDeleteMutation>;
export type ScriptingNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.ScriptingNodeDeleteMutation, Types.ScriptingNodeDeleteMutationVariables>;
export const SqlNodeDeleteDocument = gql`
    mutation SqlNodeDelete($id: String!) {
  draftSqlNode {
    delete(id: $id)
  }
}
    `;
export type SqlNodeDeleteMutationFn = Apollo.MutationFunction<Types.SqlNodeDeleteMutation, Types.SqlNodeDeleteMutationVariables>;

/**
 * __useSqlNodeDeleteMutation__
 *
 * To run a mutation, you first call `useSqlNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqlNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqlNodeDeleteMutation, { data, loading, error }] = useSqlNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSqlNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqlNodeDeleteMutation, Types.SqlNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqlNodeDeleteMutation, Types.SqlNodeDeleteMutationVariables>(SqlNodeDeleteDocument, options);
      }
export type SqlNodeDeleteMutationHookResult = ReturnType<typeof useSqlNodeDeleteMutation>;
export type SqlNodeDeleteMutationResult = Apollo.MutationResult<Types.SqlNodeDeleteMutation>;
export type SqlNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.SqlNodeDeleteMutation, Types.SqlNodeDeleteMutationVariables>;
export const SqliteNodeDeleteDocument = gql`
    mutation SqliteNodeDelete($id: String!) {
  draftSqliteNode {
    delete(id: $id)
  }
}
    `;
export type SqliteNodeDeleteMutationFn = Apollo.MutationFunction<Types.SqliteNodeDeleteMutation, Types.SqliteNodeDeleteMutationVariables>;

/**
 * __useSqliteNodeDeleteMutation__
 *
 * To run a mutation, you first call `useSqliteNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqliteNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqliteNodeDeleteMutation, { data, loading, error }] = useSqliteNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSqliteNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqliteNodeDeleteMutation, Types.SqliteNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqliteNodeDeleteMutation, Types.SqliteNodeDeleteMutationVariables>(SqliteNodeDeleteDocument, options);
      }
export type SqliteNodeDeleteMutationHookResult = ReturnType<typeof useSqliteNodeDeleteMutation>;
export type SqliteNodeDeleteMutationResult = Apollo.MutationResult<Types.SqliteNodeDeleteMutation>;
export type SqliteNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.SqliteNodeDeleteMutation, Types.SqliteNodeDeleteMutationVariables>;
export const SyntheticNodeDeleteDocument = gql`
    mutation SyntheticNodeDelete($id: String!) {
  draftSyntheticNode {
    delete(id: $id)
  }
}
    `;
export type SyntheticNodeDeleteMutationFn = Apollo.MutationFunction<Types.SyntheticNodeDeleteMutation, Types.SyntheticNodeDeleteMutationVariables>;

/**
 * __useSyntheticNodeDeleteMutation__
 *
 * To run a mutation, you first call `useSyntheticNodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyntheticNodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syntheticNodeDeleteMutation, { data, loading, error }] = useSyntheticNodeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyntheticNodeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyntheticNodeDeleteMutation, Types.SyntheticNodeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyntheticNodeDeleteMutation, Types.SyntheticNodeDeleteMutationVariables>(SyntheticNodeDeleteDocument, options);
      }
export type SyntheticNodeDeleteMutationHookResult = ReturnType<typeof useSyntheticNodeDeleteMutation>;
export type SyntheticNodeDeleteMutationResult = Apollo.MutationResult<Types.SyntheticNodeDeleteMutation>;
export type SyntheticNodeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.SyntheticNodeDeleteMutation, Types.SyntheticNodeDeleteMutationVariables>;
export const SubmittedRequestComputeNodeRunDocument = gql`
    mutation SubmittedRequestComputeNodeRun($input: RunSubmittedDataRoomRequestInput!) {
  runSubmittedDataRoomRequest(input: $input) @client {
    id
    enclaveComputeJobId
    status
    createdAt
  }
}
    `;
export type SubmittedRequestComputeNodeRunMutationFn = Apollo.MutationFunction<Types.SubmittedRequestComputeNodeRunMutation, Types.SubmittedRequestComputeNodeRunMutationVariables>;

/**
 * __useSubmittedRequestComputeNodeRunMutation__
 *
 * To run a mutation, you first call `useSubmittedRequestComputeNodeRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestComputeNodeRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submittedRequestComputeNodeRunMutation, { data, loading, error }] = useSubmittedRequestComputeNodeRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmittedRequestComputeNodeRunMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmittedRequestComputeNodeRunMutation, Types.SubmittedRequestComputeNodeRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmittedRequestComputeNodeRunMutation, Types.SubmittedRequestComputeNodeRunMutationVariables>(SubmittedRequestComputeNodeRunDocument, options);
      }
export type SubmittedRequestComputeNodeRunMutationHookResult = ReturnType<typeof useSubmittedRequestComputeNodeRunMutation>;
export type SubmittedRequestComputeNodeRunMutationResult = Apollo.MutationResult<Types.SubmittedRequestComputeNodeRunMutation>;
export type SubmittedRequestComputeNodeRunMutationOptions = Apollo.BaseMutationOptions<Types.SubmittedRequestComputeNodeRunMutation, Types.SubmittedRequestComputeNodeRunMutationVariables>;
export const TestDraftComputationDocument = gql`
    mutation TestDraftComputation($input: TestDraftComputationInput!) {
  testDraftComputation(input: $input) @client {
    id
    enclaveComputeJobId
    status
    updatedAt
    createdAt
    autoFetching @client
  }
}
    `;
export type TestDraftComputationMutationFn = Apollo.MutationFunction<Types.TestDraftComputationMutation, Types.TestDraftComputationMutationVariables>;

/**
 * __useTestDraftComputationMutation__
 *
 * To run a mutation, you first call `useTestDraftComputationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestDraftComputationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testDraftComputationMutation, { data, loading, error }] = useTestDraftComputationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestDraftComputationMutation(baseOptions?: Apollo.MutationHookOptions<Types.TestDraftComputationMutation, Types.TestDraftComputationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TestDraftComputationMutation, Types.TestDraftComputationMutationVariables>(TestDraftComputationDocument, options);
      }
export type TestDraftComputationMutationHookResult = ReturnType<typeof useTestDraftComputationMutation>;
export type TestDraftComputationMutationResult = Apollo.MutationResult<Types.TestDraftComputationMutation>;
export type TestDraftComputationMutationOptions = Apollo.BaseMutationOptions<Types.TestDraftComputationMutation, Types.TestDraftComputationMutationVariables>;
export const ValidateDataRoomRequestDocument = gql`
    mutation ValidateDataRoomRequest($input: ValidateDataRoomRequestInput!) {
  validateDataRoomRequest(input: $input) @client {
    id
    enclaveComputeJobId
    status
  }
}
    `;
export type ValidateDataRoomRequestMutationFn = Apollo.MutationFunction<Types.ValidateDataRoomRequestMutation, Types.ValidateDataRoomRequestMutationVariables>;

/**
 * __useValidateDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useValidateDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateDataRoomRequestMutation, { data, loading, error }] = useValidateDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.ValidateDataRoomRequestMutation, Types.ValidateDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ValidateDataRoomRequestMutation, Types.ValidateDataRoomRequestMutationVariables>(ValidateDataRoomRequestDocument, options);
      }
export type ValidateDataRoomRequestMutationHookResult = ReturnType<typeof useValidateDataRoomRequestMutation>;
export type ValidateDataRoomRequestMutationResult = Apollo.MutationResult<Types.ValidateDataRoomRequestMutation>;
export type ValidateDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.ValidateDataRoomRequestMutation, Types.ValidateDataRoomRequestMutationVariables>;
export const MatchNodeCreateDocument = gql`
    mutation MatchNodeCreate($input: CreateDraftMatchNodeInput!) {
  draftMatchNode {
    create(input: $input) {
      id
      name
    }
  }
}
    `;
export type MatchNodeCreateMutationFn = Apollo.MutationFunction<Types.MatchNodeCreateMutation, Types.MatchNodeCreateMutationVariables>;

/**
 * __useMatchNodeCreateMutation__
 *
 * To run a mutation, you first call `useMatchNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchNodeCreateMutation, { data, loading, error }] = useMatchNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMatchNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.MatchNodeCreateMutation, Types.MatchNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MatchNodeCreateMutation, Types.MatchNodeCreateMutationVariables>(MatchNodeCreateDocument, options);
      }
export type MatchNodeCreateMutationHookResult = ReturnType<typeof useMatchNodeCreateMutation>;
export type MatchNodeCreateMutationResult = Apollo.MutationResult<Types.MatchNodeCreateMutation>;
export type MatchNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.MatchNodeCreateMutation, Types.MatchNodeCreateMutationVariables>;
export const PostNodeCreateDocument = gql`
    mutation PostNodeCreate($input: CreateDraftPostNodeInput!) {
  draftPostNode {
    create(input: $input) {
      id
      name
      computationType
    }
  }
}
    `;
export type PostNodeCreateMutationFn = Apollo.MutationFunction<Types.PostNodeCreateMutation, Types.PostNodeCreateMutationVariables>;

/**
 * __usePostNodeCreateMutation__
 *
 * To run a mutation, you first call `usePostNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postNodeCreateMutation, { data, loading, error }] = usePostNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.PostNodeCreateMutation, Types.PostNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PostNodeCreateMutation, Types.PostNodeCreateMutationVariables>(PostNodeCreateDocument, options);
      }
export type PostNodeCreateMutationHookResult = ReturnType<typeof usePostNodeCreateMutation>;
export type PostNodeCreateMutationResult = Apollo.MutationResult<Types.PostNodeCreateMutation>;
export type PostNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.PostNodeCreateMutation, Types.PostNodeCreateMutationVariables>;
export const PreviewNodeCreateDocument = gql`
    mutation PreviewNodeCreate($input: CreateDraftPreviewNodeInput!) {
  draftPreviewNode {
    create(input: $input) {
      id
      name
      quotaBytes
      computationType
    }
  }
}
    `;
export type PreviewNodeCreateMutationFn = Apollo.MutationFunction<Types.PreviewNodeCreateMutation, Types.PreviewNodeCreateMutationVariables>;

/**
 * __usePreviewNodeCreateMutation__
 *
 * To run a mutation, you first call `usePreviewNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewNodeCreateMutation, { data, loading, error }] = usePreviewNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.PreviewNodeCreateMutation, Types.PreviewNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PreviewNodeCreateMutation, Types.PreviewNodeCreateMutationVariables>(PreviewNodeCreateDocument, options);
      }
export type PreviewNodeCreateMutationHookResult = ReturnType<typeof usePreviewNodeCreateMutation>;
export type PreviewNodeCreateMutationResult = Apollo.MutationResult<Types.PreviewNodeCreateMutation>;
export type PreviewNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.PreviewNodeCreateMutation, Types.PreviewNodeCreateMutationVariables>;
export const S3NodeCreateDocument = gql`
    mutation S3NodeCreate($input: CreateDraftS3SinkNodeInput!) {
  draftS3SinkNode {
    create(input: $input) {
      id
      name
      endpoint
      region
      computationType
      credentialsDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
      }
      uploadDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type S3NodeCreateMutationFn = Apollo.MutationFunction<Types.S3NodeCreateMutation, Types.S3NodeCreateMutationVariables>;

/**
 * __useS3NodeCreateMutation__
 *
 * To run a mutation, you first call `useS3NodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useS3NodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [s3NodeCreateMutation, { data, loading, error }] = useS3NodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useS3NodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.S3NodeCreateMutation, Types.S3NodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.S3NodeCreateMutation, Types.S3NodeCreateMutationVariables>(S3NodeCreateDocument, options);
      }
export type S3NodeCreateMutationHookResult = ReturnType<typeof useS3NodeCreateMutation>;
export type S3NodeCreateMutationResult = Apollo.MutationResult<Types.S3NodeCreateMutation>;
export type S3NodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.S3NodeCreateMutation, Types.S3NodeCreateMutationVariables>;
export const ScriptingNodeCreateDocument = gql`
    mutation ScriptingNodeCreate($input: CreateDraftScriptingNodeInput!) {
  draftScriptingNode {
    create(input: $input) {
      id
      name
      scriptingLanguage
      computationType
      scripts {
        nodes {
          id
          name
          content
        }
      }
      output
      scriptingLanguage
    }
  }
}
    `;
export type ScriptingNodeCreateMutationFn = Apollo.MutationFunction<Types.ScriptingNodeCreateMutation, Types.ScriptingNodeCreateMutationVariables>;

/**
 * __useScriptingNodeCreateMutation__
 *
 * To run a mutation, you first call `useScriptingNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScriptingNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scriptingNodeCreateMutation, { data, loading, error }] = useScriptingNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScriptingNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ScriptingNodeCreateMutation, Types.ScriptingNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ScriptingNodeCreateMutation, Types.ScriptingNodeCreateMutationVariables>(ScriptingNodeCreateDocument, options);
      }
export type ScriptingNodeCreateMutationHookResult = ReturnType<typeof useScriptingNodeCreateMutation>;
export type ScriptingNodeCreateMutationResult = Apollo.MutationResult<Types.ScriptingNodeCreateMutation>;
export type ScriptingNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.ScriptingNodeCreateMutation, Types.ScriptingNodeCreateMutationVariables>;
export const SqlNodeCreateDocument = gql`
    mutation SqlNodeCreate($input: CreateDraftSqlNodeInput!) {
  draftSqlNode {
    create(input: $input) {
      id
      name
      statement
      computationType
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
  }
}
    `;
export type SqlNodeCreateMutationFn = Apollo.MutationFunction<Types.SqlNodeCreateMutation, Types.SqlNodeCreateMutationVariables>;

/**
 * __useSqlNodeCreateMutation__
 *
 * To run a mutation, you first call `useSqlNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqlNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqlNodeCreateMutation, { data, loading, error }] = useSqlNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSqlNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqlNodeCreateMutation, Types.SqlNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqlNodeCreateMutation, Types.SqlNodeCreateMutationVariables>(SqlNodeCreateDocument, options);
      }
export type SqlNodeCreateMutationHookResult = ReturnType<typeof useSqlNodeCreateMutation>;
export type SqlNodeCreateMutationResult = Apollo.MutationResult<Types.SqlNodeCreateMutation>;
export type SqlNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.SqlNodeCreateMutation, Types.SqlNodeCreateMutationVariables>;
export const SqliteNodeCreateDocument = gql`
    mutation SqliteNodeCreate($input: CreateDraftSqliteNodeInput!) {
  draftSqliteNode {
    create(input: $input) {
      id
      name
      statement
      computationType
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
  }
}
    `;
export type SqliteNodeCreateMutationFn = Apollo.MutationFunction<Types.SqliteNodeCreateMutation, Types.SqliteNodeCreateMutationVariables>;

/**
 * __useSqliteNodeCreateMutation__
 *
 * To run a mutation, you first call `useSqliteNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqliteNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqliteNodeCreateMutation, { data, loading, error }] = useSqliteNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSqliteNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqliteNodeCreateMutation, Types.SqliteNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqliteNodeCreateMutation, Types.SqliteNodeCreateMutationVariables>(SqliteNodeCreateDocument, options);
      }
export type SqliteNodeCreateMutationHookResult = ReturnType<typeof useSqliteNodeCreateMutation>;
export type SqliteNodeCreateMutationResult = Apollo.MutationResult<Types.SqliteNodeCreateMutation>;
export type SqliteNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.SqliteNodeCreateMutation, Types.SqliteNodeCreateMutationVariables>;
export const SyntheticNodeCreateDocument = gql`
    mutation SyntheticNodeCreate($input: CreateDraftSyntheticNodeInput!) {
  draftSyntheticNode {
    create(input: $input) {
      id
      name
      computationType
    }
  }
}
    `;
export type SyntheticNodeCreateMutationFn = Apollo.MutationFunction<Types.SyntheticNodeCreateMutation, Types.SyntheticNodeCreateMutationVariables>;

/**
 * __useSyntheticNodeCreateMutation__
 *
 * To run a mutation, you first call `useSyntheticNodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyntheticNodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syntheticNodeCreateMutation, { data, loading, error }] = useSyntheticNodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyntheticNodeCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyntheticNodeCreateMutation, Types.SyntheticNodeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyntheticNodeCreateMutation, Types.SyntheticNodeCreateMutationVariables>(SyntheticNodeCreateDocument, options);
      }
export type SyntheticNodeCreateMutationHookResult = ReturnType<typeof useSyntheticNodeCreateMutation>;
export type SyntheticNodeCreateMutationResult = Apollo.MutationResult<Types.SyntheticNodeCreateMutation>;
export type SyntheticNodeCreateMutationOptions = Apollo.BaseMutationOptions<Types.SyntheticNodeCreateMutation, Types.SyntheticNodeCreateMutationVariables>;
export const DraftComputeNodeEditorDocument = gql`
    query DraftComputeNodeEditor($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
  }
}
    `;

/**
 * __useDraftComputeNodeEditorQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeEditorQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeEditorQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeEditorQuery, Types.DraftComputeNodeEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeEditorQuery, Types.DraftComputeNodeEditorQueryVariables>(DraftComputeNodeEditorDocument, options);
      }
export function useDraftComputeNodeEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeEditorQuery, Types.DraftComputeNodeEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeEditorQuery, Types.DraftComputeNodeEditorQueryVariables>(DraftComputeNodeEditorDocument, options);
        }
export type DraftComputeNodeEditorQueryHookResult = ReturnType<typeof useDraftComputeNodeEditorQuery>;
export type DraftComputeNodeEditorLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeEditorLazyQuery>;
export type DraftComputeNodeEditorQueryResult = Apollo.QueryResult<Types.DraftComputeNodeEditorQuery, Types.DraftComputeNodeEditorQueryVariables>;
export const PublishedComputeNodeEditorDocument = gql`
    query PublishedComputeNodeEditor($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
  }
}
    `;

/**
 * __usePublishedComputeNodeEditorQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeEditorQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeEditorQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeEditorQuery, Types.PublishedComputeNodeEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeEditorQuery, Types.PublishedComputeNodeEditorQueryVariables>(PublishedComputeNodeEditorDocument, options);
      }
export function usePublishedComputeNodeEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeEditorQuery, Types.PublishedComputeNodeEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeEditorQuery, Types.PublishedComputeNodeEditorQueryVariables>(PublishedComputeNodeEditorDocument, options);
        }
export type PublishedComputeNodeEditorQueryHookResult = ReturnType<typeof usePublishedComputeNodeEditorQuery>;
export type PublishedComputeNodeEditorLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeEditorLazyQuery>;
export type PublishedComputeNodeEditorQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeEditorQuery, Types.PublishedComputeNodeEditorQueryVariables>;
export const DraftMatchNodeAddDependencyDocument = gql`
    mutation DraftMatchNodeAddDependency($id: String!, $dependencyId: NodeId!) {
  draftMatchNode {
    addDependency(id: $id, dependencyId: $dependencyId) {
      id
      ... on DraftMatchNode {
        dependencies {
          nodes {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
            ... on PublishedNodeConnection {
              computeNodeId
            }
          }
        }
      }
    }
  }
}
    `;
export type DraftMatchNodeAddDependencyMutationFn = Apollo.MutationFunction<Types.DraftMatchNodeAddDependencyMutation, Types.DraftMatchNodeAddDependencyMutationVariables>;

/**
 * __useDraftMatchNodeAddDependencyMutation__
 *
 * To run a mutation, you first call `useDraftMatchNodeAddDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftMatchNodeAddDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftMatchNodeAddDependencyMutation, { data, loading, error }] = useDraftMatchNodeAddDependencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useDraftMatchNodeAddDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftMatchNodeAddDependencyMutation, Types.DraftMatchNodeAddDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftMatchNodeAddDependencyMutation, Types.DraftMatchNodeAddDependencyMutationVariables>(DraftMatchNodeAddDependencyDocument, options);
      }
export type DraftMatchNodeAddDependencyMutationHookResult = ReturnType<typeof useDraftMatchNodeAddDependencyMutation>;
export type DraftMatchNodeAddDependencyMutationResult = Apollo.MutationResult<Types.DraftMatchNodeAddDependencyMutation>;
export type DraftMatchNodeAddDependencyMutationOptions = Apollo.BaseMutationOptions<Types.DraftMatchNodeAddDependencyMutation, Types.DraftMatchNodeAddDependencyMutationVariables>;
export const DraftMatchNodeConfigDocument = gql`
    query DraftMatchNodeConfig($id: String!) {
  draftNode(id: $id) {
    id
    ... on DraftMatchNode {
      config
    }
  }
}
    `;

/**
 * __useDraftMatchNodeConfigQuery__
 *
 * To run a query within a React component, call `useDraftMatchNodeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftMatchNodeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftMatchNodeConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftMatchNodeConfigQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftMatchNodeConfigQuery, Types.DraftMatchNodeConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftMatchNodeConfigQuery, Types.DraftMatchNodeConfigQueryVariables>(DraftMatchNodeConfigDocument, options);
      }
export function useDraftMatchNodeConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftMatchNodeConfigQuery, Types.DraftMatchNodeConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftMatchNodeConfigQuery, Types.DraftMatchNodeConfigQueryVariables>(DraftMatchNodeConfigDocument, options);
        }
export type DraftMatchNodeConfigQueryHookResult = ReturnType<typeof useDraftMatchNodeConfigQuery>;
export type DraftMatchNodeConfigLazyQueryHookResult = ReturnType<typeof useDraftMatchNodeConfigLazyQuery>;
export type DraftMatchNodeConfigQueryResult = Apollo.QueryResult<Types.DraftMatchNodeConfigQuery, Types.DraftMatchNodeConfigQueryVariables>;
export const DraftMatchNodeDependenciesDocument = gql`
    query DraftMatchNodeDependencies($id: String!) {
  draftNode(id: $id) {
    id
    ... on DraftMatchNode {
      dependencies {
        nodes {
          ... on DraftNodeConnection {
            draftNode {
              id
              name
            }
          }
          ... on PublishedNodeConnection {
            computeNodeId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftMatchNodeDependenciesQuery__
 *
 * To run a query within a React component, call `useDraftMatchNodeDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftMatchNodeDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftMatchNodeDependenciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftMatchNodeDependenciesQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftMatchNodeDependenciesQuery, Types.DraftMatchNodeDependenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftMatchNodeDependenciesQuery, Types.DraftMatchNodeDependenciesQueryVariables>(DraftMatchNodeDependenciesDocument, options);
      }
export function useDraftMatchNodeDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftMatchNodeDependenciesQuery, Types.DraftMatchNodeDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftMatchNodeDependenciesQuery, Types.DraftMatchNodeDependenciesQueryVariables>(DraftMatchNodeDependenciesDocument, options);
        }
export type DraftMatchNodeDependenciesQueryHookResult = ReturnType<typeof useDraftMatchNodeDependenciesQuery>;
export type DraftMatchNodeDependenciesLazyQueryHookResult = ReturnType<typeof useDraftMatchNodeDependenciesLazyQuery>;
export type DraftMatchNodeDependenciesQueryResult = Apollo.QueryResult<Types.DraftMatchNodeDependenciesQuery, Types.DraftMatchNodeDependenciesQueryVariables>;
export const DraftMatchNodeRemoveDependencyDocument = gql`
    mutation DraftMatchNodeRemoveDependency($id: String!, $dependencyId: NodeId!) {
  draftMatchNode {
    removeDependency(id: $id, dependencyId: $dependencyId) {
      id
      ... on DraftMatchNode {
        dependencies {
          nodes {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
            ... on PublishedNodeConnection {
              computeNodeId
            }
          }
        }
      }
    }
  }
}
    `;
export type DraftMatchNodeRemoveDependencyMutationFn = Apollo.MutationFunction<Types.DraftMatchNodeRemoveDependencyMutation, Types.DraftMatchNodeRemoveDependencyMutationVariables>;

/**
 * __useDraftMatchNodeRemoveDependencyMutation__
 *
 * To run a mutation, you first call `useDraftMatchNodeRemoveDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftMatchNodeRemoveDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftMatchNodeRemoveDependencyMutation, { data, loading, error }] = useDraftMatchNodeRemoveDependencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useDraftMatchNodeRemoveDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftMatchNodeRemoveDependencyMutation, Types.DraftMatchNodeRemoveDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftMatchNodeRemoveDependencyMutation, Types.DraftMatchNodeRemoveDependencyMutationVariables>(DraftMatchNodeRemoveDependencyDocument, options);
      }
export type DraftMatchNodeRemoveDependencyMutationHookResult = ReturnType<typeof useDraftMatchNodeRemoveDependencyMutation>;
export type DraftMatchNodeRemoveDependencyMutationResult = Apollo.MutationResult<Types.DraftMatchNodeRemoveDependencyMutation>;
export type DraftMatchNodeRemoveDependencyMutationOptions = Apollo.BaseMutationOptions<Types.DraftMatchNodeRemoveDependencyMutation, Types.DraftMatchNodeRemoveDependencyMutationVariables>;
export const DraftMatchNodeUpdateConfigDocument = gql`
    mutation DraftMatchNodeUpdateConfig($id: String!, $config: JSON!) {
  draftMatchNode {
    update(input: {id: $id, config: $config}) {
      id
      config
    }
  }
}
    `;
export type DraftMatchNodeUpdateConfigMutationFn = Apollo.MutationFunction<Types.DraftMatchNodeUpdateConfigMutation, Types.DraftMatchNodeUpdateConfigMutationVariables>;

/**
 * __useDraftMatchNodeUpdateConfigMutation__
 *
 * To run a mutation, you first call `useDraftMatchNodeUpdateConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftMatchNodeUpdateConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftMatchNodeUpdateConfigMutation, { data, loading, error }] = useDraftMatchNodeUpdateConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useDraftMatchNodeUpdateConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftMatchNodeUpdateConfigMutation, Types.DraftMatchNodeUpdateConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftMatchNodeUpdateConfigMutation, Types.DraftMatchNodeUpdateConfigMutationVariables>(DraftMatchNodeUpdateConfigDocument, options);
      }
export type DraftMatchNodeUpdateConfigMutationHookResult = ReturnType<typeof useDraftMatchNodeUpdateConfigMutation>;
export type DraftMatchNodeUpdateConfigMutationResult = Apollo.MutationResult<Types.DraftMatchNodeUpdateConfigMutation>;
export type DraftMatchNodeUpdateConfigMutationOptions = Apollo.BaseMutationOptions<Types.DraftMatchNodeUpdateConfigMutation, Types.DraftMatchNodeUpdateConfigMutationVariables>;
export const PublishedMatchNodeConfigDocument = gql`
    query PublishedMatchNodeConfig($commitId: HexString, $dcrHash: HexString!, $driverAttestationHash: HexString!, $id: String!) {
  publishedNode(
    commitId: $commitId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    id: $id
  ) {
    id
    ... on PublishedMatchNode {
      config
    }
  }
}
    `;

/**
 * __usePublishedMatchNodeConfigQuery__
 *
 * To run a query within a React component, call `usePublishedMatchNodeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMatchNodeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMatchNodeConfigQuery({
 *   variables: {
 *      commitId: // value for 'commitId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMatchNodeConfigQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMatchNodeConfigQuery, Types.PublishedMatchNodeConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMatchNodeConfigQuery, Types.PublishedMatchNodeConfigQueryVariables>(PublishedMatchNodeConfigDocument, options);
      }
export function usePublishedMatchNodeConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMatchNodeConfigQuery, Types.PublishedMatchNodeConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMatchNodeConfigQuery, Types.PublishedMatchNodeConfigQueryVariables>(PublishedMatchNodeConfigDocument, options);
        }
export type PublishedMatchNodeConfigQueryHookResult = ReturnType<typeof usePublishedMatchNodeConfigQuery>;
export type PublishedMatchNodeConfigLazyQueryHookResult = ReturnType<typeof usePublishedMatchNodeConfigLazyQuery>;
export type PublishedMatchNodeConfigQueryResult = Apollo.QueryResult<Types.PublishedMatchNodeConfigQuery, Types.PublishedMatchNodeConfigQueryVariables>;
export const PublishedMatchNodeDependenciesDocument = gql`
    query PublishedMatchNodeDependencies($commitId: HexString, $dcrHash: HexString!, $driverAttestationHash: HexString!, $id: String!) {
  publishedNode(
    commitId: $commitId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    id: $id
  ) {
    id
    ... on PublishedMatchNode {
      dependencies {
        ... on PublishedNode {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedMatchNodeDependenciesQuery__
 *
 * To run a query within a React component, call `usePublishedMatchNodeDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMatchNodeDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMatchNodeDependenciesQuery({
 *   variables: {
 *      commitId: // value for 'commitId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMatchNodeDependenciesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMatchNodeDependenciesQuery, Types.PublishedMatchNodeDependenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMatchNodeDependenciesQuery, Types.PublishedMatchNodeDependenciesQueryVariables>(PublishedMatchNodeDependenciesDocument, options);
      }
export function usePublishedMatchNodeDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMatchNodeDependenciesQuery, Types.PublishedMatchNodeDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMatchNodeDependenciesQuery, Types.PublishedMatchNodeDependenciesQueryVariables>(PublishedMatchNodeDependenciesDocument, options);
        }
export type PublishedMatchNodeDependenciesQueryHookResult = ReturnType<typeof usePublishedMatchNodeDependenciesQuery>;
export type PublishedMatchNodeDependenciesLazyQueryHookResult = ReturnType<typeof usePublishedMatchNodeDependenciesLazyQuery>;
export type PublishedMatchNodeDependenciesQueryResult = Apollo.QueryResult<Types.PublishedMatchNodeDependenciesQuery, Types.PublishedMatchNodeDependenciesQueryVariables>;
export const DraftPostComputeNodeDocument = gql`
    query DraftPostComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftPostNode {
      id
      name
      computationType
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftPostComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftPostComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPostComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPostComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftPostComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftPostComputeNodeQuery, Types.DraftPostComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftPostComputeNodeQuery, Types.DraftPostComputeNodeQueryVariables>(DraftPostComputeNodeDocument, options);
      }
export function useDraftPostComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftPostComputeNodeQuery, Types.DraftPostComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftPostComputeNodeQuery, Types.DraftPostComputeNodeQueryVariables>(DraftPostComputeNodeDocument, options);
        }
export type DraftPostComputeNodeQueryHookResult = ReturnType<typeof useDraftPostComputeNodeQuery>;
export type DraftPostComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftPostComputeNodeLazyQuery>;
export type DraftPostComputeNodeQueryResult = Apollo.QueryResult<Types.DraftPostComputeNodeQuery, Types.DraftPostComputeNodeQueryVariables>;
export const PublishedPostComputeNodeDocument = gql`
    query PublishedPostComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedPostNode {
      id
      name
      computationType
      dependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedPostComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedPostComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedPostComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedPostComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedPostComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedPostComputeNodeQuery, Types.PublishedPostComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedPostComputeNodeQuery, Types.PublishedPostComputeNodeQueryVariables>(PublishedPostComputeNodeDocument, options);
      }
export function usePublishedPostComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedPostComputeNodeQuery, Types.PublishedPostComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedPostComputeNodeQuery, Types.PublishedPostComputeNodeQueryVariables>(PublishedPostComputeNodeDocument, options);
        }
export type PublishedPostComputeNodeQueryHookResult = ReturnType<typeof usePublishedPostComputeNodeQuery>;
export type PublishedPostComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedPostComputeNodeLazyQuery>;
export type PublishedPostComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedPostComputeNodeQuery, Types.PublishedPostComputeNodeQueryVariables>;
export const UpdatePostComputeNodeDependencyDocument = gql`
    mutation UpdatePostComputeNodeDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftPostNode {
    setDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;
export type UpdatePostComputeNodeDependencyMutationFn = Apollo.MutationFunction<Types.UpdatePostComputeNodeDependencyMutation, Types.UpdatePostComputeNodeDependencyMutationVariables>;

/**
 * __useUpdatePostComputeNodeDependencyMutation__
 *
 * To run a mutation, you first call `useUpdatePostComputeNodeDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostComputeNodeDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostComputeNodeDependencyMutation, { data, loading, error }] = useUpdatePostComputeNodeDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useUpdatePostComputeNodeDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePostComputeNodeDependencyMutation, Types.UpdatePostComputeNodeDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePostComputeNodeDependencyMutation, Types.UpdatePostComputeNodeDependencyMutationVariables>(UpdatePostComputeNodeDependencyDocument, options);
      }
export type UpdatePostComputeNodeDependencyMutationHookResult = ReturnType<typeof useUpdatePostComputeNodeDependencyMutation>;
export type UpdatePostComputeNodeDependencyMutationResult = Apollo.MutationResult<Types.UpdatePostComputeNodeDependencyMutation>;
export type UpdatePostComputeNodeDependencyMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePostComputeNodeDependencyMutation, Types.UpdatePostComputeNodeDependencyMutationVariables>;
export const DraftPreviewComputeNodeDocument = gql`
    query DraftPreviewComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftPreviewNode {
      id
      name
      quotaBytes
      computationType
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftPreviewComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftPreviewComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPreviewComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPreviewComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftPreviewComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftPreviewComputeNodeQuery, Types.DraftPreviewComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftPreviewComputeNodeQuery, Types.DraftPreviewComputeNodeQueryVariables>(DraftPreviewComputeNodeDocument, options);
      }
export function useDraftPreviewComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftPreviewComputeNodeQuery, Types.DraftPreviewComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftPreviewComputeNodeQuery, Types.DraftPreviewComputeNodeQueryVariables>(DraftPreviewComputeNodeDocument, options);
        }
export type DraftPreviewComputeNodeQueryHookResult = ReturnType<typeof useDraftPreviewComputeNodeQuery>;
export type DraftPreviewComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftPreviewComputeNodeLazyQuery>;
export type DraftPreviewComputeNodeQueryResult = Apollo.QueryResult<Types.DraftPreviewComputeNodeQuery, Types.DraftPreviewComputeNodeQueryVariables>;
export const PublishedPreviewComputeNodeDocument = gql`
    query PublishedPreviewComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedPreviewNode {
      id
      name
      usedQuotaBytes
      remainingQuotaBytes
      totalQuotaBytes
      computationType
      dependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedPreviewComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedPreviewComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedPreviewComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedPreviewComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedPreviewComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedPreviewComputeNodeQuery, Types.PublishedPreviewComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedPreviewComputeNodeQuery, Types.PublishedPreviewComputeNodeQueryVariables>(PublishedPreviewComputeNodeDocument, options);
      }
export function usePublishedPreviewComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedPreviewComputeNodeQuery, Types.PublishedPreviewComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedPreviewComputeNodeQuery, Types.PublishedPreviewComputeNodeQueryVariables>(PublishedPreviewComputeNodeDocument, options);
        }
export type PublishedPreviewComputeNodeQueryHookResult = ReturnType<typeof usePublishedPreviewComputeNodeQuery>;
export type PublishedPreviewComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedPreviewComputeNodeLazyQuery>;
export type PublishedPreviewComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedPreviewComputeNodeQuery, Types.PublishedPreviewComputeNodeQueryVariables>;
export const UpdatePreviewComputeNodeDependencyDocument = gql`
    mutation UpdatePreviewComputeNodeDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftPreviewNode {
    setDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;
export type UpdatePreviewComputeNodeDependencyMutationFn = Apollo.MutationFunction<Types.UpdatePreviewComputeNodeDependencyMutation, Types.UpdatePreviewComputeNodeDependencyMutationVariables>;

/**
 * __useUpdatePreviewComputeNodeDependencyMutation__
 *
 * To run a mutation, you first call `useUpdatePreviewComputeNodeDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreviewComputeNodeDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreviewComputeNodeDependencyMutation, { data, loading, error }] = useUpdatePreviewComputeNodeDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useUpdatePreviewComputeNodeDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePreviewComputeNodeDependencyMutation, Types.UpdatePreviewComputeNodeDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePreviewComputeNodeDependencyMutation, Types.UpdatePreviewComputeNodeDependencyMutationVariables>(UpdatePreviewComputeNodeDependencyDocument, options);
      }
export type UpdatePreviewComputeNodeDependencyMutationHookResult = ReturnType<typeof useUpdatePreviewComputeNodeDependencyMutation>;
export type UpdatePreviewComputeNodeDependencyMutationResult = Apollo.MutationResult<Types.UpdatePreviewComputeNodeDependencyMutation>;
export type UpdatePreviewComputeNodeDependencyMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePreviewComputeNodeDependencyMutation, Types.UpdatePreviewComputeNodeDependencyMutationVariables>;
export const UpdatePreviewComputeNodeQuotaDocument = gql`
    mutation UpdatePreviewComputeNodeQuota($computeNodeId: String!, $quotaBytes: SetUnsignedLong!) {
  draftPreviewNode {
    update(input: {id: $computeNodeId, quotaBytes: $quotaBytes}) {
      id
      quotaBytes
    }
  }
}
    `;
export type UpdatePreviewComputeNodeQuotaMutationFn = Apollo.MutationFunction<Types.UpdatePreviewComputeNodeQuotaMutation, Types.UpdatePreviewComputeNodeQuotaMutationVariables>;

/**
 * __useUpdatePreviewComputeNodeQuotaMutation__
 *
 * To run a mutation, you first call `useUpdatePreviewComputeNodeQuotaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreviewComputeNodeQuotaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreviewComputeNodeQuotaMutation, { data, loading, error }] = useUpdatePreviewComputeNodeQuotaMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      quotaBytes: // value for 'quotaBytes'
 *   },
 * });
 */
export function useUpdatePreviewComputeNodeQuotaMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePreviewComputeNodeQuotaMutation, Types.UpdatePreviewComputeNodeQuotaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePreviewComputeNodeQuotaMutation, Types.UpdatePreviewComputeNodeQuotaMutationVariables>(UpdatePreviewComputeNodeQuotaDocument, options);
      }
export type UpdatePreviewComputeNodeQuotaMutationHookResult = ReturnType<typeof useUpdatePreviewComputeNodeQuotaMutation>;
export type UpdatePreviewComputeNodeQuotaMutationResult = Apollo.MutationResult<Types.UpdatePreviewComputeNodeQuotaMutation>;
export type UpdatePreviewComputeNodeQuotaMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePreviewComputeNodeQuotaMutation, Types.UpdatePreviewComputeNodeQuotaMutationVariables>;
export const DraftS3ConnectionParamsDocument = gql`
    query DraftS3ConnectionParams($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftS3SinkNode {
      id
      name
      endpoint
      region
      credentialsDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftS3ConnectionParamsQuery__
 *
 * To run a query within a React component, call `useDraftS3ConnectionParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftS3ConnectionParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftS3ConnectionParamsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftS3ConnectionParamsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftS3ConnectionParamsQuery, Types.DraftS3ConnectionParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftS3ConnectionParamsQuery, Types.DraftS3ConnectionParamsQueryVariables>(DraftS3ConnectionParamsDocument, options);
      }
export function useDraftS3ConnectionParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftS3ConnectionParamsQuery, Types.DraftS3ConnectionParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftS3ConnectionParamsQuery, Types.DraftS3ConnectionParamsQueryVariables>(DraftS3ConnectionParamsDocument, options);
        }
export type DraftS3ConnectionParamsQueryHookResult = ReturnType<typeof useDraftS3ConnectionParamsQuery>;
export type DraftS3ConnectionParamsLazyQueryHookResult = ReturnType<typeof useDraftS3ConnectionParamsLazyQuery>;
export type DraftS3ConnectionParamsQueryResult = Apollo.QueryResult<Types.DraftS3ConnectionParamsQuery, Types.DraftS3ConnectionParamsQueryVariables>;
export const PublishedS3ConnectionParamsDocument = gql`
    query PublishedS3ConnectionParams($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedS3SinkNode {
      id
      name
      endpoint
      region
      credentialsDependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedS3ConnectionParamsQuery__
 *
 * To run a query within a React component, call `usePublishedS3ConnectionParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedS3ConnectionParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedS3ConnectionParamsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedS3ConnectionParamsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedS3ConnectionParamsQuery, Types.PublishedS3ConnectionParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedS3ConnectionParamsQuery, Types.PublishedS3ConnectionParamsQueryVariables>(PublishedS3ConnectionParamsDocument, options);
      }
export function usePublishedS3ConnectionParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedS3ConnectionParamsQuery, Types.PublishedS3ConnectionParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedS3ConnectionParamsQuery, Types.PublishedS3ConnectionParamsQueryVariables>(PublishedS3ConnectionParamsDocument, options);
        }
export type PublishedS3ConnectionParamsQueryHookResult = ReturnType<typeof usePublishedS3ConnectionParamsQuery>;
export type PublishedS3ConnectionParamsLazyQueryHookResult = ReturnType<typeof usePublishedS3ConnectionParamsLazyQuery>;
export type PublishedS3ConnectionParamsQueryResult = Apollo.QueryResult<Types.PublishedS3ConnectionParamsQuery, Types.PublishedS3ConnectionParamsQueryVariables>;
export const UpdateS3ConnectionParamsDocument = gql`
    mutation UpdateS3ConnectionParams($input: UpdateDraftS3SinkNodeInput!) {
  draftS3SinkNode {
    update(input: $input) {
      id
      name
      endpoint
      region
    }
  }
}
    `;
export type UpdateS3ConnectionParamsMutationFn = Apollo.MutationFunction<Types.UpdateS3ConnectionParamsMutation, Types.UpdateS3ConnectionParamsMutationVariables>;

/**
 * __useUpdateS3ConnectionParamsMutation__
 *
 * To run a mutation, you first call `useUpdateS3ConnectionParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateS3ConnectionParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateS3ConnectionParamsMutation, { data, loading, error }] = useUpdateS3ConnectionParamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateS3ConnectionParamsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateS3ConnectionParamsMutation, Types.UpdateS3ConnectionParamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateS3ConnectionParamsMutation, Types.UpdateS3ConnectionParamsMutationVariables>(UpdateS3ConnectionParamsDocument, options);
      }
export type UpdateS3ConnectionParamsMutationHookResult = ReturnType<typeof useUpdateS3ConnectionParamsMutation>;
export type UpdateS3ConnectionParamsMutationResult = Apollo.MutationResult<Types.UpdateS3ConnectionParamsMutation>;
export type UpdateS3ConnectionParamsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateS3ConnectionParamsMutation, Types.UpdateS3ConnectionParamsMutationVariables>;
export const UpdateS3CredentialsDependencyDocument = gql`
    mutation UpdateS3CredentialsDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftS3SinkNode {
    setCredentialsDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      credentialsDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;
export type UpdateS3CredentialsDependencyMutationFn = Apollo.MutationFunction<Types.UpdateS3CredentialsDependencyMutation, Types.UpdateS3CredentialsDependencyMutationVariables>;

/**
 * __useUpdateS3CredentialsDependencyMutation__
 *
 * To run a mutation, you first call `useUpdateS3CredentialsDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateS3CredentialsDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateS3CredentialsDependencyMutation, { data, loading, error }] = useUpdateS3CredentialsDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useUpdateS3CredentialsDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateS3CredentialsDependencyMutation, Types.UpdateS3CredentialsDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateS3CredentialsDependencyMutation, Types.UpdateS3CredentialsDependencyMutationVariables>(UpdateS3CredentialsDependencyDocument, options);
      }
export type UpdateS3CredentialsDependencyMutationHookResult = ReturnType<typeof useUpdateS3CredentialsDependencyMutation>;
export type UpdateS3CredentialsDependencyMutationResult = Apollo.MutationResult<Types.UpdateS3CredentialsDependencyMutation>;
export type UpdateS3CredentialsDependencyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateS3CredentialsDependencyMutation, Types.UpdateS3CredentialsDependencyMutationVariables>;
export const DraftS3ObjectUploadDependencyDocument = gql`
    query DraftS3ObjectUploadDependency($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftS3SinkNode {
      id
      uploadDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftS3ObjectUploadDependencyQuery__
 *
 * To run a query within a React component, call `useDraftS3ObjectUploadDependencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftS3ObjectUploadDependencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftS3ObjectUploadDependencyQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftS3ObjectUploadDependencyQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftS3ObjectUploadDependencyQuery, Types.DraftS3ObjectUploadDependencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftS3ObjectUploadDependencyQuery, Types.DraftS3ObjectUploadDependencyQueryVariables>(DraftS3ObjectUploadDependencyDocument, options);
      }
export function useDraftS3ObjectUploadDependencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftS3ObjectUploadDependencyQuery, Types.DraftS3ObjectUploadDependencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftS3ObjectUploadDependencyQuery, Types.DraftS3ObjectUploadDependencyQueryVariables>(DraftS3ObjectUploadDependencyDocument, options);
        }
export type DraftS3ObjectUploadDependencyQueryHookResult = ReturnType<typeof useDraftS3ObjectUploadDependencyQuery>;
export type DraftS3ObjectUploadDependencyLazyQueryHookResult = ReturnType<typeof useDraftS3ObjectUploadDependencyLazyQuery>;
export type DraftS3ObjectUploadDependencyQueryResult = Apollo.QueryResult<Types.DraftS3ObjectUploadDependencyQuery, Types.DraftS3ObjectUploadDependencyQueryVariables>;
export const PublishedS3ObjectUploadDependencyDocument = gql`
    query PublishedS3ObjectUploadDependency($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedS3SinkNode {
      id
      uploadDependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedS3ObjectUploadDependencyQuery__
 *
 * To run a query within a React component, call `usePublishedS3ObjectUploadDependencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedS3ObjectUploadDependencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedS3ObjectUploadDependencyQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedS3ObjectUploadDependencyQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedS3ObjectUploadDependencyQuery, Types.PublishedS3ObjectUploadDependencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedS3ObjectUploadDependencyQuery, Types.PublishedS3ObjectUploadDependencyQueryVariables>(PublishedS3ObjectUploadDependencyDocument, options);
      }
export function usePublishedS3ObjectUploadDependencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedS3ObjectUploadDependencyQuery, Types.PublishedS3ObjectUploadDependencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedS3ObjectUploadDependencyQuery, Types.PublishedS3ObjectUploadDependencyQueryVariables>(PublishedS3ObjectUploadDependencyDocument, options);
        }
export type PublishedS3ObjectUploadDependencyQueryHookResult = ReturnType<typeof usePublishedS3ObjectUploadDependencyQuery>;
export type PublishedS3ObjectUploadDependencyLazyQueryHookResult = ReturnType<typeof usePublishedS3ObjectUploadDependencyLazyQuery>;
export type PublishedS3ObjectUploadDependencyQueryResult = Apollo.QueryResult<Types.PublishedS3ObjectUploadDependencyQuery, Types.PublishedS3ObjectUploadDependencyQueryVariables>;
export const UpdateS3UploadDependencyDocument = gql`
    mutation UpdateS3UploadDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftS3SinkNode {
    setUploadDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      uploadDependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;
export type UpdateS3UploadDependencyMutationFn = Apollo.MutationFunction<Types.UpdateS3UploadDependencyMutation, Types.UpdateS3UploadDependencyMutationVariables>;

/**
 * __useUpdateS3UploadDependencyMutation__
 *
 * To run a mutation, you first call `useUpdateS3UploadDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateS3UploadDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateS3UploadDependencyMutation, { data, loading, error }] = useUpdateS3UploadDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useUpdateS3UploadDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateS3UploadDependencyMutation, Types.UpdateS3UploadDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateS3UploadDependencyMutation, Types.UpdateS3UploadDependencyMutationVariables>(UpdateS3UploadDependencyDocument, options);
      }
export type UpdateS3UploadDependencyMutationHookResult = ReturnType<typeof useUpdateS3UploadDependencyMutation>;
export type UpdateS3UploadDependencyMutationResult = Apollo.MutationResult<Types.UpdateS3UploadDependencyMutation>;
export type UpdateS3UploadDependencyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateS3UploadDependencyMutation, Types.UpdateS3UploadDependencyMutationVariables>;
export const CreateStaticScriptDocument = gql`
    mutation CreateStaticScript($input: CreateDraftScriptInput!) {
  draftScriptingNode {
    createScript(input: $input) {
      record {
        id
        name
        content
        isMainScript
      }
    }
  }
}
    `;
export type CreateStaticScriptMutationFn = Apollo.MutationFunction<Types.CreateStaticScriptMutation, Types.CreateStaticScriptMutationVariables>;

/**
 * __useCreateStaticScriptMutation__
 *
 * To run a mutation, you first call `useCreateStaticScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaticScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaticScriptMutation, { data, loading, error }] = useCreateStaticScriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaticScriptMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStaticScriptMutation, Types.CreateStaticScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStaticScriptMutation, Types.CreateStaticScriptMutationVariables>(CreateStaticScriptDocument, options);
      }
export type CreateStaticScriptMutationHookResult = ReturnType<typeof useCreateStaticScriptMutation>;
export type CreateStaticScriptMutationResult = Apollo.MutationResult<Types.CreateStaticScriptMutation>;
export type CreateStaticScriptMutationOptions = Apollo.BaseMutationOptions<Types.CreateStaticScriptMutation, Types.CreateStaticScriptMutationVariables>;
export const DeleteStaticScriptDocument = gql`
    mutation DeleteStaticScript($scriptId: String!) {
  draftScriptingNode {
    deleteScript(id: $scriptId) {
      id
      scripts {
        nodes {
          id
          name
          content
          isMainScript
        }
      }
    }
  }
}
    `;
export type DeleteStaticScriptMutationFn = Apollo.MutationFunction<Types.DeleteStaticScriptMutation, Types.DeleteStaticScriptMutationVariables>;

/**
 * __useDeleteStaticScriptMutation__
 *
 * To run a mutation, you first call `useDeleteStaticScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaticScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaticScriptMutation, { data, loading, error }] = useDeleteStaticScriptMutation({
 *   variables: {
 *      scriptId: // value for 'scriptId'
 *   },
 * });
 */
export function useDeleteStaticScriptMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStaticScriptMutation, Types.DeleteStaticScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStaticScriptMutation, Types.DeleteStaticScriptMutationVariables>(DeleteStaticScriptDocument, options);
      }
export type DeleteStaticScriptMutationHookResult = ReturnType<typeof useDeleteStaticScriptMutation>;
export type DeleteStaticScriptMutationResult = Apollo.MutationResult<Types.DeleteStaticScriptMutation>;
export type DeleteStaticScriptMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStaticScriptMutation, Types.DeleteStaticScriptMutationVariables>;
export const DraftScriptingComputeNodeDocument = gql`
    query DraftScriptingComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftScriptingNode {
      id
      name
      computationType
      scripts {
        nodes {
          id
          name
          content
          isMainScript
        }
      }
      dependencies {
        nodes {
          ... on DraftNodeConnection {
            draftNode {
              id
              name
            }
          }
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      output
      scriptingLanguage
    }
  }
}
    `;

/**
 * __useDraftScriptingComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftScriptingComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftScriptingComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftScriptingComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftScriptingComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftScriptingComputeNodeQuery, Types.DraftScriptingComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftScriptingComputeNodeQuery, Types.DraftScriptingComputeNodeQueryVariables>(DraftScriptingComputeNodeDocument, options);
      }
export function useDraftScriptingComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftScriptingComputeNodeQuery, Types.DraftScriptingComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftScriptingComputeNodeQuery, Types.DraftScriptingComputeNodeQueryVariables>(DraftScriptingComputeNodeDocument, options);
        }
export type DraftScriptingComputeNodeQueryHookResult = ReturnType<typeof useDraftScriptingComputeNodeQuery>;
export type DraftScriptingComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftScriptingComputeNodeLazyQuery>;
export type DraftScriptingComputeNodeQueryResult = Apollo.QueryResult<Types.DraftScriptingComputeNodeQuery, Types.DraftScriptingComputeNodeQueryVariables>;
export const PublishedScriptingComputeNodeDocument = gql`
    query PublishedScriptingComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedScriptingNode {
      id
      name
      computationType
      scripts {
        name
        content
        isMainScript
      }
      dependencies {
        id
        name
      }
      output
      scriptingLanguage
    }
  }
}
    `;

/**
 * __usePublishedScriptingComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedScriptingComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedScriptingComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedScriptingComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedScriptingComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedScriptingComputeNodeQuery, Types.PublishedScriptingComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedScriptingComputeNodeQuery, Types.PublishedScriptingComputeNodeQueryVariables>(PublishedScriptingComputeNodeDocument, options);
      }
export function usePublishedScriptingComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedScriptingComputeNodeQuery, Types.PublishedScriptingComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedScriptingComputeNodeQuery, Types.PublishedScriptingComputeNodeQueryVariables>(PublishedScriptingComputeNodeDocument, options);
        }
export type PublishedScriptingComputeNodeQueryHookResult = ReturnType<typeof usePublishedScriptingComputeNodeQuery>;
export type PublishedScriptingComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedScriptingComputeNodeLazyQuery>;
export type PublishedScriptingComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedScriptingComputeNodeQuery, Types.PublishedScriptingComputeNodeQueryVariables>;
export const ScriptingNodeInteractivityPublishedDependenciesDocument = gql`
    query ScriptingNodeInteractivityPublishedDependencies($id: String!) {
  publishedDataRoom(id: $id) {
    id
    publishedNodes @client {
      id
      name
    }
  }
}
    `;

/**
 * __useScriptingNodeInteractivityPublishedDependenciesQuery__
 *
 * To run a query within a React component, call `useScriptingNodeInteractivityPublishedDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptingNodeInteractivityPublishedDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptingNodeInteractivityPublishedDependenciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScriptingNodeInteractivityPublishedDependenciesQuery(baseOptions: Apollo.QueryHookOptions<Types.ScriptingNodeInteractivityPublishedDependenciesQuery, Types.ScriptingNodeInteractivityPublishedDependenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScriptingNodeInteractivityPublishedDependenciesQuery, Types.ScriptingNodeInteractivityPublishedDependenciesQueryVariables>(ScriptingNodeInteractivityPublishedDependenciesDocument, options);
      }
export function useScriptingNodeInteractivityPublishedDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScriptingNodeInteractivityPublishedDependenciesQuery, Types.ScriptingNodeInteractivityPublishedDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScriptingNodeInteractivityPublishedDependenciesQuery, Types.ScriptingNodeInteractivityPublishedDependenciesQueryVariables>(ScriptingNodeInteractivityPublishedDependenciesDocument, options);
        }
export type ScriptingNodeInteractivityPublishedDependenciesQueryHookResult = ReturnType<typeof useScriptingNodeInteractivityPublishedDependenciesQuery>;
export type ScriptingNodeInteractivityPublishedDependenciesLazyQueryHookResult = ReturnType<typeof useScriptingNodeInteractivityPublishedDependenciesLazyQuery>;
export type ScriptingNodeInteractivityPublishedDependenciesQueryResult = Apollo.QueryResult<Types.ScriptingNodeInteractivityPublishedDependenciesQuery, Types.ScriptingNodeInteractivityPublishedDependenciesQueryVariables>;
export const UpdateScriptContentDocument = gql`
    mutation UpdateScriptContent($input: UpdateDraftScriptInput!) {
  draftScriptingNode {
    updateScript(input: $input) {
      id
      content
    }
  }
}
    `;
export type UpdateScriptContentMutationFn = Apollo.MutationFunction<Types.UpdateScriptContentMutation, Types.UpdateScriptContentMutationVariables>;

/**
 * __useUpdateScriptContentMutation__
 *
 * To run a mutation, you first call `useUpdateScriptContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScriptContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScriptContentMutation, { data, loading, error }] = useUpdateScriptContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScriptContentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScriptContentMutation, Types.UpdateScriptContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScriptContentMutation, Types.UpdateScriptContentMutationVariables>(UpdateScriptContentDocument, options);
      }
export type UpdateScriptContentMutationHookResult = ReturnType<typeof useUpdateScriptContentMutation>;
export type UpdateScriptContentMutationResult = Apollo.MutationResult<Types.UpdateScriptContentMutation>;
export type UpdateScriptContentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScriptContentMutation, Types.UpdateScriptContentMutationVariables>;
export const UpdateStaticScriptNameDocument = gql`
    mutation UpdateStaticScriptName($input: UpdateDraftScriptInput!) {
  draftScriptingNode {
    updateScript(input: $input) {
      id
      name
    }
  }
}
    `;
export type UpdateStaticScriptNameMutationFn = Apollo.MutationFunction<Types.UpdateStaticScriptNameMutation, Types.UpdateStaticScriptNameMutationVariables>;

/**
 * __useUpdateStaticScriptNameMutation__
 *
 * To run a mutation, you first call `useUpdateStaticScriptNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticScriptNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticScriptNameMutation, { data, loading, error }] = useUpdateStaticScriptNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaticScriptNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStaticScriptNameMutation, Types.UpdateStaticScriptNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStaticScriptNameMutation, Types.UpdateStaticScriptNameMutationVariables>(UpdateStaticScriptNameDocument, options);
      }
export type UpdateStaticScriptNameMutationHookResult = ReturnType<typeof useUpdateStaticScriptNameMutation>;
export type UpdateStaticScriptNameMutationResult = Apollo.MutationResult<Types.UpdateStaticScriptNameMutation>;
export type UpdateStaticScriptNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStaticScriptNameMutation, Types.UpdateStaticScriptNameMutationVariables>;
export const AddScriptingNodeDependencyDocument = gql`
    mutation AddScriptingNodeDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftScriptingNode {
    addDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      dependencies {
        nodes {
          ... on DraftNodeConnection {
            draftNode {
              id
              name
            }
          }
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
    }
  }
}
    `;
export type AddScriptingNodeDependencyMutationFn = Apollo.MutationFunction<Types.AddScriptingNodeDependencyMutation, Types.AddScriptingNodeDependencyMutationVariables>;

/**
 * __useAddScriptingNodeDependencyMutation__
 *
 * To run a mutation, you first call `useAddScriptingNodeDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScriptingNodeDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScriptingNodeDependencyMutation, { data, loading, error }] = useAddScriptingNodeDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useAddScriptingNodeDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddScriptingNodeDependencyMutation, Types.AddScriptingNodeDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddScriptingNodeDependencyMutation, Types.AddScriptingNodeDependencyMutationVariables>(AddScriptingNodeDependencyDocument, options);
      }
export type AddScriptingNodeDependencyMutationHookResult = ReturnType<typeof useAddScriptingNodeDependencyMutation>;
export type AddScriptingNodeDependencyMutationResult = Apollo.MutationResult<Types.AddScriptingNodeDependencyMutation>;
export type AddScriptingNodeDependencyMutationOptions = Apollo.BaseMutationOptions<Types.AddScriptingNodeDependencyMutation, Types.AddScriptingNodeDependencyMutationVariables>;
export const RemoveScriptingNodeDependencyDocument = gql`
    mutation RemoveScriptingNodeDependency($computeNodeId: String!, $dependencyId: NodeId!) {
  draftScriptingNode {
    removeDependency(id: $computeNodeId, dependencyId: $dependencyId) {
      id
      dependencies {
        nodes {
          ... on DraftNodeConnection {
            draftNode {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type RemoveScriptingNodeDependencyMutationFn = Apollo.MutationFunction<Types.RemoveScriptingNodeDependencyMutation, Types.RemoveScriptingNodeDependencyMutationVariables>;

/**
 * __useRemoveScriptingNodeDependencyMutation__
 *
 * To run a mutation, you first call `useRemoveScriptingNodeDependencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScriptingNodeDependencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScriptingNodeDependencyMutation, { data, loading, error }] = useRemoveScriptingNodeDependencyMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *   },
 * });
 */
export function useRemoveScriptingNodeDependencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveScriptingNodeDependencyMutation, Types.RemoveScriptingNodeDependencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveScriptingNodeDependencyMutation, Types.RemoveScriptingNodeDependencyMutationVariables>(RemoveScriptingNodeDependencyDocument, options);
      }
export type RemoveScriptingNodeDependencyMutationHookResult = ReturnType<typeof useRemoveScriptingNodeDependencyMutation>;
export type RemoveScriptingNodeDependencyMutationResult = Apollo.MutationResult<Types.RemoveScriptingNodeDependencyMutation>;
export type RemoveScriptingNodeDependencyMutationOptions = Apollo.BaseMutationOptions<Types.RemoveScriptingNodeDependencyMutation, Types.RemoveScriptingNodeDependencyMutationVariables>;
export const UpdateScriptingNodeOutputFolderDocument = gql`
    mutation UpdateScriptingNodeOutputFolder($input: UpdateDraftScriptingNodeInput!) {
  draftScriptingNode {
    update(input: $input) {
      id
      output
    }
  }
}
    `;
export type UpdateScriptingNodeOutputFolderMutationFn = Apollo.MutationFunction<Types.UpdateScriptingNodeOutputFolderMutation, Types.UpdateScriptingNodeOutputFolderMutationVariables>;

/**
 * __useUpdateScriptingNodeOutputFolderMutation__
 *
 * To run a mutation, you first call `useUpdateScriptingNodeOutputFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScriptingNodeOutputFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScriptingNodeOutputFolderMutation, { data, loading, error }] = useUpdateScriptingNodeOutputFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScriptingNodeOutputFolderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScriptingNodeOutputFolderMutation, Types.UpdateScriptingNodeOutputFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScriptingNodeOutputFolderMutation, Types.UpdateScriptingNodeOutputFolderMutationVariables>(UpdateScriptingNodeOutputFolderDocument, options);
      }
export type UpdateScriptingNodeOutputFolderMutationHookResult = ReturnType<typeof useUpdateScriptingNodeOutputFolderMutation>;
export type UpdateScriptingNodeOutputFolderMutationResult = Apollo.MutationResult<Types.UpdateScriptingNodeOutputFolderMutation>;
export type UpdateScriptingNodeOutputFolderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScriptingNodeOutputFolderMutation, Types.UpdateScriptingNodeOutputFolderMutationVariables>;
export const DraftSqlComputeNodeDocument = gql`
    query DraftSqlComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSqlNode {
      id
      name
      computationType
      statement
    }
    ... on DraftSqliteNode {
      id
      name
      computationType
      statement
    }
  }
}
    `;

/**
 * __useDraftSqlComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftSqlComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSqlComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSqlComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSqlComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSqlComputeNodeQuery, Types.DraftSqlComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSqlComputeNodeQuery, Types.DraftSqlComputeNodeQueryVariables>(DraftSqlComputeNodeDocument, options);
      }
export function useDraftSqlComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSqlComputeNodeQuery, Types.DraftSqlComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSqlComputeNodeQuery, Types.DraftSqlComputeNodeQueryVariables>(DraftSqlComputeNodeDocument, options);
        }
export type DraftSqlComputeNodeQueryHookResult = ReturnType<typeof useDraftSqlComputeNodeQuery>;
export type DraftSqlComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftSqlComputeNodeLazyQuery>;
export type DraftSqlComputeNodeQueryResult = Apollo.QueryResult<Types.DraftSqlComputeNodeQuery, Types.DraftSqlComputeNodeQueryVariables>;
export const PublishedSqlComputeNodeDocument = gql`
    query PublishedSqlComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedSqlNode {
      id
      name
      computationType
      statement
    }
    ... on PublishedSqliteNode {
      id
      name
      computationType
      statement
    }
  }
}
    `;

/**
 * __usePublishedSqlComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedSqlComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSqlComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSqlComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSqlComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSqlComputeNodeQuery, Types.PublishedSqlComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSqlComputeNodeQuery, Types.PublishedSqlComputeNodeQueryVariables>(PublishedSqlComputeNodeDocument, options);
      }
export function usePublishedSqlComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSqlComputeNodeQuery, Types.PublishedSqlComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSqlComputeNodeQuery, Types.PublishedSqlComputeNodeQueryVariables>(PublishedSqlComputeNodeDocument, options);
        }
export type PublishedSqlComputeNodeQueryHookResult = ReturnType<typeof usePublishedSqlComputeNodeQuery>;
export type PublishedSqlComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedSqlComputeNodeLazyQuery>;
export type PublishedSqlComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedSqlComputeNodeQuery, Types.PublishedSqlComputeNodeQueryVariables>;
export const UpdateSqlComputeNodeDocument = gql`
    mutation UpdateSqlComputeNode($input: UpdateDraftSqlNodeInput!) {
  draftSqlNode {
    update(input: $input) {
      statement
      name
      id
    }
  }
}
    `;
export type UpdateSqlComputeNodeMutationFn = Apollo.MutationFunction<Types.UpdateSqlComputeNodeMutation, Types.UpdateSqlComputeNodeMutationVariables>;

/**
 * __useUpdateSqlComputeNodeMutation__
 *
 * To run a mutation, you first call `useUpdateSqlComputeNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSqlComputeNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSqlComputeNodeMutation, { data, loading, error }] = useUpdateSqlComputeNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSqlComputeNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSqlComputeNodeMutation, Types.UpdateSqlComputeNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSqlComputeNodeMutation, Types.UpdateSqlComputeNodeMutationVariables>(UpdateSqlComputeNodeDocument, options);
      }
export type UpdateSqlComputeNodeMutationHookResult = ReturnType<typeof useUpdateSqlComputeNodeMutation>;
export type UpdateSqlComputeNodeMutationResult = Apollo.MutationResult<Types.UpdateSqlComputeNodeMutation>;
export type UpdateSqlComputeNodeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSqlComputeNodeMutation, Types.UpdateSqlComputeNodeMutationVariables>;
export const UpdateSqliteComputeNodeDocument = gql`
    mutation UpdateSqliteComputeNode($input: UpdateDraftSqliteNodeInput!) {
  draftSqliteNode {
    update(input: $input) {
      statement
      name
      id
    }
  }
}
    `;
export type UpdateSqliteComputeNodeMutationFn = Apollo.MutationFunction<Types.UpdateSqliteComputeNodeMutation, Types.UpdateSqliteComputeNodeMutationVariables>;

/**
 * __useUpdateSqliteComputeNodeMutation__
 *
 * To run a mutation, you first call `useUpdateSqliteComputeNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSqliteComputeNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSqliteComputeNodeMutation, { data, loading, error }] = useUpdateSqliteComputeNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSqliteComputeNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSqliteComputeNodeMutation, Types.UpdateSqliteComputeNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSqliteComputeNodeMutation, Types.UpdateSqliteComputeNodeMutationVariables>(UpdateSqliteComputeNodeDocument, options);
      }
export type UpdateSqliteComputeNodeMutationHookResult = ReturnType<typeof useUpdateSqliteComputeNodeMutation>;
export type UpdateSqliteComputeNodeMutationResult = Apollo.MutationResult<Types.UpdateSqliteComputeNodeMutation>;
export type UpdateSqliteComputeNodeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSqliteComputeNodeMutation, Types.UpdateSqliteComputeNodeMutationVariables>;
export const DraftPrivacyFilterDocument = gql`
    query DraftPrivacyFilter($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSqlNode {
      id
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
    ... on DraftSqliteNode {
      id
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
  }
}
    `;

/**
 * __useDraftPrivacyFilterQuery__
 *
 * To run a query within a React component, call `useDraftPrivacyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPrivacyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPrivacyFilterQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftPrivacyFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftPrivacyFilterQuery, Types.DraftPrivacyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftPrivacyFilterQuery, Types.DraftPrivacyFilterQueryVariables>(DraftPrivacyFilterDocument, options);
      }
export function useDraftPrivacyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftPrivacyFilterQuery, Types.DraftPrivacyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftPrivacyFilterQuery, Types.DraftPrivacyFilterQueryVariables>(DraftPrivacyFilterDocument, options);
        }
export type DraftPrivacyFilterQueryHookResult = ReturnType<typeof useDraftPrivacyFilterQuery>;
export type DraftPrivacyFilterLazyQueryHookResult = ReturnType<typeof useDraftPrivacyFilterLazyQuery>;
export type DraftPrivacyFilterQueryResult = Apollo.QueryResult<Types.DraftPrivacyFilterQuery, Types.DraftPrivacyFilterQueryVariables>;
export const PublishedPrivacyFilterDocument = gql`
    query PublishedPrivacyFilter($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedSqlNode {
      id
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
  }
}
    `;

/**
 * __usePublishedPrivacyFilterQuery__
 *
 * To run a query within a React component, call `usePublishedPrivacyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedPrivacyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedPrivacyFilterQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedPrivacyFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedPrivacyFilterQuery, Types.PublishedPrivacyFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedPrivacyFilterQuery, Types.PublishedPrivacyFilterQueryVariables>(PublishedPrivacyFilterDocument, options);
      }
export function usePublishedPrivacyFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedPrivacyFilterQuery, Types.PublishedPrivacyFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedPrivacyFilterQuery, Types.PublishedPrivacyFilterQueryVariables>(PublishedPrivacyFilterDocument, options);
        }
export type PublishedPrivacyFilterQueryHookResult = ReturnType<typeof usePublishedPrivacyFilterQuery>;
export type PublishedPrivacyFilterLazyQueryHookResult = ReturnType<typeof usePublishedPrivacyFilterLazyQuery>;
export type PublishedPrivacyFilterQueryResult = Apollo.QueryResult<Types.PublishedPrivacyFilterQuery, Types.PublishedPrivacyFilterQueryVariables>;
export const SetPrivacyFilterDocument = gql`
    mutation SetPrivacyFilter($input: UpdateDraftSqlNodeInput!) {
  draftSqlNode {
    update(input: $input) {
      id
      privacyFilter {
        minimumRows
        isEnabled
      }
    }
  }
}
    `;
export type SetPrivacyFilterMutationFn = Apollo.MutationFunction<Types.SetPrivacyFilterMutation, Types.SetPrivacyFilterMutationVariables>;

/**
 * __useSetPrivacyFilterMutation__
 *
 * To run a mutation, you first call `useSetPrivacyFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrivacyFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrivacyFilterMutation, { data, loading, error }] = useSetPrivacyFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPrivacyFilterMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetPrivacyFilterMutation, Types.SetPrivacyFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetPrivacyFilterMutation, Types.SetPrivacyFilterMutationVariables>(SetPrivacyFilterDocument, options);
      }
export type SetPrivacyFilterMutationHookResult = ReturnType<typeof useSetPrivacyFilterMutation>;
export type SetPrivacyFilterMutationResult = Apollo.MutationResult<Types.SetPrivacyFilterMutation>;
export type SetPrivacyFilterMutationOptions = Apollo.BaseMutationOptions<Types.SetPrivacyFilterMutation, Types.SetPrivacyFilterMutationVariables>;
export const DraftSyntheticComputeNodeDocument = gql`
    query DraftSyntheticComputeNode($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSyntheticNode {
      id
      accuracy
      computationType
      includeReportWithStats
      columns {
        nodes {
          id
          index
          name
          maskType
          dataType
          shouldMaskColumn
        }
      }
      dependencyUpdatedAt
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftSyntheticComputeNodeQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSyntheticComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticComputeNodeQuery, Types.DraftSyntheticComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticComputeNodeQuery, Types.DraftSyntheticComputeNodeQueryVariables>(DraftSyntheticComputeNodeDocument, options);
      }
export function useDraftSyntheticComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticComputeNodeQuery, Types.DraftSyntheticComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticComputeNodeQuery, Types.DraftSyntheticComputeNodeQueryVariables>(DraftSyntheticComputeNodeDocument, options);
        }
export type DraftSyntheticComputeNodeQueryHookResult = ReturnType<typeof useDraftSyntheticComputeNodeQuery>;
export type DraftSyntheticComputeNodeLazyQueryHookResult = ReturnType<typeof useDraftSyntheticComputeNodeLazyQuery>;
export type DraftSyntheticComputeNodeQueryResult = Apollo.QueryResult<Types.DraftSyntheticComputeNodeQuery, Types.DraftSyntheticComputeNodeQueryVariables>;
export const PublishedSyntheticComputeNodeDocument = gql`
    query PublishedSyntheticComputeNode($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    ... on PublishedSyntheticNode {
      dependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedSyntheticComputeNodeQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticComputeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticComputeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticComputeNodeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticComputeNodeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticComputeNodeQuery, Types.PublishedSyntheticComputeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticComputeNodeQuery, Types.PublishedSyntheticComputeNodeQueryVariables>(PublishedSyntheticComputeNodeDocument, options);
      }
export function usePublishedSyntheticComputeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticComputeNodeQuery, Types.PublishedSyntheticComputeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticComputeNodeQuery, Types.PublishedSyntheticComputeNodeQueryVariables>(PublishedSyntheticComputeNodeDocument, options);
        }
export type PublishedSyntheticComputeNodeQueryHookResult = ReturnType<typeof usePublishedSyntheticComputeNodeQuery>;
export type PublishedSyntheticComputeNodeLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticComputeNodeLazyQuery>;
export type PublishedSyntheticComputeNodeQueryResult = Apollo.QueryResult<Types.PublishedSyntheticComputeNodeQuery, Types.PublishedSyntheticComputeNodeQueryVariables>;
export const DraftSyntheticNodeAccuracyDocument = gql`
    query DraftSyntheticNodeAccuracy($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSyntheticNode {
      id
      accuracy
    }
  }
}
    `;

/**
 * __useDraftSyntheticNodeAccuracyQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticNodeAccuracyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticNodeAccuracyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticNodeAccuracyQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSyntheticNodeAccuracyQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticNodeAccuracyQuery, Types.DraftSyntheticNodeAccuracyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticNodeAccuracyQuery, Types.DraftSyntheticNodeAccuracyQueryVariables>(DraftSyntheticNodeAccuracyDocument, options);
      }
export function useDraftSyntheticNodeAccuracyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticNodeAccuracyQuery, Types.DraftSyntheticNodeAccuracyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticNodeAccuracyQuery, Types.DraftSyntheticNodeAccuracyQueryVariables>(DraftSyntheticNodeAccuracyDocument, options);
        }
export type DraftSyntheticNodeAccuracyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeAccuracyQuery>;
export type DraftSyntheticNodeAccuracyLazyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeAccuracyLazyQuery>;
export type DraftSyntheticNodeAccuracyQueryResult = Apollo.QueryResult<Types.DraftSyntheticNodeAccuracyQuery, Types.DraftSyntheticNodeAccuracyQueryVariables>;
export const PublishedSyntheticNodeAccuracyDocument = gql`
    query PublishedSyntheticNodeAccuracy($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedSyntheticNode {
      id
      accuracy
    }
  }
}
    `;

/**
 * __usePublishedSyntheticNodeAccuracyQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticNodeAccuracyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticNodeAccuracyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticNodeAccuracyQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticNodeAccuracyQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticNodeAccuracyQuery, Types.PublishedSyntheticNodeAccuracyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticNodeAccuracyQuery, Types.PublishedSyntheticNodeAccuracyQueryVariables>(PublishedSyntheticNodeAccuracyDocument, options);
      }
export function usePublishedSyntheticNodeAccuracyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticNodeAccuracyQuery, Types.PublishedSyntheticNodeAccuracyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticNodeAccuracyQuery, Types.PublishedSyntheticNodeAccuracyQueryVariables>(PublishedSyntheticNodeAccuracyDocument, options);
        }
export type PublishedSyntheticNodeAccuracyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeAccuracyQuery>;
export type PublishedSyntheticNodeAccuracyLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeAccuracyLazyQuery>;
export type PublishedSyntheticNodeAccuracyQueryResult = Apollo.QueryResult<Types.PublishedSyntheticNodeAccuracyQuery, Types.PublishedSyntheticNodeAccuracyQueryVariables>;
export const UpdateSyntheticNodeAccuracyDocument = gql`
    mutation UpdateSyntheticNodeAccuracy($input: UpdateDraftSyntheticNodeInput!) {
  draftSyntheticNode {
    update(input: $input) {
      id
      accuracy
    }
  }
}
    `;
export type UpdateSyntheticNodeAccuracyMutationFn = Apollo.MutationFunction<Types.UpdateSyntheticNodeAccuracyMutation, Types.UpdateSyntheticNodeAccuracyMutationVariables>;

/**
 * __useUpdateSyntheticNodeAccuracyMutation__
 *
 * To run a mutation, you first call `useUpdateSyntheticNodeAccuracyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSyntheticNodeAccuracyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSyntheticNodeAccuracyMutation, { data, loading, error }] = useUpdateSyntheticNodeAccuracyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSyntheticNodeAccuracyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSyntheticNodeAccuracyMutation, Types.UpdateSyntheticNodeAccuracyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSyntheticNodeAccuracyMutation, Types.UpdateSyntheticNodeAccuracyMutationVariables>(UpdateSyntheticNodeAccuracyDocument, options);
      }
export type UpdateSyntheticNodeAccuracyMutationHookResult = ReturnType<typeof useUpdateSyntheticNodeAccuracyMutation>;
export type UpdateSyntheticNodeAccuracyMutationResult = Apollo.MutationResult<Types.UpdateSyntheticNodeAccuracyMutation>;
export type UpdateSyntheticNodeAccuracyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSyntheticNodeAccuracyMutation, Types.UpdateSyntheticNodeAccuracyMutationVariables>;
export const DraftSyntheticNodeDataSourceDocument = gql`
    query DraftSyntheticNodeDataSource($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSyntheticNode {
      id
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftSyntheticNodeDataSourceQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticNodeDataSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticNodeDataSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticNodeDataSourceQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSyntheticNodeDataSourceQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticNodeDataSourceQuery, Types.DraftSyntheticNodeDataSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticNodeDataSourceQuery, Types.DraftSyntheticNodeDataSourceQueryVariables>(DraftSyntheticNodeDataSourceDocument, options);
      }
export function useDraftSyntheticNodeDataSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticNodeDataSourceQuery, Types.DraftSyntheticNodeDataSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticNodeDataSourceQuery, Types.DraftSyntheticNodeDataSourceQueryVariables>(DraftSyntheticNodeDataSourceDocument, options);
        }
export type DraftSyntheticNodeDataSourceQueryHookResult = ReturnType<typeof useDraftSyntheticNodeDataSourceQuery>;
export type DraftSyntheticNodeDataSourceLazyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeDataSourceLazyQuery>;
export type DraftSyntheticNodeDataSourceQueryResult = Apollo.QueryResult<Types.DraftSyntheticNodeDataSourceQuery, Types.DraftSyntheticNodeDataSourceQueryVariables>;
export const PublishedSyntheticNodeDataSourceDocument = gql`
    query PublishedSyntheticNodeDataSource($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedSyntheticNode {
      id
      dependency {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePublishedSyntheticNodeDataSourceQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticNodeDataSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticNodeDataSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticNodeDataSourceQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticNodeDataSourceQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticNodeDataSourceQuery, Types.PublishedSyntheticNodeDataSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticNodeDataSourceQuery, Types.PublishedSyntheticNodeDataSourceQueryVariables>(PublishedSyntheticNodeDataSourceDocument, options);
      }
export function usePublishedSyntheticNodeDataSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticNodeDataSourceQuery, Types.PublishedSyntheticNodeDataSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticNodeDataSourceQuery, Types.PublishedSyntheticNodeDataSourceQueryVariables>(PublishedSyntheticNodeDataSourceDocument, options);
        }
export type PublishedSyntheticNodeDataSourceQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeDataSourceQuery>;
export type PublishedSyntheticNodeDataSourceLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeDataSourceLazyQuery>;
export type PublishedSyntheticNodeDataSourceQueryResult = Apollo.QueryResult<Types.PublishedSyntheticNodeDataSourceQuery, Types.PublishedSyntheticNodeDataSourceQueryVariables>;
export const SyntheticNodeInteractivityPublishedDependenciesDocument = gql`
    query SyntheticNodeInteractivityPublishedDependencies($id: String!) {
  publishedDataRoom(id: $id) {
    id
    publishedNodes @client {
      id
      name
      ... on PublishedTableLeafNode {
        isRequired
        columns {
          name
          dataType
          isNullable
          formatType
          hashWith
        }
      }
    }
  }
}
    `;

/**
 * __useSyntheticNodeInteractivityPublishedDependenciesQuery__
 *
 * To run a query within a React component, call `useSyntheticNodeInteractivityPublishedDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyntheticNodeInteractivityPublishedDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyntheticNodeInteractivityPublishedDependenciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyntheticNodeInteractivityPublishedDependenciesQuery(baseOptions: Apollo.QueryHookOptions<Types.SyntheticNodeInteractivityPublishedDependenciesQuery, Types.SyntheticNodeInteractivityPublishedDependenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SyntheticNodeInteractivityPublishedDependenciesQuery, Types.SyntheticNodeInteractivityPublishedDependenciesQueryVariables>(SyntheticNodeInteractivityPublishedDependenciesDocument, options);
      }
export function useSyntheticNodeInteractivityPublishedDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SyntheticNodeInteractivityPublishedDependenciesQuery, Types.SyntheticNodeInteractivityPublishedDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SyntheticNodeInteractivityPublishedDependenciesQuery, Types.SyntheticNodeInteractivityPublishedDependenciesQueryVariables>(SyntheticNodeInteractivityPublishedDependenciesDocument, options);
        }
export type SyntheticNodeInteractivityPublishedDependenciesQueryHookResult = ReturnType<typeof useSyntheticNodeInteractivityPublishedDependenciesQuery>;
export type SyntheticNodeInteractivityPublishedDependenciesLazyQueryHookResult = ReturnType<typeof useSyntheticNodeInteractivityPublishedDependenciesLazyQuery>;
export type SyntheticNodeInteractivityPublishedDependenciesQueryResult = Apollo.QueryResult<Types.SyntheticNodeInteractivityPublishedDependenciesQuery, Types.SyntheticNodeInteractivityPublishedDependenciesQueryVariables>;
export const UpdateSyntheticNodeDataSourceDocument = gql`
    mutation UpdateSyntheticNodeDataSource($computeNodeId: String!, $dependencyId: NodeId!, $dependencyLastUpdatedAt: DateTime, $syntheticNodeColumns: [CreateSyntheticNodeColumnForSyntheticNodeInput!]) {
  draftSyntheticNode {
    setDependency(
      id: $computeNodeId
      dependencyId: $dependencyId
      dependencyLastUpdatedAt: $dependencyLastUpdatedAt
      syntheticNodeColumns: $syntheticNodeColumns
    ) {
      id
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
            ... on DraftTableLeafNode {
              columns {
                nodes {
                  id
                  name
                  dataType
                  isNullable
                }
              }
            }
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
      columns {
        nodes {
          id
          index
          name
          maskType
          dataType
          shouldMaskColumn
        }
      }
    }
  }
}
    `;
export type UpdateSyntheticNodeDataSourceMutationFn = Apollo.MutationFunction<Types.UpdateSyntheticNodeDataSourceMutation, Types.UpdateSyntheticNodeDataSourceMutationVariables>;

/**
 * __useUpdateSyntheticNodeDataSourceMutation__
 *
 * To run a mutation, you first call `useUpdateSyntheticNodeDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSyntheticNodeDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSyntheticNodeDataSourceMutation, { data, loading, error }] = useUpdateSyntheticNodeDataSourceMutation({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dependencyId: // value for 'dependencyId'
 *      dependencyLastUpdatedAt: // value for 'dependencyLastUpdatedAt'
 *      syntheticNodeColumns: // value for 'syntheticNodeColumns'
 *   },
 * });
 */
export function useUpdateSyntheticNodeDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSyntheticNodeDataSourceMutation, Types.UpdateSyntheticNodeDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSyntheticNodeDataSourceMutation, Types.UpdateSyntheticNodeDataSourceMutationVariables>(UpdateSyntheticNodeDataSourceDocument, options);
      }
export type UpdateSyntheticNodeDataSourceMutationHookResult = ReturnType<typeof useUpdateSyntheticNodeDataSourceMutation>;
export type UpdateSyntheticNodeDataSourceMutationResult = Apollo.MutationResult<Types.UpdateSyntheticNodeDataSourceMutation>;
export type UpdateSyntheticNodeDataSourceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSyntheticNodeDataSourceMutation, Types.UpdateSyntheticNodeDataSourceMutationVariables>;
export const DraftSyntheticNodeIncludeStatisticsDocument = gql`
    query DraftSyntheticNodeIncludeStatistics($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSyntheticNode {
      id
      includeReportWithStats
    }
  }
}
    `;

/**
 * __useDraftSyntheticNodeIncludeStatisticsQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticNodeIncludeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticNodeIncludeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticNodeIncludeStatisticsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSyntheticNodeIncludeStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticNodeIncludeStatisticsQuery, Types.DraftSyntheticNodeIncludeStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticNodeIncludeStatisticsQuery, Types.DraftSyntheticNodeIncludeStatisticsQueryVariables>(DraftSyntheticNodeIncludeStatisticsDocument, options);
      }
export function useDraftSyntheticNodeIncludeStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticNodeIncludeStatisticsQuery, Types.DraftSyntheticNodeIncludeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticNodeIncludeStatisticsQuery, Types.DraftSyntheticNodeIncludeStatisticsQueryVariables>(DraftSyntheticNodeIncludeStatisticsDocument, options);
        }
export type DraftSyntheticNodeIncludeStatisticsQueryHookResult = ReturnType<typeof useDraftSyntheticNodeIncludeStatisticsQuery>;
export type DraftSyntheticNodeIncludeStatisticsLazyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeIncludeStatisticsLazyQuery>;
export type DraftSyntheticNodeIncludeStatisticsQueryResult = Apollo.QueryResult<Types.DraftSyntheticNodeIncludeStatisticsQuery, Types.DraftSyntheticNodeIncludeStatisticsQueryVariables>;
export const PublishedSyntheticNodeIncludeStatisticsDocument = gql`
    query PublishedSyntheticNodeIncludeStatistics($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    ... on PublishedSyntheticNode {
      id
      includeReportWithStats
    }
  }
}
    `;

/**
 * __usePublishedSyntheticNodeIncludeStatisticsQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticNodeIncludeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticNodeIncludeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticNodeIncludeStatisticsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticNodeIncludeStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticNodeIncludeStatisticsQuery, Types.PublishedSyntheticNodeIncludeStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticNodeIncludeStatisticsQuery, Types.PublishedSyntheticNodeIncludeStatisticsQueryVariables>(PublishedSyntheticNodeIncludeStatisticsDocument, options);
      }
export function usePublishedSyntheticNodeIncludeStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticNodeIncludeStatisticsQuery, Types.PublishedSyntheticNodeIncludeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticNodeIncludeStatisticsQuery, Types.PublishedSyntheticNodeIncludeStatisticsQueryVariables>(PublishedSyntheticNodeIncludeStatisticsDocument, options);
        }
export type PublishedSyntheticNodeIncludeStatisticsQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeIncludeStatisticsQuery>;
export type PublishedSyntheticNodeIncludeStatisticsLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeIncludeStatisticsLazyQuery>;
export type PublishedSyntheticNodeIncludeStatisticsQueryResult = Apollo.QueryResult<Types.PublishedSyntheticNodeIncludeStatisticsQuery, Types.PublishedSyntheticNodeIncludeStatisticsQueryVariables>;
export const SetSyntheticNodeIncludeStatisticsDocument = gql`
    mutation SetSyntheticNodeIncludeStatistics($input: UpdateDraftSyntheticNodeInput!) {
  draftSyntheticNode {
    update(input: $input) {
      id
      includeReportWithStats
    }
  }
}
    `;
export type SetSyntheticNodeIncludeStatisticsMutationFn = Apollo.MutationFunction<Types.SetSyntheticNodeIncludeStatisticsMutation, Types.SetSyntheticNodeIncludeStatisticsMutationVariables>;

/**
 * __useSetSyntheticNodeIncludeStatisticsMutation__
 *
 * To run a mutation, you first call `useSetSyntheticNodeIncludeStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSyntheticNodeIncludeStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSyntheticNodeIncludeStatisticsMutation, { data, loading, error }] = useSetSyntheticNodeIncludeStatisticsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSyntheticNodeIncludeStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSyntheticNodeIncludeStatisticsMutation, Types.SetSyntheticNodeIncludeStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSyntheticNodeIncludeStatisticsMutation, Types.SetSyntheticNodeIncludeStatisticsMutationVariables>(SetSyntheticNodeIncludeStatisticsDocument, options);
      }
export type SetSyntheticNodeIncludeStatisticsMutationHookResult = ReturnType<typeof useSetSyntheticNodeIncludeStatisticsMutation>;
export type SetSyntheticNodeIncludeStatisticsMutationResult = Apollo.MutationResult<Types.SetSyntheticNodeIncludeStatisticsMutation>;
export type SetSyntheticNodeIncludeStatisticsMutationOptions = Apollo.BaseMutationOptions<Types.SetSyntheticNodeIncludeStatisticsMutation, Types.SetSyntheticNodeIncludeStatisticsMutationVariables>;
export const AddSyntheticNodeColumnDocument = gql`
    mutation AddSyntheticNodeColumn($id: String!, $column: CreateSyntheticNodeColumnForSyntheticNodeInput!) {
  draftSyntheticNode {
    insertSyntheticNodeColumn(
      draftSyntheticNodeId: $id
      syntheticNodeColumn: $column
    ) {
      id
      columns {
        nodes {
          id
          index
          name
          maskType
          dataType
          shouldMaskColumn
        }
      }
    }
  }
}
    `;
export type AddSyntheticNodeColumnMutationFn = Apollo.MutationFunction<Types.AddSyntheticNodeColumnMutation, Types.AddSyntheticNodeColumnMutationVariables>;

/**
 * __useAddSyntheticNodeColumnMutation__
 *
 * To run a mutation, you first call `useAddSyntheticNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSyntheticNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSyntheticNodeColumnMutation, { data, loading, error }] = useAddSyntheticNodeColumnMutation({
 *   variables: {
 *      id: // value for 'id'
 *      column: // value for 'column'
 *   },
 * });
 */
export function useAddSyntheticNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSyntheticNodeColumnMutation, Types.AddSyntheticNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSyntheticNodeColumnMutation, Types.AddSyntheticNodeColumnMutationVariables>(AddSyntheticNodeColumnDocument, options);
      }
export type AddSyntheticNodeColumnMutationHookResult = ReturnType<typeof useAddSyntheticNodeColumnMutation>;
export type AddSyntheticNodeColumnMutationResult = Apollo.MutationResult<Types.AddSyntheticNodeColumnMutation>;
export type AddSyntheticNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.AddSyntheticNodeColumnMutation, Types.AddSyntheticNodeColumnMutationVariables>;
export const DeleteSyntheticNodeColumnDocument = gql`
    mutation DeleteSyntheticNodeColumn($id: String!) {
  draftSyntheticNode {
    deleteSyntheticNodeColumn(syntheticNodeColumnId: $id)
  }
}
    `;
export type DeleteSyntheticNodeColumnMutationFn = Apollo.MutationFunction<Types.DeleteSyntheticNodeColumnMutation, Types.DeleteSyntheticNodeColumnMutationVariables>;

/**
 * __useDeleteSyntheticNodeColumnMutation__
 *
 * To run a mutation, you first call `useDeleteSyntheticNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSyntheticNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSyntheticNodeColumnMutation, { data, loading, error }] = useDeleteSyntheticNodeColumnMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSyntheticNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSyntheticNodeColumnMutation, Types.DeleteSyntheticNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSyntheticNodeColumnMutation, Types.DeleteSyntheticNodeColumnMutationVariables>(DeleteSyntheticNodeColumnDocument, options);
      }
export type DeleteSyntheticNodeColumnMutationHookResult = ReturnType<typeof useDeleteSyntheticNodeColumnMutation>;
export type DeleteSyntheticNodeColumnMutationResult = Apollo.MutationResult<Types.DeleteSyntheticNodeColumnMutation>;
export type DeleteSyntheticNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSyntheticNodeColumnMutation, Types.DeleteSyntheticNodeColumnMutationVariables>;
export const DraftSyntheticNodeDependencyDocument = gql`
    query DraftSyntheticNodeDependency($id: String!) {
  draftNode(id: $id) {
    id
    name
    updatedAt
    ... on DraftTableLeafNode {
      columns {
        nodes {
          id
          name
          dataType
          isNullable
        }
      }
      columnsOrder
    }
  }
}
    `;

/**
 * __useDraftSyntheticNodeDependencyQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticNodeDependencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticNodeDependencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticNodeDependencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftSyntheticNodeDependencyQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticNodeDependencyQuery, Types.DraftSyntheticNodeDependencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticNodeDependencyQuery, Types.DraftSyntheticNodeDependencyQueryVariables>(DraftSyntheticNodeDependencyDocument, options);
      }
export function useDraftSyntheticNodeDependencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticNodeDependencyQuery, Types.DraftSyntheticNodeDependencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticNodeDependencyQuery, Types.DraftSyntheticNodeDependencyQueryVariables>(DraftSyntheticNodeDependencyDocument, options);
        }
export type DraftSyntheticNodeDependencyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeDependencyQuery>;
export type DraftSyntheticNodeDependencyLazyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeDependencyLazyQuery>;
export type DraftSyntheticNodeDependencyQueryResult = Apollo.QueryResult<Types.DraftSyntheticNodeDependencyQuery, Types.DraftSyntheticNodeDependencyQueryVariables>;
export const DraftSyntheticNodeMaskingDocument = gql`
    query DraftSyntheticNodeMasking($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    ... on DraftSyntheticNode {
      id
      columns {
        nodes {
          id
          index
          name
          maskType
          dataType
          shouldMaskColumn
        }
      }
      dependencyUpdatedAt
      dependency {
        ... on DraftNodeConnection {
          draftNode {
            id
            name
          }
        }
        ... on PublishedNodeConnection {
          computeNodeId
          publishedDataRoomId
        }
      }
    }
  }
}
    `;

/**
 * __useDraftSyntheticNodeMaskingQuery__
 *
 * To run a query within a React component, call `useDraftSyntheticNodeMaskingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSyntheticNodeMaskingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSyntheticNodeMaskingQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftSyntheticNodeMaskingQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftSyntheticNodeMaskingQuery, Types.DraftSyntheticNodeMaskingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftSyntheticNodeMaskingQuery, Types.DraftSyntheticNodeMaskingQueryVariables>(DraftSyntheticNodeMaskingDocument, options);
      }
export function useDraftSyntheticNodeMaskingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftSyntheticNodeMaskingQuery, Types.DraftSyntheticNodeMaskingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftSyntheticNodeMaskingQuery, Types.DraftSyntheticNodeMaskingQueryVariables>(DraftSyntheticNodeMaskingDocument, options);
        }
export type DraftSyntheticNodeMaskingQueryHookResult = ReturnType<typeof useDraftSyntheticNodeMaskingQuery>;
export type DraftSyntheticNodeMaskingLazyQueryHookResult = ReturnType<typeof useDraftSyntheticNodeMaskingLazyQuery>;
export type DraftSyntheticNodeMaskingQueryResult = Apollo.QueryResult<Types.DraftSyntheticNodeMaskingQuery, Types.DraftSyntheticNodeMaskingQueryVariables>;
export const PublishedSyntheticNodeDependencyDocument = gql`
    query PublishedSyntheticNodeDependency($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    ... on PublishedTableLeafNode {
      columns {
        name
        dataType
        isNullable
        formatType
        hashWith
      }
    }
  }
}
    `;

/**
 * __usePublishedSyntheticNodeDependencyQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticNodeDependencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticNodeDependencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticNodeDependencyQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticNodeDependencyQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticNodeDependencyQuery, Types.PublishedSyntheticNodeDependencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticNodeDependencyQuery, Types.PublishedSyntheticNodeDependencyQueryVariables>(PublishedSyntheticNodeDependencyDocument, options);
      }
export function usePublishedSyntheticNodeDependencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticNodeDependencyQuery, Types.PublishedSyntheticNodeDependencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticNodeDependencyQuery, Types.PublishedSyntheticNodeDependencyQueryVariables>(PublishedSyntheticNodeDependencyDocument, options);
        }
export type PublishedSyntheticNodeDependencyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeDependencyQuery>;
export type PublishedSyntheticNodeDependencyLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeDependencyLazyQuery>;
export type PublishedSyntheticNodeDependencyQueryResult = Apollo.QueryResult<Types.PublishedSyntheticNodeDependencyQuery, Types.PublishedSyntheticNodeDependencyQueryVariables>;
export const PublishedSyntheticNodeMaskingDocument = gql`
    query PublishedSyntheticNodeMasking($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    ... on PublishedSyntheticNode {
      columns {
        name
        shouldMaskColumn
        index
        maskType
        dataType
      }
      dependency {
        id
      }
    }
  }
}
    `;

/**
 * __usePublishedSyntheticNodeMaskingQuery__
 *
 * To run a query within a React component, call `usePublishedSyntheticNodeMaskingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSyntheticNodeMaskingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSyntheticNodeMaskingQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedSyntheticNodeMaskingQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedSyntheticNodeMaskingQuery, Types.PublishedSyntheticNodeMaskingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedSyntheticNodeMaskingQuery, Types.PublishedSyntheticNodeMaskingQueryVariables>(PublishedSyntheticNodeMaskingDocument, options);
      }
export function usePublishedSyntheticNodeMaskingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedSyntheticNodeMaskingQuery, Types.PublishedSyntheticNodeMaskingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedSyntheticNodeMaskingQuery, Types.PublishedSyntheticNodeMaskingQueryVariables>(PublishedSyntheticNodeMaskingDocument, options);
        }
export type PublishedSyntheticNodeMaskingQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeMaskingQuery>;
export type PublishedSyntheticNodeMaskingLazyQueryHookResult = ReturnType<typeof usePublishedSyntheticNodeMaskingLazyQuery>;
export type PublishedSyntheticNodeMaskingQueryResult = Apollo.QueryResult<Types.PublishedSyntheticNodeMaskingQuery, Types.PublishedSyntheticNodeMaskingQueryVariables>;
export const SetSyntheticNodeColumnsDocument = gql`
    mutation SetSyntheticNodeColumns($id: String!, $columns: [CreateSyntheticNodeColumnForSyntheticNodeInput!]!) {
  draftSyntheticNode {
    setSyntheticNodeColumns(
      draftSyntheticNodeId: $id
      syntheticNodeColumns: $columns
    ) {
      id
      columns {
        nodes {
          id
          index
          name
          maskType
          dataType
          shouldMaskColumn
        }
      }
    }
  }
}
    `;
export type SetSyntheticNodeColumnsMutationFn = Apollo.MutationFunction<Types.SetSyntheticNodeColumnsMutation, Types.SetSyntheticNodeColumnsMutationVariables>;

/**
 * __useSetSyntheticNodeColumnsMutation__
 *
 * To run a mutation, you first call `useSetSyntheticNodeColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSyntheticNodeColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSyntheticNodeColumnsMutation, { data, loading, error }] = useSetSyntheticNodeColumnsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      columns: // value for 'columns'
 *   },
 * });
 */
export function useSetSyntheticNodeColumnsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSyntheticNodeColumnsMutation, Types.SetSyntheticNodeColumnsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSyntheticNodeColumnsMutation, Types.SetSyntheticNodeColumnsMutationVariables>(SetSyntheticNodeColumnsDocument, options);
      }
export type SetSyntheticNodeColumnsMutationHookResult = ReturnType<typeof useSetSyntheticNodeColumnsMutation>;
export type SetSyntheticNodeColumnsMutationResult = Apollo.MutationResult<Types.SetSyntheticNodeColumnsMutation>;
export type SetSyntheticNodeColumnsMutationOptions = Apollo.BaseMutationOptions<Types.SetSyntheticNodeColumnsMutation, Types.SetSyntheticNodeColumnsMutationVariables>;
export const UpdateSyntheticNodeColumnDocument = gql`
    mutation UpdateSyntheticNodeColumn($id: String!, $maskType: SyntheticMaskType, $shouldMaskColumn: Boolean) {
  draftSyntheticNode {
    updateSyntheticNodeColumn(
      input: {id: $id, maskType: $maskType, shouldMaskColumn: $shouldMaskColumn}
    ) {
      id
      maskType
      shouldMaskColumn
    }
  }
}
    `;
export type UpdateSyntheticNodeColumnMutationFn = Apollo.MutationFunction<Types.UpdateSyntheticNodeColumnMutation, Types.UpdateSyntheticNodeColumnMutationVariables>;

/**
 * __useUpdateSyntheticNodeColumnMutation__
 *
 * To run a mutation, you first call `useUpdateSyntheticNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSyntheticNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSyntheticNodeColumnMutation, { data, loading, error }] = useUpdateSyntheticNodeColumnMutation({
 *   variables: {
 *      id: // value for 'id'
 *      maskType: // value for 'maskType'
 *      shouldMaskColumn: // value for 'shouldMaskColumn'
 *   },
 * });
 */
export function useUpdateSyntheticNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSyntheticNodeColumnMutation, Types.UpdateSyntheticNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSyntheticNodeColumnMutation, Types.UpdateSyntheticNodeColumnMutationVariables>(UpdateSyntheticNodeColumnDocument, options);
      }
export type UpdateSyntheticNodeColumnMutationHookResult = ReturnType<typeof useUpdateSyntheticNodeColumnMutation>;
export type UpdateSyntheticNodeColumnMutationResult = Apollo.MutationResult<Types.UpdateSyntheticNodeColumnMutation>;
export type UpdateSyntheticNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSyntheticNodeColumnMutation, Types.UpdateSyntheticNodeColumnMutationVariables>;
export const RetrieveDraftComputationFormatDocument = gql`
    mutation RetrieveDraftComputationFormat($id: String!, $nodeId: String!) {
  retrieveDraftComputationFormat(input: {id: $id, nodeId: $nodeId}) @client {
    format
  }
}
    `;
export type RetrieveDraftComputationFormatMutationFn = Apollo.MutationFunction<Types.RetrieveDraftComputationFormatMutation, Types.RetrieveDraftComputationFormatMutationVariables>;

/**
 * __useRetrieveDraftComputationFormatMutation__
 *
 * To run a mutation, you first call `useRetrieveDraftComputationFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveDraftComputationFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveDraftComputationFormatMutation, { data, loading, error }] = useRetrieveDraftComputationFormatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useRetrieveDraftComputationFormatMutation(baseOptions?: Apollo.MutationHookOptions<Types.RetrieveDraftComputationFormatMutation, Types.RetrieveDraftComputationFormatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RetrieveDraftComputationFormatMutation, Types.RetrieveDraftComputationFormatMutationVariables>(RetrieveDraftComputationFormatDocument, options);
      }
export type RetrieveDraftComputationFormatMutationHookResult = ReturnType<typeof useRetrieveDraftComputationFormatMutation>;
export type RetrieveDraftComputationFormatMutationResult = Apollo.MutationResult<Types.RetrieveDraftComputationFormatMutation>;
export type RetrieveDraftComputationFormatMutationOptions = Apollo.BaseMutationOptions<Types.RetrieveDraftComputationFormatMutation, Types.RetrieveDraftComputationFormatMutationVariables>;
export const RetrievePublishedComputationFormatDocument = gql`
    mutation RetrievePublishedComputationFormat($dcrHash: HexString!, $driverAttestationHash: HexString!, $computeNodeId: String!) {
  retrievePublishedComputationFormat(
    input: {dcrHash: $dcrHash, driverAttestationHash: $driverAttestationHash, computeNodeId: $computeNodeId}
  ) @client {
    format
  }
}
    `;
export type RetrievePublishedComputationFormatMutationFn = Apollo.MutationFunction<Types.RetrievePublishedComputationFormatMutation, Types.RetrievePublishedComputationFormatMutationVariables>;

/**
 * __useRetrievePublishedComputationFormatMutation__
 *
 * To run a mutation, you first call `useRetrievePublishedComputationFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrievePublishedComputationFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrievePublishedComputationFormatMutation, { data, loading, error }] = useRetrievePublishedComputationFormatMutation({
 *   variables: {
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useRetrievePublishedComputationFormatMutation(baseOptions?: Apollo.MutationHookOptions<Types.RetrievePublishedComputationFormatMutation, Types.RetrievePublishedComputationFormatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RetrievePublishedComputationFormatMutation, Types.RetrievePublishedComputationFormatMutationVariables>(RetrievePublishedComputationFormatDocument, options);
      }
export type RetrievePublishedComputationFormatMutationHookResult = ReturnType<typeof useRetrievePublishedComputationFormatMutation>;
export type RetrievePublishedComputationFormatMutationResult = Apollo.MutationResult<Types.RetrievePublishedComputationFormatMutation>;
export type RetrievePublishedComputationFormatMutationOptions = Apollo.BaseMutationOptions<Types.RetrievePublishedComputationFormatMutation, Types.RetrievePublishedComputationFormatMutationVariables>;
export const DraftComputeNodeNameDocument = gql`
    query DraftComputeNodeName($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    name
  }
}
    `;

/**
 * __useDraftComputeNodeNameQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeNameQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeNameQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeNameQuery, Types.DraftComputeNodeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeNameQuery, Types.DraftComputeNodeNameQueryVariables>(DraftComputeNodeNameDocument, options);
      }
export function useDraftComputeNodeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeNameQuery, Types.DraftComputeNodeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeNameQuery, Types.DraftComputeNodeNameQueryVariables>(DraftComputeNodeNameDocument, options);
        }
export type DraftComputeNodeNameQueryHookResult = ReturnType<typeof useDraftComputeNodeNameQuery>;
export type DraftComputeNodeNameLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeNameLazyQuery>;
export type DraftComputeNodeNameQueryResult = Apollo.QueryResult<Types.DraftComputeNodeNameQuery, Types.DraftComputeNodeNameQueryVariables>;
export const PublishedComputeNodeNameDocument = gql`
    query PublishedComputeNodeName($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
  }
}
    `;

/**
 * __usePublishedComputeNodeNameQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeNameQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeNameQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeNameQuery, Types.PublishedComputeNodeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeNameQuery, Types.PublishedComputeNodeNameQueryVariables>(PublishedComputeNodeNameDocument, options);
      }
export function usePublishedComputeNodeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeNameQuery, Types.PublishedComputeNodeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeNameQuery, Types.PublishedComputeNodeNameQueryVariables>(PublishedComputeNodeNameDocument, options);
        }
export type PublishedComputeNodeNameQueryHookResult = ReturnType<typeof usePublishedComputeNodeNameQuery>;
export type PublishedComputeNodeNameLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeNameLazyQuery>;
export type PublishedComputeNodeNameQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeNameQuery, Types.PublishedComputeNodeNameQueryVariables>;
export const MatchNodeNameEditDocument = gql`
    mutation MatchNodeNameEdit($input: UpdateDraftMatchNodeInput!) {
  draftMatchNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type MatchNodeNameEditMutationFn = Apollo.MutationFunction<Types.MatchNodeNameEditMutation, Types.MatchNodeNameEditMutationVariables>;

/**
 * __useMatchNodeNameEditMutation__
 *
 * To run a mutation, you first call `useMatchNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchNodeNameEditMutation, { data, loading, error }] = useMatchNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMatchNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.MatchNodeNameEditMutation, Types.MatchNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MatchNodeNameEditMutation, Types.MatchNodeNameEditMutationVariables>(MatchNodeNameEditDocument, options);
      }
export type MatchNodeNameEditMutationHookResult = ReturnType<typeof useMatchNodeNameEditMutation>;
export type MatchNodeNameEditMutationResult = Apollo.MutationResult<Types.MatchNodeNameEditMutation>;
export type MatchNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.MatchNodeNameEditMutation, Types.MatchNodeNameEditMutationVariables>;
export const PostNodeNameEditDocument = gql`
    mutation PostNodeNameEdit($input: UpdateDraftPostNodeInput!) {
  draftPostNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type PostNodeNameEditMutationFn = Apollo.MutationFunction<Types.PostNodeNameEditMutation, Types.PostNodeNameEditMutationVariables>;

/**
 * __usePostNodeNameEditMutation__
 *
 * To run a mutation, you first call `usePostNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postNodeNameEditMutation, { data, loading, error }] = usePostNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.PostNodeNameEditMutation, Types.PostNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PostNodeNameEditMutation, Types.PostNodeNameEditMutationVariables>(PostNodeNameEditDocument, options);
      }
export type PostNodeNameEditMutationHookResult = ReturnType<typeof usePostNodeNameEditMutation>;
export type PostNodeNameEditMutationResult = Apollo.MutationResult<Types.PostNodeNameEditMutation>;
export type PostNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.PostNodeNameEditMutation, Types.PostNodeNameEditMutationVariables>;
export const PreviewNodeNameEditDocument = gql`
    mutation PreviewNodeNameEdit($input: UpdateDraftPreviewNodeInput!) {
  draftPreviewNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type PreviewNodeNameEditMutationFn = Apollo.MutationFunction<Types.PreviewNodeNameEditMutation, Types.PreviewNodeNameEditMutationVariables>;

/**
 * __usePreviewNodeNameEditMutation__
 *
 * To run a mutation, you first call `usePreviewNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewNodeNameEditMutation, { data, loading, error }] = usePreviewNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.PreviewNodeNameEditMutation, Types.PreviewNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PreviewNodeNameEditMutation, Types.PreviewNodeNameEditMutationVariables>(PreviewNodeNameEditDocument, options);
      }
export type PreviewNodeNameEditMutationHookResult = ReturnType<typeof usePreviewNodeNameEditMutation>;
export type PreviewNodeNameEditMutationResult = Apollo.MutationResult<Types.PreviewNodeNameEditMutation>;
export type PreviewNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.PreviewNodeNameEditMutation, Types.PreviewNodeNameEditMutationVariables>;
export const S3NodeNameEditDocument = gql`
    mutation S3NodeNameEdit($input: UpdateDraftS3SinkNodeInput!) {
  draftS3SinkNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type S3NodeNameEditMutationFn = Apollo.MutationFunction<Types.S3NodeNameEditMutation, Types.S3NodeNameEditMutationVariables>;

/**
 * __useS3NodeNameEditMutation__
 *
 * To run a mutation, you first call `useS3NodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useS3NodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [s3NodeNameEditMutation, { data, loading, error }] = useS3NodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useS3NodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.S3NodeNameEditMutation, Types.S3NodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.S3NodeNameEditMutation, Types.S3NodeNameEditMutationVariables>(S3NodeNameEditDocument, options);
      }
export type S3NodeNameEditMutationHookResult = ReturnType<typeof useS3NodeNameEditMutation>;
export type S3NodeNameEditMutationResult = Apollo.MutationResult<Types.S3NodeNameEditMutation>;
export type S3NodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.S3NodeNameEditMutation, Types.S3NodeNameEditMutationVariables>;
export const ScriptingNodeNameEditDocument = gql`
    mutation ScriptingNodeNameEdit($input: UpdateDraftScriptingNodeInput!) {
  draftScriptingNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type ScriptingNodeNameEditMutationFn = Apollo.MutationFunction<Types.ScriptingNodeNameEditMutation, Types.ScriptingNodeNameEditMutationVariables>;

/**
 * __useScriptingNodeNameEditMutation__
 *
 * To run a mutation, you first call `useScriptingNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScriptingNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scriptingNodeNameEditMutation, { data, loading, error }] = useScriptingNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScriptingNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.ScriptingNodeNameEditMutation, Types.ScriptingNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ScriptingNodeNameEditMutation, Types.ScriptingNodeNameEditMutationVariables>(ScriptingNodeNameEditDocument, options);
      }
export type ScriptingNodeNameEditMutationHookResult = ReturnType<typeof useScriptingNodeNameEditMutation>;
export type ScriptingNodeNameEditMutationResult = Apollo.MutationResult<Types.ScriptingNodeNameEditMutation>;
export type ScriptingNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.ScriptingNodeNameEditMutation, Types.ScriptingNodeNameEditMutationVariables>;
export const SqlNodeNameEditDocument = gql`
    mutation SqlNodeNameEdit($input: UpdateDraftSqlNodeInput!) {
  draftSqlNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type SqlNodeNameEditMutationFn = Apollo.MutationFunction<Types.SqlNodeNameEditMutation, Types.SqlNodeNameEditMutationVariables>;

/**
 * __useSqlNodeNameEditMutation__
 *
 * To run a mutation, you first call `useSqlNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqlNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqlNodeNameEditMutation, { data, loading, error }] = useSqlNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSqlNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqlNodeNameEditMutation, Types.SqlNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqlNodeNameEditMutation, Types.SqlNodeNameEditMutationVariables>(SqlNodeNameEditDocument, options);
      }
export type SqlNodeNameEditMutationHookResult = ReturnType<typeof useSqlNodeNameEditMutation>;
export type SqlNodeNameEditMutationResult = Apollo.MutationResult<Types.SqlNodeNameEditMutation>;
export type SqlNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.SqlNodeNameEditMutation, Types.SqlNodeNameEditMutationVariables>;
export const SqliteNodeNameEditDocument = gql`
    mutation SqliteNodeNameEdit($input: UpdateDraftSqliteNodeInput!) {
  draftSqliteNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type SqliteNodeNameEditMutationFn = Apollo.MutationFunction<Types.SqliteNodeNameEditMutation, Types.SqliteNodeNameEditMutationVariables>;

/**
 * __useSqliteNodeNameEditMutation__
 *
 * To run a mutation, you first call `useSqliteNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSqliteNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sqliteNodeNameEditMutation, { data, loading, error }] = useSqliteNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSqliteNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.SqliteNodeNameEditMutation, Types.SqliteNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SqliteNodeNameEditMutation, Types.SqliteNodeNameEditMutationVariables>(SqliteNodeNameEditDocument, options);
      }
export type SqliteNodeNameEditMutationHookResult = ReturnType<typeof useSqliteNodeNameEditMutation>;
export type SqliteNodeNameEditMutationResult = Apollo.MutationResult<Types.SqliteNodeNameEditMutation>;
export type SqliteNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.SqliteNodeNameEditMutation, Types.SqliteNodeNameEditMutationVariables>;
export const SyntheticNodeNameEditDocument = gql`
    mutation SyntheticNodeNameEdit($input: UpdateDraftSyntheticNodeInput!) {
  draftSyntheticNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type SyntheticNodeNameEditMutationFn = Apollo.MutationFunction<Types.SyntheticNodeNameEditMutation, Types.SyntheticNodeNameEditMutationVariables>;

/**
 * __useSyntheticNodeNameEditMutation__
 *
 * To run a mutation, you first call `useSyntheticNodeNameEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyntheticNodeNameEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syntheticNodeNameEditMutation, { data, loading, error }] = useSyntheticNodeNameEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyntheticNodeNameEditMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyntheticNodeNameEditMutation, Types.SyntheticNodeNameEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyntheticNodeNameEditMutation, Types.SyntheticNodeNameEditMutationVariables>(SyntheticNodeNameEditDocument, options);
      }
export type SyntheticNodeNameEditMutationHookResult = ReturnType<typeof useSyntheticNodeNameEditMutation>;
export type SyntheticNodeNameEditMutationResult = Apollo.MutationResult<Types.SyntheticNodeNameEditMutation>;
export type SyntheticNodeNameEditMutationOptions = Apollo.BaseMutationOptions<Types.SyntheticNodeNameEditMutation, Types.SyntheticNodeNameEditMutationVariables>;
export const DraftComputeNodeParticipantsDocument = gql`
    query DraftComputeNodeParticipants($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    permissions {
      nodes {
        ... on DraftAnalystPermission {
          participant {
            id
            userEmail
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftComputeNodeParticipantsQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeParticipantsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeParticipantsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeParticipantsQuery, Types.DraftComputeNodeParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeParticipantsQuery, Types.DraftComputeNodeParticipantsQueryVariables>(DraftComputeNodeParticipantsDocument, options);
      }
export function useDraftComputeNodeParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeParticipantsQuery, Types.DraftComputeNodeParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeParticipantsQuery, Types.DraftComputeNodeParticipantsQueryVariables>(DraftComputeNodeParticipantsDocument, options);
        }
export type DraftComputeNodeParticipantsQueryHookResult = ReturnType<typeof useDraftComputeNodeParticipantsQuery>;
export type DraftComputeNodeParticipantsLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeParticipantsLazyQuery>;
export type DraftComputeNodeParticipantsQueryResult = Apollo.QueryResult<Types.DraftComputeNodeParticipantsQuery, Types.DraftComputeNodeParticipantsQueryVariables>;
export const PublishedComputeNodeParticipantsDocument = gql`
    query PublishedComputeNodeParticipants($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    permissions {
      ... on PublishedAnalystPermission {
        participant {
          userEmail
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedComputeNodeParticipantsQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeParticipantsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeParticipantsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeParticipantsQuery, Types.PublishedComputeNodeParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeParticipantsQuery, Types.PublishedComputeNodeParticipantsQueryVariables>(PublishedComputeNodeParticipantsDocument, options);
      }
export function usePublishedComputeNodeParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeParticipantsQuery, Types.PublishedComputeNodeParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeParticipantsQuery, Types.PublishedComputeNodeParticipantsQueryVariables>(PublishedComputeNodeParticipantsDocument, options);
        }
export type PublishedComputeNodeParticipantsQueryHookResult = ReturnType<typeof usePublishedComputeNodeParticipantsQuery>;
export type PublishedComputeNodeParticipantsLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeParticipantsLazyQuery>;
export type PublishedComputeNodeParticipantsQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeParticipantsQuery, Types.PublishedComputeNodeParticipantsQueryVariables>;
export const FetchComputeJobResultDocument = gql`
    mutation FetchComputeJobResult($input: RetrieveComputeJobResultInput!) {
  retrieveComputeJobResult(input: $input) @client {
    result
  }
}
    `;
export type FetchComputeJobResultMutationFn = Apollo.MutationFunction<Types.FetchComputeJobResultMutation, Types.FetchComputeJobResultMutationVariables>;

/**
 * __useFetchComputeJobResultMutation__
 *
 * To run a mutation, you first call `useFetchComputeJobResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchComputeJobResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchComputeJobResultMutation, { data, loading, error }] = useFetchComputeJobResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchComputeJobResultMutation(baseOptions?: Apollo.MutationHookOptions<Types.FetchComputeJobResultMutation, Types.FetchComputeJobResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FetchComputeJobResultMutation, Types.FetchComputeJobResultMutationVariables>(FetchComputeJobResultDocument, options);
      }
export type FetchComputeJobResultMutationHookResult = ReturnType<typeof useFetchComputeJobResultMutation>;
export type FetchComputeJobResultMutationResult = Apollo.MutationResult<Types.FetchComputeJobResultMutation>;
export type FetchComputeJobResultMutationOptions = Apollo.BaseMutationOptions<Types.FetchComputeJobResultMutation, Types.FetchComputeJobResultMutationVariables>;
export const FetchComputeJobResultSizeDocument = gql`
    mutation FetchComputeJobResultSize($input: RetrieveComputeJobResultInput!) {
  retrieveComputeJobResultSize(input: $input) @client
}
    `;
export type FetchComputeJobResultSizeMutationFn = Apollo.MutationFunction<Types.FetchComputeJobResultSizeMutation, Types.FetchComputeJobResultSizeMutationVariables>;

/**
 * __useFetchComputeJobResultSizeMutation__
 *
 * To run a mutation, you first call `useFetchComputeJobResultSizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchComputeJobResultSizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchComputeJobResultSizeMutation, { data, loading, error }] = useFetchComputeJobResultSizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchComputeJobResultSizeMutation(baseOptions?: Apollo.MutationHookOptions<Types.FetchComputeJobResultSizeMutation, Types.FetchComputeJobResultSizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FetchComputeJobResultSizeMutation, Types.FetchComputeJobResultSizeMutationVariables>(FetchComputeJobResultSizeDocument, options);
      }
export type FetchComputeJobResultSizeMutationHookResult = ReturnType<typeof useFetchComputeJobResultSizeMutation>;
export type FetchComputeJobResultSizeMutationResult = Apollo.MutationResult<Types.FetchComputeJobResultSizeMutation>;
export type FetchComputeJobResultSizeMutationOptions = Apollo.BaseMutationOptions<Types.FetchComputeJobResultSizeMutation, Types.FetchComputeJobResultSizeMutationVariables>;
export const DraftComputeNodeTypeDocument = gql`
    query DraftComputeNodeType($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    name
    ... on DraftScriptingNode {
      computationType
    }
    ... on DraftSyntheticNode {
      computationType
    }
    ... on DraftPostNode {
      computationType
    }
    ... on DraftPreviewNode {
      computationType
    }
    ... on DraftS3SinkNode {
      computationType
    }
    ... on DraftMatchNode {
      computationType
    }
    ... on DraftSqlNode {
      computationType
      privacyFilter {
        minimumRows
      }
    }
    ... on DraftSqliteNode {
      computationType
      privacyFilter {
        minimumRows
      }
    }
  }
}
    `;

/**
 * __useDraftComputeNodeTypeQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeTypeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeTypeQuery, Types.DraftComputeNodeTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeTypeQuery, Types.DraftComputeNodeTypeQueryVariables>(DraftComputeNodeTypeDocument, options);
      }
export function useDraftComputeNodeTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeTypeQuery, Types.DraftComputeNodeTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeTypeQuery, Types.DraftComputeNodeTypeQueryVariables>(DraftComputeNodeTypeDocument, options);
        }
export type DraftComputeNodeTypeQueryHookResult = ReturnType<typeof useDraftComputeNodeTypeQuery>;
export type DraftComputeNodeTypeLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeTypeLazyQuery>;
export type DraftComputeNodeTypeQueryResult = Apollo.QueryResult<Types.DraftComputeNodeTypeQuery, Types.DraftComputeNodeTypeQueryVariables>;
export const PublishedComputeNodeTypeDocument = gql`
    query PublishedComputeNodeType($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    ... on PublishedScriptingNode {
      computationType
    }
    ... on PublishedSyntheticNode {
      computationType
    }
    ... on PublishedPostNode {
      computationType
    }
    ... on PublishedPreviewNode {
      computationType
    }
    ... on PublishedS3SinkNode {
      computationType
    }
    ... on PublishedMatchNode {
      computationType
    }
    ... on PublishedSqliteNode {
      computationType
      privacyFilter {
        minimumRows
      }
    }
    ... on PublishedSqlNode {
      computationType
      privacyFilter {
        minimumRows
      }
    }
  }
}
    `;

/**
 * __usePublishedComputeNodeTypeQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeTypeQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeTypeQuery, Types.PublishedComputeNodeTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeTypeQuery, Types.PublishedComputeNodeTypeQueryVariables>(PublishedComputeNodeTypeDocument, options);
      }
export function usePublishedComputeNodeTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeTypeQuery, Types.PublishedComputeNodeTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeTypeQuery, Types.PublishedComputeNodeTypeQueryVariables>(PublishedComputeNodeTypeDocument, options);
        }
export type PublishedComputeNodeTypeQueryHookResult = ReturnType<typeof usePublishedComputeNodeTypeQuery>;
export type PublishedComputeNodeTypeLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeTypeLazyQuery>;
export type PublishedComputeNodeTypeQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeTypeQuery, Types.PublishedComputeNodeTypeQueryVariables>;
export const StartJobPollingDocument = gql`
    mutation StartJobPolling($input: PollComputeJobInput!) {
  pollComputeJob(input: $input) @client
}
    `;
export type StartJobPollingMutationFn = Apollo.MutationFunction<Types.StartJobPollingMutation, Types.StartJobPollingMutationVariables>;

/**
 * __useStartJobPollingMutation__
 *
 * To run a mutation, you first call `useStartJobPollingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartJobPollingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startJobPollingMutation, { data, loading, error }] = useStartJobPollingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartJobPollingMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartJobPollingMutation, Types.StartJobPollingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartJobPollingMutation, Types.StartJobPollingMutationVariables>(StartJobPollingDocument, options);
      }
export type StartJobPollingMutationHookResult = ReturnType<typeof useStartJobPollingMutation>;
export type StartJobPollingMutationResult = Apollo.MutationResult<Types.StartJobPollingMutation>;
export type StartJobPollingMutationOptions = Apollo.BaseMutationOptions<Types.StartJobPollingMutation, Types.StartJobPollingMutationVariables>;
export const IsDraftDataRoomOwnerDocument = gql`
    query IsDraftDataRoomOwner($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    isOwner @client
  }
}
    `;

/**
 * __useIsDraftDataRoomOwnerQuery__
 *
 * To run a query within a React component, call `useIsDraftDataRoomOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDraftDataRoomOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDraftDataRoomOwnerQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useIsDraftDataRoomOwnerQuery(baseOptions: Apollo.QueryHookOptions<Types.IsDraftDataRoomOwnerQuery, Types.IsDraftDataRoomOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsDraftDataRoomOwnerQuery, Types.IsDraftDataRoomOwnerQueryVariables>(IsDraftDataRoomOwnerDocument, options);
      }
export function useIsDraftDataRoomOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsDraftDataRoomOwnerQuery, Types.IsDraftDataRoomOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsDraftDataRoomOwnerQuery, Types.IsDraftDataRoomOwnerQueryVariables>(IsDraftDataRoomOwnerDocument, options);
        }
export type IsDraftDataRoomOwnerQueryHookResult = ReturnType<typeof useIsDraftDataRoomOwnerQuery>;
export type IsDraftDataRoomOwnerLazyQueryHookResult = ReturnType<typeof useIsDraftDataRoomOwnerLazyQuery>;
export type IsDraftDataRoomOwnerQueryResult = Apollo.QueryResult<Types.IsDraftDataRoomOwnerQuery, Types.IsDraftDataRoomOwnerQueryVariables>;
export const IsPublishedDataRoomOwnerDocument = gql`
    query IsPublishedDataRoomOwner($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    isOwner @client
  }
}
    `;

/**
 * __useIsPublishedDataRoomOwnerQuery__
 *
 * To run a query within a React component, call `useIsPublishedDataRoomOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPublishedDataRoomOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPublishedDataRoomOwnerQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useIsPublishedDataRoomOwnerQuery(baseOptions: Apollo.QueryHookOptions<Types.IsPublishedDataRoomOwnerQuery, Types.IsPublishedDataRoomOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsPublishedDataRoomOwnerQuery, Types.IsPublishedDataRoomOwnerQueryVariables>(IsPublishedDataRoomOwnerDocument, options);
      }
export function useIsPublishedDataRoomOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsPublishedDataRoomOwnerQuery, Types.IsPublishedDataRoomOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsPublishedDataRoomOwnerQuery, Types.IsPublishedDataRoomOwnerQueryVariables>(IsPublishedDataRoomOwnerDocument, options);
        }
export type IsPublishedDataRoomOwnerQueryHookResult = ReturnType<typeof useIsPublishedDataRoomOwnerQuery>;
export type IsPublishedDataRoomOwnerLazyQueryHookResult = ReturnType<typeof useIsPublishedDataRoomOwnerLazyQuery>;
export type IsPublishedDataRoomOwnerQueryResult = Apollo.QueryResult<Types.IsPublishedDataRoomOwnerQuery, Types.IsPublishedDataRoomOwnerQueryVariables>;
export const TestAllComputationsDocument = gql`
    mutation TestAllComputations($input: TestAllComputationsInput!) {
  testAllComputations(input: $input) @client {
    results {
      computeNodeId
    }
  }
}
    `;
export type TestAllComputationsMutationFn = Apollo.MutationFunction<Types.TestAllComputationsMutation, Types.TestAllComputationsMutationVariables>;

/**
 * __useTestAllComputationsMutation__
 *
 * To run a mutation, you first call `useTestAllComputationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestAllComputationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testAllComputationsMutation, { data, loading, error }] = useTestAllComputationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestAllComputationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.TestAllComputationsMutation, Types.TestAllComputationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TestAllComputationsMutation, Types.TestAllComputationsMutationVariables>(TestAllComputationsDocument, options);
      }
export type TestAllComputationsMutationHookResult = ReturnType<typeof useTestAllComputationsMutation>;
export type TestAllComputationsMutationResult = Apollo.MutationResult<Types.TestAllComputationsMutation>;
export type TestAllComputationsMutationOptions = Apollo.BaseMutationOptions<Types.TestAllComputationsMutation, Types.TestAllComputationsMutationVariables>;
export const CreateDataLabDocument = gql`
    mutation CreateDataLab($input: CreateDataLabInput!) {
  dataLab {
    create(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type CreateDataLabMutationFn = Apollo.MutationFunction<Types.CreateDataLabMutation, Types.CreateDataLabMutationVariables>;

/**
 * __useCreateDataLabMutation__
 *
 * To run a mutation, you first call `useCreateDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataLabMutation, { data, loading, error }] = useCreateDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataLabMutation, Types.CreateDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDataLabMutation, Types.CreateDataLabMutationVariables>(CreateDataLabDocument, options);
      }
export type CreateDataLabMutationHookResult = ReturnType<typeof useCreateDataLabMutation>;
export type CreateDataLabMutationResult = Apollo.MutationResult<Types.CreateDataLabMutation>;
export type CreateDataLabMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataLabMutation, Types.CreateDataLabMutationVariables>;
export const DataLabDocument = gql`
    query DataLab($id: String!) {
  dataLab(id: $id) {
    ...FullDataLab
  }
}
    ${FullDataLabFragment}`;

/**
 * __useDataLabQuery__
 *
 * To run a query within a React component, call `useDataLabQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLabQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataLabQuery(baseOptions: Apollo.QueryHookOptions<Types.DataLabQuery, Types.DataLabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataLabQuery, Types.DataLabQueryVariables>(DataLabDocument, options);
      }
export function useDataLabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataLabQuery, Types.DataLabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataLabQuery, Types.DataLabQueryVariables>(DataLabDocument, options);
        }
export type DataLabQueryHookResult = ReturnType<typeof useDataLabQuery>;
export type DataLabLazyQueryHookResult = ReturnType<typeof useDataLabLazyQuery>;
export type DataLabQueryResult = Apollo.QueryResult<Types.DataLabQuery, Types.DataLabQueryVariables>;
export const DataLabsDocument = gql`
    query DataLabs {
  dataLabs {
    nodes {
      ...FullDataLab
    }
  }
}
    ${FullDataLabFragment}`;

/**
 * __useDataLabsQuery__
 *
 * To run a query within a React component, call `useDataLabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataLabsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataLabsQuery, Types.DataLabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataLabsQuery, Types.DataLabsQueryVariables>(DataLabsDocument, options);
      }
export function useDataLabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataLabsQuery, Types.DataLabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataLabsQuery, Types.DataLabsQueryVariables>(DataLabsDocument, options);
        }
export type DataLabsQueryHookResult = ReturnType<typeof useDataLabsQuery>;
export type DataLabsLazyQueryHookResult = ReturnType<typeof useDataLabsLazyQuery>;
export type DataLabsQueryResult = Apollo.QueryResult<Types.DataLabsQuery, Types.DataLabsQueryVariables>;
export const DeleteDataLabDocument = gql`
    mutation DeleteDataLab($id: String!) {
  dataLab {
    deleteById(id: $id)
  }
}
    `;
export type DeleteDataLabMutationFn = Apollo.MutationFunction<Types.DeleteDataLabMutation, Types.DeleteDataLabMutationVariables>;

/**
 * __useDeleteDataLabMutation__
 *
 * To run a mutation, you first call `useDeleteDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataLabMutation, { data, loading, error }] = useDeleteDataLabMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDataLabMutation, Types.DeleteDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDataLabMutation, Types.DeleteDataLabMutationVariables>(DeleteDataLabDocument, options);
      }
export type DeleteDataLabMutationHookResult = ReturnType<typeof useDeleteDataLabMutation>;
export type DeleteDataLabMutationResult = Apollo.MutationResult<Types.DeleteDataLabMutation>;
export type DeleteDataLabMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDataLabMutation, Types.DeleteDataLabMutationVariables>;
export const ResetJobIdsForDataLabDocument = gql`
    mutation ResetJobIdsForDataLab($id: String!) {
  dataLab {
    resetJobIds(id: $id) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type ResetJobIdsForDataLabMutationFn = Apollo.MutationFunction<Types.ResetJobIdsForDataLabMutation, Types.ResetJobIdsForDataLabMutationVariables>;

/**
 * __useResetJobIdsForDataLabMutation__
 *
 * To run a mutation, you first call `useResetJobIdsForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetJobIdsForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetJobIdsForDataLabMutation, { data, loading, error }] = useResetJobIdsForDataLabMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetJobIdsForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetJobIdsForDataLabMutation, Types.ResetJobIdsForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetJobIdsForDataLabMutation, Types.ResetJobIdsForDataLabMutationVariables>(ResetJobIdsForDataLabDocument, options);
      }
export type ResetJobIdsForDataLabMutationHookResult = ReturnType<typeof useResetJobIdsForDataLabMutation>;
export type ResetJobIdsForDataLabMutationResult = Apollo.MutationResult<Types.ResetJobIdsForDataLabMutation>;
export type ResetJobIdsForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.ResetJobIdsForDataLabMutation, Types.ResetJobIdsForDataLabMutationVariables>;
export const SetDemographicsDatasetForDataLabDocument = gql`
    mutation SetDemographicsDatasetForDataLab($input: SetDataLabDatasetInput!) {
  dataLab {
    setDemographicsDataset(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetDemographicsDatasetForDataLabMutationFn = Apollo.MutationFunction<Types.SetDemographicsDatasetForDataLabMutation, Types.SetDemographicsDatasetForDataLabMutationVariables>;

/**
 * __useSetDemographicsDatasetForDataLabMutation__
 *
 * To run a mutation, you first call `useSetDemographicsDatasetForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDemographicsDatasetForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDemographicsDatasetForDataLabMutation, { data, loading, error }] = useSetDemographicsDatasetForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDemographicsDatasetForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDemographicsDatasetForDataLabMutation, Types.SetDemographicsDatasetForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDemographicsDatasetForDataLabMutation, Types.SetDemographicsDatasetForDataLabMutationVariables>(SetDemographicsDatasetForDataLabDocument, options);
      }
export type SetDemographicsDatasetForDataLabMutationHookResult = ReturnType<typeof useSetDemographicsDatasetForDataLabMutation>;
export type SetDemographicsDatasetForDataLabMutationResult = Apollo.MutationResult<Types.SetDemographicsDatasetForDataLabMutation>;
export type SetDemographicsDatasetForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetDemographicsDatasetForDataLabMutation, Types.SetDemographicsDatasetForDataLabMutationVariables>;
export const SetEmbeddingsDatasetForDataLabDocument = gql`
    mutation SetEmbeddingsDatasetForDataLab($input: SetDataLabDatasetInput!) {
  dataLab {
    setEmbeddingsDataset(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetEmbeddingsDatasetForDataLabMutationFn = Apollo.MutationFunction<Types.SetEmbeddingsDatasetForDataLabMutation, Types.SetEmbeddingsDatasetForDataLabMutationVariables>;

/**
 * __useSetEmbeddingsDatasetForDataLabMutation__
 *
 * To run a mutation, you first call `useSetEmbeddingsDatasetForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmbeddingsDatasetForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmbeddingsDatasetForDataLabMutation, { data, loading, error }] = useSetEmbeddingsDatasetForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEmbeddingsDatasetForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetEmbeddingsDatasetForDataLabMutation, Types.SetEmbeddingsDatasetForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetEmbeddingsDatasetForDataLabMutation, Types.SetEmbeddingsDatasetForDataLabMutationVariables>(SetEmbeddingsDatasetForDataLabDocument, options);
      }
export type SetEmbeddingsDatasetForDataLabMutationHookResult = ReturnType<typeof useSetEmbeddingsDatasetForDataLabMutation>;
export type SetEmbeddingsDatasetForDataLabMutationResult = Apollo.MutationResult<Types.SetEmbeddingsDatasetForDataLabMutation>;
export type SetEmbeddingsDatasetForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetEmbeddingsDatasetForDataLabMutation, Types.SetEmbeddingsDatasetForDataLabMutationVariables>;
export const SetJobIdsForDataLabDocument = gql`
    mutation SetJobIdsForDataLab($input: SetDataLabJobIdsInput!) {
  dataLab {
    setJobIds(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetJobIdsForDataLabMutationFn = Apollo.MutationFunction<Types.SetJobIdsForDataLabMutation, Types.SetJobIdsForDataLabMutationVariables>;

/**
 * __useSetJobIdsForDataLabMutation__
 *
 * To run a mutation, you first call `useSetJobIdsForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobIdsForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobIdsForDataLabMutation, { data, loading, error }] = useSetJobIdsForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetJobIdsForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetJobIdsForDataLabMutation, Types.SetJobIdsForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetJobIdsForDataLabMutation, Types.SetJobIdsForDataLabMutationVariables>(SetJobIdsForDataLabDocument, options);
      }
export type SetJobIdsForDataLabMutationHookResult = ReturnType<typeof useSetJobIdsForDataLabMutation>;
export type SetJobIdsForDataLabMutationResult = Apollo.MutationResult<Types.SetJobIdsForDataLabMutation>;
export type SetJobIdsForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetJobIdsForDataLabMutation, Types.SetJobIdsForDataLabMutationVariables>;
export const SetSegmentsDatasetForDataLabDocument = gql`
    mutation SetSegmentsDatasetForDataLab($input: SetDataLabDatasetInput!) {
  dataLab {
    setSegmentsDataset(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetSegmentsDatasetForDataLabMutationFn = Apollo.MutationFunction<Types.SetSegmentsDatasetForDataLabMutation, Types.SetSegmentsDatasetForDataLabMutationVariables>;

/**
 * __useSetSegmentsDatasetForDataLabMutation__
 *
 * To run a mutation, you first call `useSetSegmentsDatasetForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSegmentsDatasetForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSegmentsDatasetForDataLabMutation, { data, loading, error }] = useSetSegmentsDatasetForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSegmentsDatasetForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSegmentsDatasetForDataLabMutation, Types.SetSegmentsDatasetForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSegmentsDatasetForDataLabMutation, Types.SetSegmentsDatasetForDataLabMutationVariables>(SetSegmentsDatasetForDataLabDocument, options);
      }
export type SetSegmentsDatasetForDataLabMutationHookResult = ReturnType<typeof useSetSegmentsDatasetForDataLabMutation>;
export type SetSegmentsDatasetForDataLabMutationResult = Apollo.MutationResult<Types.SetSegmentsDatasetForDataLabMutation>;
export type SetSegmentsDatasetForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetSegmentsDatasetForDataLabMutation, Types.SetSegmentsDatasetForDataLabMutationVariables>;
export const SetStatisticsForDataLabDocument = gql`
    mutation SetStatisticsForDataLab($input: SetDataLabStatisticsInput!) {
  dataLab {
    setStatistics(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetStatisticsForDataLabMutationFn = Apollo.MutationFunction<Types.SetStatisticsForDataLabMutation, Types.SetStatisticsForDataLabMutationVariables>;

/**
 * __useSetStatisticsForDataLabMutation__
 *
 * To run a mutation, you first call `useSetStatisticsForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatisticsForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatisticsForDataLabMutation, { data, loading, error }] = useSetStatisticsForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStatisticsForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetStatisticsForDataLabMutation, Types.SetStatisticsForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetStatisticsForDataLabMutation, Types.SetStatisticsForDataLabMutationVariables>(SetStatisticsForDataLabDocument, options);
      }
export type SetStatisticsForDataLabMutationHookResult = ReturnType<typeof useSetStatisticsForDataLabMutation>;
export type SetStatisticsForDataLabMutationResult = Apollo.MutationResult<Types.SetStatisticsForDataLabMutation>;
export type SetStatisticsForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetStatisticsForDataLabMutation, Types.SetStatisticsForDataLabMutationVariables>;
export const SetUsersDatasetForDataLabDocument = gql`
    mutation SetUsersDatasetForDataLab($input: SetDataLabDatasetInput!) {
  dataLab {
    setUsersDataset(input: $input) {
      record {
        ...FullDataLab
      }
    }
  }
}
    ${FullDataLabFragment}`;
export type SetUsersDatasetForDataLabMutationFn = Apollo.MutationFunction<Types.SetUsersDatasetForDataLabMutation, Types.SetUsersDatasetForDataLabMutationVariables>;

/**
 * __useSetUsersDatasetForDataLabMutation__
 *
 * To run a mutation, you first call `useSetUsersDatasetForDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUsersDatasetForDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUsersDatasetForDataLabMutation, { data, loading, error }] = useSetUsersDatasetForDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUsersDatasetForDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetUsersDatasetForDataLabMutation, Types.SetUsersDatasetForDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetUsersDatasetForDataLabMutation, Types.SetUsersDatasetForDataLabMutationVariables>(SetUsersDatasetForDataLabDocument, options);
      }
export type SetUsersDatasetForDataLabMutationHookResult = ReturnType<typeof useSetUsersDatasetForDataLabMutation>;
export type SetUsersDatasetForDataLabMutationResult = Apollo.MutationResult<Types.SetUsersDatasetForDataLabMutation>;
export type SetUsersDatasetForDataLabMutationOptions = Apollo.BaseMutationOptions<Types.SetUsersDatasetForDataLabMutation, Types.SetUsersDatasetForDataLabMutationVariables>;
export const DraftTableLeafNodeUniquenessDocument = gql`
    query DraftTableLeafNodeUniqueness($id: String!) {
  draftNode(id: $id) {
    id
    ... on DraftTableLeafNode {
      uniqueColumnIds
    }
  }
}
    `;

/**
 * __useDraftTableLeafNodeUniquenessQuery__
 *
 * To run a query within a React component, call `useDraftTableLeafNodeUniquenessQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftTableLeafNodeUniquenessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftTableLeafNodeUniquenessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftTableLeafNodeUniquenessQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftTableLeafNodeUniquenessQuery, Types.DraftTableLeafNodeUniquenessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftTableLeafNodeUniquenessQuery, Types.DraftTableLeafNodeUniquenessQueryVariables>(DraftTableLeafNodeUniquenessDocument, options);
      }
export function useDraftTableLeafNodeUniquenessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftTableLeafNodeUniquenessQuery, Types.DraftTableLeafNodeUniquenessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftTableLeafNodeUniquenessQuery, Types.DraftTableLeafNodeUniquenessQueryVariables>(DraftTableLeafNodeUniquenessDocument, options);
        }
export type DraftTableLeafNodeUniquenessQueryHookResult = ReturnType<typeof useDraftTableLeafNodeUniquenessQuery>;
export type DraftTableLeafNodeUniquenessLazyQueryHookResult = ReturnType<typeof useDraftTableLeafNodeUniquenessLazyQuery>;
export type DraftTableLeafNodeUniquenessQueryResult = Apollo.QueryResult<Types.DraftTableLeafNodeUniquenessQuery, Types.DraftTableLeafNodeUniquenessQueryVariables>;
export const DraftTableLeafNodeUpdateUniquenessDocument = gql`
    mutation DraftTableLeafNodeUpdateUniqueness($id: String!, $uniqueness: SetUniqueValidationSettingsInput!) {
  draftTableLeafNode {
    update(input: {id: $id, uniqueness: $uniqueness}) {
      id
      uniqueColumnIds
    }
  }
}
    `;
export type DraftTableLeafNodeUpdateUniquenessMutationFn = Apollo.MutationFunction<Types.DraftTableLeafNodeUpdateUniquenessMutation, Types.DraftTableLeafNodeUpdateUniquenessMutationVariables>;

/**
 * __useDraftTableLeafNodeUpdateUniquenessMutation__
 *
 * To run a mutation, you first call `useDraftTableLeafNodeUpdateUniquenessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftTableLeafNodeUpdateUniquenessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftTableLeafNodeUpdateUniquenessMutation, { data, loading, error }] = useDraftTableLeafNodeUpdateUniquenessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      uniqueness: // value for 'uniqueness'
 *   },
 * });
 */
export function useDraftTableLeafNodeUpdateUniquenessMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftTableLeafNodeUpdateUniquenessMutation, Types.DraftTableLeafNodeUpdateUniquenessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftTableLeafNodeUpdateUniquenessMutation, Types.DraftTableLeafNodeUpdateUniquenessMutationVariables>(DraftTableLeafNodeUpdateUniquenessDocument, options);
      }
export type DraftTableLeafNodeUpdateUniquenessMutationHookResult = ReturnType<typeof useDraftTableLeafNodeUpdateUniquenessMutation>;
export type DraftTableLeafNodeUpdateUniquenessMutationResult = Apollo.MutationResult<Types.DraftTableLeafNodeUpdateUniquenessMutation>;
export type DraftTableLeafNodeUpdateUniquenessMutationOptions = Apollo.BaseMutationOptions<Types.DraftTableLeafNodeUpdateUniquenessMutation, Types.DraftTableLeafNodeUpdateUniquenessMutationVariables>;
export const PublishedTableLeafNodeUniquenessDocument = gql`
    query PublishedTableLeafNodeUniqueness($id: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $id
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    ... on PublishedTableLeafNode {
      uniqueColumnIds
    }
  }
}
    `;

/**
 * __usePublishedTableLeafNodeUniquenessQuery__
 *
 * To run a query within a React component, call `usePublishedTableLeafNodeUniquenessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedTableLeafNodeUniquenessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedTableLeafNodeUniquenessQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedTableLeafNodeUniquenessQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedTableLeafNodeUniquenessQuery, Types.PublishedTableLeafNodeUniquenessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedTableLeafNodeUniquenessQuery, Types.PublishedTableLeafNodeUniquenessQueryVariables>(PublishedTableLeafNodeUniquenessDocument, options);
      }
export function usePublishedTableLeafNodeUniquenessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedTableLeafNodeUniquenessQuery, Types.PublishedTableLeafNodeUniquenessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedTableLeafNodeUniquenessQuery, Types.PublishedTableLeafNodeUniquenessQueryVariables>(PublishedTableLeafNodeUniquenessDocument, options);
        }
export type PublishedTableLeafNodeUniquenessQueryHookResult = ReturnType<typeof usePublishedTableLeafNodeUniquenessQuery>;
export type PublishedTableLeafNodeUniquenessLazyQueryHookResult = ReturnType<typeof usePublishedTableLeafNodeUniquenessLazyQuery>;
export type PublishedTableLeafNodeUniquenessQueryResult = Apollo.QueryResult<Types.PublishedTableLeafNodeUniquenessQuery, Types.PublishedTableLeafNodeUniquenessQueryVariables>;
export const CreateDraftRawLeafNodeDocument = gql`
    mutation CreateDraftRawLeafNode($input: CreateDraftRawLeafNodeInput!) {
  dataNode: draftRawLeafNode {
    create(input: $input) {
      id
      name
      isRequired
    }
  }
}
    `;
export type CreateDraftRawLeafNodeMutationFn = Apollo.MutationFunction<Types.CreateDraftRawLeafNodeMutation, Types.CreateDraftRawLeafNodeMutationVariables>;

/**
 * __useCreateDraftRawLeafNodeMutation__
 *
 * To run a mutation, you first call `useCreateDraftRawLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftRawLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftRawLeafNodeMutation, { data, loading, error }] = useCreateDraftRawLeafNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftRawLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftRawLeafNodeMutation, Types.CreateDraftRawLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftRawLeafNodeMutation, Types.CreateDraftRawLeafNodeMutationVariables>(CreateDraftRawLeafNodeDocument, options);
      }
export type CreateDraftRawLeafNodeMutationHookResult = ReturnType<typeof useCreateDraftRawLeafNodeMutation>;
export type CreateDraftRawLeafNodeMutationResult = Apollo.MutationResult<Types.CreateDraftRawLeafNodeMutation>;
export type CreateDraftRawLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftRawLeafNodeMutation, Types.CreateDraftRawLeafNodeMutationVariables>;
export const CreateDraftTableLeafNodeDocument = gql`
    mutation CreateDraftTableLeafNode($input: CreateDraftTableLeafNodeInput!) {
  dataNode: draftTableLeafNode {
    create(input: $input) {
      id
      name
      isRequired
      columns {
        nodes {
          id
          name
          dataType
          isNullable
        }
      }
    }
  }
}
    `;
export type CreateDraftTableLeafNodeMutationFn = Apollo.MutationFunction<Types.CreateDraftTableLeafNodeMutation, Types.CreateDraftTableLeafNodeMutationVariables>;

/**
 * __useCreateDraftTableLeafNodeMutation__
 *
 * To run a mutation, you first call `useCreateDraftTableLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftTableLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftTableLeafNodeMutation, { data, loading, error }] = useCreateDraftTableLeafNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftTableLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftTableLeafNodeMutation, Types.CreateDraftTableLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftTableLeafNodeMutation, Types.CreateDraftTableLeafNodeMutationVariables>(CreateDraftTableLeafNodeDocument, options);
      }
export type CreateDraftTableLeafNodeMutationHookResult = ReturnType<typeof useCreateDraftTableLeafNodeMutation>;
export type CreateDraftTableLeafNodeMutationResult = Apollo.MutationResult<Types.CreateDraftTableLeafNodeMutation>;
export type CreateDraftTableLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftTableLeafNodeMutation, Types.CreateDraftTableLeafNodeMutationVariables>;
export const CreateDraftTableLeafNodeColumnDocument = gql`
    mutation CreateDraftTableLeafNodeColumn($id: String!, $input: CreateDraftTableLeafNodeColumnInput!) {
  draftTableLeafNode {
    addColumn(id: $id, input: $input) {
      id
      name
      isNullable
      dataType
      formatType
    }
  }
}
    `;
export type CreateDraftTableLeafNodeColumnMutationFn = Apollo.MutationFunction<Types.CreateDraftTableLeafNodeColumnMutation, Types.CreateDraftTableLeafNodeColumnMutationVariables>;

/**
 * __useCreateDraftTableLeafNodeColumnMutation__
 *
 * To run a mutation, you first call `useCreateDraftTableLeafNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftTableLeafNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftTableLeafNodeColumnMutation, { data, loading, error }] = useCreateDraftTableLeafNodeColumnMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftTableLeafNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftTableLeafNodeColumnMutation, Types.CreateDraftTableLeafNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftTableLeafNodeColumnMutation, Types.CreateDraftTableLeafNodeColumnMutationVariables>(CreateDraftTableLeafNodeColumnDocument, options);
      }
export type CreateDraftTableLeafNodeColumnMutationHookResult = ReturnType<typeof useCreateDraftTableLeafNodeColumnMutation>;
export type CreateDraftTableLeafNodeColumnMutationResult = Apollo.MutationResult<Types.CreateDraftTableLeafNodeColumnMutation>;
export type CreateDraftTableLeafNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftTableLeafNodeColumnMutation, Types.CreateDraftTableLeafNodeColumnMutationVariables>;
export const DeleteDraftRawLeafNodeDocument = gql`
    mutation DeleteDraftRawLeafNode($id: String!) {
  dataNode: draftRawLeafNode {
    delete(id: $id)
  }
}
    `;
export type DeleteDraftRawLeafNodeMutationFn = Apollo.MutationFunction<Types.DeleteDraftRawLeafNodeMutation, Types.DeleteDraftRawLeafNodeMutationVariables>;

/**
 * __useDeleteDraftRawLeafNodeMutation__
 *
 * To run a mutation, you first call `useDeleteDraftRawLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftRawLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftRawLeafNodeMutation, { data, loading, error }] = useDeleteDraftRawLeafNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftRawLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDraftRawLeafNodeMutation, Types.DeleteDraftRawLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDraftRawLeafNodeMutation, Types.DeleteDraftRawLeafNodeMutationVariables>(DeleteDraftRawLeafNodeDocument, options);
      }
export type DeleteDraftRawLeafNodeMutationHookResult = ReturnType<typeof useDeleteDraftRawLeafNodeMutation>;
export type DeleteDraftRawLeafNodeMutationResult = Apollo.MutationResult<Types.DeleteDraftRawLeafNodeMutation>;
export type DeleteDraftRawLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDraftRawLeafNodeMutation, Types.DeleteDraftRawLeafNodeMutationVariables>;
export const DeleteDraftTableLeafNodeDocument = gql`
    mutation DeleteDraftTableLeafNode($id: String!) {
  dataNode: draftTableLeafNode {
    delete(id: $id)
  }
}
    `;
export type DeleteDraftTableLeafNodeMutationFn = Apollo.MutationFunction<Types.DeleteDraftTableLeafNodeMutation, Types.DeleteDraftTableLeafNodeMutationVariables>;

/**
 * __useDeleteDraftTableLeafNodeMutation__
 *
 * To run a mutation, you first call `useDeleteDraftTableLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftTableLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftTableLeafNodeMutation, { data, loading, error }] = useDeleteDraftTableLeafNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftTableLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDraftTableLeafNodeMutation, Types.DeleteDraftTableLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDraftTableLeafNodeMutation, Types.DeleteDraftTableLeafNodeMutationVariables>(DeleteDraftTableLeafNodeDocument, options);
      }
export type DeleteDraftTableLeafNodeMutationHookResult = ReturnType<typeof useDeleteDraftTableLeafNodeMutation>;
export type DeleteDraftTableLeafNodeMutationResult = Apollo.MutationResult<Types.DeleteDraftTableLeafNodeMutation>;
export type DeleteDraftTableLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDraftTableLeafNodeMutation, Types.DeleteDraftTableLeafNodeMutationVariables>;
export const DeleteDraftTableLeafNodeColumnDocument = gql`
    mutation DeleteDraftTableLeafNodeColumn($id: String!) {
  draftTableLeafNode {
    removeColumn(id: $id)
  }
}
    `;
export type DeleteDraftTableLeafNodeColumnMutationFn = Apollo.MutationFunction<Types.DeleteDraftTableLeafNodeColumnMutation, Types.DeleteDraftTableLeafNodeColumnMutationVariables>;

/**
 * __useDeleteDraftTableLeafNodeColumnMutation__
 *
 * To run a mutation, you first call `useDeleteDraftTableLeafNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftTableLeafNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftTableLeafNodeColumnMutation, { data, loading, error }] = useDeleteDraftTableLeafNodeColumnMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftTableLeafNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDraftTableLeafNodeColumnMutation, Types.DeleteDraftTableLeafNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDraftTableLeafNodeColumnMutation, Types.DeleteDraftTableLeafNodeColumnMutationVariables>(DeleteDraftTableLeafNodeColumnDocument, options);
      }
export type DeleteDraftTableLeafNodeColumnMutationHookResult = ReturnType<typeof useDeleteDraftTableLeafNodeColumnMutation>;
export type DeleteDraftTableLeafNodeColumnMutationResult = Apollo.MutationResult<Types.DeleteDraftTableLeafNodeColumnMutation>;
export type DeleteDraftTableLeafNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDraftTableLeafNodeColumnMutation, Types.DeleteDraftTableLeafNodeColumnMutationVariables>;
export const DeleteTestModePublicationDocument = gql`
    mutation DeleteTestModePublication($nodeId: NodeId!) {
  testModePublication {
    delete(nodeId: $nodeId) {
      myself {
        id
      }
    }
  }
}
    `;
export type DeleteTestModePublicationMutationFn = Apollo.MutationFunction<Types.DeleteTestModePublicationMutation, Types.DeleteTestModePublicationMutationVariables>;

/**
 * __useDeleteTestModePublicationMutation__
 *
 * To run a mutation, you first call `useDeleteTestModePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestModePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestModePublicationMutation, { data, loading, error }] = useDeleteTestModePublicationMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteTestModePublicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTestModePublicationMutation, Types.DeleteTestModePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTestModePublicationMutation, Types.DeleteTestModePublicationMutationVariables>(DeleteTestModePublicationDocument, options);
      }
export type DeleteTestModePublicationMutationHookResult = ReturnType<typeof useDeleteTestModePublicationMutation>;
export type DeleteTestModePublicationMutationResult = Apollo.MutationResult<Types.DeleteTestModePublicationMutation>;
export type DeleteTestModePublicationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTestModePublicationMutation, Types.DeleteTestModePublicationMutationVariables>;
export const DraftDataRoomDataNodesDocument = gql`
    query DraftDataRoomDataNodes($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    dataNodesOrder
    computeNodesOrder
    draftNodes {
      nodes {
        id
        name
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              node {
                id
              }
            }
            ... on DraftDataOwnerPermission {
              node {
                id
              }
            }
          }
        }
        ... on DraftTableLeafNode {
          isRequired
          columns {
            nodes {
              id
              name
              dataType
              isNullable
              formatType
              hashWith
            }
          }
          columnsOrder
          testModePublication {
            dataset {
              id
              manifestHash
              createdAt
              owner {
                email
              }
            }
          }
          uniqueColumnIds
        }
        ... on DraftRawLeafNode {
          isRequired
          testModePublication {
            dataset {
              id
              manifestHash
              createdAt
              owner {
                email
              }
            }
          }
        }
      }
    }
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              node {
                id
              }
            }
            ... on DraftDataOwnerPermission {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftDataRoomDataNodesQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomDataNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomDataNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomDataNodesQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomDataNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomDataNodesQuery, Types.DraftDataRoomDataNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomDataNodesQuery, Types.DraftDataRoomDataNodesQueryVariables>(DraftDataRoomDataNodesDocument, options);
      }
export function useDraftDataRoomDataNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomDataNodesQuery, Types.DraftDataRoomDataNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomDataNodesQuery, Types.DraftDataRoomDataNodesQueryVariables>(DraftDataRoomDataNodesDocument, options);
        }
export type DraftDataRoomDataNodesQueryHookResult = ReturnType<typeof useDraftDataRoomDataNodesQuery>;
export type DraftDataRoomDataNodesLazyQueryHookResult = ReturnType<typeof useDraftDataRoomDataNodesLazyQuery>;
export type DraftDataRoomDataNodesQueryResult = Apollo.QueryResult<Types.DraftDataRoomDataNodesQuery, Types.DraftDataRoomDataNodesQueryVariables>;
export const DraftDataRoomTestPublicationsDocument = gql`
    query DraftDataRoomTestPublications($dataRoomId: String!) {
  draftDataRoom(id: $dataRoomId) {
    id
    draftNodes {
      nodes {
        id
        ... on DraftTableLeafNode {
          testModePublication {
            dataset {
              id
              manifestHash
            }
          }
        }
        ... on DraftRawLeafNode {
          testModePublication {
            dataset {
              id
              manifestHash
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDraftDataRoomTestPublicationsQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomTestPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomTestPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomTestPublicationsQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDraftDataRoomTestPublicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomTestPublicationsQuery, Types.DraftDataRoomTestPublicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomTestPublicationsQuery, Types.DraftDataRoomTestPublicationsQueryVariables>(DraftDataRoomTestPublicationsDocument, options);
      }
export function useDraftDataRoomTestPublicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomTestPublicationsQuery, Types.DraftDataRoomTestPublicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomTestPublicationsQuery, Types.DraftDataRoomTestPublicationsQueryVariables>(DraftDataRoomTestPublicationsDocument, options);
        }
export type DraftDataRoomTestPublicationsQueryHookResult = ReturnType<typeof useDraftDataRoomTestPublicationsQuery>;
export type DraftDataRoomTestPublicationsLazyQueryHookResult = ReturnType<typeof useDraftDataRoomTestPublicationsLazyQuery>;
export type DraftDataRoomTestPublicationsQueryResult = Apollo.QueryResult<Types.DraftDataRoomTestPublicationsQuery, Types.DraftDataRoomTestPublicationsQueryVariables>;
export const PublishedDataRoomDataNodesDocument = gql`
    query PublishedDataRoomDataNodes($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    publishedNodes @client {
      __typename
      id
      name
      permissions {
        ... on PublishedAnalystPermission {
          participant {
            userEmail
          }
        }
        ... on PublishedDataOwnerPermission {
          participant {
            userEmail
          }
        }
      }
      ... on PublishedRawLeafNode {
        isRequired
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
        testDataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
      ... on PublishedTableLeafNode {
        isRequired
        columns {
          name
          isNullable
          dataType
          formatType
          hashWith
        }
        uniqueColumnIds
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
        testDataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedDataRoomDataNodesQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomDataNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomDataNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomDataNodesQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomDataNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomDataNodesQuery, Types.PublishedDataRoomDataNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomDataNodesQuery, Types.PublishedDataRoomDataNodesQueryVariables>(PublishedDataRoomDataNodesDocument, options);
      }
export function usePublishedDataRoomDataNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomDataNodesQuery, Types.PublishedDataRoomDataNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomDataNodesQuery, Types.PublishedDataRoomDataNodesQueryVariables>(PublishedDataRoomDataNodesDocument, options);
        }
export type PublishedDataRoomDataNodesQueryHookResult = ReturnType<typeof usePublishedDataRoomDataNodesQuery>;
export type PublishedDataRoomDataNodesLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomDataNodesLazyQuery>;
export type PublishedDataRoomDataNodesQueryResult = Apollo.QueryResult<Types.PublishedDataRoomDataNodesQuery, Types.PublishedDataRoomDataNodesQueryVariables>;
export const SetTestModePublicationDocument = gql`
    mutation SetTestModePublication($input: SetTestModePublicationInput!) {
  testModePublication {
    set(input: $input) {
      record {
        dataset {
          id
          manifestHash
          createdAt
          owner {
            email
          }
        }
      }
    }
  }
}
    `;
export type SetTestModePublicationMutationFn = Apollo.MutationFunction<Types.SetTestModePublicationMutation, Types.SetTestModePublicationMutationVariables>;

/**
 * __useSetTestModePublicationMutation__
 *
 * To run a mutation, you first call `useSetTestModePublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTestModePublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTestModePublicationMutation, { data, loading, error }] = useSetTestModePublicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTestModePublicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetTestModePublicationMutation, Types.SetTestModePublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetTestModePublicationMutation, Types.SetTestModePublicationMutationVariables>(SetTestModePublicationDocument, options);
      }
export type SetTestModePublicationMutationHookResult = ReturnType<typeof useSetTestModePublicationMutation>;
export type SetTestModePublicationMutationResult = Apollo.MutationResult<Types.SetTestModePublicationMutation>;
export type SetTestModePublicationMutationOptions = Apollo.BaseMutationOptions<Types.SetTestModePublicationMutation, Types.SetTestModePublicationMutationVariables>;
export const TestModePublicationDocument = gql`
    query TestModePublication($id: NodeId!) {
  testModePublication(id: $id) {
    dataset {
      id
      manifestHash
      name
      description
      owner {
        id
        email
      }
      createdAt
      usage
    }
  }
}
    `;

/**
 * __useTestModePublicationQuery__
 *
 * To run a query within a React component, call `useTestModePublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestModePublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestModePublicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestModePublicationQuery(baseOptions: Apollo.QueryHookOptions<Types.TestModePublicationQuery, Types.TestModePublicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestModePublicationQuery, Types.TestModePublicationQueryVariables>(TestModePublicationDocument, options);
      }
export function useTestModePublicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestModePublicationQuery, Types.TestModePublicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestModePublicationQuery, Types.TestModePublicationQueryVariables>(TestModePublicationDocument, options);
        }
export type TestModePublicationQueryHookResult = ReturnType<typeof useTestModePublicationQuery>;
export type TestModePublicationLazyQueryHookResult = ReturnType<typeof useTestModePublicationLazyQuery>;
export type TestModePublicationQueryResult = Apollo.QueryResult<Types.TestModePublicationQuery, Types.TestModePublicationQueryVariables>;
export const UnpublishDatasetDocument = gql`
    mutation UnpublishDataset($input: UnpublishDatasetInput!) {
  unpublishDataset(input: $input) @client {
    id
  }
}
    `;
export type UnpublishDatasetMutationFn = Apollo.MutationFunction<Types.UnpublishDatasetMutation, Types.UnpublishDatasetMutationVariables>;

/**
 * __useUnpublishDatasetMutation__
 *
 * To run a mutation, you first call `useUnpublishDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishDatasetMutation, { data, loading, error }] = useUnpublishDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnpublishDatasetMutation, Types.UnpublishDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnpublishDatasetMutation, Types.UnpublishDatasetMutationVariables>(UnpublishDatasetDocument, options);
      }
export type UnpublishDatasetMutationHookResult = ReturnType<typeof useUnpublishDatasetMutation>;
export type UnpublishDatasetMutationResult = Apollo.MutationResult<Types.UnpublishDatasetMutation>;
export type UnpublishDatasetMutationOptions = Apollo.BaseMutationOptions<Types.UnpublishDatasetMutation, Types.UnpublishDatasetMutationVariables>;
export const UpdateDraftRawLeafNodeDocument = gql`
    mutation UpdateDraftRawLeafNode($input: UpdateDraftRawLeafNodeInput!) {
  draftRawLeafNode {
    update(input: $input) {
      id
      name
      isRequired
    }
  }
}
    `;
export type UpdateDraftRawLeafNodeMutationFn = Apollo.MutationFunction<Types.UpdateDraftRawLeafNodeMutation, Types.UpdateDraftRawLeafNodeMutationVariables>;

/**
 * __useUpdateDraftRawLeafNodeMutation__
 *
 * To run a mutation, you first call `useUpdateDraftRawLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftRawLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftRawLeafNodeMutation, { data, loading, error }] = useUpdateDraftRawLeafNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftRawLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftRawLeafNodeMutation, Types.UpdateDraftRawLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftRawLeafNodeMutation, Types.UpdateDraftRawLeafNodeMutationVariables>(UpdateDraftRawLeafNodeDocument, options);
      }
export type UpdateDraftRawLeafNodeMutationHookResult = ReturnType<typeof useUpdateDraftRawLeafNodeMutation>;
export type UpdateDraftRawLeafNodeMutationResult = Apollo.MutationResult<Types.UpdateDraftRawLeafNodeMutation>;
export type UpdateDraftRawLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftRawLeafNodeMutation, Types.UpdateDraftRawLeafNodeMutationVariables>;
export const UpdateDraftRawLeafNodeNameDocument = gql`
    mutation UpdateDraftRawLeafNodeName($input: UpdateDraftRawLeafNodeInput!) {
  draftRawLeafNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type UpdateDraftRawLeafNodeNameMutationFn = Apollo.MutationFunction<Types.UpdateDraftRawLeafNodeNameMutation, Types.UpdateDraftRawLeafNodeNameMutationVariables>;

/**
 * __useUpdateDraftRawLeafNodeNameMutation__
 *
 * To run a mutation, you first call `useUpdateDraftRawLeafNodeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftRawLeafNodeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftRawLeafNodeNameMutation, { data, loading, error }] = useUpdateDraftRawLeafNodeNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftRawLeafNodeNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftRawLeafNodeNameMutation, Types.UpdateDraftRawLeafNodeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftRawLeafNodeNameMutation, Types.UpdateDraftRawLeafNodeNameMutationVariables>(UpdateDraftRawLeafNodeNameDocument, options);
      }
export type UpdateDraftRawLeafNodeNameMutationHookResult = ReturnType<typeof useUpdateDraftRawLeafNodeNameMutation>;
export type UpdateDraftRawLeafNodeNameMutationResult = Apollo.MutationResult<Types.UpdateDraftRawLeafNodeNameMutation>;
export type UpdateDraftRawLeafNodeNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftRawLeafNodeNameMutation, Types.UpdateDraftRawLeafNodeNameMutationVariables>;
export const UpdateDraftTableLeafNodeDocument = gql`
    mutation UpdateDraftTableLeafNode($input: UpdateDraftTableLeafNodeInput!) {
  draftTableLeafNode {
    update(input: $input) {
      id
      name
      isRequired
      columnsOrder
    }
  }
}
    `;
export type UpdateDraftTableLeafNodeMutationFn = Apollo.MutationFunction<Types.UpdateDraftTableLeafNodeMutation, Types.UpdateDraftTableLeafNodeMutationVariables>;

/**
 * __useUpdateDraftTableLeafNodeMutation__
 *
 * To run a mutation, you first call `useUpdateDraftTableLeafNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftTableLeafNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftTableLeafNodeMutation, { data, loading, error }] = useUpdateDraftTableLeafNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftTableLeafNodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftTableLeafNodeMutation, Types.UpdateDraftTableLeafNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftTableLeafNodeMutation, Types.UpdateDraftTableLeafNodeMutationVariables>(UpdateDraftTableLeafNodeDocument, options);
      }
export type UpdateDraftTableLeafNodeMutationHookResult = ReturnType<typeof useUpdateDraftTableLeafNodeMutation>;
export type UpdateDraftTableLeafNodeMutationResult = Apollo.MutationResult<Types.UpdateDraftTableLeafNodeMutation>;
export type UpdateDraftTableLeafNodeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftTableLeafNodeMutation, Types.UpdateDraftTableLeafNodeMutationVariables>;
export const UpdateDraftTableLeafNodeColumnDocument = gql`
    mutation UpdateDraftTableLeafNodeColumn($input: UpdateDraftTableLeafNodeColumnInput!) {
  draftTableLeafNode {
    updateColumn(input: $input) {
      id
      name
      dataType
      formatType
      isNullable
      hashWith
    }
  }
}
    `;
export type UpdateDraftTableLeafNodeColumnMutationFn = Apollo.MutationFunction<Types.UpdateDraftTableLeafNodeColumnMutation, Types.UpdateDraftTableLeafNodeColumnMutationVariables>;

/**
 * __useUpdateDraftTableLeafNodeColumnMutation__
 *
 * To run a mutation, you first call `useUpdateDraftTableLeafNodeColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftTableLeafNodeColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftTableLeafNodeColumnMutation, { data, loading, error }] = useUpdateDraftTableLeafNodeColumnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftTableLeafNodeColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftTableLeafNodeColumnMutation, Types.UpdateDraftTableLeafNodeColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftTableLeafNodeColumnMutation, Types.UpdateDraftTableLeafNodeColumnMutationVariables>(UpdateDraftTableLeafNodeColumnDocument, options);
      }
export type UpdateDraftTableLeafNodeColumnMutationHookResult = ReturnType<typeof useUpdateDraftTableLeafNodeColumnMutation>;
export type UpdateDraftTableLeafNodeColumnMutationResult = Apollo.MutationResult<Types.UpdateDraftTableLeafNodeColumnMutation>;
export type UpdateDraftTableLeafNodeColumnMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftTableLeafNodeColumnMutation, Types.UpdateDraftTableLeafNodeColumnMutationVariables>;
export const UpdateDraftTableLeafNodeNameDocument = gql`
    mutation UpdateDraftTableLeafNodeName($input: UpdateDraftTableLeafNodeInput!) {
  draftTableLeafNode {
    update(input: $input) {
      id
      name
    }
  }
}
    `;
export type UpdateDraftTableLeafNodeNameMutationFn = Apollo.MutationFunction<Types.UpdateDraftTableLeafNodeNameMutation, Types.UpdateDraftTableLeafNodeNameMutationVariables>;

/**
 * __useUpdateDraftTableLeafNodeNameMutation__
 *
 * To run a mutation, you first call `useUpdateDraftTableLeafNodeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftTableLeafNodeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftTableLeafNodeNameMutation, { data, loading, error }] = useUpdateDraftTableLeafNodeNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftTableLeafNodeNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftTableLeafNodeNameMutation, Types.UpdateDraftTableLeafNodeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftTableLeafNodeNameMutation, Types.UpdateDraftTableLeafNodeNameMutationVariables>(UpdateDraftTableLeafNodeNameDocument, options);
      }
export type UpdateDraftTableLeafNodeNameMutationHookResult = ReturnType<typeof useUpdateDraftTableLeafNodeNameMutation>;
export type UpdateDraftTableLeafNodeNameMutationResult = Apollo.MutationResult<Types.UpdateDraftTableLeafNodeNameMutation>;
export type UpdateDraftTableLeafNodeNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftTableLeafNodeNameMutation, Types.UpdateDraftTableLeafNodeNameMutationVariables>;
export const CreateDataPartnerDisplayConfigurationDocument = gql`
    mutation CreateDataPartnerDisplayConfiguration($input: CreateDataPartnerDisplayConfigurationInput!) {
  dataPartnerDisplayConfiguration {
    create(input: $input) {
      record {
        ...DataPartnerDisplayConfiguration
      }
    }
  }
}
    ${DataPartnerDisplayConfigurationFragment}`;
export type CreateDataPartnerDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.CreateDataPartnerDisplayConfigurationMutation, Types.CreateDataPartnerDisplayConfigurationMutationVariables>;

/**
 * __useCreateDataPartnerDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateDataPartnerDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataPartnerDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataPartnerDisplayConfigurationMutation, { data, loading, error }] = useCreateDataPartnerDisplayConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataPartnerDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataPartnerDisplayConfigurationMutation, Types.CreateDataPartnerDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDataPartnerDisplayConfigurationMutation, Types.CreateDataPartnerDisplayConfigurationMutationVariables>(CreateDataPartnerDisplayConfigurationDocument, options);
      }
export type CreateDataPartnerDisplayConfigurationMutationHookResult = ReturnType<typeof useCreateDataPartnerDisplayConfigurationMutation>;
export type CreateDataPartnerDisplayConfigurationMutationResult = Apollo.MutationResult<Types.CreateDataPartnerDisplayConfigurationMutation>;
export type CreateDataPartnerDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataPartnerDisplayConfigurationMutation, Types.CreateDataPartnerDisplayConfigurationMutationVariables>;
export const CreateDataPartnerUsageConfigurationDocument = gql`
    mutation CreateDataPartnerUsageConfiguration($input: CreateDataPartnerUsageConfigurationInput!) {
  dataPartnerUsageConfiguration {
    create(input: $input) {
      record {
        ...DataPartnerUsageConfiguration
      }
    }
  }
}
    ${DataPartnerUsageConfigurationFragment}`;
export type CreateDataPartnerUsageConfigurationMutationFn = Apollo.MutationFunction<Types.CreateDataPartnerUsageConfigurationMutation, Types.CreateDataPartnerUsageConfigurationMutationVariables>;

/**
 * __useCreateDataPartnerUsageConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateDataPartnerUsageConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataPartnerUsageConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataPartnerUsageConfigurationMutation, { data, loading, error }] = useCreateDataPartnerUsageConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataPartnerUsageConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataPartnerUsageConfigurationMutation, Types.CreateDataPartnerUsageConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDataPartnerUsageConfigurationMutation, Types.CreateDataPartnerUsageConfigurationMutationVariables>(CreateDataPartnerUsageConfigurationDocument, options);
      }
export type CreateDataPartnerUsageConfigurationMutationHookResult = ReturnType<typeof useCreateDataPartnerUsageConfigurationMutation>;
export type CreateDataPartnerUsageConfigurationMutationResult = Apollo.MutationResult<Types.CreateDataPartnerUsageConfigurationMutation>;
export type CreateDataPartnerUsageConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataPartnerUsageConfigurationMutation, Types.CreateDataPartnerUsageConfigurationMutationVariables>;
export const DataPartnerDisplayConfigurationDocument = gql`
    query DataPartnerDisplayConfiguration($id: String!, $isAdmin: Boolean!) {
  ownOrganization: myself @skip(if: $isAdmin) {
    organization {
      dataPartnerDisplayConfiguration {
        ...DataPartnerDisplayConfiguration
      }
    }
  }
  otherOrganization: organization(id: $id) @include(if: $isAdmin) {
    dataPartnerDisplayConfiguration {
      ...DataPartnerDisplayConfiguration
    }
  }
}
    ${DataPartnerDisplayConfigurationFragment}`;

/**
 * __useDataPartnerDisplayConfigurationQuery__
 *
 * To run a query within a React component, call `useDataPartnerDisplayConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPartnerDisplayConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPartnerDisplayConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useDataPartnerDisplayConfigurationQuery(baseOptions: Apollo.QueryHookOptions<Types.DataPartnerDisplayConfigurationQuery, Types.DataPartnerDisplayConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataPartnerDisplayConfigurationQuery, Types.DataPartnerDisplayConfigurationQueryVariables>(DataPartnerDisplayConfigurationDocument, options);
      }
export function useDataPartnerDisplayConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPartnerDisplayConfigurationQuery, Types.DataPartnerDisplayConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataPartnerDisplayConfigurationQuery, Types.DataPartnerDisplayConfigurationQueryVariables>(DataPartnerDisplayConfigurationDocument, options);
        }
export type DataPartnerDisplayConfigurationQueryHookResult = ReturnType<typeof useDataPartnerDisplayConfigurationQuery>;
export type DataPartnerDisplayConfigurationLazyQueryHookResult = ReturnType<typeof useDataPartnerDisplayConfigurationLazyQuery>;
export type DataPartnerDisplayConfigurationQueryResult = Apollo.QueryResult<Types.DataPartnerDisplayConfigurationQuery, Types.DataPartnerDisplayConfigurationQueryVariables>;
export const DataPartnerUsageConfigurationsDocument = gql`
    query DataPartnerUsageConfigurations($id: String!, $isAdmin: Boolean!) {
  ownOrganization: myself @skip(if: $isAdmin) {
    organization {
      dataPartnerUsageConfigurations {
        nodes {
          ...DataPartnerUsageConfiguration
        }
        totalCount
      }
    }
  }
  otherOrganization: organization(id: $id) @include(if: $isAdmin) {
    dataPartnerUsageConfigurations {
      nodes {
        ...DataPartnerUsageConfiguration
      }
      totalCount
    }
  }
}
    ${DataPartnerUsageConfigurationFragment}`;

/**
 * __useDataPartnerUsageConfigurationsQuery__
 *
 * To run a query within a React component, call `useDataPartnerUsageConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPartnerUsageConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPartnerUsageConfigurationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useDataPartnerUsageConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<Types.DataPartnerUsageConfigurationsQuery, Types.DataPartnerUsageConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataPartnerUsageConfigurationsQuery, Types.DataPartnerUsageConfigurationsQueryVariables>(DataPartnerUsageConfigurationsDocument, options);
      }
export function useDataPartnerUsageConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPartnerUsageConfigurationsQuery, Types.DataPartnerUsageConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataPartnerUsageConfigurationsQuery, Types.DataPartnerUsageConfigurationsQueryVariables>(DataPartnerUsageConfigurationsDocument, options);
        }
export type DataPartnerUsageConfigurationsQueryHookResult = ReturnType<typeof useDataPartnerUsageConfigurationsQuery>;
export type DataPartnerUsageConfigurationsLazyQueryHookResult = ReturnType<typeof useDataPartnerUsageConfigurationsLazyQuery>;
export type DataPartnerUsageConfigurationsQueryResult = Apollo.QueryResult<Types.DataPartnerUsageConfigurationsQuery, Types.DataPartnerUsageConfigurationsQueryVariables>;
export const DeleteDataPartnerDisplayConfigurationDocument = gql`
    mutation DeleteDataPartnerDisplayConfiguration($id: String!) {
  dataPartnerDisplayConfiguration {
    delete(id: $id)
  }
}
    `;
export type DeleteDataPartnerDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.DeleteDataPartnerDisplayConfigurationMutation, Types.DeleteDataPartnerDisplayConfigurationMutationVariables>;

/**
 * __useDeleteDataPartnerDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteDataPartnerDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataPartnerDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataPartnerDisplayConfigurationMutation, { data, loading, error }] = useDeleteDataPartnerDisplayConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataPartnerDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDataPartnerDisplayConfigurationMutation, Types.DeleteDataPartnerDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDataPartnerDisplayConfigurationMutation, Types.DeleteDataPartnerDisplayConfigurationMutationVariables>(DeleteDataPartnerDisplayConfigurationDocument, options);
      }
export type DeleteDataPartnerDisplayConfigurationMutationHookResult = ReturnType<typeof useDeleteDataPartnerDisplayConfigurationMutation>;
export type DeleteDataPartnerDisplayConfigurationMutationResult = Apollo.MutationResult<Types.DeleteDataPartnerDisplayConfigurationMutation>;
export type DeleteDataPartnerDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDataPartnerDisplayConfigurationMutation, Types.DeleteDataPartnerDisplayConfigurationMutationVariables>;
export const DeleteDataPartnerUsageConfigurationDocument = gql`
    mutation DeleteDataPartnerUsageConfiguration($id: String!) {
  dataPartnerUsageConfiguration {
    delete(id: $id)
  }
}
    `;
export type DeleteDataPartnerUsageConfigurationMutationFn = Apollo.MutationFunction<Types.DeleteDataPartnerUsageConfigurationMutation, Types.DeleteDataPartnerUsageConfigurationMutationVariables>;

/**
 * __useDeleteDataPartnerUsageConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteDataPartnerUsageConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataPartnerUsageConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataPartnerUsageConfigurationMutation, { data, loading, error }] = useDeleteDataPartnerUsageConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataPartnerUsageConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDataPartnerUsageConfigurationMutation, Types.DeleteDataPartnerUsageConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDataPartnerUsageConfigurationMutation, Types.DeleteDataPartnerUsageConfigurationMutationVariables>(DeleteDataPartnerUsageConfigurationDocument, options);
      }
export type DeleteDataPartnerUsageConfigurationMutationHookResult = ReturnType<typeof useDeleteDataPartnerUsageConfigurationMutation>;
export type DeleteDataPartnerUsageConfigurationMutationResult = Apollo.MutationResult<Types.DeleteDataPartnerUsageConfigurationMutation>;
export type DeleteDataPartnerUsageConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDataPartnerUsageConfigurationMutation, Types.DeleteDataPartnerUsageConfigurationMutationVariables>;
export const UpdateDataPartnerDisplayConfigurationDocument = gql`
    mutation UpdateDataPartnerDisplayConfiguration($input: UpdateDataPartnerDisplayConfigurationInput!) {
  dataPartnerDisplayConfiguration {
    update(input: $input) {
      record {
        ...DataPartnerDisplayConfiguration
      }
    }
  }
}
    ${DataPartnerDisplayConfigurationFragment}`;
export type UpdateDataPartnerDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.UpdateDataPartnerDisplayConfigurationMutation, Types.UpdateDataPartnerDisplayConfigurationMutationVariables>;

/**
 * __useUpdateDataPartnerDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateDataPartnerDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPartnerDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPartnerDisplayConfigurationMutation, { data, loading, error }] = useUpdateDataPartnerDisplayConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataPartnerDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDataPartnerDisplayConfigurationMutation, Types.UpdateDataPartnerDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDataPartnerDisplayConfigurationMutation, Types.UpdateDataPartnerDisplayConfigurationMutationVariables>(UpdateDataPartnerDisplayConfigurationDocument, options);
      }
export type UpdateDataPartnerDisplayConfigurationMutationHookResult = ReturnType<typeof useUpdateDataPartnerDisplayConfigurationMutation>;
export type UpdateDataPartnerDisplayConfigurationMutationResult = Apollo.MutationResult<Types.UpdateDataPartnerDisplayConfigurationMutation>;
export type UpdateDataPartnerDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDataPartnerDisplayConfigurationMutation, Types.UpdateDataPartnerDisplayConfigurationMutationVariables>;
export const DatasetSchemaDocument = gql`
    query DatasetSchema($datasetId: String!) {
  dataset(id: $datasetId) {
    id
    statistics
  }
}
    `;

/**
 * __useDatasetSchemaQuery__
 *
 * To run a query within a React component, call `useDatasetSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetSchemaQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useDatasetSchemaQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetSchemaQuery, Types.DatasetSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetSchemaQuery, Types.DatasetSchemaQueryVariables>(DatasetSchemaDocument, options);
      }
export function useDatasetSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetSchemaQuery, Types.DatasetSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetSchemaQuery, Types.DatasetSchemaQueryVariables>(DatasetSchemaDocument, options);
        }
export type DatasetSchemaQueryHookResult = ReturnType<typeof useDatasetSchemaQuery>;
export type DatasetSchemaLazyQueryHookResult = ReturnType<typeof useDatasetSchemaLazyQuery>;
export type DatasetSchemaQueryResult = Apollo.QueryResult<Types.DatasetSchemaQuery, Types.DatasetSchemaQueryVariables>;
export const DatasetManifestHashDocument = gql`
    query DatasetManifestHash($manifestHash: HexString!) {
  datasetByManifestHash(manifestHash: $manifestHash) {
    id
    manifestHash
    publications {
      nodes {
        dataRoom {
          id
          driverAttestationHash
        }
        leaf
      }
    }
  }
}
    `;

/**
 * __useDatasetManifestHashQuery__
 *
 * To run a query within a React component, call `useDatasetManifestHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetManifestHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetManifestHashQuery({
 *   variables: {
 *      manifestHash: // value for 'manifestHash'
 *   },
 * });
 */
export function useDatasetManifestHashQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetManifestHashQuery, Types.DatasetManifestHashQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetManifestHashQuery, Types.DatasetManifestHashQueryVariables>(DatasetManifestHashDocument, options);
      }
export function useDatasetManifestHashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetManifestHashQuery, Types.DatasetManifestHashQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetManifestHashQuery, Types.DatasetManifestHashQueryVariables>(DatasetManifestHashDocument, options);
        }
export type DatasetManifestHashQueryHookResult = ReturnType<typeof useDatasetManifestHashQuery>;
export type DatasetManifestHashLazyQueryHookResult = ReturnType<typeof useDatasetManifestHashLazyQuery>;
export type DatasetManifestHashQueryResult = Apollo.QueryResult<Types.DatasetManifestHashQuery, Types.DatasetManifestHashQueryVariables>;
export const DeleteDatasetDocument = gql`
    mutation DeleteDataset($input: DeleteDatasetInput!) {
  deleteDataset(input: $input) @client {
    id
  }
}
    `;
export type DeleteDatasetMutationFn = Apollo.MutationFunction<Types.DeleteDatasetMutation, Types.DeleteDatasetMutationVariables>;

/**
 * __useDeleteDatasetMutation__
 *
 * To run a mutation, you first call `useDeleteDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDatasetMutation, { data, loading, error }] = useDeleteDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDatasetMutation, Types.DeleteDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDatasetMutation, Types.DeleteDatasetMutationVariables>(DeleteDatasetDocument, options);
      }
export type DeleteDatasetMutationHookResult = ReturnType<typeof useDeleteDatasetMutation>;
export type DeleteDatasetMutationResult = Apollo.MutationResult<Types.DeleteDatasetMutation>;
export type DeleteDatasetMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDatasetMutation, Types.DeleteDatasetMutationVariables>;
export const ExternalConnectionsDocument = gql`
    query ExternalConnections($datasetImportsFilter: DatasetImportFilter, $datasetExportsFilter: DatasetExportFilter) {
  datasetImports(filter: $datasetImportsFilter) {
    nodes {
      ...DatasetImport
    }
  }
  datasetExports(filter: $datasetExportsFilter) {
    nodes {
      ...DatasetExport
    }
  }
}
    ${DatasetImportFragment}
${DatasetExportFragment}`;

/**
 * __useExternalConnectionsQuery__
 *
 * To run a query within a React component, call `useExternalConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalConnectionsQuery({
 *   variables: {
 *      datasetImportsFilter: // value for 'datasetImportsFilter'
 *      datasetExportsFilter: // value for 'datasetExportsFilter'
 *   },
 * });
 */
export function useExternalConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ExternalConnectionsQuery, Types.ExternalConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExternalConnectionsQuery, Types.ExternalConnectionsQueryVariables>(ExternalConnectionsDocument, options);
      }
export function useExternalConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExternalConnectionsQuery, Types.ExternalConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExternalConnectionsQuery, Types.ExternalConnectionsQueryVariables>(ExternalConnectionsDocument, options);
        }
export type ExternalConnectionsQueryHookResult = ReturnType<typeof useExternalConnectionsQuery>;
export type ExternalConnectionsLazyQueryHookResult = ReturnType<typeof useExternalConnectionsLazyQuery>;
export type ExternalConnectionsQueryResult = Apollo.QueryResult<Types.ExternalConnectionsQuery, Types.ExternalConnectionsQueryVariables>;
export const DatasetExportResultDocument = gql`
    query DatasetExportResult($id: String!) {
  datasetExport(id: $id) {
    id
    result @client {
      success
      error
    }
  }
}
    `;

/**
 * __useDatasetExportResultQuery__
 *
 * To run a query within a React component, call `useDatasetExportResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetExportResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetExportResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatasetExportResultQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetExportResultQuery, Types.DatasetExportResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetExportResultQuery, Types.DatasetExportResultQueryVariables>(DatasetExportResultDocument, options);
      }
export function useDatasetExportResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetExportResultQuery, Types.DatasetExportResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetExportResultQuery, Types.DatasetExportResultQueryVariables>(DatasetExportResultDocument, options);
        }
export type DatasetExportResultQueryHookResult = ReturnType<typeof useDatasetExportResultQuery>;
export type DatasetExportResultLazyQueryHookResult = ReturnType<typeof useDatasetExportResultLazyQuery>;
export type DatasetExportResultQueryResult = Apollo.QueryResult<Types.DatasetExportResultQuery, Types.DatasetExportResultQueryVariables>;
export const DatasetImportResultDocument = gql`
    query DatasetImportResult($id: String!) {
  datasetImport(id: $id) {
    id
    result @client {
      success
      error
    }
  }
}
    `;

/**
 * __useDatasetImportResultQuery__
 *
 * To run a query within a React component, call `useDatasetImportResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetImportResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetImportResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatasetImportResultQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetImportResultQuery, Types.DatasetImportResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetImportResultQuery, Types.DatasetImportResultQueryVariables>(DatasetImportResultDocument, options);
      }
export function useDatasetImportResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetImportResultQuery, Types.DatasetImportResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetImportResultQuery, Types.DatasetImportResultQueryVariables>(DatasetImportResultDocument, options);
        }
export type DatasetImportResultQueryHookResult = ReturnType<typeof useDatasetImportResultQuery>;
export type DatasetImportResultLazyQueryHookResult = ReturnType<typeof useDatasetImportResultLazyQuery>;
export type DatasetImportResultQueryResult = Apollo.QueryResult<Types.DatasetImportResultQuery, Types.DatasetImportResultQueryVariables>;
export const DatasetsDocument = gql`
    query Datasets {
  datasets {
    nodes {
      id
      name
      owner {
        email
        id
      }
      createdAt
      datasetImport {
        config
      }
      statistics
      manifestHash
      description
      size
      sourceType
      publications {
        nodes {
          dataRoom {
            id
            title
            driverAttestationHash
          }
          leaf
        }
      }
    }
    totalCount @client
  }
}
    `;

/**
 * __useDatasetsQuery__
 *
 * To run a query within a React component, call `useDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatasetsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DatasetsQuery, Types.DatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetsQuery, Types.DatasetsQueryVariables>(DatasetsDocument, options);
      }
export function useDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetsQuery, Types.DatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetsQuery, Types.DatasetsQueryVariables>(DatasetsDocument, options);
        }
export type DatasetsQueryHookResult = ReturnType<typeof useDatasetsQuery>;
export type DatasetsLazyQueryHookResult = ReturnType<typeof useDatasetsLazyQuery>;
export type DatasetsQueryResult = Apollo.QueryResult<Types.DatasetsQuery, Types.DatasetsQueryVariables>;
export const DatasetDocument = gql`
    query Dataset($datasetId: String!) {
  dataset(id: $datasetId) {
    id
    name
    isOwner @client
    owner {
      id
      email
    }
    createdAt
    statistics
    manifestHash
    description
    size
    publications {
      nodes {
        dataRoom {
          id
          title
          driverAttestationHash
        }
        leaf
      }
    }
  }
}
    `;

/**
 * __useDatasetQuery__
 *
 * To run a query within a React component, call `useDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useDatasetQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetQuery, Types.DatasetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetQuery, Types.DatasetQueryVariables>(DatasetDocument, options);
      }
export function useDatasetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetQuery, Types.DatasetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetQuery, Types.DatasetQueryVariables>(DatasetDocument, options);
        }
export type DatasetQueryHookResult = ReturnType<typeof useDatasetQuery>;
export type DatasetLazyQueryHookResult = ReturnType<typeof useDatasetLazyQuery>;
export type DatasetQueryResult = Apollo.QueryResult<Types.DatasetQuery, Types.DatasetQueryVariables>;
export const DatasetByHashDocument = gql`
    query DatasetByHash($manifestHash: HexString!) {
  datasetByManifestHash(manifestHash: $manifestHash) {
    id
    name
    isOwner @client
    owner {
      id
      email
    }
    datasetImport {
      config
    }
    createdAt
    statistics
    manifestHash
    description
    size
    sourceType
    publications {
      nodes {
        dataRoom {
          id
          title
          driverAttestationHash
        }
        leaf
      }
    }
  }
}
    `;

/**
 * __useDatasetByHashQuery__
 *
 * To run a query within a React component, call `useDatasetByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetByHashQuery({
 *   variables: {
 *      manifestHash: // value for 'manifestHash'
 *   },
 * });
 */
export function useDatasetByHashQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetByHashQuery, Types.DatasetByHashQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetByHashQuery, Types.DatasetByHashQueryVariables>(DatasetByHashDocument, options);
      }
export function useDatasetByHashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetByHashQuery, Types.DatasetByHashQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetByHashQuery, Types.DatasetByHashQueryVariables>(DatasetByHashDocument, options);
        }
export type DatasetByHashQueryHookResult = ReturnType<typeof useDatasetByHashQuery>;
export type DatasetByHashLazyQueryHookResult = ReturnType<typeof useDatasetByHashLazyQuery>;
export type DatasetByHashQueryResult = Apollo.QueryResult<Types.DatasetByHashQuery, Types.DatasetByHashQueryVariables>;
export const DatasetStatisticsDocument = gql`
    query DatasetStatistics($datasetId: String!) {
  dataset(id: $datasetId) {
    id
    name
    statistics
    size
    createdAt
    owner {
      id
      email
    }
    isOwner @client
  }
}
    `;

/**
 * __useDatasetStatisticsQuery__
 *
 * To run a query within a React component, call `useDatasetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetStatisticsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useDatasetStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetStatisticsQuery, Types.DatasetStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetStatisticsQuery, Types.DatasetStatisticsQueryVariables>(DatasetStatisticsDocument, options);
      }
export function useDatasetStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetStatisticsQuery, Types.DatasetStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetStatisticsQuery, Types.DatasetStatisticsQueryVariables>(DatasetStatisticsDocument, options);
        }
export type DatasetStatisticsQueryHookResult = ReturnType<typeof useDatasetStatisticsQuery>;
export type DatasetStatisticsLazyQueryHookResult = ReturnType<typeof useDatasetStatisticsLazyQuery>;
export type DatasetStatisticsQueryResult = Apollo.QueryResult<Types.DatasetStatisticsQuery, Types.DatasetStatisticsQueryVariables>;
export const DevComputeNodesDocument = gql`
    query DevComputeNodes($id: String!) {
  publishedDataRoom(id: $id) {
    id
    playgrounds {
      nodes {
        id
        owner {
          email
        }
        draftNode {
          id
          name
          ... on DraftMatchNode {
            computationType
          }
          ... on DraftSyntheticNode {
            computationType
          }
          ... on DraftPostNode {
            computationType
          }
          ... on DraftPreviewNode {
            computationType
          }
          ... on DraftS3SinkNode {
            computationType
          }
          ... on DraftScriptingNode {
            scriptingLanguage
            computationType
          }
          ... on DraftSqlNode {
            computationType
            privacyFilter {
              minimumRows
            }
          }
          ... on DraftSqliteNode {
            computationType
            privacyFilter {
              minimumRows
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDevComputeNodesQuery__
 *
 * To run a query within a React component, call `useDevComputeNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevComputeNodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDevComputeNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.DevComputeNodesQuery, Types.DevComputeNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DevComputeNodesQuery, Types.DevComputeNodesQueryVariables>(DevComputeNodesDocument, options);
      }
export function useDevComputeNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DevComputeNodesQuery, Types.DevComputeNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DevComputeNodesQuery, Types.DevComputeNodesQueryVariables>(DevComputeNodesDocument, options);
        }
export type DevComputeNodesQueryHookResult = ReturnType<typeof useDevComputeNodesQuery>;
export type DevComputeNodesLazyQueryHookResult = ReturnType<typeof useDevComputeNodesLazyQuery>;
export type DevComputeNodesQueryResult = Apollo.QueryResult<Types.DevComputeNodesQuery, Types.DevComputeNodesQueryVariables>;
export const CreateDataRoomPlaygroundDocument = gql`
    mutation CreateDataRoomPlayground($input: CreateDataRoomPlaygroundInput!) {
  playground {
    create(input: $input) {
      id
    }
  }
}
    `;
export type CreateDataRoomPlaygroundMutationFn = Apollo.MutationFunction<Types.CreateDataRoomPlaygroundMutation, Types.CreateDataRoomPlaygroundMutationVariables>;

/**
 * __useCreateDataRoomPlaygroundMutation__
 *
 * To run a mutation, you first call `useCreateDataRoomPlaygroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataRoomPlaygroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataRoomPlaygroundMutation, { data, loading, error }] = useCreateDataRoomPlaygroundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataRoomPlaygroundMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataRoomPlaygroundMutation, Types.CreateDataRoomPlaygroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDataRoomPlaygroundMutation, Types.CreateDataRoomPlaygroundMutationVariables>(CreateDataRoomPlaygroundDocument, options);
      }
export type CreateDataRoomPlaygroundMutationHookResult = ReturnType<typeof useCreateDataRoomPlaygroundMutation>;
export type CreateDataRoomPlaygroundMutationResult = Apollo.MutationResult<Types.CreateDataRoomPlaygroundMutation>;
export type CreateDataRoomPlaygroundMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataRoomPlaygroundMutation, Types.CreateDataRoomPlaygroundMutationVariables>;
export const DraftDataRoomActionsDocument = gql`
    query DraftDataRoomActions($id: String!) {
  draftDataRoom(id: $id) {
    id
    name: title
    logo
    showOrganizationLogo
    requirePassword
    password @client
    userSettings {
      id
      isArchived
      isFavorite
    }
    owner {
      id
      email
      organizationLogo
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useDraftDataRoomActionsQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomActionsQuery, Types.DraftDataRoomActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomActionsQuery, Types.DraftDataRoomActionsQueryVariables>(DraftDataRoomActionsDocument, options);
      }
export function useDraftDataRoomActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomActionsQuery, Types.DraftDataRoomActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomActionsQuery, Types.DraftDataRoomActionsQueryVariables>(DraftDataRoomActionsDocument, options);
        }
export type DraftDataRoomActionsQueryHookResult = ReturnType<typeof useDraftDataRoomActionsQuery>;
export type DraftDataRoomActionsLazyQueryHookResult = ReturnType<typeof useDraftDataRoomActionsLazyQuery>;
export type DraftDataRoomActionsQueryResult = Apollo.QueryResult<Types.DraftDataRoomActionsQuery, Types.DraftDataRoomActionsQueryVariables>;
export const DraftDataRoomAsJsonDocument = gql`
    query DraftDataRoomAsJson($id: String!) {
  draftDataRoom(id: $id) {
    id
    title
    description
    enableDevelopment
    enableInteractivity
    logo
    dataNodesOrder
    computeNodesOrder
    draftNodes {
      nodes {
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              participant {
                id
                userEmail
              }
            }
          }
        }
        ... on DraftScriptingNode {
          id
          name
          computationType
          scripts {
            nodes {
              id
              name
              content
              isMainScript
            }
          }
          dependencies {
            nodes {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
            }
          }
          output
          scriptingLanguage
        }
        ... on DraftMatchNode {
          id
          name
          config
        }
        ... on DraftSqlNode {
          id
          name
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
        }
        ... on DraftSqliteNode {
          id
          name
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
        }
        ... on DraftSyntheticNode {
          id
          name
          accuracy
          includeReportWithStats
          computationType
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftPostNode {
          id
          name
          computationType
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftPreviewNode {
          id
          name
          quotaBytes
          computationType
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftS3SinkNode {
          id
          name
          endpoint
          computationType
          region
          credentialsDependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
          uploadDependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftTableLeafNode {
          id
          name
          isRequired
          columns {
            nodes {
              id
              name
              dataType
              isNullable
            }
          }
        }
        ... on DraftRawLeafNode {
          id
          name
          isRequired
        }
      }
    }
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftDataOwnerPermission {
              node {
                id
                name
              }
            }
            ... on DraftAnalystPermission {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
    owner {
      id
      email
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useDraftDataRoomAsJsonQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomAsJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomAsJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomAsJsonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomAsJsonQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomAsJsonQuery, Types.DraftDataRoomAsJsonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomAsJsonQuery, Types.DraftDataRoomAsJsonQueryVariables>(DraftDataRoomAsJsonDocument, options);
      }
export function useDraftDataRoomAsJsonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomAsJsonQuery, Types.DraftDataRoomAsJsonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomAsJsonQuery, Types.DraftDataRoomAsJsonQueryVariables>(DraftDataRoomAsJsonDocument, options);
        }
export type DraftDataRoomAsJsonQueryHookResult = ReturnType<typeof useDraftDataRoomAsJsonQuery>;
export type DraftDataRoomAsJsonLazyQueryHookResult = ReturnType<typeof useDraftDataRoomAsJsonLazyQuery>;
export type DraftDataRoomAsJsonQueryResult = Apollo.QueryResult<Types.DraftDataRoomAsJsonQuery, Types.DraftDataRoomAsJsonQueryVariables>;
export const DraftDataRoomDeleteDocument = gql`
    mutation DraftDataRoomDelete($id: String!) {
  draftDataRoom {
    delete(id: $id)
  }
}
    `;
export type DraftDataRoomDeleteMutationFn = Apollo.MutationFunction<Types.DraftDataRoomDeleteMutation, Types.DraftDataRoomDeleteMutationVariables>;

/**
 * __useDraftDataRoomDeleteMutation__
 *
 * To run a mutation, you first call `useDraftDataRoomDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftDataRoomDeleteMutation, { data, loading, error }] = useDraftDataRoomDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftDataRoomDeleteMutation, Types.DraftDataRoomDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftDataRoomDeleteMutation, Types.DraftDataRoomDeleteMutationVariables>(DraftDataRoomDeleteDocument, options);
      }
export type DraftDataRoomDeleteMutationHookResult = ReturnType<typeof useDraftDataRoomDeleteMutation>;
export type DraftDataRoomDeleteMutationResult = Apollo.MutationResult<Types.DraftDataRoomDeleteMutation>;
export type DraftDataRoomDeleteMutationOptions = Apollo.BaseMutationOptions<Types.DraftDataRoomDeleteMutation, Types.DraftDataRoomDeleteMutationVariables>;
export const DraftDataRoomDuplicateDocument = gql`
    mutation DraftDataRoomDuplicate($input: ImportDataScienceDataRoomInput!) {
  draftDataRoom {
    importDataScienceDataRoom(input: $input) {
      record {
        id
        title
        description
        owner {
          id
          email
        }
        logo
        enableInteractivity
        enableDevelopment
        userSettings {
          id
          isArchived
          isFavorite
        }
        createdAt
        draftNodes {
          nodes {
            id
            name
          }
        }
        editors {
          nodes {
            user {
              id
              email
            }
            role
          }
        }
        dataNodesOrder
        computeNodesOrder
        participants {
          nodes {
            id
            userEmail
            permissions {
              nodes {
                ... on DraftDataOwnerPermission {
                  node {
                    id
                    name
                  }
                }
                ... on DraftAnalystPermission {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        query {
          dataRooms(
            filter: {isArchived: {equalTo: false}, isFavorite: {equalTo: false}, isOwnedByUser: {equalTo: true}}
            sortBy: {updatedAt: DESCENDING}
          ) {
            nodes {
              ... on DraftDataRoom {
                ...NavigationDrawerDraftDataRoom
              }
              ... on PublishedDataRoom {
                ...NavigationDrawerPublishedDataRoom
              }
              ... on PublishedMediaDataRoom {
                ...NavigationDrawerPublishedMediaDataRoom
              }
              ... on PublishedLookalikeMediaDataRoom {
                ...NavigationDrawerPublishedLookalikeMediaDataRoom
              }
              ... on PublishedMediaInsightsDcr {
                ...NavigationDrawerPublishedMediaInsightsDcr
              }
            }
          }
        }
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}`;
export type DraftDataRoomDuplicateMutationFn = Apollo.MutationFunction<Types.DraftDataRoomDuplicateMutation, Types.DraftDataRoomDuplicateMutationVariables>;

/**
 * __useDraftDataRoomDuplicateMutation__
 *
 * To run a mutation, you first call `useDraftDataRoomDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftDataRoomDuplicateMutation, { data, loading, error }] = useDraftDataRoomDuplicateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftDataRoomDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<Types.DraftDataRoomDuplicateMutation, Types.DraftDataRoomDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DraftDataRoomDuplicateMutation, Types.DraftDataRoomDuplicateMutationVariables>(DraftDataRoomDuplicateDocument, options);
      }
export type DraftDataRoomDuplicateMutationHookResult = ReturnType<typeof useDraftDataRoomDuplicateMutation>;
export type DraftDataRoomDuplicateMutationResult = Apollo.MutationResult<Types.DraftDataRoomDuplicateMutation>;
export type DraftDataRoomDuplicateMutationOptions = Apollo.BaseMutationOptions<Types.DraftDataRoomDuplicateMutation, Types.DraftDataRoomDuplicateMutationVariables>;
export const CreateDraftDataRoomIsFavoriteDocument = gql`
    mutation CreateDraftDataRoomIsFavorite($draftDataRoomId: String!, $isFavorite: Boolean!) {
  draftDataRoom {
    createUserSettings(
      input: {draftDataRoomId: $draftDataRoomId, isFavorite: $isFavorite, isArchived: false}
    ) {
      id
      isFavorite
      query {
        draftDataRoom(id: $draftDataRoomId) {
          id
          userSettings {
            id
          }
        }
      }
    }
  }
}
    `;
export type CreateDraftDataRoomIsFavoriteMutationFn = Apollo.MutationFunction<Types.CreateDraftDataRoomIsFavoriteMutation, Types.CreateDraftDataRoomIsFavoriteMutationVariables>;

/**
 * __useCreateDraftDataRoomIsFavoriteMutation__
 *
 * To run a mutation, you first call `useCreateDraftDataRoomIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftDataRoomIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftDataRoomIsFavoriteMutation, { data, loading, error }] = useCreateDraftDataRoomIsFavoriteMutation({
 *   variables: {
 *      draftDataRoomId: // value for 'draftDataRoomId'
 *      isFavorite: // value for 'isFavorite'
 *   },
 * });
 */
export function useCreateDraftDataRoomIsFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftDataRoomIsFavoriteMutation, Types.CreateDraftDataRoomIsFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftDataRoomIsFavoriteMutation, Types.CreateDraftDataRoomIsFavoriteMutationVariables>(CreateDraftDataRoomIsFavoriteDocument, options);
      }
export type CreateDraftDataRoomIsFavoriteMutationHookResult = ReturnType<typeof useCreateDraftDataRoomIsFavoriteMutation>;
export type CreateDraftDataRoomIsFavoriteMutationResult = Apollo.MutationResult<Types.CreateDraftDataRoomIsFavoriteMutation>;
export type CreateDraftDataRoomIsFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftDataRoomIsFavoriteMutation, Types.CreateDraftDataRoomIsFavoriteMutationVariables>;
export const DraftDataRoomIsFavoriteDocument = gql`
    query DraftDataRoomIsFavorite($id: String!) {
  draftDataRoom(id: $id) {
    id
    userSettings {
      id
      isFavorite
    }
  }
}
    `;

/**
 * __useDraftDataRoomIsFavoriteQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomIsFavoriteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomIsFavoriteQuery, Types.DraftDataRoomIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomIsFavoriteQuery, Types.DraftDataRoomIsFavoriteQueryVariables>(DraftDataRoomIsFavoriteDocument, options);
      }
export function useDraftDataRoomIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomIsFavoriteQuery, Types.DraftDataRoomIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomIsFavoriteQuery, Types.DraftDataRoomIsFavoriteQueryVariables>(DraftDataRoomIsFavoriteDocument, options);
        }
export type DraftDataRoomIsFavoriteQueryHookResult = ReturnType<typeof useDraftDataRoomIsFavoriteQuery>;
export type DraftDataRoomIsFavoriteLazyQueryHookResult = ReturnType<typeof useDraftDataRoomIsFavoriteLazyQuery>;
export type DraftDataRoomIsFavoriteQueryResult = Apollo.QueryResult<Types.DraftDataRoomIsFavoriteQuery, Types.DraftDataRoomIsFavoriteQueryVariables>;
export const UpdateDraftDataRoomIsFavoriteDocument = gql`
    mutation UpdateDraftDataRoomIsFavorite($id: String!, $isFavorite: Boolean!) {
  draftDataRoom {
    updateUserSettings(input: {id: $id, isFavorite: $isFavorite}) {
      id
      isFavorite
    }
  }
}
    `;
export type UpdateDraftDataRoomIsFavoriteMutationFn = Apollo.MutationFunction<Types.UpdateDraftDataRoomIsFavoriteMutation, Types.UpdateDraftDataRoomIsFavoriteMutationVariables>;

/**
 * __useUpdateDraftDataRoomIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateDraftDataRoomIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftDataRoomIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftDataRoomIsFavoriteMutation, { data, loading, error }] = useUpdateDraftDataRoomIsFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFavorite: // value for 'isFavorite'
 *   },
 * });
 */
export function useUpdateDraftDataRoomIsFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftDataRoomIsFavoriteMutation, Types.UpdateDraftDataRoomIsFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftDataRoomIsFavoriteMutation, Types.UpdateDraftDataRoomIsFavoriteMutationVariables>(UpdateDraftDataRoomIsFavoriteDocument, options);
      }
export type UpdateDraftDataRoomIsFavoriteMutationHookResult = ReturnType<typeof useUpdateDraftDataRoomIsFavoriteMutation>;
export type UpdateDraftDataRoomIsFavoriteMutationResult = Apollo.MutationResult<Types.UpdateDraftDataRoomIsFavoriteMutation>;
export type UpdateDraftDataRoomIsFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftDataRoomIsFavoriteMutation, Types.UpdateDraftDataRoomIsFavoriteMutationVariables>;
export const CreateDraftDataRoomIsHiddenDocument = gql`
    mutation CreateDraftDataRoomIsHidden($draftDataRoomId: String!, $isHidden: Boolean!) {
  draftDataRoom {
    createUserSettings(
      input: {draftDataRoomId: $draftDataRoomId, isArchived: $isHidden, isFavorite: false}
    ) {
      id
      isArchived
      isFavorite
      query {
        draftDataRoom(id: $draftDataRoomId) {
          id
          userSettings {
            id
          }
        }
      }
    }
  }
}
    `;
export type CreateDraftDataRoomIsHiddenMutationFn = Apollo.MutationFunction<Types.CreateDraftDataRoomIsHiddenMutation, Types.CreateDraftDataRoomIsHiddenMutationVariables>;

/**
 * __useCreateDraftDataRoomIsHiddenMutation__
 *
 * To run a mutation, you first call `useCreateDraftDataRoomIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftDataRoomIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftDataRoomIsHiddenMutation, { data, loading, error }] = useCreateDraftDataRoomIsHiddenMutation({
 *   variables: {
 *      draftDataRoomId: // value for 'draftDataRoomId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useCreateDraftDataRoomIsHiddenMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftDataRoomIsHiddenMutation, Types.CreateDraftDataRoomIsHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftDataRoomIsHiddenMutation, Types.CreateDraftDataRoomIsHiddenMutationVariables>(CreateDraftDataRoomIsHiddenDocument, options);
      }
export type CreateDraftDataRoomIsHiddenMutationHookResult = ReturnType<typeof useCreateDraftDataRoomIsHiddenMutation>;
export type CreateDraftDataRoomIsHiddenMutationResult = Apollo.MutationResult<Types.CreateDraftDataRoomIsHiddenMutation>;
export type CreateDraftDataRoomIsHiddenMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftDataRoomIsHiddenMutation, Types.CreateDraftDataRoomIsHiddenMutationVariables>;
export const DraftDataRoomIsHiddenDocument = gql`
    query DraftDataRoomIsHidden($id: String!) {
  draftDataRoom(id: $id) {
    id
    userSettings {
      id
      isHidden: isArchived
    }
  }
}
    `;

/**
 * __useDraftDataRoomIsHiddenQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomIsHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomIsHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomIsHiddenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomIsHiddenQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomIsHiddenQuery, Types.DraftDataRoomIsHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomIsHiddenQuery, Types.DraftDataRoomIsHiddenQueryVariables>(DraftDataRoomIsHiddenDocument, options);
      }
export function useDraftDataRoomIsHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomIsHiddenQuery, Types.DraftDataRoomIsHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomIsHiddenQuery, Types.DraftDataRoomIsHiddenQueryVariables>(DraftDataRoomIsHiddenDocument, options);
        }
export type DraftDataRoomIsHiddenQueryHookResult = ReturnType<typeof useDraftDataRoomIsHiddenQuery>;
export type DraftDataRoomIsHiddenLazyQueryHookResult = ReturnType<typeof useDraftDataRoomIsHiddenLazyQuery>;
export type DraftDataRoomIsHiddenQueryResult = Apollo.QueryResult<Types.DraftDataRoomIsHiddenQuery, Types.DraftDataRoomIsHiddenQueryVariables>;
export const UpdateDraftDataRoomIsHiddenDocument = gql`
    mutation UpdateDraftDataRoomIsHidden($id: String!, $isHidden: Boolean!) {
  draftDataRoom {
    updateUserSettings(input: {id: $id, isArchived: $isHidden}) {
      id
      isHidden: isArchived
    }
  }
}
    `;
export type UpdateDraftDataRoomIsHiddenMutationFn = Apollo.MutationFunction<Types.UpdateDraftDataRoomIsHiddenMutation, Types.UpdateDraftDataRoomIsHiddenMutationVariables>;

/**
 * __useUpdateDraftDataRoomIsHiddenMutation__
 *
 * To run a mutation, you first call `useUpdateDraftDataRoomIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftDataRoomIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftDataRoomIsHiddenMutation, { data, loading, error }] = useUpdateDraftDataRoomIsHiddenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateDraftDataRoomIsHiddenMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftDataRoomIsHiddenMutation, Types.UpdateDraftDataRoomIsHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftDataRoomIsHiddenMutation, Types.UpdateDraftDataRoomIsHiddenMutationVariables>(UpdateDraftDataRoomIsHiddenDocument, options);
      }
export type UpdateDraftDataRoomIsHiddenMutationHookResult = ReturnType<typeof useUpdateDraftDataRoomIsHiddenMutation>;
export type UpdateDraftDataRoomIsHiddenMutationResult = Apollo.MutationResult<Types.UpdateDraftDataRoomIsHiddenMutation>;
export type UpdateDraftDataRoomIsHiddenMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftDataRoomIsHiddenMutation, Types.UpdateDraftDataRoomIsHiddenMutationVariables>;
export const DraftDataRoomPublishDocument = gql`
    query DraftDataRoomPublish($id: String!) {
  draftDataRoom(id: $id) {
    id
    name: title
    logo
    owner {
      id
      email
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useDraftDataRoomPublishQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomPublishQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomPublishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomPublishQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomPublishQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomPublishQuery, Types.DraftDataRoomPublishQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomPublishQuery, Types.DraftDataRoomPublishQueryVariables>(DraftDataRoomPublishDocument, options);
      }
export function useDraftDataRoomPublishLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomPublishQuery, Types.DraftDataRoomPublishQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomPublishQuery, Types.DraftDataRoomPublishQueryVariables>(DraftDataRoomPublishDocument, options);
        }
export type DraftDataRoomPublishQueryHookResult = ReturnType<typeof useDraftDataRoomPublishQuery>;
export type DraftDataRoomPublishLazyQueryHookResult = ReturnType<typeof useDraftDataRoomPublishLazyQuery>;
export type DraftDataRoomPublishQueryResult = Apollo.QueryResult<Types.DraftDataRoomPublishQuery, Types.DraftDataRoomPublishQueryVariables>;
export const DraftDataRoomRequirePasswordDocument = gql`
    query DraftDataRoomRequirePassword($id: String!) {
  draftDataRoom(id: $id) {
    requirePassword
  }
}
    `;

/**
 * __useDraftDataRoomRequirePasswordQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomRequirePasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomRequirePasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomRequirePasswordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomRequirePasswordQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomRequirePasswordQuery, Types.DraftDataRoomRequirePasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomRequirePasswordQuery, Types.DraftDataRoomRequirePasswordQueryVariables>(DraftDataRoomRequirePasswordDocument, options);
      }
export function useDraftDataRoomRequirePasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomRequirePasswordQuery, Types.DraftDataRoomRequirePasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomRequirePasswordQuery, Types.DraftDataRoomRequirePasswordQueryVariables>(DraftDataRoomRequirePasswordDocument, options);
        }
export type DraftDataRoomRequirePasswordQueryHookResult = ReturnType<typeof useDraftDataRoomRequirePasswordQuery>;
export type DraftDataRoomRequirePasswordLazyQueryHookResult = ReturnType<typeof useDraftDataRoomRequirePasswordLazyQuery>;
export type DraftDataRoomRequirePasswordQueryResult = Apollo.QueryResult<Types.DraftDataRoomRequirePasswordQuery, Types.DraftDataRoomRequirePasswordQueryVariables>;
export const UpdateDraftDataRoomRequirePasswordDocument = gql`
    mutation UpdateDraftDataRoomRequirePassword($id: String!, $requirePassword: Boolean!) {
  draftDataRoom {
    update(input: {id: $id, requirePassword: $requirePassword}) {
      id
      requirePassword
    }
  }
}
    `;
export type UpdateDraftDataRoomRequirePasswordMutationFn = Apollo.MutationFunction<Types.UpdateDraftDataRoomRequirePasswordMutation, Types.UpdateDraftDataRoomRequirePasswordMutationVariables>;

/**
 * __useUpdateDraftDataRoomRequirePasswordMutation__
 *
 * To run a mutation, you first call `useUpdateDraftDataRoomRequirePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftDataRoomRequirePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftDataRoomRequirePasswordMutation, { data, loading, error }] = useUpdateDraftDataRoomRequirePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requirePassword: // value for 'requirePassword'
 *   },
 * });
 */
export function useUpdateDraftDataRoomRequirePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftDataRoomRequirePasswordMutation, Types.UpdateDraftDataRoomRequirePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftDataRoomRequirePasswordMutation, Types.UpdateDraftDataRoomRequirePasswordMutationVariables>(UpdateDraftDataRoomRequirePasswordDocument, options);
      }
export type UpdateDraftDataRoomRequirePasswordMutationHookResult = ReturnType<typeof useUpdateDraftDataRoomRequirePasswordMutation>;
export type UpdateDraftDataRoomRequirePasswordMutationResult = Apollo.MutationResult<Types.UpdateDraftDataRoomRequirePasswordMutation>;
export type UpdateDraftDataRoomRequirePasswordMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftDataRoomRequirePasswordMutation, Types.UpdateDraftDataRoomRequirePasswordMutationVariables>;
export const DraftDataRoomShowOrganizationLogoDocument = gql`
    query DraftDataRoomShowOrganizationLogo($id: String!) {
  draftDataRoom(id: $id) {
    id
    showOrganizationLogo
  }
}
    `;

/**
 * __useDraftDataRoomShowOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomShowOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomShowOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomShowOrganizationLogoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomShowOrganizationLogoQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomShowOrganizationLogoQuery, Types.DraftDataRoomShowOrganizationLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomShowOrganizationLogoQuery, Types.DraftDataRoomShowOrganizationLogoQueryVariables>(DraftDataRoomShowOrganizationLogoDocument, options);
      }
export function useDraftDataRoomShowOrganizationLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomShowOrganizationLogoQuery, Types.DraftDataRoomShowOrganizationLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomShowOrganizationLogoQuery, Types.DraftDataRoomShowOrganizationLogoQueryVariables>(DraftDataRoomShowOrganizationLogoDocument, options);
        }
export type DraftDataRoomShowOrganizationLogoQueryHookResult = ReturnType<typeof useDraftDataRoomShowOrganizationLogoQuery>;
export type DraftDataRoomShowOrganizationLogoLazyQueryHookResult = ReturnType<typeof useDraftDataRoomShowOrganizationLogoLazyQuery>;
export type DraftDataRoomShowOrganizationLogoQueryResult = Apollo.QueryResult<Types.DraftDataRoomShowOrganizationLogoQuery, Types.DraftDataRoomShowOrganizationLogoQueryVariables>;
export const UpdateDraftDataRoomShowOrganizationLogoDocument = gql`
    mutation UpdateDraftDataRoomShowOrganizationLogo($id: String!, $showOrganizationLogo: Boolean!) {
  draftDataRoom {
    update(input: {id: $id, showOrganizationLogo: $showOrganizationLogo}) {
      id
      showOrganizationLogo
    }
  }
}
    `;
export type UpdateDraftDataRoomShowOrganizationLogoMutationFn = Apollo.MutationFunction<Types.UpdateDraftDataRoomShowOrganizationLogoMutation, Types.UpdateDraftDataRoomShowOrganizationLogoMutationVariables>;

/**
 * __useUpdateDraftDataRoomShowOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUpdateDraftDataRoomShowOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftDataRoomShowOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftDataRoomShowOrganizationLogoMutation, { data, loading, error }] = useUpdateDraftDataRoomShowOrganizationLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      showOrganizationLogo: // value for 'showOrganizationLogo'
 *   },
 * });
 */
export function useUpdateDraftDataRoomShowOrganizationLogoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDraftDataRoomShowOrganizationLogoMutation, Types.UpdateDraftDataRoomShowOrganizationLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDraftDataRoomShowOrganizationLogoMutation, Types.UpdateDraftDataRoomShowOrganizationLogoMutationVariables>(UpdateDraftDataRoomShowOrganizationLogoDocument, options);
      }
export type UpdateDraftDataRoomShowOrganizationLogoMutationHookResult = ReturnType<typeof useUpdateDraftDataRoomShowOrganizationLogoMutation>;
export type UpdateDraftDataRoomShowOrganizationLogoMutationResult = Apollo.MutationResult<Types.UpdateDraftDataRoomShowOrganizationLogoMutation>;
export type UpdateDraftDataRoomShowOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDraftDataRoomShowOrganizationLogoMutation, Types.UpdateDraftDataRoomShowOrganizationLogoMutationVariables>;
export const CompleteMediaDataRoomDocument = gql`
    query CompleteMediaDataRoom($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    name @client
    activationType @client
    publisherUserEmails @client
    enableAuditLogRetrieval @client
    advertiserUserEmails @client
    observerUserEmails @client
    agencyUserEmails @client
    advertiserDatasetHash @client
    publisherDatasetHash @client
    activationDownloadByAdvertiser @client
    activationDownloadByPublisher @client
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __useCompleteMediaDataRoomQuery__
 *
 * To run a query within a React component, call `useCompleteMediaDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteMediaDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteMediaDataRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteMediaDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.CompleteMediaDataRoomQuery, Types.CompleteMediaDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompleteMediaDataRoomQuery, Types.CompleteMediaDataRoomQueryVariables>(CompleteMediaDataRoomDocument, options);
      }
export function useCompleteMediaDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompleteMediaDataRoomQuery, Types.CompleteMediaDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompleteMediaDataRoomQuery, Types.CompleteMediaDataRoomQueryVariables>(CompleteMediaDataRoomDocument, options);
        }
export type CompleteMediaDataRoomQueryHookResult = ReturnType<typeof useCompleteMediaDataRoomQuery>;
export type CompleteMediaDataRoomLazyQueryHookResult = ReturnType<typeof useCompleteMediaDataRoomLazyQuery>;
export type CompleteMediaDataRoomQueryResult = Apollo.QueryResult<Types.CompleteMediaDataRoomQuery, Types.CompleteMediaDataRoomQueryVariables>;
export const PublishedMediaDataRoomActionsDocument = gql`
    query PublishedMediaDataRoomActions($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    name: title
    userSettings {
      id
      isArchived
      isFavorite
    }
    owner {
      id
      email
    }
    updatedAt
    createdAt
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __usePublishedMediaDataRoomActionsQuery__
 *
 * To run a query within a React component, call `usePublishedMediaDataRoomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaDataRoomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaDataRoomActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaDataRoomActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaDataRoomActionsQuery, Types.PublishedMediaDataRoomActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaDataRoomActionsQuery, Types.PublishedMediaDataRoomActionsQueryVariables>(PublishedMediaDataRoomActionsDocument, options);
      }
export function usePublishedMediaDataRoomActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaDataRoomActionsQuery, Types.PublishedMediaDataRoomActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaDataRoomActionsQuery, Types.PublishedMediaDataRoomActionsQueryVariables>(PublishedMediaDataRoomActionsDocument, options);
        }
export type PublishedMediaDataRoomActionsQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomActionsQuery>;
export type PublishedMediaDataRoomActionsLazyQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomActionsLazyQuery>;
export type PublishedMediaDataRoomActionsQueryResult = Apollo.QueryResult<Types.PublishedMediaDataRoomActionsQuery, Types.PublishedMediaDataRoomActionsQueryVariables>;
export const MediaDataRoomActivationDocument = gql`
    query MediaDataRoomActivation($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    activationType @client
    advertiserDatasetHash @client
    publisherDatasetHash @client
  }
}
    `;

/**
 * __useMediaDataRoomActivationQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomActivationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomActivationQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomActivationQuery, Types.MediaDataRoomActivationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomActivationQuery, Types.MediaDataRoomActivationQueryVariables>(MediaDataRoomActivationDocument, options);
      }
export function useMediaDataRoomActivationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomActivationQuery, Types.MediaDataRoomActivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomActivationQuery, Types.MediaDataRoomActivationQueryVariables>(MediaDataRoomActivationDocument, options);
        }
export type MediaDataRoomActivationQueryHookResult = ReturnType<typeof useMediaDataRoomActivationQuery>;
export type MediaDataRoomActivationLazyQueryHookResult = ReturnType<typeof useMediaDataRoomActivationLazyQuery>;
export type MediaDataRoomActivationQueryResult = Apollo.QueryResult<Types.MediaDataRoomActivationQuery, Types.MediaDataRoomActivationQueryVariables>;
export const ActivateConsentlessAudienceDocument = gql`
    mutation ActivateConsentlessAudience($input: MediaActivateConsentlessAudienceInput!) {
  mediaActivateConsentlessAudience(input: $input) @client {
    audienceId
  }
}
    `;
export type ActivateConsentlessAudienceMutationFn = Apollo.MutationFunction<Types.ActivateConsentlessAudienceMutation, Types.ActivateConsentlessAudienceMutationVariables>;

/**
 * __useActivateConsentlessAudienceMutation__
 *
 * To run a mutation, you first call `useActivateConsentlessAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateConsentlessAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateConsentlessAudienceMutation, { data, loading, error }] = useActivateConsentlessAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateConsentlessAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateConsentlessAudienceMutation, Types.ActivateConsentlessAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateConsentlessAudienceMutation, Types.ActivateConsentlessAudienceMutationVariables>(ActivateConsentlessAudienceDocument, options);
      }
export type ActivateConsentlessAudienceMutationHookResult = ReturnType<typeof useActivateConsentlessAudienceMutation>;
export type ActivateConsentlessAudienceMutationResult = Apollo.MutationResult<Types.ActivateConsentlessAudienceMutation>;
export type ActivateConsentlessAudienceMutationOptions = Apollo.BaseMutationOptions<Types.ActivateConsentlessAudienceMutation, Types.ActivateConsentlessAudienceMutationVariables>;
export const ConsentlessAudiencesDocument = gql`
    query ConsentlessAudiences($input: MediaRetrieveConsentlessAudiencesInput!, $id: String!) {
  mediaRetrieveConsentlessAudiences(input: $input) @client {
    nodes {
      id
      name
      precision
      size
      activated
      downloaded
      owner
    }
  }
  publishedMediaDataRoom(id: $id) {
    id
    activationDownloadByPublisher @client
  }
}
    `;

/**
 * __useConsentlessAudiencesQuery__
 *
 * To run a query within a React component, call `useConsentlessAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentlessAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentlessAudiencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsentlessAudiencesQuery(baseOptions: Apollo.QueryHookOptions<Types.ConsentlessAudiencesQuery, Types.ConsentlessAudiencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConsentlessAudiencesQuery, Types.ConsentlessAudiencesQueryVariables>(ConsentlessAudiencesDocument, options);
      }
export function useConsentlessAudiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConsentlessAudiencesQuery, Types.ConsentlessAudiencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConsentlessAudiencesQuery, Types.ConsentlessAudiencesQueryVariables>(ConsentlessAudiencesDocument, options);
        }
export type ConsentlessAudiencesQueryHookResult = ReturnType<typeof useConsentlessAudiencesQuery>;
export type ConsentlessAudiencesLazyQueryHookResult = ReturnType<typeof useConsentlessAudiencesLazyQuery>;
export type ConsentlessAudiencesQueryResult = Apollo.QueryResult<Types.ConsentlessAudiencesQuery, Types.ConsentlessAudiencesQueryVariables>;
export const DownloadActivatedConsentlessAudienceDocument = gql`
    mutation DownloadActivatedConsentlessAudience($input: MediaDownloadConsentlessActivatedAudienceInput!) {
  mediaDownloadActivatedConsentlessAudience(input: $input) @client {
    audienceId
    audienceCsv
  }
}
    `;
export type DownloadActivatedConsentlessAudienceMutationFn = Apollo.MutationFunction<Types.DownloadActivatedConsentlessAudienceMutation, Types.DownloadActivatedConsentlessAudienceMutationVariables>;

/**
 * __useDownloadActivatedConsentlessAudienceMutation__
 *
 * To run a mutation, you first call `useDownloadActivatedConsentlessAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadActivatedConsentlessAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadActivatedConsentlessAudienceMutation, { data, loading, error }] = useDownloadActivatedConsentlessAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadActivatedConsentlessAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DownloadActivatedConsentlessAudienceMutation, Types.DownloadActivatedConsentlessAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DownloadActivatedConsentlessAudienceMutation, Types.DownloadActivatedConsentlessAudienceMutationVariables>(DownloadActivatedConsentlessAudienceDocument, options);
      }
export type DownloadActivatedConsentlessAudienceMutationHookResult = ReturnType<typeof useDownloadActivatedConsentlessAudienceMutation>;
export type DownloadActivatedConsentlessAudienceMutationResult = Apollo.MutationResult<Types.DownloadActivatedConsentlessAudienceMutation>;
export type DownloadActivatedConsentlessAudienceMutationOptions = Apollo.BaseMutationOptions<Types.DownloadActivatedConsentlessAudienceMutation, Types.DownloadActivatedConsentlessAudienceMutationVariables>;
export const DeleteConsentlessAudienceDocument = gql`
    mutation DeleteConsentlessAudience($input: MediaDeleteConsentlessAudienceInput!) {
  mediaDeleteConsentlessAudience(input: $input) @client {
    audienceId
  }
}
    `;
export type DeleteConsentlessAudienceMutationFn = Apollo.MutationFunction<Types.DeleteConsentlessAudienceMutation, Types.DeleteConsentlessAudienceMutationVariables>;

/**
 * __useDeleteConsentlessAudienceMutation__
 *
 * To run a mutation, you first call `useDeleteConsentlessAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsentlessAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsentlessAudienceMutation, { data, loading, error }] = useDeleteConsentlessAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConsentlessAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConsentlessAudienceMutation, Types.DeleteConsentlessAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConsentlessAudienceMutation, Types.DeleteConsentlessAudienceMutationVariables>(DeleteConsentlessAudienceDocument, options);
      }
export type DeleteConsentlessAudienceMutationHookResult = ReturnType<typeof useDeleteConsentlessAudienceMutation>;
export type DeleteConsentlessAudienceMutationResult = Apollo.MutationResult<Types.DeleteConsentlessAudienceMutation>;
export type DeleteConsentlessAudienceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConsentlessAudienceMutation, Types.DeleteConsentlessAudienceMutationVariables>;
export const DownloadActivatedDirectAudienceDocument = gql`
    mutation DownloadActivatedDirectAudience($input: MediaDownloadDirectActivatedAudienceInput!) {
  mediaDownloadActivatedDirectAudience(input: $input) @client {
    audienceType
    audienceCsv
  }
}
    `;
export type DownloadActivatedDirectAudienceMutationFn = Apollo.MutationFunction<Types.DownloadActivatedDirectAudienceMutation, Types.DownloadActivatedDirectAudienceMutationVariables>;

/**
 * __useDownloadActivatedDirectAudienceMutation__
 *
 * To run a mutation, you first call `useDownloadActivatedDirectAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadActivatedDirectAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadActivatedDirectAudienceMutation, { data, loading, error }] = useDownloadActivatedDirectAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadActivatedDirectAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DownloadActivatedDirectAudienceMutation, Types.DownloadActivatedDirectAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DownloadActivatedDirectAudienceMutation, Types.DownloadActivatedDirectAudienceMutationVariables>(DownloadActivatedDirectAudienceDocument, options);
      }
export type DownloadActivatedDirectAudienceMutationHookResult = ReturnType<typeof useDownloadActivatedDirectAudienceMutation>;
export type DownloadActivatedDirectAudienceMutationResult = Apollo.MutationResult<Types.DownloadActivatedDirectAudienceMutation>;
export type DownloadActivatedDirectAudienceMutationOptions = Apollo.BaseMutationOptions<Types.DownloadActivatedDirectAudienceMutation, Types.DownloadActivatedDirectAudienceMutationVariables>;
export const StoreActivatedDirectAudienceAsDatasetDocument = gql`
    mutation StoreActivatedDirectAudienceAsDataset($input: StoreDirectActivatedAudienceAsDatasetInput!) {
  storeActivatedDirectAudienceAsDataset(input: $input) @client {
    audienceType
    createImportPayload {
      datasetImport {
        ...DatasetImport
      }
    }
  }
}
    ${DatasetImportFragment}`;
export type StoreActivatedDirectAudienceAsDatasetMutationFn = Apollo.MutationFunction<Types.StoreActivatedDirectAudienceAsDatasetMutation, Types.StoreActivatedDirectAudienceAsDatasetMutationVariables>;

/**
 * __useStoreActivatedDirectAudienceAsDatasetMutation__
 *
 * To run a mutation, you first call `useStoreActivatedDirectAudienceAsDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreActivatedDirectAudienceAsDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeActivatedDirectAudienceAsDatasetMutation, { data, loading, error }] = useStoreActivatedDirectAudienceAsDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreActivatedDirectAudienceAsDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.StoreActivatedDirectAudienceAsDatasetMutation, Types.StoreActivatedDirectAudienceAsDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StoreActivatedDirectAudienceAsDatasetMutation, Types.StoreActivatedDirectAudienceAsDatasetMutationVariables>(StoreActivatedDirectAudienceAsDatasetDocument, options);
      }
export type StoreActivatedDirectAudienceAsDatasetMutationHookResult = ReturnType<typeof useStoreActivatedDirectAudienceAsDatasetMutation>;
export type StoreActivatedDirectAudienceAsDatasetMutationResult = Apollo.MutationResult<Types.StoreActivatedDirectAudienceAsDatasetMutation>;
export type StoreActivatedDirectAudienceAsDatasetMutationOptions = Apollo.BaseMutationOptions<Types.StoreActivatedDirectAudienceAsDatasetMutation, Types.StoreActivatedDirectAudienceAsDatasetMutationVariables>;
export const ActivateDirectAudienceDocument = gql`
    mutation ActivateDirectAudience($input: MediaActivateDirectAudienceInput!) {
  mediaActivateDirectAudience(input: $input) @client {
    audienceTypes
  }
}
    `;
export type ActivateDirectAudienceMutationFn = Apollo.MutationFunction<Types.ActivateDirectAudienceMutation, Types.ActivateDirectAudienceMutationVariables>;

/**
 * __useActivateDirectAudienceMutation__
 *
 * To run a mutation, you first call `useActivateDirectAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDirectAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDirectAudienceMutation, { data, loading, error }] = useActivateDirectAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateDirectAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateDirectAudienceMutation, Types.ActivateDirectAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateDirectAudienceMutation, Types.ActivateDirectAudienceMutationVariables>(ActivateDirectAudienceDocument, options);
      }
export type ActivateDirectAudienceMutationHookResult = ReturnType<typeof useActivateDirectAudienceMutation>;
export type ActivateDirectAudienceMutationResult = Apollo.MutationResult<Types.ActivateDirectAudienceMutation>;
export type ActivateDirectAudienceMutationOptions = Apollo.BaseMutationOptions<Types.ActivateDirectAudienceMutation, Types.ActivateDirectAudienceMutationVariables>;
export const DirectAudiencesDocument = gql`
    query DirectAudiences($input: MediaRetrieveDirectAudiencesInput!, $id: String!) {
  mediaRetrieveDirectAudiences(input: $input) @client {
    nodes {
      name
      size
      activated
      downloaded
    }
  }
  publishedMediaDataRoom(id: $id) {
    id
    activationDownloadByPublisher @client
  }
}
    `;

/**
 * __useDirectAudiencesQuery__
 *
 * To run a query within a React component, call `useDirectAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectAudiencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectAudiencesQuery(baseOptions: Apollo.QueryHookOptions<Types.DirectAudiencesQuery, Types.DirectAudiencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DirectAudiencesQuery, Types.DirectAudiencesQueryVariables>(DirectAudiencesDocument, options);
      }
export function useDirectAudiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DirectAudiencesQuery, Types.DirectAudiencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DirectAudiencesQuery, Types.DirectAudiencesQueryVariables>(DirectAudiencesDocument, options);
        }
export type DirectAudiencesQueryHookResult = ReturnType<typeof useDirectAudiencesQuery>;
export type DirectAudiencesLazyQueryHookResult = ReturnType<typeof useDirectAudiencesLazyQuery>;
export type DirectAudiencesQueryResult = Apollo.QueryResult<Types.DirectAudiencesQuery, Types.DirectAudiencesQueryVariables>;
export const GenerateConsentlessAudienceDocument = gql`
    mutation GenerateConsentlessAudience($input: MediaGenerateConsentlessAudienceInput!) {
  mediaGenerateConsentlessAudience(input: $input) @client {
    audienceIds
  }
}
    `;
export type GenerateConsentlessAudienceMutationFn = Apollo.MutationFunction<Types.GenerateConsentlessAudienceMutation, Types.GenerateConsentlessAudienceMutationVariables>;

/**
 * __useGenerateConsentlessAudienceMutation__
 *
 * To run a mutation, you first call `useGenerateConsentlessAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateConsentlessAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateConsentlessAudienceMutation, { data, loading, error }] = useGenerateConsentlessAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateConsentlessAudienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateConsentlessAudienceMutation, Types.GenerateConsentlessAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateConsentlessAudienceMutation, Types.GenerateConsentlessAudienceMutationVariables>(GenerateConsentlessAudienceDocument, options);
      }
export type GenerateConsentlessAudienceMutationHookResult = ReturnType<typeof useGenerateConsentlessAudienceMutation>;
export type GenerateConsentlessAudienceMutationResult = Apollo.MutationResult<Types.GenerateConsentlessAudienceMutation>;
export type GenerateConsentlessAudienceMutationOptions = Apollo.BaseMutationOptions<Types.GenerateConsentlessAudienceMutation, Types.GenerateConsentlessAudienceMutationVariables>;
export const MediaDataRoomActivationDownloadConfigDocument = gql`
    query MediaDataRoomActivationDownloadConfig($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    activationType @client
    activationDownloadByAdvertiser @client
    activationDownloadByPublisher @client
  }
}
    `;

/**
 * __useMediaDataRoomActivationDownloadConfigQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomActivationDownloadConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomActivationDownloadConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomActivationDownloadConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomActivationDownloadConfigQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomActivationDownloadConfigQuery, Types.MediaDataRoomActivationDownloadConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomActivationDownloadConfigQuery, Types.MediaDataRoomActivationDownloadConfigQueryVariables>(MediaDataRoomActivationDownloadConfigDocument, options);
      }
export function useMediaDataRoomActivationDownloadConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomActivationDownloadConfigQuery, Types.MediaDataRoomActivationDownloadConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomActivationDownloadConfigQuery, Types.MediaDataRoomActivationDownloadConfigQueryVariables>(MediaDataRoomActivationDownloadConfigDocument, options);
        }
export type MediaDataRoomActivationDownloadConfigQueryHookResult = ReturnType<typeof useMediaDataRoomActivationDownloadConfigQuery>;
export type MediaDataRoomActivationDownloadConfigLazyQueryHookResult = ReturnType<typeof useMediaDataRoomActivationDownloadConfigLazyQuery>;
export type MediaDataRoomActivationDownloadConfigQueryResult = Apollo.QueryResult<Types.MediaDataRoomActivationDownloadConfigQuery, Types.MediaDataRoomActivationDownloadConfigQueryVariables>;
export const ConsentlessActivatedAudiencesDocument = gql`
    query ConsentlessActivatedAudiences($input: MediaRetrieveConsentlessActivatedAudiencesInput!, $id: String!) {
  mediaRetrieveConsentlessActivatedAudiences(input: $input) @client {
    nodes {
      id
      name
      size
      downloaded
      owner
    }
  }
  publishedMediaDataRoom(id: $id) {
    id
    activationDownloadByPublisher @client
  }
}
    `;

/**
 * __useConsentlessActivatedAudiencesQuery__
 *
 * To run a query within a React component, call `useConsentlessActivatedAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentlessActivatedAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentlessActivatedAudiencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsentlessActivatedAudiencesQuery(baseOptions: Apollo.QueryHookOptions<Types.ConsentlessActivatedAudiencesQuery, Types.ConsentlessActivatedAudiencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConsentlessActivatedAudiencesQuery, Types.ConsentlessActivatedAudiencesQueryVariables>(ConsentlessActivatedAudiencesDocument, options);
      }
export function useConsentlessActivatedAudiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConsentlessActivatedAudiencesQuery, Types.ConsentlessActivatedAudiencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConsentlessActivatedAudiencesQuery, Types.ConsentlessActivatedAudiencesQueryVariables>(ConsentlessActivatedAudiencesDocument, options);
        }
export type ConsentlessActivatedAudiencesQueryHookResult = ReturnType<typeof useConsentlessActivatedAudiencesQuery>;
export type ConsentlessActivatedAudiencesLazyQueryHookResult = ReturnType<typeof useConsentlessActivatedAudiencesLazyQuery>;
export type ConsentlessActivatedAudiencesQueryResult = Apollo.QueryResult<Types.ConsentlessActivatedAudiencesQuery, Types.ConsentlessActivatedAudiencesQueryVariables>;
export const DirectActivatedAudiencesDocument = gql`
    query DirectActivatedAudiences($input: MediaRetrieveDirectActivatedAudiencesInput!, $id: String!) {
  mediaRetrieveDirectActivatedAudiences(input: $input) @client {
    nodes {
      name
      size
      downloaded
    }
  }
  publishedMediaDataRoom(id: $id) {
    id
    activationDownloadByPublisher @client
  }
}
    `;

/**
 * __useDirectActivatedAudiencesQuery__
 *
 * To run a query within a React component, call `useDirectActivatedAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectActivatedAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectActivatedAudiencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectActivatedAudiencesQuery(baseOptions: Apollo.QueryHookOptions<Types.DirectActivatedAudiencesQuery, Types.DirectActivatedAudiencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DirectActivatedAudiencesQuery, Types.DirectActivatedAudiencesQueryVariables>(DirectActivatedAudiencesDocument, options);
      }
export function useDirectActivatedAudiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DirectActivatedAudiencesQuery, Types.DirectActivatedAudiencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DirectActivatedAudiencesQuery, Types.DirectActivatedAudiencesQueryVariables>(DirectActivatedAudiencesDocument, options);
        }
export type DirectActivatedAudiencesQueryHookResult = ReturnType<typeof useDirectActivatedAudiencesQuery>;
export type DirectActivatedAudiencesLazyQueryHookResult = ReturnType<typeof useDirectActivatedAudiencesLazyQuery>;
export type DirectActivatedAudiencesQueryResult = Apollo.QueryResult<Types.DirectActivatedAudiencesQuery, Types.DirectActivatedAudiencesQueryVariables>;
export const MediaDataRoomAudienceInsightsDocument = gql`
    query MediaDataRoomAudienceInsights($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    enclaveId: id
    driverAttestationHash
    activationType @client
    enableOverlapInsights @client
    advertiserDatasetHash @client
    publisherDatasetHash @client
  }
}
    `;

/**
 * __useMediaDataRoomAudienceInsightsQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomAudienceInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomAudienceInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomAudienceInsightsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomAudienceInsightsQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomAudienceInsightsQuery, Types.MediaDataRoomAudienceInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomAudienceInsightsQuery, Types.MediaDataRoomAudienceInsightsQueryVariables>(MediaDataRoomAudienceInsightsDocument, options);
      }
export function useMediaDataRoomAudienceInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomAudienceInsightsQuery, Types.MediaDataRoomAudienceInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomAudienceInsightsQuery, Types.MediaDataRoomAudienceInsightsQueryVariables>(MediaDataRoomAudienceInsightsDocument, options);
        }
export type MediaDataRoomAudienceInsightsQueryHookResult = ReturnType<typeof useMediaDataRoomAudienceInsightsQuery>;
export type MediaDataRoomAudienceInsightsLazyQueryHookResult = ReturnType<typeof useMediaDataRoomAudienceInsightsLazyQuery>;
export type MediaDataRoomAudienceInsightsQueryResult = Apollo.QueryResult<Types.MediaDataRoomAudienceInsightsQuery, Types.MediaDataRoomAudienceInsightsQueryVariables>;
export const AudienceInsightsAdvertiserDocument = gql`
    query AudienceInsightsAdvertiser($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    enclaveId: id
    driverAttestationHash
    advertiserDatasetHash @client
    publisherDatasetHash @client
  }
}
    `;

/**
 * __useAudienceInsightsAdvertiserQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsAdvertiserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsAdvertiserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsAdvertiserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAudienceInsightsAdvertiserQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsAdvertiserQuery, Types.AudienceInsightsAdvertiserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsAdvertiserQuery, Types.AudienceInsightsAdvertiserQueryVariables>(AudienceInsightsAdvertiserDocument, options);
      }
export function useAudienceInsightsAdvertiserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsAdvertiserQuery, Types.AudienceInsightsAdvertiserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsAdvertiserQuery, Types.AudienceInsightsAdvertiserQueryVariables>(AudienceInsightsAdvertiserDocument, options);
        }
export type AudienceInsightsAdvertiserQueryHookResult = ReturnType<typeof useAudienceInsightsAdvertiserQuery>;
export type AudienceInsightsAdvertiserLazyQueryHookResult = ReturnType<typeof useAudienceInsightsAdvertiserLazyQuery>;
export type AudienceInsightsAdvertiserQueryResult = Apollo.QueryResult<Types.AudienceInsightsAdvertiserQuery, Types.AudienceInsightsAdvertiserQueryVariables>;
export const AudienceInsightsAdvertiserStatisticsDocument = gql`
    query AudienceInsightsAdvertiserStatistics($input: MediaCalculateStatisticsInput!) {
  mediaCalculateStatistics(input: $input) @client {
    advertiserSize
    advertiserNumberOfAudiences
  }
}
    `;

/**
 * __useAudienceInsightsAdvertiserStatisticsQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsAdvertiserStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsAdvertiserStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsAdvertiserStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAudienceInsightsAdvertiserStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsAdvertiserStatisticsQuery, Types.AudienceInsightsAdvertiserStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsAdvertiserStatisticsQuery, Types.AudienceInsightsAdvertiserStatisticsQueryVariables>(AudienceInsightsAdvertiserStatisticsDocument, options);
      }
export function useAudienceInsightsAdvertiserStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsAdvertiserStatisticsQuery, Types.AudienceInsightsAdvertiserStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsAdvertiserStatisticsQuery, Types.AudienceInsightsAdvertiserStatisticsQueryVariables>(AudienceInsightsAdvertiserStatisticsDocument, options);
        }
export type AudienceInsightsAdvertiserStatisticsQueryHookResult = ReturnType<typeof useAudienceInsightsAdvertiserStatisticsQuery>;
export type AudienceInsightsAdvertiserStatisticsLazyQueryHookResult = ReturnType<typeof useAudienceInsightsAdvertiserStatisticsLazyQuery>;
export type AudienceInsightsAdvertiserStatisticsQueryResult = Apollo.QueryResult<Types.AudienceInsightsAdvertiserStatisticsQuery, Types.AudienceInsightsAdvertiserStatisticsQueryVariables>;
export const MediaUnpublishAdvertiserDatasetDocument = gql`
    mutation MediaUnpublishAdvertiserDataset($dataRoomId: String!, $driverAttestationHash: String!) {
  mediaUnpublishAdvertiserDataset(
    input: {dataRoomId: $dataRoomId, driverAttestationHash: $driverAttestationHash}
  ) @client
}
    `;
export type MediaUnpublishAdvertiserDatasetMutationFn = Apollo.MutationFunction<Types.MediaUnpublishAdvertiserDatasetMutation, Types.MediaUnpublishAdvertiserDatasetMutationVariables>;

/**
 * __useMediaUnpublishAdvertiserDatasetMutation__
 *
 * To run a mutation, you first call `useMediaUnpublishAdvertiserDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaUnpublishAdvertiserDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaUnpublishAdvertiserDatasetMutation, { data, loading, error }] = useMediaUnpublishAdvertiserDatasetMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *   },
 * });
 */
export function useMediaUnpublishAdvertiserDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.MediaUnpublishAdvertiserDatasetMutation, Types.MediaUnpublishAdvertiserDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MediaUnpublishAdvertiserDatasetMutation, Types.MediaUnpublishAdvertiserDatasetMutationVariables>(MediaUnpublishAdvertiserDatasetDocument, options);
      }
export type MediaUnpublishAdvertiserDatasetMutationHookResult = ReturnType<typeof useMediaUnpublishAdvertiserDatasetMutation>;
export type MediaUnpublishAdvertiserDatasetMutationResult = Apollo.MutationResult<Types.MediaUnpublishAdvertiserDatasetMutation>;
export type MediaUnpublishAdvertiserDatasetMutationOptions = Apollo.BaseMutationOptions<Types.MediaUnpublishAdvertiserDatasetMutation, Types.MediaUnpublishAdvertiserDatasetMutationVariables>;
export const AudienceInsightsPublisherDocument = gql`
    query AudienceInsightsPublisher($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    enclaveId: id
    driverAttestationHash
    publisherDatasetHash @client
    advertiserDatasetHash @client
  }
}
    `;

/**
 * __useAudienceInsightsPublisherQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsPublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsPublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsPublisherQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAudienceInsightsPublisherQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsPublisherQuery, Types.AudienceInsightsPublisherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsPublisherQuery, Types.AudienceInsightsPublisherQueryVariables>(AudienceInsightsPublisherDocument, options);
      }
export function useAudienceInsightsPublisherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsPublisherQuery, Types.AudienceInsightsPublisherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsPublisherQuery, Types.AudienceInsightsPublisherQueryVariables>(AudienceInsightsPublisherDocument, options);
        }
export type AudienceInsightsPublisherQueryHookResult = ReturnType<typeof useAudienceInsightsPublisherQuery>;
export type AudienceInsightsPublisherLazyQueryHookResult = ReturnType<typeof useAudienceInsightsPublisherLazyQuery>;
export type AudienceInsightsPublisherQueryResult = Apollo.QueryResult<Types.AudienceInsightsPublisherQuery, Types.AudienceInsightsPublisherQueryVariables>;
export const AudienceInsightsPublisherStatisticsDocument = gql`
    query AudienceInsightsPublisherStatistics($input: MediaCalculateStatisticsInput!) {
  mediaCalculateStatistics(input: $input) @client {
    publisherNumberOfSegments
  }
}
    `;

/**
 * __useAudienceInsightsPublisherStatisticsQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsPublisherStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsPublisherStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsPublisherStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAudienceInsightsPublisherStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsPublisherStatisticsQuery, Types.AudienceInsightsPublisherStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsPublisherStatisticsQuery, Types.AudienceInsightsPublisherStatisticsQueryVariables>(AudienceInsightsPublisherStatisticsDocument, options);
      }
export function useAudienceInsightsPublisherStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsPublisherStatisticsQuery, Types.AudienceInsightsPublisherStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsPublisherStatisticsQuery, Types.AudienceInsightsPublisherStatisticsQueryVariables>(AudienceInsightsPublisherStatisticsDocument, options);
        }
export type AudienceInsightsPublisherStatisticsQueryHookResult = ReturnType<typeof useAudienceInsightsPublisherStatisticsQuery>;
export type AudienceInsightsPublisherStatisticsLazyQueryHookResult = ReturnType<typeof useAudienceInsightsPublisherStatisticsLazyQuery>;
export type AudienceInsightsPublisherStatisticsQueryResult = Apollo.QueryResult<Types.AudienceInsightsPublisherStatisticsQuery, Types.AudienceInsightsPublisherStatisticsQueryVariables>;
export const MediaUnpublishPublisherDatasetDocument = gql`
    mutation MediaUnpublishPublisherDataset($dataRoomId: String!, $driverAttestationHash: String!) {
  mediaUnpublishPublisherDataset(
    input: {dataRoomId: $dataRoomId, driverAttestationHash: $driverAttestationHash}
  ) @client
}
    `;
export type MediaUnpublishPublisherDatasetMutationFn = Apollo.MutationFunction<Types.MediaUnpublishPublisherDatasetMutation, Types.MediaUnpublishPublisherDatasetMutationVariables>;

/**
 * __useMediaUnpublishPublisherDatasetMutation__
 *
 * To run a mutation, you first call `useMediaUnpublishPublisherDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaUnpublishPublisherDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaUnpublishPublisherDatasetMutation, { data, loading, error }] = useMediaUnpublishPublisherDatasetMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *   },
 * });
 */
export function useMediaUnpublishPublisherDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.MediaUnpublishPublisherDatasetMutation, Types.MediaUnpublishPublisherDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MediaUnpublishPublisherDatasetMutation, Types.MediaUnpublishPublisherDatasetMutationVariables>(MediaUnpublishPublisherDatasetDocument, options);
      }
export type MediaUnpublishPublisherDatasetMutationHookResult = ReturnType<typeof useMediaUnpublishPublisherDatasetMutation>;
export type MediaUnpublishPublisherDatasetMutationResult = Apollo.MutationResult<Types.MediaUnpublishPublisherDatasetMutation>;
export type MediaUnpublishPublisherDatasetMutationOptions = Apollo.BaseMutationOptions<Types.MediaUnpublishPublisherDatasetMutation, Types.MediaUnpublishPublisherDatasetMutationVariables>;
export const AudienceInsightsStatisticsDocument = gql`
    query AudienceInsightsStatistics($dataRoomId: String!, $driverAttestationHash: String!) {
  mediaCalculateStatistics(
    input: {dataRoomId: $dataRoomId, driverAttestationHash: $driverAttestationHash}
  ) @client {
    advertiserSize
  }
  mediaCalculateOverlapByAudience(
    input: {dataRoomId: $dataRoomId, driverAttestationHash: $driverAttestationHash}
  ) @client {
    overlapCsv
  }
}
    `;

/**
 * __useAudienceInsightsStatisticsQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsStatisticsQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *   },
 * });
 */
export function useAudienceInsightsStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsStatisticsQuery, Types.AudienceInsightsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsStatisticsQuery, Types.AudienceInsightsStatisticsQueryVariables>(AudienceInsightsStatisticsDocument, options);
      }
export function useAudienceInsightsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsStatisticsQuery, Types.AudienceInsightsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsStatisticsQuery, Types.AudienceInsightsStatisticsQueryVariables>(AudienceInsightsStatisticsDocument, options);
        }
export type AudienceInsightsStatisticsQueryHookResult = ReturnType<typeof useAudienceInsightsStatisticsQuery>;
export type AudienceInsightsStatisticsLazyQueryHookResult = ReturnType<typeof useAudienceInsightsStatisticsLazyQuery>;
export type AudienceInsightsStatisticsQueryResult = Apollo.QueryResult<Types.AudienceInsightsStatisticsQuery, Types.AudienceInsightsStatisticsQueryVariables>;
export const MediaDataRoomActivationTypeDocument = gql`
    query MediaDataRoomActivationType($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    enclaveId: id
    driverAttestationHash
    activationType @client
  }
}
    `;

/**
 * __useMediaDataRoomActivationTypeQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomActivationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomActivationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomActivationTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomActivationTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomActivationTypeQuery, Types.MediaDataRoomActivationTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomActivationTypeQuery, Types.MediaDataRoomActivationTypeQueryVariables>(MediaDataRoomActivationTypeDocument, options);
      }
export function useMediaDataRoomActivationTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomActivationTypeQuery, Types.MediaDataRoomActivationTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomActivationTypeQuery, Types.MediaDataRoomActivationTypeQueryVariables>(MediaDataRoomActivationTypeDocument, options);
        }
export type MediaDataRoomActivationTypeQueryHookResult = ReturnType<typeof useMediaDataRoomActivationTypeQuery>;
export type MediaDataRoomActivationTypeLazyQueryHookResult = ReturnType<typeof useMediaDataRoomActivationTypeLazyQuery>;
export type MediaDataRoomActivationTypeQueryResult = Apollo.QueryResult<Types.MediaDataRoomActivationTypeQuery, Types.MediaDataRoomActivationTypeQueryVariables>;
export const AudienceInsightsDocument = gql`
    query AudienceInsights($input: MediaCalculateOverlapInsightsInput!) {
  mediaCalculateOverlapInsights(input: $input) @client {
    insightsCsv
  }
}
    `;

/**
 * __useAudienceInsightsQuery__
 *
 * To run a query within a React component, call `useAudienceInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceInsightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAudienceInsightsQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceInsightsQuery, Types.AudienceInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceInsightsQuery, Types.AudienceInsightsQueryVariables>(AudienceInsightsDocument, options);
      }
export function useAudienceInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceInsightsQuery, Types.AudienceInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceInsightsQuery, Types.AudienceInsightsQueryVariables>(AudienceInsightsDocument, options);
        }
export type AudienceInsightsQueryHookResult = ReturnType<typeof useAudienceInsightsQuery>;
export type AudienceInsightsLazyQueryHookResult = ReturnType<typeof useAudienceInsightsLazyQuery>;
export type AudienceInsightsQueryResult = Apollo.QueryResult<Types.AudienceInsightsQuery, Types.AudienceInsightsQueryVariables>;
export const AudienceTypesDocument = gql`
    query AudienceTypes($input: MediaRetrieveAudienceTypesInput!) {
  mediaRetrieveAudienceTypes(input: $input) @client {
    audienceTypes
  }
}
    `;

/**
 * __useAudienceTypesQuery__
 *
 * To run a query within a React component, call `useAudienceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAudienceTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.AudienceTypesQuery, Types.AudienceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AudienceTypesQuery, Types.AudienceTypesQueryVariables>(AudienceTypesDocument, options);
      }
export function useAudienceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AudienceTypesQuery, Types.AudienceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AudienceTypesQuery, Types.AudienceTypesQueryVariables>(AudienceTypesDocument, options);
        }
export type AudienceTypesQueryHookResult = ReturnType<typeof useAudienceTypesQuery>;
export type AudienceTypesLazyQueryHookResult = ReturnType<typeof useAudienceTypesLazyQuery>;
export type AudienceTypesQueryResult = Apollo.QueryResult<Types.AudienceTypesQuery, Types.AudienceTypesQueryVariables>;
export const MediaInviteParticipantsDocument = gql`
    mutation MediaInviteParticipants($input: InviteParticipantsInput!) {
  publishedDataRoom {
    inviteParticipants(input: $input) {
      id
    }
  }
}
    `;
export type MediaInviteParticipantsMutationFn = Apollo.MutationFunction<Types.MediaInviteParticipantsMutation, Types.MediaInviteParticipantsMutationVariables>;

/**
 * __useMediaInviteParticipantsMutation__
 *
 * To run a mutation, you first call `useMediaInviteParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaInviteParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaInviteParticipantsMutation, { data, loading, error }] = useMediaInviteParticipantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaInviteParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<Types.MediaInviteParticipantsMutation, Types.MediaInviteParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MediaInviteParticipantsMutation, Types.MediaInviteParticipantsMutationVariables>(MediaInviteParticipantsDocument, options);
      }
export type MediaInviteParticipantsMutationHookResult = ReturnType<typeof useMediaInviteParticipantsMutation>;
export type MediaInviteParticipantsMutationResult = Apollo.MutationResult<Types.MediaInviteParticipantsMutation>;
export type MediaInviteParticipantsMutationOptions = Apollo.BaseMutationOptions<Types.MediaInviteParticipantsMutation, Types.MediaInviteParticipantsMutationVariables>;
export const MediaPublishDraftDataRoomDocument = gql`
    mutation MediaPublishDraftDataRoom($name: String!, $mainPublisherUserEmail: String!, $mainAdvertiserUserEmail: String!, $publisherUserEmails: [String!]!, $advertiserUserEmails: [String!]!, $observerUserEmails: [String!]!, $agencyUserEmails: [String!]!, $enableOverlapInsights: Boolean!, $activationType: ActivationType, $activationDownloadByPublisher: Boolean!, $activationDownloadByAdvertiser: Boolean!, $activationDownloadByAgency: Boolean!) {
  mediaPublishDraftDataRoom(
    input: {name: $name, mainPublisherUserEmail: $mainPublisherUserEmail, mainAdvertiserUserEmail: $mainAdvertiserUserEmail, publisherUserEmails: $publisherUserEmails, advertiserUserEmails: $advertiserUserEmails, observerUserEmails: $observerUserEmails, agencyUserEmails: $agencyUserEmails, enableOverlapInsights: $enableOverlapInsights, activationType: $activationType, activationDownloadByPublisher: $activationDownloadByPublisher, activationDownloadByAdvertiser: $activationDownloadByAdvertiser, activationDownloadByAgency: $activationDownloadByAgency}
  ) @client {
    id
    driverAttestationHash
  }
}
    `;
export type MediaPublishDraftDataRoomMutationFn = Apollo.MutationFunction<Types.MediaPublishDraftDataRoomMutation, Types.MediaPublishDraftDataRoomMutationVariables>;

/**
 * __useMediaPublishDraftDataRoomMutation__
 *
 * To run a mutation, you first call `useMediaPublishDraftDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaPublishDraftDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaPublishDraftDataRoomMutation, { data, loading, error }] = useMediaPublishDraftDataRoomMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mainPublisherUserEmail: // value for 'mainPublisherUserEmail'
 *      mainAdvertiserUserEmail: // value for 'mainAdvertiserUserEmail'
 *      publisherUserEmails: // value for 'publisherUserEmails'
 *      advertiserUserEmails: // value for 'advertiserUserEmails'
 *      observerUserEmails: // value for 'observerUserEmails'
 *      agencyUserEmails: // value for 'agencyUserEmails'
 *      enableOverlapInsights: // value for 'enableOverlapInsights'
 *      activationType: // value for 'activationType'
 *      activationDownloadByPublisher: // value for 'activationDownloadByPublisher'
 *      activationDownloadByAdvertiser: // value for 'activationDownloadByAdvertiser'
 *      activationDownloadByAgency: // value for 'activationDownloadByAgency'
 *   },
 * });
 */
export function useMediaPublishDraftDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.MediaPublishDraftDataRoomMutation, Types.MediaPublishDraftDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MediaPublishDraftDataRoomMutation, Types.MediaPublishDraftDataRoomMutationVariables>(MediaPublishDraftDataRoomDocument, options);
      }
export type MediaPublishDraftDataRoomMutationHookResult = ReturnType<typeof useMediaPublishDraftDataRoomMutation>;
export type MediaPublishDraftDataRoomMutationResult = Apollo.MutationResult<Types.MediaPublishDraftDataRoomMutation>;
export type MediaPublishDraftDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.MediaPublishDraftDataRoomMutation, Types.MediaPublishDraftDataRoomMutationVariables>;
export const MediaDataRoomMainbarDocument = gql`
    query MediaDataRoomMainbar($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    title: name @client
  }
}
    `;

/**
 * __useMediaDataRoomMainbarQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomMainbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomMainbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomMainbarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomMainbarQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomMainbarQuery, Types.MediaDataRoomMainbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomMainbarQuery, Types.MediaDataRoomMainbarQueryVariables>(MediaDataRoomMainbarDocument, options);
      }
export function useMediaDataRoomMainbarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomMainbarQuery, Types.MediaDataRoomMainbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomMainbarQuery, Types.MediaDataRoomMainbarQueryVariables>(MediaDataRoomMainbarDocument, options);
        }
export type MediaDataRoomMainbarQueryHookResult = ReturnType<typeof useMediaDataRoomMainbarQuery>;
export type MediaDataRoomMainbarLazyQueryHookResult = ReturnType<typeof useMediaDataRoomMainbarLazyQuery>;
export type MediaDataRoomMainbarQueryResult = Apollo.QueryResult<Types.MediaDataRoomMainbarQuery, Types.MediaDataRoomMainbarQueryVariables>;
export const MediaDataRoomSummaryDocument = gql`
    query MediaDataRoomSummary($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    name @client
    activationDownloadByAdvertiser @client
    activationDownloadByPublisher @client
    activationDownloadByAgency @client
    activationType @client
    advertiserUserEmails @client
    enableOverlapInsights @client
    observerUserEmails @client
    publisherUserEmails @client
    agencyUserEmails @client
  }
}
    `;

/**
 * __useMediaDataRoomSummaryQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomSummaryQuery, Types.MediaDataRoomSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomSummaryQuery, Types.MediaDataRoomSummaryQueryVariables>(MediaDataRoomSummaryDocument, options);
      }
export function useMediaDataRoomSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomSummaryQuery, Types.MediaDataRoomSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomSummaryQuery, Types.MediaDataRoomSummaryQueryVariables>(MediaDataRoomSummaryDocument, options);
        }
export type MediaDataRoomSummaryQueryHookResult = ReturnType<typeof useMediaDataRoomSummaryQuery>;
export type MediaDataRoomSummaryLazyQueryHookResult = ReturnType<typeof useMediaDataRoomSummaryLazyQuery>;
export type MediaDataRoomSummaryQueryResult = Apollo.QueryResult<Types.MediaDataRoomSummaryQuery, Types.MediaDataRoomSummaryQueryVariables>;
export const MediaDataRoomDatasetHashesDocument = gql`
    query MediaDataRoomDatasetHashes($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    activationType @client
    advertiserDatasetHash @client
    publisherDatasetHash @client
  }
}
    `;

/**
 * __useMediaDataRoomDatasetHashesQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomDatasetHashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomDatasetHashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomDatasetHashesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomDatasetHashesQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomDatasetHashesQuery, Types.MediaDataRoomDatasetHashesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomDatasetHashesQuery, Types.MediaDataRoomDatasetHashesQueryVariables>(MediaDataRoomDatasetHashesDocument, options);
      }
export function useMediaDataRoomDatasetHashesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomDatasetHashesQuery, Types.MediaDataRoomDatasetHashesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomDatasetHashesQuery, Types.MediaDataRoomDatasetHashesQueryVariables>(MediaDataRoomDatasetHashesDocument, options);
        }
export type MediaDataRoomDatasetHashesQueryHookResult = ReturnType<typeof useMediaDataRoomDatasetHashesQuery>;
export type MediaDataRoomDatasetHashesLazyQueryHookResult = ReturnType<typeof useMediaDataRoomDatasetHashesLazyQuery>;
export type MediaDataRoomDatasetHashesQueryResult = Apollo.QueryResult<Types.MediaDataRoomDatasetHashesQuery, Types.MediaDataRoomDatasetHashesQueryVariables>;
export const MediaDataRoomHashesDocument = gql`
    query MediaDataRoomHashes($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    enclaveId: id
    driverAttestationHash
  }
}
    `;

/**
 * __useMediaDataRoomHashesQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomHashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomHashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomHashesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaDataRoomHashesQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomHashesQuery, Types.MediaDataRoomHashesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomHashesQuery, Types.MediaDataRoomHashesQueryVariables>(MediaDataRoomHashesDocument, options);
      }
export function useMediaDataRoomHashesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomHashesQuery, Types.MediaDataRoomHashesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomHashesQuery, Types.MediaDataRoomHashesQueryVariables>(MediaDataRoomHashesDocument, options);
        }
export type MediaDataRoomHashesQueryHookResult = ReturnType<typeof useMediaDataRoomHashesQuery>;
export type MediaDataRoomHashesLazyQueryHookResult = ReturnType<typeof useMediaDataRoomHashesLazyQuery>;
export type MediaDataRoomHashesQueryResult = Apollo.QueryResult<Types.MediaDataRoomHashesQuery, Types.MediaDataRoomHashesQueryVariables>;
export const PublishMediaAdvertiserDatasetDocument = gql`
    mutation PublishMediaAdvertiserDataset($input: PublishMediaDatasetInput!) {
  mediaPublishAdvertiserDataset(input: $input) @client
}
    `;
export type PublishMediaAdvertiserDatasetMutationFn = Apollo.MutationFunction<Types.PublishMediaAdvertiserDatasetMutation, Types.PublishMediaAdvertiserDatasetMutationVariables>;

/**
 * __usePublishMediaAdvertiserDatasetMutation__
 *
 * To run a mutation, you first call `usePublishMediaAdvertiserDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMediaAdvertiserDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMediaAdvertiserDatasetMutation, { data, loading, error }] = usePublishMediaAdvertiserDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishMediaAdvertiserDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishMediaAdvertiserDatasetMutation, Types.PublishMediaAdvertiserDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishMediaAdvertiserDatasetMutation, Types.PublishMediaAdvertiserDatasetMutationVariables>(PublishMediaAdvertiserDatasetDocument, options);
      }
export type PublishMediaAdvertiserDatasetMutationHookResult = ReturnType<typeof usePublishMediaAdvertiserDatasetMutation>;
export type PublishMediaAdvertiserDatasetMutationResult = Apollo.MutationResult<Types.PublishMediaAdvertiserDatasetMutation>;
export type PublishMediaAdvertiserDatasetMutationOptions = Apollo.BaseMutationOptions<Types.PublishMediaAdvertiserDatasetMutation, Types.PublishMediaAdvertiserDatasetMutationVariables>;
export const PublishMediaPublisherDatasetDocument = gql`
    mutation PublishMediaPublisherDataset($input: PublishMediaDatasetInput!) {
  mediaPublishPublisherDataset(input: $input) @client
}
    `;
export type PublishMediaPublisherDatasetMutationFn = Apollo.MutationFunction<Types.PublishMediaPublisherDatasetMutation, Types.PublishMediaPublisherDatasetMutationVariables>;

/**
 * __usePublishMediaPublisherDatasetMutation__
 *
 * To run a mutation, you first call `usePublishMediaPublisherDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMediaPublisherDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMediaPublisherDatasetMutation, { data, loading, error }] = usePublishMediaPublisherDatasetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishMediaPublisherDatasetMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishMediaPublisherDatasetMutation, Types.PublishMediaPublisherDatasetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishMediaPublisherDatasetMutation, Types.PublishMediaPublisherDatasetMutationVariables>(PublishMediaPublisherDatasetDocument, options);
      }
export type PublishMediaPublisherDatasetMutationHookResult = ReturnType<typeof usePublishMediaPublisherDatasetMutation>;
export type PublishMediaPublisherDatasetMutationResult = Apollo.MutationResult<Types.PublishMediaPublisherDatasetMutation>;
export type PublishMediaPublisherDatasetMutationOptions = Apollo.BaseMutationOptions<Types.PublishMediaPublisherDatasetMutation, Types.PublishMediaPublisherDatasetMutationVariables>;
export const DeprovisionDataLabDocument = gql`
    mutation DeprovisionDataLab($dataRoomId: String!) {
  dataLab {
    deprovisionDataLab(lookalikeMediaDcrId: $dataRoomId) {
      id
    }
  }
}
    `;
export type DeprovisionDataLabMutationFn = Apollo.MutationFunction<Types.DeprovisionDataLabMutation, Types.DeprovisionDataLabMutationVariables>;

/**
 * __useDeprovisionDataLabMutation__
 *
 * To run a mutation, you first call `useDeprovisionDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprovisionDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprovisionDataLabMutation, { data, loading, error }] = useDeprovisionDataLabMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useDeprovisionDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeprovisionDataLabMutation, Types.DeprovisionDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeprovisionDataLabMutation, Types.DeprovisionDataLabMutationVariables>(DeprovisionDataLabDocument, options);
      }
export type DeprovisionDataLabMutationHookResult = ReturnType<typeof useDeprovisionDataLabMutation>;
export type DeprovisionDataLabMutationResult = Apollo.MutationResult<Types.DeprovisionDataLabMutation>;
export type DeprovisionDataLabMutationOptions = Apollo.BaseMutationOptions<Types.DeprovisionDataLabMutation, Types.DeprovisionDataLabMutationVariables>;
export const ProvisionDataLabDocument = gql`
    mutation ProvisionDataLab($input: ProvisionDataLabInput!) {
  dataLab {
    provisionDataLab(input: $input) {
      id
    }
  }
}
    `;
export type ProvisionDataLabMutationFn = Apollo.MutationFunction<Types.ProvisionDataLabMutation, Types.ProvisionDataLabMutationVariables>;

/**
 * __useProvisionDataLabMutation__
 *
 * To run a mutation, you first call `useProvisionDataLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionDataLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionDataLabMutation, { data, loading, error }] = useProvisionDataLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvisionDataLabMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvisionDataLabMutation, Types.ProvisionDataLabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvisionDataLabMutation, Types.ProvisionDataLabMutationVariables>(ProvisionDataLabDocument, options);
      }
export type ProvisionDataLabMutationHookResult = ReturnType<typeof useProvisionDataLabMutation>;
export type ProvisionDataLabMutationResult = Apollo.MutationResult<Types.ProvisionDataLabMutation>;
export type ProvisionDataLabMutationOptions = Apollo.BaseMutationOptions<Types.ProvisionDataLabMutation, Types.ProvisionDataLabMutationVariables>;
export const PublishedLookalikeMediaDataRoomDocument = gql`
    query PublishedLookalikeMediaDataRoom($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    isStopped
    isActive
    deactivatedAt
    createdAt
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomQuery, Types.PublishedLookalikeMediaDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomQuery, Types.PublishedLookalikeMediaDataRoomQueryVariables>(PublishedLookalikeMediaDataRoomDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomQuery, Types.PublishedLookalikeMediaDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomQuery, Types.PublishedLookalikeMediaDataRoomQueryVariables>(PublishedLookalikeMediaDataRoomDocument, options);
        }
export type PublishedLookalikeMediaDataRoomQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomQuery>;
export type PublishedLookalikeMediaDataRoomLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomLazyQuery>;
export type PublishedLookalikeMediaDataRoomQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomQuery, Types.PublishedLookalikeMediaDataRoomQueryVariables>;
export const PublishedLookalikeMediaDataRoomDataLabDataDocument = gql`
    query PublishedLookalikeMediaDataRoomDataLabData($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    wasDataLabPublishedBefore
    publishedDataLab {
      ...FullDataLab
    }
  }
}
    ${FullDataLabFragment}`;

/**
 * __usePublishedLookalikeMediaDataRoomDataLabDataQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomDataLabDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomDataLabDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomDataLabDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomDataLabDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomDataLabDataQuery, Types.PublishedLookalikeMediaDataRoomDataLabDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomDataLabDataQuery, Types.PublishedLookalikeMediaDataRoomDataLabDataQueryVariables>(PublishedLookalikeMediaDataRoomDataLabDataDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomDataLabDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomDataLabDataQuery, Types.PublishedLookalikeMediaDataRoomDataLabDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomDataLabDataQuery, Types.PublishedLookalikeMediaDataRoomDataLabDataQueryVariables>(PublishedLookalikeMediaDataRoomDataLabDataDocument, options);
        }
export type PublishedLookalikeMediaDataRoomDataLabDataQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomDataLabDataQuery>;
export type PublishedLookalikeMediaDataRoomDataLabDataLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomDataLabDataLazyQuery>;
export type PublishedLookalikeMediaDataRoomDataLabDataQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomDataLabDataQuery, Types.PublishedLookalikeMediaDataRoomDataLabDataQueryVariables>;
export const PublishedLookalikeMediaDataRoomOrganizationsDocument = gql`
    query PublishedLookalikeMediaDataRoomOrganizations($advertiserEmail: String!, $publisherEmail: String!) {
  advertiserOrganization: userByEmail(email: $advertiserEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
  publisherOrganization: userByEmail(email: $publisherEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomOrganizationsQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomOrganizationsQuery({
 *   variables: {
 *      advertiserEmail: // value for 'advertiserEmail'
 *      publisherEmail: // value for 'publisherEmail'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomOrganizationsQuery, Types.PublishedLookalikeMediaDataRoomOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomOrganizationsQuery, Types.PublishedLookalikeMediaDataRoomOrganizationsQueryVariables>(PublishedLookalikeMediaDataRoomOrganizationsDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomOrganizationsQuery, Types.PublishedLookalikeMediaDataRoomOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomOrganizationsQuery, Types.PublishedLookalikeMediaDataRoomOrganizationsQueryVariables>(PublishedLookalikeMediaDataRoomOrganizationsDocument, options);
        }
export type PublishedLookalikeMediaDataRoomOrganizationsQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomOrganizationsQuery>;
export type PublishedLookalikeMediaDataRoomOrganizationsLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomOrganizationsLazyQuery>;
export type PublishedLookalikeMediaDataRoomOrganizationsQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomOrganizationsQuery, Types.PublishedLookalikeMediaDataRoomOrganizationsQueryVariables>;
export const PublishedLookalikeMediaDataRoomActionsDocument = gql`
    query PublishedLookalikeMediaDataRoomActions($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    name: title
    userSettings {
      id
      isArchived
      isFavorite
    }
    owner {
      id
      email
    }
    updatedAt
    createdAt
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomActionsQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomActionsQuery, Types.PublishedLookalikeMediaDataRoomActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomActionsQuery, Types.PublishedLookalikeMediaDataRoomActionsQueryVariables>(PublishedLookalikeMediaDataRoomActionsDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomActionsQuery, Types.PublishedLookalikeMediaDataRoomActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomActionsQuery, Types.PublishedLookalikeMediaDataRoomActionsQueryVariables>(PublishedLookalikeMediaDataRoomActionsDocument, options);
        }
export type PublishedLookalikeMediaDataRoomActionsQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomActionsQuery>;
export type PublishedLookalikeMediaDataRoomActionsLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomActionsLazyQuery>;
export type PublishedLookalikeMediaDataRoomActionsQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomActionsQuery, Types.PublishedLookalikeMediaDataRoomActionsQueryVariables>;
export const LookalikeMediaDataRoomMainbarDocument = gql`
    query LookalikeMediaDataRoomMainbar($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    title
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __useLookalikeMediaDataRoomMainbarQuery__
 *
 * To run a query within a React component, call `useLookalikeMediaDataRoomMainbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookalikeMediaDataRoomMainbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookalikeMediaDataRoomMainbarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLookalikeMediaDataRoomMainbarQuery(baseOptions: Apollo.QueryHookOptions<Types.LookalikeMediaDataRoomMainbarQuery, Types.LookalikeMediaDataRoomMainbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LookalikeMediaDataRoomMainbarQuery, Types.LookalikeMediaDataRoomMainbarQueryVariables>(LookalikeMediaDataRoomMainbarDocument, options);
      }
export function useLookalikeMediaDataRoomMainbarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LookalikeMediaDataRoomMainbarQuery, Types.LookalikeMediaDataRoomMainbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LookalikeMediaDataRoomMainbarQuery, Types.LookalikeMediaDataRoomMainbarQueryVariables>(LookalikeMediaDataRoomMainbarDocument, options);
        }
export type LookalikeMediaDataRoomMainbarQueryHookResult = ReturnType<typeof useLookalikeMediaDataRoomMainbarQuery>;
export type LookalikeMediaDataRoomMainbarLazyQueryHookResult = ReturnType<typeof useLookalikeMediaDataRoomMainbarLazyQuery>;
export type LookalikeMediaDataRoomMainbarQueryResult = Apollo.QueryResult<Types.LookalikeMediaDataRoomMainbarQuery, Types.LookalikeMediaDataRoomMainbarQueryVariables>;
export const ActiveMarketsDocument = gql`
    query ActiveMarkets {
  publisherMarkets {
    nodes {
      id
      rank
      name
    }
  }
}
    `;

/**
 * __useActiveMarketsQuery__
 *
 * To run a query within a React component, call `useActiveMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveMarketsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ActiveMarketsQuery, Types.ActiveMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActiveMarketsQuery, Types.ActiveMarketsQueryVariables>(ActiveMarketsDocument, options);
      }
export function useActiveMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveMarketsQuery, Types.ActiveMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActiveMarketsQuery, Types.ActiveMarketsQueryVariables>(ActiveMarketsDocument, options);
        }
export type ActiveMarketsQueryHookResult = ReturnType<typeof useActiveMarketsQuery>;
export type ActiveMarketsLazyQueryHookResult = ReturnType<typeof useActiveMarketsLazyQuery>;
export type ActiveMarketsQueryResult = Apollo.QueryResult<Types.ActiveMarketsQuery, Types.ActiveMarketsQueryVariables>;
export const PublishedDataRoomPasswordRequirementsDocument = gql`
    query PublishedDataRoomPasswordRequirements($id: String!) {
  publishedDataRoom(id: $id) {
    id
    driverAttestationHash
    requirePassword
    password @client
    createdAt
  }
}
    `;

/**
 * __usePublishedDataRoomPasswordRequirementsQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomPasswordRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomPasswordRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomPasswordRequirementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomPasswordRequirementsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomPasswordRequirementsQuery, Types.PublishedDataRoomPasswordRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomPasswordRequirementsQuery, Types.PublishedDataRoomPasswordRequirementsQueryVariables>(PublishedDataRoomPasswordRequirementsDocument, options);
      }
export function usePublishedDataRoomPasswordRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomPasswordRequirementsQuery, Types.PublishedDataRoomPasswordRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomPasswordRequirementsQuery, Types.PublishedDataRoomPasswordRequirementsQueryVariables>(PublishedDataRoomPasswordRequirementsDocument, options);
        }
export type PublishedDataRoomPasswordRequirementsQueryHookResult = ReturnType<typeof usePublishedDataRoomPasswordRequirementsQuery>;
export type PublishedDataRoomPasswordRequirementsLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomPasswordRequirementsLazyQuery>;
export type PublishedDataRoomPasswordRequirementsQueryResult = Apollo.QueryResult<Types.PublishedDataRoomPasswordRequirementsQuery, Types.PublishedDataRoomPasswordRequirementsQueryVariables>;
export const DataRoomAttestationSpecsDocument = gql`
    query DataRoomAttestationSpecs($dcrHash: HexString!, $driverAttestationHash: HexString!) {
  dataRoomAttestationSpecs(
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
  ) @client {
    id
    name
    hash
    isInsecure
    isUnavailable
  }
}
    `;

/**
 * __useDataRoomAttestationSpecsQuery__
 *
 * To run a query within a React component, call `useDataRoomAttestationSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRoomAttestationSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRoomAttestationSpecsQuery({
 *   variables: {
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *   },
 * });
 */
export function useDataRoomAttestationSpecsQuery(baseOptions: Apollo.QueryHookOptions<Types.DataRoomAttestationSpecsQuery, Types.DataRoomAttestationSpecsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataRoomAttestationSpecsQuery, Types.DataRoomAttestationSpecsQueryVariables>(DataRoomAttestationSpecsDocument, options);
      }
export function useDataRoomAttestationSpecsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataRoomAttestationSpecsQuery, Types.DataRoomAttestationSpecsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataRoomAttestationSpecsQuery, Types.DataRoomAttestationSpecsQueryVariables>(DataRoomAttestationSpecsDocument, options);
        }
export type DataRoomAttestationSpecsQueryHookResult = ReturnType<typeof useDataRoomAttestationSpecsQuery>;
export type DataRoomAttestationSpecsLazyQueryHookResult = ReturnType<typeof useDataRoomAttestationSpecsLazyQuery>;
export type DataRoomAttestationSpecsQueryResult = Apollo.QueryResult<Types.DataRoomAttestationSpecsQuery, Types.DataRoomAttestationSpecsQueryVariables>;
export const PublishedDataRoomActionsDocument = gql`
    query PublishedDataRoomActions($id: String!) {
  publishedDataRoom(id: $id) {
    id
    driverAttestationHash
    name: title
    userSettings {
      id
      isArchived
      isFavorite
    }
    owner {
      email
    }
    updatedAt
    createdAt
    isStopped
    deactivatedAt
  }
}
    `;

/**
 * __usePublishedDataRoomActionsQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomActionsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomActionsQuery, Types.PublishedDataRoomActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomActionsQuery, Types.PublishedDataRoomActionsQueryVariables>(PublishedDataRoomActionsDocument, options);
      }
export function usePublishedDataRoomActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomActionsQuery, Types.PublishedDataRoomActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomActionsQuery, Types.PublishedDataRoomActionsQueryVariables>(PublishedDataRoomActionsDocument, options);
        }
export type PublishedDataRoomActionsQueryHookResult = ReturnType<typeof usePublishedDataRoomActionsQuery>;
export type PublishedDataRoomActionsLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomActionsLazyQuery>;
export type PublishedDataRoomActionsQueryResult = Apollo.QueryResult<Types.PublishedDataRoomActionsQuery, Types.PublishedDataRoomActionsQueryVariables>;
export const PublishedDataRoomDuplicateDocument = gql`
    mutation PublishedDataRoomDuplicate($input: ImportDataScienceDataRoomInput!) {
  draftDataRoom {
    importDataScienceDataRoom(input: $input) {
      record {
        id
        title
        description
        owner {
          id
          email
        }
        logo
        enableInteractivity
        enableDevelopment
        userSettings {
          id
          isArchived
          isFavorite
        }
        createdAt
        draftNodes {
          nodes {
            id
            name
          }
        }
        editors {
          nodes {
            user {
              id
              email
            }
            role
          }
        }
        dataNodesOrder
        computeNodesOrder
        participants {
          nodes {
            id
            userEmail
            permissions {
              nodes {
                ... on DraftDataOwnerPermission {
                  node {
                    id
                    name
                  }
                }
                ... on DraftAnalystPermission {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        query {
          dataRooms(
            filter: {isArchived: {equalTo: false}, isFavorite: {equalTo: false}, isOwnedByUser: {equalTo: true}}
            sortBy: {updatedAt: DESCENDING}
          ) {
            nodes {
              ... on DraftDataRoom {
                ...NavigationDrawerDraftDataRoom
              }
              ... on PublishedDataRoom {
                ...NavigationDrawerPublishedDataRoom
              }
              ... on PublishedMediaDataRoom {
                ...NavigationDrawerPublishedMediaDataRoom
              }
              ... on PublishedLookalikeMediaDataRoom {
                ...NavigationDrawerPublishedLookalikeMediaDataRoom
              }
              ... on PublishedMediaInsightsDcr {
                ...NavigationDrawerPublishedMediaInsightsDcr
              }
            }
          }
        }
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}`;
export type PublishedDataRoomDuplicateMutationFn = Apollo.MutationFunction<Types.PublishedDataRoomDuplicateMutation, Types.PublishedDataRoomDuplicateMutationVariables>;

/**
 * __usePublishedDataRoomDuplicateMutation__
 *
 * To run a mutation, you first call `usePublishedDataRoomDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishedDataRoomDuplicateMutation, { data, loading, error }] = usePublishedDataRoomDuplicateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishedDataRoomDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishedDataRoomDuplicateMutation, Types.PublishedDataRoomDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishedDataRoomDuplicateMutation, Types.PublishedDataRoomDuplicateMutationVariables>(PublishedDataRoomDuplicateDocument, options);
      }
export type PublishedDataRoomDuplicateMutationHookResult = ReturnType<typeof usePublishedDataRoomDuplicateMutation>;
export type PublishedDataRoomDuplicateMutationResult = Apollo.MutationResult<Types.PublishedDataRoomDuplicateMutation>;
export type PublishedDataRoomDuplicateMutationOptions = Apollo.BaseMutationOptions<Types.PublishedDataRoomDuplicateMutation, Types.PublishedDataRoomDuplicateMutationVariables>;
export const CreatePublishedDataRoomIsFavoriteDocument = gql`
    mutation CreatePublishedDataRoomIsFavorite($publishedDataRoomId: String!, $isFavorite: Boolean!) {
  publishedDataRoom {
    createUserSettings(
      input: {publishedDataRoomId: $publishedDataRoomId, isFavorite: $isFavorite, isArchived: false}
    ) {
      id
      isFavorite
      isArchived
    }
  }
}
    `;
export type CreatePublishedDataRoomIsFavoriteMutationFn = Apollo.MutationFunction<Types.CreatePublishedDataRoomIsFavoriteMutation, Types.CreatePublishedDataRoomIsFavoriteMutationVariables>;

/**
 * __useCreatePublishedDataRoomIsFavoriteMutation__
 *
 * To run a mutation, you first call `useCreatePublishedDataRoomIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublishedDataRoomIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublishedDataRoomIsFavoriteMutation, { data, loading, error }] = useCreatePublishedDataRoomIsFavoriteMutation({
 *   variables: {
 *      publishedDataRoomId: // value for 'publishedDataRoomId'
 *      isFavorite: // value for 'isFavorite'
 *   },
 * });
 */
export function useCreatePublishedDataRoomIsFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePublishedDataRoomIsFavoriteMutation, Types.CreatePublishedDataRoomIsFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePublishedDataRoomIsFavoriteMutation, Types.CreatePublishedDataRoomIsFavoriteMutationVariables>(CreatePublishedDataRoomIsFavoriteDocument, options);
      }
export type CreatePublishedDataRoomIsFavoriteMutationHookResult = ReturnType<typeof useCreatePublishedDataRoomIsFavoriteMutation>;
export type CreatePublishedDataRoomIsFavoriteMutationResult = Apollo.MutationResult<Types.CreatePublishedDataRoomIsFavoriteMutation>;
export type CreatePublishedDataRoomIsFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.CreatePublishedDataRoomIsFavoriteMutation, Types.CreatePublishedDataRoomIsFavoriteMutationVariables>;
export const PublishedDataRoomIsFavoriteDocument = gql`
    query PublishedDataRoomIsFavorite($id: String!) {
  publishedDataRoom(id: $id) {
    id
    userSettings {
      id
      isFavorite
    }
  }
}
    `;

/**
 * __usePublishedDataRoomIsFavoriteQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomIsFavoriteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomIsFavoriteQuery, Types.PublishedDataRoomIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomIsFavoriteQuery, Types.PublishedDataRoomIsFavoriteQueryVariables>(PublishedDataRoomIsFavoriteDocument, options);
      }
export function usePublishedDataRoomIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomIsFavoriteQuery, Types.PublishedDataRoomIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomIsFavoriteQuery, Types.PublishedDataRoomIsFavoriteQueryVariables>(PublishedDataRoomIsFavoriteDocument, options);
        }
export type PublishedDataRoomIsFavoriteQueryHookResult = ReturnType<typeof usePublishedDataRoomIsFavoriteQuery>;
export type PublishedDataRoomIsFavoriteLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomIsFavoriteLazyQuery>;
export type PublishedDataRoomIsFavoriteQueryResult = Apollo.QueryResult<Types.PublishedDataRoomIsFavoriteQuery, Types.PublishedDataRoomIsFavoriteQueryVariables>;
export const PublishedLookalikeMediaDataRoomIsFavoriteDocument = gql`
    query PublishedLookalikeMediaDataRoomIsFavorite($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    userSettings {
      id
      isFavorite
    }
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomIsFavoriteQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomIsFavoriteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomIsFavoriteQuery, Types.PublishedLookalikeMediaDataRoomIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomIsFavoriteQuery, Types.PublishedLookalikeMediaDataRoomIsFavoriteQueryVariables>(PublishedLookalikeMediaDataRoomIsFavoriteDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomIsFavoriteQuery, Types.PublishedLookalikeMediaDataRoomIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomIsFavoriteQuery, Types.PublishedLookalikeMediaDataRoomIsFavoriteQueryVariables>(PublishedLookalikeMediaDataRoomIsFavoriteDocument, options);
        }
export type PublishedLookalikeMediaDataRoomIsFavoriteQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomIsFavoriteQuery>;
export type PublishedLookalikeMediaDataRoomIsFavoriteLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomIsFavoriteLazyQuery>;
export type PublishedLookalikeMediaDataRoomIsFavoriteQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomIsFavoriteQuery, Types.PublishedLookalikeMediaDataRoomIsFavoriteQueryVariables>;
export const PublishedMediaDataRoomIsFavoriteDocument = gql`
    query PublishedMediaDataRoomIsFavorite($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    userSettings {
      id
      isFavorite
    }
  }
}
    `;

/**
 * __usePublishedMediaDataRoomIsFavoriteQuery__
 *
 * To run a query within a React component, call `usePublishedMediaDataRoomIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaDataRoomIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaDataRoomIsFavoriteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaDataRoomIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaDataRoomIsFavoriteQuery, Types.PublishedMediaDataRoomIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaDataRoomIsFavoriteQuery, Types.PublishedMediaDataRoomIsFavoriteQueryVariables>(PublishedMediaDataRoomIsFavoriteDocument, options);
      }
export function usePublishedMediaDataRoomIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaDataRoomIsFavoriteQuery, Types.PublishedMediaDataRoomIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaDataRoomIsFavoriteQuery, Types.PublishedMediaDataRoomIsFavoriteQueryVariables>(PublishedMediaDataRoomIsFavoriteDocument, options);
        }
export type PublishedMediaDataRoomIsFavoriteQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomIsFavoriteQuery>;
export type PublishedMediaDataRoomIsFavoriteLazyQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomIsFavoriteLazyQuery>;
export type PublishedMediaDataRoomIsFavoriteQueryResult = Apollo.QueryResult<Types.PublishedMediaDataRoomIsFavoriteQuery, Types.PublishedMediaDataRoomIsFavoriteQueryVariables>;
export const PublishedMediaInsightsDcrIsFavoriteDocument = gql`
    query PublishedMediaInsightsDcrIsFavorite($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    userSettings {
      id
      isFavorite
    }
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrIsFavoriteQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrIsFavoriteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrIsFavoriteQuery, Types.PublishedMediaInsightsDcrIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrIsFavoriteQuery, Types.PublishedMediaInsightsDcrIsFavoriteQueryVariables>(PublishedMediaInsightsDcrIsFavoriteDocument, options);
      }
export function usePublishedMediaInsightsDcrIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrIsFavoriteQuery, Types.PublishedMediaInsightsDcrIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrIsFavoriteQuery, Types.PublishedMediaInsightsDcrIsFavoriteQueryVariables>(PublishedMediaInsightsDcrIsFavoriteDocument, options);
        }
export type PublishedMediaInsightsDcrIsFavoriteQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrIsFavoriteQuery>;
export type PublishedMediaInsightsDcrIsFavoriteLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrIsFavoriteLazyQuery>;
export type PublishedMediaInsightsDcrIsFavoriteQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrIsFavoriteQuery, Types.PublishedMediaInsightsDcrIsFavoriteQueryVariables>;
export const UpdatePublishedDataRoomIsFavoriteDocument = gql`
    mutation UpdatePublishedDataRoomIsFavorite($id: String!, $isFavorite: Boolean!) {
  publishedDataRoom {
    updateUserSettings(input: {id: $id, isFavorite: $isFavorite}) {
      id
      isFavorite
    }
  }
}
    `;
export type UpdatePublishedDataRoomIsFavoriteMutationFn = Apollo.MutationFunction<Types.UpdatePublishedDataRoomIsFavoriteMutation, Types.UpdatePublishedDataRoomIsFavoriteMutationVariables>;

/**
 * __useUpdatePublishedDataRoomIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdatePublishedDataRoomIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishedDataRoomIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishedDataRoomIsFavoriteMutation, { data, loading, error }] = useUpdatePublishedDataRoomIsFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFavorite: // value for 'isFavorite'
 *   },
 * });
 */
export function useUpdatePublishedDataRoomIsFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePublishedDataRoomIsFavoriteMutation, Types.UpdatePublishedDataRoomIsFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePublishedDataRoomIsFavoriteMutation, Types.UpdatePublishedDataRoomIsFavoriteMutationVariables>(UpdatePublishedDataRoomIsFavoriteDocument, options);
      }
export type UpdatePublishedDataRoomIsFavoriteMutationHookResult = ReturnType<typeof useUpdatePublishedDataRoomIsFavoriteMutation>;
export type UpdatePublishedDataRoomIsFavoriteMutationResult = Apollo.MutationResult<Types.UpdatePublishedDataRoomIsFavoriteMutation>;
export type UpdatePublishedDataRoomIsFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePublishedDataRoomIsFavoriteMutation, Types.UpdatePublishedDataRoomIsFavoriteMutationVariables>;
export const CreatePublishedDataRoomIsHiddenDocument = gql`
    mutation CreatePublishedDataRoomIsHidden($publishedDataRoomId: String!, $isHidden: Boolean!) {
  publishedDataRoom {
    createUserSettings(
      input: {publishedDataRoomId: $publishedDataRoomId, isArchived: $isHidden, isFavorite: false}
    ) {
      id
      isArchived
      isFavorite
    }
  }
}
    `;
export type CreatePublishedDataRoomIsHiddenMutationFn = Apollo.MutationFunction<Types.CreatePublishedDataRoomIsHiddenMutation, Types.CreatePublishedDataRoomIsHiddenMutationVariables>;

/**
 * __useCreatePublishedDataRoomIsHiddenMutation__
 *
 * To run a mutation, you first call `useCreatePublishedDataRoomIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublishedDataRoomIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublishedDataRoomIsHiddenMutation, { data, loading, error }] = useCreatePublishedDataRoomIsHiddenMutation({
 *   variables: {
 *      publishedDataRoomId: // value for 'publishedDataRoomId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useCreatePublishedDataRoomIsHiddenMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePublishedDataRoomIsHiddenMutation, Types.CreatePublishedDataRoomIsHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePublishedDataRoomIsHiddenMutation, Types.CreatePublishedDataRoomIsHiddenMutationVariables>(CreatePublishedDataRoomIsHiddenDocument, options);
      }
export type CreatePublishedDataRoomIsHiddenMutationHookResult = ReturnType<typeof useCreatePublishedDataRoomIsHiddenMutation>;
export type CreatePublishedDataRoomIsHiddenMutationResult = Apollo.MutationResult<Types.CreatePublishedDataRoomIsHiddenMutation>;
export type CreatePublishedDataRoomIsHiddenMutationOptions = Apollo.BaseMutationOptions<Types.CreatePublishedDataRoomIsHiddenMutation, Types.CreatePublishedDataRoomIsHiddenMutationVariables>;
export const PublishedDataRoomIsHiddenDocument = gql`
    query PublishedDataRoomIsHidden($id: String!) {
  publishedDataRoom(id: $id) {
    id
    userSettings {
      id
      isHidden: isArchived
    }
  }
}
    `;

/**
 * __usePublishedDataRoomIsHiddenQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomIsHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomIsHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomIsHiddenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomIsHiddenQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomIsHiddenQuery, Types.PublishedDataRoomIsHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomIsHiddenQuery, Types.PublishedDataRoomIsHiddenQueryVariables>(PublishedDataRoomIsHiddenDocument, options);
      }
export function usePublishedDataRoomIsHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomIsHiddenQuery, Types.PublishedDataRoomIsHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomIsHiddenQuery, Types.PublishedDataRoomIsHiddenQueryVariables>(PublishedDataRoomIsHiddenDocument, options);
        }
export type PublishedDataRoomIsHiddenQueryHookResult = ReturnType<typeof usePublishedDataRoomIsHiddenQuery>;
export type PublishedDataRoomIsHiddenLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomIsHiddenLazyQuery>;
export type PublishedDataRoomIsHiddenQueryResult = Apollo.QueryResult<Types.PublishedDataRoomIsHiddenQuery, Types.PublishedDataRoomIsHiddenQueryVariables>;
export const PublishedLookalikeMediaDataRoomIsHiddenDocument = gql`
    query PublishedLookalikeMediaDataRoomIsHidden($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    userSettings {
      id
      isHidden: isArchived
    }
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomIsHiddenQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomIsHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomIsHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomIsHiddenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomIsHiddenQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomIsHiddenQuery, Types.PublishedLookalikeMediaDataRoomIsHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomIsHiddenQuery, Types.PublishedLookalikeMediaDataRoomIsHiddenQueryVariables>(PublishedLookalikeMediaDataRoomIsHiddenDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomIsHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomIsHiddenQuery, Types.PublishedLookalikeMediaDataRoomIsHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomIsHiddenQuery, Types.PublishedLookalikeMediaDataRoomIsHiddenQueryVariables>(PublishedLookalikeMediaDataRoomIsHiddenDocument, options);
        }
export type PublishedLookalikeMediaDataRoomIsHiddenQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomIsHiddenQuery>;
export type PublishedLookalikeMediaDataRoomIsHiddenLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomIsHiddenLazyQuery>;
export type PublishedLookalikeMediaDataRoomIsHiddenQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomIsHiddenQuery, Types.PublishedLookalikeMediaDataRoomIsHiddenQueryVariables>;
export const PublishedMediaDataRoomIsHiddenDocument = gql`
    query PublishedMediaDataRoomIsHidden($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    userSettings {
      id
      isHidden: isArchived
    }
  }
}
    `;

/**
 * __usePublishedMediaDataRoomIsHiddenQuery__
 *
 * To run a query within a React component, call `usePublishedMediaDataRoomIsHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaDataRoomIsHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaDataRoomIsHiddenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaDataRoomIsHiddenQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaDataRoomIsHiddenQuery, Types.PublishedMediaDataRoomIsHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaDataRoomIsHiddenQuery, Types.PublishedMediaDataRoomIsHiddenQueryVariables>(PublishedMediaDataRoomIsHiddenDocument, options);
      }
export function usePublishedMediaDataRoomIsHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaDataRoomIsHiddenQuery, Types.PublishedMediaDataRoomIsHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaDataRoomIsHiddenQuery, Types.PublishedMediaDataRoomIsHiddenQueryVariables>(PublishedMediaDataRoomIsHiddenDocument, options);
        }
export type PublishedMediaDataRoomIsHiddenQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomIsHiddenQuery>;
export type PublishedMediaDataRoomIsHiddenLazyQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomIsHiddenLazyQuery>;
export type PublishedMediaDataRoomIsHiddenQueryResult = Apollo.QueryResult<Types.PublishedMediaDataRoomIsHiddenQuery, Types.PublishedMediaDataRoomIsHiddenQueryVariables>;
export const PublishedMediaInsightsDcrIsHiddenDocument = gql`
    query PublishedMediaInsightsDcrIsHidden($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    userSettings {
      id
      isHidden: isArchived
    }
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrIsHiddenQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrIsHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrIsHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrIsHiddenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrIsHiddenQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrIsHiddenQuery, Types.PublishedMediaInsightsDcrIsHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrIsHiddenQuery, Types.PublishedMediaInsightsDcrIsHiddenQueryVariables>(PublishedMediaInsightsDcrIsHiddenDocument, options);
      }
export function usePublishedMediaInsightsDcrIsHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrIsHiddenQuery, Types.PublishedMediaInsightsDcrIsHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrIsHiddenQuery, Types.PublishedMediaInsightsDcrIsHiddenQueryVariables>(PublishedMediaInsightsDcrIsHiddenDocument, options);
        }
export type PublishedMediaInsightsDcrIsHiddenQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrIsHiddenQuery>;
export type PublishedMediaInsightsDcrIsHiddenLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrIsHiddenLazyQuery>;
export type PublishedMediaInsightsDcrIsHiddenQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrIsHiddenQuery, Types.PublishedMediaInsightsDcrIsHiddenQueryVariables>;
export const UpdatePublishedDataRoomIsHiddenDocument = gql`
    mutation UpdatePublishedDataRoomIsHidden($id: String!, $isHidden: Boolean!) {
  publishedDataRoom {
    updateUserSettings(input: {id: $id, isArchived: $isHidden}) {
      id
      isHidden: isArchived
    }
  }
}
    `;
export type UpdatePublishedDataRoomIsHiddenMutationFn = Apollo.MutationFunction<Types.UpdatePublishedDataRoomIsHiddenMutation, Types.UpdatePublishedDataRoomIsHiddenMutationVariables>;

/**
 * __useUpdatePublishedDataRoomIsHiddenMutation__
 *
 * To run a mutation, you first call `useUpdatePublishedDataRoomIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishedDataRoomIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishedDataRoomIsHiddenMutation, { data, loading, error }] = useUpdatePublishedDataRoomIsHiddenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdatePublishedDataRoomIsHiddenMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePublishedDataRoomIsHiddenMutation, Types.UpdatePublishedDataRoomIsHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePublishedDataRoomIsHiddenMutation, Types.UpdatePublishedDataRoomIsHiddenMutationVariables>(UpdatePublishedDataRoomIsHiddenDocument, options);
      }
export type UpdatePublishedDataRoomIsHiddenMutationHookResult = ReturnType<typeof useUpdatePublishedDataRoomIsHiddenMutation>;
export type UpdatePublishedDataRoomIsHiddenMutationResult = Apollo.MutationResult<Types.UpdatePublishedDataRoomIsHiddenMutation>;
export type UpdatePublishedDataRoomIsHiddenMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePublishedDataRoomIsHiddenMutation, Types.UpdatePublishedDataRoomIsHiddenMutationVariables>;
export const PublishedDataRoomStopDataDocument = gql`
    query PublishedDataRoomStopData($id: String!) {
  publishedDataRoom(id: $id) {
    id
    driverAttestationHash
  }
}
    `;

/**
 * __usePublishedDataRoomStopDataQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomStopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomStopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomStopDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomStopDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomStopDataQuery, Types.PublishedDataRoomStopDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomStopDataQuery, Types.PublishedDataRoomStopDataQueryVariables>(PublishedDataRoomStopDataDocument, options);
      }
export function usePublishedDataRoomStopDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomStopDataQuery, Types.PublishedDataRoomStopDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomStopDataQuery, Types.PublishedDataRoomStopDataQueryVariables>(PublishedDataRoomStopDataDocument, options);
        }
export type PublishedDataRoomStopDataQueryHookResult = ReturnType<typeof usePublishedDataRoomStopDataQuery>;
export type PublishedDataRoomStopDataLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomStopDataLazyQuery>;
export type PublishedDataRoomStopDataQueryResult = Apollo.QueryResult<Types.PublishedDataRoomStopDataQuery, Types.PublishedDataRoomStopDataQueryVariables>;
export const PublishedLookalikeMediaDataRoomStopDataDocument = gql`
    query PublishedLookalikeMediaDataRoomStopData($id: String!) {
  publishedLookalikeMediaDataRoom(id: $id) {
    id
    driverAttestationHash
    deactivatedAt
    isStopped
  }
}
    `;

/**
 * __usePublishedLookalikeMediaDataRoomStopDataQuery__
 *
 * To run a query within a React component, call `usePublishedLookalikeMediaDataRoomStopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedLookalikeMediaDataRoomStopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedLookalikeMediaDataRoomStopDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedLookalikeMediaDataRoomStopDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedLookalikeMediaDataRoomStopDataQuery, Types.PublishedLookalikeMediaDataRoomStopDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedLookalikeMediaDataRoomStopDataQuery, Types.PublishedLookalikeMediaDataRoomStopDataQueryVariables>(PublishedLookalikeMediaDataRoomStopDataDocument, options);
      }
export function usePublishedLookalikeMediaDataRoomStopDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedLookalikeMediaDataRoomStopDataQuery, Types.PublishedLookalikeMediaDataRoomStopDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedLookalikeMediaDataRoomStopDataQuery, Types.PublishedLookalikeMediaDataRoomStopDataQueryVariables>(PublishedLookalikeMediaDataRoomStopDataDocument, options);
        }
export type PublishedLookalikeMediaDataRoomStopDataQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomStopDataQuery>;
export type PublishedLookalikeMediaDataRoomStopDataLazyQueryHookResult = ReturnType<typeof usePublishedLookalikeMediaDataRoomStopDataLazyQuery>;
export type PublishedLookalikeMediaDataRoomStopDataQueryResult = Apollo.QueryResult<Types.PublishedLookalikeMediaDataRoomStopDataQuery, Types.PublishedLookalikeMediaDataRoomStopDataQueryVariables>;
export const PublishedMediaDataRoomStopDataDocument = gql`
    query PublishedMediaDataRoomStopData($id: String!) {
  publishedMediaDataRoom(id: $id) {
    id
    driverAttestationHash
  }
}
    `;

/**
 * __usePublishedMediaDataRoomStopDataQuery__
 *
 * To run a query within a React component, call `usePublishedMediaDataRoomStopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaDataRoomStopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaDataRoomStopDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaDataRoomStopDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaDataRoomStopDataQuery, Types.PublishedMediaDataRoomStopDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaDataRoomStopDataQuery, Types.PublishedMediaDataRoomStopDataQueryVariables>(PublishedMediaDataRoomStopDataDocument, options);
      }
export function usePublishedMediaDataRoomStopDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaDataRoomStopDataQuery, Types.PublishedMediaDataRoomStopDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaDataRoomStopDataQuery, Types.PublishedMediaDataRoomStopDataQueryVariables>(PublishedMediaDataRoomStopDataDocument, options);
        }
export type PublishedMediaDataRoomStopDataQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomStopDataQuery>;
export type PublishedMediaDataRoomStopDataLazyQueryHookResult = ReturnType<typeof usePublishedMediaDataRoomStopDataLazyQuery>;
export type PublishedMediaDataRoomStopDataQueryResult = Apollo.QueryResult<Types.PublishedMediaDataRoomStopDataQuery, Types.PublishedMediaDataRoomStopDataQueryVariables>;
export const PublishedMediaInsightsDcrStopDataDocument = gql`
    query PublishedMediaInsightsDcrStopData($id: String!) {
  publishedMediaInsightsDcr(id: $id) {
    id
    driverAttestationHash
    deactivatedAt
    isStopped
  }
}
    `;

/**
 * __usePublishedMediaInsightsDcrStopDataQuery__
 *
 * To run a query within a React component, call `usePublishedMediaInsightsDcrStopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMediaInsightsDcrStopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMediaInsightsDcrStopDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedMediaInsightsDcrStopDataQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedMediaInsightsDcrStopDataQuery, Types.PublishedMediaInsightsDcrStopDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedMediaInsightsDcrStopDataQuery, Types.PublishedMediaInsightsDcrStopDataQueryVariables>(PublishedMediaInsightsDcrStopDataDocument, options);
      }
export function usePublishedMediaInsightsDcrStopDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedMediaInsightsDcrStopDataQuery, Types.PublishedMediaInsightsDcrStopDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedMediaInsightsDcrStopDataQuery, Types.PublishedMediaInsightsDcrStopDataQueryVariables>(PublishedMediaInsightsDcrStopDataDocument, options);
        }
export type PublishedMediaInsightsDcrStopDataQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrStopDataQuery>;
export type PublishedMediaInsightsDcrStopDataLazyQueryHookResult = ReturnType<typeof usePublishedMediaInsightsDcrStopDataLazyQuery>;
export type PublishedMediaInsightsDcrStopDataQueryResult = Apollo.QueryResult<Types.PublishedMediaInsightsDcrStopDataQuery, Types.PublishedMediaInsightsDcrStopDataQueryVariables>;
export const StopDataRoomDocument = gql`
    mutation StopDataRoom($input: StopDataRoomInput!) {
  stopDataRoom(input: $input) @client {
    id
  }
}
    `;
export type StopDataRoomMutationFn = Apollo.MutationFunction<Types.StopDataRoomMutation, Types.StopDataRoomMutationVariables>;

/**
 * __useStopDataRoomMutation__
 *
 * To run a mutation, you first call `useStopDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopDataRoomMutation, { data, loading, error }] = useStopDataRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.StopDataRoomMutation, Types.StopDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StopDataRoomMutation, Types.StopDataRoomMutationVariables>(StopDataRoomDocument, options);
      }
export type StopDataRoomMutationHookResult = ReturnType<typeof useStopDataRoomMutation>;
export type StopDataRoomMutationResult = Apollo.MutationResult<Types.StopDataRoomMutation>;
export type StopDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.StopDataRoomMutation, Types.StopDataRoomMutationVariables>;
export const PublishedDataRoomTestingDocument = gql`
    query PublishedDataRoomTesting($id: String!) {
  publishedDataRoom(id: $id) {
    id
    testing @client
  }
}
    `;

/**
 * __usePublishedDataRoomTestingQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomTestingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomTestingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomTestingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomTestingQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomTestingQuery, Types.PublishedDataRoomTestingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomTestingQuery, Types.PublishedDataRoomTestingQueryVariables>(PublishedDataRoomTestingDocument, options);
      }
export function usePublishedDataRoomTestingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomTestingQuery, Types.PublishedDataRoomTestingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomTestingQuery, Types.PublishedDataRoomTestingQueryVariables>(PublishedDataRoomTestingDocument, options);
        }
export type PublishedDataRoomTestingQueryHookResult = ReturnType<typeof usePublishedDataRoomTestingQuery>;
export type PublishedDataRoomTestingLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomTestingLazyQuery>;
export type PublishedDataRoomTestingQueryResult = Apollo.QueryResult<Types.PublishedDataRoomTestingQuery, Types.PublishedDataRoomTestingQueryVariables>;
export const ActivationConfigurationsDocument = gql`
    query ActivationConfigurations($id: String!, $isAdmin: Boolean!) {
  ownOrganization: myself {
    organization {
      activationConfigurations {
        nodes {
          ...FullActivationConfiguration
        }
        totalCount
      }
    }
  }
  otherOrganization: organization(id: $id) @include(if: $isAdmin) {
    activationConfigurations {
      nodes {
        ...FullActivationConfiguration
      }
      totalCount
    }
  }
}
    ${FullActivationConfigurationFragment}`;

/**
 * __useActivationConfigurationsQuery__
 *
 * To run a query within a React component, call `useActivationConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivationConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivationConfigurationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useActivationConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ActivationConfigurationsQuery, Types.ActivationConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActivationConfigurationsQuery, Types.ActivationConfigurationsQueryVariables>(ActivationConfigurationsDocument, options);
      }
export function useActivationConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActivationConfigurationsQuery, Types.ActivationConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActivationConfigurationsQuery, Types.ActivationConfigurationsQueryVariables>(ActivationConfigurationsDocument, options);
        }
export type ActivationConfigurationsQueryHookResult = ReturnType<typeof useActivationConfigurationsQuery>;
export type ActivationConfigurationsLazyQueryHookResult = ReturnType<typeof useActivationConfigurationsLazyQuery>;
export type ActivationConfigurationsQueryResult = Apollo.QueryResult<Types.ActivationConfigurationsQuery, Types.ActivationConfigurationsQueryVariables>;
export const CreateActivationConfigurationDocument = gql`
    mutation CreateActivationConfiguration($input: CreateActivationConfigurationInput!) {
  activationConfiguration {
    create(input: $input) {
      record {
        ...FullActivationConfiguration
      }
    }
  }
}
    ${FullActivationConfigurationFragment}`;
export type CreateActivationConfigurationMutationFn = Apollo.MutationFunction<Types.CreateActivationConfigurationMutation, Types.CreateActivationConfigurationMutationVariables>;

/**
 * __useCreateActivationConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateActivationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivationConfigurationMutation, { data, loading, error }] = useCreateActivationConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivationConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateActivationConfigurationMutation, Types.CreateActivationConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateActivationConfigurationMutation, Types.CreateActivationConfigurationMutationVariables>(CreateActivationConfigurationDocument, options);
      }
export type CreateActivationConfigurationMutationHookResult = ReturnType<typeof useCreateActivationConfigurationMutation>;
export type CreateActivationConfigurationMutationResult = Apollo.MutationResult<Types.CreateActivationConfigurationMutation>;
export type CreateActivationConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.CreateActivationConfigurationMutation, Types.CreateActivationConfigurationMutationVariables>;
export const CreatePublisherDisplayConfigurationDocument = gql`
    mutation CreatePublisherDisplayConfiguration($input: CreatePublisherDisplayConfigurationInput!) {
  publisherDisplayConfiguration {
    create(input: $input) {
      record {
        id
        publisherName
        description
        logo
        collaborationRequestUsers
      }
    }
  }
}
    `;
export type CreatePublisherDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.CreatePublisherDisplayConfigurationMutation, Types.CreatePublisherDisplayConfigurationMutationVariables>;

/**
 * __useCreatePublisherDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useCreatePublisherDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherDisplayConfigurationMutation, { data, loading, error }] = useCreatePublisherDisplayConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePublisherDisplayConfigurationMutation, Types.CreatePublisherDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePublisherDisplayConfigurationMutation, Types.CreatePublisherDisplayConfigurationMutationVariables>(CreatePublisherDisplayConfigurationDocument, options);
      }
export type CreatePublisherDisplayConfigurationMutationHookResult = ReturnType<typeof useCreatePublisherDisplayConfigurationMutation>;
export type CreatePublisherDisplayConfigurationMutationResult = Apollo.MutationResult<Types.CreatePublisherDisplayConfigurationMutation>;
export type CreatePublisherDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.CreatePublisherDisplayConfigurationMutation, Types.CreatePublisherDisplayConfigurationMutationVariables>;
export const DeleteActivationConfigurationDocument = gql`
    mutation DeleteActivationConfiguration($id: String!) {
  activationConfiguration {
    delete(id: $id)
  }
}
    `;
export type DeleteActivationConfigurationMutationFn = Apollo.MutationFunction<Types.DeleteActivationConfigurationMutation, Types.DeleteActivationConfigurationMutationVariables>;

/**
 * __useDeleteActivationConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteActivationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivationConfigurationMutation, { data, loading, error }] = useDeleteActivationConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivationConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteActivationConfigurationMutation, Types.DeleteActivationConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteActivationConfigurationMutation, Types.DeleteActivationConfigurationMutationVariables>(DeleteActivationConfigurationDocument, options);
      }
export type DeleteActivationConfigurationMutationHookResult = ReturnType<typeof useDeleteActivationConfigurationMutation>;
export type DeleteActivationConfigurationMutationResult = Apollo.MutationResult<Types.DeleteActivationConfigurationMutation>;
export type DeleteActivationConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteActivationConfigurationMutation, Types.DeleteActivationConfigurationMutationVariables>;
export const DeletePublisherDisplayConfigurationDocument = gql`
    mutation DeletePublisherDisplayConfiguration($id: String!) {
  publisherDisplayConfiguration {
    delete(id: $id)
  }
}
    `;
export type DeletePublisherDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.DeletePublisherDisplayConfigurationMutation, Types.DeletePublisherDisplayConfigurationMutationVariables>;

/**
 * __useDeletePublisherDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useDeletePublisherDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublisherDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublisherDisplayConfigurationMutation, { data, loading, error }] = useDeletePublisherDisplayConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublisherDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePublisherDisplayConfigurationMutation, Types.DeletePublisherDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePublisherDisplayConfigurationMutation, Types.DeletePublisherDisplayConfigurationMutationVariables>(DeletePublisherDisplayConfigurationDocument, options);
      }
export type DeletePublisherDisplayConfigurationMutationHookResult = ReturnType<typeof useDeletePublisherDisplayConfigurationMutation>;
export type DeletePublisherDisplayConfigurationMutationResult = Apollo.MutationResult<Types.DeletePublisherDisplayConfigurationMutation>;
export type DeletePublisherDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.DeletePublisherDisplayConfigurationMutation, Types.DeletePublisherDisplayConfigurationMutationVariables>;
export const GetPublisherDisplayConfigurationDocument = gql`
    query GetPublisherDisplayConfiguration($id: String!, $isAdmin: Boolean!) {
  ownOrganization: myself @skip(if: $isAdmin) {
    organization {
      publisherDisplayConfiguration {
        id
        publisherName
        description
        collaborationRequestUsers
        logo
        marketIds
      }
    }
  }
  otherOrganization: organization(id: $id) @include(if: $isAdmin) {
    publisherDisplayConfiguration {
      id
      publisherName
      description
      collaborationRequestUsers
      logo
      marketIds
    }
  }
}
    `;

/**
 * __useGetPublisherDisplayConfigurationQuery__
 *
 * To run a query within a React component, call `useGetPublisherDisplayConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublisherDisplayConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublisherDisplayConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetPublisherDisplayConfigurationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPublisherDisplayConfigurationQuery, Types.GetPublisherDisplayConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPublisherDisplayConfigurationQuery, Types.GetPublisherDisplayConfigurationQueryVariables>(GetPublisherDisplayConfigurationDocument, options);
      }
export function useGetPublisherDisplayConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPublisherDisplayConfigurationQuery, Types.GetPublisherDisplayConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPublisherDisplayConfigurationQuery, Types.GetPublisherDisplayConfigurationQueryVariables>(GetPublisherDisplayConfigurationDocument, options);
        }
export type GetPublisherDisplayConfigurationQueryHookResult = ReturnType<typeof useGetPublisherDisplayConfigurationQuery>;
export type GetPublisherDisplayConfigurationLazyQueryHookResult = ReturnType<typeof useGetPublisherDisplayConfigurationLazyQuery>;
export type GetPublisherDisplayConfigurationQueryResult = Apollo.QueryResult<Types.GetPublisherDisplayConfigurationQuery, Types.GetPublisherDisplayConfigurationQueryVariables>;
export const UpdatePublisherDisplayConfigurationDocument = gql`
    mutation UpdatePublisherDisplayConfiguration($input: UpdatePublisherDisplayConfigurationInput!) {
  publisherDisplayConfiguration {
    update(input: $input) {
      record {
        id
        publisherName
        description
        collaborationRequestUsers
        logo
        marketIds
      }
    }
  }
}
    `;
export type UpdatePublisherDisplayConfigurationMutationFn = Apollo.MutationFunction<Types.UpdatePublisherDisplayConfigurationMutation, Types.UpdatePublisherDisplayConfigurationMutationVariables>;

/**
 * __useUpdatePublisherDisplayConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherDisplayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherDisplayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherDisplayConfigurationMutation, { data, loading, error }] = useUpdatePublisherDisplayConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherDisplayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePublisherDisplayConfigurationMutation, Types.UpdatePublisherDisplayConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePublisherDisplayConfigurationMutation, Types.UpdatePublisherDisplayConfigurationMutationVariables>(UpdatePublisherDisplayConfigurationDocument, options);
      }
export type UpdatePublisherDisplayConfigurationMutationHookResult = ReturnType<typeof useUpdatePublisherDisplayConfigurationMutation>;
export type UpdatePublisherDisplayConfigurationMutationResult = Apollo.MutationResult<Types.UpdatePublisherDisplayConfigurationMutation>;
export type UpdatePublisherDisplayConfigurationMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePublisherDisplayConfigurationMutation, Types.UpdatePublisherDisplayConfigurationMutationVariables>;
export const ApproveSubmittedDataRoomRequestDocument = gql`
    mutation ApproveSubmittedDataRoomRequest($input: ApproveSubmittedDataRoomRequestLocalInput!) {
  approveSubmittedDataRoomRequest(input: $input) @client {
    signature
    status
  }
}
    `;
export type ApproveSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.ApproveSubmittedDataRoomRequestMutation, Types.ApproveSubmittedDataRoomRequestMutationVariables>;

/**
 * __useApproveSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useApproveSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubmittedDataRoomRequestMutation, { data, loading, error }] = useApproveSubmittedDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.ApproveSubmittedDataRoomRequestMutation, Types.ApproveSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ApproveSubmittedDataRoomRequestMutation, Types.ApproveSubmittedDataRoomRequestMutationVariables>(ApproveSubmittedDataRoomRequestDocument, options);
      }
export type ApproveSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useApproveSubmittedDataRoomRequestMutation>;
export type ApproveSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.ApproveSubmittedDataRoomRequestMutation>;
export type ApproveSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.ApproveSubmittedDataRoomRequestMutation, Types.ApproveSubmittedDataRoomRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($id: String!) {
  dataRoomRequest {
    delete(dataRoomRequestId: $id)
  }
}
    `;
export type DeleteRequestMutationFn = Apollo.MutationFunction<Types.DeleteRequestMutation, Types.DeleteRequestMutationVariables>;

/**
 * __useDeleteRequestMutation__
 *
 * To run a mutation, you first call `useDeleteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestMutation, { data, loading, error }] = useDeleteRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRequestMutation, Types.DeleteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRequestMutation, Types.DeleteRequestMutationVariables>(DeleteRequestDocument, options);
      }
export type DeleteRequestMutationHookResult = ReturnType<typeof useDeleteRequestMutation>;
export type DeleteRequestMutationResult = Apollo.MutationResult<Types.DeleteRequestMutation>;
export type DeleteRequestMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRequestMutation, Types.DeleteRequestMutationVariables>;
export const EnclaveConfigurationPinDocument = gql`
    query EnclaveConfigurationPin($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    enclaveConfigurationPin @client
  }
}
    `;

/**
 * __useEnclaveConfigurationPinQuery__
 *
 * To run a query within a React component, call `useEnclaveConfigurationPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnclaveConfigurationPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnclaveConfigurationPinQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function useEnclaveConfigurationPinQuery(baseOptions: Apollo.QueryHookOptions<Types.EnclaveConfigurationPinQuery, Types.EnclaveConfigurationPinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EnclaveConfigurationPinQuery, Types.EnclaveConfigurationPinQueryVariables>(EnclaveConfigurationPinDocument, options);
      }
export function useEnclaveConfigurationPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EnclaveConfigurationPinQuery, Types.EnclaveConfigurationPinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EnclaveConfigurationPinQuery, Types.EnclaveConfigurationPinQueryVariables>(EnclaveConfigurationPinDocument, options);
        }
export type EnclaveConfigurationPinQueryHookResult = ReturnType<typeof useEnclaveConfigurationPinQuery>;
export type EnclaveConfigurationPinLazyQueryHookResult = ReturnType<typeof useEnclaveConfigurationPinLazyQuery>;
export type EnclaveConfigurationPinQueryResult = Apollo.QueryResult<Types.EnclaveConfigurationPinQuery, Types.EnclaveConfigurationPinQueryVariables>;
export const MergeSubmittedDataRoomRequestDocument = gql`
    mutation MergeSubmittedDataRoomRequest($input: MergeSubmittedDataRoomRequestInput!) {
  mergeSubmittedDataRoomRequest(input: $input) @client
}
    `;
export type MergeSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.MergeSubmittedDataRoomRequestMutation, Types.MergeSubmittedDataRoomRequestMutationVariables>;

/**
 * __useMergeSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useMergeSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSubmittedDataRoomRequestMutation, { data, loading, error }] = useMergeSubmittedDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.MergeSubmittedDataRoomRequestMutation, Types.MergeSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MergeSubmittedDataRoomRequestMutation, Types.MergeSubmittedDataRoomRequestMutationVariables>(MergeSubmittedDataRoomRequestDocument, options);
      }
export type MergeSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useMergeSubmittedDataRoomRequestMutation>;
export type MergeSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.MergeSubmittedDataRoomRequestMutation>;
export type MergeSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.MergeSubmittedDataRoomRequestMutation, Types.MergeSubmittedDataRoomRequestMutationVariables>;
export const SubmitDataRoomRequestDocument = gql`
    mutation SubmitDataRoomRequest($input: SubmitDataRoomRequestLocalInput!) {
  submitDataRoomRequest(input: $input) @client {
    id
    status
  }
}
    `;
export type SubmitDataRoomRequestMutationFn = Apollo.MutationFunction<Types.SubmitDataRoomRequestMutation, Types.SubmitDataRoomRequestMutationVariables>;

/**
 * __useSubmitDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useSubmitDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDataRoomRequestMutation, { data, loading, error }] = useSubmitDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitDataRoomRequestMutation, Types.SubmitDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitDataRoomRequestMutation, Types.SubmitDataRoomRequestMutationVariables>(SubmitDataRoomRequestDocument, options);
      }
export type SubmitDataRoomRequestMutationHookResult = ReturnType<typeof useSubmitDataRoomRequestMutation>;
export type SubmitDataRoomRequestMutationResult = Apollo.MutationResult<Types.SubmitDataRoomRequestMutation>;
export type SubmitDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.SubmitDataRoomRequestMutation, Types.SubmitDataRoomRequestMutationVariables>;
export const AddRequestParticipantDocument = gql`
    mutation AddRequestParticipant($input: CreateDraftParticipantInput!, $requestId: String!) {
  dataRoomRequest {
    addParticipant(input: $input, dataRoomRequestId: $requestId) {
      id
      userEmail
    }
  }
}
    `;
export type AddRequestParticipantMutationFn = Apollo.MutationFunction<Types.AddRequestParticipantMutation, Types.AddRequestParticipantMutationVariables>;

/**
 * __useAddRequestParticipantMutation__
 *
 * To run a mutation, you first call `useAddRequestParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestParticipantMutation, { data, loading, error }] = useAddRequestParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAddRequestParticipantMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRequestParticipantMutation, Types.AddRequestParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRequestParticipantMutation, Types.AddRequestParticipantMutationVariables>(AddRequestParticipantDocument, options);
      }
export type AddRequestParticipantMutationHookResult = ReturnType<typeof useAddRequestParticipantMutation>;
export type AddRequestParticipantMutationResult = Apollo.MutationResult<Types.AddRequestParticipantMutation>;
export type AddRequestParticipantMutationOptions = Apollo.BaseMutationOptions<Types.AddRequestParticipantMutation, Types.AddRequestParticipantMutationVariables>;
export const DeleteRequestParticipantDocument = gql`
    mutation DeleteRequestParticipant($id: String!) {
  draftParticipant {
    delete(id: $id)
  }
}
    `;
export type DeleteRequestParticipantMutationFn = Apollo.MutationFunction<Types.DeleteRequestParticipantMutation, Types.DeleteRequestParticipantMutationVariables>;

/**
 * __useDeleteRequestParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteRequestParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestParticipantMutation, { data, loading, error }] = useDeleteRequestParticipantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRequestParticipantMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRequestParticipantMutation, Types.DeleteRequestParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRequestParticipantMutation, Types.DeleteRequestParticipantMutationVariables>(DeleteRequestParticipantDocument, options);
      }
export type DeleteRequestParticipantMutationHookResult = ReturnType<typeof useDeleteRequestParticipantMutation>;
export type DeleteRequestParticipantMutationResult = Apollo.MutationResult<Types.DeleteRequestParticipantMutation>;
export type DeleteRequestParticipantMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRequestParticipantMutation, Types.DeleteRequestParticipantMutationVariables>;
export const RequestComputeNodePermissionsDocument = gql`
    query RequestComputeNodePermissions($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    permissions {
      nodes {
        ... on DraftAnalystPermission {
          participant {
            id
            userEmail
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRequestComputeNodePermissionsQuery__
 *
 * To run a query within a React component, call `useRequestComputeNodePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestComputeNodePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestComputeNodePermissionsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useRequestComputeNodePermissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestComputeNodePermissionsQuery, Types.RequestComputeNodePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestComputeNodePermissionsQuery, Types.RequestComputeNodePermissionsQueryVariables>(RequestComputeNodePermissionsDocument, options);
      }
export function useRequestComputeNodePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestComputeNodePermissionsQuery, Types.RequestComputeNodePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestComputeNodePermissionsQuery, Types.RequestComputeNodePermissionsQueryVariables>(RequestComputeNodePermissionsDocument, options);
        }
export type RequestComputeNodePermissionsQueryHookResult = ReturnType<typeof useRequestComputeNodePermissionsQuery>;
export type RequestComputeNodePermissionsLazyQueryHookResult = ReturnType<typeof useRequestComputeNodePermissionsLazyQuery>;
export type RequestComputeNodePermissionsQueryResult = Apollo.QueryResult<Types.RequestComputeNodePermissionsQuery, Types.RequestComputeNodePermissionsQueryVariables>;
export const SubmittedRequestComputeNodePermissionsDocument = gql`
    query SubmittedRequestComputeNodePermissions($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    permissions {
      ... on PublishedAnalystPermission {
        participant {
          userEmail
        }
      }
    }
  }
}
    `;

/**
 * __useSubmittedRequestComputeNodePermissionsQuery__
 *
 * To run a query within a React component, call `useSubmittedRequestComputeNodePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestComputeNodePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRequestComputeNodePermissionsQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function useSubmittedRequestComputeNodePermissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedRequestComputeNodePermissionsQuery, Types.SubmittedRequestComputeNodePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedRequestComputeNodePermissionsQuery, Types.SubmittedRequestComputeNodePermissionsQueryVariables>(SubmittedRequestComputeNodePermissionsDocument, options);
      }
export function useSubmittedRequestComputeNodePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedRequestComputeNodePermissionsQuery, Types.SubmittedRequestComputeNodePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedRequestComputeNodePermissionsQuery, Types.SubmittedRequestComputeNodePermissionsQueryVariables>(SubmittedRequestComputeNodePermissionsDocument, options);
        }
export type SubmittedRequestComputeNodePermissionsQueryHookResult = ReturnType<typeof useSubmittedRequestComputeNodePermissionsQuery>;
export type SubmittedRequestComputeNodePermissionsLazyQueryHookResult = ReturnType<typeof useSubmittedRequestComputeNodePermissionsLazyQuery>;
export type SubmittedRequestComputeNodePermissionsQueryResult = Apollo.QueryResult<Types.SubmittedRequestComputeNodePermissionsQuery, Types.SubmittedRequestComputeNodePermissionsQueryVariables>;
export const CreateDataRoomRequestDocument = gql`
    mutation CreateDataRoomRequest($input: CreateDataRoomRequestInput!) {
  dataRoomRequest {
    create(input: $input) {
      id
    }
  }
}
    `;
export type CreateDataRoomRequestMutationFn = Apollo.MutationFunction<Types.CreateDataRoomRequestMutation, Types.CreateDataRoomRequestMutationVariables>;

/**
 * __useCreateDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useCreateDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataRoomRequestMutation, { data, loading, error }] = useCreateDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataRoomRequestMutation, Types.CreateDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDataRoomRequestMutation, Types.CreateDataRoomRequestMutationVariables>(CreateDataRoomRequestDocument, options);
      }
export type CreateDataRoomRequestMutationHookResult = ReturnType<typeof useCreateDataRoomRequestMutation>;
export type CreateDataRoomRequestMutationResult = Apollo.MutationResult<Types.CreateDataRoomRequestMutation>;
export type CreateDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataRoomRequestMutation, Types.CreateDataRoomRequestMutationVariables>;
export const RequestsDocument = gql`
    query Requests($id: String!) {
  publishedDataRoom(id: $id) {
    id
    requests {
      nodes {
        id
        draftNode {
          id
          name
          permissions {
            nodes {
              ... on DraftAnalystPermission {
                participant {
                  id
                  userEmail
                }
              }
            }
          }
          ... on DraftSyntheticNode {
            computationType
          }
          ... on DraftPostNode {
            computationType
          }
          ... on DraftPreviewNode {
            computationType
          }
          ... on DraftMatchNode {
            computationType
          }
          ... on DraftS3SinkNode {
            computationType
          }
          ... on DraftScriptingNode {
            computationType
            scriptingLanguage
          }
          ... on DraftSqlNode {
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSqliteNode {
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
          }
          ... on DraftSyntheticNode {
            accuracy
            includeReportWithStats
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRequestsQuery__
 *
 * To run a query within a React component, call `useRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestsQuery, Types.RequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestsQuery, Types.RequestsQueryVariables>(RequestsDocument, options);
      }
export function useRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestsQuery, Types.RequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestsQuery, Types.RequestsQueryVariables>(RequestsDocument, options);
        }
export type RequestsQueryHookResult = ReturnType<typeof useRequestsQuery>;
export type RequestsLazyQueryHookResult = ReturnType<typeof useRequestsLazyQuery>;
export type RequestsQueryResult = Apollo.QueryResult<Types.RequestsQuery, Types.RequestsQueryVariables>;
export const SubmittedDataRoomRequestsDocument = gql`
    query SubmittedDataRoomRequests($id: String!) {
  publishedDataRoom(id: $id) {
    submittedRequests {
      nodes {
        id
        enclaveCommitId
        enclaveConfigurationPin
        signers {
          nodes {
            id
            userEmail
            user {
              id
              email
            }
            signature
          }
        }
        owner {
          id
          email
        }
        createdAt
        updatedAt
        node @client {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
          permissions {
            ... on PublishedAnalystPermission {
              participant {
                userEmail
              }
            }
            ... on PublishedDataOwnerPermission {
              participant {
                userEmail
              }
            }
          }
          ... on PublishedScriptingNode {
            computationType
            output
            scriptingLanguage
            scripts {
              name
              content
              isMainScript
            }
            dependencies {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
          }
          ... on PublishedSqlNode {
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
            dependencies {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
            statement
          }
          ... on PublishedSqliteNode {
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
            dependencies {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
            statement
          }
          ... on PublishedMatchNode {
            computationType
            dependencies {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
            config
          }
          ... on PublishedSyntheticNode {
            computationType
            accuracy
            includeReportWithStats
            dependency {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
            columns {
              name
              shouldMaskColumn
              index
              maskType
              dataType
            }
          }
          ... on PublishedPostNode {
            computationType
            dependency {
              __typename
              id
              dcrHash
              driverAttestationHash
              commitId
            }
          }
          ... on PublishedPreviewNode {
            computationType
            usedQuotaBytes
            remainingQuotaBytes
            totalQuotaBytes
            dependency {
              __typename
              id
              dcrHash
              driverAttestationHash
              commitId
            }
          }
          ... on PublishedS3SinkNode {
            endpoint
            computationType
            region
            credentialsDependency {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
            uploadDependency {
              __typename
              id
              name
              dcrHash
              driverAttestationHash
              commitId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSubmittedDataRoomRequestsQuery__
 *
 * To run a query within a React component, call `useSubmittedDataRoomRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedDataRoomRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedDataRoomRequestsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedDataRoomRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedDataRoomRequestsQuery, Types.SubmittedDataRoomRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedDataRoomRequestsQuery, Types.SubmittedDataRoomRequestsQueryVariables>(SubmittedDataRoomRequestsDocument, options);
      }
export function useSubmittedDataRoomRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedDataRoomRequestsQuery, Types.SubmittedDataRoomRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedDataRoomRequestsQuery, Types.SubmittedDataRoomRequestsQueryVariables>(SubmittedDataRoomRequestsDocument, options);
        }
export type SubmittedDataRoomRequestsQueryHookResult = ReturnType<typeof useSubmittedDataRoomRequestsQuery>;
export type SubmittedDataRoomRequestsLazyQueryHookResult = ReturnType<typeof useSubmittedDataRoomRequestsLazyQuery>;
export type SubmittedDataRoomRequestsQueryResult = Apollo.QueryResult<Types.SubmittedDataRoomRequestsQuery, Types.SubmittedDataRoomRequestsQueryVariables>;
export const RebaseSubmittedRequestDocument = gql`
    mutation RebaseSubmittedRequest($input: RebaseSubmittedDataRoomRequestLocalInput!) {
  rebaseSubmittedDataRoomRequest(input: $input) @client {
    id
  }
}
    `;
export type RebaseSubmittedRequestMutationFn = Apollo.MutationFunction<Types.RebaseSubmittedRequestMutation, Types.RebaseSubmittedRequestMutationVariables>;

/**
 * __useRebaseSubmittedRequestMutation__
 *
 * To run a mutation, you first call `useRebaseSubmittedRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebaseSubmittedRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebaseSubmittedRequestMutation, { data, loading, error }] = useRebaseSubmittedRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebaseSubmittedRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.RebaseSubmittedRequestMutation, Types.RebaseSubmittedRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RebaseSubmittedRequestMutation, Types.RebaseSubmittedRequestMutationVariables>(RebaseSubmittedRequestDocument, options);
      }
export type RebaseSubmittedRequestMutationHookResult = ReturnType<typeof useRebaseSubmittedRequestMutation>;
export type RebaseSubmittedRequestMutationResult = Apollo.MutationResult<Types.RebaseSubmittedRequestMutation>;
export type RebaseSubmittedRequestMutationOptions = Apollo.BaseMutationOptions<Types.RebaseSubmittedRequestMutation, Types.RebaseSubmittedRequestMutationVariables>;
export const SubmittedRequestRebaseDocument = gql`
    query SubmittedRequestRebase($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    enclaveConfigurationPin
    owner {
      id
      email
    }
    node @client {
      id
      commitId
    }
  }
}
    `;

/**
 * __useSubmittedRequestRebaseQuery__
 *
 * To run a query within a React component, call `useSubmittedRequestRebaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestRebaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRequestRebaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedRequestRebaseQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedRequestRebaseQuery, Types.SubmittedRequestRebaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedRequestRebaseQuery, Types.SubmittedRequestRebaseQueryVariables>(SubmittedRequestRebaseDocument, options);
      }
export function useSubmittedRequestRebaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedRequestRebaseQuery, Types.SubmittedRequestRebaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedRequestRebaseQuery, Types.SubmittedRequestRebaseQueryVariables>(SubmittedRequestRebaseDocument, options);
        }
export type SubmittedRequestRebaseQueryHookResult = ReturnType<typeof useSubmittedRequestRebaseQuery>;
export type SubmittedRequestRebaseLazyQueryHookResult = ReturnType<typeof useSubmittedRequestRebaseLazyQuery>;
export type SubmittedRequestRebaseQueryResult = Apollo.QueryResult<Types.SubmittedRequestRebaseQuery, Types.SubmittedRequestRebaseQueryVariables>;
export const SubmittedRequestSigneesDocument = gql`
    query SubmittedRequestSignees($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    enclaveConfigurationPin
    node @client {
      __typename
      id
      commitId
    }
    signers {
      nodes {
        id
        userEmail
        user {
          id
          email
        }
        signature
      }
    }
  }
}
    `;

/**
 * __useSubmittedRequestSigneesQuery__
 *
 * To run a query within a React component, call `useSubmittedRequestSigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestSigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRequestSigneesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedRequestSigneesQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedRequestSigneesQuery, Types.SubmittedRequestSigneesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedRequestSigneesQuery, Types.SubmittedRequestSigneesQueryVariables>(SubmittedRequestSigneesDocument, options);
      }
export function useSubmittedRequestSigneesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedRequestSigneesQuery, Types.SubmittedRequestSigneesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedRequestSigneesQuery, Types.SubmittedRequestSigneesQueryVariables>(SubmittedRequestSigneesDocument, options);
        }
export type SubmittedRequestSigneesQueryHookResult = ReturnType<typeof useSubmittedRequestSigneesQuery>;
export type SubmittedRequestSigneesLazyQueryHookResult = ReturnType<typeof useSubmittedRequestSigneesLazyQuery>;
export type SubmittedRequestSigneesQueryResult = Apollo.QueryResult<Types.SubmittedRequestSigneesQuery, Types.SubmittedRequestSigneesQueryVariables>;
export const SubmittedRequestCreationDateDocument = gql`
    query SubmittedRequestCreationDate($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    createdAt
    owner {
      id
      email
    }
  }
}
    `;

/**
 * __useSubmittedRequestCreationDateQuery__
 *
 * To run a query within a React component, call `useSubmittedRequestCreationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedRequestCreationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedRequestCreationDateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedRequestCreationDateQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedRequestCreationDateQuery, Types.SubmittedRequestCreationDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedRequestCreationDateQuery, Types.SubmittedRequestCreationDateQueryVariables>(SubmittedRequestCreationDateDocument, options);
      }
export function useSubmittedRequestCreationDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedRequestCreationDateQuery, Types.SubmittedRequestCreationDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedRequestCreationDateQuery, Types.SubmittedRequestCreationDateQueryVariables>(SubmittedRequestCreationDateDocument, options);
        }
export type SubmittedRequestCreationDateQueryHookResult = ReturnType<typeof useSubmittedRequestCreationDateQuery>;
export type SubmittedRequestCreationDateLazyQueryHookResult = ReturnType<typeof useSubmittedRequestCreationDateLazyQuery>;
export type SubmittedRequestCreationDateQueryResult = Apollo.QueryResult<Types.SubmittedRequestCreationDateQuery, Types.SubmittedRequestCreationDateQueryVariables>;
export const CurrentUserDomainDocument = gql`
    query CurrentUserDomain {
  myself {
    id
    organizationLogo
  }
}
    `;

/**
 * __useCurrentUserDomainQuery__
 *
 * To run a query within a React component, call `useCurrentUserDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserDomainQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserDomainQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserDomainQuery, Types.CurrentUserDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserDomainQuery, Types.CurrentUserDomainQueryVariables>(CurrentUserDomainDocument, options);
      }
export function useCurrentUserDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserDomainQuery, Types.CurrentUserDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserDomainQuery, Types.CurrentUserDomainQueryVariables>(CurrentUserDomainDocument, options);
        }
export type CurrentUserDomainQueryHookResult = ReturnType<typeof useCurrentUserDomainQuery>;
export type CurrentUserDomainLazyQueryHookResult = ReturnType<typeof useCurrentUserDomainLazyQuery>;
export type CurrentUserDomainQueryResult = Apollo.QueryResult<Types.CurrentUserDomainQuery, Types.CurrentUserDomainQueryVariables>;
export const CreateDraftDataRoomDocument = gql`
    mutation CreateDraftDataRoom($input: CreateDraftDataRoomInput!) {
  draftDataRoom {
    create(input: $input) {
      record {
        id
        title
        description
        owner {
          id
          email
        }
        logo
        enableInteractivity
        enableDevelopment
        userSettings {
          id
          isArchived
          isFavorite
        }
        createdAt
        draftNodes {
          nodes {
            id
            name
          }
        }
        editors {
          nodes {
            user {
              id
              email
            }
            role
          }
        }
        dataNodesOrder
        computeNodesOrder
        participants {
          nodes {
            id
            userEmail
            permissions {
              nodes {
                ... on DraftDataOwnerPermission {
                  node {
                    id
                    name
                  }
                }
                ... on DraftAnalystPermission {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        query {
          dataRooms(
            filter: {isArchived: {equalTo: false}, isFavorite: {equalTo: false}, isOwnedByUser: {equalTo: true}}
            sortBy: {updatedAt: DESCENDING}
          ) {
            nodes {
              ... on DraftDataRoom {
                ...NavigationDrawerDraftDataRoom
              }
              ... on PublishedDataRoom {
                ...NavigationDrawerPublishedDataRoom
              }
              ... on PublishedMediaDataRoom {
                ...NavigationDrawerPublishedMediaDataRoom
              }
              ... on PublishedLookalikeMediaDataRoom {
                ...NavigationDrawerPublishedLookalikeMediaDataRoom
              }
              ... on PublishedMediaInsightsDcr {
                ...NavigationDrawerPublishedMediaInsightsDcr
              }
            }
          }
        }
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}
${NavigationDrawerPublishedLookalikeMediaDataRoomFragment}
${NavigationDrawerPublishedMediaInsightsDcrFragment}`;
export type CreateDraftDataRoomMutationFn = Apollo.MutationFunction<Types.CreateDraftDataRoomMutation, Types.CreateDraftDataRoomMutationVariables>;

/**
 * __useCreateDraftDataRoomMutation__
 *
 * To run a mutation, you first call `useCreateDraftDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftDataRoomMutation, { data, loading, error }] = useCreateDraftDataRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftDataRoomMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftDataRoomMutation, Types.CreateDraftDataRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDraftDataRoomMutation, Types.CreateDraftDataRoomMutationVariables>(CreateDraftDataRoomDocument, options);
      }
export type CreateDraftDataRoomMutationHookResult = ReturnType<typeof useCreateDraftDataRoomMutation>;
export type CreateDraftDataRoomMutationResult = Apollo.MutationResult<Types.CreateDraftDataRoomMutation>;
export type CreateDraftDataRoomMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftDataRoomMutation, Types.CreateDraftDataRoomMutationVariables>;
export const UserByEmailDocument = gql`
    query UserByEmail($email: String!) {
  userByEmail(email: $email) {
    id
    email
    organization {
      id
      name
      workerTypes {
        nodes {
          id
          name
          shortName
        }
      }
    }
  }
}
    `;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.UserByEmailQuery, Types.UserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserByEmailQuery, Types.UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserByEmailQuery, Types.UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserByEmailQuery, Types.UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<Types.UserByEmailQuery, Types.UserByEmailQueryVariables>;
export const DraftDataRoomNodesDocument = gql`
    query DraftDataRoomNodes($id: String!) {
  draftDataRoom(id: $id) {
    id
    draftNodes {
      nodes {
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              participant {
                id
                userEmail
              }
            }
            ... on DraftDataOwnerPermission {
              participant {
                id
                userEmail
              }
            }
          }
        }
        ... on DraftScriptingNode {
          id
          name
          computationType
          scripts {
            nodes {
              id
              name
              content
              isMainScript
            }
          }
          dependencies {
            nodes {
              ... on DraftNodeConnection {
                draftNode {
                  id
                  name
                }
              }
            }
          }
          output
          scriptingLanguage
        }
        ... on DraftMatchNode {
          id
          name
          config
          computationType
        }
        ... on DraftSqlNode {
          id
          name
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
        }
        ... on DraftSqliteNode {
          id
          name
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
        }
        ... on DraftSyntheticNode {
          id
          name
          accuracy
          computationType
          includeReportWithStats
          columns {
            nodes {
              id
              index
              name
              maskType
              dataType
              shouldMaskColumn
            }
          }
          dependencyUpdatedAt
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
            ... on PublishedNodeConnection {
              computeNodeId
              publishedDataRoomId
            }
          }
        }
        ... on DraftPostNode {
          id
          name
          computationType
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftPreviewNode {
          id
          name
          quotaBytes
          computationType
          dependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftS3SinkNode {
          id
          name
          endpoint
          region
          computationType
          credentialsDependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
          uploadDependency {
            ... on DraftNodeConnection {
              draftNode {
                id
                name
              }
            }
          }
        }
        ... on DraftTableLeafNode {
          id
          name
          columns {
            nodes {
              id
              name
              dataType
              isNullable
            }
          }
        }
        ... on DraftRawLeafNode {
          id
          name
        }
      }
    }
    computeNodesOrder
    dataNodesOrder
  }
}
    `;

/**
 * __useDraftDataRoomNodesQuery__
 *
 * To run a query within a React component, call `useDraftDataRoomNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftDataRoomNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftDataRoomNodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDraftDataRoomNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftDataRoomNodesQuery, Types.DraftDataRoomNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftDataRoomNodesQuery, Types.DraftDataRoomNodesQueryVariables>(DraftDataRoomNodesDocument, options);
      }
export function useDraftDataRoomNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftDataRoomNodesQuery, Types.DraftDataRoomNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftDataRoomNodesQuery, Types.DraftDataRoomNodesQueryVariables>(DraftDataRoomNodesDocument, options);
        }
export type DraftDataRoomNodesQueryHookResult = ReturnType<typeof useDraftDataRoomNodesQuery>;
export type DraftDataRoomNodesLazyQueryHookResult = ReturnType<typeof useDraftDataRoomNodesLazyQuery>;
export type DraftDataRoomNodesQueryResult = Apollo.QueryResult<Types.DraftDataRoomNodesQuery, Types.DraftDataRoomNodesQueryVariables>;
export const PublishedDataRoomNodesDocument = gql`
    query PublishedDataRoomNodes($dataRoomId: String!) {
  publishedDataRoom(id: $dataRoomId) {
    id
    publishedNodes @client {
      __typename
      id
      name
      permissions {
        ... on PublishedAnalystPermission {
          participant {
            userEmail
          }
        }
        ... on PublishedDataOwnerPermission {
          participant {
            userEmail
          }
        }
      }
      ... on PublishedTableLeafNode {
        isRequired
        columns {
          name
          dataType
          isNullable
          formatType
          hashWith
        }
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
      ... on PublishedRawLeafNode {
        isRequired
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
      ... on PublishedScriptingNode {
        scripts {
          name
          content
          isMainScript
        }
        computationType
        dependencies {
          id
          name
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
        output
        scriptingLanguage
      }
      ... on PublishedSqlNode {
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on PublishedSqliteNode {
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on PublishedMatchNode {
        computationType
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
        config
      }
      ... on PublishedSyntheticNode {
        id
        name
        accuracy
        computationType
        includeReportWithStats
        dependency {
          id
          name
        }
        columns {
          name
          shouldMaskColumn
          index
          maskType
          dataType
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on PublishedPostNode {
        id
        name
        computationType
        dependency {
          id
          name
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on PublishedPreviewNode {
        id
        name
        usedQuotaBytes
        remainingQuotaBytes
        totalQuotaBytes
        dependency {
          __typename
          id
          name
          permissions {
            ... on PublishedAnalystPermission {
              participant {
                userEmail
              }
            }
            ... on PublishedDataOwnerPermission {
              participant {
                userEmail
              }
            }
          }
          ... on PublishedTableLeafNode {
            isRequired
            columns {
              name
              dataType
              isNullable
              formatType
              hashWith
            }
            dataset {
              datasetHash
              leafId
              timestamp
              user
            }
          }
          ... on PublishedRawLeafNode {
            isRequired
            dataset {
              datasetHash
              leafId
              timestamp
              user
            }
          }
          ... on PublishedScriptingNode {
            scripts {
              name
              content
              isMainScript
            }
            computationType
            dependencies {
              id
              name
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
            output
            scriptingLanguage
          }
          ... on PublishedSqlNode {
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
          ... on PublishedSqliteNode {
            statement
            computationType
            privacyFilter {
              minimumRows
              isEnabled
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
          ... on PublishedMatchNode {
            computationType
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
            config
          }
          ... on PublishedSyntheticNode {
            id
            name
            accuracy
            computationType
            includeReportWithStats
            dependency {
              id
              name
            }
            columns {
              name
              shouldMaskColumn
              index
              maskType
              dataType
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
          ... on PublishedPostNode {
            id
            name
            computationType
            dependency {
              id
              name
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
          ... on PublishedPreviewNode {
            id
            name
            usedQuotaBytes
            remainingQuotaBytes
            totalQuotaBytes
            dependency {
              id
              name
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
          ... on PublishedS3SinkNode {
            id
            name
            endpoint
            computationType
            region
            credentialsDependency {
              id
              name
            }
            job {
              id
              enclaveComputeJobId
              status
              updatedAt
              createdAt
              driverAttestationHash
              dataRoomHash
              purpose
            }
          }
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
      ... on PublishedS3SinkNode {
        id
        name
        endpoint
        computationType
        region
        credentialsDependency {
          id
          name
        }
        job {
          id
          enclaveComputeJobId
          status
          updatedAt
          createdAt
          driverAttestationHash
          dataRoomHash
          purpose
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedDataRoomNodesQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomNodesQuery({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *   },
 * });
 */
export function usePublishedDataRoomNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomNodesQuery, Types.PublishedDataRoomNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomNodesQuery, Types.PublishedDataRoomNodesQueryVariables>(PublishedDataRoomNodesDocument, options);
      }
export function usePublishedDataRoomNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomNodesQuery, Types.PublishedDataRoomNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomNodesQuery, Types.PublishedDataRoomNodesQueryVariables>(PublishedDataRoomNodesDocument, options);
        }
export type PublishedDataRoomNodesQueryHookResult = ReturnType<typeof usePublishedDataRoomNodesQuery>;
export type PublishedDataRoomNodesLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomNodesLazyQuery>;
export type PublishedDataRoomNodesQueryResult = Apollo.QueryResult<Types.PublishedDataRoomNodesQuery, Types.PublishedDataRoomNodesQueryVariables>;
export const SetComputeNodesOrderDocument = gql`
    mutation SetComputeNodesOrder($dataRoomId: String!, $computeNodesOrder: [String!]!) {
  draftDataRoom {
    setComputeNodesOrder(
      draftDataRoomId: $dataRoomId
      draftNodeIds: $computeNodesOrder
    ) {
      id
      computeNodesOrder
    }
  }
}
    `;
export type SetComputeNodesOrderMutationFn = Apollo.MutationFunction<Types.SetComputeNodesOrderMutation, Types.SetComputeNodesOrderMutationVariables>;

/**
 * __useSetComputeNodesOrderMutation__
 *
 * To run a mutation, you first call `useSetComputeNodesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetComputeNodesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setComputeNodesOrderMutation, { data, loading, error }] = useSetComputeNodesOrderMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *      computeNodesOrder: // value for 'computeNodesOrder'
 *   },
 * });
 */
export function useSetComputeNodesOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetComputeNodesOrderMutation, Types.SetComputeNodesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetComputeNodesOrderMutation, Types.SetComputeNodesOrderMutationVariables>(SetComputeNodesOrderDocument, options);
      }
export type SetComputeNodesOrderMutationHookResult = ReturnType<typeof useSetComputeNodesOrderMutation>;
export type SetComputeNodesOrderMutationResult = Apollo.MutationResult<Types.SetComputeNodesOrderMutation>;
export type SetComputeNodesOrderMutationOptions = Apollo.BaseMutationOptions<Types.SetComputeNodesOrderMutation, Types.SetComputeNodesOrderMutationVariables>;
export const SetDataNodesOrderDocument = gql`
    mutation SetDataNodesOrder($dataRoomId: String!, $dataNodesOrder: [String!]!) {
  draftDataRoom {
    setDataNodesOrder(draftDataRoomId: $dataRoomId, draftNodeIds: $dataNodesOrder) {
      id
      dataNodesOrder
    }
  }
}
    `;
export type SetDataNodesOrderMutationFn = Apollo.MutationFunction<Types.SetDataNodesOrderMutation, Types.SetDataNodesOrderMutationVariables>;

/**
 * __useSetDataNodesOrderMutation__
 *
 * To run a mutation, you first call `useSetDataNodesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataNodesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataNodesOrderMutation, { data, loading, error }] = useSetDataNodesOrderMutation({
 *   variables: {
 *      dataRoomId: // value for 'dataRoomId'
 *      dataNodesOrder: // value for 'dataNodesOrder'
 *   },
 * });
 */
export function useSetDataNodesOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDataNodesOrderMutation, Types.SetDataNodesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDataNodesOrderMutation, Types.SetDataNodesOrderMutationVariables>(SetDataNodesOrderDocument, options);
      }
export type SetDataNodesOrderMutationHookResult = ReturnType<typeof useSetDataNodesOrderMutation>;
export type SetDataNodesOrderMutationResult = Apollo.MutationResult<Types.SetDataNodesOrderMutation>;
export type SetDataNodesOrderMutationOptions = Apollo.BaseMutationOptions<Types.SetDataNodesOrderMutation, Types.SetDataNodesOrderMutationVariables>;
export const OrganizationPreferencesDocument = gql`
    query OrganizationPreferences($userEmail: String!) {
  userByEmail(email: $userEmail) {
    id
    email
    organization {
      id
      hasAdvertiserFeatures
      hasAnalyticsFeatures
      hasPublisherFeatures
      hasDataPartnerFeatures
      canViewDataPartners
      canViewMeasurements
      allowExcludingSeedAudience
      state
    }
  }
}
    `;

/**
 * __useOrganizationPreferencesQuery__
 *
 * To run a query within a React component, call `useOrganizationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPreferencesQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useOrganizationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationPreferencesQuery, Types.OrganizationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationPreferencesQuery, Types.OrganizationPreferencesQueryVariables>(OrganizationPreferencesDocument, options);
      }
export function useOrganizationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationPreferencesQuery, Types.OrganizationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationPreferencesQuery, Types.OrganizationPreferencesQueryVariables>(OrganizationPreferencesDocument, options);
        }
export type OrganizationPreferencesQueryHookResult = ReturnType<typeof useOrganizationPreferencesQuery>;
export type OrganizationPreferencesLazyQueryHookResult = ReturnType<typeof useOrganizationPreferencesLazyQuery>;
export type OrganizationPreferencesQueryResult = Apollo.QueryResult<Types.OrganizationPreferencesQuery, Types.OrganizationPreferencesQueryVariables>;
export const SendErrorReportDocument = gql`
    mutation SendErrorReport($input: CreateErrorReportInput!) {
  errorReport {
    create(input: $input) {
      id
      createdAt
      report
    }
  }
}
    `;
export type SendErrorReportMutationFn = Apollo.MutationFunction<Types.SendErrorReportMutation, Types.SendErrorReportMutationVariables>;

/**
 * __useSendErrorReportMutation__
 *
 * To run a mutation, you first call `useSendErrorReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendErrorReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendErrorReportMutation, { data, loading, error }] = useSendErrorReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendErrorReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendErrorReportMutation, Types.SendErrorReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendErrorReportMutation, Types.SendErrorReportMutationVariables>(SendErrorReportDocument, options);
      }
export type SendErrorReportMutationHookResult = ReturnType<typeof useSendErrorReportMutation>;
export type SendErrorReportMutationResult = Apollo.MutationResult<Types.SendErrorReportMutation>;
export type SendErrorReportMutationOptions = Apollo.BaseMutationOptions<Types.SendErrorReportMutation, Types.SendErrorReportMutationVariables>;
export const UserRoleDocument = gql`
    query UserRole($userEmail: String!) {
  userByEmail(email: $userEmail) {
    id
    email
    userRole
    organization {
      id
    }
  }
}
    `;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions: Apollo.QueryHookOptions<Types.UserRoleQuery, Types.UserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserRoleQuery, Types.UserRoleQueryVariables>(UserRoleDocument, options);
      }
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserRoleQuery, Types.UserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserRoleQuery, Types.UserRoleQueryVariables>(UserRoleDocument, options);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<Types.UserRoleQuery, Types.UserRoleQueryVariables>;
export const OrganizationPageDocument = gql`
    query OrganizationPage($organizationId: String!) {
  organization(id: $organizationId) {
    id
    name
    state
    hasPublisherFeatures
    hasDataPartnerFeatures
    canViewDataPartners
  }
}
    `;

/**
 * __useOrganizationPageQuery__
 *
 * To run a query within a React component, call `useOrganizationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationPageQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationPageQuery, Types.OrganizationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationPageQuery, Types.OrganizationPageQueryVariables>(OrganizationPageDocument, options);
      }
export function useOrganizationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationPageQuery, Types.OrganizationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationPageQuery, Types.OrganizationPageQueryVariables>(OrganizationPageDocument, options);
        }
export type OrganizationPageQueryHookResult = ReturnType<typeof useOrganizationPageQuery>;
export type OrganizationPageLazyQueryHookResult = ReturnType<typeof useOrganizationPageLazyQuery>;
export type OrganizationPageQueryResult = Apollo.QueryResult<Types.OrganizationPageQuery, Types.OrganizationPageQueryVariables>;
export const OrganizationUsersDocument = gql`
    query OrganizationUsers($organizationId: String!) {
  organization(id: $organizationId) {
    id
    users {
      nodes {
        id
        email
        logo
        userRole
        createdAt
        isDemoUser
      }
    }
  }
}
    `;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationUsersQuery, Types.OrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationUsersQuery, Types.OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
      }
export function useOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationUsersQuery, Types.OrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationUsersQuery, Types.OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
        }
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = Apollo.QueryResult<Types.OrganizationUsersQuery, Types.OrganizationUsersQueryVariables>;
export const CompleteDraftDataRoomDocument = gql`
    query CompleteDraftDataRoom($id: String!) {
  draftDataRoom(id: $id) {
    id
    title
    description
    enableDevelopment
    enableInteractivity
    showOrganizationLogo
    owner {
      id
      email
    }
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              node {
                id
              }
            }
            ... on DraftDataOwnerPermission {
              node {
                id
              }
            }
          }
        }
      }
    }
    draftNodes {
      nodes {
        __typename
        id
        name
        createdAt
        updatedAt
        ... on DraftScriptingNode {
          output
          scriptingLanguage
          computationType
          scripts {
            nodes {
              name
              content
              isMainScript
            }
          }
          dependencies {
            nodes {
              ...DraftNodeConnectionId
            }
          }
        }
        ... on DraftMatchNode {
          config
          computationType
          dependencies {
            nodes {
              ...DraftNodeConnectionId
            }
          }
        }
        ... on DraftSqlNode {
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
          dependencies {
            nodes {
              ...DraftNodeConnectionId
            }
          }
        }
        ... on DraftSqliteNode {
          statement
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
          dependencies {
            nodes {
              ...DraftNodeConnectionId
            }
          }
        }
        ... on DraftSyntheticNode {
          accuracy
          computationType
          includeReportWithStats
          columns {
            nodes {
              index
              name
              maskType
              dataType
              shouldMaskColumn
            }
          }
          dependency {
            ...DraftNodeConnectionId
          }
        }
        ... on DraftPostNode {
          computationType
          dependency {
            ...DraftNodeConnectionId
          }
        }
        ... on DraftPreviewNode {
          computationType
          quotaBytes
          dependency {
            ...DraftNodeConnectionId
          }
        }
        ... on DraftS3SinkNode {
          computationType
          endpoint
          region
          credentialsDependency {
            ...DraftNodeConnectionId
          }
          uploadDependency {
            ...DraftNodeConnectionId
          }
        }
        ... on DraftRawLeafNode {
          isRequired
        }
        ... on DraftTableLeafNode {
          isRequired
          uniqueColumnIds
          columnsOrder
          minRows
          maxRows
          allowEmpty
          columns {
            nodes {
              id
              name
              dataType
              formatType
              isNullable
              hashWith
            }
          }
        }
      }
    }
    computeNodesOrder
    dataNodesOrder
  }
}
    ${DraftNodeConnectionIdFragment}`;

/**
 * __useCompleteDraftDataRoomQuery__
 *
 * To run a query within a React component, call `useCompleteDraftDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteDraftDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteDraftDataRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteDraftDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.CompleteDraftDataRoomQuery, Types.CompleteDraftDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompleteDraftDataRoomQuery, Types.CompleteDraftDataRoomQueryVariables>(CompleteDraftDataRoomDocument, options);
      }
export function useCompleteDraftDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompleteDraftDataRoomQuery, Types.CompleteDraftDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompleteDraftDataRoomQuery, Types.CompleteDraftDataRoomQueryVariables>(CompleteDraftDataRoomDocument, options);
        }
export type CompleteDraftDataRoomQueryHookResult = ReturnType<typeof useCompleteDraftDataRoomQuery>;
export type CompleteDraftDataRoomLazyQueryHookResult = ReturnType<typeof useCompleteDraftDataRoomLazyQuery>;
export type CompleteDraftDataRoomQueryResult = Apollo.QueryResult<Types.CompleteDraftDataRoomQuery, Types.CompleteDraftDataRoomQueryVariables>;
export const CreateComputeJobDocument = gql`
    mutation CreateComputeJob($input: CreateComputeJobInput!) {
  computeJob {
    create(input: $input) {
      record {
        id
        enclaveComputeJobId
        enclaveComputeJobHandleBase64
        status
        createdAt
        updatedAt
        dataRoomHash
        driverAttestationHash
        purpose
      }
    }
  }
}
    `;
export type CreateComputeJobMutationFn = Apollo.MutationFunction<Types.CreateComputeJobMutation, Types.CreateComputeJobMutationVariables>;

/**
 * __useCreateComputeJobMutation__
 *
 * To run a mutation, you first call `useCreateComputeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComputeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComputeJobMutation, { data, loading, error }] = useCreateComputeJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateComputeJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateComputeJobMutation, Types.CreateComputeJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateComputeJobMutation, Types.CreateComputeJobMutationVariables>(CreateComputeJobDocument, options);
      }
export type CreateComputeJobMutationHookResult = ReturnType<typeof useCreateComputeJobMutation>;
export type CreateComputeJobMutationResult = Apollo.MutationResult<Types.CreateComputeJobMutation>;
export type CreateComputeJobMutationOptions = Apollo.BaseMutationOptions<Types.CreateComputeJobMutation, Types.CreateComputeJobMutationVariables>;
export const GetComputeJobEnclaveHandleDocument = gql`
    query GetComputeJobEnclaveHandle($id: String!) {
  computeJob(id: $id) {
    enclaveComputeJobHandleBase64
  }
}
    `;

/**
 * __useGetComputeJobEnclaveHandleQuery__
 *
 * To run a query within a React component, call `useGetComputeJobEnclaveHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComputeJobEnclaveHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComputeJobEnclaveHandleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComputeJobEnclaveHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetComputeJobEnclaveHandleQuery, Types.GetComputeJobEnclaveHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetComputeJobEnclaveHandleQuery, Types.GetComputeJobEnclaveHandleQueryVariables>(GetComputeJobEnclaveHandleDocument, options);
      }
export function useGetComputeJobEnclaveHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetComputeJobEnclaveHandleQuery, Types.GetComputeJobEnclaveHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetComputeJobEnclaveHandleQuery, Types.GetComputeJobEnclaveHandleQueryVariables>(GetComputeJobEnclaveHandleDocument, options);
        }
export type GetComputeJobEnclaveHandleQueryHookResult = ReturnType<typeof useGetComputeJobEnclaveHandleQuery>;
export type GetComputeJobEnclaveHandleLazyQueryHookResult = ReturnType<typeof useGetComputeJobEnclaveHandleLazyQuery>;
export type GetComputeJobEnclaveHandleQueryResult = Apollo.QueryResult<Types.GetComputeJobEnclaveHandleQuery, Types.GetComputeJobEnclaveHandleQueryVariables>;
export const GetComputeJobsDocument = gql`
    query GetComputeJobs($input: NodeId!) {
  computeJobs(nodeId: $input) {
    nodes {
      id
      enclaveComputeJobId
      enclaveComputeJobHandleBase64
      status
      createdAt
      updatedAt
      autoFetching @client
      dataRoomHash
      driverAttestationHash
      purpose
    }
  }
}
    `;

/**
 * __useGetComputeJobsQuery__
 *
 * To run a query within a React component, call `useGetComputeJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComputeJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComputeJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComputeJobsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetComputeJobsQuery, Types.GetComputeJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetComputeJobsQuery, Types.GetComputeJobsQueryVariables>(GetComputeJobsDocument, options);
      }
export function useGetComputeJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetComputeJobsQuery, Types.GetComputeJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetComputeJobsQuery, Types.GetComputeJobsQueryVariables>(GetComputeJobsDocument, options);
        }
export type GetComputeJobsQueryHookResult = ReturnType<typeof useGetComputeJobsQuery>;
export type GetComputeJobsLazyQueryHookResult = ReturnType<typeof useGetComputeJobsLazyQuery>;
export type GetComputeJobsQueryResult = Apollo.QueryResult<Types.GetComputeJobsQuery, Types.GetComputeJobsQueryVariables>;
export const CompleteDataRoomRequestDocument = gql`
    query CompleteDataRoomRequest($id: String!) {
  dataRoomRequest(id: $id) {
    id
    participants {
      nodes {
        id
        userEmail
        permissions {
          nodes {
            ... on DraftAnalystPermission {
              node {
                id
              }
            }
            ... on DraftDataOwnerPermission {
              node {
                id
              }
            }
          }
        }
      }
    }
    draftNode {
      __typename
      id
      createdAt
      updatedAt
      permissions {
        nodes {
          ... on DraftAnalystPermission {
            participant {
              id
              userEmail
            }
          }
        }
      }
      ... on DraftMatchNode {
        name
        config
        computationType
        dependencies {
          nodes {
            ... on PublishedNodeConnection {
              computeNodeId
              publishedDataRoomId
            }
          }
        }
      }
      ... on DraftSqlNode {
        name
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
      }
      ... on DraftSqliteNode {
        name
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
      }
      ... on DraftSyntheticNode {
        name
        accuracy
        includeReportWithStats
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftPostNode {
        name
        computationType
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftPreviewNode {
        name
        quotaBytes
        computationType
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftScriptingNode {
        name
        computationType
        scripts {
          nodes {
            id
            name
            content
            isMainScript
          }
        }
        dependencies {
          nodes {
            ... on PublishedNodeConnection {
              computeNodeId
              publishedDataRoomId
            }
          }
        }
        output
        scriptingLanguage
      }
      ... on DraftSyntheticNode {
        name
        accuracy
        computationType
        includeReportWithStats
        columns {
          nodes {
            id
            index
            name
            maskType
            dataType
            shouldMaskColumn
          }
        }
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompleteDataRoomRequestQuery__
 *
 * To run a query within a React component, call `useCompleteDataRoomRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteDataRoomRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteDataRoomRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteDataRoomRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.CompleteDataRoomRequestQuery, Types.CompleteDataRoomRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompleteDataRoomRequestQuery, Types.CompleteDataRoomRequestQueryVariables>(CompleteDataRoomRequestDocument, options);
      }
export function useCompleteDataRoomRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompleteDataRoomRequestQuery, Types.CompleteDataRoomRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompleteDataRoomRequestQuery, Types.CompleteDataRoomRequestQueryVariables>(CompleteDataRoomRequestDocument, options);
        }
export type CompleteDataRoomRequestQueryHookResult = ReturnType<typeof useCompleteDataRoomRequestQuery>;
export type CompleteDataRoomRequestLazyQueryHookResult = ReturnType<typeof useCompleteDataRoomRequestLazyQuery>;
export type CompleteDataRoomRequestQueryResult = Apollo.QueryResult<Types.CompleteDataRoomRequestQuery, Types.CompleteDataRoomRequestQueryVariables>;
export const DatasetExportDocument = gql`
    query DatasetExport($id: String!) {
  datasetExport(id: $id) {
    ...DatasetExport
  }
}
    ${DatasetExportFragment}`;

/**
 * __useDatasetExportQuery__
 *
 * To run a query within a React component, call `useDatasetExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatasetExportQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetExportQuery, Types.DatasetExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetExportQuery, Types.DatasetExportQueryVariables>(DatasetExportDocument, options);
      }
export function useDatasetExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetExportQuery, Types.DatasetExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetExportQuery, Types.DatasetExportQueryVariables>(DatasetExportDocument, options);
        }
export type DatasetExportQueryHookResult = ReturnType<typeof useDatasetExportQuery>;
export type DatasetExportLazyQueryHookResult = ReturnType<typeof useDatasetExportLazyQuery>;
export type DatasetExportQueryResult = Apollo.QueryResult<Types.DatasetExportQuery, Types.DatasetExportQueryVariables>;
export const DatasetExportsDocument = gql`
    query DatasetExports($filter: DatasetExportFilter) {
  datasetExports(filter: $filter) {
    nodes {
      ...DatasetExport
    }
  }
}
    ${DatasetExportFragment}`;

/**
 * __useDatasetExportsQuery__
 *
 * To run a query within a React component, call `useDatasetExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetExportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDatasetExportsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DatasetExportsQuery, Types.DatasetExportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetExportsQuery, Types.DatasetExportsQueryVariables>(DatasetExportsDocument, options);
      }
export function useDatasetExportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetExportsQuery, Types.DatasetExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetExportsQuery, Types.DatasetExportsQueryVariables>(DatasetExportsDocument, options);
        }
export type DatasetExportsQueryHookResult = ReturnType<typeof useDatasetExportsQuery>;
export type DatasetExportsLazyQueryHookResult = ReturnType<typeof useDatasetExportsLazyQuery>;
export type DatasetExportsQueryResult = Apollo.QueryResult<Types.DatasetExportsQuery, Types.DatasetExportsQueryVariables>;
export const DatasetImportDocument = gql`
    query DatasetImport($id: String!) {
  datasetImport(id: $id) {
    ...DatasetImport
  }
}
    ${DatasetImportFragment}`;

/**
 * __useDatasetImportQuery__
 *
 * To run a query within a React component, call `useDatasetImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatasetImportQuery(baseOptions: Apollo.QueryHookOptions<Types.DatasetImportQuery, Types.DatasetImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetImportQuery, Types.DatasetImportQueryVariables>(DatasetImportDocument, options);
      }
export function useDatasetImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetImportQuery, Types.DatasetImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetImportQuery, Types.DatasetImportQueryVariables>(DatasetImportDocument, options);
        }
export type DatasetImportQueryHookResult = ReturnType<typeof useDatasetImportQuery>;
export type DatasetImportLazyQueryHookResult = ReturnType<typeof useDatasetImportLazyQuery>;
export type DatasetImportQueryResult = Apollo.QueryResult<Types.DatasetImportQuery, Types.DatasetImportQueryVariables>;
export const DatasetImportsDocument = gql`
    query DatasetImports($filter: DatasetImportFilter) {
  datasetImports(filter: $filter) {
    nodes {
      ...DatasetImport
    }
  }
}
    ${DatasetImportFragment}`;

/**
 * __useDatasetImportsQuery__
 *
 * To run a query within a React component, call `useDatasetImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetImportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDatasetImportsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DatasetImportsQuery, Types.DatasetImportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DatasetImportsQuery, Types.DatasetImportsQueryVariables>(DatasetImportsDocument, options);
      }
export function useDatasetImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DatasetImportsQuery, Types.DatasetImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DatasetImportsQuery, Types.DatasetImportsQueryVariables>(DatasetImportsDocument, options);
        }
export type DatasetImportsQueryHookResult = ReturnType<typeof useDatasetImportsQuery>;
export type DatasetImportsLazyQueryHookResult = ReturnType<typeof useDatasetImportsLazyQuery>;
export type DatasetImportsQueryResult = Apollo.QueryResult<Types.DatasetImportsQuery, Types.DatasetImportsQueryVariables>;
export const CreateLookalikeMediaComputeJobDocument = gql`
    mutation CreateLookalikeMediaComputeJob($input: CreateMediaComputeJobInput!) {
  mediaComputeJob {
    create(input: $input) {
      record {
        ...LookalikeMediaComputeJobComplete
      }
    }
  }
}
    ${LookalikeMediaComputeJobCompleteFragment}`;
export type CreateLookalikeMediaComputeJobMutationFn = Apollo.MutationFunction<Types.CreateLookalikeMediaComputeJobMutation, Types.CreateLookalikeMediaComputeJobMutationVariables>;

/**
 * __useCreateLookalikeMediaComputeJobMutation__
 *
 * To run a mutation, you first call `useCreateLookalikeMediaComputeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLookalikeMediaComputeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLookalikeMediaComputeJobMutation, { data, loading, error }] = useCreateLookalikeMediaComputeJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLookalikeMediaComputeJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLookalikeMediaComputeJobMutation, Types.CreateLookalikeMediaComputeJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLookalikeMediaComputeJobMutation, Types.CreateLookalikeMediaComputeJobMutationVariables>(CreateLookalikeMediaComputeJobDocument, options);
      }
export type CreateLookalikeMediaComputeJobMutationHookResult = ReturnType<typeof useCreateLookalikeMediaComputeJobMutation>;
export type CreateLookalikeMediaComputeJobMutationResult = Apollo.MutationResult<Types.CreateLookalikeMediaComputeJobMutation>;
export type CreateLookalikeMediaComputeJobMutationOptions = Apollo.BaseMutationOptions<Types.CreateLookalikeMediaComputeJobMutation, Types.CreateLookalikeMediaComputeJobMutationVariables>;
export const GetLookalikeMediaComputeJobDocument = gql`
    query GetLookalikeMediaComputeJob($input: MediaComputeJobFilterInput!) {
  mediaComputeJob(input: $input) {
    ...LookalikeMediaComputeJobComplete
  }
}
    ${LookalikeMediaComputeJobCompleteFragment}`;

/**
 * __useGetLookalikeMediaComputeJobQuery__
 *
 * To run a query within a React component, call `useGetLookalikeMediaComputeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLookalikeMediaComputeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLookalikeMediaComputeJobQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLookalikeMediaComputeJobQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLookalikeMediaComputeJobQuery, Types.GetLookalikeMediaComputeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLookalikeMediaComputeJobQuery, Types.GetLookalikeMediaComputeJobQueryVariables>(GetLookalikeMediaComputeJobDocument, options);
      }
export function useGetLookalikeMediaComputeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLookalikeMediaComputeJobQuery, Types.GetLookalikeMediaComputeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLookalikeMediaComputeJobQuery, Types.GetLookalikeMediaComputeJobQueryVariables>(GetLookalikeMediaComputeJobDocument, options);
        }
export type GetLookalikeMediaComputeJobQueryHookResult = ReturnType<typeof useGetLookalikeMediaComputeJobQuery>;
export type GetLookalikeMediaComputeJobLazyQueryHookResult = ReturnType<typeof useGetLookalikeMediaComputeJobLazyQuery>;
export type GetLookalikeMediaComputeJobQueryResult = Apollo.QueryResult<Types.GetLookalikeMediaComputeJobQuery, Types.GetLookalikeMediaComputeJobQueryVariables>;
export const CreateDatasetExportDocument = gql`
    mutation CreateDatasetExport($input: ExportDatasetInput!) {
  createDatasetExport(input: $input) @client {
    datasetExport {
      ...DatasetExport
    }
  }
}
    ${DatasetExportFragment}`;
export type CreateDatasetExportMutationFn = Apollo.MutationFunction<Types.CreateDatasetExportMutation, Types.CreateDatasetExportMutationVariables>;

/**
 * __useCreateDatasetExportMutation__
 *
 * To run a mutation, you first call `useCreateDatasetExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatasetExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatasetExportMutation, { data, loading, error }] = useCreateDatasetExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDatasetExportMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDatasetExportMutation, Types.CreateDatasetExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDatasetExportMutation, Types.CreateDatasetExportMutationVariables>(CreateDatasetExportDocument, options);
      }
export type CreateDatasetExportMutationHookResult = ReturnType<typeof useCreateDatasetExportMutation>;
export type CreateDatasetExportMutationResult = Apollo.MutationResult<Types.CreateDatasetExportMutation>;
export type CreateDatasetExportMutationOptions = Apollo.BaseMutationOptions<Types.CreateDatasetExportMutation, Types.CreateDatasetExportMutationVariables>;
export const PollDatasetExportDocument = gql`
    mutation PollDatasetExport($id: String!) {
  pollDatasetExport(id: $id) @client {
    error
    success
  }
}
    `;
export type PollDatasetExportMutationFn = Apollo.MutationFunction<Types.PollDatasetExportMutation, Types.PollDatasetExportMutationVariables>;

/**
 * __usePollDatasetExportMutation__
 *
 * To run a mutation, you first call `usePollDatasetExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePollDatasetExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pollDatasetExportMutation, { data, loading, error }] = usePollDatasetExportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePollDatasetExportMutation(baseOptions?: Apollo.MutationHookOptions<Types.PollDatasetExportMutation, Types.PollDatasetExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PollDatasetExportMutation, Types.PollDatasetExportMutationVariables>(PollDatasetExportDocument, options);
      }
export type PollDatasetExportMutationHookResult = ReturnType<typeof usePollDatasetExportMutation>;
export type PollDatasetExportMutationResult = Apollo.MutationResult<Types.PollDatasetExportMutation>;
export type PollDatasetExportMutationOptions = Apollo.BaseMutationOptions<Types.PollDatasetExportMutation, Types.PollDatasetExportMutationVariables>;
export const CreateDatasetImportDocument = gql`
    mutation CreateDatasetImport($input: ImportDatasetInput!) {
  createDatasetImport(input: $input) @client {
    datasetImport {
      ...DatasetImport
    }
  }
}
    ${DatasetImportFragment}`;
export type CreateDatasetImportMutationFn = Apollo.MutationFunction<Types.CreateDatasetImportMutation, Types.CreateDatasetImportMutationVariables>;

/**
 * __useCreateDatasetImportMutation__
 *
 * To run a mutation, you first call `useCreateDatasetImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatasetImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatasetImportMutation, { data, loading, error }] = useCreateDatasetImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDatasetImportMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDatasetImportMutation, Types.CreateDatasetImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDatasetImportMutation, Types.CreateDatasetImportMutationVariables>(CreateDatasetImportDocument, options);
      }
export type CreateDatasetImportMutationHookResult = ReturnType<typeof useCreateDatasetImportMutation>;
export type CreateDatasetImportMutationResult = Apollo.MutationResult<Types.CreateDatasetImportMutation>;
export type CreateDatasetImportMutationOptions = Apollo.BaseMutationOptions<Types.CreateDatasetImportMutation, Types.CreateDatasetImportMutationVariables>;
export const PollDatasetImportDocument = gql`
    mutation PollDatasetImport($id: String!) {
  pollDatasetImport(id: $id) @client {
    success
    error
  }
}
    `;
export type PollDatasetImportMutationFn = Apollo.MutationFunction<Types.PollDatasetImportMutation, Types.PollDatasetImportMutationVariables>;

/**
 * __usePollDatasetImportMutation__
 *
 * To run a mutation, you first call `usePollDatasetImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePollDatasetImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pollDatasetImportMutation, { data, loading, error }] = usePollDatasetImportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePollDatasetImportMutation(baseOptions?: Apollo.MutationHookOptions<Types.PollDatasetImportMutation, Types.PollDatasetImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PollDatasetImportMutation, Types.PollDatasetImportMutationVariables>(PollDatasetImportDocument, options);
      }
export type PollDatasetImportMutationHookResult = ReturnType<typeof usePollDatasetImportMutation>;
export type PollDatasetImportMutationResult = Apollo.MutationResult<Types.PollDatasetImportMutation>;
export type PollDatasetImportMutationOptions = Apollo.BaseMutationOptions<Types.PollDatasetImportMutation, Types.PollDatasetImportMutationVariables>;
export const MergeRemoteSubmittedDataRoomRequestDocument = gql`
    mutation MergeRemoteSubmittedDataRoomRequest($id: String!) {
  submittedDataRoomRequest {
    merge(submittedDataRoomRequestId: $id)
  }
}
    `;
export type MergeRemoteSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.MergeRemoteSubmittedDataRoomRequestMutation, Types.MergeRemoteSubmittedDataRoomRequestMutationVariables>;

/**
 * __useMergeRemoteSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useMergeRemoteSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeRemoteSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeRemoteSubmittedDataRoomRequestMutation, { data, loading, error }] = useMergeRemoteSubmittedDataRoomRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMergeRemoteSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.MergeRemoteSubmittedDataRoomRequestMutation, Types.MergeRemoteSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MergeRemoteSubmittedDataRoomRequestMutation, Types.MergeRemoteSubmittedDataRoomRequestMutationVariables>(MergeRemoteSubmittedDataRoomRequestDocument, options);
      }
export type MergeRemoteSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useMergeRemoteSubmittedDataRoomRequestMutation>;
export type MergeRemoteSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.MergeRemoteSubmittedDataRoomRequestMutation>;
export type MergeRemoteSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.MergeRemoteSubmittedDataRoomRequestMutation, Types.MergeRemoteSubmittedDataRoomRequestMutationVariables>;
export const SubmittedDataRoomRequestSignaturesDocument = gql`
    query SubmittedDataRoomRequestSignatures($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    enclaveCommitId
    publishedDataRoom {
      id
    }
    signers {
      nodes {
        userEmail
        user {
          email
        }
        signature
      }
    }
  }
}
    `;

/**
 * __useSubmittedDataRoomRequestSignaturesQuery__
 *
 * To run a query within a React component, call `useSubmittedDataRoomRequestSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedDataRoomRequestSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedDataRoomRequestSignaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmittedDataRoomRequestSignaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.SubmittedDataRoomRequestSignaturesQuery, Types.SubmittedDataRoomRequestSignaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubmittedDataRoomRequestSignaturesQuery, Types.SubmittedDataRoomRequestSignaturesQueryVariables>(SubmittedDataRoomRequestSignaturesDocument, options);
      }
export function useSubmittedDataRoomRequestSignaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubmittedDataRoomRequestSignaturesQuery, Types.SubmittedDataRoomRequestSignaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubmittedDataRoomRequestSignaturesQuery, Types.SubmittedDataRoomRequestSignaturesQueryVariables>(SubmittedDataRoomRequestSignaturesDocument, options);
        }
export type SubmittedDataRoomRequestSignaturesQueryHookResult = ReturnType<typeof useSubmittedDataRoomRequestSignaturesQuery>;
export type SubmittedDataRoomRequestSignaturesLazyQueryHookResult = ReturnType<typeof useSubmittedDataRoomRequestSignaturesLazyQuery>;
export type SubmittedDataRoomRequestSignaturesQueryResult = Apollo.QueryResult<Types.SubmittedDataRoomRequestSignaturesQuery, Types.SubmittedDataRoomRequestSignaturesQueryVariables>;
export const CompleteComputeJobFailureDocument = gql`
    mutation CompleteComputeJobFailure($id: String!) {
  computeJob {
    failed(id: $id) {
      record {
        __typename
        id
        enclaveComputeJobId
        status
        updatedAt
        createdAt
      }
    }
  }
}
    `;
export type CompleteComputeJobFailureMutationFn = Apollo.MutationFunction<Types.CompleteComputeJobFailureMutation, Types.CompleteComputeJobFailureMutationVariables>;

/**
 * __useCompleteComputeJobFailureMutation__
 *
 * To run a mutation, you first call `useCompleteComputeJobFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteComputeJobFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeComputeJobFailureMutation, { data, loading, error }] = useCompleteComputeJobFailureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteComputeJobFailureMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteComputeJobFailureMutation, Types.CompleteComputeJobFailureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteComputeJobFailureMutation, Types.CompleteComputeJobFailureMutationVariables>(CompleteComputeJobFailureDocument, options);
      }
export type CompleteComputeJobFailureMutationHookResult = ReturnType<typeof useCompleteComputeJobFailureMutation>;
export type CompleteComputeJobFailureMutationResult = Apollo.MutationResult<Types.CompleteComputeJobFailureMutation>;
export type CompleteComputeJobFailureMutationOptions = Apollo.BaseMutationOptions<Types.CompleteComputeJobFailureMutation, Types.CompleteComputeJobFailureMutationVariables>;
export const CompleteComputeJobSuccessDocument = gql`
    mutation CompleteComputeJobSuccess($id: String!) {
  computeJob {
    succeded(id: $id) {
      record {
        __typename
        id
        enclaveComputeJobId
        status
        updatedAt
        createdAt
      }
    }
  }
}
    `;
export type CompleteComputeJobSuccessMutationFn = Apollo.MutationFunction<Types.CompleteComputeJobSuccessMutation, Types.CompleteComputeJobSuccessMutationVariables>;

/**
 * __useCompleteComputeJobSuccessMutation__
 *
 * To run a mutation, you first call `useCompleteComputeJobSuccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteComputeJobSuccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeComputeJobSuccessMutation, { data, loading, error }] = useCompleteComputeJobSuccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteComputeJobSuccessMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteComputeJobSuccessMutation, Types.CompleteComputeJobSuccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteComputeJobSuccessMutation, Types.CompleteComputeJobSuccessMutationVariables>(CompleteComputeJobSuccessDocument, options);
      }
export type CompleteComputeJobSuccessMutationHookResult = ReturnType<typeof useCompleteComputeJobSuccessMutation>;
export type CompleteComputeJobSuccessMutationResult = Apollo.MutationResult<Types.CompleteComputeJobSuccessMutation>;
export type CompleteComputeJobSuccessMutationOptions = Apollo.BaseMutationOptions<Types.CompleteComputeJobSuccessMutation, Types.CompleteComputeJobSuccessMutationVariables>;
export const PublishDraftDataRoomRemoteDocument = gql`
    mutation PublishDraftDataRoomRemote($input: PublishDraftDataRoomInput!) {
  draftDataRoom {
    publish(input: $input) {
      record {
        id
      }
      query {
        dataRooms(sortBy: {updatedAt: DESCENDING}) {
          nodes {
            ... on DraftDataRoom {
              ...NavigationDrawerDraftDataRoom
            }
            ... on PublishedDataRoom {
              ...NavigationDrawerPublishedDataRoom
            }
            ... on PublishedMediaDataRoom {
              ...NavigationDrawerPublishedMediaDataRoom
            }
          }
        }
      }
    }
  }
}
    ${NavigationDrawerDraftDataRoomFragment}
${NavigationDrawerPublishedDataRoomFragment}
${NavigationDrawerPublishedMediaDataRoomFragment}`;
export type PublishDraftDataRoomRemoteMutationFn = Apollo.MutationFunction<Types.PublishDraftDataRoomRemoteMutation, Types.PublishDraftDataRoomRemoteMutationVariables>;

/**
 * __usePublishDraftDataRoomRemoteMutation__
 *
 * To run a mutation, you first call `usePublishDraftDataRoomRemoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftDataRoomRemoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftDataRoomRemoteMutation, { data, loading, error }] = usePublishDraftDataRoomRemoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDraftDataRoomRemoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishDraftDataRoomRemoteMutation, Types.PublishDraftDataRoomRemoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishDraftDataRoomRemoteMutation, Types.PublishDraftDataRoomRemoteMutationVariables>(PublishDraftDataRoomRemoteDocument, options);
      }
export type PublishDraftDataRoomRemoteMutationHookResult = ReturnType<typeof usePublishDraftDataRoomRemoteMutation>;
export type PublishDraftDataRoomRemoteMutationResult = Apollo.MutationResult<Types.PublishDraftDataRoomRemoteMutation>;
export type PublishDraftDataRoomRemoteMutationOptions = Apollo.BaseMutationOptions<Types.PublishDraftDataRoomRemoteMutation, Types.PublishDraftDataRoomRemoteMutationVariables>;
export const RebaseSubmittedDataRoomRequestDocument = gql`
    mutation RebaseSubmittedDataRoomRequest($input: RebaseSubmittedDataRoomRequestInput!) {
  submittedDataRoomRequest {
    rebase(input: $input) {
      id
      enclaveCommitId
      enclaveConfigurationPin
      signers {
        nodes {
          id
          userEmail
          user {
            id
            email
          }
          signature
        }
      }
      owner {
        id
        email
      }
      createdAt
      updatedAt
      node @client {
        __typename
        id
        name
        dcrHash
        driverAttestationHash
        commitId
        permissions {
          ... on PublishedAnalystPermission {
            participant {
              userEmail
            }
          }
          ... on PublishedDataOwnerPermission {
            participant {
              userEmail
            }
          }
        }
        ... on PublishedScriptingNode {
          computationType
          output
          scriptingLanguage
          scripts {
            name
            content
            isMainScript
          }
          dependencies {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
        }
        ... on PublishedSqlNode {
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
          dependencies {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
          statement
        }
        ... on PublishedSqliteNode {
          computationType
          privacyFilter {
            minimumRows
            isEnabled
          }
          dependencies {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
          statement
        }
        ... on PublishedMatchNode {
          computationType
          dependencies {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
          config
        }
        ... on PublishedSyntheticNode {
          computationType
          accuracy
          includeReportWithStats
          dependency {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
          columns {
            name
            shouldMaskColumn
            index
            maskType
            dataType
          }
        }
        ... on PublishedPostNode {
          computationType
          dependency {
            __typename
            id
            dcrHash
            driverAttestationHash
            commitId
          }
        }
        ... on PublishedPreviewNode {
          computationType
          dependency {
            __typename
            id
            dcrHash
            driverAttestationHash
            commitId
          }
        }
        ... on PublishedS3SinkNode {
          endpoint
          computationType
          region
          credentialsDependency {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
          uploadDependency {
            __typename
            id
            name
            dcrHash
            driverAttestationHash
            commitId
          }
        }
      }
    }
  }
}
    `;
export type RebaseSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.RebaseSubmittedDataRoomRequestMutation, Types.RebaseSubmittedDataRoomRequestMutationVariables>;

/**
 * __useRebaseSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useRebaseSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebaseSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebaseSubmittedDataRoomRequestMutation, { data, loading, error }] = useRebaseSubmittedDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebaseSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.RebaseSubmittedDataRoomRequestMutation, Types.RebaseSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RebaseSubmittedDataRoomRequestMutation, Types.RebaseSubmittedDataRoomRequestMutationVariables>(RebaseSubmittedDataRoomRequestDocument, options);
      }
export type RebaseSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useRebaseSubmittedDataRoomRequestMutation>;
export type RebaseSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.RebaseSubmittedDataRoomRequestMutation>;
export type RebaseSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.RebaseSubmittedDataRoomRequestMutation, Types.RebaseSubmittedDataRoomRequestMutationVariables>;
export const PublishedComputeNodeJobDocument = gql`
    query PublishedComputeNodeJob($computeNodeId: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $computeNodeId
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    dcrHash
    driverAttestationHash
    commitId
    ... on PublishedSqlNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSqliteNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedMatchNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPostNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedPreviewNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedScriptingNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedSyntheticNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
    ... on PublishedS3SinkNode {
      job {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        driverAttestationHash
        dataRoomHash
        purpose
      }
    }
  }
}
    `;

/**
 * __usePublishedComputeNodeJobQuery__
 *
 * To run a query within a React component, call `usePublishedComputeNodeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedComputeNodeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedComputeNodeJobQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedComputeNodeJobQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedComputeNodeJobQuery, Types.PublishedComputeNodeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedComputeNodeJobQuery, Types.PublishedComputeNodeJobQueryVariables>(PublishedComputeNodeJobDocument, options);
      }
export function usePublishedComputeNodeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedComputeNodeJobQuery, Types.PublishedComputeNodeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedComputeNodeJobQuery, Types.PublishedComputeNodeJobQueryVariables>(PublishedComputeNodeJobDocument, options);
        }
export type PublishedComputeNodeJobQueryHookResult = ReturnType<typeof usePublishedComputeNodeJobQuery>;
export type PublishedComputeNodeJobLazyQueryHookResult = ReturnType<typeof usePublishedComputeNodeJobLazyQuery>;
export type PublishedComputeNodeJobQueryResult = Apollo.QueryResult<Types.PublishedComputeNodeJobQuery, Types.PublishedComputeNodeJobQueryVariables>;
export const PublishedNodeTypenameDocument = gql`
    query PublishedNodeTypename($id: String!, $dcrHash: HexString!, $driverAttestationHash: HexString!, $commitId: HexString) {
  publishedNode(
    id: $id
    dcrHash: $dcrHash
    driverAttestationHash: $driverAttestationHash
    commitId: $commitId
  ) @client {
    id
    name
    dcrHash
    driverAttestationHash
    commitId
  }
}
    `;

/**
 * __usePublishedNodeTypenameQuery__
 *
 * To run a query within a React component, call `usePublishedNodeTypenameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNodeTypenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNodeTypenameQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dcrHash: // value for 'dcrHash'
 *      driverAttestationHash: // value for 'driverAttestationHash'
 *      commitId: // value for 'commitId'
 *   },
 * });
 */
export function usePublishedNodeTypenameQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedNodeTypenameQuery, Types.PublishedNodeTypenameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedNodeTypenameQuery, Types.PublishedNodeTypenameQueryVariables>(PublishedNodeTypenameDocument, options);
      }
export function usePublishedNodeTypenameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedNodeTypenameQuery, Types.PublishedNodeTypenameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedNodeTypenameQuery, Types.PublishedNodeTypenameQueryVariables>(PublishedNodeTypenameDocument, options);
        }
export type PublishedNodeTypenameQueryHookResult = ReturnType<typeof usePublishedNodeTypenameQuery>;
export type PublishedNodeTypenameLazyQueryHookResult = ReturnType<typeof usePublishedNodeTypenameLazyQuery>;
export type PublishedNodeTypenameQueryResult = Apollo.QueryResult<Types.PublishedNodeTypenameQuery, Types.PublishedNodeTypenameQueryVariables>;
export const CompletePlaygroundDocument = gql`
    query CompletePlayground($id: String!) {
  playground(id: $id) {
    id
    draftNode {
      __typename
      id
      createdAt
      updatedAt
      ... on DraftMatchNode {
        name
        config
        computationType
        dependencies {
          nodes {
            ... on PublishedNodeConnection {
              computeNodeId
              publishedDataRoomId
            }
          }
        }
      }
      ... on DraftSqlNode {
        name
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
      }
      ... on DraftSqliteNode {
        name
        statement
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
      }
      ... on DraftSyntheticNode {
        name
        accuracy
        includeReportWithStats
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftPostNode {
        name
        computationType
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftPreviewNode {
        name
        quotaBytes
        computationType
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
      ... on DraftScriptingNode {
        name
        computationType
        scripts {
          nodes {
            id
            name
            content
            isMainScript
          }
        }
        dependencies {
          nodes {
            ... on PublishedNodeConnection {
              computeNodeId
              publishedDataRoomId
            }
          }
        }
        output
        scriptingLanguage
      }
      ... on DraftSyntheticNode {
        name
        accuracy
        computationType
        includeReportWithStats
        columns {
          nodes {
            id
            index
            name
            maskType
            dataType
            shouldMaskColumn
          }
        }
        dependency {
          ... on PublishedNodeConnection {
            computeNodeId
            publishedDataRoomId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompletePlaygroundQuery__
 *
 * To run a query within a React component, call `useCompletePlaygroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletePlaygroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletePlaygroundQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletePlaygroundQuery(baseOptions: Apollo.QueryHookOptions<Types.CompletePlaygroundQuery, Types.CompletePlaygroundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompletePlaygroundQuery, Types.CompletePlaygroundQueryVariables>(CompletePlaygroundDocument, options);
      }
export function useCompletePlaygroundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompletePlaygroundQuery, Types.CompletePlaygroundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompletePlaygroundQuery, Types.CompletePlaygroundQueryVariables>(CompletePlaygroundDocument, options);
        }
export type CompletePlaygroundQueryHookResult = ReturnType<typeof useCompletePlaygroundQuery>;
export type CompletePlaygroundLazyQueryHookResult = ReturnType<typeof useCompletePlaygroundLazyQuery>;
export type CompletePlaygroundQueryResult = Apollo.QueryResult<Types.CompletePlaygroundQuery, Types.CompletePlaygroundQueryVariables>;
export const DevComputeNodeJobDocument = gql`
    query DevComputeNodeJob($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    ... on DraftMatchNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftSqlNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftSqliteNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftSqliteNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftPostNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftPreviewNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftScriptingNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftSyntheticNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
    ... on DraftS3SinkNode {
      job @client {
        id
        enclaveComputeJobId
        status
        createdAt
        autoFetching
        dataRoomHash
        purpose
        driverAttestationHash
        enclaveComputeJobHandleBase64
      }
    }
  }
}
    `;

/**
 * __useDevComputeNodeJobQuery__
 *
 * To run a query within a React component, call `useDevComputeNodeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevComputeNodeJobQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDevComputeNodeJobQuery(baseOptions: Apollo.QueryHookOptions<Types.DevComputeNodeJobQuery, Types.DevComputeNodeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DevComputeNodeJobQuery, Types.DevComputeNodeJobQueryVariables>(DevComputeNodeJobDocument, options);
      }
export function useDevComputeNodeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DevComputeNodeJobQuery, Types.DevComputeNodeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DevComputeNodeJobQuery, Types.DevComputeNodeJobQueryVariables>(DevComputeNodeJobDocument, options);
        }
export type DevComputeNodeJobQueryHookResult = ReturnType<typeof useDevComputeNodeJobQuery>;
export type DevComputeNodeJobLazyQueryHookResult = ReturnType<typeof useDevComputeNodeJobLazyQuery>;
export type DevComputeNodeJobQueryResult = Apollo.QueryResult<Types.DevComputeNodeJobQuery, Types.DevComputeNodeJobQueryVariables>;
export const DevComputeNodeTypenameDocument = gql`
    query DevComputeNodeTypename($playgroundId: String!) {
  playground(id: $playgroundId) {
    id
    draftNode {
      id
    }
  }
}
    `;

/**
 * __useDevComputeNodeTypenameQuery__
 *
 * To run a query within a React component, call `useDevComputeNodeTypenameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevComputeNodeTypenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevComputeNodeTypenameQuery({
 *   variables: {
 *      playgroundId: // value for 'playgroundId'
 *   },
 * });
 */
export function useDevComputeNodeTypenameQuery(baseOptions: Apollo.QueryHookOptions<Types.DevComputeNodeTypenameQuery, Types.DevComputeNodeTypenameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DevComputeNodeTypenameQuery, Types.DevComputeNodeTypenameQueryVariables>(DevComputeNodeTypenameDocument, options);
      }
export function useDevComputeNodeTypenameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DevComputeNodeTypenameQuery, Types.DevComputeNodeTypenameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DevComputeNodeTypenameQuery, Types.DevComputeNodeTypenameQueryVariables>(DevComputeNodeTypenameDocument, options);
        }
export type DevComputeNodeTypenameQueryHookResult = ReturnType<typeof useDevComputeNodeTypenameQuery>;
export type DevComputeNodeTypenameLazyQueryHookResult = ReturnType<typeof useDevComputeNodeTypenameLazyQuery>;
export type DevComputeNodeTypenameQueryResult = Apollo.QueryResult<Types.DevComputeNodeTypenameQuery, Types.DevComputeNodeTypenameQueryVariables>;
export const AddSubmittedDataRoomRequestSignatureDocument = gql`
    mutation AddSubmittedDataRoomRequestSignature($input: CreateDataRoomRequestSignatureInput!) {
  submittedDataRoomRequest {
    addSignature(input: $input) {
      id
      signers {
        nodes {
          id
          signature
          userEmail
          user {
            id
            email
          }
        }
      }
    }
  }
}
    `;
export type AddSubmittedDataRoomRequestSignatureMutationFn = Apollo.MutationFunction<Types.AddSubmittedDataRoomRequestSignatureMutation, Types.AddSubmittedDataRoomRequestSignatureMutationVariables>;

/**
 * __useAddSubmittedDataRoomRequestSignatureMutation__
 *
 * To run a mutation, you first call `useAddSubmittedDataRoomRequestSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubmittedDataRoomRequestSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubmittedDataRoomRequestSignatureMutation, { data, loading, error }] = useAddSubmittedDataRoomRequestSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubmittedDataRoomRequestSignatureMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubmittedDataRoomRequestSignatureMutation, Types.AddSubmittedDataRoomRequestSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubmittedDataRoomRequestSignatureMutation, Types.AddSubmittedDataRoomRequestSignatureMutationVariables>(AddSubmittedDataRoomRequestSignatureDocument, options);
      }
export type AddSubmittedDataRoomRequestSignatureMutationHookResult = ReturnType<typeof useAddSubmittedDataRoomRequestSignatureMutation>;
export type AddSubmittedDataRoomRequestSignatureMutationResult = Apollo.MutationResult<Types.AddSubmittedDataRoomRequestSignatureMutation>;
export type AddSubmittedDataRoomRequestSignatureMutationOptions = Apollo.BaseMutationOptions<Types.AddSubmittedDataRoomRequestSignatureMutation, Types.AddSubmittedDataRoomRequestSignatureMutationVariables>;
export const SignSubmittedDataRoomRequestDocument = gql`
    mutation SignSubmittedDataRoomRequest($input: SignSubmittedDataRoomRequestLocalInput!) {
  signSubmittedDataRoomRequest(input: $input) @client {
    signature
    signers {
      userEmail
      signature
    }
  }
}
    `;
export type SignSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.SignSubmittedDataRoomRequestMutation, Types.SignSubmittedDataRoomRequestMutationVariables>;

/**
 * __useSignSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useSignSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signSubmittedDataRoomRequestMutation, { data, loading, error }] = useSignSubmittedDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignSubmittedDataRoomRequestMutation, Types.SignSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignSubmittedDataRoomRequestMutation, Types.SignSubmittedDataRoomRequestMutationVariables>(SignSubmittedDataRoomRequestDocument, options);
      }
export type SignSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useSignSubmittedDataRoomRequestMutation>;
export type SignSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.SignSubmittedDataRoomRequestMutation>;
export type SignSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.SignSubmittedDataRoomRequestMutation, Types.SignSubmittedDataRoomRequestMutationVariables>;
export const CreateSubmittedDataRoomRequestDocument = gql`
    mutation CreateSubmittedDataRoomRequest($input: SubmitDataRoomRequestInput!) {
  dataRoomRequest {
    submitForApproval(input: $input) {
      id
      signers {
        nodes {
          id
          signature
          userEmail
          user {
            id
            email
          }
        }
      }
      publishedDataRoom {
        id
      }
    }
  }
}
    `;
export type CreateSubmittedDataRoomRequestMutationFn = Apollo.MutationFunction<Types.CreateSubmittedDataRoomRequestMutation, Types.CreateSubmittedDataRoomRequestMutationVariables>;

/**
 * __useCreateSubmittedDataRoomRequestMutation__
 *
 * To run a mutation, you first call `useCreateSubmittedDataRoomRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmittedDataRoomRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmittedDataRoomRequestMutation, { data, loading, error }] = useCreateSubmittedDataRoomRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubmittedDataRoomRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSubmittedDataRoomRequestMutation, Types.CreateSubmittedDataRoomRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSubmittedDataRoomRequestMutation, Types.CreateSubmittedDataRoomRequestMutationVariables>(CreateSubmittedDataRoomRequestDocument, options);
      }
export type CreateSubmittedDataRoomRequestMutationHookResult = ReturnType<typeof useCreateSubmittedDataRoomRequestMutation>;
export type CreateSubmittedDataRoomRequestMutationResult = Apollo.MutationResult<Types.CreateSubmittedDataRoomRequestMutation>;
export type CreateSubmittedDataRoomRequestMutationOptions = Apollo.BaseMutationOptions<Types.CreateSubmittedDataRoomRequestMutation, Types.CreateSubmittedDataRoomRequestMutationVariables>;
export const DraftComputeNodeTypenameDocument = gql`
    query DraftComputeNodeTypename($computeNodeId: String!) {
  draftNode(id: $computeNodeId) {
    id
    name
  }
}
    `;

/**
 * __useDraftComputeNodeTypenameQuery__
 *
 * To run a query within a React component, call `useDraftComputeNodeTypenameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftComputeNodeTypenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftComputeNodeTypenameQuery({
 *   variables: {
 *      computeNodeId: // value for 'computeNodeId'
 *   },
 * });
 */
export function useDraftComputeNodeTypenameQuery(baseOptions: Apollo.QueryHookOptions<Types.DraftComputeNodeTypenameQuery, Types.DraftComputeNodeTypenameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftComputeNodeTypenameQuery, Types.DraftComputeNodeTypenameQueryVariables>(DraftComputeNodeTypenameDocument, options);
      }
export function useDraftComputeNodeTypenameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftComputeNodeTypenameQuery, Types.DraftComputeNodeTypenameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftComputeNodeTypenameQuery, Types.DraftComputeNodeTypenameQueryVariables>(DraftComputeNodeTypenameDocument, options);
        }
export type DraftComputeNodeTypenameQueryHookResult = ReturnType<typeof useDraftComputeNodeTypenameQuery>;
export type DraftComputeNodeTypenameLazyQueryHookResult = ReturnType<typeof useDraftComputeNodeTypenameLazyQuery>;
export type DraftComputeNodeTypenameQueryResult = Apollo.QueryResult<Types.DraftComputeNodeTypenameQuery, Types.DraftComputeNodeTypenameQueryVariables>;
export const DataRoomRequestComputeNodeTypenameDocument = gql`
    query DataRoomRequestComputeNodeTypename($requestId: String!) {
  dataRoomRequest(id: $requestId) {
    id
    draftNode {
      id
    }
  }
}
    `;

/**
 * __useDataRoomRequestComputeNodeTypenameQuery__
 *
 * To run a query within a React component, call `useDataRoomRequestComputeNodeTypenameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRoomRequestComputeNodeTypenameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRoomRequestComputeNodeTypenameQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDataRoomRequestComputeNodeTypenameQuery(baseOptions: Apollo.QueryHookOptions<Types.DataRoomRequestComputeNodeTypenameQuery, Types.DataRoomRequestComputeNodeTypenameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataRoomRequestComputeNodeTypenameQuery, Types.DataRoomRequestComputeNodeTypenameQueryVariables>(DataRoomRequestComputeNodeTypenameDocument, options);
      }
export function useDataRoomRequestComputeNodeTypenameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataRoomRequestComputeNodeTypenameQuery, Types.DataRoomRequestComputeNodeTypenameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataRoomRequestComputeNodeTypenameQuery, Types.DataRoomRequestComputeNodeTypenameQueryVariables>(DataRoomRequestComputeNodeTypenameDocument, options);
        }
export type DataRoomRequestComputeNodeTypenameQueryHookResult = ReturnType<typeof useDataRoomRequestComputeNodeTypenameQuery>;
export type DataRoomRequestComputeNodeTypenameLazyQueryHookResult = ReturnType<typeof useDataRoomRequestComputeNodeTypenameLazyQuery>;
export type DataRoomRequestComputeNodeTypenameQueryResult = Apollo.QueryResult<Types.DataRoomRequestComputeNodeTypenameQuery, Types.DataRoomRequestComputeNodeTypenameQueryVariables>;
export const DataRoomRequestTempApproversDocument = gql`
    query DataRoomRequestTempApprovers($id: String!) {
  dataRoomRequest(id: $id) {
    id
    tempApprovers @client
  }
}
    `;

/**
 * __useDataRoomRequestTempApproversQuery__
 *
 * To run a query within a React component, call `useDataRoomRequestTempApproversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRoomRequestTempApproversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRoomRequestTempApproversQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataRoomRequestTempApproversQuery(baseOptions: Apollo.QueryHookOptions<Types.DataRoomRequestTempApproversQuery, Types.DataRoomRequestTempApproversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataRoomRequestTempApproversQuery, Types.DataRoomRequestTempApproversQueryVariables>(DataRoomRequestTempApproversDocument, options);
      }
export function useDataRoomRequestTempApproversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataRoomRequestTempApproversQuery, Types.DataRoomRequestTempApproversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataRoomRequestTempApproversQuery, Types.DataRoomRequestTempApproversQueryVariables>(DataRoomRequestTempApproversDocument, options);
        }
export type DataRoomRequestTempApproversQueryHookResult = ReturnType<typeof useDataRoomRequestTempApproversQuery>;
export type DataRoomRequestTempApproversLazyQueryHookResult = ReturnType<typeof useDataRoomRequestTempApproversLazyQuery>;
export type DataRoomRequestTempApproversQueryResult = Apollo.QueryResult<Types.DataRoomRequestTempApproversQuery, Types.DataRoomRequestTempApproversQueryVariables>;
export const CompletePublishedDataRoomDocument = gql`
    query CompletePublishedDataRoom($id: String!) {
  publishedDataRoom(id: $id) {
    id
    name: title
    description @client
    owner {
      __typename
      email
    }
    driverAttestationHash
    enableInteractivity @client
    enableDevelopment @client
    isStopped
    deactivatedAt
    isOwner @client
    publishedNodes @client {
      __typename
      id
      dataRoomId
      name
      dcrHash
      driverAttestationHash
      commitId
      permissions {
        ... on PublishedAnalystPermission {
          participant {
            userEmail
          }
        }
        ... on PublishedDataOwnerPermission {
          participant {
            userEmail
          }
        }
      }
      ... on PublishedScriptingNode {
        computationType
        output
        scriptingLanguage
        scripts {
          name
          content
          isMainScript
        }
        dependencies {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedSqlNode {
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        dependencies {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
        statement
      }
      ... on PublishedSqliteNode {
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        dependencies {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
        statement
      }
      ... on PublishedMatchNode {
        computationType
        dependencies {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
        config
      }
      ... on PublishedSyntheticNode {
        computationType
        accuracy
        includeReportWithStats
        columns {
          name
          shouldMaskColumn
          index
          maskType
          dataType
        }
        dependency {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedPostNode {
        computationType
        dependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedPreviewNode {
        computationType
        usedQuotaBytes
        remainingQuotaBytes
        totalQuotaBytes
        dependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedS3SinkNode {
        endpoint
        computationType
        region
        credentialsDependency {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
        uploadDependency {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedPreviewNode {
        computationType
        usedQuotaBytes
        remainingQuotaBytes
        totalQuotaBytes
        dependency {
          __typename
          id
          name
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedRawLeafNode {
        isRequired
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
        testDataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
      ... on PublishedTableLeafNode {
        isRequired
        allowEmpty
        minRows
        maxRows
        uniqueColumnIds
        columns {
          name
          dataType
          isNullable
          formatType
          hashWith
        }
        dataset {
          datasetHash
          leafId
          timestamp
          user
        }
        testDataset {
          datasetHash
          leafId
          timestamp
          user
        }
      }
    }
    participants @client {
      userEmail
      permissions {
        ... on PublishedAnalystPermission {
          node {
            __typename
            id
            dcrHash
            driverAttestationHash
            commitId
          }
        }
        ... on PublishedDataOwnerPermission {
          node {
            __typename
            id
            dcrHash
            driverAttestationHash
            commitId
          }
        }
      }
    }
    source @client
    enableAutomergeFeature @client
    enableServersideWasmValidation @client
    enableTestDatasets @client
    enableSqliteWorker @client
    enablePostWorker @client
    enableAirlock @client
  }
}
    `;

/**
 * __useCompletePublishedDataRoomQuery__
 *
 * To run a query within a React component, call `useCompletePublishedDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletePublishedDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletePublishedDataRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletePublishedDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.CompletePublishedDataRoomQuery, Types.CompletePublishedDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompletePublishedDataRoomQuery, Types.CompletePublishedDataRoomQueryVariables>(CompletePublishedDataRoomDocument, options);
      }
export function useCompletePublishedDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompletePublishedDataRoomQuery, Types.CompletePublishedDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompletePublishedDataRoomQuery, Types.CompletePublishedDataRoomQueryVariables>(CompletePublishedDataRoomDocument, options);
        }
export type CompletePublishedDataRoomQueryHookResult = ReturnType<typeof useCompletePublishedDataRoomQuery>;
export type CompletePublishedDataRoomLazyQueryHookResult = ReturnType<typeof useCompletePublishedDataRoomLazyQuery>;
export type CompletePublishedDataRoomQueryResult = Apollo.QueryResult<Types.CompletePublishedDataRoomQuery, Types.CompletePublishedDataRoomQueryVariables>;
export const PublishedDataRoomEnclaveConnectionParametersDocument = gql`
    query PublishedDataRoomEnclaveConnectionParameters($id: String!) {
  publishedDataRoom(id: $id) {
    driverAttestationHash
  }
}
    `;

/**
 * __usePublishedDataRoomEnclaveConnectionParametersQuery__
 *
 * To run a query within a React component, call `usePublishedDataRoomEnclaveConnectionParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedDataRoomEnclaveConnectionParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedDataRoomEnclaveConnectionParametersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishedDataRoomEnclaveConnectionParametersQuery(baseOptions: Apollo.QueryHookOptions<Types.PublishedDataRoomEnclaveConnectionParametersQuery, Types.PublishedDataRoomEnclaveConnectionParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublishedDataRoomEnclaveConnectionParametersQuery, Types.PublishedDataRoomEnclaveConnectionParametersQueryVariables>(PublishedDataRoomEnclaveConnectionParametersDocument, options);
      }
export function usePublishedDataRoomEnclaveConnectionParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublishedDataRoomEnclaveConnectionParametersQuery, Types.PublishedDataRoomEnclaveConnectionParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublishedDataRoomEnclaveConnectionParametersQuery, Types.PublishedDataRoomEnclaveConnectionParametersQueryVariables>(PublishedDataRoomEnclaveConnectionParametersDocument, options);
        }
export type PublishedDataRoomEnclaveConnectionParametersQueryHookResult = ReturnType<typeof usePublishedDataRoomEnclaveConnectionParametersQuery>;
export type PublishedDataRoomEnclaveConnectionParametersLazyQueryHookResult = ReturnType<typeof usePublishedDataRoomEnclaveConnectionParametersLazyQuery>;
export type PublishedDataRoomEnclaveConnectionParametersQueryResult = Apollo.QueryResult<Types.PublishedDataRoomEnclaveConnectionParametersQuery, Types.PublishedDataRoomEnclaveConnectionParametersQueryVariables>;
export const CompleteSubmittedDataRoomRequestDocument = gql`
    query CompleteSubmittedDataRoomRequest($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    node @client {
      __typename
      id
      dataRoomId
      name
      dcrHash
      driverAttestationHash
      commitId
      permissions {
        ... on PublishedAnalystPermission {
          participant {
            userEmail
          }
        }
        ... on PublishedDataOwnerPermission {
          participant {
            userEmail
          }
        }
      }
      ... on PublishedScriptingNode {
        computationType
        output
        scriptingLanguage
        scripts {
          name
          content
          isMainScript
        }
        dependencies {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedSqlNode {
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        dependencies {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
        statement
      }
      ... on PublishedSqliteNode {
        computationType
        privacyFilter {
          minimumRows
          isEnabled
        }
        dependencies {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
        statement
      }
      ... on PublishedMatchNode {
        computationType
        dependencies {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
        config
      }
      ... on PublishedSyntheticNode {
        computationType
        accuracy
        includeReportWithStats
        columns {
          name
          shouldMaskColumn
          index
          maskType
          dataType
        }
        dependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedPostNode {
        computationType
        dependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedPreviewNode {
        computationType
        dependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedS3SinkNode {
        endpoint
        computationType
        region
        credentialsDependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
        uploadDependency {
          __typename
          id
          dcrHash
          driverAttestationHash
          commitId
        }
      }
      ... on PublishedRawLeafNode {
        isRequired
      }
      ... on PublishedTableLeafNode {
        isRequired
        columns {
          name
          dataType
          isNullable
          formatType
          hashWith
        }
      }
    }
  }
}
    `;

/**
 * __useCompleteSubmittedDataRoomRequestQuery__
 *
 * To run a query within a React component, call `useCompleteSubmittedDataRoomRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteSubmittedDataRoomRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteSubmittedDataRoomRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteSubmittedDataRoomRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.CompleteSubmittedDataRoomRequestQuery, Types.CompleteSubmittedDataRoomRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompleteSubmittedDataRoomRequestQuery, Types.CompleteSubmittedDataRoomRequestQueryVariables>(CompleteSubmittedDataRoomRequestDocument, options);
      }
export function useCompleteSubmittedDataRoomRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompleteSubmittedDataRoomRequestQuery, Types.CompleteSubmittedDataRoomRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompleteSubmittedDataRoomRequestQuery, Types.CompleteSubmittedDataRoomRequestQueryVariables>(CompleteSubmittedDataRoomRequestDocument, options);
        }
export type CompleteSubmittedDataRoomRequestQueryHookResult = ReturnType<typeof useCompleteSubmittedDataRoomRequestQuery>;
export type CompleteSubmittedDataRoomRequestLazyQueryHookResult = ReturnType<typeof useCompleteSubmittedDataRoomRequestLazyQuery>;
export type CompleteSubmittedDataRoomRequestQueryResult = Apollo.QueryResult<Types.CompleteSubmittedDataRoomRequestQuery, Types.CompleteSubmittedDataRoomRequestQueryVariables>;
export const EnclaveConnectionParametersForSubmittedDataRoomRequestDocument = gql`
    query EnclaveConnectionParametersForSubmittedDataRoomRequest($id: String!) {
  submittedDataRoomRequest(id: $id) {
    id
    publishedDataRoom {
      id
      driverAttestationHash
    }
  }
}
    `;

/**
 * __useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery__
 *
 * To run a query within a React component, call `useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQuery, Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQuery, Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQueryVariables>(EnclaveConnectionParametersForSubmittedDataRoomRequestDocument, options);
      }
export function useEnclaveConnectionParametersForSubmittedDataRoomRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQuery, Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQuery, Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQueryVariables>(EnclaveConnectionParametersForSubmittedDataRoomRequestDocument, options);
        }
export type EnclaveConnectionParametersForSubmittedDataRoomRequestQueryHookResult = ReturnType<typeof useEnclaveConnectionParametersForSubmittedDataRoomRequestQuery>;
export type EnclaveConnectionParametersForSubmittedDataRoomRequestLazyQueryHookResult = ReturnType<typeof useEnclaveConnectionParametersForSubmittedDataRoomRequestLazyQuery>;
export type EnclaveConnectionParametersForSubmittedDataRoomRequestQueryResult = Apollo.QueryResult<Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQuery, Types.EnclaveConnectionParametersForSubmittedDataRoomRequestQueryVariables>;
export const MediaDataRoomDocument = gql`
    query MediaDataRoom($input: MediaRetrieveDataRoomInput!) {
  mediaRetrieveDataRoom(input: $input) @client {
    highLevel
  }
}
    `;

/**
 * __useMediaDataRoomQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaDataRoomQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomQuery, Types.MediaDataRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomQuery, Types.MediaDataRoomQueryVariables>(MediaDataRoomDocument, options);
      }
export function useMediaDataRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomQuery, Types.MediaDataRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomQuery, Types.MediaDataRoomQueryVariables>(MediaDataRoomDocument, options);
        }
export type MediaDataRoomQueryHookResult = ReturnType<typeof useMediaDataRoomQuery>;
export type MediaDataRoomLazyQueryHookResult = ReturnType<typeof useMediaDataRoomLazyQuery>;
export type MediaDataRoomQueryResult = Apollo.QueryResult<Types.MediaDataRoomQuery, Types.MediaDataRoomQueryVariables>;
export const MediaDataRoomOrganizationsDocument = gql`
    query MediaDataRoomOrganizations($advertiserEmail: String!, $publisherEmail: String!) {
  advertiserOrganization: userByEmail(email: $advertiserEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
  publisherOrganization: userByEmail(email: $publisherEmail) {
    id
    organizationLogo
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useMediaDataRoomOrganizationsQuery__
 *
 * To run a query within a React component, call `useMediaDataRoomOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaDataRoomOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaDataRoomOrganizationsQuery({
 *   variables: {
 *      advertiserEmail: // value for 'advertiserEmail'
 *      publisherEmail: // value for 'publisherEmail'
 *   },
 * });
 */
export function useMediaDataRoomOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<Types.MediaDataRoomOrganizationsQuery, Types.MediaDataRoomOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MediaDataRoomOrganizationsQuery, Types.MediaDataRoomOrganizationsQueryVariables>(MediaDataRoomOrganizationsDocument, options);
      }
export function useMediaDataRoomOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaDataRoomOrganizationsQuery, Types.MediaDataRoomOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MediaDataRoomOrganizationsQuery, Types.MediaDataRoomOrganizationsQueryVariables>(MediaDataRoomOrganizationsDocument, options);
        }
export type MediaDataRoomOrganizationsQueryHookResult = ReturnType<typeof useMediaDataRoomOrganizationsQuery>;
export type MediaDataRoomOrganizationsLazyQueryHookResult = ReturnType<typeof useMediaDataRoomOrganizationsLazyQuery>;
export type MediaDataRoomOrganizationsQueryResult = Apollo.QueryResult<Types.MediaDataRoomOrganizationsQuery, Types.MediaDataRoomOrganizationsQueryVariables>;
export const SetUserHasLoggedInBeforeDocument = gql`
    mutation SetUserHasLoggedInBefore($input: SetUserLoggedInBeforeInput!) {
  user {
    setLoggedInBefore(input: $input) {
      record {
        id
        hasLoggedInBefore
      }
    }
  }
}
    `;
export type SetUserHasLoggedInBeforeMutationFn = Apollo.MutationFunction<Types.SetUserHasLoggedInBeforeMutation, Types.SetUserHasLoggedInBeforeMutationVariables>;

/**
 * __useSetUserHasLoggedInBeforeMutation__
 *
 * To run a mutation, you first call `useSetUserHasLoggedInBeforeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserHasLoggedInBeforeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserHasLoggedInBeforeMutation, { data, loading, error }] = useSetUserHasLoggedInBeforeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserHasLoggedInBeforeMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetUserHasLoggedInBeforeMutation, Types.SetUserHasLoggedInBeforeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetUserHasLoggedInBeforeMutation, Types.SetUserHasLoggedInBeforeMutationVariables>(SetUserHasLoggedInBeforeDocument, options);
      }
export type SetUserHasLoggedInBeforeMutationHookResult = ReturnType<typeof useSetUserHasLoggedInBeforeMutation>;
export type SetUserHasLoggedInBeforeMutationResult = Apollo.MutationResult<Types.SetUserHasLoggedInBeforeMutation>;
export type SetUserHasLoggedInBeforeMutationOptions = Apollo.BaseMutationOptions<Types.SetUserHasLoggedInBeforeMutation, Types.SetUserHasLoggedInBeforeMutationVariables>;
export const UserHasLoggedInBeforeDocument = gql`
    query UserHasLoggedInBefore($email: String!) {
  userByEmail(email: $email) {
    id
    email
    hasLoggedInBefore
  }
}
    `;

/**
 * __useUserHasLoggedInBeforeQuery__
 *
 * To run a query within a React component, call `useUserHasLoggedInBeforeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasLoggedInBeforeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasLoggedInBeforeQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserHasLoggedInBeforeQuery(baseOptions: Apollo.QueryHookOptions<Types.UserHasLoggedInBeforeQuery, Types.UserHasLoggedInBeforeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserHasLoggedInBeforeQuery, Types.UserHasLoggedInBeforeQueryVariables>(UserHasLoggedInBeforeDocument, options);
      }
export function useUserHasLoggedInBeforeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserHasLoggedInBeforeQuery, Types.UserHasLoggedInBeforeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserHasLoggedInBeforeQuery, Types.UserHasLoggedInBeforeQueryVariables>(UserHasLoggedInBeforeDocument, options);
        }
export type UserHasLoggedInBeforeQueryHookResult = ReturnType<typeof useUserHasLoggedInBeforeQuery>;
export type UserHasLoggedInBeforeLazyQueryHookResult = ReturnType<typeof useUserHasLoggedInBeforeLazyQuery>;
export type UserHasLoggedInBeforeQueryResult = Apollo.QueryResult<Types.UserHasLoggedInBeforeQuery, Types.UserHasLoggedInBeforeQueryVariables>;