import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import {
  faSignOut,
  faUserCog,
  faUserSlash,
} from "@fortawesome/pro-light-svg-icons";
import { Avatar, Popover, Typography } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DeleteAccountDialog } from "components";
import { SidebarMenuItem } from "features/sidebar";
import { useAccountDelete, usePopupState, useSignOut } from "hooks";
import { bindHover, bindPopover } from "hooks/usePopupState/usePopupState";

const UserMenuSidebarItem: React.FC = () => {
  const {
    user: { email: currentUserEmail = "", name: currentUserName = "" } = {},
  } = useAuth0();
  const popupState = usePopupState({
    popupId: "participants",
    variant: "popover",
  });
  // Delete account
  const [
    isDeleteAccountDialogOpen,
    { setFalse: closeDeleteAccountDialog, setTrue: openDeleteAccountDialog },
  ] = useBoolean(false);
  const [deleteAccount, isDeleteAccountLoading] = useAccountDelete(
    closeDeleteAccountDialog
  );
  // Sign out
  const signOut = useSignOut();

  return (
    <>
      <Avatar
        {...bindHover(popupState)}
        alt={currentUserName}
        data-testid={testIds.sidebar.sidebarMenuItems.userAvatar}
        sx={{ bgcolor: "#289090" }}
      >
        {currentUserName.substr(0, 2).toUpperCase()}
      </Avatar>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
        marginThreshold={8}
        slotProps={{
          paper: {
            sx: { minWidth: "250px", padding: 1 },
          },
        }}
        transformOrigin={{
          horizontal: -40,
          vertical: 0,
        }}
      >
        <Typography sx={{ marginBottom: 0.5 }} variant="body2">
          {currentUserEmail}
        </Typography>
        <SidebarMenuItem
          icon={faUserCog}
          iconSize="lg"
          label="API tokens"
          orientation="horizontal"
          to="/tokens"
        />
        <SidebarMenuItem
          icon={faUserSlash}
          iconSize="lg"
          label="Delete account"
          onClick={openDeleteAccountDialog}
          orientation="horizontal"
          sx={{ "& .MuiListItemButton-root": { color: "red" } }}
        />
        <SidebarMenuItem
          data-testid={testIds.sidebar.sidebarMenuItems.userSignOut}
          icon={faSignOut}
          iconSize="lg"
          label="Sign out"
          onClick={signOut}
          orientation="horizontal"
        />
      </Popover>
      <DeleteAccountDialog
        loading={isDeleteAccountLoading}
        onCancel={closeDeleteAccountDialog}
        onConfirm={deleteAccount}
        open={isDeleteAccountDialogOpen}
      />
    </>
  );
};

UserMenuSidebarItem.displayName = "UserMenuSidebarItem";

export default memo(UserMenuSidebarItem);
