import { useApolloClient } from "@apollo/client";
import { useMount } from "ahooks";
import { useCallback } from "react";
import { useDataRoom } from "contexts";
import { useDataRoomTestingNavigationState } from "hooks";
import {
  PublishedDataRoomTestingFragmentFragment,
  usePublishedDataRoomTestingQuery,
} from "hooks/__generated-new";

type PublishedDataRoomTestModeHookResult = [boolean, () => void];

const usePublishedDataRoomTestMode =
  (): PublishedDataRoomTestModeHookResult => {
    const { dataRoomId, allowTestMode, isPublished } = useDataRoom();
    const { testing: testingFromParams, setTesting: setTestingParam } =
      useDataRoomTestingNavigationState();
    const { cache } = useApolloClient();
    const skip = !allowTestMode || !isPublished;
    const { data } = usePublishedDataRoomTestingQuery({
      skip,
      variables: { id: dataRoomId },
    });
    const testing = !!data?.publishedDataRoom?.testing;
    const setTesting = useCallback(
      (testing: boolean) => {
        cache.writeFragment({
          data: {
            id: dataRoomId,
            testing,
          },
          fragment: PublishedDataRoomTestingFragmentFragment,
          id: cache.identify({
            __typename: "PublishedDataRoom",
            id: dataRoomId,
          }),
        });
        setTestingParam(testing);
      },
      [dataRoomId, cache, setTestingParam]
    );
    const toggle = useCallback(() => {
      if (skip) {
        return;
      }
      setTesting(!testing);
    }, [setTesting, testing, skip]);
    useMount(() => {
      setTesting(testingFromParams);
    });
    return [testing, toggle];
  };

export default usePublishedDataRoomTestMode;
