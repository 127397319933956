import {
  faChevronDown,
  faChevronRight,
  faClone,
} from "@fortawesome/pro-light-svg-icons";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DetailsGridLabel, DetailsGridValue } from "components";
import { formatSize } from "features/datasets/utils";
import { useCopyToClipboard } from "hooks";
import {
  PYTHON_INPUT_FILE_PREFIX,
  R_INPUT_FILE_PREFIX,
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
  ScriptingNodeInputTypeNames,
} from "models";
import {
  type DraftScript,
  type PublishedScript,
  ScriptingLanguage,
} from "types/__generated-new";
import useScriptingComputeNode from "../../useScriptingComputeNode";
import useScriptingNodeInputs from "../../useScriptingNodeInputs/useScriptingNodeInputs";
import FileExplorerItem from "../FileExplorerItem/FileExplorerItem";

const FileExplorerTreeView = styled(SimpleTreeView)({
  "& .Mui-disabled": {
    "& .MuiTreeItem-iconContainer": {
      width: "0 !important",
    },
    cursor: "default",
    opacity: "1 !important",
  },
});

const tabularInputPath = (input: string) => {
  // read_tabular_data() function does not take the dataset.csv
  return input?.substring(input?.lastIndexOf("/dataset.csv"), -1);
};

const pythonNodeInputCopyContent = new Map<
  ScriptingInputNodeSnapshot["typename"],
  (input: string) => string
>([
  [
    ScriptingNodeInputTypeNames.DraftSyntheticNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftTableLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqlNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqliteNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftMatchNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPostNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScriptingNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScript,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftRawLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPreviewNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSyntheticNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedTableLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqlNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqliteNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedMatchNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPostNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPreviewNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScriptingNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScript,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedRawLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
]);

const RNodeInputCopyContent = new Map<
  ScriptingInputNodeSnapshot["typename"],
  (input: string) => string
>([
  [
    ScriptingNodeInputTypeNames.DraftSyntheticNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftTableLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqlNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqliteNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftMatchNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPostNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPreviewNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScriptingNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScript,
    (input) => `${R_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftRawLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSyntheticNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedTableLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqlNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqliteNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedMatchNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPostNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScriptingNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScript,
    (input) => `${R_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedRawLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPreviewNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
]);

const getInputCopyContent = (
  scriptingLanguage: ScriptingLanguage,
  inputTypename: ScriptingInputNodeSnapshot["typename"],
  inputPath: string
) => {
  if (scriptingLanguage === ScriptingLanguage.R) {
    return RNodeInputCopyContent.get(inputTypename)!(inputPath);
  }
  if (scriptingLanguage === ScriptingLanguage.Python) {
    return pythonNodeInputCopyContent.get(inputTypename)!(inputPath);
  }
};

interface ScriptingComputeNodeFileExplorerProps {
  computeNodeId: string;
  readOnly?: boolean;
  fullHeight?: boolean;
}

const ScriptingComputeNodeFileExplorer: React.FC<
  ScriptingComputeNodeFileExplorerProps
> = ({ computeNodeId, readOnly, fullHeight = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  // TODO: move this state with button and dialog into separate component, something like PreviewNodeTreeItem
  const [selectedPreviewNode, setSelectedPreviewNode] =
    useState<ScriptingInputNodeSnapshot | null>(null);
  const { scripts, scriptingLanguage, dependencies } =
    useScriptingComputeNode(computeNodeId);
  // TreeView state
  const [expanded, setExpanded] = useState<string[]>([
    "input",
    "output",
    "__tmp__",
    "__script-tmp__",
    "code",
    "script-output",
  ]);
  const handleToggle = (
    event: React.SyntheticEvent,
    nodeIds: string[] | string | null
  ) => {
    setExpanded(nodeIds as string[]);
  };
  // Dependencies
  const scriptingNodeDependenciesIds = dependencies?.map((node) => node.id);
  const scriptingNodeAvailableInputs = useScriptingNodeInputs(computeNodeId);
  const scriptingNodeInputs = scriptingNodeAvailableInputs.filter(
    ({ computeNodeId }) => scriptingNodeDependenciesIds?.includes(computeNodeId)
  );
  // Static Scripts
  const staticScripts =
    scripts?.filter(
      ({ isMainScript }: DraftScript | PublishedScript) => !isMainScript
    ) || [];
  const staticScriptsInputs: ScriptingInputNodeSnapshot[] = staticScripts.map(
    (script) =>
      ({
        computeNodeId: script.name as string,
        id: script.name as string,
        label: `${script.name}, FILE`,
        name: script.name as string,
        path: `${script.name}`,
        typename: script.__typename,
      }) as ScriptingInputNodeSnapshot
  );
  useEffect(() => {
    setExpanded((expanded) =>
      scriptingNodeInputs.reduce(
        (expandedItemsArray, input, index) => {
          return [
            ...expandedItemsArray,
            `${index}-input-${input.computeNodeId}`,
            `${index}-input-code-${input.computeNodeId}`,
            `${index}-input-${input.computeNodeId}-content`,
          ];
        },
        [...expanded]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptingNodeInputs.length]);
  return (
    <Box
      sx={{
        background: "#f7f7f7",
        flex: 1,
        height: "100%",
        maxHeight: readOnly ? "fit-content" : fullHeight ? "100%" : "450px",
        overflow: "scroll",
        padding: "10px",
        width: "100%",
      }}
    >
      <Typography variant="subtitle2">File browser</Typography>
      {/* This list should look like a TreeView, but with no ability to toggle and select on inner levels. 
      Hence, the easiest way to achieve that is to disable them and customize the styling to have it looking like expanded ones */}
      <FileExplorerTreeView
        expandedItems={expanded}
        onExpandedItemsChange={handleToggle}
        slots={{
          collapseIcon: () => <FontAwesomeIcon icon={faChevronDown} />,
          expandIcon: () => <FontAwesomeIcon icon={faChevronRight} />,
        }}
      >
        <TreeItem
          itemId={"input"}
          label={<Typography variant="subtitle2">input</Typography>}
        >
          {scriptingNodeInputs.map((input, index) =>
            input.typename === ScriptingNodeInputTypeNames.DraftRawLeafNode ||
            input.typename ===
              ScriptingNodeInputTypeNames.PublishedRawLeafNode ? (
              <FileExplorerItem
                copyValue={getInputCopyContent(
                  scriptingLanguage!,
                  input.typename,
                  input?.path
                )}
                disabled={true}
                input={input}
                itemId={`${index}-input-${input.computeNodeId}`}
                key={index}
                slots={{
                  collapseIcon: () => <></>,
                  expandIcon: () => <></>,
                }}
              />
            ) : (
              <TreeItem
                disabled={true}
                itemId={`${index}-input-${input.computeNodeId}`}
                key={`${index}-input-${input.computeNodeId}`}
                label={
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      gap: "0.25rem",
                      lineHeight: "1.5rem",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                      variant="body2"
                    >
                      {input?.name || ""}
                    </Typography>
                    <Typography
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        borderRadius: "3px",
                        color: "black",
                        lineHeight: "0.75rem",
                        padding: "0.125rem",
                      }}
                      variant="caption"
                    >
                      {scriptingNodeGroupLabelsMap[
                        input?.typename
                      ]?.toUpperCase()}
                    </Typography>
                  </Box>
                }
                slots={{
                  collapseIcon: () => <></>,
                  expandIcon: () => <></>,
                }}
              >
                {input?.typename === "PublishedPreviewNode" && (
                  <span>
                    Remaining quota:{" "}
                    {formatSize(input?.remainingQuotaBytes ?? 0)}{" "}
                    <Button
                      color="inherit"
                      onClick={() => setSelectedPreviewNode(input)}
                      sx={{
                        display: "inline-block",
                        minWidth: "auto",
                        padding: "0",
                      }}
                    >
                      Details
                    </Button>
                  </span>
                )}
                <TreeItem
                  disabled={true}
                  itemId={`${index}-input-${input.computeNodeId}-content`}
                  label={
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {input.typename ===
                        ScriptingNodeInputTypeNames.DraftScriptingNode ||
                      input.typename ===
                        ScriptingNodeInputTypeNames.PublishedScriptingNode ? (
                        <Typography
                          sx={{ fontStyle: "italic" }}
                          variant="body2"
                        >
                          Script output
                        </Typography>
                      ) : (
                        <Typography variant="body2">dataset.csv</Typography>
                      )}
                      <Tooltip title="Click to copy snippet for importing this file">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(
                              // @ts-ignore
                              getInputCopyContent(
                                scriptingLanguage!,
                                input.typename,
                                input.path
                              )
                            );
                            enqueueSnackbar(
                              `'${input.path}' importing snippet copied to clipboard`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon={faClone} />
                        </div>
                      </Tooltip>
                    </div>
                  }
                  slots={{
                    collapseIcon: () => <></>,
                    expandIcon: () => <></>,
                  }}
                />
              </TreeItem>
            )
          )}
          {staticScriptsInputs.length > 0 && (
            <TreeItem
              disabled={true}
              itemId={"code"}
              label={<Typography variant="subtitle2">code</Typography>}
              slots={{
                collapseIcon: () => <></>,
                expandIcon: () => <></>,
              }}
            >
              {staticScriptsInputs.map((input, index) => (
                <FileExplorerItem
                  copyValue={getInputCopyContent(
                    scriptingLanguage!,
                    input.typename,
                    input?.path
                  )}
                  input={input}
                  itemId={`${index}-input-code-${input.computeNodeId}`}
                  key={index}
                />
              ))}
            </TreeItem>
          )}
        </TreeItem>
        <TreeItem
          itemId={"output"}
          label={<Typography variant="subtitle2">output</Typography>}
        >
          <TreeItem
            disabled={true}
            itemId="script-output"
            label={
              <Typography variant="body2">
                <i>Save output files into this directory</i>
              </Typography>
            }
          />
        </TreeItem>
        <TreeItem
          itemId={"__tmp__"}
          label={<Typography variant="subtitle2">tmp</Typography>}
        >
          <TreeItem
            disabled={true}
            itemId="__script-tmp__"
            label={
              <Typography variant="body2">
                <i>Save temporary files into this directory</i>
              </Typography>
            }
          />
        </TreeItem>
      </FileExplorerTreeView>
      <Dialog
        onClose={() => setSelectedPreviewNode(null)}
        open={selectedPreviewNode !== null}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{selectedPreviewNode?.name}</span>
          <IconButton
            color="inherit"
            onClick={() => setSelectedPreviewNode(null)}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container={true}
            item={true}
            sx={{ paddingTop: "0 !important" }}
            xs={6}
          >
            <DetailsGridLabel label="Total quota" />
            <DetailsGridValue
              value={formatSize(selectedPreviewNode?.totalQuotaBytes ?? 0)}
            />
          </Grid>
          <Grid
            container={true}
            item={true}
            sx={{ paddingTop: "0 !important" }}
            xs={6}
          >
            <DetailsGridLabel label="Used quota" />
            <DetailsGridValue
              value={formatSize(selectedPreviewNode?.usedQuotaBytes ?? 0)}
            />
          </Grid>
          <Grid
            container={true}
            item={true}
            sx={{ paddingTop: "0 !important" }}
            xs={6}
          >
            <DetailsGridLabel label="Remaining quota" />
            <DetailsGridValue
              value={formatSize(selectedPreviewNode?.remainingQuotaBytes ?? 0)}
            />
          </Grid>
          <Alert severity="info">
            If you need more quota, please create a request for a new Airlock
            computation.
          </Alert>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ScriptingComputeNodeFileExplorer;
