import {
  Box,
  Button,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { SelectableControlLabel } from "components";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import {
  EXTERNAL_DATA_IMPORT_STEPS,
  useImportExternalDataDialog,
} from "features/datasets/components/ImportExternalData";
import { useDocsLink } from "hooks";
import { DataSourceType } from "types/__generated-new";

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

const WITH_AZURE = true;

type ExternalDataSourceSelectorProps = {
  onClose: () => void;
};

const ExternalDataSourceSelector: React.FC<ExternalDataSourceSelectorProps> = ({
  onClose,
}) => {
  const { selectedDataSource, setSelectedDataSource, setImportStep } =
    useImportExternalDataDialog();

  const docsBaseLink = useDocsLink();

  const handleDataSourceChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDataSource(target.value as DataSourceType);
    },
    [setSelectedDataSource]
  );

  const isDataSourceFormStepDisabled = false;

  const handleNextStepClick = useCallback(() => {
    // Remove when other data source type are implemented
    if (isDataSourceFormStepDisabled) {
      return;
    }
    setImportStep(EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM);
  }, [isDataSourceFormStepDisabled, setImportStep]);

  return (
    <>
      <Grid container={true} item={true} spacing={3} xs={12}>
        <Grid item={true} xs={5}>
          <FormControl fullWidth={true} size="small">
            <RadioGroup
              onChange={handleDataSourceChange}
              sx={{
                "& .Mui-checked": { color: "#289090 !important" },
                gap: "0.5rem",
                marginBottom: "8px",
              }}
            >
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataSource === DataSourceType.S3}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataSourceType.S3}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataSourceTypePresentation.get(DataSourceType.S3)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataSource === DataSourceType.S3}
                value={DataSourceType.S3}
              />
              {WITH_AZURE && (
                <SelectableControlLabel
                  control={
                    <Radio
                      checked={selectedDataSource === DataSourceType.Azure}
                      size="small"
                      sx={{ marginRight: "8px", padding: "2px" }}
                    />
                  }
                  disableTypography={true}
                  label={
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <ExternalConnectionsIcon
                        connectionType={DataSourceType.Azure}
                        size={ExternalConnectionsIconSize.sm}
                      />
                      <Typography sx={{ marginLeft: "4px" }}>
                        {dataSourceTypePresentation.get(DataSourceType.Azure)}
                      </Typography>
                    </Box>
                  }
                  selected={selectedDataSource === DataSourceType.Azure}
                  value={DataSourceType.Azure}
                />
              )}
              <SelectableControlLabel
                control={
                  <Radio
                    checked={
                      selectedDataSource === DataSourceType.GoogleCloudStorage
                    }
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataSourceType.GoogleCloudStorage}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataSourceTypePresentation.get(
                        DataSourceType.GoogleCloudStorage
                      )}
                    </Typography>
                  </Box>
                }
                selected={
                  selectedDataSource === DataSourceType.GoogleCloudStorage
                }
                value={DataSourceType.GoogleCloudStorage}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataSource === DataSourceType.Snowflake}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataSourceType.Snowflake}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataSourceTypePresentation.get(DataSourceType.Snowflake)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataSource === DataSourceType.Snowflake}
                value={DataSourceType.Snowflake}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataSource === DataSourceType.Salesforce}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataSourceType.Salesforce}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataSourceTypePresentation.get(
                        DataSourceType.Salesforce
                      )}
                    </Typography>
                  </Box>
                }
                selected={selectedDataSource === DataSourceType.Salesforce}
                value={DataSourceType.Salesforce}
              />
              <SelectableControlLabel
                control={
                  <Radio
                    checked={selectedDataSource === DataSourceType.Permutive}
                    size="small"
                    sx={{ marginRight: "8px", padding: "2px" }}
                  />
                }
                disableTypography={true}
                label={
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <ExternalConnectionsIcon
                      connectionType={DataSourceType.Permutive}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    <Typography sx={{ marginLeft: "4px" }}>
                      {dataSourceTypePresentation.get(DataSourceType.Permutive)}
                    </Typography>
                  </Box>
                }
                selected={selectedDataSource === DataSourceType.Permutive}
                value={DataSourceType.Permutive}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item={true} xs={7}>
          {selectedDataSource === DataSourceType.S3 && (
            <>
              <Typography mb={1}>
                This will import any object from an Amazon S3 Bucket and store
                it as a dataset in the Decentriq platform.
              </Typography>
              <Typography>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Typography>
            </>
          )}
          {WITH_AZURE && selectedDataSource === DataSourceType.Azure && (
            <>
              <Typography mb={1}>
                This will import any object from an Azure Blob Storage Container
                and store it as a dataset in the Decentriq platform.
              </Typography>
              <Typography>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Typography>
            </>
          )}
          {selectedDataSource === DataSourceType.GoogleCloudStorage && (
            <>
              <Typography mb={1}>
                This will import any object from a Google Cloud Storage Bucket
                and store it as a dataset in the Decentriq platform.
              </Typography>
              <Typography>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Typography>
            </>
          )}
          {selectedDataSource === DataSourceType.Snowflake && (
            <>
              <Typography mb={1}>
                This will import the contents of any table or query data
                unloaded to a Snowflake stage, and store it as a dataset in the
                Decentriq platform.
              </Typography>
              <Typography>
                Note: please{" "}
                <DocsLink
                  href={`${docsBaseLink}/data-connectors`}
                  rel="noreferrer"
                  target="_blank"
                >
                  follow the instructions
                </DocsLink>{" "}
                to prepare your data for import.
              </Typography>
            </>
          )}
          {selectedDataSource === DataSourceType.Salesforce && (
            <>
              <Typography mb={1}>
                This will import any object from Salesforce and store it as a
                dataset in the Decentriq platform.
              </Typography>
              <Typography>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Typography>
            </>
          )}
          {selectedDataSource === DataSourceType.Permutive && (
            <>
              <Typography mb={1}>
                This will import Publisher datasets from Permutive via a Cloud
                Storage service and store them as datasets in the Decentriq
                platform. These datasets can then be provisioned directly to
                Decentriq Data Labs for Publishers.
              </Typography>
              {/* <Typography>
                Note: To prepare your datasets for importing, please follow the{" "}
                <a
                  // href={""}
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                  target="_blank"
                >
                  Permutive guide
                </a>
              </Typography> */}
            </>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="inherit"
          disabled={isDataSourceFormStepDisabled}
          onClick={handleNextStepClick}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

ExternalDataSourceSelector.displayName = "ExternalDataSourceSelector";

export default memo(ExternalDataSourceSelector);
