import { useUserRole } from "hooks";
import {
  type SendExternalInvitationOrgAdminMutationHookResult,
  type SendExternalInvitationOrgAdminMutationOptions,
  type SendExternalInvitationSuperAdminMutationHookResult,
  type SendExternalInvitationSuperAdminMutationOptions,
  useSendExternalInvitationOrgAdminMutation,
  useSendExternalInvitationSuperAdminMutation,
} from "hooks/__generated-new";

type SendExternalInvitationParams = {
  organizationId: string;
} & (
  | SendExternalInvitationOrgAdminMutationOptions
  | SendExternalInvitationSuperAdminMutationOptions
);

// Different mutations are used as depending on the role, some data that is not allowed
// for ORGANIZATION_ADMIN has to be updated for SUPER_ADMIN (e.g. OrganizationsList or UsersList)
const useSendExternalInvitation = ({
  organizationId,
  ...mutationProps
}: SendExternalInvitationParams):
  | SendExternalInvitationOrgAdminMutationHookResult
  | SendExternalInvitationSuperAdminMutationHookResult => {
  const { isSuperAdmin } = useUserRole();

  const sendExternalInvitationOrgAdminMutation =
    useSendExternalInvitationOrgAdminMutation(mutationProps);
  const sendExternalInvitationSuperAdminMutation =
    useSendExternalInvitationSuperAdminMutation(mutationProps);

  return isSuperAdmin
    ? sendExternalInvitationSuperAdminMutation
    : sendExternalInvitationOrgAdminMutation;
};

export default useSendExternalInvitation;
