import {
  faEye,
  faEyeSlash,
  faKey,
  faUnlock,
} from "@fortawesome/pro-light-svg-icons";
import { faCaretRight as faCaretRightSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { generate as generatePassword } from "generate-password-browser";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useCopyToClipboard, useDocsLink, useSignOut } from "hooks";
import { passwordRegExp } from "utils/validation";
import { useKeychain } from "wrappers";
import { useAccordionSummaryReversedStyles } from "../Accordion/Accordion";

const validationSchema = yup.object({
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Please repeat your password")
    .defined(),
  password: yup
    .string()
    .trim()
    .min(
      10,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .matches(
      passwordRegExp,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .required()
    .defined(),
});

const passwordSuggestion = () =>
  generatePassword({
    length: 14,
    lowercase: true,
    numbers: true,
    strict: true,
    uppercase: true,
  });

type SetupKeychainFormValues = yup.InferType<typeof validationSchema>;

interface SetupKeychainProps {}

const SetupKeychain: React.FC<SetupKeychainProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  const { classes: accordionSummaryClasses } =
    useAccordionSummaryReversedStyles();
  const docsBaseLink = useDocsLink();
  const { createKeychain } = useKeychain();
  const [creating, setCreating] = useState(false);
  const [suggestedPassword, setSuggestedPassword] = useState<
    string | undefined
  >();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: SetupKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      confirmPassword: confirmPassword || "",
      password,
    });
  }, [password, confirmPassword, validate]);
  const handleSubmit = useCallback(
    async (
      password: string | undefined,
      confirmPassword: string | undefined
    ) => {
      setCreating(true);
      const isValid = await validate({
        confirmPassword: confirmPassword || "",
        password: password || "",
      });
      if (isValid) {
        await createKeychain(password!.trim());
      }
      setCreating(false);
    },
    [setCreating, createKeychain, validate]
  );
  const handlePasswordFocus = useCallback(
    (password: string | undefined) => {
      if (suggestedPassword) {
        return;
      }
      if (!password || !password.trim().length) {
        const newPassword = passwordSuggestion();
        setSuggestedPassword(newPassword);
      }
    },
    [setSuggestedPassword, suggestedPassword]
  );
  const handlePasswordSuggestionUse = useCallback(
    (suggestedPassword: string) => {
      copyToClipboard(suggestedPassword);
      enqueueSnackbar("Password copied to clipboard", {
        autoHideDuration: 1500,
      });
      setPassword(suggestedPassword);
      setConfirmPassword(suggestedPassword);
      setSuggestedPassword(undefined);
    },
    [copyToClipboard, enqueueSnackbar, setPassword, setConfirmPassword]
  );
  const onSignOut = useSignOut();
  return (
    <Dialog fullWidth={true} maxWidth="md" open={true}>
      <DialogTitle>
        <FontAwesomeIcon fixedWidth={true} icon={faKey} /> Keychain
      </DialogTitle>
      <DialogContent>
        <Typography>
          The Keychain lets you confidentially store dataset encryption keys and
          other secrets.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Among other features, this enables you to reuse datasets across data
          clean rooms without having to re-upload them to Decentriq.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          The Keychain must be enabled to interact with the Decentriq Platform.
          Please set your password now.
        </Typography>
        <Box sx={{ mb: 2, mt: 2 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(password, confirmPassword);
            }}
          >
            <FormControl fullWidth={true} variant="filled">
              <InputLabel>Create new Keychain password</InputLabel>
              <Tooltip
                disableFocusListener={true}
                disableHoverListener={true}
                disableTouchListener={true}
                onClose={() => setSuggestedPassword(undefined)}
                open={!!suggestedPassword}
                placement="top"
                title={
                  <Box
                    onClick={(e) => {
                      handlePasswordSuggestionUse(suggestedPassword!);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="caption">
                      Click here to use suggested password:
                      <pre style={{ margin: ".5rem 0" }}>
                        {suggestedPassword}
                      </pre>
                    </Typography>
                  </Box>
                }
              >
                <FilledInput
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} tabIndex={-1}>
                        {showPassword ? (
                          <FontAwesomeIcon
                            fixedWidth={true}
                            icon={faEyeSlash}
                          />
                        ) : (
                          <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth={true}
                  onBlur={() =>
                    setTimeout(() => setSuggestedPassword(undefined), 300)
                  }
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    setPassword(newPassword);
                    if (suggestedPassword) {
                      if (!newPassword.length) {
                        handlePasswordFocus(newPassword);
                      }
                    } else {
                      if (newPassword.trim().length) {
                        setSuggestedPassword(undefined);
                      }
                    }
                  }}
                  onFocus={() => handlePasswordFocus(password)}
                  type={showPassword ? "text" : "password"}
                  value={password || ""}
                />
              </Tooltip>
            </FormControl>
            <FormControl fullWidth={true} sx={{ mt: 1 }} variant="filled">
              <InputLabel>Repeat password</InputLabel>
              <FilledInput
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowConfirmPassword}
                      tabIndex={-1}
                    >
                      {showConfirmPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth={true}
                onChange={(event) => setConfirmPassword(event.target.value)}
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword || ""}
              />
            </FormControl>
          </form>
        </Box>
        {!isValid ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            <strong>Invalid password:</strong>
            <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
              {validationErrors?.map((validationError, index) => (
                <li key={index}>{validationError}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <Box sx={{ mt: 4 }}>
          <Accordion disableGutters={true} elevation={0} square={true}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              expandIcon={<FontAwesomeIcon icon={faCaretRightSolid} />}
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                alignItems: "center",
              }}
            >
              <Box
                sx={{ alignItems: "center", display: "flex", height: "100%" }}
              >
                <Typography>How does it work?</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The Keychain operates like a traditional password manager. It
                derives an encryption key from your password. This key is used
                to locally encrypt the secrets (e.g. dataset encryption keys)
                you want to store in the Keychain.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                The encrypted secrets are then stored on the Decentriq Platform.
                They are retrieved when needed and the same password-derived
                encryption key is used to decrypt them.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                As Decentriq does not have access to your password, Decentriq
                can never access the secrets stored in the Keychain.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                If you lose your password, you will have to reset it and lose
                access to all previously stored secrets.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                For more information, please{" "}
                <Link
                  href={`${docsBaseLink}/keychain`}
                  rel="noreferrer"
                  target="_blank"
                >
                  check the documentation
                </Link>
                .
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={creating} onClick={onSignOut}>
          Sign out
        </Button>
        <LoadingButton
          color="primary"
          loading={creating}
          loadingPosition="start"
          onClick={() => handleSubmit(password, confirmPassword)}
          startIcon={<FontAwesomeIcon icon={faUnlock} />}
          variant="contained"
        >
          Activate Keychain
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default memo(SetupKeychain);
