import { type FetchResult } from "@apollo/client";
import { useDraftDataRoomDeleteMutation } from "hooks/__generated-new";
import { type DraftDataRoomDeleteMutation } from "types/__generated-new";

interface useDraftDataRoomDeleteArgs {
  id: string;
}

interface useDraftDataRoomDeleteResult {
  draftDataRoomDelete: () => Promise<
    FetchResult<
      DraftDataRoomDeleteMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  draftDataRoomDeleteLoading: boolean;
}

const useDraftDataRoomDelete = ({
  id: dataRoomId,
}: useDraftDataRoomDeleteArgs): useDraftDataRoomDeleteResult => {
  const [draftDataRoomDeleteMutation, { loading: draftDataRoomDeleteLoading }] =
    useDraftDataRoomDeleteMutation();
  const draftDataRoomDelete = () =>
    draftDataRoomDeleteMutation({
      onQueryUpdated: (observableQuery, { complete, missing }) => {
        if (complete) {
          return observableQuery.reobserve();
        }
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: "DraftDataRoom",
            id: dataRoomId,
          }),
        });
        cache.gc();
      },
      variables: { id: dataRoomId },
    });
  return {
    draftDataRoomDelete,
    draftDataRoomDeleteLoading,
  };
};

export default useDraftDataRoomDelete;
