import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { z } from "zod";
import { ColumnDataType } from "types/__generated-new";

export const isValidIdentifier = (value?: string): boolean =>
  !SANITIZE_IDENTIFIER_INPUT ||
  (value !== undefined
    ? new RegExp(`^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9_ ]*[a-zA-Z0-9])$`, "gm").test(
        value
      )
    : false);

export const sanitizeIdentifier = (value: string) =>
  value?.replaceAll(new RegExp(`'|"`, "gmi"), "")?.trim() || "";

export const passwordRegExp = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\d\\w\\W]{10,}$`
);

export const datasetMetadataZodSchema = z.object({
  schema: z.array(
    z.object({
      name: z.string(),
      nullable: z.boolean(),
      primitiveType: z.nativeEnum(ColumnDataType),
    })
  ),
});

export type DatasetMetadata = z.infer<typeof datasetMetadataZodSchema>;
