import { memo } from "react";
import { type DatasetColumnStatistics, DatasetSchema } from "features/datasets";
import { type DatasetMetadata } from "utils/validation";

type DatasetSchemaTabPanelProps = {
  metadata: DatasetMetadata;
};

const DatasetSchemaTabPanel: React.FC<DatasetSchemaTabPanelProps> = ({
  metadata,
}) => {
  const columns = metadata?.schema.map(
    ({ name, primitiveType, nullable }): DatasetColumnStatistics => ({
      column: name,
      nullable,
      type: primitiveType,
    })
  );
  return <DatasetSchema columns={columns} />;
};

DatasetSchemaTabPanel.displayName = "DatasetSchemaTabPanel";

export default memo(DatasetSchemaTabPanel);
