import { Chip as MuiChip, type ChipProps as MuiChipProps } from "@mui/material";
import { memo } from "react";

const Chip: React.FC<MuiChipProps> = ({ sx = {}, label, ...props }) => {
  return (
    <MuiChip
      label={label}
      sx={{
        borderRadius: "2px",
        height: "22px",
        marginTop: "-2px",
        ...sx,
      }}
      {...props}
    />
  );
};

Chip.displayName = "Chip";

export default memo(Chip);
