import { useApolloClient } from "@apollo/client";
import { type Key } from "@decentriq/utils";
import { useCallback } from "react";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useDeleteTestModePublicationMutation,
  useSetTestModePublicationMutation,
} from "hooks/__generated-new";
import {
  DatasetByHashDocument,
  DraftNodeIdDocument,
  type DraftNodeIdQuery,
} from "types/__generated-new";

const useTestDraftNodeActions = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const apolloClient = useApolloClient();
  const [deleteTestModePublicationMutation] =
    useDeleteTestModePublicationMutation({
      onCompleted: () => {
        enqueueSnackbar("Your data has been deprovisioned.");
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, `Can't deprovision data`)
        );
      },
    });
  const onDatasetDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) => {
      const node = apolloClient.cache.readQuery<DraftNodeIdQuery>({
        query: DraftNodeIdDocument,
        variables: {
          id: dataNodeId,
        },
      });
      await deleteTestModePublicationMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              testModePublication: (existing = {}) => {
                return null;
              },
            },
            id: cache.identify({
              __typename: node?.draftNode?.__typename,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          nodeId: {
            draft: dataNodeId,
          },
        },
      });
    },
    [deleteTestModePublicationMutation, apolloClient]
  );
  const [setTestModePublicationMutation] = useSetTestModePublicationMutation();
  const onSetTestModePublication = useCallback(
    async ({
      dataNodeId,
      datasetId,
      manifestHash,
    }: {
      key: Key;
      dataNodeId: string;
      manifestHash: string;
      datasetId: string;
    }) => {
      const publishedNode = apolloClient.cache.readQuery<DraftNodeIdQuery>({
        query: DraftNodeIdDocument,
        variables: {
          id: dataNodeId,
        },
      });
      await setTestModePublicationMutation({
        refetchQueries: [
          {
            fetchPolicy: "network-only",
            query: DatasetByHashDocument,
            variables: { manifestHash },
          },
        ],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              testModePublication: (existing = {}) => {
                return {
                  __typename: "TestModePublication",
                  dataset: data?.testModePublication?.set?.record?.dataset,
                };
              },
            },
            id: cache.identify({
              __typename: publishedNode?.draftNode.__typename,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          input: {
            datasetId,
            leafNodeId: {
              draft: dataNodeId,
            },
          },
        },
      });
    },
    [setTestModePublicationMutation, apolloClient]
  );
  const onConnectDataset = useCallback(
    async (args: { key: Key; dataNodeId: string; manifestHash: string }) => {
      const { data } = await apolloClient.query({
        query: DatasetByHashDocument,
        variables: {
          manifestHash: args.manifestHash,
        },
      });
      const datasetId = data?.datasetByManifestHash?.id;
      if (!datasetId) {
        throw Error("Can't find dataset");
      }
      await onSetTestModePublication({
        ...args,
        datasetId,
      });
    },
    [apolloClient, onSetTestModePublication]
  );
  const {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData: onSetTestModePublication,
    onConnectDataset,
    onDatasetDeprovision,
  });
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useTestDraftNodeActions;
