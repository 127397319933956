import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { DataSourceType, DataTargetType } from "types/__generated-new";
import {
  AzureConnectionConfiguration,
  GoogleAdManagerConnectionConfiguration,
  GoogleCloudStorageConnectionConfiguration,
  GoogleDV360ConnectionConfiguration,
  MetaConnectionConfiguration,
  PermutiveConnectionConfiguration,
  S3ConnectionConfiguration,
  SalesforceConnectionConfiguration,
  SnowflakeConnectionConfiguration,
} from "./components";

type Typename = "DatasetImport" | "DatasetExport";

interface ExternalConnectionConfigurationProps {
  connectionType: DataSourceType | DataTargetType;
  connectionId: string;
  __typename: Typename;
}

const ExternalConnectionConfiguration: React.FC<
  ExternalConnectionConfigurationProps
> = ({ connectionType, connectionId, __typename }) => {
  return (
    <>
      {connectionType !== DataSourceType.Compute && (
        <Grid item={true} xs={12}>
          <Typography variant="body2">Configuration</Typography>
        </Grid>
      )}
      <Grid item={true} paddingLeft={"16px"} xs={12}>
        <>
          {connectionType === DataSourceType.S3 ||
          connectionType === DataTargetType.S3 ? (
            <S3ConnectionConfiguration
              connectionId={connectionId}
              typename={__typename}
            />
          ) : null}
          {connectionType === DataSourceType.GoogleCloudStorage ||
          connectionType === DataTargetType.GoogleCloudStorage ? (
            <GoogleCloudStorageConnectionConfiguration
              connectionId={connectionId}
              typename={__typename}
            />
          ) : null}
          {connectionType === DataTargetType.GoogleAdManager ? (
            <GoogleAdManagerConnectionConfiguration
              connectionId={connectionId}
            />
          ) : null}
          {connectionType === DataSourceType.Snowflake ? (
            <SnowflakeConnectionConfiguration connectionId={connectionId} />
          ) : null}
          {connectionType === DataTargetType.Meta ? (
            <MetaConnectionConfiguration connectionId={connectionId} />
          ) : null}
          {connectionType === DataTargetType.GoogleDv_360 ? (
            <GoogleDV360ConnectionConfiguration connectionId={connectionId} />
          ) : null}
          {connectionType === DataTargetType.Permutive ? (
            <PermutiveConnectionConfiguration connectionId={connectionId} />
          ) : null}
          {connectionType === DataSourceType.Azure ||
          connectionType === DataTargetType.Azure ? (
            <AzureConnectionConfiguration
              connectionId={connectionId}
              typename={__typename}
            />
          ) : null}
          {connectionType === DataSourceType.Salesforce ? (
            <SalesforceConnectionConfiguration connectionId={connectionId} />
          ) : null}
        </>
      </Grid>
    </>
  );
};

ExternalConnectionConfiguration.displayName = "ExternalConnectionConfiguration";

export default memo(ExternalConnectionConfiguration);
