import { useEffect } from "react";
import {
  useDatasetExportsQuery,
  useDatasetImportsQuery,
  usePollDatasetExportMutation,
  usePollDatasetImportMutation,
} from "hooks/__generated-new";
import {
  DataImportExportStatus,
  type DatasetExport,
  DatasetsDocument,
} from "types/__generated-new";
import { useKeychainService } from "../contexts";
import {
  KeychainItemKind,
  type KeychainService,
  UserKeychainStatus,
} from "../services/keychain";

async function getPendingImportsFromKeychain(
  keychain: KeychainService,
  itemKind: KeychainItemKind
) {
  const keychainItems = await keychain.getItems();
  const pendingDatasetImports = keychainItems.filter((item) => {
    return item.kind === itemKind;
  });
  return pendingDatasetImports;
}

/**
 * This hook's job is to poll the result of all pending dataset imports.
 * For each import we'll trigger the process of polling the enclave for the import
 * result (the manifest hash of the new dataset), so that we can update the keychain
 * with the new key->manifestHash mapping.
 * This is necessary, as the user might have closed the browser window
 * in an earlier dataset import attempt, without having waited for the import
 * to finish.
 */
export default function useDatasetImportExportWatcher() {
  const { keychain, keychainStatus } = useKeychainService();

  // When a new "dataset import" is created, this components
  // useEffect callback should run again to trigger the import
  // polling procedure.
  const allDatasetImports = useDatasetImportsQuery({
    variables: {
      filter: null,
    },
  });
  // When a new "dataset export" is created, this components
  // useEffect callback should run again to trigger the import
  // polling procedure.
  const allDatasetExports = useDatasetExportsQuery({
    variables: {
      filter: null,
    },
  });

  const [pollDatasetImportMutation] = usePollDatasetImportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });
  const [pollDatasetExportMutation] = usePollDatasetExportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });

  // Note that this effect depends on `allDatasetImports`. If a new dataset import
  // is being created by the import wizard, there will also be a new keychain
  // item for a pending dataset import.
  // Ideally this component would directly depend on the keychain items cache
  // (or whatever other state management system we end up using).
  useEffect(() => {
    async function completePendingImports() {
      const pendingImports = await getPendingImportsFromKeychain(
        keychain,
        KeychainItemKind.PendingDatasetImport
      );
      await Promise.all(
        pendingImports.map(async (datasetImport) => {
          await pollDatasetImportMutation({
            variables: { id: datasetImport.id },
          });
        })
      );
    }
    if (keychainStatus === UserKeychainStatus.Unlocked) {
      completePendingImports();
    }
  }, [
    keychainStatus,
    allDatasetImports.data,
    keychain,
    pollDatasetImportMutation,
  ]);

  useEffect(() => {
    const pendingDatasetExports = (
      allDatasetExports?.data?.datasetExports?.nodes || []
    ).filter(
      ({ status }: DatasetExport) => status === DataImportExportStatus.Pending
    );
    async function completePendingExports() {
      await Promise.all(
        pendingDatasetExports.map(async (datasetExport: DatasetExport) => {
          await pollDatasetExportMutation({
            variables: { id: datasetExport.id },
          });
        })
      );
    }
    completePendingExports();
  }, [allDatasetExports.data, pollDatasetExportMutation]);
}
