import { testIds } from "@decentriq/utils";
import { FormControl, MenuItem, Select } from "@mui/material";
import { memo, useCallback } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useUpdateSyntheticNodeDataSourceMutation } from "hooks/__generated-new";
import { mapColumnDataTypeToMaskType } from "../../models";
import useSyntheticNodeDataSources from "../useSyntheticNodeDataSources/useSyntheticNodeDataSources";
import useSyntheticNodeDataSource from "./useSyntheticNodeDataSource";

type SyntheticNodeDataSourceSelectProps = {
  computeNodeId: string;
  readOnly?: boolean;
};

const SyntheticNodeDataSourceSelect: React.FC<
  SyntheticNodeDataSourceSelectProps
> = ({ computeNodeId, readOnly }) => {
  const { dataRoomId } = useDataRoom();
  const { dependency } = useSyntheticNodeDataSource(computeNodeId);
  const dataSource = dependency?.id || "";
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const dataSourceOptions = useSyntheticNodeDataSources();
  const [updateSyntheticNodeDataSourceMutation] =
    useUpdateSyntheticNodeDataSourceMutation();
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const updateSyntheticNodeDataSource = useCallback(
    async (dependencyId: string) => {
      try {
        const selectedOption = dataSourceOptions.find(
          ({ computeNodeId }) => dependencyId === computeNodeId
        )!;
        return updateSyntheticNodeDataSourceMutation({
          variables: {
            computeNodeId,
            dependencyId: isInteractivityContext
              ? {
                  published: {
                    computeNodeId: dependencyId,
                    publishedDataRoomId: dataRoomId,
                  },
                }
              : { draft: dependencyId },
            dependencyLastUpdatedAt: selectedOption.updatedAt,
            syntheticNodeColumns:
              selectedOption.type === "tabular"
                ? selectedOption.namedColumns.map(
                    ({ dataType, name }, index) => ({
                      dataType,
                      index,
                      maskType: mapColumnDataTypeToMaskType(dataType),
                      name,
                      shouldMaskColumn: true,
                    })
                  )
                : undefined,
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation data source could not be updated."
          )
        );
        throw error;
      }
    },
    [
      updateSyntheticNodeDataSourceMutation,
      computeNodeId,
      enqueueSnackbar,
      dataSourceOptions,
      isInteractivityContext,
      dataRoomId,
    ]
  );
  return (
    <FormControl>
      <Select
        data-testid={testIds.computeNode.syntethicNodeEditor.datasetDropdown}
        disabled={readOnly}
        displayEmpty={true}
        fullWidth={true}
        onChange={({ target }) => {
          if (target.value !== dataSource) {
            updateSyntheticNodeDataSource(target.value);
          }
        }}
        renderValue={(value) =>
          dataSourceOptions.find((o) => o.computeNodeId === value)?.name ||
          "Data source"
        }
        size="small"
        style={{
          background: "transparent",
          textAlign: "center",
          width: "280px",
        }}
        value={dataSource}
        variant="standard"
      >
        {dataSourceOptions.map(({ name, computeNodeId }) => (
          <MenuItem
            data-testid={`${testIds.computeNode.syntethicNodeEditor.helper}${name}`}
            key={computeNodeId}
            value={computeNodeId}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SyntheticNodeDataSourceSelect.displayName = "SyntheticNodeDataSourceSelect";

export default memo(SyntheticNodeDataSourceSelect);
