import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, type Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useFourOFour = makeStyles()((theme: Theme) => ({
  containerCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
  },
  contentText: {
    display: "block",
    marginBlockEnd: "0.7em",
    marginBlockStart: "0.3em",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
  },
  fofIcon: {
    height: "5em",
    marginBottom: "0.5em",
    width: "5em !important",
  },
}));

const FourOFourPage = () => {
  const { classes: fourOFourClasses } = useFourOFour();
  return (
    <Container className={fourOFourClasses.containerCenter} maxWidth={false}>
      <FontAwesomeIcon
        className={fourOFourClasses.fofIcon}
        fixedWidth={true}
        height="5em !important"
        icon={faTriangleExclamation}
        width={24}
      />
      <Typography variant="h5">
        <strong>404 Not found</strong>
      </Typography>
      <Typography>
        <p className={fourOFourClasses.contentText}>
          We can't find the page you were looking for
        </p>
      </Typography>
      <Button
        color="primary"
        href="/"
        sx={{ backgroundColor: "#289090", color: "#fff" }}
        variant="contained"
      >
        BACK TO HOME SCREEN
      </Button>
    </Container>
  );
};

export default FourOFourPage;
