import { faShieldKeyhole as faShieldKeyholeDuotone } from "@fortawesome/pro-duotone-svg-icons";
import {
  faCopy,
  faExclamationTriangle,
  faFileCertificate,
} from "@fortawesome/pro-light-svg-icons";
import { faShieldKeyhole as faShieldKeyholeSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Portal, Tooltip } from "@mui/material";
import { useBoolean, useHover } from "ahooks";
import { useSnackbar } from "notistack";
import { memo, useCallback, useRef } from "react";
import { Actions, PublishedAndProtectedDialog } from "components";
import { usePublishedDataRoom } from "contexts";
import { DataRoomActionTypes } from "features/dataRoom";
import { useCopyToClipboard } from "hooks";
import { useDataRoomAttestationSpecsQuery } from "hooks/__generated-new";
import { EnclaveSpecName } from "utils/apicore";

const SPEC_MSG_BY_NAME: { [key in EnclaveSpecName]: string } = {
  [EnclaveSpecName.GCG]: "Enclave version",
  [EnclaveSpecName.SQL]: "SQL worker",
  [EnclaveSpecName.SQLITE]: "SQLite worker",
  [EnclaveSpecName.POST]: "Post worker",
  [EnclaveSpecName.PYTHON]: "Python worker",
  [EnclaveSpecName.R]: "R worker",
  [EnclaveSpecName.SYNTHETIC_DATA]: "Synthetic data worker",
  [EnclaveSpecName.S3]: "S3 worker",
};

function EnclaveSpecActions() {
  const { dataRoomId, driverAttestationHash } = usePublishedDataRoom();
  const { data: { dataRoomAttestationSpecs = [] } = {} } =
    useDataRoomAttestationSpecsQuery({
      variables: {
        dcrHash: dataRoomId,
        driverAttestationHash,
      },
    });
  // Open "Published and protected" dialog
  const [
    isPublishedAndProtectedDialogOpen,
    {
      setTrue: openPublishedAndProtectedDialog,
      setFalse: closePublishedAndProtectedDialog,
    },
  ] = useBoolean(false);
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const { enqueueSnackbar } = useSnackbar();
  const [, hashCopyToClipboard] = useCopyToClipboard();
  const copyHashToClipboard = useCallback(
    (hash: string, message: string) => {
      if (hash) {
        hashCopyToClipboard(hash);
        enqueueSnackbar(
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faCopy}
              style={{ marginRight: "0.5rem" }}
            />
            {message}
          </Box>
        );
      }
    },
    [hashCopyToClipboard, enqueueSnackbar]
  );
  const menuItems = dataRoomAttestationSpecs.map((spec) =>
    makeMenuItem({
      copyHashToClipboard,
      hash: spec.hash,
      insecure: spec.isInsecure,
      name: SPEC_MSG_BY_NAME[spec.name as EnclaveSpecName] ?? "",
      unavailable: spec.isUnavailable,
    })
  );
  const actions = {
    buttons: [],
    menuLists: [
      [
        {
          icon: faFileCertificate,
          name: "More about enclave security",
          onClick: openPublishedAndProtectedDialog,
        },
      ],
      menuItems,
    ],
  };
  const isDcrInsecure = dataRoomAttestationSpecs.some(
    (spec) => spec.isInsecure
  );
  return (
    <Box alignItems="center" display="flex">
      {isDcrInsecure && (
        <Tooltip
          placement="bottom-end"
          title="This data clean room contains untrusted enclave workers. Please use only datasets for test purposes."
        >
          <Box color="red" display="inline" marginRight={1}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Box>
        </Tooltip>
      )}
      <Actions
        actions={actions}
        inline={false}
        moreIcon={isHovering ? faShieldKeyholeDuotone : faShieldKeyholeSolid}
        moreTooltipPlacement={"bottom-end"}
        moreTooltipTitle={
          <div>
            <div>Published and protected</div>
            <div>
              This data clean room has been published. The data clean room
              definition cannot be changed anymore and participants can interact
              with it. All data room interactions are directly with the
              confidential computing enclave.
            </div>
          </div>
        }
        ref={ref}
      >
        <Portal>
          <PublishedAndProtectedDialog
            onClose={closePublishedAndProtectedDialog}
            open={isPublishedAndProtectedDialogOpen}
          />
        </Portal>
      </Actions>
    </Box>
  );
}

function makeMenuItem({
  name,
  copyHashToClipboard,
  hash,
  insecure,
  unavailable,
}: {
  name: string;
  copyHashToClipboard: (hash: string, message: string) => void;
  hash: string;
  insecure: boolean;
  unavailable: boolean;
}) {
  return {
    name: (
      <div
        style={{
          lineHeight: "1.1875rem",
          opacity: 0.75,
          overflow: "auto",
          whiteSpace: "pre",
        }}
      >
        <div style={{ marginTop: 4 }}>
          <strong>{name}</strong>
          {(insecure || unavailable) && (
            <Box color="red" display="inline" marginLeft={1}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </Box>
          )}
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              maxWidth: 100,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={hash}
          >
            {hash}
          </div>
          <div
            onClick={() =>
              copyHashToClipboard(hash!, `${name} copied to clipboard`)
            }
            style={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
            }}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faCopy}
              style={{
                marginRight: "0.25rem",
              }}
            />{" "}
            Copy
          </div>
        </div>
      </div>
    ),
    type: DataRoomActionTypes.Details,
  };
}

export default memo(EnclaveSpecActions);
