/* eslint-disable sort-keys-fix/sort-keys-fix */
import { MenuItem, Select } from "@mui/material";
import { memo, type SetStateAction } from "react";
import {
  type DraftDataRoom,
  type PublishedDataRoom,
  type PublishedLookalikeMediaDataRoom,
  type PublishedMediaDataRoom,
} from "types/__generated-new";

type DataRoomListFilter = {
  key: "all" | "hidden" | "favourites" | "stopped" | "sharedWithMe" | "active";
  field: string | null;
  value?: string | boolean | null;
  label: string;
  equalsTo?: (
    dataRoom: Partial<
      | DraftDataRoom
      | PublishedLookalikeMediaDataRoom
      | PublishedDataRoom
      | PublishedMediaDataRoom
    >,
    payload: any
  ) => boolean;
};

// An item will be filtered out only field's value === value defined in the object
export const FILTER_OPTIONS: { [key: string]: DataRoomListFilter } = {
  active: {
    equalsTo: ({ userSettings, isStopped = false, deactivatedAt = null }) =>
      !(userSettings?.isArchived || isStopped || deactivatedAt),
    field: null,
    key: "active",
    label: "Active",
  },
  sharedWithMe: {
    equalsTo: (dataRoom, currentUserEmail) =>
      dataRoom?.owner?.email !== currentUserEmail,
    field: "owner.email",
    key: "sharedWithMe",
    label: "Shared with me",
  },
  favourites: {
    field: "userSettings.isFavorite",
    key: "favourites",
    label: "Favourites",
    value: true,
  },
  hidden: {
    field: "userSettings.isArchived",
    key: "hidden",
    label: "Hidden",
    value: true,
  },
  stopped: {
    field: "isStopped",
    key: "stopped",
    label: "Stopped",
    value: true,
  },
  all: {
    field: null,
    key: "all",
    label: "All",
    value: null,
  },
};

type DataRoomsListFilterProps = {
  filterKey: DataRoomListFilter["key"];
  setFilterKey: (value: SetStateAction<DataRoomListFilter["key"]>) => void;
};

const DataRoomsListFilter: React.FC<DataRoomsListFilterProps> = ({
  filterKey,
  setFilterKey,
}) => {
  return (
    <Select
      defaultValue={FILTER_OPTIONS.active.key}
      displayEmpty={true}
      onChange={({ target }) => {
        setFilterKey(target.value as DataRoomListFilter["key"]);
      }}
      sx={{ m: 1 }}
      value={filterKey}
      variant="standard"
    >
      {Object.values(FILTER_OPTIONS).map(
        ({ label, key }: DataRoomListFilter) => (
          <MenuItem dense={true} key={key} value={key}>
            {label}
          </MenuItem>
        )
      )}
    </Select>
  );
};

DataRoomsListFilter.displayName = "DataRoomsListFilter";

export default memo(DataRoomsListFilter);
