import { useAuth0 } from "@auth0/auth0-react";
import { Box, Link } from "@mui/material";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CustomBrandingLogo } from "components";
import { useCurrentUserDomainQuery } from "hooks/__generated-new";

const OrganizationLogoSidebarItem: React.FC = () => {
  const { user } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};
  const domain =
    currentUserEmail.slice(currentUserEmail.indexOf("@") + 1).toLowerCase() ||
    "";
  const { data: currentUserDomainData } = useCurrentUserDomainQuery();
  const logoBase64 = currentUserDomainData?.myself?.organizationLogo;

  return (
    <Box sx={{ marginBottom: 1.5 }}>
      <Link
        color="inherit"
        component={RouterLink}
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
          ml: 1,
          mr: 1,
          mt: 1,
        }}
        to="/"
      >
        <CustomBrandingLogo
          domain={domain}
          fallbackToDecentriq={false}
          logoBase64={logoBase64!}
          maxHeight={"auto"}
          withPoweredBy={false}
        />
      </Link>
    </Box>
  );
};

OrganizationLogoSidebarItem.displayName = "OrganizationLogoSidebarItem";

export default memo(OrganizationLogoSidebarItem);
