import { type lookalike_media_request, type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useApiCore } from "contexts";
import { filterNullableOverlapInsightsRows } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/helpers";
import useQueryMediaInsightsComputeJob, {
  type QueryComputeJobHookPayload,
  type QueryMediaInsightsComputeJobHookResult,
} from "features/mediaDataRoomV2/hooks/useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";
import { type AudienceOverlapStatisticsRaw } from "features/mediaDataRoomV2/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type CreateMediaComputeJobInput } from "types/__generated-new";
import {
  type OverlapInsightsCacheKey,
  type OverlapSegment,
  type OverlapSegmentsCollection,
  type OverlapStatistic,
  type OverlapStatisticsCollection,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

interface LookalikeMediaOverlapInsightsHookPayload {
  createCacheKeyString: (key: OverlapInsightsCacheKey) => Promise<string>;
  key?: OverlapInsightsCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

export type LookalikeMediaOverlapInsightsHookResult =
  QueryMediaInsightsComputeJobHookResult<{
    insights: OverlapSegment[];
    segments: OverlapSegment[];
    statistics: AudienceOverlapStatisticsRaw[];
    totalPublisherCount: number;
    withNoiseCalculation: boolean;
  }>;

const useLookalikeMediaOverlapInsightsData = ({
  createCacheKeyString,
  key,
  session,
  skip,
}: LookalikeMediaOverlapInsightsHookPayload) => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const { client } = useApiCore();

  const jobType = "LOOKALIKE_MEDIA_OVERLAP_INSIGHTS";

  const createJob: QueryComputeJobHookPayload<
    null,
    OverlapInsightsCacheKey
  >["createJob"] = useCallback(
    async ({
      key,
      jobCacheKeyString,
      session,
    }): Promise<CreateMediaComputeJobInput> => {
      const scopeId = await client.ensureDcrDataScope(key.dataRoomId);
      const request: lookalike_media_request.LookalikeMediaRequest = {
        calculateOverlapInsights: {
          dataRoomIdHex: key.dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendLookalikeMediaRequest(request);
      if (!("calculateOverlapInsights" in response)) {
        throw new Error("Expected calculateOverlapInsights response");
      }
      const computeNodeName = response.calculateOverlapInsights.computeNodeName;
      const jobIdHex = response.calculateOverlapInsights.jobIdHex;
      return {
        cacheKey: jobCacheKeyString,
        computeNodeName,
        jobIdHex,
        jobType,
        publishedDataRoomId: key.dataRoomId,
      };
    },
    [client]
  );

  const transform = useCallback(async (result: Uint8Array) => {
    const zip = await loadAsync(result);

    const overlapInsightsSegmentsFile = zip.file("segments.json");
    if (overlapInsightsSegmentsFile === null) {
      throw new Error("segments.json not found in zip");
    }
    const overlapInsightsSegments: OverlapSegmentsCollection = JSON.parse(
      await overlapInsightsSegmentsFile.async("string")
    );

    const overlapInsightsStatisticsFile = zip.file("overlap.json");
    if (overlapInsightsStatisticsFile === null) {
      throw new Error("overlap.json not found in zip");
    }
    const overlapInsightsStatistics: OverlapStatisticsCollection = JSON.parse(
      await overlapInsightsStatisticsFile.async("string")
    );

    const formattedStatistics: AudienceOverlapStatisticsRaw[] = (
      overlapInsightsStatistics?.overlap_statistics || []
    ).map((overlap: OverlapStatistic) => ({
      advertiserSize: overlap.advertiser_size,
      audienceType: overlap.audience_type,
      overlapSize: overlap.overlap_size,
    }));

    const segments = filterNullableOverlapInsightsRows(overlapInsightsSegments);

    return {
      insights: segments,
      segments,
      statistics: formattedStatistics,
      totalPublisherCount: overlapInsightsStatistics?.total_publisher,
      // 11.1% rounding has been introduced in v2 compute, hence, for the previous versions of the LMDCRs
      // it is not available, and the content related to it must not be visible
      withNoiseCalculation: !!overlapInsightsStatistics?.total_publisher,
    };
  }, []);

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    createJob,
    jobCacheKey: key ?? undefined,
    jobType,
    queryKeyPrefix: ["lal-dcr-media-overlap-insights"],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Advertiser overlap insights`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useLookalikeMediaOverlapInsightsData;
