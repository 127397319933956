import { type SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChartLine,
  faRadar,
  faText,
  type IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabList } from "@mui/lab";
import { Box, FormLabel, styled, Tab } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useLookalikeMediaOverlapInsights } from "features/mediaDataRoomV2/components/LookalikeMediaInsights/hooks";
import { type OverlapInsightsChartTypes } from "features/mediaDataRoomV2/models";

const WITH_TAG_CLOUD = false;

type ChartTypeButton = {
  icon: IconDefinition | null;
  isVisible: boolean;
  label: string;
  size: SizeProp;
  value: OverlapInsightsChartTypes;
};

const ChartTypeTabs = styled(TabList)(({ theme: { spacing } }) => ({
  "& .MuiTab-iconWrapper": {
    marginBottom: "0 !important",
    marginRight: spacing(0.5),
  },
  "& .MuiTab-root": {
    "&.Mui-selected": {
      background: "rgba(40, 144, 144, 0.08)",
      color: "#289090",
    },
    "&:not(:last-of-type)": {
      marginRight: "-1px",
    },
    border: `1px solid ${grey[200]}`,
    flexDirection: "row",
    height: spacing(4),
    minHeight: spacing(4),
    minWidth: "auto",
    padding: spacing(1.5),
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  height: spacing(4),
  minHeight: spacing(4),
}));

const CHART_TYPES_BUTTONS: ChartTypeButton[] = [
  {
    icon: faRadar,
    isVisible: true,
    label: "Radar",
    size: "sm",
    value: "RADAR",
  },
  {
    icon: faChartLine,
    isVisible: true,
    label: "Line",
    size: "sm",
    value: "LINE",
  },
  {
    icon: faText,
    isVisible: WITH_TAG_CLOUD,
    label: "Tag Cloud",
    size: "sm",
    value: "TAG_CLOUD",
  },
];

const LookalikeMediaInsightsChartTypeSelector: React.FC = () => {
  const { isEmpty: isEmptyOverlapInsights } =
    useLookalikeMediaOverlapInsights();
  const { control: formControl } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: 2,
        width: "100%",
      }}
    >
      <FormLabel component="legend" sx={{ marginBottom: 0.5 }}>
        Chart Type
      </FormLabel>
      <Controller
        control={formControl}
        name="chartType"
        render={({ field: { onChange = () => {}, value } = {} }) => {
          return (
            <TabContext value={isEmptyOverlapInsights ? null : value}>
              <ChartTypeTabs
                onChange={(_, newValue: OverlapInsightsChartTypes) =>
                  onChange(newValue)
                }
              >
                {CHART_TYPES_BUTTONS.map(
                  (
                    {
                      label,
                      value,
                      icon = null,
                      size = "sm",
                      isVisible = true,
                    },
                    index
                  ) =>
                    isVisible && (
                      <Tab
                        disabled={isEmptyOverlapInsights}
                        icon={<FontAwesomeIcon icon={icon!} size={size} />}
                        key={index}
                        label={label}
                        value={value}
                      />
                    )
                )}
              </ChartTypeTabs>
            </TabContext>
          );
        }}
      />
    </Box>
  );
};

LookalikeMediaInsightsChartTypeSelector.displayName =
  "LookalikeMediaInsightsChartTypeSelector";

export default memo(LookalikeMediaInsightsChartTypeSelector);
