import { Typography } from "@mui/material";
import { memo } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { ActivationType } from "types/__generated-new";
import PublisherMediaDataRoomActivation from "../PublisherMediaDataRoomActivation/PublisherMediaDataRoomActivation";
import {
  AdvertiserConsentlessAudiencesTable,
  GenerateConsentlessAudienceBar,
} from "..";

interface ConsentlessMediaDataRoomActivationProps {
  id: string;
}

const AdvertiserConsentlessMediaDataRoomActivation: React.FC<
  ConsentlessMediaDataRoomActivationProps
> = ({ id }) => {
  const { isPublisher, isAdvertiser, isAgency } = usePublishedMediaDataRoom();
  if (isAdvertiser || isAgency) {
    return (
      <>
        <Typography gutterBottom={true} variant="h6">
          Top affinity segments activation
        </Typography>
        <GenerateConsentlessAudienceBar />
        <AdvertiserConsentlessAudiencesTable />
      </>
    );
  }
  if (isPublisher) {
    return (
      <PublisherMediaDataRoomActivation
        activationType={ActivationType.Consentless}
        id={id}
      />
    );
  }
  return <></>;
};

AdvertiserConsentlessMediaDataRoomActivation.displayName =
  "AdvertiserConsentlessMediaDataRoomActivation";

export default memo(AdvertiserConsentlessMediaDataRoomActivation);
