import { memo, type ReactNode, useCallback, useMemo } from "react";
import {
  type DataRoomErrorReportContextValue,
  DataRoomErrorReportProvider,
  useApiCore,
} from "contexts";
import {
  useAuditLogsLazyQuery,
  useDevComputeNodesForReportLazyQuery,
  useRequestsForReportLazyQuery,
  useSubmittedDataRoomRequestsLazyQuery,
} from "hooks/__generated-new";
import { DataRoomType } from "models";

interface PublishedDataRoomErrorReportProps {
  dataRoomId: string;
  driverAttestationHash: string;
  children: ReactNode;
  createdAt: string;
  includeDevelopmentFetching?: boolean;
  includeRequestsFetching?: boolean;
}

const PublishedDataRoomErrorReport: React.FC<
  PublishedDataRoomErrorReportProps
> = ({
  children,
  driverAttestationHash,
  dataRoomId,
  createdAt,
  includeDevelopmentFetching,
  includeRequestsFetching,
}) => {
  const { sessionManager } = useApiCore();
  const [fetchLogs] = useAuditLogsLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      dcrHash: dataRoomId,
      driverAttestationHash,
    },
  });
  const [fetchDevComputeNodes] = useDevComputeNodesForReportLazyQuery({
    variables: { id: dataRoomId },
  });
  const handleDevComputeNodesFetching = useCallback(
    async () =>
      fetchDevComputeNodes().then((value) =>
        JSON.stringify(value.data?.publishedDataRoom.playgrounds.nodes)
      ),
    [fetchDevComputeNodes]
  );
  const [fetchRequests] = useRequestsForReportLazyQuery({
    fetchPolicy: "network-only",
    variables: { id: dataRoomId },
  });
  const handleRequestsFetching = useCallback(
    async () =>
      fetchRequests().then((value) =>
        JSON.stringify(value.data?.publishedDataRoom.requests.nodes)
      ),
    [fetchRequests]
  );
  const [fetchSubmittedRequests] = useSubmittedDataRoomRequestsLazyQuery({
    variables: { id: dataRoomId },
  });
  const handleSubmittedRequestsFetching = useCallback(
    async () =>
      fetchSubmittedRequests().then((value) =>
        JSON.stringify(value.data?.publishedDataRoom.submittedRequests.nodes)
      ),
    [fetchSubmittedRequests]
  );
  const handleRequestsInfoFetching = useCallback(async () => {
    const requests = await handleRequestsFetching();
    const submittedRequests = await handleSubmittedRequestsFetching();

    return JSON.stringify({
      requests,
      submittedRequests,
    });
  }, [handleRequestsFetching, handleSubmittedRequestsFetching]);
  const getDcrHLRepresentation = useCallback(async () => {
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataRoomResponse = await sdkSession.retrieveDataRoom(dataRoomId);
    const dataScienceDcr =
      await sdkSession.constructVerifiedDataScienceDataRoom(dataRoomResponse);
    return dataScienceDcr
      ? JSON.stringify({
          createdAt,
          dataScienceDataRoom: dataScienceDcr,
        })
      : "";
  }, [dataRoomId, driverAttestationHash, sessionManager, createdAt]);
  const getAuditLog = useCallback(
    async () => (await fetchLogs()).data?.auditLog?.log || "",
    [fetchLogs]
  );
  const contextValue = useMemo<DataRoomErrorReportContextValue>(
    () => ({
      dataRoomId,
      dataRoomType: DataRoomType.DataScience,
      getAuditLog,
      getDcrHLRepresentation,
      getDevelopmentInfo: includeDevelopmentFetching
        ? handleDevComputeNodesFetching
        : undefined,
      getRequestsInfo: includeRequestsFetching
        ? handleRequestsInfoFetching
        : undefined,
    }),
    [
      dataRoomId,
      getAuditLog,
      getDcrHLRepresentation,
      includeDevelopmentFetching,
      includeRequestsFetching,
      handleDevComputeNodesFetching,
      handleRequestsInfoFetching,
    ]
  );
  return (
    <DataRoomErrorReportProvider value={contextValue}>
      {children}
    </DataRoomErrorReportProvider>
  );
};

PublishedDataRoomErrorReport.displayName = "PublishedDataRoomErrorReport";

export default memo(PublishedDataRoomErrorReport);
