import { EMAIL_REGEXP } from "constants/index";
import { InlineEditor } from "@decentriq/components";
import { memo, useCallback, useMemo } from "react";
import { useDataRoomParticipants } from "components";
import { usePermissionsVars } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDraftParticipantUpdateUserEmailMutation } from "hooks/__generated-new";
import {
  type DraftParticipant,
  type PublishedParticipant,
} from "types/__generated-new";

interface ParticipantEmailFieldProps {
  participant: DraftParticipant | PublishedParticipant;
}

const ParticipantEmailField: React.FC<ParticipantEmailFieldProps> = memo(
  ({
    participant: { id: participantId = undefined, userEmail = undefined } = {},
  }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { canEditPermissions } = usePermissionsVars();
    const { participants = [] } = useDataRoomParticipants();
    const { participantsEmails, participantEmail } = useMemo(
      () => ({
        participantEmail: userEmail,
        participantsEmails:
          participants.map(({ userEmail }) => userEmail) || [],
      }),
      [participants, userEmail]
    );
    const [draftParticipantUpdateUserEmailMutation] =
      useDraftParticipantUpdateUserEmailMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Participant email could not be updated."
            )
          );
        },
      });
    const handleEmailChange = useCallback(
      (newEmail: string) => {
        const normalizedEmail = newEmail.trim()?.toLowerCase();
        draftParticipantUpdateUserEmailMutation({
          update: (cache) => {
            cache.gc();
          },
          variables: {
            id: participantId || "",
            participantEmail: normalizedEmail,
          },
        });
      },
      [participantId, draftParticipantUpdateUserEmailMutation]
    );
    return (
      <InlineEditor
        cancelEditingButtonEnabled={false}
        fullWidth={true}
        onChange={handleEmailChange}
        placeholder="Participant email"
        readOnly={!canEditPermissions}
        saveEditingButtonEnabled={false}
        saveEditingOnClickAway={true}
        validate={(value: string) => {
          if (
            participantsEmails.some(
              (email) =>
                email.toLowerCase().trim() === value.toLowerCase().trim()
            )
          ) {
            return "Participant email must be unique";
          }
          if (!EMAIL_REGEXP.test(value)) {
            return "Invalid participant email";
          }
          return null;
        }}
        value={participantEmail}
      />
    );
  }
);

ParticipantEmailField.displayName = "ParticipantEmailField";

export default ParticipantEmailField;
