import { memo, type ReactNode, useCallback } from "react";
import { DataRoomErrorReportProvider, useApiCore } from "contexts";
import { fetchPublishedLookalikeMediaDataRoomDefinition } from "features/mediaDataRoomV2/contexts/MediaInsightsDcrContext/queries/usePublishedLookalikeMediaDataRoomDefinition";
import { useAuditLogsLazyQuery } from "hooks/__generated-new";
import { DataRoomType } from "models";

interface MediaDataRoomErrorReportProps {
  dataRoomId: string;
  driverAttestationHash: string;
  createdAt: string;
  children: ReactNode;
}

const MediaDataRoomErrorReport: React.FC<MediaDataRoomErrorReportProps> = ({
  children,
  driverAttestationHash,
  dataRoomId,
  createdAt,
}) => {
  const { sessionManager } = useApiCore();
  const [fetchLogs] = useAuditLogsLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      dcrHash: dataRoomId,
      driverAttestationHash,
    },
  });
  const getDcrHLRepresentation = useCallback(async () => {
    const { definition } = await fetchPublishedLookalikeMediaDataRoomDefinition(
      sessionManager
    )({
      dataRoomId,
      driverAttestationHash,
    });
    return JSON.stringify({
      createdAt,
      lookalikeMediaDataRoom: definition,
    });
  }, [createdAt, dataRoomId, driverAttestationHash, sessionManager]);
  const getAuditLog = useCallback(
    async () => (await fetchLogs()).data?.auditLog?.log || "",
    [fetchLogs]
  );
  return (
    <DataRoomErrorReportProvider
      value={{
        dataRoomId,
        dataRoomType: DataRoomType.LookalikeMedia,
        getAuditLog,
        getDcrHLRepresentation,
      }}
    >
      {children}
    </DataRoomErrorReportProvider>
  );
};

MediaDataRoomErrorReport.displayName = "MediaDataRoomErrorReport";

export default memo(MediaDataRoomErrorReport);
