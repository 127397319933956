import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useMatch } from "react-router-dom";
import {
  DataLabCreateButton,
  DataLabsList,
  DataLabsWrapper,
} from "features/dataLabs";

const DataLabs: React.FC = () => {
  // To be removed when DataLabs page is fully moved to a publisher portal
  const isDataLabsPage = useMatch({
    end: true,
    path: "/datalabs",
  });

  return (
    <DataLabsWrapper>
      <Box
        sx={
          isDataLabsPage
            ? {
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                padding: (theme) => theme.spacing(2, 2.5),
              }
            : { marginBottom: 2, width: "100%" }
        }
      >
        {isDataLabsPage && <Typography variant="h5">Data Labs</Typography>}
        <Box sx={{ marginLeft: "auto", width: "fit-content" }}>
          <DataLabCreateButton />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflow: "hidden",
            padding: ({ spacing }) => (isDataLabsPage ? spacing(2.5) : 0),
          }}
        >
          <DataLabsList />
        </Box>
      </Box>
    </DataLabsWrapper>
  );
};

export default memo(DataLabs);
