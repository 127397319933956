import { type Session } from "@decentriq/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { usePublishedMediaInsightsDcrDataLabDataQuery } from "hooks/__generated-new";
import { type FullDataLabFragment } from "types/__generated-new";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";
import { getOverlapInsightsCacheKey } from "../../../helpers";
import useAdvertiserDataReport from "../../../hooks/useAdvertiserDataReport";
import usePublishedDatasets from "../../../hooks/usePublishedDatasets";
import useQueryAvailableAudiences, {
  type QueryAvailableAudiencesHookResult,
} from "../../../hooks/useQueryAvailableAudiences";
import useQueryInsights, {
  type QueryInsightsHookResult,
} from "../../../hooks/useQueryInsights";
import { type QueryMediaInsightsComputeJobHookResult } from "../../../hooks/useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";
import useQueryOverlapStatistics, {
  type QueryOverlapStatisticsHookResult,
} from "../../../hooks/useQueryOverlapStatistics";
import type {
  AdvertiserDataReportCacheKey,
  OverlapInsightsCacheKey,
  PublisherDatasetsHashes,
} from "../../../models";
import type { PublishedMediaInsightsDcrQueryHookResult } from "./usePublishedMediaDataRoomQuery/usePublishedMediaInsightsDcrQuery";

interface UseMediaInsightsDcrDataQueryQueryPayload {
  publishedMediaDataRoom: PublishedMediaInsightsDcrQueryHookResult;
}

export interface UseMediaInsightsDcrDataQueryResult {
  advertiserDatasetHash: string | null;
  availableAudiences: QueryAvailableAudiencesHookResult;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  datasetsLoading: boolean;
  error?: string;
  hasRequiredData: boolean;
  hasAdvertiserData: boolean;
  hasPublisherData: boolean;
  isPublisherAudienceBeingUpdated: boolean;
  retrieveDatasets: () => Promise<void>;
  insights: QueryInsightsHookResult;
  overlapStatistics: QueryOverlapStatisticsHookResult;
  advertiserDataReport: QueryMediaInsightsComputeJobHookResult<{
    numberOfDeduplicatedRows: number;
    numberOfIngestedRows: number;
  }>;
  session?: Session | null;
  publishedDataLab: FullDataLabFragment | null;
  dataLabDataLoading: boolean;
}

const useMediaInsightsDcrDataQuery = ({
  publishedMediaDataRoom,
}: UseMediaInsightsDcrDataQueryQueryPayload) => {
  const {
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isAdvertiser,
    isAgency,
    isDataPartner,
    isPublisher,
    hasDataPartner,
    // wasDataLabPublishedBefore,
    features: { insights: enableInsights },
  } = publishedMediaDataRoom;
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { sessionManager } = useApiCore();
  const queryClient = useQueryClient();

  const {
    data: dataLabData,
    loading: dataLabDataLoading,
    refetch: refetchDatalabQuery,
  } = usePublishedMediaInsightsDcrDataLabDataQuery({
    skip: !isPublisher,
    variables: {
      id: dataRoomId,
    },
  });

  const wasDataLabPublishedBefore = useMemo(
    () => !!dataLabData?.publishedMediaInsightsDcr.wasDataLabPublishedBefore,
    [dataLabData]
  );

  const publishedDatasetsQuery = usePublishedDatasets({
    dataRoomId,
    driverAttestationHash,
    queryKeyPrefix: ["mi-dcr"],
  });

  const error = useMemo(
    () => publishedDatasetsQuery?.error?.message,
    [publishedDatasetsQuery.error]
  );

  useEffect(() => {
    const error = publishedDatasetsQuery?.error;
    if (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Unable to fetch datasets")
      );
    }
  }, [enqueueSnackbar, publishedDatasetsQuery?.error]);

  const { data: session } = useQuery({
    enabled: driverAttestationHash != null,
    queryFn: async () => {
      return await sessionManager.get({ driverAttestationHash });
    },
    queryKey: ["mi-dcr", "session", driverAttestationHash],
  });

  const { data: key } = useQuery({
    queryFn: async () => {
      const key = await getOverlapInsightsCacheKey(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      );
      return key;
    },
    queryKey: ["mi-dcr", "key", dataRoomId, driverAttestationHash],
  });

  const createCacheKeyString = useCallback(
    async (key: OverlapInsightsCacheKey): Promise<string> => {
      const publishedDatasets =
        (await session?.retrievePublishedDatasets(key.dataRoomId))
          ?.publishedDatasets || [];
      return computeCacheKeyString({
        ...key,
        publishedDatasets,
      });
    },
    [session]
  );

  const insights = useQueryInsights({
    createCacheKeyString,
    key,
    session,
    skip:
      isDeactivated ||
      !publishedDatasetsQuery.data?.hasRequiredData ||
      !enableInsights ||
      isDataPartner,
  });

  const overlapStatistics = useQueryOverlapStatistics({
    createCacheKeyString,
    key,
    session,
    skip: isDeactivated || !publishedDatasetsQuery.data?.hasRequiredData,
  });

  const advertiserDataReportKey = useMemo(():
    | AdvertiserDataReportCacheKey
    | undefined => {
    const advertiserDatasetHash =
      publishedDatasetsQuery.data?.advertiserDatasetHash;
    if (advertiserDatasetHash) {
      return { advertiserDatasetHash, dataRoomId };
    }
    return undefined;
  }, [dataRoomId, publishedDatasetsQuery.data?.advertiserDatasetHash]);

  const advertiserDataReport = useAdvertiserDataReport({
    key: advertiserDataReportKey,
    session,
    skip: isDeactivated || !(hasDataPartner ? isDataPartner : isAdvertiser),
  });

  // TODO imperative refetching shouldn't happen in the first place
  // Facilitate this with cache invalidation in the future
  const handleDatasetsRetrieval = useCallback(async () => {
    const queries: Promise<unknown>[] = [
      publishedDatasetsQuery.refetch(),
      queryClient.invalidateQueries({ queryKey: ["mi-dcr"] }),
    ];
    if (isPublisher) {
      queries.push(refetchDatalabQuery());
    }
    await Promise.all(queries);
  }, [publishedDatasetsQuery, queryClient, refetchDatalabQuery, isPublisher]);

  const availableAudiences = useQueryAvailableAudiences({
    createCacheKeyString,
    key,
    session,
    skip:
      isDeactivated ||
      !publishedDatasetsQuery.data?.hasRequiredData ||
      !(isAdvertiser || isAgency),
  });

  const contextValue = useMemo(
    (): UseMediaInsightsDcrDataQueryResult => ({
      ...publishedDatasetsQuery.data,
      advertiserDataReport,
      availableAudiences,
      dataLabDataLoading,
      datasetsLoading: publishedDatasetsQuery.isLoading,
      error,
      insights,
      isPublisherAudienceBeingUpdated:
        wasDataLabPublishedBefore &&
        !publishedDatasetsQuery.data?.hasPublisherData &&
        !!publishedDatasetsQuery.data?.hasAdvertiserData,
      overlapStatistics,
      publishedDataLab:
        dataLabData?.publishedMediaInsightsDcr?.publishedDataLab ?? null,
      retrieveDatasets: handleDatasetsRetrieval,
      session,
    }),
    [
      publishedDatasetsQuery.data,
      publishedDatasetsQuery.isLoading,
      advertiserDataReport,
      availableAudiences,
      error,
      insights,
      wasDataLabPublishedBefore,
      overlapStatistics,
      dataLabData?.publishedMediaInsightsDcr?.publishedDataLab,
      handleDatasetsRetrieval,
      session,
      dataLabDataLoading,
    ]
  );

  return contextValue;
};

export default useMediaInsightsDcrDataQuery;
