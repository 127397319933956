import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type Theme,
} from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import { type DatasetColumnStatistics } from "features/datasets";
import { dataRoomTablePrimitiveTypePresentation } from "models";

const useStyles = makeStyles()((theme: Theme) => ({
  cell: {
    border: "none",
    padding: "8px !important",
  },
  headCell: {
    color: "inherit",
    padding: "8px !important",
  },
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: "whitesmoke",
    },
  },
}));

interface DatasetSchemaProps {
  columns: DatasetColumnStatistics[];
}

const DatasetSchema: React.FC<DatasetSchemaProps> = ({ columns }) => {
  const { classes: styles } = useStyles();

  if (!columns.length) {
    return (
      <Alert severity="info">Schema is not provided for this dataset</Alert>
    );
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ borderBottom: "1px solid lightgrey", mb: 0.5 }}>
            <TableCell className={styles.headCell} sx={{ minWidth: "250px" }}>
              Column
            </TableCell>
            <TableCell className={styles.headCell}>Type</TableCell>
            <TableCell className={styles.headCell}>
              Allow empty values
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {columns.map(
            ({ column, type, nullable }: DatasetColumnStatistics) => (
              <TableRow className={styles.row} key={column}>
                <TableCell className={styles.cell} sx={{ minWidth: "250px" }}>
                  {column}
                </TableCell>
                <TableCell className={styles.cell}>
                  {dataRoomTablePrimitiveTypePresentation.get(type!)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {nullable && (
                    <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DatasetSchema.displayName = "DatasetSchema";

export default memo(DatasetSchema);
