import { useMemo } from "react";
import { useMediaInsightsDcrData } from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import { type AudienceOverlapStatistics } from "../../models";

type OverlapStatistics = {
  overlapPercentage: string;
  overlapStatistics: AudienceOverlapStatistics;
};

const useOverlapStatistics = (selectedAudience: string): OverlapStatistics => {
  const {
    overlapStatistics: {
      computeResults: { statistics: overlapInsightsStatistics = [] } = {},
    },
  } = useMediaInsightsDcrData();

  const {
    advertiserSize = 0,
    audienceType = "__default__",
    overlapSize = 0,
  } = overlapInsightsStatistics.find(
    ({ audienceType }) => audienceType === selectedAudience
  ) || {};

  const selectedOverlapInsightStatistics = useMemo(
    () => ({
      notMatching: advertiserSize - overlapSize,
      overlap: overlapSize,
      total: advertiserSize,
      type: audienceType,
    }),
    [advertiserSize, audienceType, overlapSize]
  );

  const overlapPercentage = useMemo(() => {
    if (
      !selectedOverlapInsightStatistics?.overlap ||
      !selectedOverlapInsightStatistics?.total
    ) {
      return "0";
    }
    return (
      (selectedOverlapInsightStatistics?.overlap /
        selectedOverlapInsightStatistics?.total) *
      100
    ).toFixed(1);
  }, [
    selectedOverlapInsightStatistics?.overlap,
    selectedOverlapInsightStatistics?.total,
  ]);

  return {
    overlapPercentage,
    overlapStatistics: selectedOverlapInsightStatistics,
  };
};

export default useOverlapStatistics;
