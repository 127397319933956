import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftS3ObjectUploadDependencyQuery,
  usePublishedS3ObjectUploadDependencyQuery,
} from "hooks/__generated-new";
import { type PublishedNode } from "types";
import {
  type DraftNode,
  type DraftNodeConnection,
  type DraftS3SinkNode,
  type PublishedS3SinkNode,
} from "types/__generated-new";

const useS3ObjectUploadDependency = (
  computeNodeId: string
): {
  node: DraftS3SinkNode | PublishedS3SinkNode | undefined;
  uploadDependency: PublishedNode | DraftNode | undefined;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftS3ObjectUploadDependencyQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedS3ObjectUploadDependencyQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  const publishedNode = publishedData?.publishedNode as
    | PublishedS3SinkNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftS3SinkNode | undefined;
  return {
    node: shouldUseDraft ? draftNode : publishedNode,
    uploadDependency: shouldUseDraft
      ? (draftNode?.uploadDependency as DraftNodeConnection)?.draftNode
      : publishedNode?.uploadDependency,
  };
};

export default useS3ObjectUploadDependency;
