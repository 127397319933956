import { export_dataset } from "@decentriq/core";
import { Key } from "@decentriq/utils";
import * as forge from "node-forge";
import { KeychainItemKind, type KeychainService } from "services/keychain";
import { type ApiCoreContextInterface } from "contexts";
import {
  type CreateDatasetExportPayload,
  type DataImportExportStatus,
  DatasetExportDocument,
  DatasetExportFragment,
  type DatasetExportQuery,
  type DatasetExportQueryVariables,
  type DatasetExportResult,
  DatasetExportResultFragment,
  DatasetExportsDocument,
  type DatasetExportsQuery,
  type DatasetExportsQueryVariables,
  type MutationCreateDatasetExportArgs,
  type MutationPollDatasetExportArgs,
} from "types/__generated-new";
import { getLatestEnclaveSpecsPerType } from "utils/apicore";
import { type LocalResolverContext } from "../../../models";

export const makeCreateDatasetExportResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    keychain: KeychainService
  ) =>
  async (
    _obj: null,
    args: MutationCreateDatasetExportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<CreateDatasetExportPayload> => {
    const {
      dv360,
      s3,
      meta,
      gcs,
      googleAdManager,
      azure,
      permutive,
      manifestHash,
    } = args.input;

    const encryptionKeyPayload = await keychain.getItem(
      manifestHash,
      KeychainItemKind.Dataset
    );
    if (!encryptionKeyPayload.item) {
      throw new Error(
        `No encryption key in keychain for dataset with manifest hash '${manifestHash}'`
      );
    }
    const encryptionKey = new Key(
      forge.util.binary.hex.decode(encryptionKeyPayload.item.value)
    );

    let enclaveSpecifications;
    let enclaveSpecs;

    try {
      enclaveSpecifications = await client.getEnclaveSpecifications();
      enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
    } catch (error) {
      throw new Error(error as string);
    }

    let datasetExportId;

    if (s3 !== undefined) {
      const { credentials, targetConfig } = s3!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetS3(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          encryptionKey,
          manifestHash,
          targetConfig: {
            bucket: targetConfig.bucket,
            key: targetConfig.objectKey,
            region: targetConfig.region,
          },
        }
      );
    } else if (meta !== undefined) {
      const { accessToken, adAccountId, audienceName } = meta!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetMeta(
        client,
        session,
        enclaveSpecs,
        {
          accessToken,
          adAccountId,
          audienceName,
          encryptionKey,
          manifestHash,
        }
      );
    } else if (dv360 !== undefined) {
      const {
        advertiserId,
        displayName,
        description,
        membershipDurationDays,
        credentials,
      } = dv360!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetDv360(
        client,
        session,
        enclaveSpecs,
        {
          advertiserId,
          credentials,
          description,
          displayName,
          encryptionKey,
          manifestHash,
          membershipDurationDays,
        }
      );
    } else if (azure !== undefined) {
      const { credentials } = azure!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetAzure(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          encryptionKey,
          manifestHash,
        }
      );
    } else if (googleAdManager !== undefined) {
      const {
        credentials,
        identifierKind,
        listId,
        inputHasHeader,
        bucket,
        objectName,
      } = googleAdManager!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetGoogleAdManager(
        client,
        session,
        enclaveSpecs,
        {
          bucket,
          credentials,
          encryptionKey,
          identifierKind,
          inputHasHeader,
          listId,
          manifestHash,
          objectName,
        }
      );
    } else if (gcs !== undefined) {
      const { credentials, bucketName, objectName } = gcs!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetGCS(
        client,
        session,
        enclaveSpecs,
        {
          bucketName,
          credentials,
          encryptionKey,
          manifestHash,
          objectName,
        }
      );
    } else if (permutive !== undefined) {
      const {
        credentials,
        importId,
        segmentName,
        segmentCode,
        aws,
        gcs,
        inputHasHeader,
      } = permutive!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetPermutive(
        client,
        session,
        enclaveSpecs,
        {
          aws:
            aws !== undefined
              ? {
                  credentials: aws!.credentials,
                  targetConfig: {
                    bucket: aws!.targetConfig.bucket,
                    key: aws!.targetConfig.objectKey,
                    region: aws!.targetConfig.region,
                  },
                }
              : undefined,
          credentials,
          encryptionKey,
          gcs: gcs ?? undefined,
          importId,
          inputHasHeader,
          manifestHash,
          segmentCode,
          segmentName,
        }
      );
    } else {
      throw new Error("No target type configured");
    }

    const response = await context.client.query<
      DatasetExportQuery,
      DatasetExportQueryVariables
    >({
      query: DatasetExportDocument,
      variables: {
        id: datasetExportId,
      },
    });
    const newDatasetExport = response.data.datasetExport;

    const exportsQuery = context.cache.readQuery<
      DatasetExportsQuery,
      DatasetExportsQueryVariables
    >({
      query: DatasetExportsDocument,
      variables: { filter: null },
    });

    const currentExports = exportsQuery
      ? exportsQuery!.datasetExports?.nodes
      : [];
    context.cache.writeQuery<DatasetExportsQuery, DatasetExportsQueryVariables>(
      {
        data: {
          datasetExports: {
            __typename: "DatasetExportCollection",
            nodes: [newDatasetExport, ...currentExports],
          },
        },
        query: DatasetExportsDocument,
        variables: {
          filter: null,
        },
      }
    );

    context.cache.modify({
      fields: {
        datasetExports: (existing = {}) => {
          const datasetExportRef = context.cache.writeFragment({
            data: newDatasetExport,
            fragment: DatasetExportFragment,
          });
          return {
            ...existing,
            nodes: [datasetExportRef, ...(existing?.nodes || [])],
          };
        },
      },
    });

    return {
      datasetExport: { ...newDatasetExport },
    };
  };

export const makePollDatasetExportResolver =
  (
    client: ApiCoreContextInterface["client"],
    _sessionManager: ApiCoreContextInterface["sessionManager"],
    keychain: KeychainService
  ) =>
  async (
    _obj: null,
    args: MutationPollDatasetExportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<DatasetExportResult> => {
    const datasetExportId = args.id;

    const enclaveSpecifications = await client.getEnclaveSpecifications();
    const enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
    const [auth] = await client.createAuthUsingDecentriqPki(enclaveSpecs);

    let error: string | null = null;
    let success = false;

    try {
      await export_dataset.getDatasetExportResult(
        datasetExportId,
        client,
        auth,
        {
          additionalEnclaveSpecs: enclaveSpecifications,
        }
      );
      success = true;
    } catch (e) {
      success = false;
      error = e.message;
    }

    // Fetch finishedAt value
    const response = await context.client.query<
      DatasetExportQuery,
      DatasetExportQueryVariables
    >({
      fetchPolicy: "network-only",
      query: DatasetExportDocument,
      variables: {
        id: datasetExportId,
      },
    });

    const { finishedAt } = response?.data?.datasetExport || {};
    const result = { error, success };

    // Update cached DatasetExport with result and status field
    context.client.writeFragment({
      data: {
        finishedAt,
        result,
        status: (result.success
          ? "SUCCESS"
          : "FAILED") as DataImportExportStatus,
      },
      fragment: DatasetExportResultFragment,
      id: context.cache.identify({
        __typename: "DatasetExport",
        id: datasetExportId,
      }),
    });

    return result;
  };
