import { useState } from "react";
import { usePublishDraftDataRoomMutation } from "hooks/__generated-new";
import { MyDataRoomsDocument } from "types/__generated-new";

interface useDraftDataRoomPublishArgs {
  id: string;
  password?: string;
}

interface useDraftDataRoomPublishResult {
  draftDataRoomPublish: (
    validate: boolean,
    keepTestData: boolean
  ) => Promise<string | undefined>;
  draftDataRoomPublishLoading: boolean;
}

const useDraftDataRoomPublish = ({
  id,
  password,
}: useDraftDataRoomPublishArgs): useDraftDataRoomPublishResult => {
  const [publishDraftDataRoomMutation] = usePublishDraftDataRoomMutation({
    refetchQueries: [{ query: MyDataRoomsDocument }],
  });
  const [publishing, setPublishing] = useState(false);
  const draftDataRoomPublish = async (
    validate: boolean,
    keepTestData: boolean
  ) => {
    setPublishing(true);
    try {
      const publishedDataRoomResult = await publishDraftDataRoomMutation({
        variables: {
          input: {
            id,
            keepTestData,
            password,
            validate,
          },
        },
      });
      return publishedDataRoomResult.data?.publishDraftDataRoom.id;
    } finally {
      setPublishing(false);
    }
  };
  return {
    draftDataRoomPublish,
    draftDataRoomPublishLoading: publishing,
  };
};

export default useDraftDataRoomPublish;
