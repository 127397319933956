import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { xor } from "lodash";
import groupBy from "lodash/groupBy";
import { memo, useCallback } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useAddScriptingNodeDependencyMutation,
  useRemoveScriptingNodeDependencyMutation,
} from "hooks/__generated-new";
import {
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
} from "models";
import { ScriptingLanguage } from "types/__generated-new";
import useScriptingComputeNode from "../../useScriptingComputeNode";
import useScriptingNodeInputs from "../../useScriptingNodeInputs/useScriptingNodeInputs";
import OutputFolderEditor from "../OutputFolderEditor/OutputFolderEditor";

const SHOW_PYTHON_OUTPUT = false;
const SHOW_R_OUTPUT = false;

const scriptingInputsEditorConfig = new Map<ScriptingLanguage, React.ReactNode>(
  [
    [
      ScriptingLanguage.Python,
      <span>
        Tables, files and the results of other computations selected in the
        dropdown can be loaded as files.
        <br />
        <br />
        To read tabular results, e.g. TABLE, SQL, JOIN, SYNTHETIC:
        <br />
        <code>
          df_table =
          decentriq_util.read_tabular_data("/input/table_or_computation_name")
        </code>
        <br />
        <br />
        To read any file or unstructured data, use the absolute paths:
        <br />
        <code>f = open("/input/computation_name/file.json", "r")</code>
        <br />
        <br />
        Hint: Copy snippets for each input file to the clipboard directly from
        the file browser.
        <br />
      </span>,
    ],
    [
      ScriptingLanguage.R,
      <span>
        Tables, files and the results of other computations selected in the
        dropdown can be loaded as files.
        <br />
        Use the absolute paths, for example:
        <br />
        <code>"/input/table_name/dataset.csv"</code>
      </span>,
    ],
  ]
);

interface InputsEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const InputsEditor = memo<InputsEditorProps>(({ computeNodeId, readOnly }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId } = useDataRoom();
  // Fetch scripting compute node
  const { scriptingLanguage, dependencies } =
    useScriptingComputeNode(computeNodeId);
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const scriptingNodeAvailableInputs = useScriptingNodeInputs(computeNodeId);
  const groupedInputList = groupBy(scriptingNodeAvailableInputs, "typename");
  const scriptingNodeDependenciesIds = dependencies?.map((node) => node.id);
  const availableDataTooltip = scriptingInputsEditorConfig.get(
    scriptingLanguage!
  );
  // Changing dependencies
  const [addScriptingNodeDependencyMutation, { loading: adding }] =
    useAddScriptingNodeDependencyMutation();
  const addScriptingNodeDependency = useCallback(
    async (dependencyId: string) => {
      try {
        return addScriptingNodeDependencyMutation({
          variables: {
            computeNodeId,
            dependencyId: isInteractivityContext
              ? {
                  published: {
                    computeNodeId: dependencyId,
                    publishedDataRoomId: dataRoomId,
                  },
                }
              : { draft: dependencyId },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The script input data could not be added."
          )
        );
        throw error;
      }
    },
    [
      enqueueSnackbar,
      computeNodeId,
      addScriptingNodeDependencyMutation,
      isInteractivityContext,
      dataRoomId,
    ]
  );
  const [removeScriptingNodeDependencyMutation, { loading: removing }] =
    useRemoveScriptingNodeDependencyMutation();
  const removeScriptingNodeDependency = useCallback(
    async (dependencyId: string) => {
      try {
        return removeScriptingNodeDependencyMutation({
          variables: {
            computeNodeId,
            dependencyId: isInteractivityContext
              ? {
                  published: {
                    computeNodeId: dependencyId,
                    publishedDataRoomId: dataRoomId,
                  },
                }
              : { draft: dependencyId },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The script input data could not be removed."
          )
        );
        throw error;
      }
    },
    [
      enqueueSnackbar,
      dataRoomId,
      computeNodeId,
      removeScriptingNodeDependencyMutation,
      isInteractivityContext,
    ]
  );
  const updateDependency = useCallback(
    (dependencyId: string) => {
      if (scriptingNodeDependenciesIds?.includes(dependencyId)) {
        removeScriptingNodeDependency(dependencyId);
      } else {
        scriptingNodeDependenciesIds?.push(dependencyId);
        addScriptingNodeDependency(dependencyId);
      }
    },
    [
      removeScriptingNodeDependency,
      scriptingNodeDependenciesIds,
      addScriptingNodeDependency,
    ]
  );
  const loading = adding || removing;
  const disabled = readOnly || loading;
  return (
    <Box
      position="relative"
      sx={({ spacing }) => ({ margin: `-2px 0 ${spacing(1)}` })}
    >
      <FormControl fullWidth={true}>
        <FormLabel component="legend" sx={{ display: "flex" }}>
          Available data (read-only):
          <InfoTooltip
            IconButtonProps={{
              sx: { "&:hover": { "&:after": { content: "none" } } },
            }}
            relaxed={true}
            tooltip={<>{availableDataTooltip}</>}
          />
        </FormLabel>
        <Select
          data-testid={testIds.computeNode.computeNodeCreator.inputEditorLabel}
          displayEmpty={true}
          fullWidth={true}
          multiple={true}
          onChange={(event) => {
            if (!disabled) {
              const { value } = event.target;
              const effectiveValue =
                typeof value === "string"
                  ? value.split(",")
                  : (value as string[]);
              const diff = xor(
                effectiveValue,
                scriptingNodeDependenciesIds || []
              );
              const focusedValue = diff[0];
              if (focusedValue) {
                updateDependency(diff[0]);
              }
            }
          }}
          renderValue={(selected) => {
            const selectedNodes = scriptingNodeAvailableInputs.filter(
              ({ computeNodeId }) => selected.includes(computeNodeId)
            );
            return selectedNodes.length === 1
              ? selectedNodes[0]?.label
              : `${selectedNodes.length} input files`;
          }}
          size="small"
          sx={{ background: "transparent" }}
          value={scriptingNodeDependenciesIds || []}
          variant="standard"
        >
          {Object.keys(groupedInputList)
            .map((groupKey) => [
              <ListSubheader
                key={`${groupKey}-header`}
                style={{ lineHeight: 1.6 }}
              >
                {
                  scriptingNodeGroupLabelsMap[
                    groupKey as ScriptingInputNodeSnapshot["typename"]
                  ]
                }
              </ListSubheader>,
              ...groupedInputList[groupKey].map(
                ({ computeNodeId, name, allowedForCurrent }) => (
                  <MenuItem
                    data-testid={`${testIds.computeNode.computeNodeCreator.selectHelper}${name}`}
                    dense={true}
                    disabled={!allowedForCurrent}
                    key={`${groupKey}-${computeNodeId}`}
                    value={computeNodeId}
                  >
                    <Checkbox
                      checked={scriptingNodeDependenciesIds?.includes(
                        computeNodeId
                      )}
                      disabled={readOnly || !allowedForCurrent}
                      size="small"
                    />
                    <ListItemText
                      primary={`${name} ${
                        allowedForCurrent
                          ? ""
                          : "(To run on this data, create a request)"
                      }`}
                    />
                  </MenuItem>
                )
              ),
            ])
            .flat()}
        </Select>
      </FormControl>
      {(SHOW_PYTHON_OUTPUT || SHOW_R_OUTPUT) && (
        <OutputFolderEditor computeNodeId={computeNodeId} />
      )}
    </Box>
  );
});

InputsEditor.displayName = "InputsEditor";

export default InputsEditor;
