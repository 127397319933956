import { EMAIL_REGEXP, PASSWORD_REGEXP } from "constants/index";
import {
  faArrowUpFromLine,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import * as yup from "yup";
import { AdminDialog } from "components";
import {
  CommonSnackbarOrigin,
  useFileToBase64,
  useGeneralSnackbar,
} from "hooks";
import { useCreateDemoUserMutation } from "hooks/__generated-new";
import { UserFragment, UserRole } from "types/__generated-new";

type AddDemoUserDialogProps = {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
};

const addDemoUserValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(EMAIL_REGEXP, "Email is invalid"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      PASSWORD_REGEXP,
      "Password must contain at least 8 characters, including uppercase, lowercase and numbers."
    ),
  repeatPassword: yup
    .string()
    .required("Repeat password field is required")
    .oneOf([yup.ref("password")], `Passwords doesn't match`),
});

const AddDemoUserDialog: React.FC<AddDemoUserDialogProps> = ({
  organizationId,
  open,
  onCancel,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const { handleSubmit, control, reset, watch, setValue, ...restForm } =
    useForm({
      defaultValues: {
        email: "",
        logo: "",
        password: "",
        repeatPassword: "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(addDemoUserValidationSchema),
    });
  const { isValid } = useFormState({ control });
  const logo = watch("logo");

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const fileToBase64 = useFileToBase64();

  const [createDemoUser, { loading }] = useCreateDemoUserMutation({
    onCompleted: () => {
      enqueueSnackbar(`Demo user has been successfully created.`);
      onCancel();
    },
    onError: (error) =>
      enqueueSnackbar(`Demo user could not be created.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      }),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          users: (existing = {}) => {
            const userRef = cache.writeFragment({
              data: data?.organization?.createDemoUser?.record,
              fragment: UserFragment,
            });
            return {
              ...existing,
              nodes: [userRef, ...(existing?.nodes || [])],
              totalCount: Math.max((existing?.totalCount || 0) + 1, 0),
            };
          },
        },
        id: cache.identify({
          __typename: "Organization",
          id: organizationId,
        }),
      });
    },
  });

  const handleCreateDemoUser = useCallback(() => {
    const { email, password, logo } = restForm.getValues();
    createDemoUser({
      variables: {
        input: {
          email,
          logo,
          password,
          userRole: UserRole.NormalUser,
        },
      },
    });
  }, [createDemoUser, restForm]);

  useEffect(() => {
    // Clean fields whenever modal is closed
    reset();
  }, [open, reset]);

  const handleFileSelect = useCallback(
    async ({
      target: {
        validity: { valid },
        files,
      },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (!valid) return;
      const image = await fileToBase64(files![0]);
      setValue("logo", image as string);
    },
    [fileToBase64, setValue]
  );

  const handleShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );
  const handleShowRepeatPassword = useCallback(
    () => setShowRepeatPassword(!showRepeatPassword),
    [showRepeatPassword]
  );

  return (
    <AdminDialog
      disabled={!isValid}
      loading={loading}
      onClose={onCancel}
      onConfirm={handleSubmit(handleCreateDemoUser)}
      open={open}
      title="Create demo user"
    >
      <form>
        <Box sx={{ alignItems: "center", display: "flex", marginBottom: 1 }}>
          {logo && (
            <img
              alt="Add user logo"
              src={`data:image;base64,${logo}`}
              style={{ height: 56, marginRight: 16 }}
            />
          )}
          <Controller
            control={control}
            name="logo"
            render={() => {
              return (
                <Button
                  color="inherit"
                  component="label"
                  startIcon={<FontAwesomeIcon icon={faArrowUpFromLine} />}
                  sx={{ height: "fit-content" }}
                  variant="text"
                >
                  Upload new logo
                  <input
                    accept="image/png, image/jpeg, image/gif"
                    hidden={true}
                    id="file-button"
                    multiple={false}
                    onChange={handleFileSelect}
                    type="file"
                  />
                </Button>
              );
            }}
          />
        </Box>
        <Controller
          control={control}
          name="email"
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <TextField
                InputProps={{
                  sx: {
                    "& .MuiInput-input": { padding: "7px 0" },
                    "&:before": { borderBottomStyle: "solid" },
                  },
                }}
                error={!isEmpty(errors["email"])}
                fullWidth={true}
                helperText={errors["email"]?.message}
                placeholder="Email"
                size="small"
                sx={{ mb: 1 }}
                variant="standard"
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="password"
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={handleShowPassword}>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    "& .MuiInput-input": { padding: "7px 0" },
                    "&:before": { borderBottomStyle: "solid" },
                  },
                }}
                error={!isEmpty(errors["password"])}
                fullWidth={true}
                helperText={errors["password"]?.message}
                placeholder="Password"
                size="small"
                sx={{ mb: 1 }}
                type={showPassword ? "text" : "password"}
                variant="standard"
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="repeatPassword"
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={handleShowRepeatPassword}>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={showRepeatPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    "& .MuiInput-input": { padding: "7px 0" },
                    "&:before": { borderBottomStyle: "solid" },
                  },
                }}
                error={!isEmpty(errors["repeatPassword"])}
                fullWidth={true}
                helperText={errors["repeatPassword"]?.message}
                placeholder="Repeat password"
                size="small"
                sx={{ mb: 1 }}
                type={showRepeatPassword ? "text" : "password"}
                variant="standard"
                {...field}
              />
            );
          }}
        />
      </form>
    </AdminDialog>
  );
};

AddDemoUserDialog.displayName = "AddDemoUserDialog";

export default memo(AddDemoUserDialog);
