import {
  faBullhorn,
  faDoorOpen,
  faEye,
  faLink,
  faNewspaper,
  faUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { memo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { usePublishedLookalikeMediaDataRoom } from "features/mediaDataRoomV2/contexts";
import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "types/__generated-new";

const ReviewStepIcon = styled(FontAwesomeIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
}));

type LookalikeMediaDataRoomSummaryProps = {
  open: boolean;
  id: string;
  driverAttestationHash: string;
};

const LookalikeMediaDataRoomSummary: React.FC<
  LookalikeMediaDataRoomSummaryProps
> = ({ id: dataRoomId, driverAttestationHash }) => {
  const { definition, loading } = usePublishedLookalikeMediaDataRoom();
  const {
    name,
    publisherEmails = [],
    advertiserEmails = [],
    observerEmails = [],
    agencyEmails = [],
    matchingIdFormat = "STRING",
    hashMatchingIdWith = null,
  } = definition ?? {};

  if (loading) {
    return <CircularProgress color="inherit" size={32} thickness={1} />;
  }

  return (
    <Grid container={true}>
      <Grid container={true} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faDoorOpen} />
          <Typography>Name</Typography>
        </Grid>
        <Grid xs={9}>{name || "—"}</Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faLink} />
          <Typography>Matching ID</Typography>
        </Grid>
        <Grid xs={9}>
          {matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat: matchingIdFormat as MatchingColumnFormat,
            matchingIdHashingAlgorithm:
              hashMatchingIdWith as TableColumnHashingAlgorithm,
          })}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faNewspaper} />
          <Typography>Publisher</Typography>
        </Grid>
        <Grid xs={9}>
          {publisherEmails.length ? publisherEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faBullhorn} />
          <Typography>Advertiser</Typography>
        </Grid>
        <Grid xs={9}>
          {advertiserEmails.length ? advertiserEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faEye} />
          <Typography>Observer</Typography>
        </Grid>
        <Grid xs={9}>
          {observerEmails.length ? observerEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={3}>
          <ReviewStepIcon fixedWidth={true} icon={faUserTie} />
          <Typography>Agency</Typography>
        </Grid>
        <Grid xs={9}>
          {agencyEmails.length ? agencyEmails.join(", ") : "—"}
        </Grid>
      </Grid>
    </Grid>
  );
};

LookalikeMediaDataRoomSummary.displayName = "LookalikeMediaDataRoomSummary";

export default memo(LookalikeMediaDataRoomSummary);
