import { useCallback } from "react";
import {
  usePublishedDataRoomStopDataLazyQuery,
  usePublishedLookalikeMediaDataRoomStopDataLazyQuery,
  usePublishedMediaDataRoomStopDataLazyQuery,
  usePublishedMediaInsightsDcrStopDataLazyQuery,
  useStopDataRoomMutation,
} from "hooks/__generated-new";
import { DataRoomTypeNames } from "models";

interface usePublishedDataRoomStopArgs {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
}

interface usePublishedDataRoomStopResult {
  stopDataRoomMutation: () => Promise<string | undefined>;
  isDataRoomStopLoading: boolean;
}

const usePublishedDataRoomStop = ({
  dataRoomId,
  __typename,
}: usePublishedDataRoomStopArgs): usePublishedDataRoomStopResult => {
  const [fetchDataScienceDcr] = usePublishedDataRoomStopDataLazyQuery({
    variables: {
      id: dataRoomId,
    },
  });
  const [fetchMediaDcr] = usePublishedMediaDataRoomStopDataLazyQuery({
    variables: {
      id: dataRoomId,
    },
  });
  const [fetchLookalikeMediaDcr] =
    usePublishedLookalikeMediaDataRoomStopDataLazyQuery({
      variables: {
        id: dataRoomId,
      },
    });
  const [fetchMediaInsightsMediaDcr] =
    usePublishedMediaInsightsDcrStopDataLazyQuery({
      variables: {
        id: dataRoomId,
      },
    });
  const getDataRoom = useCallback(async () => {
    switch (__typename) {
      case DataRoomTypeNames.PublishedDataRoom:
        return await fetchDataScienceDcr().then(
          ({ data }) => data?.publishedDataRoom!
        );
      case DataRoomTypeNames.PublishedMediaDataRoom:
        return await fetchMediaDcr().then(
          ({ data }) => data?.publishedMediaDataRoom!
        );
      case DataRoomTypeNames.PublishedLookalikeMediaDataRoom:
        return await fetchLookalikeMediaDcr().then(
          ({ data }) => data?.publishedLookalikeMediaDataRoom!
        );
      case DataRoomTypeNames.PublishedMediaInsightsDcr:
        return await fetchMediaInsightsMediaDcr().then(
          ({ data }) => data?.publishedMediaInsightsDcr!
        );
      default:
        throw new Error("Unhandled data room type");
    }
  }, [
    __typename,
    fetchDataScienceDcr,
    fetchMediaDcr,
    fetchLookalikeMediaDcr,
    fetchMediaInsightsMediaDcr,
  ]);
  const [mutation, { loading: isDataRoomStopLoading }] =
    useStopDataRoomMutation({
      update: (cache) => {
        const now = new Date().toISOString();
        cache.modify({
          fields: {
            deactivatedAt: () => now,
            isActive: () => false,
            isStopped: () => true,
            stoppedAt: () => now,
          },
          id: cache.identify({
            __typename,
            id: dataRoomId,
          }),
        });
      },
    });
  const stopDataRoomMutation = useCallback(
    () =>
      getDataRoom().then((dataRoom) =>
        mutation({
          variables: {
            input: {
              dcrHash: dataRoom!.id,
              driverAttestationHash: dataRoom!.driverAttestationHash,
            },
          },
        }).then((v) => v.data?.stopDataRoom.id)
      ),
    [mutation, getDataRoom]
  );
  return {
    isDataRoomStopLoading,
    stopDataRoomMutation,
  };
};

export default usePublishedDataRoomStop;
