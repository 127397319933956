import { InfoTooltip } from "@decentriq/components";
import { faFileCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Slider,
  Stack,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { getAudiencePresentation, sortAudiences } from "features/mediaDataRoom";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useAudienceTypesQuery,
  useGenerateConsentlessAudienceMutation,
} from "hooks/__generated-new";
import { ConsentlessAudiencesDocument } from "types/__generated-new";

const GenerateConsentlessAudienceBar: React.FC = () => {
  const { dcrHash, driverAttestationHash } = usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [selectedAudienceTypes, selectAudienceTypes] = useState<string[]>([]);
  const [precision, setPrecision] = useState<number>(1);
  const {
    data: audienceTypesData,
    loading: audienceTypesLoading,
    error,
  } = useAudienceTypesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });
  const audienceTypes = useMemo(
    () =>
      sortAudiences(
        audienceTypesData?.mediaRetrieveAudienceTypes?.audienceTypes || []
      ),
    [audienceTypesData]
  );
  useEffect(() => {
    selectAudienceTypes([audienceTypes[0]]);
  }, [audienceTypes]);
  const handleSelectAudienceType = useCallback(
    (event: SelectChangeEvent<string>) => {
      selectAudienceTypes([event.target.value]);
    },
    []
  );
  const [generateConsentlessAudienceMutation, { loading: generating }] =
    useGenerateConsentlessAudienceMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Audience could not be generated."
          )
        );
      },
      refetchQueries: [
        {
          query: ConsentlessAudiencesDocument,
          variables: {
            id: dcrHash,
            input: {
              dataRoomId: dcrHash,
              driverAttestationHash,
            },
          },
        },
      ],
    });
  const mediaGenerateConsentlessAudience = useCallback(() => {
    generateConsentlessAudienceMutation({
      variables: {
        input: {
          audienceTypes: selectedAudienceTypes,
          dataRoomId: dcrHash,
          driverAttestationHash,
          precision: precision / 100,
        },
      },
    });
  }, [
    generateConsentlessAudienceMutation,
    driverAttestationHash,
    dcrHash,
    precision,
    selectedAudienceTypes,
  ]);
  if ((audienceTypesLoading && !audienceTypesData) || error) {
    return <></>;
  }
  return (
    <Box mb={2} mt={1}>
      <Grid container={true} spacing={2}>
        <Grid alignItems="center" display="flex" sx={{ height: 70 }} xs="auto">
          <FormControl sx={{ maxWidth: "350px", minWidth: "350px" }}>
            <FormLabel component="legend">Advertiser audience</FormLabel>
            <Select
              disabled={audienceTypes.length <= 2}
              displayEmpty={true}
              fullWidth={true}
              onChange={handleSelectAudienceType}
              renderValue={getAudiencePresentation}
              size="small"
              sx={{
                ...(audienceTypes.length <= 2
                  ? {
                      "& .MuiSelect-icon": { display: "none" },
                      "&.Mui-disabled": { background: "white" },
                    }
                  : {}),
                background: "transparent",
              }}
              value={selectedAudienceTypes?.[0] || ""}
              variant="standard"
            >
              {audienceTypes.map((audience) => (
                <MenuItem key={audience} value={audience}>
                  <ListItemText primary={getAudiencePresentation(audience)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          alignItems="center"
          display="flex"
          sx={{ height: 70, ml: 2, mr: 2 }}
          xs="auto"
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={4}
            sx={{ flex: 1 }}
          >
            <Box>Precision</Box>
            <Slider
              defaultValue={1}
              marks={[
                { label: "1%", value: 1 },
                { label: "30%", value: 30 },
              ]}
              max={30}
              min={1}
              onChange={(event, newPrecision: number | number[]) =>
                setPrecision(newPrecision as number)
              }
              size="small"
              step={1}
              sx={{
                "& .MuiSlider-markLabel": { top: "1.5rem" },
                minWidth: 200,
              }}
              value={precision}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
            />
            <Box>Reach</Box>
          </Stack>
          <InfoTooltip tooltip="The generated top affinity audiences will include segments until the estimated reach is achieved." />
        </Grid>
        <Grid
          alignItems="flex-end"
          display="flex"
          sx={{ height: 70 }}
          xs="auto"
        >
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            placement="top"
            title="This will generate an audience with segments for activation based on the selected Advertiser audience. Segments are ranked by the highest affinity ratio. Users in this audience belong to at least one segment."
          >
            <LoadingButton
              color="primary"
              disabled={!selectedAudienceTypes.length || generating}
              loading={generating}
              loadingPosition="start"
              onClick={mediaGenerateConsentlessAudience}
              startIcon={<FontAwesomeIcon icon={faFileCirclePlus} />}
              variant="contained"
            >
              Generate
            </LoadingButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

GenerateConsentlessAudienceBar.displayName = "GenerateConsentlessAudienceBar";

export default memo(GenerateConsentlessAudienceBar);
