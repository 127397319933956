import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftDataRoomNameQuery,
  usePublishedDataRoomNameQuery,
} from "hooks/__generated-new";

const useDataRoomName = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isPublished } = useDataRoom();
  // Fetch draft data room name
  const { data: draftData, loading: isDraftDataRoomNameLoading } =
    useDraftDataRoomNameQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Data clean room name could not be retrieved."
          )
        );
      },
      skip: isPublished,
      variables: { dataRoomId },
    });
  // Fetch published data room name
  const { data: publishedData, loading: isPublishedDataRoomNameLoading } =
    usePublishedDataRoomNameQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Data clean room name could not be retrieved."
          )
        );
      },
      skip: !isPublished,
      variables: { dataRoomId },
    });

  const dataRoomName = isPublished
    ? publishedData?.publishedDataRoom?.name
    : draftData?.draftDataRoom?.name;

  return {
    isLoading:
      !dataRoomName ||
      isPublishedDataRoomNameLoading ||
      isDraftDataRoomNameLoading,
    isPublished,
    name: dataRoomName,
  };
};

export default useDataRoomName;
