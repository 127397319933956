import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

interface DeprovisionConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDeprovision: () => Promise<void>;
}

const DeprovisionConfirmationDialog: React.FC<
  DeprovisionConfirmationDialogProps
> = ({ open, onCancel, onDeprovision }) => {
  const [deprovisioning, setDeprovisioning] = useState(false);
  const handleDeprovision = useCallback(async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
      onCancel();
    } finally {
      setDeprovisioning(false);
    }
  }, [setDeprovisioning, onDeprovision, onCancel]);
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} role="delete-dialog">
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>
            Are you sure you want to deprovision the advertiser audience data?
          </strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          Deprovisoning this data means it is no longer available to this clean
          room. This will delete any insights and the dashboard will no longer
          be viewable. Any audiences generated through retargeting or lookalike
          modeling will no longer be available to export. Previously exported
          audiences will still be available.
          <br />
          <br />
          If you provision new data to this clean room, any insights or
          audiences will be recalculated. This may take some time, especially if
          using a lookalike audience, as the model needs to be retrained.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={deprovisioning} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          disabled={deprovisioning}
          loading={deprovisioning}
          onClick={handleDeprovision}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Deprovision
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeprovisionConfirmationDialog.displayName = "DeprovisionConfirmationDialog";

export default DeprovisionConfirmationDialog;
