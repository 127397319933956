import { type Reference } from "@apollo/client";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import {
  useEditUserOrganizationMutation,
  useOrganizationsQuery,
  useUserDetailsQuery,
} from "hooks/__generated-new";
import { type Organization, UserRole } from "types/__generated-new";

type UserOrganizationFieldProps = {
  userId: string;
  closeUserDetailsDialog: () => void;
};

const UserOrganizationField: React.FC<UserOrganizationFieldProps> = ({
  userId,
  closeUserDetailsDialog,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdminReadOnly } = useUserRole();

  const { data: { user } = {}, loading } = useUserDetailsQuery({
    variables: { userId },
  });
  const { data: organizationsData } = useOrganizationsQuery();

  const organizationsList = (organizationsData?.organizations?.nodes || [])
    // array is frozen in strict mode so it has to be copied before sorting
    .slice()
    .sort((a, b) => a?.name?.localeCompare(b?.name)) as Organization[];

  const [editUserOrganizationMutation] = useEditUserOrganizationMutation({
    onCompleted: () => {
      enqueueSnackbar(
        `User has been successfully assigned to the organization.`
      );
      closeUserDetailsDialog();
    },
    onError: (error) => {
      enqueueSnackbar(`User could not be assigned to the organization`, {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });

  const editUserOrganization = useCallback(
    ({ target }: SelectChangeEvent<string>) => {
      if (user?.organization?.id === target?.value) {
        return;
      }
      editUserOrganizationMutation({
        update: (cache, { data }) => {
          // Update user's data
          cache.modify({
            fields: {
              userRole: () => UserRole.NormalUser,
            },
            id: cache.identify({
              __typename: "User",
              id: userId,
            }),
          });
          // Remove user from old organization
          cache.modify({
            fields: {
              users: (existing = {}, { readField }) => {
                return {
                  ...existing,
                  nodes: (existing?.nodes || []).filter(
                    (ref: Reference) => readField("id", ref) !== userId
                  ),
                  totalCount: Math.max((existing?.totalCount || 0) - 1, 0),
                };
              },
            },
            id: cache.identify({
              __typename: "Organization",
              id: user?.organization?.id,
            }),
          });
          // Move user to new organization
          cache.modify({
            fields: {
              users: (existing = {}, { toReference }) => {
                const userRef = toReference({
                  __typename: "User",
                  id: userId,
                });
                return {
                  ...existing,
                  nodes: [userRef, ...(existing?.nodes || [])],
                  totalCount: Math.max((existing?.totalCount || 0) + 1, 0),
                };
              },
            },
            id: cache.identify({
              __typename: "Organization",
              id: data?.user?.setOrganization?.record?.organization?.id,
            }),
          });
        },
        variables: {
          input: {
            organizationId: target?.value,
            role: UserRole.NormalUser,
            userId,
          },
        },
      });
    },
    [user?.organization?.id, editUserOrganizationMutation, userId]
  );

  return (
    <FormControl
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        marginBottom: 1,
        width: "40%",
      }}
    >
      <Typography sx={{ color: "inherit" }}>Organization: </Typography>
      {loading ? (
        <CircularProgress size={16} sx={{ marginLeft: "6px" }} />
      ) : (
        <Select
          disabled={user?.isDemoUser || isSuperAdminReadOnly}
          displayEmpty={true}
          fullWidth={true}
          onChange={editUserOrganization}
          renderValue={(organizationId) => {
            const organizationName = organizationsList.find(
              ({ id }) => organizationId === id
            )?.name;
            return (
              organizationName ||
              user?.organization?.name ||
              "Assign to organization"
            );
          }}
          size="small"
          style={{ background: "transparent" }}
          sx={{
            "& .MuiSelect-select": { padding: "4px 32px 4px 4px !important" },
            marginLeft: 1,
          }}
          value={user?.organization?.id}
          variant="standard"
        >
          {organizationsList.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

UserOrganizationField.displayName = "UserOrganizationField";

export default memo(UserOrganizationField);
