import { memo, useState } from "react";
import {
  EXTERNAL_DATA_IMPORT_STEPS,
  ImportExternalDataDialogProvider,
} from "features/datasets/components/ImportExternalData";
import { DataSourceType } from "types/__generated-new";

interface ImportExternalDataDialogWrapperProps {
  children: React.ReactNode;
}

const ImportExternalDataDialogWrapper: React.FC<
  ImportExternalDataDialogWrapperProps
> = ({ children }) => {
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status)
  // can be fetched via datasetImport query on the IMPORT_STATUS step
  const [datasetImportId, setDatasetImportId] = useState<string | null>(null);
  const [importStep, setImportStep] = useState<EXTERNAL_DATA_IMPORT_STEPS>(
    EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE
  );
  const [selectedDataSource, setSelectedDataSource] = useState<DataSourceType>(
    DataSourceType.S3
  );
  // Returns true if the first step of external import is completed (creating import itself)
  const [isImportCreated, setIsImportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetImportError, setDatasetImportError] = useState<string | null>(
    null
  );

  return (
    <ImportExternalDataDialogProvider
      value={{
        datasetImportError,
        datasetImportId,
        importStep,
        isImportCreated,
        selectedDataSource,
        setDatasetImportError,
        setDatasetImportId,
        setImportStep,
        setIsImportCreated,
        setSelectedDataSource,
      }}
    >
      {children}
    </ImportExternalDataDialogProvider>
  );
};

export default memo(ImportExternalDataDialogWrapper);
