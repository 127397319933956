import {
  type Client,
  type data_science,
  proto,
  type types,
} from "@decentriq/core";
import * as forge from "node-forge";
import { type DraftNode } from "types/__generated-new";
import {
  buildDataScienceCommit,
  getLatestEnclaveSpecsPerType,
} from "utils/apicore";

export async function getDataRoomUsedSpecs(
  dataScienceWrapper: data_science.DataScienceDataRoomWrapper,
  latestEnclaveSpecs: types.EnclaveSpecification[]
): Promise<string[]> {
  const dataRoomEnclaveSpecs = dataScienceWrapper.getEnclaveSpecifications();
  const latestEnclaveSpecsMap = new Map(
    latestEnclaveSpecs.map((i) => {
      const encoded =
        proto.attestation.AttestationSpecification.encodeDelimited(
          i.proto
        ).finish();
      const hash = forge.md.sha256
        .create()
        .update(forge.util.binary.raw.encode(encoded))
        .digest()
        .toHex();
      return [hash, i];
    })
  );
  const usedSpecs: string[] = [];
  for (const enclaveSpec of dataRoomEnclaveSpecs) {
    const attestationProto = forge.util.binary.base64.decode(
      enclaveSpec.attestationProtoBase64
    );
    const hash = forge.md.sha256
      .create()
      .update(forge.util.binary.raw.encode(attestationProto))
      .digest()
      .toHex();
    const spec = latestEnclaveSpecsMap.get(hash);
    if (spec) {
      usedSpecs.push(spec.name);
    } else {
      throw new Error("Enclave spec not found");
    }
  }
  return usedSpecs;
}

export async function createDataScienceCommit(
  draftNode: DraftNode,
  analysts: Array<string>,
  isDevMode: boolean,
  sdkClient: Client,
  dataScienceWrapper: data_science.DataScienceDataRoomWrapper
): Promise<{ commitId: string; pin: string }> {
  const pin = (await dataScienceWrapper.retrieveCurrentConfiguration())
    .historyPin;
  const latestEnclaveSpecs = await sdkClient.getEnclaveSpecifications();
  const usedSpecs = await getDataRoomUsedSpecs(
    dataScienceWrapper,
    latestEnclaveSpecs
  );

  // Translate the data science commit and publish it
  const nodeNamesMap = new Map(
    dataScienceWrapper.getNodes().map((node) => [node.name, node.id])
  );

  const dataScienceCommit = buildDataScienceCommit(
    draftNode,
    analysts,
    dataScienceWrapper.dataRoomId,
    pin,
    getLatestEnclaveSpecsPerType(latestEnclaveSpecs),
    usedSpecs,
    nodeNamesMap,
    isDevMode
  );
  const commitId = await dataScienceWrapper.createCommit(dataScienceCommit);
  return { commitId, pin };
}
