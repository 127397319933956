import { DatasetSchemaExtractionDialog } from "@decentriq/components";
import { exceptions } from "@decentriq/utils";
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DataNodeUploadDataDialog } from "features";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { useReportError } from "hooks";
import {
  castFormatTypeToPrimitiveType,
  type DataRoomData,
  type DataRoomDataTable,
  DataRoomType,
} from "models";
import DataNodeActions from "../../components/DataNodeConstructor/DataNodeActions";
import { useDataNodeActions } from "../DataNodes/DataNodesActionsWrapper";
import useTestDraftDataNodeActions from "./useTestDraftDataNodeActions";

interface TestDataNodeActionsProps {
  dataNode: DataRoomData;
  dataRoomId: string;
  mode: DataNodeConstructorMode;
  nodesLoading: boolean;
}

const TestDataNodeActions: React.FC<TestDataNodeActionsProps> = ({
  dataNode,
  dataRoomId,
  mode,
  nodesLoading,
}) => {
  const {
    handleTableColumnsOrderUpdate,
    handleTableColumnCreate,
    handleTableColumnDelete,
  } = useDataNodeActions();
  const {
    activeDataRoomUpload,
    currentUserEmail,
    dataNodeForIngestion,
    handleIngestData,
    handleDataDeprovision,
    handleConnectFromKeychain,
    resetUploadings,
    setDataNodeForIngestion,
    uploadings,
    handleUploadClose,
  } = useTestDraftDataNodeActions();
  const reportError = useReportError();
  useEffect(() => {
    if (
      mode === DataNodeConstructorMode.ACTION ||
      mode === DataNodeConstructorMode.STATUS ||
      mode === DataNodeConstructorMode.DEGRADE_ACTION
    ) {
      resetUploadings();
    }
  }, [mode, dataRoomId, resetUploadings]);
  const onDataDeprovision = useCallback(
    async () => await handleDataDeprovision(dataNode.id),
    [handleDataDeprovision, dataNode.id]
  );
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: `${DataRoomType.DataScience}_TEST`,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const key = `${dataNode.id}-${currentUserEmail}`;
  const id = dataNodeForIngestion?.id;
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: id!,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          id!,
          payload.datasetKeychainItem!
        );
      }
    },
    [id, handleIngestData, handleConnectFromKeychain]
  );
  const { dataType } = dataNode;
  const [open, setOpen] = useState(false);
  const replaceWithColumns = useCallback(
    async (columns: []) => {
      await Promise.all(
        dataNode?.columns?.map(({ id }: { id: string }) =>
          handleTableColumnDelete(id)
        )
      );
      const columnsOrder = await Promise.all(
        columns?.map(({ name, nullable, formatType }: any) =>
          handleTableColumnCreate(dataNode.id, {
            formatType,
            name,
            nullable,
            primitiveType: castFormatTypeToPrimitiveType(formatType),
          })
        )
      ).then((data) =>
        data.map(
          ({
            data: { draftTableLeafNode: { addColumn: { id } = {} } = {} } = {},
          }) => id
        )
      );
      await handleTableColumnsOrderUpdate({ columnsOrder, id: dataNode.id });
    },
    [
      dataNode?.columns,
      dataNode.id,
      handleTableColumnsOrderUpdate,
      handleTableColumnCreate,
      handleTableColumnDelete,
    ]
  );
  return (
    <>
      {dataType === "table" ? (
        <Button
          color="inherit"
          onClick={() => setOpen(true)}
          variant="outlined"
        >
          Import schema
        </Button>
      ) : null}
      <DatasetSchemaExtractionDialog
        DatasetUploaderProps={{
          OkayButtonProps: {
            onClick: (columns: []) => {
              replaceWithColumns?.(columns);
              setOpen(false);
            },
          },
        }}
        DialogTitleChildren={<span>Import schema</span>}
        DialogTitleProps={{
          sx: {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      />
      <DataNodeActions
        dataType={dataNode.dataType}
        datasetHash={dataNode?.testDataset?.datasetHash}
        id={dataNode.id}
        isLoading={
          uploadings[key]?.isLoading ||
          activeDataRoomUpload === key ||
          nodesLoading
        }
        onDeprovision={onDataDeprovision}
        onUpload={() => {
          setDataNodeForIngestion(dataNode);
        }}
        testing={true}
      />
      {dataNodeForIngestion && (
        <DataNodeUploadDataDialog
          columns={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columns
              : undefined
          }
          columnsOrder={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columnsOrder
              : undefined
          }
          id={dataNodeForIngestion.id}
          name={dataNodeForIngestion.name}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!dataNodeForIngestion}
          uniqueColumnIds={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).uniqueColumnIds
              : undefined
          }
        />
      )}
    </>
  );
};

export default TestDataNodeActions;
