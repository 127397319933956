import { useEffect, useState } from "react";
import { usePublishedDataRoom } from "contexts";
import {
  useDevComputeNodeActionsQuery,
  useFetchComputeJobResultSizeMutation,
} from "hooks/__generated-new";
import { type DraftComputeNodeTypes } from "models";

interface UseFetchComputeJobResultSizeArgs {
  computeNodeId: string;
  skip?: boolean;
}

const useFetchComputeJobResultSize = ({
  computeNodeId,
  skip,
}: UseFetchComputeJobResultSizeArgs) => {
  const [resultSize, setResultSize] = useState<number | null>(null);
  const {
    dataRoomId: dcrHash,
    driverAttestationHash,
    enableAirlock,
  } = usePublishedDataRoom();
  const { data: actionsData } = useDevComputeNodeActionsQuery({
    variables: {
      computeNodeId,
    },
  });

  const [
    fetchComputeJobResultSizeMutation,
    { loading: fetchComputeJobResultSizeLoading },
  ] = useFetchComputeJobResultSizeMutation();

  useEffect(() => {
    const nodeJob = (
      actionsData?.draftNode as DraftComputeNodeTypes | undefined
    )?.job;
    if (skip || !nodeJob || !enableAirlock) {
      setResultSize(null);
      return;
    }
    fetchComputeJobResultSizeMutation({
      variables: {
        input: {
          computeNodeId,
          dcrHash,
          driverAttestationHash,
          jobId: nodeJob.id,
        },
      },
    }).then((res) =>
      setResultSize(res.data?.retrieveComputeJobResultSize ?? null)
    );
  }, [
    actionsData,
    computeNodeId,
    dcrHash,
    driverAttestationHash,
    enableAirlock,
    fetchComputeJobResultSizeMutation,
    skip,
  ]);
  return { fetchComputeJobResultSizeLoading, resultSize };
};

export default useFetchComputeJobResultSize;
