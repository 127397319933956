import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import { faEye, faEyeSlash, faUnlock } from "@fortawesome/pro-light-svg-icons";
import {
  faCaretRight as faCaretRightSolid,
  faKey,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useDocsLink, useSignOut } from "hooks";
import { useKeychain } from "wrappers";
import { useAccordionSummaryReversedStyles } from "../Accordion/Accordion";

const validationSchema = yup.object({
  password: yup.string().trim().required().defined(),
});

type UnlockKeychainFormValues = yup.InferType<typeof validationSchema>;

interface UnlockKeychainProps {
  onForgotPassword: () => void;
  open: boolean;
}

const UnlockKeychain: React.FC<UnlockKeychainProps> = ({
  onForgotPassword,
  open,
}) => {
  const [unlocking, setUnlocking] = useState(false);
  const { unlockKeychain } = useKeychain();
  const { user } = useAuth0();
  const { classes: accodionSummaryClasses } =
    useAccordionSummaryReversedStyles();
  const [password, setPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: UnlockKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  const onSignOut = useSignOut();
  const docsBaseLink = useDocsLink();
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      password,
    });
  }, [password, validate]);
  const handleSubmit = useCallback(
    async (password: string | undefined) => {
      setUnlocking(true);
      const isValid = await validate({
        password: password || "",
      });
      if (isValid) {
        await unlockKeychain(password!.trim());
      }
      setUnlocking(false);
    },
    [unlockKeychain, setUnlocking, validate]
  );
  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogTitle>
        <FontAwesomeIcon fixedWidth={true} icon={faKey} /> Keychain
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 0.5 }} variant="subtitle1">
          {user?.email}
        </Typography>
        <Typography sx={{ mb: 1 }}>
          To interact with the platform, your Keychain must be activated.
        </Typography>
        <Box sx={{ mb: 1 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(password);
            }}
          >
            <FormControl fullWidth={true} variant="filled">
              <InputLabel>Keychain password</InputLabel>
              <FilledInput
                autoComplete="new-password"
                data-testid={testIds.keychain.unlockKeychain.keychainInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth={true}
                onChange={(event) => setPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={password || ""}
              />
            </FormControl>
          </form>
        </Box>
        {!isValid ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            <strong>Invalid password:</strong>
            <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
              {validationErrors?.map((validationError, index) => (
                <li key={index}>{validationError}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <Box sx={{ mt: 3 }}>
          <Accordion disableGutters={true} elevation={0} square={true}>
            <AccordionSummary
              classes={accodionSummaryClasses}
              expandIcon={<FontAwesomeIcon icon={faCaretRightSolid} />}
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                alignItems: "center",
              }}
            >
              <Box
                sx={{ alignItems: "center", display: "flex", height: "100%" }}
              >
                <Typography>How does it work?</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The Keychain operates like a traditional password manager. It
                derives an encryption key from your password. This key is used
                to locally encrypt the secrets (e.g. dataset encryption keys)
                you want to store in the Keychain.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                The encrypted secrets are then stored on the Decentriq Platform.
                They are retrieved when needed and the same password-derived
                encryption key is used to decrypt them.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                As Decentriq does not have access to your password, Decentriq
                can never access the secrets stored in the Keychain.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                If you lose your password, you will have to reset it and lose
                access to all previously stored secrets.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                For more information, please{" "}
                <Link
                  href={`${docsBaseLink}/keychain`}
                  rel="noreferrer"
                  target="_blank"
                >
                  check the documentation
                </Link>
                .
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <div>
          <Button color="inherit" disabled={unlocking} onClick={onSignOut}>
            Sign out
          </Button>
          <Button
            color="inherit"
            disabled={unlocking}
            onClick={onForgotPassword}
            style={{ marginLeft: ".5rem" }}
          >
            Forgot password
          </Button>
        </div>
        <LoadingButton
          color="primary"
          data-testid={testIds.keychain.unlockKeychain.submitButton}
          loading={unlocking}
          loadingPosition="start"
          onClick={() => handleSubmit(password)}
          startIcon={<FontAwesomeIcon icon={faUnlock} />}
          variant="contained"
        >
          Activate Keychain
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UnlockKeychain);
