import { InfoTooltip } from "@decentriq/components";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

interface PositiveCurveScoreBarProps {
  withChartButton?: boolean;
}

const PositiveCurveScoreBar: React.FC<PositiveCurveScoreBarProps> = ({
  withChartButton = true,
}) => {
  const { openAdvertiserAudienceQualityDrawer, selectedAudienceQuality } =
    useAdvertiserAudienceGenerator();

  const positiveCurveScorePercentage = useMemo(
    () => selectedAudienceQuality?.roc_curve?.tpr || 0,
    [selectedAudienceQuality?.roc_curve?.tpr]
  );

  return (
    <Grid
      container={true}
      padding={2}
      sx={({ shape, palette }) => ({
        border: `1px solid ${palette.grey[300]}`,
        borderRadius: shape.borderRadius,
      })}
      xs={12}
    >
      <Grid item={true} xs={12}>
        <Typography fontWeight={500} variant="subtitle1">
          ROC Score
          <InfoTooltip tooltip="This is a measure of how much of the seed/remarketing audience the model would recover at this size. It is directly calculated from the Receiver Operating Characteristic (ROC) graph — the “True Positive Rate” is the Remarketing Coverage Score. In other words, a precision settings with 50% remarketing coverage that is not excluding the seed audience from the output will usually include about 50% of the seed audience." />
        </Typography>
        <Typography fontWeight={400} variant="subtitle2">
          Percentage of "True Positives" from the seed audience recovered in the
          lookalike audience
        </Typography>
      </Grid>
      <Grid alignItems="center" container={true} item={true} mt={1} xs={12}>
        <Grid xs={8}>
          <Box alignItems="center" display="flex" mr={1} width="100%">
            <LinearProgress
              color="warning"
              sx={({ shape }) => ({
                borderRadius: shape.borderRadius,
                height: "12px",
                mr: 1,
                width: "100%",
              })}
              value={positiveCurveScorePercentage * 100}
              variant="determinate"
            />
            <Typography color="text.secondary" variant="body2">
              {(positiveCurveScorePercentage * 100).toFixed(1)}%
            </Typography>
          </Box>
        </Grid>
        {withChartButton && (
          <Grid container={true} item={true} justifyContent="flex-end" xs={4}>
            <Button
              color="secondary"
              onClick={openAdvertiserAudienceQualityDrawer}
              startIcon={<FontAwesomeIcon icon={faArrowRight} />}
              variant="text"
            >
              See graph
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PositiveCurveScoreBar.displayName = "PositiveCurveScoreBar";

export default memo(PositiveCurveScoreBar);
