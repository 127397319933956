import {
  faBullhorn,
  faDatabase,
  faDoorOpen,
  faEye,
  faLink,
  faNewspaper,
  faUserTie,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { get } from "lodash";
import { memo, useMemo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import usePublishedMediaInsightsDcrDefinition from "features/MediaInsightsDcr/contexts/MediaInsightsDcrContext/hooks/usePublishedMediaInsightsDcrDefinition";
import { usePublishedMediaInsightsDcrQuery } from "hooks/__generated-new";
import {
  type CollaborationTypes,
  RawMediaDataRoomFeaturesToMediaDataRoomFeatures,
} from "models";
import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "types/__generated-new";
import { RawSupportedFeatures } from "../models";

enum MediaDataRoomSupportedFeaturesLabels {
  HideAbsoluteValues = "Hide Absolute Values from Insights",
  EnableModelPerformanceEvaluation = "Enable Model Performance Evaluation",
  EnableAdvertiserAudienceDownload = "Enable Advertiser Audience Download",
  EnableExtendedLalQualityStatistics = "Enable Extended Lookalike Quality Statistics",
}

const rawMediaDcrSupportedFeaturesToMediaDcrSupportedFeaturesPresentation: Record<
  RawSupportedFeatures,
  MediaDataRoomSupportedFeaturesLabels
> = {
  [RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS]:
    MediaDataRoomSupportedFeaturesLabels.HideAbsoluteValues,
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    MediaDataRoomSupportedFeaturesLabels.EnableAdvertiserAudienceDownload,
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]:
    MediaDataRoomSupportedFeaturesLabels.EnableModelPerformanceEvaluation,
  [RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS]:
    MediaDataRoomSupportedFeaturesLabels.EnableExtendedLalQualityStatistics,
};

const ReviewStepIcon = styled(FontAwesomeIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
}));

const MediaDataRoomSummary: React.FC = () => {
  const { data: { definition = null, supportedFeatures } = {}, status } =
    usePublishedMediaInsightsDcrDefinition({
      queryKeyPrefix: ["mi"],
    });
  const {
    name,
    publisherEmails = [],
    advertiserEmails = [],
    observerEmails = [],
    agencyEmails = [],
    dataPartnerEmails = [],
    matchingIdFormat = "STRING",
    hashMatchingIdWith = null,
  } = definition ?? {};

  const enabledFeaturesDisplay = useMemo(() => {
    return supportedFeatures?.reduce((acc, feature) => {
      const mappedFeature: CollaborationTypes | null = get(
        RawMediaDataRoomFeaturesToMediaDataRoomFeatures,
        feature,
        null
      );
      if (mappedFeature !== null) {
        acc.push(mappedFeature);
      }
      return acc;
    }, [] as CollaborationTypes[]);
  }, [supportedFeatures]);

  const supportedFeaturesDisplay = useMemo(() => {
    return supportedFeatures?.reduce((acc, feature) => {
      const mappedSupportedFeature: RawSupportedFeatures | null = get(
        rawMediaDcrSupportedFeaturesToMediaDcrSupportedFeaturesPresentation,
        feature,
        null
      );
      if (mappedSupportedFeature) {
        acc.push(mappedSupportedFeature);
      }
      return acc;
    }, [] as MediaDataRoomSupportedFeaturesLabels[]);
  }, [supportedFeatures]);

  if (status !== "success") {
    return <CircularProgress color="inherit" size={32} thickness={1} />;
  }

  return (
    <Grid container={true}>
      <Grid container={true} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faDoorOpen} />
          <Typography sx={{ fontWeight: 500 }}>Name</Typography>
        </Grid>
        <Grid xs={8}>{name || "—"}</Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faLink} />
          <Typography sx={{ fontWeight: 500 }}>Matching ID</Typography>
        </Grid>
        <Grid xs={8}>
          {matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat: matchingIdFormat as MatchingColumnFormat,
            matchingIdHashingAlgorithm:
              hashMatchingIdWith as TableColumnHashingAlgorithm,
          })}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faWrench} />
          <Typography sx={{ fontWeight: 500 }}>Enabled Features</Typography>
        </Grid>
        <Grid xs={8}>
          {enabledFeaturesDisplay?.length
            ? enabledFeaturesDisplay.join(", ")
            : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faWrench} />
          <Typography sx={{ fontWeight: 500 }}>
            Additional Configurations
          </Typography>
        </Grid>
        <Grid xs={8}>
          {supportedFeaturesDisplay?.length
            ? supportedFeaturesDisplay.join(", ")
            : ""}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faNewspaper} />
          <Typography sx={{ fontWeight: 500 }}>Publisher</Typography>
        </Grid>
        <Grid xs={8}>
          {publisherEmails.length ? publisherEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faBullhorn} />
          <Typography sx={{ fontWeight: 500 }}>Advertiser</Typography>
        </Grid>
        <Grid xs={8}>
          {advertiserEmails.length ? advertiserEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faEye} />
          <Typography sx={{ fontWeight: 500 }}>Observer</Typography>
        </Grid>
        <Grid xs={8}>
          {observerEmails.length ? observerEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={10}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faUserTie} />
          <Typography sx={{ fontWeight: 500 }}>Agency</Typography>
        </Grid>
        <Grid xs={8}>
          {agencyEmails.length ? agencyEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      {dataPartnerEmails !== null && (
        <Grid container={true} mt={1} xs={10}>
          <Grid alignItems="center" container={true} xs={4}>
            <ReviewStepIcon fixedWidth={true} icon={faDatabase} />
            <Typography sx={{ fontWeight: 500 }}>Data partner</Typography>
          </Grid>
          <Grid xs={8}>
            {dataPartnerEmails.length ? dataPartnerEmails.join(", ") : "—"}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MediaDataRoomSummary.displayName = "LookalikeMediaDataRoomSummary";

export default memo(MediaDataRoomSummary);
