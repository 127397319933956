import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import React, { memo, useCallback } from "react";
import { useAdvertiserAudiences } from "../contexts/advertiserAudiences/advertiserAudiences";

type ActivateAudienceButtonProps = {
  audienceType: string;
  activationType: string;
  reach: number;
  published: boolean;
};

const ActivateAudienceButton: React.FC<ActivateAudienceButtonProps> = ({
  audienceType,
  activationType,
  reach,
  published,
}) => {
  const { publishAudience } = useAdvertiserAudiences();

  const publish = useCallback(
    async () => publishAudience({ activationType, audienceType, reach }),
    [audienceType, publishAudience, reach, activationType]
  );

  return (
    <LoadingButton
      color="inherit"
      disabled={published}
      loadingPosition="start"
      onClick={publish}
      startIcon={
        <FontAwesomeIcon icon={published ? faCheck : faBullseyePointer} />
      }
      sx={{ fontWeight: 600, textTransform: "none" }}
      variant="text"
    >
      {published ? "Available to Publisher" : "Make available to Publisher"}
    </LoadingButton>
  );
};

ActivateAudienceButton.displayName = "ActivateAudienceButton";

export default memo(ActivateAudienceButton);
