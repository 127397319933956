import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { memo, type SetStateAction, useCallback } from "react";
import { type ActivationType, type Audience } from "../../../../models";
import useExportAudience from "./useExportAudience";

type ActivationExportButtonProps = {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
  closeDownloadButtonPopover: () => void;
  setDownloadAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const ActivationExportButton: React.FC<ActivationExportButtonProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  closeDownloadButtonPopover,
  setDownloadAudienceLoading,
}) => {
  const { loading, exportAudience } = useExportAudience({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });
  const handleExportAudience = useCallback(async () => {
    setDownloadAudienceLoading(true);
    closeDownloadButtonPopover();
    await exportAudience();
    setDownloadAudienceLoading(false);
  }, [closeDownloadButtonPopover, exportAudience, setDownloadAudienceLoading]);
  return (
    <LoadingButton
      color="inherit"
      loading={loading}
      loadingPosition="start"
      onClick={handleExportAudience}
      startIcon={<FontAwesomeIcon icon={faDownload} />}
      sx={({ spacing }) => ({
        fontSize: "16px",
        fontWeight: 400,
        justifyContent: "flex-start",
        padding: spacing(0.5, 2),
      })}
      variant="text"
    >
      Download
    </LoadingButton>
  );
};

ActivationExportButton.displayName = "ActivationExportButton";

export default memo(ActivationExportButton);
