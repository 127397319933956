import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useDatasetImportQuery } from "hooks/__generated-new";
import { SalesforceProductType } from "types/__generated-new";

export const SalesforceProductTypeMap = new Map<SalesforceProductType, string>([
  [SalesforceProductType.Core, "Core"],
  [SalesforceProductType.MarketingCloud, "Marketing Cloud"],
]);

type SalesforceConnectionConfigurationProps = {
  connectionId: string;
};

const SalesforceConnectionConfiguration: React.FC<
  SalesforceConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { datasetImport: { config: datasetImportConfig = {} } = {} } = {},
  } = useDatasetImportQuery({
    variables: { id: connectionId },
  });
  const { apiName, domainUrl, productType } = datasetImportConfig || {};

  return (
    <Box>
      <Typography variant="body2">
        <strong>API name:</strong> {apiName}
      </Typography>
      <Typography variant="body2">
        <strong>Domain URL:</strong> {domainUrl}
      </Typography>
      <Typography variant="body2">
        <strong>Product type:</strong>{" "}
        {SalesforceProductTypeMap.get(productType)}
      </Typography>
    </Box>
  );
};

SalesforceConnectionConfiguration.displayName =
  "SalesforceConnectionConfiguration";

export default memo(SalesforceConnectionConfiguration);
