import { useCallback, useState } from "react";
import { useApiCore } from "contexts";
import { useCompleteDraftDataRoomLazyQuery } from "hooks/__generated-new";
import {
  buildDataScienceDataRoom,
  exportDataScienceDataRoom,
  getLatestEnclaveSpecsPerType,
} from "utils/apicore";

interface useDraftDataRoomAsJsonArgs {
  id: string;
}

interface useDraftDataRoomAsJsonResult {
  draftDataRoomAsJson: () => Promise<string | undefined>;
  draftDataRoomAsJsonLoading: boolean;
}

const useDraftDataRoomAsJson = ({
  id: dataRoomId,
}: useDraftDataRoomAsJsonArgs): useDraftDataRoomAsJsonResult => {
  const { client, sessionManager } = useApiCore();
  const [getDraftDataRoomAsJson, { loading }] =
    useCompleteDraftDataRoomLazyQuery({ variables: { id: dataRoomId } });
  const [exporting, setExporting] = useState(false);
  const draftDataRoomAsJson = useCallback(
    () =>
      getDraftDataRoomAsJson().then(async (result) => {
        setExporting(true);
        try {
          const { data } = result || {};
          const { draftDataRoom } = data || {};
          if (draftDataRoom) {
            const rootCertificatePem =
              await client.decentriqCaRootCertificate();
            const enclaveSpecifications =
              await client.getEnclaveSpecifications();
            const latestEnclaveSpecifications = getLatestEnclaveSpecsPerType(
              enclaveSpecifications
            );
            const dataScienceDataRoom = buildDataScienceDataRoom(
              draftDataRoom,
              rootCertificatePem,
              latestEnclaveSpecifications,
              true
            );
            const sdkSession = await sessionManager.get();
            const exportedDataScienceDataRoom = exportDataScienceDataRoom(
              sdkSession.compiler,
              dataScienceDataRoom
            );
            return JSON.stringify(exportedDataScienceDataRoom, null, 2);
          }
        } finally {
          setExporting(false);
        }
      }),
    [client, getDraftDataRoomAsJson, sessionManager]
  );
  return {
    draftDataRoomAsJson,
    draftDataRoomAsJsonLoading: loading || exporting,
  };
};

export default useDraftDataRoomAsJson;
