import { testIds } from "@decentriq/utils";
import { FormControl, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useMatchNodeDataSources } from "../../hooks";

interface TablesSelectorProps {
  id: string;
  readOnly?: boolean;
  [key: string]: any;
}

const TablesSelector: React.FC<TablesSelectorProps> = ({
  id,
  readOnly = false,
}) => {
  const {
    availableDataSources,
    selectedLeftDataSourceNodeId,
    setSelectedLeftDataSourceNodeId,
    selectedRightDataSourceNodeId,
    setSelectedRightDataSourceNodeId,
  } = useMatchNodeDataSources(id);
  return (
    <Grid container={true} spacing={2}>
      <Grid alignItems="center" display="flex" xs={1}>
        Join
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        <FormControl>
          <Select
            data-testid={testIds.computeNode.tablesSelector.select}
            disabled={readOnly}
            displayEmpty={true}
            fullWidth={true}
            onChange={({ target }) => {
              if (target.value !== selectedLeftDataSourceNodeId) {
                setSelectedLeftDataSourceNodeId(target.value);
              }
            }}
            renderValue={(value) =>
              availableDataSources.find((o) => o.id === value)?.name ||
              "Data source"
            }
            size="small"
            style={{
              background: "transparent",
              textAlign: "center",
              width: "280px",
            }}
            value={selectedLeftDataSourceNodeId || ""}
            variant="standard"
          >
            {availableDataSources.map(({ name, id }) => (
              <MenuItem
                data-testid={`${testIds.computeNode.tablesSelector.selectHelper}${name}`}
                key={id}
                value={id}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        with
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        <Select
          data-testid={testIds.computeNode.tablesSelector.selectCompareTo}
          disabled={readOnly}
          displayEmpty={true}
          fullWidth={true}
          onChange={({ target }) => {
            if (target.value !== selectedRightDataSourceNodeId) {
              setSelectedRightDataSourceNodeId(target.value);
            }
          }}
          renderValue={(value) =>
            availableDataSources.find((o) => o.id === value)?.name ||
            "Data source"
          }
          size="small"
          style={{
            background: "transparent",
            textAlign: "center",
            width: "280px",
          }}
          value={selectedRightDataSourceNodeId || ""}
          variant="standard"
        >
          {availableDataSources.map(({ name, id }) => (
            <MenuItem
              data-testid={`${testIds.computeNode.tablesSelector.selectHelperCompareTo}${name}`}
              key={id}
              value={id}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};
TablesSelector.displayName = "TablesSelector";

export default TablesSelector;
