import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DeleteDatasetButton,
  useDatasetPopupConfig,
  useGetDatasetWithMetadata,
} from "features/datasets";
import { ExportDatasetButton } from "features/datasets/components/ExportDataset";
import DatasetTabs from "../DatasetTabs/DatasetTabs";

interface DatasetPopupProps {
  open: boolean;
  isDatasetOwner?: boolean;
  onDismiss: () => void;
  datasetHash?: string;
  testing?: {
    dataNodeId: string;
  };
  withActions?: boolean;
}

const DatasetPopup = ({
  datasetHash,
  open,
  onDismiss,
  testing,
  withActions = true,
}: DatasetPopupProps) => {
  const { disableDelete, disableExport } = useDatasetPopupConfig();
  const { dataset } = useGetDatasetWithMetadata({
    datasetHash,
    skipFetching: !open,
  });
  if (!dataset || !datasetHash) return <></>;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={onDismiss}
      open={open}
      scroll="paper"
    >
      <DialogTitle>{dataset.name || dataset.id?.slice(0, 8)}</DialogTitle>
      <DialogContent>
        <DatasetTabs datasetHash={datasetHash} skipFetching={!open} />
      </DialogContent>
      {withActions && (
        <DialogActions>
          <Box sx={{ alignItems: "center", display: "flex" }}>
            {dataset?.isOwner && !disableDelete ? (
              <DeleteDatasetButton
                closeDatasetPopup={onDismiss}
                datasetManifestHash={dataset.manifestHash || ""}
                testing={testing}
              />
            ) : null}
            {dataset?.isOwner && !disableExport && (
              <ExportDatasetButton datasetManifestHash={dataset.manifestHash} />
            )}
          </Box>
          <Button color="inherit" onClick={onDismiss}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DatasetPopup;
