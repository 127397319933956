import { DqTable } from "@decentriq/components";
import { Box, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { type Audience } from "../models";

interface AudiencesTableProps {
  audiences: Audience[];
  rowActions: (row: Audience) => React.ReactNode;
  hasMutipleActions: boolean;
  noRecordsToDisplay: string;
  getAudienceStatusLabel: (row: Audience) => string;
}

const audienceTypePresentationMap = new Map<string, string>([
  ["lookalike", "Lookalike"],
  ["retarget", "Remarketing"],
  ["exclusion_targeting", "Exclusion targeting"],
]);

const AudiencesTable: React.FC<AudiencesTableProps> = ({
  audiences,
  getAudienceStatusLabel,
  rowActions,
  hasMutipleActions,
  noRecordsToDisplay,
}) => {
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ cell, row }) => {
          let label = cell.getValue() as string;
          if (row?.original?.activationType === "lookalike") {
            label += ` - ${row?.original?.reach}%`;
          }
          return (
            <Typography sx={{ fontSize: "14px", my: "auto" }}>
              {label}
            </Typography>
          );
        },
        accessorKey: "audienceType",
        header: "Audience",
        minSize: 150,
      },
      {
        Cell: ({ cell, row }) => {
          const audienceType = cell.getValue<string>();
          return audienceType === "lookalike" &&
            row?.original?.excludeSeedAudience
            ? "Lookalike (seed audience excluded)"
            : audienceTypePresentationMap.get(audienceType) ||
                capitalize(audienceType.replace(/_/g, " "));
        },
        accessorKey: "activationType",
        header: "Type",
        minSize: 150,
      },
      {
        Cell: ({ row }) => {
          return (
            <Typography sx={{ fontSize: "14px", my: "auto" }}>
              {getAudienceStatusLabel(row?.original)}
            </Typography>
          );
        },
        header: "Status",
        id: "status",
        minSize: 200,
      },
    ],
    [getAudienceStatusLabel]
  );

  return (
    <DqTable
      columns={columns}
      data={audiences}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: hasMutipleActions ? 500 : 350,
        },
      }}
      enableRowActions={true}
      getRowId={(row) => `${row.audienceType}-${row.reach}`}
      localization={{
        actions: "",
        noRecordsToDisplay,
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {rowActions(row?.original)}
          </Box>
        );
      }}
    />
  );
};

AudiencesTable.displayName = "AudiencesTable";

export default AudiencesTable;
