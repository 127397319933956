import { ClientApiError, type Session } from "@decentriq/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as forge from "node-forge";
import { useCallback, useEffect, useMemo } from "react";
import {
  useCreateLookalikeMediaComputeJobMutation,
  useGetLookalikeMediaComputeJobLazyQuery,
} from "hooks/__generated-new";
import { type CreateMediaComputeJobInput } from "types/__generated-new";
import { logDebug, parseMediaDataRoomError } from "utils";

export interface CacheKeyBase {
  dataRoomId: string;
}
export interface QueryComputeJobHookPayload<T, U extends CacheKeyBase> {
  jobCacheKey?: U;
  session?: Session | null;
  skip: boolean;
  // TODO perhaps use a strict type for jobType
  jobType: string;
  queryKeyPrefix: string[];
  createCacheKeyString: (key: U) => Promise<string>;
  createJob: (createJobProps: {
    key: U;
    jobCacheKeyString: string;
    session: Session;
  }) => Promise<CreateMediaComputeJobInput>;
  transform: (data: Uint8Array) => Promise<T>;
}

export interface QueryMediaInsightsComputeJobHookResult<T> {
  computeResults?: T;
  error: string | undefined;
  retry: () => Promise<void>;
  status: "COMPUTING" | "FETCHING" | "COMPLETED";
}

const useQueryMediaInsightsComputeJob = <T, U extends CacheKeyBase>({
  createCacheKeyString,
  createJob,
  jobCacheKey,
  jobType,
  queryKeyPrefix,
  session,
  skip,
  transform,
}: QueryComputeJobHookPayload<
  T,
  U
>): QueryMediaInsightsComputeJobHookResult<T> => {
  const queryClient = useQueryClient();
  const [createLookalikeMediaComputeJobMutation] =
    useCreateLookalikeMediaComputeJobMutation();

  const [getLookalikeMediaComputeJob] =
    useGetLookalikeMediaComputeJobLazyQuery();

  const { data: existingJob, isLoading: existingJobLoading } = useQuery({
    enabled: Boolean(jobCacheKey) && !skip,
    queryFn: async () => {
      if (!jobCacheKey) {
        return null;
      }
      const getJobResult = await getLookalikeMediaComputeJob({
        variables: {
          // This is specific to Lookalike media DCR
          // But can stay here for the time being.
          // All compute jobs are created this way
          input: {
            cacheKey: await createCacheKeyString(jobCacheKey),
            jobType,
            publishedDataRoomId: jobCacheKey.dataRoomId,
          },
        },
      });
      const mediaComputeJob = getJobResult?.data?.mediaComputeJob;
      const existingJob = mediaComputeJob
        ? {
            computeNodeName: mediaComputeJob.computeNodeName,
            jobIdHex: mediaComputeJob.jobIdHex,
          }
        : null;
      return existingJob;
    },
    queryKey: [...queryKeyPrefix, "existingJob", jobCacheKey],
  });

  const { mutate: createJobMutation } = useMutation({
    mutationFn: async ({
      jobCacheKey,
      session,
    }: {
      jobCacheKey: U;
      session: Session;
    }) => {
      const createResponse = await createLookalikeMediaComputeJobMutation({
        variables: {
          input: await createJob({
            jobCacheKeyString: await createCacheKeyString(jobCacheKey),
            key: jobCacheKey,
            session,
          }),
        },
      });
      return createResponse.data?.mediaComputeJob;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        [...queryKeyPrefix, "existingJob", jobCacheKey],
        {
          computeNodeName: data?.create.record.computeNodeName,
          jobIdHex: data?.create.record.jobIdHex,
        }
      );
    },
  });

  // If existingJob is null, we need to create a new job
  useEffect(() => {
    if (
      !skip &&
      existingJob == null &&
      !existingJobLoading &&
      !!jobCacheKey &&
      !!session
    ) {
      logDebug("creating job");
      createJobMutation({ jobCacheKey, session });
    }
  }, [
    createJobMutation,
    existingJob,
    existingJobLoading,
    session,
    jobCacheKey,
    skip,
  ]);

  // Polling for job status
  const { data: jobStatus, isLoading: jobStatusIsLoading } = useQuery({
    enabled: Boolean(session) && Boolean(existingJob),
    queryFn: async () => {
      if (!existingJob || !session) {
        return null;
      }
      const statusResponse = await session?.getComputationStatus(
        existingJob?.jobIdHex
      );

      const isCompleted = statusResponse.completeComputeNodeIds?.includes(
        existingJob.computeNodeName
      );

      return isCompleted ? "COMPLETED" : "COMPUTING";
    },
    queryKey: [
      ...queryKeyPrefix,
      "jobStatus",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
    refetchInterval: (query) => {
      if (existingJob && query.state.data === "COMPUTING") {
        // TODO @matyasfodor - polling interval should be configurable
        return 1000;
      }
    },
  });

  // Query the results
  const {
    data: computeResults,
    isLoading: resultsFetchingLoading,
    error: resultsFetchingError,
  } = useQuery({
    enabled: jobStatus === "COMPLETED" && !skip,
    queryFn: async () => {
      try {
        if (!existingJob) {
          throw new Error("Compute job not found");
        }
        logDebug("existing job found, fetching results");
        const result = await session?.getJobResults(
          forge.util.binary.hex.decode(existingJob.jobIdHex),
          existingJob.computeNodeName
        );

        if (!result) {
          throw new Error("No result");
        }

        return await transform(result);
      } catch (error) {
        throw parseMediaDataRoomError(error);
      }
    },
    queryKey: [
      ...queryKeyPrefix,
      "fetchResults",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
  });

  const error = useMemo(
    () =>
      resultsFetchingError === null
        ? undefined
        : resultsFetchingError instanceof ClientApiError
          ? resultsFetchingError?.message
          : `${resultsFetchingError}`,
    [resultsFetchingError]
  );

  const retry = useCallback(async () => {
    if (!jobCacheKey || !session) {
      throw new Error("No key or session");
    }
    return createJobMutation({ jobCacheKey, session });
  }, [jobCacheKey, createJobMutation, session]);

  const status = useMemo(
    () =>
      jobStatusIsLoading || jobStatus !== "COMPLETED"
        ? "COMPUTING"
        : resultsFetchingLoading
          ? "FETCHING"
          : "COMPLETED",
    [jobStatus, jobStatusIsLoading, resultsFetchingLoading]
  );

  return {
    computeResults,
    error,
    retry,
    status,
  };
};

export default useQueryMediaInsightsComputeJob;
