import { useAuth0 } from "@auth0/auth0-react";
import { faCheck, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { memo, useEffect } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { useSubmittedRequestSigneesLazyQuery } from "hooks/__generated-new";

interface SubmittedRequestApprovalDialogProps {
  requestId: string;
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const SubmittedRequestApprovalDialog: React.FC<
  SubmittedRequestApprovalDialogProps
> = ({ open, requestId, onCancel, onConfirm, loading }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user } = useAuth0();
  const currentUserEmail = user?.email || "";
  const [
    fetchSubmittedRequestSignees,
    { data, loading: loadingRequest, error },
  ] = useSubmittedRequestSigneesLazyQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Failed to get signees")
      );
    },
  });
  useEffect(() => {
    if (open) {
      fetchSubmittedRequestSignees({
        variables: { id: requestId },
      });
    }
  }, [open, requestId, fetchSubmittedRequestSignees]);
  const isMergingAvailable =
    data &&
    (data?.submittedDataRoomRequest?.signers?.nodes?.every((s) =>
      Boolean(s?.signature)
    ) ||
      (data.submittedDataRoomRequest?.signers?.nodes?.filter(
        (s) => !s?.signature
      )?.length === 1 &&
        data.submittedDataRoomRequest?.signers?.nodes?.some(
          (s) => s?.userEmail === currentUserEmail && !s?.signature
        )));
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open}>
      <DialogContent>
        {loadingRequest ? (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              minHeight: "4rem",
            }}
          >
            <CircularProgress color="inherit" size={16} />
          </Box>
        ) : error ? (
          <Typography align="center" variant="body2">
            Something went wrong with getting request
          </Typography>
        ) : (
          <>
            <Box sx={{ mb: 1, textAlign: "center" }}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faInfoCircle}
                size="3x"
              />
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom={true}
              style={{ lineHeight: 1.25 }}
              variant="h6"
            >
              <strong>
                Are you sure you would like to approve
                <br />
                this request?
              </strong>
            </Typography>
            {isMergingAvailable ? (
              <Typography align="center" variant="body2">
                The new computation will be integrated to the data clean room.
              </Typography>
            ) : (
              <Typography align="center" variant="body2">
                Once all data owners have approved,
                <br />
                it can be integrated to the data clean room.
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={onCancel}>
          Go back to review
        </Button>
        <div>
          {!loadingRequest && !error ? (
            <LoadingButton
              color="inherit"
              data-testid="popup_publish_dcr_btn"
              loading={loading}
              loadingPosition="start"
              onClick={onConfirm}
              startIcon={<FontAwesomeIcon icon={faCheck} />}
            >
              {isMergingAvailable
                ? "Approve and integrate"
                : "Confirm approval"}
            </LoadingButton>
          ) : null}
        </div>
      </DialogActions>
    </Dialog>
  );
};

SubmittedRequestApprovalDialog.displayName = "SubmittedRequestApprovalDialog";

export default memo(SubmittedRequestApprovalDialog);
