import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import eightLogoSrc from "assets/logos/8451-logo.png";
import abbottLogoSrc from "assets/logos/abbott-logo.png";
import amopharLogoSrc from "assets/logos/amophar-logo.png";
import apbLogoSrc from "assets/logos/apb-logo.png";
import astrazenecaLogoSrc from "assets/logos/astrazeneca-logo.png";
import avanadeLogoSrc from "assets/logos/avanade-logo.png";
import axaLogoSrc from "assets/logos/axa-logo.png";
import baldertonLogoSrc from "assets/logos/balderton-logo.png";
import baloiseLogoSrc from "assets/logos/baloise-logo.png";
import bancsabadellLogoSrc from "assets/logos/bancsabadell-logo.png";
import bayerLogoSrc from "assets/logos/bayer-logo.png";
import beiersdorfLogoSrc from "assets/logos/beiersdorf-logo.png";
import biocodexLogoSrc from "assets/logos/biocodex-logo.jpg";
import biogenLogoSrc from "assets/logos/biogen-logo.png";
import bnpLogoSrc from "assets/logos/bnpparibas-logo.png";
import BtovLogo from "assets/logos/btov-logo.svg";
import celentLogoSrc from "assets/logos/celent-logo.png";
import ceresLogoSrc from "assets/logos/ceres-logo.png";
import ChariteLogo from "assets/logos/charite-logo.svg";
import chlLogoSrc from "assets/logos/chl-logo.png";
import coOperativeLogoSrc from "assets/logos/co-operative-logo.png";
import correosLogoSrc from "assets/logos/correos-logo.png";
import creditSuisseLogoSrc from "assets/logos/credit-suisse-logo.png";
import ctrsLogoSrc from "assets/logos/ctrs-logo.png";
import dbLogoSrc from "assets/logos/db-logo.png";
import dbsLogoSrc from "assets/logos/dbs-logo.png";
import DecentriqPlatformLogo from "assets/logos/decentriq-platform-logo.svg";
import deutschepostLogoSrc from "assets/logos/deutschepost-logo.png";
import devstructiongamesLogoSrc from "assets/logos/devstructiongames-logo.png";
import dnextLogoSrc from "assets/logos/dnext-logo.jpeg";
import egLogoSrc from "assets/logos/eg-logo.png";
import ergoLogoSrc from "assets/logos/ergo-logo.png";
import evonikLogoSrc from "assets/logos/evonik-logo.png";
import forsLogoSrc from "assets/logos/fors-logo.png";
import galittLogoSrc from "assets/logos/galitt-logo.png";
import generaliLogoSrc from "assets/logos/generali-logo.png";
import groupemutuelLogoSrc from "assets/logos/groupemutuel-logo.png";
import gsbnLogoSrc from "assets/logos/gsbn-logo.jpg";
import gskLogoSrc from "assets/logos/gsk-logo.png";
import hanoverresearchLogoSrc from "assets/logos/hanoverresearch-logo.png";
import hugLogoSrc from "assets/logos/hcuge-logo.png";
import heelLogoSrc from "assets/logos/heel-logo.png";
import houseofinsurtechLogoSrc from "assets/logos/houseofinsurtech-logo.png";
import huk24LogoSrc from "assets/logos/huk24-logo.png";
import imLogoSrc from "assets/logos/im-logo.jpg";
import intesasanpaoloLogoSrc from "assets/logos/intesasanpaolo-logo.png";
import jdexLogoSrc from "assets/logos/j-dex-logo.png";
import jnjLogoSrc from "assets/logos/jnj-logo.png";
import keeplerLogoSrc from "assets/logos/keepler-logo.png";
import lihLogoSrc from "assets/logos/lih-logo.png";
import losingerMarazziLogoSrc from "assets/logos/losinger-marazzi-logo.png";
import lynxLogoSrc from "assets/logos/lynxcare-logo.png";
import mafCarreFourLogoSrc from "assets/logos/mafcarrefour-logo.png";
import mckinseyLogoSrc from "assets/logos/mckinsey-logo.png";
import mediamarktLogoSrc from "assets/logos/mediamarkt-logo.png";
import melisanaLogoSrc from "assets/logos/melisana-logo.png";
import MerckLogo from "assets/logos/merck-logo.svg";
import metagenicsLogoSrc from "assets/logos/metagenics-logo.png";
import mmmLogoSrc from "assets/logos/mmm-logo.png";
import mobiLogoSrc from "assets/logos/mobi-logo.png";
import mohLogoSrc from "assets/logos/moh-logo.png";
import novartisLogoSrc from "assets/logos/novartis-logo.png";
import nttdataLogoSrc from "assets/logos/nttdata-logo.png";
import ocbcLogoSrc from "assets/logos/ocbc-logo.png";
import ottoLogoSrc from "assets/logos/otto-logo.png";
import paladinLogoSrc from "assets/logos/paladin-logo.png";
import peakonLogoSrc from "assets/logos/peakon-logo.png";
import perrigoLogoSrc from "assets/logos/perrigo-logo.png";
import pfLogoSrc from "assets/logos/pf-logo.png";
import pfizerLogoSrc from "assets/logos/pfizer-logo.png";
import pgLogoSrc from "assets/logos/pg-logo.png";
import phamaxLogoSrc from "assets/logos/phamax-logo.png";
import pistoiaallianceLogoSrc from "assets/logos/pistoiaalliance-logo.jpg";
import postLogoSrc from "assets/logos/post-logo.png";
import progressiveLogoSrc from "assets/logos/progressive-logo.png";
import pwcLogoSrc from "assets/logos/pwc-logo.png";
import qualipharLogoSrc from "assets/logos/qualiphar-logo.png";
import rbLogoSrc from "assets/logos/rb-logo.png";
import ringierLogoSrc from "assets/logos/ringier-logo.png";
import rocheLogoSrc from "assets/logos/roche-logo.png";
import samsungLogoSrc from "assets/logos/samsung-logo.png";
import sanitasLogoSrc from "assets/logos/sanitas-logo.png";
import sanofiLogoSrc from "assets/logos/sanofi-logo.png";
import sapLogoSrc from "assets/logos/sap-logo.png";
import SwissBankingLogo from "assets/logos/sba-logo.svg";
import scLogoSrc from "assets/logos/sc-logo.png";
import scorLogoSrc from "assets/logos/scor-logo.png";
import secunetLogoSrc from "assets/logos/secunet-logo.png";
import sgtradexLogoSrc from "assets/logos/sgtradex-logo.png";
import sicpaLogoSrc from "assets/logos/sicpa-logo.png";
import socgenLogoSrc from "assets/logos/socgen-logo.png";
import strategichieLogoSrc from "assets/logos/strategichie-logo.png";
import svvLogoSrc from "assets/logos/svv-logo.png";
import SwissReLogo from "assets/logos/swiss-re-logo.svg";
import switchLogoSrc from "assets/logos/switch-logo.png";
import syneoshealthLogoSrc from "assets/logos/syneoshealth-logo.png";
import tSystemsLogoSrc from "assets/logos/t-systems-logo.png";
import therabelLogoSrc from "assets/logos/therabel-logo.png";
import tideLogoSrc from "assets/logos/tide-logo.png";
import tilmanLogoSrc from "assets/logos/tilman-logo.png";
import trafiguraLogoSrc from "assets/logos/trafigura-logo.png";
import travizoryLogoSrc from "assets/logos/travizory-logo.jpg";
import truedataLogoSrc from "assets/logos/truedata-logo.png";
import ubsLogoSrc from "assets/logos/ubs-logo.png";
import vbzLogoSrc from "assets/logos/vbz-logo.png";
import vemediaLogoSrc from "assets/logos/vemedia-logo.png";
import vkbLogoSrc from "assets/logos/vkb-logo.png";
import vtgLogoSrc from "assets/logos/vtg-logo.png";
import walmartLogoSrc from "assets/logos/walmart-logo.png";
import wefoxLogoSrc from "assets/logos/wefox-logo.png";
import willpharmaLogoSrc from "assets/logos/willpharma-logo.png";
import zambongroupLogoSrc from "assets/logos/zambongroup-logo.png";
import zooplaLogoSrc from "assets/logos/zoopla-logo.png";
import zuelligpharmaLogoSrc from "assets/logos/zuelligpharma-logo.png";
import ZurichLogo from "assets/logos/zurich-logo.svg";
import zurichnaLogoSrc from "assets/logos/zurichna-logo.png";

const CUSTOM_LOGO_ENABLED = true;

const useCustomLogoStyles = makeStyles()((theme) => ({
  powered: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export const customBrandingLogos: {
  [key: string]: JSX.Element;
} = {
  "8451.com": <img alt="8451" src={eightLogoSrc} style={{ height: "2rem" }} />,
  "abbott.com": (
    <img alt="Abbott" src={abbottLogoSrc} style={{ height: "2rem" }} />
  ),
  "amophar.be": (
    <img alt="Amophar" src={amopharLogoSrc} style={{ height: "4.5rem" }} />
  ),
  "apb.be": <img alt="APB" src={apbLogoSrc} style={{ height: "2.5rem" }} />,
  "astrazeneca.com": (
    <img
      alt="AstraZeneca"
      src={astrazenecaLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "avanade.com": (
    <img alt="Avanade" src={avanadeLogoSrc} style={{ height: "3rem" }} />
  ),
  "axa.ch": <img alt="AXA" src={axaLogoSrc} style={{ height: "2.5rem" }} />,
  "balderton.com": (
    <img alt="Balderton" src={baldertonLogoSrc} style={{ height: "1.5rem" }} />
  ),
  "baloise.ch": (
    <img alt="Baloise" src={baloiseLogoSrc} style={{ height: "2rem" }} />
  ),
  "bancsabadell.com": (
    <img
      alt="Bancsabadell"
      src={bancsabadellLogoSrc}
      style={{ height: "2rem" }}
    />
  ),
  "bayer.com": (
    <img alt="Bayer" src={bayerLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "beiersdorf.com": (
    <img
      alt="Beiersdorf"
      src={beiersdorfLogoSrc}
      style={{ height: "1.5rem" }}
    />
  ),
  "biocodex.be": (
    <img alt="Biocodex" src={biocodexLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "biogen.com": (
    <img alt="Biogen" src={biogenLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "bnpparibas.com": (
    <img alt="BNP" src={bnpLogoSrc} style={{ height: "2rem" }} />
  ),
  "btov.vc": <BtovLogo style={{ height: "2.5rem" }} />,
  "celent.com": (
    <img alt="Celent" src={celentLogoSrc} style={{ height: "2rem" }} />
  ),
  "ceres-pharma.com": (
    <img alt="Ceres Pharma" src={ceresLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "charite.de": <ChariteLogo style={{ height: "3rem" }} />,
  "chl.lu": <img alt="chl" src={chlLogoSrc} style={{ height: "3rem" }} />,
  "co-operative.coop": (
    <img
      alt="co-operative"
      src={coOperativeLogoSrc}
      style={{ height: "2rem" }}
    />
  ),
  "correos.com": (
    <img alt="Correos" src={correosLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "credit-suisse.com": (
    <img
      alt="Credit Suisse"
      src={creditSuisseLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "ctrs.ca": <img alt="CTRS" src={ctrsLogoSrc} style={{ height: "2rem" }} />,
  "db.com": (
    <img alt="Deutsche Bank" src={dbLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "dbs.com": <img alt="DBS" src={dbsLogoSrc} style={{ height: "2rem" }} />,
  "deutschepost.de": (
    <img
      alt="Deutschepost"
      src={deutschepostLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "devstructiongames.com": (
    <img
      alt="Devstruction Games"
      src={devstructiongamesLogoSrc}
      style={{ height: "3.5rem" }}
    />
  ),
  "dnext.io": <img alt="DNEXT" src={dnextLogoSrc} style={{ height: "3rem" }} />,
  "eg.be": <img alt="EG" src={egLogoSrc} style={{ height: "3.5rem" }} />,
  "ergo.de": <img alt="Ergo" src={ergoLogoSrc} style={{ height: "2.5rem" }} />,
  "evonik.com": (
    <img alt="Evonik" src={evonikLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "fors.com": <img alt="Fors" src={forsLogoSrc} style={{ height: "2rem" }} />,
  "galitt.com": (
    <img alt="Galitt" src={galittLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "generali.com": (
    <img alt="Generali" src={generaliLogoSrc} style={{ width: "6.5rem" }} />
  ),
  "gi-de.com": (
    <img alt="Gi-de" src={secunetLogoSrc} style={{ width: "8.5rem" }} />
  ),
  "groupemutuel.ch": (
    <img
      alt="GroupeMutuel"
      src={groupemutuelLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "gsbn.trade": (
    <img alt="GSBN" src={gsbnLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "gsk.com": <img alt="gsk" src={gskLogoSrc} style={{ height: "2.5rem" }} />,
  "hanoverresearch.com": (
    <img
      alt="Hanover Research"
      src={hanoverresearchLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "hcuge.ch": <img alt="HUG" src={hugLogoSrc} style={{ height: "2rem" }} />,
  "heel.be": <img alt="Heel" src={heelLogoSrc} style={{ height: "3rem" }} />,
  "houseofinsurtech.ch": (
    <img
      alt="houseofinsurtech"
      src={houseofinsurtechLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "huk24.de": <img alt="HUK24" src={huk24LogoSrc} style={{ height: "2rem" }} />,
  "im-associates.eu": (
    <img alt="IM Assosiates" src={imLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "intesasanpaolo.com": (
    <img
      alt="Intesasanpaolo"
      src={intesasanpaoloLogoSrc}
      style={{ height: "1.5rem" }}
    />
  ),
  "its.jnj.com": (
    <img alt="J&J" src={jnjLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "j-dex.co.jp": (
    <img alt="j-dex" src={jdexLogoSrc} style={{ height: "3.5rem" }} />
  ),
  "keepler.io": (
    <img alt="Keepler" src={keeplerLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "lih.lu": <img alt="LIH" src={lihLogoSrc} style={{ height: "2rem" }} />,
  "losinger-marazzi.ch": (
    <img
      alt="Losinger Marazzi"
      src={losingerMarazziLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "lynxcare.eu": (
    <img alt="Lynxcare" src={lynxLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "mafcarrefour.com": (
    <img
      alt="MafCarreFour"
      src={mafCarreFourLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "mckinsey.com": (
    <img alt="mckinsey" src={mckinseyLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "mediamarkt.com": (
    <img
      alt="Media Markt"
      src={mediamarktLogoSrc}
      style={{ height: "1.5rem" }}
    />
  ),
  "melisana.be": (
    <img alt="Melisana" src={melisanaLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "merck.com": <MerckLogo style={{ height: "2rem" }} />,
  "metagenics.eu": (
    <img alt="Metagenics" src={metagenicsLogoSrc} style={{ height: "3rem" }} />
  ),
  "mmm.com": <img alt="MMM" src={mmmLogoSrc} style={{ height: "2rem" }} />,
  "mobi.ch": (
    <img alt="Mobiliar" src={mobiLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "moh.gov.sg": (
    <img alt="MOH Singapore" src={mohLogoSrc} style={{ height: "3.5rem" }} />
  ),
  "novartis.com": (
    <img alt="Novartis" src={novartisLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "nttdata.com": (
    <img alt="Ntt data" src={nttdataLogoSrc} style={{ height: "1.5rem" }} />
  ),
  "ocbc.com": <img alt="OCBC" src={ocbcLogoSrc} style={{ height: "2rem" }} />,
  "otto.de": <img alt="Otto" src={ottoLogoSrc} style={{ height: "2rem" }} />,
  "paladingroupuk.com": (
    <img
      alt="Paladin Group UK"
      src={paladinLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "peakon.com": (
    <img alt="Peakon" src={peakonLogoSrc} style={{ height: "1.5rem" }} />
  ),
  "perrigo.com": (
    <img alt="Perrigo" src={perrigoLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "pfizer.com": (
    <img alt="Pfizer" src={pfizerLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "pg.com": <img alt="PG" src={pgLogoSrc} style={{ height: "3rem" }} />,
  "phamax.com": (
    <img alt="Phamax" src={phamaxLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "pistoiaalliance.org": (
    <img
      alt="Pistoia Alliance"
      src={pistoiaallianceLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "post.ch": (
    <img alt="Swiss Post" src={postLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "postfinance.ch": (
    <img alt="Post Finance" src={pfLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "progressive.com": (
    <img
      alt="Progressive"
      src={progressiveLogoSrc}
      style={{ height: "1.5rem" }}
    />
  ),
  "pwc.com": <img alt="pwc" src={pwcLogoSrc} style={{ height: "2.5rem" }} />,
  "qualiphar.be": (
    <img alt="Qualiphar" src={qualipharLogoSrc} style={{ height: "2rem" }} />
  ),
  "rb.com": <img alt="Reckit" src={rbLogoSrc} style={{ height: "2rem" }} />,
  "ringier.ch": (
    <img alt="Ringier" src={ringierLogoSrc} style={{ height: "2rem" }} />
  ),
  "roche.com": (
    <img alt="Roche" src={rocheLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "samsung.com": (
    <img alt="Samsung" src={samsungLogoSrc} style={{ height: "1.5rem" }} />
  ),
  "sanitas.com": (
    <img alt="Sanitas" src={sanitasLogoSrc} style={{ height: "2rem" }} />
  ),
  "sanofi.com": (
    <img alt="Sanofi" src={sanofiLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "sap.com": <img alt="SAP" src={sapLogoSrc} style={{ height: "2rem" }} />,
  "sba.ch": <SwissBankingLogo style={{ height: "1.5rem" }} />,
  "sc.com": (
    <img
      alt="Standart Chartared"
      src={scLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "scor.com": <img alt="Scor" src={scorLogoSrc} style={{ height: "1.5rem" }} />,
  "secunet.com": (
    <img alt="Secunet" src={secunetLogoSrc} style={{ width: "8.5rem" }} />
  ),
  "sgtradex.com": (
    <img alt="Sgtradex" src={sgtradexLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "sicpa.com": (
    <img alt="Sicpa" src={sicpaLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "socgen.com": (
    <img alt="Socgen" src={socgenLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "strategichie.com": (
    <img
      alt="Strategichie"
      src={strategichieLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "svv.ch": <img alt="SVV" src={svvLogoSrc} style={{ height: "2rem" }} />,
  "swissre.com": <SwissReLogo style={{ height: "2.5rem" }} />,
  "switch.com": (
    <img alt="Switch" src={switchLogoSrc} style={{ height: "2rem" }} />
  ),
  "syneoshealth.com": (
    <img
      alt="Syneos Health"
      src={syneoshealthLogoSrc}
      style={{ height: "3rem" }}
    />
  ),
  "t-systems.com": (
    <img alt="T-systems" src={tSystemsLogoSrc} style={{ height: "2rem" }} />
  ),
  "therabel.com": (
    <img alt="Therabel" src={therabelLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "tide.com": <img alt="Tide" src={tideLogoSrc} style={{ height: "4rem" }} />,
  "tilman.be": (
    <img alt="Tilman" src={tilmanLogoSrc} style={{ height: "3rem" }} />
  ),
  "trafigura.com": (
    <img alt="Tragigura" src={trafiguraLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "travizory.com": (
    <img alt="Travizory" src={travizoryLogoSrc} style={{ height: "2rem" }} />
  ),
  "truedata.com": (
    <img alt="Truedata" src={truedataLogoSrc} style={{ height: "2rem" }} />
  ),
  "ubs.com": <img alt="UBS" src={ubsLogoSrc} style={{ height: "2.5rem" }} />,
  "vbz.ch": <img alt="ZVV" src={vbzLogoSrc} style={{ height: "3rem" }} />,
  "vemedia.be": (
    <img alt="Vemedia" src={vemediaLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "vkb.de": <img alt="VKB" src={vkbLogoSrc} style={{ height: "3rem" }} />,
  "vtg.admin.ch": (
    <img alt="VTG" src={vtgLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "walmart.com": (
    <img alt="Walmart" src={walmartLogoSrc} style={{ height: "2rem" }} />
  ),
  "wefox.com": (
    <img alt="Wefox" src={wefoxLogoSrc} style={{ height: "2rem" }} />
  ),
  "willpharma.com": (
    <img
      alt="WillPharma"
      src={willpharmaLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "zambongroup.com": (
    <img alt="Zambon" src={zambongroupLogoSrc} style={{ height: "2.5rem" }} />
  ),
  "zoopla.com": (
    <img alt="Zoopla" src={zooplaLogoSrc} style={{ height: "2rem" }} />
  ),
  "zuelligpharma.com": (
    <img
      alt="Zuellig Pharma"
      src={zuelligpharmaLogoSrc}
      style={{ height: "2.5rem" }}
    />
  ),
  "zuerich.ch": <ZurichLogo style={{ height: "2.5rem" }} />,
  "zurichna.com": (
    <img alt="Zurich" src={zurichnaLogoSrc} style={{ height: "2rem" }} />
  ),
};

interface CustomBrandingLogoProps {
  logoBase64?: string;
  maxHeight?: string;
  domain?: string;
  fallbackToDecentriq?: boolean;
  withPoweredBy?: boolean;
}

const CustomBrandingLogo: React.FC<CustomBrandingLogoProps> = ({
  logoBase64 = "",
  maxHeight = "32px",
  domain = "",
  fallbackToDecentriq = true,
  withPoweredBy = !["decentriq.ch", "decentriq.com"].includes(domain),
}) => {
  const { classes: brandingClasses } = useCustomLogoStyles();
  const { user, isLoading } = useAuth0();
  if (isLoading) {
    return <Box style={{ height: "2.5rem" }}></Box>;
  }
  const standartLogo = (
    <DecentriqPlatformLogo
      style={{ height: "1.75rem", strokeWidth: "4px" }}
      viewBox="-4 -4 428 48"
    />
  );
  const brandDomain =
    domain ||
    user?.email?.slice(user?.email?.indexOf("@") + 1).toLowerCase() ||
    "";
  const customBrandingLogo = customBrandingLogos[brandDomain];
  const hasCustomBranding = Boolean(customBrandingLogo);
  return CUSTOM_LOGO_ENABLED && (hasCustomBranding || logoBase64) ? (
    <Box
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {logoBase64 ? (
        <img
          alt=""
          src={`data:image;base64,${logoBase64}`}
          style={{
            display: "block",
            maxHeight,
            maxWidth: withPoweredBy ? 160 : 280,
            width: "100%",
          }}
        />
      ) : (
        customBrandingLogo
      )}
      {withPoweredBy ? (
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "column",
            marginLeft: "1rem",
          }}
        >
          <Typography className={brandingClasses.powered} variant="caption">
            powered by
          </Typography>
          <DecentriqPlatformLogo
            style={{ height: ".7rem", strokeWidth: "4px" }}
            viewBox="-4 -4 428 48"
          />
        </Box>
      ) : null}
    </Box>
  ) : fallbackToDecentriq ? (
    standartLogo
  ) : null;
};

CustomBrandingLogo.displayName = "CustomBrandingLogo";

export default memo(CustomBrandingLogo);
