import { faKey as faKeyRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/material";
import { memo } from "react";
import { type ActionsItemsDefinition } from "components";
import { useGetDatasetWithMetadata } from "features/datasets/hooks";
import DatasetActions from "../DatasetActions/DatasetActions";

interface DatasetToolbarProps {
  datasetHash: string;
  hasKeychainItem: boolean;
  customDatasetActions?: (datasetHash: string) => ActionsItemsDefinition;
}

const DatasetToolbar: React.FC<DatasetToolbarProps> = ({
  datasetHash,
  hasKeychainItem,
  customDatasetActions,
}) => {
  const { dataset } = useGetDatasetWithMetadata({
    datasetHash,
    skipFetching: !datasetHash,
  });
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 1,
          overflow: "hidden",
        }}
      >
        <Typography fontWeight="fontWeightSemiBold" noWrap={true} variant="h6">
          {!dataset?.name ? "Loading dataset name..." : dataset?.name}
        </Typography>
        {hasKeychainItem ? (
          <Tooltip
            placement="top"
            title="This dataset encryption key is stored in the Keychain."
          >
            <FontAwesomeIcon fixedWidth={true} icon={faKeyRegular} />
          </Tooltip>
        ) : null}
      </Box>
      <DatasetActions
        customActions={customDatasetActions}
        datasetHash={datasetHash}
      />
    </Box>
  );
};
DatasetToolbar.displayName = "DatasetToolbar";

export default memo(DatasetToolbar);
