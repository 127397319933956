import { Box, styled, Typography } from "@mui/material";
import { AccessTokensConstructor } from "components";
import { useDocsLink } from "hooks";

const ENABLE_JS_DOCS_REFERENCE = false;

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

const AccessTokens: React.FC = () => {
  const docsBaseLink = useDocsLink();
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography marginBottom={1} variant="h5">
        Access tokens
      </Typography>
      <Typography marginBottom={0.5} variant="body1">
        The access tokens created on this page can be used in our SDKs to
        authenticate with the Decentriq Platform.
      </Typography>
      <Typography marginBottom={2} variant="body1">
        For more details, please refer to the{" "}
        <DocsLink
          href={`${docsBaseLink}/python-quick-start`}
          rel="noreferrer"
          target="_blank"
        >
          Python SDK documentation
        </DocsLink>
        {". "}
        {ENABLE_JS_DOCS_REFERENCE && (
          <>
            or the{" "}
            <DocsLink
              href={`${docsBaseLink}/js-quick-start`}
              rel="noreferrer"
              target="_blank"
            >
              JavaScript SDK
            </DocsLink>
            .
          </>
        )}
      </Typography>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
