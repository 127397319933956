import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { EXTERNAL_CONNECTIONS_TYPES } from "features/datasets/components/ExternalConnections";
import {
  useDatasetExportQuery,
  useDatasetExportResultLazyQuery,
  useDatasetImportQuery,
  useDatasetImportResultLazyQuery,
} from "hooks/__generated-new";

type useExternalConnectionDataHookReturn = {
  connectionType: string | undefined;
  createdAt: string | undefined;
  finishedAt: string | undefined;
  name: string | undefined;
  status: string | undefined;
  type: EXTERNAL_CONNECTIONS_TYPES;
};

const useExternalConnectionData = (
  connectionId: string,
  typename: string
): useExternalConnectionDataHookReturn => {
  const { data: { datasetImport } = {} } = useDatasetImportQuery({
    skip: !connectionId || typename !== "DatasetImport",
    variables: { id: connectionId },
  });
  // Fetch basic export data to show on the collapsed item
  const { data: { datasetExport } = {} } = useDatasetExportQuery({
    skip: !connectionId || typename !== "DatasetExport",
    variables: { id: connectionId },
  });
  return {
    connectionType: datasetImport?.sourceType || datasetExport?.targetType,
    createdAt: datasetImport?.createdAt || datasetExport?.createdAt,
    finishedAt: datasetImport?.finishedAt || datasetExport?.finishedAt,
    name: datasetImport?.name || datasetExport?.name,
    status: datasetImport?.status || datasetExport?.status,
    type: isEmpty(datasetImport)
      ? EXTERNAL_CONNECTIONS_TYPES.EXPORT
      : EXTERNAL_CONNECTIONS_TYPES.IMPORT,
  };
};

type useExternalConnectionResultHookReturn = [
  () => void,
  { error: string; isLoading: boolean },
];

const useExternalConnectionResult = (
  connectionId: string,
  typename: string
): useExternalConnectionResultHookReturn => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch enclave data to show on expanded item
  const [fetchDatasetImportResult] = useDatasetImportResultLazyQuery({
    variables: { id: connectionId },
  });

  const [fetchDatasetExportResult] = useDatasetExportResultLazyQuery({
    variables: { id: connectionId },
  });

  const fetchExternalConnectionResult: any = useCallback(async () => {
    setIsLoading(true);
    if (typename === "DatasetImport") {
      const { data: { datasetImport } = {} } = await fetchDatasetImportResult();
      setError(datasetImport?.result?.error || "");
    } else if (typename === "DatasetExport") {
      const { data: { datasetExport } = {} } = await fetchDatasetExportResult();
      setError(datasetExport?.result?.error || "");
    }
    setIsLoading(false);
  }, [fetchDatasetExportResult, fetchDatasetImportResult, typename]);

  return [fetchExternalConnectionResult, { error, isLoading }];
};

export { useExternalConnectionData, useExternalConnectionResult };
