import {
  useDatasetExportQuery,
  useDatasetImportQuery,
} from "hooks/__generated-new";

type ConnectionTooltipData = {
  bucketName: string | undefined;
  objectName: string | undefined;
};

const useGoogleCloudStorageConnectionConfigurationData = (
  connectionId: string,
  typename: string
): ConnectionTooltipData => {
  const {
    data: { datasetImport: { config: datasetImportConfig = {} } = {} } = {},
  } = useDatasetImportQuery({
    skip: !connectionId || typename !== "DatasetImport",
    variables: { id: connectionId },
  });

  const {
    data: { datasetExport: { config: datasetExportConfig = {} } = {} } = {},
  } = useDatasetExportQuery({
    skip: !connectionId || typename !== "DatasetExport",
    variables: { id: connectionId },
  });

  return {
    bucketName:
      datasetImportConfig?.bucketName || datasetExportConfig?.bucketName,
    objectName:
      datasetImportConfig?.objectName || datasetExportConfig?.objectName,
  };
};

export default useGoogleCloudStorageConnectionConfigurationData;
