import { ApolloError } from "@apollo/client";
import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import {
  type ComputeJob,
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  CreateComputeJobDocument,
  DataRoomRequestComputeNodeTypenameDocument,
  type DataRoomRequestComputeNodeTypenameQuery,
  DataRoomRequestTempApproversDocument,
  DevComputeNodeJobDocument,
  type MutationValidateDataRoomRequestArgs,
} from "types/__generated-new";
import { parseDataRoomComputationError } from "utils";
import {
  maybeUseDataRoomSecret,
  parseErrorMessage,
} from "wrappers/ApolloWrapper/helpers";
import { createDataScienceCommit } from "wrappers/ApolloWrapper/helpers/createDataScienceCommit";
import { getDataRoomRequestCommitData } from "wrappers/ApolloWrapper/helpers/getDataRoomRequestCommitData";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeValidateDataRoomRequestResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationValidateDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob>> => {
    const { id, dcrHash, driverAttestationHash, autoFetching } = args.input;
    context.cache.writeQuery({
      data: {
        dataRoomRequest: {
          __typename: "DataRoomRequest",
          id,
          tempApprovers: undefined,
        },
      },
      query: DataRoomRequestTempApproversDocument,
      variables: {
        id,
      },
    });
    const { draftNode, analysts } = await getDataRoomRequestCommitData(
      id,
      context.client
    );
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    await maybeUseDataRoomSecret(sdkSession, context.cache, dcrHash);
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    try {
      const { commitId } = await createDataScienceCommit(
        draftNode as any,
        analysts,
        true,
        client,
        wrapper
      );
      const approvers = await wrapper.retrieveCommitApprovers(commitId);
      context.cache.writeQuery({
        data: {
          dataRoomRequest: {
            __typename: "DataRoomRequest",
            id,
            tempApprovers: approvers,
          },
        },
        query: DataRoomRequestTempApproversDocument,
        variables: {
          id,
        },
      });
      let job;
      const testDatasetsInput = args.input.testDatasets;
      if (testDatasetsInput) {
        const testDatasetsInput = args.input.testDatasets;
        const testDatasets = new Map<string, TestDataset>();
        for (const dataset of testDatasetsInput || []) {
          testDatasets.set(dataset.leafNodeId, {
            key: store.pop(dataset.encryptionKey)!,
            manifestHash: dataset.manifestHash,
          });
        }
        job = await wrapper.createCommitJob([draftNode.id], commitId, {
          dryRun: {
            testDatasets,
          },
        });
      } else {
        job = await wrapper.createCommitJob([draftNode.id], commitId, {
          dryRun: {},
        });
      }
      const encodedDataScienceJobHandle = forge.util.binary.base64.encode(
        new TextEncoder().encode(JSON.stringify(job))
      );
      const result = await context.client.mutate({
        mutation: CreateComputeJobDocument,
        variables: {
          input: {
            computeNodeId: {
              draft: draftNode.id,
            },
            dataRoomHash: dcrHash,
            driverAttestationHash,
            enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
            enclaveComputeJobId: job.jobId,
            purpose: ComputeJobPurpose.Standard,
          },
        },
      });
      const computeJob = result.data?.computeJob.create.record;
      if (computeJob) {
        const request =
          context.cache.readQuery<DataRoomRequestComputeNodeTypenameQuery>({
            query: DataRoomRequestComputeNodeTypenameDocument,
            variables: {
              requestId: id,
            },
          });
        context.client.writeQuery({
          data: {
            draftNode: {
              __typename: request?.dataRoomRequest?.draftNode?.__typename,
              id: request?.dataRoomRequest?.draftNode?.id!,
              job: {
                __typename: "ComputeJob",
                autoFetching: autoFetching
                  ? ComputeJobAutoFetching.Validation
                  : ComputeJobAutoFetching.None,
                createdAt: computeJob.createdAt,
                dataRoomHash: computeJob.dataRoomHash,
                driverAttestationHash: computeJob.driverAttestationHash,
                enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
                enclaveComputeJobId: computeJob.enclaveComputeJobId,
                id: computeJob.id,
                purpose: ComputeJobPurpose.Standard,
                status: computeJob.status,
              },
            },
          },
          query: DevComputeNodeJobDocument,
        });
        return computeJob;
      } else {
        throw new ApolloError({
          errorMessage: "Failed to create job",
          graphQLErrors: result.errors,
        });
      }
    } catch (error) {
      throw parseDataRoomComputationError(
        parseErrorMessage(context.cache, error, args),
        draftNode?.name
      );
    }
  };
