import { testIds } from "@decentriq/utils";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { teal } from "@mui/material/colors";
import { memo } from "react";
import {
  dataRoomSyntheticDataMaskingTypePresentation as dataRoomSyntheticDataMaskTypePresentation,
  dataRoomSyntheticDataNumericMaskingType,
  tableColumnDataTypePresentation,
} from "models";
import { ColumnDataType, type SyntheticMaskType } from "types/__generated-new";

interface MaskingConfigRecordConstructorProps {
  index: number;
  maskedColumnId?: string;
  isSelected: boolean;
  isExisting: boolean;
  isDeleted: boolean;
  disabled?: boolean;
  readonly?: boolean;
  name: string;
  dataType: ColumnDataType;
  maskType?: SyntheticMaskType;
  onCheck: (
    column: {
      dataType: ColumnDataType;
      name: string;
    },
    index: number,
    maskedColumnId?: string
  ) => void;
  onMaskUpdate: (maskedColumnId: string, maskType: SyntheticMaskType) => void;
  onUncheck: (maskedColumnId: string) => void;
  onDelete: (maskedColumnId: string) => void;
}

const MaskingConfigRecordConstructor: React.FC<MaskingConfigRecordConstructorProps> =
  memo(
    ({
      index,
      maskedColumnId,
      name,
      dataType,
      isSelected,
      isExisting,
      isDeleted,
      maskType,
      onCheck,
      onMaskUpdate,
      onUncheck,
      onDelete,
      disabled,
    }) => {
      const style = {
        display: "flex",
        marginBottom: 4,
        padding: 0,
      };
      return (
        <ListItem style={style}>
          <div style={{ flex: 5 }}>
            {name}
            {isDeleted || !isExisting ? (
              <span
                style={{
                  backgroundColor: isDeleted ? "#d32f2f" : teal["600"],
                  borderRadius: "3px",
                  color: "white",
                  marginLeft: ".5rem",
                  padding: "2px",
                  verticalAlign: "middle",
                }}
              >
                {isDeleted ? "Deleted" : "New"}
              </span>
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 2, justifyContent: "center" }}>
            {tableColumnDataTypePresentation.get(dataType)}
          </div>
          <div style={{ display: "flex", flex: 2, justifyContent: "center" }}>
            <Tooltip
              disableFocusListener={true}
              disableTouchListener={true}
              enterDelay={500}
              placement="top"
              title={""}
            >
              <div style={{ display: "flex" }}>
                <Checkbox
                  // @ts-ignore
                  checked={isSelected}
                  disabled={disabled || isDeleted}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(event: any) => {
                    if (isDeleted) return;
                    if (isSelected) {
                      onUncheck(maskedColumnId!);
                    } else {
                      onCheck(
                        {
                          dataType,
                          name,
                        },
                        index,
                        maskedColumnId
                      );
                    }
                  }}
                  size="small"
                  style={{
                    color: "#498E8F",
                    minWidth: 124,
                  }}
                />
              </div>
            </Tooltip>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 3,
              justifyContent:
                isSelected && isDeleted ? "space-between" : "center",
            }}
          >
            {isSelected && !isDeleted ? (
              <Tooltip
                disableFocusListener={true}
                disableTouchListener={true}
                enterDelay={500}
                placement="top"
                title="" // Data type
              >
                <Select
                  data-testid={`${testIds.computeNode.computeNodeEditor.maskingHelper}${name}`}
                  disabled={disabled || isDeleted}
                  fullWidth={true}
                  onChange={(event) => {
                    if (isDeleted) return;
                    onMaskUpdate(
                      maskedColumnId!,
                      event.target.value as SyntheticMaskType
                    );
                  }}
                  size="small"
                  style={{
                    background: "transparent",
                    maxWidth: 240,
                    minWidth: 144,
                    textAlign: "center",
                    width: "100%",
                  }}
                  value={maskType}
                  variant="standard"
                >
                  {Array.from(
                    dataRoomSyntheticDataMaskTypePresentation.entries()
                  )
                    .filter(
                      ([value]) =>
                        dataType === ColumnDataType.Text ||
                        dataRoomSyntheticDataNumericMaskingType.includes(value)
                    )
                    .map(([value, label], index) => (
                      <MenuItem
                        data-testid={`${testIds.computeNode.computeNodeEditor.maskingHelper}${value}`}
                        key={index}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </Tooltip>
            ) : null}
            {isDeleted && (
              <>
                {isSelected ? (
                  <Typography variant="body2">
                    {dataRoomSyntheticDataMaskTypePresentation.get(maskType!)}
                  </Typography>
                ) : undefined}
                <IconButton
                  onClick={() => {
                    onDelete(maskedColumnId!);
                  }}
                  size="small"
                  type="button"
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
                </IconButton>
              </>
            )}
          </div>
        </ListItem>
      );
    }
  );

MaskingConfigRecordConstructor.displayName = "MaskingConfigRecordConstructor";

export default MaskingConfigRecordConstructor;
