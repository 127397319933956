import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { memo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";
import {
  PositiveCurveScoreBar,
  PositiveCurveScoreChart,
} from "../AudienceQualityStatistics";

const AudienceQualityStatisticsDrawer: React.FC = () => {
  const {
    isAdvertiserAudienceQualityDrawerOpen,
    closeAdvertiserAudienceQualityDrawer,
  } = useAdvertiserAudienceGenerator();

  return (
    <Drawer
      PaperProps={{
        sx: {
          display: "block",
          width: "700px",
        },
      }}
      anchor="right"
      onClose={closeAdvertiserAudienceQualityDrawer}
      open={isAdvertiserAudienceQualityDrawerOpen}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        p={2}
        sx={({ palette }) => ({
          borderBottom: `1px solid ${palette.grey[300]}`,
        })}
      >
        <Typography variant="h6" width="100%">
          ROC Curve Score
        </Typography>
        <IconButton
          color="inherit"
          onClick={closeAdvertiserAudienceQualityDrawer}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
        </IconButton>
      </Box>
      <Box p={3}>
        <PositiveCurveScoreBar withChartButton={false} />
        <PositiveCurveScoreChart />
      </Box>
    </Drawer>
  );
};

AudienceQualityStatisticsDrawer.displayName = "AudienceQualityStatisticsDrawer";

export default memo(AudienceQualityStatisticsDrawer);
