import {
  faCaretLeft as faCaretLeftSolid,
  faCaretRight as faCaretRightSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useBoolean, useDebounceFn } from "ahooks";
import { memo, useCallback } from "react";
import { SqlEditorField } from "components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useUpdateSqlComputeNodeMutation,
  useUpdateSqliteComputeNodeMutation,
} from "hooks/__generated-new";
import { WorkerTypeShortName } from "types/__generated-new";
import { FileExplorer, PrivacyFilterEditor } from "./components";
import useSqlComputeNode from "./useSqlComputeNode";

interface SqlComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
  editorOptions?: object;
  hidePrivacyFilter?: boolean;
  fullHeight?: boolean;
  variant: WorkerTypeShortName.Sqlite | WorkerTypeShortName.Sql;
}

const SqlComputeNodeEditor: React.FC<SqlComputeNodeEditorProps> = memo(
  ({
    computeNodeId,
    readOnly,
    fullHeight = false,
    editorOptions,
    hidePrivacyFilter,
    variant,
  }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { node, isLoading } = useSqlComputeNode(computeNodeId);
    const [
      isFileExplorerExpanded,
      { setTrue: expandFileExplorer, setFalse: collapseFileExplorer },
    ] = useBoolean(true);
    const sqlStatement = node?.statement || "";
    const [updateSqlComputeNodeMutation] = useUpdateSqlComputeNodeMutation();
    const [updateSqliteComputeNodeMutation] =
      useUpdateSqliteComputeNodeMutation();
    const updateSqlComputeNode = useCallback(
      async (value: string = "") => {
        try {
          return await (
            variant === WorkerTypeShortName.Sql
              ? updateSqlComputeNodeMutation
              : updateSqliteComputeNodeMutation
          )({
            variables: {
              input: {
                id: computeNodeId,
                statement: value,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The computation could not be updated."
            )
          );
        }
      },
      [
        variant,
        updateSqlComputeNodeMutation,
        updateSqliteComputeNodeMutation,
        computeNodeId,
        enqueueSnackbar,
      ]
    );
    const { run: debouncedUpdateSqlComputeNode } = useDebounceFn(
      updateSqlComputeNode,
      { wait: 750 }
    );
    return isLoading ? null : (
      <Grid
        columnSpacing={isFileExplorerExpanded ? 1 : 0}
        container={true}
        flex={1}
        height={fullHeight ? "100%" : "auto"}
      >
        <Grid
          container={true}
          direction="row"
          height="100%"
          item={true}
          xs={!readOnly && isFileExplorerExpanded ? 9 : 12}
        >
          <Box
            sx={
              !readOnly
                ? { marginRight: 1, width: "calc(100% - 24px)" }
                : { width: "100%" }
            }
          >
            {!hidePrivacyFilter && (
              <PrivacyFilterEditor
                computeNodeId={computeNodeId}
                readOnly={readOnly}
              />
            )}
            <SqlEditorField
              defaultValue={sqlStatement}
              editorOptions={editorOptions}
              onChange={debouncedUpdateSqlComputeNode}
            />
          </Box>
          {!readOnly && (
            <Box
              onClick={() => {
                isFileExplorerExpanded
                  ? collapseFileExplorer()
                  : expandFileExplorer();
              }}
              sx={{
                alignItems: "center",
                background: grey[100],
                cursor: "pointer",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                // maxHeight: "380px",
                width: "16px",
              }}
            >
              <FontAwesomeIcon
                color={grey[500]}
                fixedWidth={true}
                icon={
                  isFileExplorerExpanded ? faCaretRightSolid : faCaretLeftSolid
                }
              />
            </Box>
          )}
        </Grid>
        {!readOnly && isFileExplorerExpanded && (
          <Grid height="100%" item={true} xs={3}>
            <FileExplorer fullHeight={fullHeight} />
          </Grid>
        )}
      </Grid>
    );
  }
);
SqlComputeNodeEditor.displayName = "SqlComputeNodeEditor";

export default SqlComputeNodeEditor;
