import { faArrowUpRightFromSquare as faArrowUpRightFromSquareRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DatasetPopup } from "features/datasets";

type DeleteKeychainItemButtonProps = {
  datasetHash: string;
};

const DeleteKeychainItemButton: React.FC<DeleteKeychainItemButtonProps> = ({
  datasetHash,
}) => {
  const [
    isDatasetPopupOpen,
    { setTrue: openDatasetPopup, setFalse: closeDatasetPopup },
  ] = useBoolean(false);

  return (
    <>
      <IconButton onClick={openDatasetPopup}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faArrowUpRightFromSquareRegular}
        />
      </IconButton>
      <DatasetPopup
        datasetHash={datasetHash}
        onDismiss={closeDatasetPopup}
        open={isDatasetPopupOpen}
      />
    </>
  );
};

DeleteKeychainItemButton.displayName = "DeleteKeychainItem";

export default memo(DeleteKeychainItemButton);
