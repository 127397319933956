import { type ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftDataRoomNodesQuery,
  usePublishedDataRoomNodesQuery,
  useSetComputeNodesOrderMutation,
  useSetDataNodesOrderMutation,
} from "hooks/__generated-new";
import { ComputeNodeTypeNames, DataNodeTypeNames } from "models";
import {
  type DraftMatchNode,
  type DraftPostNode,
  type DraftPreviewNode,
  type DraftRawLeafNode,
  type DraftS3SinkNode,
  type DraftScriptingNode,
  type DraftSqlNode,
  type DraftSyntheticNode,
  type DraftTableLeafNode,
  type PublishedMatchNode,
  type PublishedPostNode,
  type PublishedPreviewNode,
  type PublishedRawLeafNode,
  type PublishedS3SinkNode,
  type PublishedScriptingNode,
  type PublishedSqlNode,
  type PublishedSyntheticNode,
  type PublishedTableLeafNode,
} from "types/__generated-new";

export type HooksDraftDataNode = DraftRawLeafNode | DraftTableLeafNode;
export type HooksDraftComputationNode =
  | DraftMatchNode
  | DraftSqlNode
  | DraftScriptingNode
  | DraftSyntheticNode
  | DraftS3SinkNode
  | DraftPostNode
  | DraftPreviewNode;
export type HooksDraftNode = HooksDraftDataNode | HooksDraftComputationNode;

export type HooksPublishedDataNode =
  | PublishedRawLeafNode
  | PublishedTableLeafNode;
export type HooksPublishedComputationNode =
  | PublishedMatchNode
  | PublishedSqlNode
  | PublishedScriptingNode
  | PublishedSyntheticNode
  | PublishedS3SinkNode
  | PublishedPostNode
  | PublishedPreviewNode;
export type HooksPublishedNode =
  | HooksPublishedDataNode
  | HooksPublishedComputationNode;

export type HooksNode = HooksDraftNode | HooksPublishedNode;

type UseNodesReturn = {
  computeNodesCount: number;
  computeNodesOrder: string[];
  dataNodesCount: number;
  dataNodesOrder: string[];
  error: ApolloError | undefined;
  loading: boolean;
  nodes: HooksNode[];
  reorderComputeNodes: (computeNodesOrder: string[]) => void;
  reorderDataNodes: (dataNodesOrder: string[]) => void;
};

const useNodes = (): UseNodesReturn => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isPublished } = useDataRoom();
  const {
    data: draftData,
    loading: isDraftLoading,
    error: draftError,
  } = useDraftDataRoomNodesQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Computations could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: isPublished,
    variables: { id: dataRoomId },
  });
  const {
    data: publishedData,
    loading: isPublishedLoading,
    error: publishedError,
  } = usePublishedDataRoomNodesQuery({
    context: {
      dataRoomId,
    },
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Computations could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !isPublished,
    variables: { dataRoomId },
  });
  const publishedNodes = publishedData?.publishedDataRoom?.publishedNodes;
  const draftNodes = draftData?.draftDataRoom?.draftNodes?.nodes?.map(
    ({ permissions, ...node }) => ({ permissions: permissions?.nodes, ...node })
  );
  // Map permissions
  const nodes = (isPublished ? publishedNodes : draftNodes) || [];
  const dataNodesCount =
    nodes.filter(({ __typename }) =>
      (Object.values(DataNodeTypeNames) as string[]).includes(__typename!)
    ).length || 0;
  const computeNodesCount =
    nodes.filter(({ __typename }) =>
      (Object.values(ComputeNodeTypeNames) as string[]).includes(__typename!)
    ).length || 0;
  const computeNodesOrder = isPublished
    ? publishedData?.publishedDataRoom?.publishedNodes
        ?.filter(
          (n) =>
            n.__typename !== "PublishedRawLeafNode" &&
            n.__typename !== "PublishedTableLeafNode"
        )
        ?.map((n) => n.id)
    : draftData?.draftDataRoom?.computeNodesOrder || [];
  const dataNodesOrder = isPublished
    ? publishedData?.publishedDataRoom?.publishedNodes
        ?.filter(
          (n) =>
            n.__typename === "PublishedRawLeafNode" ||
            n.__typename === "PublishedTableLeafNode"
        )
        ?.map((n) => n.id)
    : draftData?.draftDataRoom?.dataNodesOrder || [];
  // Nodes reordering
  const [setComputeNodesOrder] = useSetComputeNodesOrderMutation();
  const [setDataNodesOrder] = useSetDataNodesOrderMutation();
  const reorderComputeNodes = useCallback(
    (computeNodesOrder: string[]) => {
      setComputeNodesOrder({
        optimisticResponse: {
          __typename: "Mutation",
          draftDataRoom: {
            __typename: "DraftDataRoomMutations",
            setComputeNodesOrder: {
              __typename: "DraftDataRoom",
              computeNodesOrder,
              id: dataRoomId,
            },
          },
        },
        variables: {
          computeNodesOrder,
          dataRoomId,
        },
      });
    },
    [dataRoomId, setComputeNodesOrder]
  );
  const reorderDataNodes = useCallback(
    (dataNodesOrder: string[]) => {
      setDataNodesOrder({
        variables: {
          dataNodesOrder,
          dataRoomId,
        },
      });
    },
    [dataRoomId, setDataNodesOrder]
  );
  return {
    computeNodesCount,
    computeNodesOrder: computeNodesOrder as string[],
    dataNodesCount,
    dataNodesOrder: dataNodesOrder as string[],
    error: draftError || publishedError,
    loading: isDraftLoading || isPublishedLoading,
    nodes: nodes as HooksNode[],
    reorderComputeNodes,
    reorderDataNodes,
  };
};

export default useNodes;
