import { DataTargetType } from "types/__generated-new";

export const dataTargetTypePresentation = new Map<DataTargetType, string>([
  [DataTargetType.S3, "Amazon S3"],
  [DataTargetType.Meta, "Meta Ads"],
  [DataTargetType.GoogleDv_360, "Google DV 360"],
  [DataTargetType.GoogleCloudStorage, "Google Cloud Storage"],
  [DataTargetType.TradeDesk, "The Trade Desk"],
  [DataTargetType.Azure, "Azure Blob Storage"],
  [DataTargetType.Permutive, "Permutive"],
  [DataTargetType.GoogleAdManager, "Google Ad Manager"],
  [DataTargetType.Sportradar, "Sportradar ad:s DSP"],
]);
