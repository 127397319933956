import { useAuth0 } from "@auth0/auth0-react";
import { useComputeNodesVars, usePublishedDataRoom } from "contexts";
import { useNodes } from "hooks";
import {
  ComputeNodeTypeNames,
  DataNodeTypeNames,
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
} from "models";

const useScriptingNodeInputs = (computeNodeId: string) => {
  const { user: { email: currentUserEmail } = {} } = useAuth0();
  const { nodes } = useNodes() || [];
  const { testing } = usePublishedDataRoom();
  const { executionContext } = useComputeNodesVars();
  const permissionNodes = nodes.filter(({ id }) => id !== computeNodeId);
  const computeNodes = permissionNodes.filter(({ __typename }) =>
    (Object.values(ComputeNodeTypeNames) as string[]).includes(__typename!)
  );
  const dataNodes = permissionNodes.filter(({ __typename }) =>
    (Object.values(DataNodeTypeNames) as string[]).includes(__typename!)
  );
  const allInputs = [
    ...dataNodes.map((node) => {
      const isUnstructured =
        node.__typename === DataNodeTypeNames.DraftRawLeafNode ||
        node.__typename === DataNodeTypeNames.PublishedRawLeafNode;
      const allowedForCurrent =
        executionContext !== "development" ||
        testing ||
        ("nodes" in node.permissions
          ? node.permissions.nodes
          : node.permissions
        ).some((e) => e.participant.userEmail === currentUserEmail);
      return {
        allowedForCurrent,
        computeNodeId: node?.id,
        id: node?.name,
        label: `${node?.name}, ${isUnstructured ? "FILE" : "TABLE"}`,
        name: node?.name,
        path: `${node?.name}${isUnstructured ? "" : "/dataset.csv"}`,
        typename: node?.__typename,
      };
    }),
    ...computeNodes.map((node) => {
      const allowedForCurrent =
        executionContext !== "development" ||
        testing ||
        ("nodes" in node.permissions
          ? node.permissions.nodes
          : node.permissions
        ).some((e) => e.participant.userEmail === currentUserEmail);
      // TODO: address typings
      let airlockedDependencies: ScriptingInputNodeSnapshot[] | undefined;
      if (node.__typename === ComputeNodeTypeNames.PublishedPreviewNode) {
        airlockedDependencies = [
          {
            allowedForCurrent: true,
            computeNodeId: node.dependency.id,
            id: node.dependency.name,
            label: `${node.dependency.name}, ${(
              scriptingNodeGroupLabelsMap[
                node?.dependency?.__typename!
              ] as string
            )?.toUpperCase()}`,
            name: node.dependency.name,
            path: "",
            typename: node.dependency.__typename,
          },
        ];
      }
      return {
        airlockedDependencies,
        allowedForCurrent,
        computeNodeId: node?.id,
        id: node?.name,
        label: `${node?.name}, ${(
          scriptingNodeGroupLabelsMap[node?.__typename!] as string
        )?.toUpperCase()}`,
        name: node?.name,
        path: `${node?.name}/${
          node?.__typename === ComputeNodeTypeNames.DraftScriptingNode ||
          node?.__typename === ComputeNodeTypeNames.PublishedScriptingNode
            ? "*"
            : "dataset.csv"
        }`,
        remainingQuotaBytes: node.remainingQuotaBytes,
        totalQuotaBytes: node.totalQuotaBytes,
        typename: node?.__typename,
        usedQuotaBytes: node.usedQuotaBytes,
      };
    }),
  ] as ScriptingInputNodeSnapshot[];
  return allInputs;
};

export default useScriptingNodeInputs;
