import { type MutationBaseOptions } from "@apollo/client/core/watchQueryOptions";
import { createContext, type ReactNode, useContext, useMemo } from "react";
import {
  type ActiveMarket,
  type OrganizationUser,
  useActiveMarkets,
  useOrganizationUsers,
} from "features/mediaPortalShared";
import { useUserRole } from "hooks";
import {
  type CreateDataPartnerDisplayConfigurationMutationOptions,
  type CreateDataPartnerUsageConfigurationMutationOptions,
  type UpdateDataPartnerDisplayConfigurationMutationOptions,
  useCreateDataPartnerDisplayConfigurationMutation,
  useCreateDataPartnerUsageConfigurationMutation,
  useDataPartnerDisplayConfigurationQuery,
  useDataPartnerUsageConfigurationsQuery,
  useDeleteDataPartnerDisplayConfigurationMutation,
  useDeleteDataPartnerUsageConfigurationMutation,
  useUpdateDataPartnerDisplayConfigurationMutation,
} from "hooks/__generated-new";
import {
  type DataPartnerDisplayConfiguration,
  DataPartnerDisplayConfigurationDocument,
  type DataPartnerUsageConfiguration,
  DataPartnerUsageConfigurationsDocument,
} from "types/__generated-new";

interface DataPartnerPortalContextValue {
  organizationUsers: OrganizationUser[];
  dataPartnerMarkets: ActiveMarket[];
  usageConfigurations: DataPartnerUsageConfiguration[];
  createUsageConfiguration: (
    args: CreateDataPartnerUsageConfigurationMutationOptions
  ) => Promise<void>;
  deleteUsageConfiguration: (id: string) => Promise<void>;
  displayConfiguration: DataPartnerDisplayConfiguration | null;
  isDisplayConfigurationLoading: boolean;
  createDisplayConfiguration: (
    mutationParams: CreateDataPartnerDisplayConfigurationMutationOptions
  ) => Promise<void>;
  updateDisplayConfiguration: (
    mutationParams: UpdateDataPartnerDisplayConfigurationMutationOptions
  ) => Promise<void>;
  deleteDisplayConfiguration: (id: string) => Promise<void>;
}

const DataPartnerPortalContext =
  createContext<DataPartnerPortalContextValue | null>(null);

interface DataPartnerPortalWrapperProps {
  children: ReactNode;
  organizationId?: string;
}

export const DataPartnerPortalWrapper: React.FC<
  DataPartnerPortalWrapperProps
> = ({ children, organizationId }) => {
  const isAdmin = useMemo(() => Boolean(organizationId), [organizationId]);
  const { organizationId: currentUserOrganizationId } = useUserRole();
  const { organizationUsers } = useOrganizationUsers({
    organizationId: isAdmin ? organizationId : currentUserOrganizationId,
  });
  const { activeMarkets: dataPartnerMarkets } = useActiveMarkets();
  const queryVariabes = useMemo(
    () => ({
      variables: {
        id: organizationId ?? "",
        isAdmin,
      },
    }),
    [organizationId, isAdmin]
  );
  const {
    data: displayConfigurationQueryData,
    loading: isDisplayConfigurationLoading,
  } = useDataPartnerDisplayConfigurationQuery(queryVariabes);
  const {
    data: usageConfigurationsQueryData,
    refetch: refetchUsageConfigurations,
  } = useDataPartnerUsageConfigurationsQuery(queryVariabes);
  const displayConfiguration = useMemo<DataPartnerDisplayConfiguration | null>(
    () =>
      isAdmin
        ? displayConfigurationQueryData?.otherOrganization
            ?.dataPartnerDisplayConfiguration ?? null
        : displayConfigurationQueryData?.ownOrganization.organization
            ?.dataPartnerDisplayConfiguration ?? null,
    [isAdmin, displayConfigurationQueryData]
  );
  const usageConfigurations = useMemo<DataPartnerUsageConfiguration[]>(
    () =>
      isAdmin
        ? usageConfigurationsQueryData?.otherOrganization
            ?.dataPartnerUsageConfigurations?.nodes ?? []
        : usageConfigurationsQueryData?.ownOrganization.organization
            ?.dataPartnerUsageConfigurations?.nodes ?? [],
    [usageConfigurationsQueryData, isAdmin]
  );

  const commonMutationOptions: Pick<MutationBaseOptions, "update"> = {
    update: (cache) => {
      cache.evict({ fieldName: "availableDataPartners" });
    },
  };

  const [createDisplayConfigurationMutation] =
    useCreateDataPartnerDisplayConfigurationMutation(commonMutationOptions);
  const [updateDataPartnerDisplayConfigurationMutation] =
    useUpdateDataPartnerDisplayConfigurationMutation(commonMutationOptions);
  const [deleteDisplayConfigurationMutation] =
    useDeleteDataPartnerDisplayConfigurationMutation(commonMutationOptions);
  const [createUsageConfigurationMutation] =
    useCreateDataPartnerUsageConfigurationMutation(commonMutationOptions);
  const [deleteUsageConfigurationMutation] =
    useDeleteDataPartnerUsageConfigurationMutation(commonMutationOptions);

  const value = useMemo<DataPartnerPortalContextValue>(
    () => ({
      createDisplayConfiguration: async (
        mutationParams: CreateDataPartnerDisplayConfigurationMutationOptions
      ) => {
        const variables = mutationParams.variables!;
        await createDisplayConfigurationMutation({
          ...mutationParams,
          refetchQueries: [
            DataPartnerDisplayConfigurationDocument.definitions[0].name.value,
          ],
          variables: {
            input: {
              ...variables?.input,
              organizationId,
            },
          },
        });
      },
      createUsageConfiguration: async (
        mutationParams: CreateDataPartnerUsageConfigurationMutationOptions
      ) => {
        const variables = mutationParams.variables!;
        await createUsageConfigurationMutation({
          ...mutationParams,
          refetchQueries: [
            DataPartnerUsageConfigurationsDocument.definitions[0].name.value,
          ],
          variables: {
            input: {
              ...variables?.input,
            },
          },
        });
      },
      dataPartnerMarkets,
      deleteDisplayConfiguration: async (id: string) => {
        await deleteDisplayConfigurationMutation({
          refetchQueries: [
            DataPartnerDisplayConfigurationDocument.definitions[0].name.value,
          ],
          variables: {
            id,
          },
        }).then(() => refetchUsageConfigurations());
      },
      deleteUsageConfiguration: async (id: string) => {
        await deleteUsageConfigurationMutation({
          refetchQueries: [
            DataPartnerUsageConfigurationsDocument.definitions[0].name.value,
          ],
          variables: {
            id,
          },
        });
      },
      displayConfiguration,
      isDisplayConfigurationLoading,
      organizationUsers,
      updateDisplayConfiguration: async (
        mutationParams: UpdateDataPartnerDisplayConfigurationMutationOptions
      ) => {
        await updateDataPartnerDisplayConfigurationMutation({
          refetchQueries: [
            DataPartnerDisplayConfigurationDocument.definitions[0].name.value,
          ],
          ...mutationParams,
        });
      },
      usageConfigurations,
    }),
    [
      organizationUsers,
      createUsageConfigurationMutation,
      deleteUsageConfigurationMutation,
      dataPartnerMarkets,
      displayConfiguration,
      isDisplayConfigurationLoading,
      createDisplayConfigurationMutation,
      deleteDisplayConfigurationMutation,
      updateDataPartnerDisplayConfigurationMutation,
      organizationId,
      refetchUsageConfigurations,
      usageConfigurations,
    ]
  );
  return (
    <DataPartnerPortalContext.Provider value={value}>
      {children}
    </DataPartnerPortalContext.Provider>
  );
};

export const useDataPartnerPortal = () => {
  const contextValue = useContext(DataPartnerPortalContext);
  if (contextValue === null) {
    throw new Error(
      "useDataPartnerPortal must be used within a DataPartnerPortalWrapper"
    );
  }
  return contextValue;
};
