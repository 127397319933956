import { testIds } from "@decentriq/utils";
import {
  faPencil as faPencilRegular,
  faPlus as faPlusRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import DataPartnerDisplayConfigurationForm from "../DataPartnerDisplayConfigurationForm/DataPartnerDisplayConfigurationForm";
import {
  type DataPartnerDisplayConfigurationFormValues,
  DataPartnerDisplayConfigurationValidationSchema,
  defaultDataPartnerDisplayConfigurationFormValues,
} from "../DataPartnerDisplayConfigurationForm/model";

interface UpsertDataPartnerDisplayConfigurationDialogProps {
  updating?: boolean;
  open: boolean;
  onClose: () => void;
}

const UpsertDataPartnerDisplayConfigurationDialog: React.FC<
  UpsertDataPartnerDisplayConfigurationDialogProps
> = ({ open, onClose, updating }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.PUBLISHER_PORTAL,
  });
  const { handleSubmit, reset, ...publisherDisplayConfigurationForm } =
    useForm<DataPartnerDisplayConfigurationFormValues>({
      defaultValues: defaultDataPartnerDisplayConfigurationFormValues,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(DataPartnerDisplayConfigurationValidationSchema),
    });
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  const {
    displayConfiguration,
    createDisplayConfiguration,
    updateDisplayConfiguration,
  } = useDataPartnerPortal();
  const displayConfigurationId = useMemo(
    () => displayConfiguration?.id,
    [displayConfiguration?.id]
  );
  const handleCreate = useCallback(
    (values: DataPartnerDisplayConfigurationFormValues) =>
      createDisplayConfiguration({
        onCompleted: () => {
          enqueueSnackbar(`Display configuration has been created`);
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot create display configuration"
            )
          );
        },
        variables: {
          input: {
            ...values,
            collaborationRequestUsers: values.collaborationRequestUsers || [],
          },
        },
      }),
    [createDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpdate = useCallback(
    (id: string, values: DataPartnerDisplayConfigurationFormValues) =>
      updateDisplayConfiguration({
        onCompleted: () => {
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot update display configuration"
            )
          );
        },
        variables: {
          input: {
            collaborationRequestUsers: values.collaborationRequestUsers,
            description: {
              value: values.description,
            },
            id,
            logo: {
              value: values.logo,
            },
            marketIds: values.marketIds,
            name: {
              value: values.name,
            },
          },
        },
      }),
    [updateDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpsert = useCallback(
    async (values: DataPartnerDisplayConfigurationFormValues) => {
      setLoading(true);
      await (updating
        ? handleUpdate(displayConfigurationId!, values)
        : handleCreate(values));
      setLoading(false);
    },
    [updating, displayConfigurationId, handleCreate, handleUpdate, setLoading]
  );
  useEffect(() => {
    if (displayConfiguration && open) {
      const { name, description, collaborationRequestUsers, logo, marketIds } =
        displayConfiguration;
      reset({
        collaborationRequestUsers:
          collaborationRequestUsers ||
          defaultDataPartnerDisplayConfigurationFormValues.collaborationRequestUsers,
        description:
          description ||
          defaultDataPartnerDisplayConfigurationFormValues.description,
        logo: logo || defaultDataPartnerDisplayConfigurationFormValues.logo,
        marketIds:
          marketIds ||
          defaultDataPartnerDisplayConfigurationFormValues.marketIds,
        name: name || defaultDataPartnerDisplayConfigurationFormValues.name,
      });
    }
  }, [displayConfiguration, reset, open]);
  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={onClose} open={open}>
      <FormProvider
        {...publisherDisplayConfigurationForm}
        handleSubmit={handleSubmit}
        reset={reset}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {updating ? "Edit" : "Create"} display configuration
        </DialogTitle>
        <DialogContent>
          <DataPartnerDisplayConfigurationForm />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            color="primary"
            data-testid={
              testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
                .submitButton
            }
            loading={loading}
            loadingPosition="start"
            onClick={handleSubmit(handleUpsert)}
            startIcon={
              <FontAwesomeIcon
                icon={updating ? faPencilRegular : faPlusRegular}
              />
            }
            variant="contained"
          >
            {updating ? "Update" : "Create"}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
UpsertDataPartnerDisplayConfigurationDialog.displayName =
  "UpsertDataPartnerDisplayConfigurationDialog";

export default memo(UpsertDataPartnerDisplayConfigurationDialog);
