import { memo, useCallback } from "react";
import {
  EXTERNAL_CONNECTIONS_TYPES,
  ExternalConnectionStatus,
} from "features/datasets/components/ExternalConnections";
import {
  EXTERNAL_DATA_IMPORT_STEPS,
  useDatasetImportResult,
  useImportExternalDataDialog,
} from "features/datasets/components/ImportExternalData";
import { DataImportExportStatus } from "types/__generated-new";

type ExternalDataImportStatusProps = {
  onClose: () => void;
};

const ExternalDataImportStatus: React.FC<ExternalDataImportStatusProps> = ({
  onClose,
}) => {
  const {
    selectedDataSource,
    datasetImportId,
    setDatasetImportId,
    setImportStep,
    setIsImportCreated,
    isImportCreated,
    datasetImportError,
  } = useImportExternalDataDialog();
  const {
    status: datasetImportStatus = DataImportExportStatus.Pending,
    result: datasetImportResult,
  } = useDatasetImportResult(datasetImportId as string);

  const handlePreviousStepClick = useCallback(() => {
    setDatasetImportId(null);
    setIsImportCreated(false);
    setImportStep(EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM);
  }, [setDatasetImportId, setImportStep, setIsImportCreated]);

  return (
    <ExternalConnectionStatus
      connectionError={datasetImportError}
      isImportExportCreated={isImportCreated}
      onClose={onClose}
      onPreviousStepClick={handlePreviousStepClick}
      result={datasetImportResult}
      selectedConnection={selectedDataSource}
      status={datasetImportStatus}
      type={EXTERNAL_CONNECTIONS_TYPES.IMPORT}
    />
  );
};

ExternalDataImportStatus.displayName = "ExternalDataImportStatus";

export default memo(ExternalDataImportStatus);
