export enum DataRoomActionTypes {
  Hide,
  HideRestoreOrDelete,
  CopyLink,
  Delete,
  Details,
  Duplicate,
  Export,
  ExportFromEnclave,
  OpenPublishedAndProtectedDialog,
  Publish,
  Rename,
  Restore,
  RestoreOrDelete,
  ShowOrganizationLogo,
  ToggleFavorite,
  Validate,
  Stop,
  EnableExpertView,
  RequirePassword,
}

export const IS_ADD_TO_FAVORITES_ENABLED = true;
export const USE_DATA_ROOM_HIDE_DIALOG = false;
