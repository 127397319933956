import { type lookalike_media_request, type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type ApiCoreContextInterface } from "contexts";
import { type ActivationType } from "features/MediaInsightsDcr/models";
import {
  CreateLookalikeMediaComputeJobDocument,
  type CreateLookalikeMediaComputeJobMutation,
  type CreateLookalikeMediaComputeJobMutationVariables,
  GetLookalikeMediaComputeJobDocument,
  type GetLookalikeMediaComputeJobQuery,
  type GetLookalikeMediaComputeJobQueryVariables,
} from "types/__generated-new";
import { parseMediaDataRoomError } from "utils";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";
import { computeCacheKeyString, LruExpiringPromiseCache } from "../LruCache";

// TODO @matyasfodor this file contains a few leftovers from the lookalike media local resolver state management
// and should be cleaned up

interface AudienceSizeValue {
  reach: number;
  size: number;
}

interface AudienceSize {
  audience_type: string;
  audience_sizes: AudienceSizeValue[];
}

export interface AudienceSizesCollection {
  audience_sizes: AudienceSize[];
}

export interface OverlapStatistic {
  audience_type: string;
  advertiser_size: number;
  overlap_size: number;
}

export interface OverlapStatisticsCollection {
  overlap_statistics: OverlapStatistic[];
  total_publisher: number;
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapSegmentsCollection {
  audiences: OverlapSegment[];
}

export interface ModelledAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface ModelledSegment {
  audience_type: string;
  reach: number;
  aggregations: ModelledAggregationsCollection[];
}

export interface ModelledSegmentsCollection {
  audiences: ModelledSegment[];
}

export interface ActivatedAudience {
  activation_type: ActivationType;
  audience_type: string;
  reach: number;
  is_published: boolean;
}

export interface ActivatedAudiencesConfigWrapper {
  advertiser_manifest_hash: string;
  activated_audiences: ActivatedAudience[];
}

export interface OverlapInsightsCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash: string;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
}

export interface ModelledOverlapInsightsCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash: string;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  activatedAudiences: ActivatedAudience[];
}

export interface LookalikeAudienceCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash: string;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  activatedAudiences: ActivatedAudience[];
  audienceType: string;
  reach: number;
}

export interface LookalikeAdvertiserDataReportCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
}

export async function getOverlapInsightsCacheKey(
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<OverlapInsightsCacheKey | null> {
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherUsersDatasetHash == null ||
    datasets.publisherSegmentsDatasetHash == null
  ) {
    return null;
  }

  return {
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
    publisherSegmentsDatasetHash: datasets.publisherSegmentsDatasetHash,
    publisherUsersDatasetHash: datasets.publisherUsersDatasetHash,
    ...(datasets.publisherDemographicsDatasetHash && {
      publisherDemographicsDatasetHash:
        datasets.publisherDemographicsDatasetHash,
    }),
    ...(datasets.publisherEmbeddingsDatasetHash && {
      publisherEmbeddingsDatasetHash: datasets.publisherEmbeddingsDatasetHash,
    }),
  };
}

async function getAdvertiserDataReportCacheKey(
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<LookalikeAdvertiserDataReportCacheKey | null> {
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  if (datasets.advertiserDatasetHash == null) {
    return null;
  }

  return {
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
  };
}

export async function getModelledOverlapInsightsCacheKey({
  client,
  sessionManager,
  dataRoomId,
  driverAttestationHash,
  forceRecompute,
}: {
  client: ApiCoreContextInterface["client"];
  sessionManager: ApiCoreContextInterface["sessionManager"];
  dataRoomId: string;
  driverAttestationHash: string;
  forceRecompute: boolean;
}): Promise<ModelledOverlapInsightsCacheKey | null> {
  // TODO inject datasets
  // and activated audiences
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  const activatedAudiences = await retrieveActivatedAudiencesConfigCached(
    client,
    sessionManager,
    dataRoomId,
    driverAttestationHash,
    forceRecompute
  );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherUsersDatasetHash == null ||
    datasets.publisherSegmentsDatasetHash == null ||
    activatedAudiences.length === 0
  ) {
    return null;
  }

  return {
    activatedAudiences,
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
    publisherSegmentsDatasetHash: datasets.publisherSegmentsDatasetHash,
    publisherUsersDatasetHash: datasets.publisherUsersDatasetHash,
    ...(datasets.publisherDemographicsDatasetHash && {
      publisherDemographicsDatasetHash:
        datasets.publisherDemographicsDatasetHash,
    }),
    ...(datasets.publisherEmbeddingsDatasetHash && {
      publisherEmbeddingsDatasetHash: datasets.publisherEmbeddingsDatasetHash,
    }),
  };
}

export async function getLookalikeAudienceCacheKey({
  audienceType,
  client,
  dataRoomId,
  driverAttestationHash,
  forceRecompute,
  reach,
  sessionManager,
}: {
  audienceType: string;
  client: ApiCoreContextInterface["client"];
  dataRoomId: string;
  driverAttestationHash: string;
  forceRecompute: boolean;
  reach: number;
  sessionManager: ApiCoreContextInterface["sessionManager"];
}): Promise<LookalikeAudienceCacheKey | null> {
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  const activatedAudiences =
    await retrievePublishedActivatedAudiencesConfigCached(
      client,
      sessionManager,
      dataRoomId,
      driverAttestationHash,
      forceRecompute
    );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherUsersDatasetHash == null ||
    datasets.publisherSegmentsDatasetHash == null ||
    activatedAudiences.length === 0
  ) {
    return null;
  }

  return {
    activatedAudiences,
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    audienceType,
    dataRoomId,
    publisherSegmentsDatasetHash: datasets.publisherSegmentsDatasetHash,
    publisherUsersDatasetHash: datasets.publisherUsersDatasetHash,
    reach,
    ...(datasets.publisherDemographicsDatasetHash && {
      publisherDemographicsDatasetHash:
        datasets.publisherDemographicsDatasetHash,
    }),
    ...(datasets.publisherEmbeddingsDatasetHash && {
      publisherEmbeddingsDatasetHash: datasets.publisherEmbeddingsDatasetHash,
    }),
  };
}

export async function getViewModelledOverlapInsightsCacheKey({
  client,
  sessionManager,
  dataRoomId,
  driverAttestationHash,
  forceRecompute,
}: {
  client: ApiCoreContextInterface["client"];
  sessionManager: ApiCoreContextInterface["sessionManager"];
  dataRoomId: string;
  driverAttestationHash: string;
  forceRecompute: boolean;
}): Promise<ModelledOverlapInsightsCacheKey | null> {
  const datasets = await retrievePublishedDatasetsCached(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  const activatedAudiences =
    await retrievePublishedActivatedAudiencesConfigCached(
      client,
      sessionManager,
      dataRoomId,
      driverAttestationHash,
      forceRecompute
    );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherUsersDatasetHash == null ||
    datasets.publisherSegmentsDatasetHash == null ||
    activatedAudiences.length === 0
  ) {
    return null;
  }

  return {
    activatedAudiences,
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
    publisherDemographicsDatasetHash: datasets.publisherDemographicsDatasetHash,
    publisherEmbeddingsDatasetHash: datasets.publisherEmbeddingsDatasetHash,
    publisherSegmentsDatasetHash: datasets.publisherSegmentsDatasetHash,
    publisherUsersDatasetHash: datasets.publisherUsersDatasetHash,
  };
}

const cache = {
  activatedAudiences: new LruExpiringPromiseCache<string, ActivatedAudience[]>(
    5,
    5 * 60 * 1000
  ),
  publishedActivatedAudiences: new LruExpiringPromiseCache<
    string,
    ActivatedAudience[]
  >(5, 5 * 60 * 1000),
  publishedDatasets: new LruExpiringPromiseCache<string, PublishedDatasets>(
    5,
    5 * 60 * 1000
  ),
};

interface PublishedDatasets {
  advertiserDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  publisherSegmentsDatasetHash?: string | null;
  publisherUsersDatasetHash?: string | null;
}

export async function retrievePublishedDatasets(
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<PublishedDatasets> {
  const session = await sessionManager.get({ driverAttestationHash });
  const request: lookalike_media_request.LookalikeMediaRequest = {
    retrievePublishedDatasets: {
      dataRoomIdHex: dataRoomId,
    },
  };
  const response = await session.sendLookalikeMediaRequest(request);
  if (!("retrievePublishedDatasets" in response)) {
    throw new Error("Expected retrievePublishedDatasets response");
  }
  return {
    advertiserDatasetHash:
      response.retrievePublishedDatasets.advertiserDatasetHashHex,
    publisherDemographicsDatasetHash:
      response.retrievePublishedDatasets.demographicsDatasetHashHex,
    publisherEmbeddingsDatasetHash:
      response.retrievePublishedDatasets.embeddingsDatasetHashHex,
    publisherSegmentsDatasetHash:
      response.retrievePublishedDatasets.segmentsDatasetHashHex,
    publisherUsersDatasetHash:
      response.retrievePublishedDatasets.publisherDatasetHashHex,
  };
}

export async function retrievePublishedDatasetsCached(
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<PublishedDatasets> {
  const cached = cache.publishedDatasets.get(dataRoomId);
  if (cached !== undefined) {
    return await cached;
  }
  const promise = retrievePublishedDatasets(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );
  cache.publishedDatasets.put(dataRoomId, promise);
  return await promise;
}

interface MediaJob {
  jobIdHex: string;
  computeNodeName: string;
}

// TODO @matyasfodor rescue this
export async function getMediaJob(
  session: Session,
  apolloClient: LocalResolverContext["client"],
  dataRoomId: string,
  jobType: MediaJobType,
  cacheKey: any,
  forceRecompute: boolean
): Promise<MediaJob | null> {
  if (forceRecompute) {
    return null;
  } else {
    const publishedDatasets =
      (await session.retrievePublishedDatasets(dataRoomId)).publishedDatasets ||
      [];
    const cacheKeyString = computeCacheKeyString({
      ...cacheKey,
      publishedDatasets,
    });
    const result = await apolloClient.query<
      GetLookalikeMediaComputeJobQuery,
      GetLookalikeMediaComputeJobQueryVariables
    >({
      query: GetLookalikeMediaComputeJobDocument,
      variables: {
        input: {
          cacheKey: cacheKeyString,
          jobType,
          publishedDataRoomId: dataRoomId,
        },
      },
    });
    const mediaComputeJob = result.data.mediaComputeJob;
    if (mediaComputeJob) {
      return {
        computeNodeName: mediaComputeJob.computeNodeName,
        jobIdHex: mediaComputeJob.jobIdHex,
      };
    } else {
      return null;
    }
  }
}

// TODO @matyasfodor rescue this
export async function storeMediaJob(
  session: Session,
  apolloClient: LocalResolverContext["client"],
  job: MediaJob,
  dataRoomId: string,
  jobType: MediaJobType,
  cacheKey: any
): Promise<void> {
  const publishedDatasets =
    (await session.retrievePublishedDatasets(dataRoomId)).publishedDatasets ||
    [];
  const cacheKeyString = computeCacheKeyString({
    ...cacheKey,
    publishedDatasets,
  });
  const result = await apolloClient.mutate<
    CreateLookalikeMediaComputeJobMutation,
    CreateLookalikeMediaComputeJobMutationVariables
  >({
    mutation: CreateLookalikeMediaComputeJobDocument,
    variables: {
      input: {
        cacheKey: cacheKeyString,
        computeNodeName: job.computeNodeName,
        jobIdHex: job.jobIdHex,
        jobType,
        publishedDataRoomId: dataRoomId,
      },
    },
  });
  if (result.errors) {
    throw new Error(result.errors.join(", "));
  }
}

type MediaJobType =
  | "LOOKALIKE_MEDIA_OVERLAP_INSIGHTS"
  | "LOOKALIKE_MEDIA_MODELLED_OVERLAP_INSIGHTS"
  | "LOOKALIKE_MEDIA_MODELLED_OVERLAP_INSIGHTS_VIEW"
  | "LOOKALIKE_MEDIA_AUDIENCE_SIZES"
  | "LOOKALIKE_MEDIA_LOOKALIKE_AUDIENCE"
  | "LOOKALIKE_MEDIA_ADVERTISER_DATA_REPORT";

export async function retrieveActivatedAudiencesConfigCached(
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string,
  forceRecompute: boolean
): Promise<ActivatedAudience[]> {
  if (forceRecompute) {
    cache.activatedAudiences.evict(dataRoomId);
  } else {
    const cached = cache.activatedAudiences.get(dataRoomId);
    if (cached !== undefined) {
      return await cached;
    }
  }
  const promise = retrieveActivatedAudiencesConfig(
    client,
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );
  cache.activatedAudiences.put(dataRoomId, promise);
  return await promise;
}

export async function retrieveActivatedAudiencesConfig(
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<ActivatedAudience[]> {
  const [scopeId, session, publishedDatasets] = await Promise.all([
    client.ensureDcrDataScope(dataRoomId),
    sessionManager.get({
      driverAttestationHash,
    }),
    retrievePublishedDatasetsCached(
      sessionManager,
      dataRoomId,
      driverAttestationHash
    ),
  ]);
  const { advertiserDatasetHash } = publishedDatasets;
  if (advertiserDatasetHash === null || advertiserDatasetHash === undefined) {
    throw new Error("Missing advertiser dataset hash");
  }
  const request: lookalike_media_request.LookalikeMediaRequest = {
    viewActivatedAudiences: {
      dataRoomIdHex: dataRoomId,
      scopeIdHex: scopeId,
    },
  };
  const response = await session.sendLookalikeMediaRequest(request);
  if (!("viewActivatedAudiences" in response)) {
    throw new Error("Expected viewActivatedAudiences response");
  }
  const computeNodeName = response.viewActivatedAudiences.computeNodeName;
  const jobIdHex = response.viewActivatedAudiences.jobIdHex;
  const result = await session.getComputationResult(
    { computeNodeId: computeNodeName, jobId: jobIdHex },
    { interval: 1 }
  );
  const zip = await loadAsync(result);
  const activatedAudiencesFile = zip.file("activated_audiences.json");
  if (activatedAudiencesFile === null) {
    throw new Error("activated_audiences.json not found in zip");
  }
  const activatedAudiences: ActivatedAudiencesConfigWrapper = JSON.parse(
    await activatedAudiencesFile.async("string")
  );
  if (activatedAudiences.advertiser_manifest_hash !== advertiserDatasetHash) {
    return [];
  }
  return activatedAudiences.activated_audiences;
}

export async function retrievePublishedActivatedAudiencesConfigCached(
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string,
  forceRecompute: boolean
): Promise<ActivatedAudience[]> {
  if (forceRecompute) {
    cache.publishedActivatedAudiences.evict(dataRoomId);
  } else {
    const cached = cache.publishedActivatedAudiences.get(dataRoomId);
    if (cached !== undefined) {
      return await cached;
    }
  }
  const promise = retrievePublishedActivatedAudiencesConfig(
    client,
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );
  cache.publishedActivatedAudiences.put(dataRoomId, promise);
  return await promise;
}

export async function retrievePublishedActivatedAudiencesConfig(
  client: ApiCoreContextInterface["client"],
  sessionManager: ApiCoreContextInterface["sessionManager"],
  dataRoomId: string,
  driverAttestationHash: string
): Promise<ActivatedAudience[]> {
  try {
    const [scopeId, session, publishedDatasets] = await Promise.all([
      client.ensureDcrDataScope(dataRoomId),
      sessionManager.get({
        driverAttestationHash,
      }),
      retrievePublishedDatasetsCached(
        sessionManager,
        dataRoomId,
        driverAttestationHash
      ),
    ]);
    const { advertiserDatasetHash } = publishedDatasets;
    if (advertiserDatasetHash === null || advertiserDatasetHash === undefined) {
      throw new Error("Missing advertiser dataset hash");
    }
    const request: lookalike_media_request.LookalikeMediaRequest = {
      viewPublishedActivatedAudiences: {
        dataRoomIdHex: dataRoomId,
        scopeIdHex: scopeId,
      },
    };
    const response = await session.sendLookalikeMediaRequest(request);
    if (!("viewPublishedActivatedAudiences" in response)) {
      throw new Error("Expected viewActivatedAudiences response");
    }
    const computeNodeName =
      response.viewPublishedActivatedAudiences.computeNodeName;
    const jobIdHex = response.viewPublishedActivatedAudiences.jobIdHex;
    const result = await session.getComputationResult(
      { computeNodeId: computeNodeName, jobId: jobIdHex },
      { interval: 1 }
    );
    const zip = await loadAsync(result);
    const activatedAudiencesFile = zip.file("activated_audiences.json");
    if (activatedAudiencesFile === null) {
      throw new Error("activated_audiences.json not found in zip");
    }
    const activatedAudiences: ActivatedAudiencesConfigWrapper = JSON.parse(
      await activatedAudiencesFile.async("string")
    );
    if (activatedAudiences.advertiser_manifest_hash !== advertiserDatasetHash) {
      return [];
    }
    return activatedAudiences.activated_audiences;
  } catch (error) {
    throw parseMediaDataRoomError(error);
  }
}
