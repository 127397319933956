import { faGear, faKey, faShield } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ImportExternalDataFormProps } from "features/datasets/components/ImportExternalData";

const snowflakeFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    databaseName: yup.string().trim().required("Database name is required"),
    schemaName: yup.string().trim().required("Schema name is required"),
    stageName: yup.string().trim().required("Stage name is required"),
    tableName: yup.string().trim().required("Table name is required"),
    warehouseName: yup.string().trim().required("Warehouse name is required"),
  }),
  credentials: yup.object({
    accountId: yup.string().trim().required("Account ID is required"),
    password: yup.string().trim().required("Password is required"),
    role: yup.string().trim().required("Role is required"),
    username: yup.string().trim().required("Username is required"),
  }),
  datasetName: yup.string(),
});

const SnowflakeForm: React.FC<ImportExternalDataFormProps> = ({
  cancelFormHandler,
  submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        databaseName: "",
        schemaName: "",
        stageName: "",
        tableName: "",
        warehouseName: "",
      },
      credentials: {
        accountId: "",
        password: "",
        role: "",
        username: "",
      },
      datasetName: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(snowflakeFormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: any = {}) => {
      const {
        configuration = {},
        credentials = {},
        datasetName = "",
      } = formValues;
      submitFormHandler({
        input: {
          datasetName: datasetName.trim() || configuration?.tableName?.trim(),
          snowflake: {
            credentials,
            sourceConfig: configuration,
          },
        },
      });
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Box>
          <Typography color="inherit">
            <FontAwesomeIcon
              icon={faGear}
              style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
            />
            Configuration
          </Typography>
          <Controller
            control={control}
            name="configuration.warehouseName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.warehouseName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Warehouse name"
                  placeholder="Example: DEV_WAREHOUSE"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.databaseName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.databaseName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Database name"
                  placeholder="Example: TEST_DATABASE"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.schemaName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.schemaName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Schema name"
                  placeholder="Example: TEST_SCHEMA"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.tableName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.tableName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Table name"
                  placeholder="Example: DATA_TABLE"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.stageName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.stageName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Stage name"
                  placeholder="Example: my_int_stage"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="datasetName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.datasetName;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Stored dataset name (optional)"
                  placeholder="Example: decentriq_dataset_name"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
        <Box mb={2} mt={2}>
          <Typography color="inherit">
            <FontAwesomeIcon
              icon={faKey}
              style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
            />
            Credentials
            <Tooltip
              disableFocusListener={true}
              disableTouchListener={true}
              placement="top-start"
              title="Credentials are encrypted and utilized only once by the enclave."
            >
              <FontAwesomeIcon
                color={grey[600]}
                icon={faShield}
                style={{ cursor: "pointer", marginLeft: "4px" }}
              />
            </Tooltip>
          </Typography>
          <Controller
            control={control}
            name="credentials.accountId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.accountId;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Account ID"
                  placeholder="Example: sdkreui-mt37045"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="credentials.role"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.role;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Role"
                  placeholder="Example: PUBLIC"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="credentials.username"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.username;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Username"
                  placeholder="Example: testuser"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="credentials.password"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.credentials?.password;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  autoComplete="off"
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Password"
                  placeholder="Example: Cfw3?F^K46m(fj"
                  size="small"
                  sx={{ mb: 1 }}
                  type="password"
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
        </Box>
      </form>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button color="inherit" onClick={handleSubmit(handleFormSubmit)}>
          Import
        </Button>
      </Box>
    </Box>
  );
};

SnowflakeForm.displayName = "SnowflakeForm";

export default memo(SnowflakeForm);
