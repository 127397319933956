import { DqTable } from "@decentriq/components";
import { faArrowRight, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Box, Chip, CircularProgress, styled, Tooltip } from "@mui/material";
import { format } from "date-fns";
import orderBy from "lodash/orderBy";
import { type MRT_ColumnDef } from "material-react-table";
import { useCallback, useMemo } from "react";
import { usePublishedLookalikeMediaDataRoom } from "contexts";
import { useDataLabsContext } from "features/dataLabs";
import {
  type DataLab,
  matchingColumnFormatToTableColumnFormatType,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";
import { type MatchingColumnFormat } from "types/__generated-new";
import usePublisherDataNodeActions from "../PublisherDataNode/usePublisherDataNodeActions";

interface DataLabConnectionTableProps {
  dataRoomId: string;
  retrieveDatasets: () => Promise<void>;
  onCancel: () => void;
}

const StyledChip = styled(Chip)((theme) => ({
  borderRadius: 0,
  height: "22px",
}));

const DataLabConnectionTable: React.FC<DataLabConnectionTableProps> = ({
  retrieveDatasets,
  dataRoomId,
  onCancel,
}) => {
  const { matchingIdFormat, hashMatchingIdWith } =
    usePublishedLookalikeMediaDataRoom();
  const {
    dataLabs: { data: dataLabsData, loading },
  } = useDataLabsContext();
  const { connectDataLab, connecting, dataLabId } = usePublisherDataNodeActions(
    { retrieveDatasets }
  );
  const handleConnectDataLab = useCallback(
    async (dataLab: DataLab) => {
      await connectDataLab(dataLab);
      onCancel();
    },
    [connectDataLab, onCancel]
  );
  const dateFormat = "dd-MM-yyyy";
  const dataLabs = useMemo(
    () =>
      orderBy(
        dataLabsData || [],
        ({ updatedAt }) => new Date(updatedAt),
        "desc"
      ),
    [dataLabsData]
  );
  const dataLabsColumnDef: MRT_ColumnDef<DataLab>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        id: "name",
      },
      {
        Cell: ({ cell }) => {
          const createdAt = cell.getValue<string>();
          return createdAt ? format(new Date(createdAt), dateFormat) : "—";
        },
        accessorKey: "updatedAt",
        header: "Last modified",
        id: "updatedAt",
      },
      {
        Cell: () => "Lookalike preparation Lab",
        header: "Type",
        id: "__typename",
      },
      {
        Cell: ({ cell }) => {
          const matchingIdFormat = cell.getValue<MatchingColumnFormat>();
          const matchingIdHashingAlgorithm =
            cell.row.original.matchingIdHashingAlgorithm;
          return matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat,
            matchingIdHashingAlgorithm,
          });
        },
        accessorKey: "matchingIdFormat",
        header: "Matching ID type",
        id: "matchingIdFormat",
      },
    ],
    []
  );
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="5rem"
        width="100%"
      >
        <CircularProgress color="inherit" size="2.5rem" thickness={1} />
      </Box>
    );
  }
  return (
    <DqTable
      columns={dataLabsColumnDef}
      data={dataLabs}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 150,
        },
      }}
      enableRowActions={true}
      localization={{
        actions: "",
        noRecordsToDisplay: "No data labs found",
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({ row }) => {
        const validated = row.original.isValidated;
        const matchedFormat =
          matchingIdFormat ===
            matchingColumnFormatToTableColumnFormatType.get(
              row.original.matchingIdFormat
            ) &&
          (hashMatchingIdWith ?? null) ===
            (row.original.matchingIdHashingAlgorithm ?? null);
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {validated ? (
              matchedFormat ? (
                <LoadingButton
                  disabled={connecting && dataLabId !== row.original.id}
                  endIcon={
                    <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
                  }
                  loading={connecting && dataLabId === row.original.id}
                  loadingPosition="end"
                  onClick={() => handleConnectDataLab(row.original)}
                  size="small"
                  style={{ marginLeft: "16px" }}
                >
                  Provision
                </LoadingButton>
              ) : (
                <Tooltip
                  disableFocusListener={true}
                  disableTouchListener={true}
                  placement="top-start"
                  title="Datasets from this Data Lab use a matching ID that is not compatible with the matching ID going to be used by the Advertiser in this Data Clean Room."
                >
                  <StyledChip
                    color={"warning"}
                    icon={<FontAwesomeIcon icon={faWarning} />}
                    label={"Different Matching ID"}
                  />
                </Tooltip>
              )
            ) : (
              <Tooltip
                disableFocusListener={true}
                disableTouchListener={true}
                placement="top-start"
                title="Please go to this Data Lab, review the provisioned datasets and compute statistics to have it validated."
              >
                <StyledChip
                  color={"warning"}
                  icon={<FontAwesomeIcon icon={faWarning} />}
                  label={"Not validated"}
                />
              </Tooltip>
            )}
          </Box>
        );
      }}
    />
  );
};

DataLabConnectionTable.displayName = "DataLabConnectionTable";

export default DataLabConnectionTable;
