import { EMAIL_REGEXP } from "constants/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import * as yup from "yup";
import { AdminDialog } from "components";
import {
  CommonSnackbarOrigin,
  useGeneralSnackbar,
  useIsDecentriqOrganization,
  useUserRole,
} from "hooks";
import {
  useCreateOrganizationUserMutation,
  useOrganizationDomainsLazyQuery,
  useOrganizationNameQuery,
} from "hooks/__generated-new";
import { userRolePresentation } from "models";
import {
  UserFragment,
  UserRole,
  UsersDocument,
  type UsersQuery,
} from "types/__generated-new";

interface AddOrganizationUserDialogProps {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
}

const AddOrganizationUserDialog: React.FC<AddOrganizationUserDialogProps> = ({
  organizationId,
  open,
  onCancel,
}) => {
  const [fetchOrganizationDomains] = useOrganizationDomainsLazyQuery({
    variables: { organizationId },
  });
  const { data: organizationData } = useOrganizationNameQuery({
    fetchPolicy: "cache-only",
  });

  const addOrganizationUserValidationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required("Email is required")
          .matches(EMAIL_REGEXP, "Email is invalid")
          .test(
            "check-domain",
            "Only user emails matching the allowed domain(s) can be added to this organization.",
            async (value = "") => {
              const organizationDomainsData = await fetchOrganizationDomains();
              const organizationDomains =
                organizationDomainsData?.data?.organization?.domains || [];
              const [, domain] = value.split("@");
              return organizationDomains.includes(domain);
            }
          ),
        role: yup
          .string()
          .required("User role is required")
          .oneOf(Object.values(UserRole)),
      }),
    [fetchOrganizationDomains]
  );

  const { handleSubmit, control, reset, watch, setValue, ...restForm } =
    useForm({
      defaultValues: {
        email: "",
        role: UserRole.NormalUser,
      },
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(addOrganizationUserValidationSchema),
    });
  const { isValid } = useFormState({ control });

  const isDecentriqOrganization = useIsDecentriqOrganization(organizationId);
  const { isSuperAdmin } = useUserRole();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const [createOrganizationUser, { loading }] =
    useCreateOrganizationUserMutation({
      onCompleted: () => {
        enqueueSnackbar(`User has been successfully created.`);
        onCancel();
      },
      onError: (error) =>
        enqueueSnackbar(`User could not be created.`, {
          context: error?.message,
          persist: true,
          variant: "error",
        }),
      update: (cache, { data }) => {
        // Add user to the organization
        cache.modify({
          fields: {
            users: (existing = {}) => {
              const userRef = cache.writeFragment({
                data: data?.organization?.createInternalUser?.record,
                fragment: UserFragment,
              });
              return {
                ...existing,
                nodes: [userRef, ...(existing?.nodes || [])],
                totalCount: Math.max((existing?.totalCount || 0) + 1, 0),
              };
            },
          },
          id: cache.identify({
            __typename: "Organization",
            id: organizationId,
          }),
        });

        // List of organizations and their users must be updated only for Decentriq Super Admin
        // as other roles have no permissions to access those lists
        if (!isSuperAdmin) return;

        // Add user to Users list
        const usersList = cache.readQuery<UsersQuery>({
          query: UsersDocument,
        });
        if (!usersList) return;

        cache.writeQuery({
          data: {
            users: {
              nodes: [
                {
                  ...data?.organization?.createInternalUser?.record,
                  organization: {
                    __typename: "Organization",
                    id: organizationId,
                    name: organizationData?.organization?.name,
                  },
                },
                ...(usersList?.users?.nodes || []),
              ],
            },
          },
          query: UsersDocument,
        });
      },
    });

  const handleCreateOrganizationUser = useCallback(() => {
    const { email, role } = restForm.getValues();
    createOrganizationUser({
      variables: {
        input: {
          email,
          organizationId,
          userRole: role,
        },
      },
    });
  }, [createOrganizationUser, organizationId, restForm]);

  useEffect(() => {
    // Clean fields whenever modal is closed
    reset();
  }, [open, reset]);

  const rolesOptions = [
    UserRole.NormalUser,
    UserRole.OrganizationAdmin,
    ...(isDecentriqOrganization && isSuperAdmin
      ? [UserRole.SuperAdmin, UserRole.SuperAdminReadOnly]
      : []),
  ];

  return (
    <AdminDialog
      disabled={!isValid}
      loading={loading}
      onClose={onCancel}
      onConfirm={handleSubmit(handleCreateOrganizationUser)}
      open={open}
      title={
        <Box sx={{ alignItems: "baseline", display: "flex" }}>
          Create organization user
        </Box>
      }
    >
      <form>
        <Controller
          control={control}
          name="email"
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <TextField
                InputProps={{
                  sx: {
                    "& .MuiInput-input": { padding: "7px 0" },
                    "&:before": { borderBottomStyle: "solid" },
                  },
                }}
                error={!isEmpty(errors["email"])}
                fullWidth={true}
                helperText={errors["email"]?.message}
                placeholder="Email"
                size="small"
                variant="standard"
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="role"
          render={({ field }) => {
            return (
              <Select
                displayEmpty={true}
                fullWidth={true}
                size="small"
                style={{ background: "transparent" }}
                sx={{ mt: 2 }}
                variant="standard"
                {...field}
              >
                {(rolesOptions as UserRole[]).map((role) => (
                  <MenuItem key={role} value={role}>
                    {userRolePresentation.get(role)}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </form>
    </AdminDialog>
  );
};

export default AddOrganizationUserDialog;
