import {
  type DataRoomTableColumnBase,
  type SyntheticNodeAccuracy,
} from "models";
import {
  ScriptingLanguage,
  SyntheticMaskType,
  WorkerTypeShortName,
} from "types/__generated-new";

export interface DataRoomComputeNodeBase {
  id: string;
  dataRoomId: string;
  computeNodeId: string;
  computeNodeType: string;
  nodeName: string;
  isSaving: boolean;
  config: {
    config: {
      computation: {
        sqlStatement: string;
        postInput?: string;
        pythonInputs?: string[];
        pythonOutput?: string;
        pythonScript?: string;
        pythonContents?: { name: string; content: string }[];
        rInputs?: string[];
        rOutput?: string;
        rScript?: string;
        rContents?: { name: string; content: string }[];
        syntheticDataSource?: string;
        syntheticDataAccuracy?: number;
        syntheticDataReportWithStats?: boolean;
        syntheticDataMaskingConfig?: DataRoomSyntheticComputationMaskingConfig[];
        syntheticDataSourceUpdatedAt?: string;
        s3EndpointUrl?: string;
        s3Region?: string;
        s3CredentialsFile?: string;
        s3DataSource?: string;
      };
      computationType?: ComputationType;
      isPrivacyFilterEnabled?: boolean;
      privacyFilterValue?: number;
    };
  };
  updatedAt?: string;
}

export const dataRoomSyntheticDataMaskingTypePresentation = new Map<
  SyntheticMaskType,
  string
>([
  [SyntheticMaskType.GenericString, "Generic string"],
  [SyntheticMaskType.GenericNumber, "Generic number"],
  [SyntheticMaskType.Name, "Name"],
  [SyntheticMaskType.Address, "Address"],
  [SyntheticMaskType.Postcode, "Postcode"],
  [SyntheticMaskType.PhoneNumber, "Phone number"],
  [SyntheticMaskType.SocialSecurityNumber, "Social Security Number"],
  [SyntheticMaskType.Email, "Email"],
  [SyntheticMaskType.Date, "Date"],
  [SyntheticMaskType.Timestamp, "Timestamp"],
  [SyntheticMaskType.Iban, "IBAN"],
]);

export const dataRoomSyntheticDataNumericMaskingType = [
  SyntheticMaskType.GenericNumber,
  SyntheticMaskType.Postcode,
  SyntheticMaskType.Timestamp,
];

export interface DataRoomSyntheticComputationMaskingConfig {
  index: number;
  columnDefinition: DataRoomTableColumnBase;
  maskingType: SyntheticMaskType;
  unmasked?: boolean;
}

export type ComputationType =
  | "sql"
  | "python"
  | "s3"
  | "post_compute"
  | "r"
  | "synthetic_data";

export const scriptingComputationTypes: ComputationType[] = ["python", "r"];

export enum ComputationTypes {
  S3 = "s3",
  PYTHON = "python",
  SQL = "sql",
  POST_COMPUTE = "post_compute",
  R = "r",
  SYNTHETIC_DATA = "synthetic_data",
}

export interface DataRoomComputeNodeDefinition {
  index: number;
  name: string;
  computationType: ComputationType;
}

export interface DataRoomComputeNode extends DataRoomComputeNodeDefinition {
  id?: string;
  computeNodeId: string;
  dataRoomId: string;
  ownerEmail?: string;
  participants: DataRoomComputeNodeParticipant[];
  isSaving?: boolean;
  updatedAt?: string;
  isPrivacyFilterEnabled?: boolean;
  privacyFilterValue?: number;
}

export interface DataRoomSqlCompute extends DataRoomComputeNode {
  sqlStatement: string;
  isPrivacyFilterEnabled?: boolean;
  privacyFilterValue?: number;
}

export interface DataRoomPostCompute extends DataRoomComputeNode {
  postInput: string;
}

export interface DataRoomPythonCompute extends DataRoomComputeNode {
  pythonScript: string;
  pythonInputs: string[];
  pythonOutput: string;
  pythonContents: { name: string; content: string }[];
}

export interface DataRoomRCompute extends DataRoomComputeNode {
  rScript: string;
  rInputs: string[];
  rOutput: string;
  rContents: { name: string; content: string }[];
}

export interface DataRoomS3Compute extends DataRoomComputeNode {
  s3EndpointUrl: string;
  s3Region: string;
  s3CredentialsFile: string;
  s3DataSource: string;
}

export interface DataRoomSyntheticCompute extends DataRoomComputeNode {
  syntheticDataSource?: string;
  syntheticDataAccuracy: SyntheticNodeAccuracy;
  syntheticDataReportWithStats?: boolean;
  syntheticDataMaskingConfig?: DataRoomSyntheticComputationMaskingConfig[];
  syntheticDataSourceUpdatedAt?: string;
}

export type ComputationResultLoadingType =
  | "preparing-job"
  | "run"
  | "check"
  | "polling";

export interface DataRoomComputationResult {
  computeNodeId: string;
  hasCompletedRunForJob?: string;
  lastRunAt: string;
  header?: string[];
  result?: Uint8Array;
  isLoading?: boolean;
  loadingType?: ComputationResultLoadingType;
  jobId?: string;
  pollingHandler?: any;
}

export interface DataRoomComputeNodeParticipant {
  userEmail: string;
  jobId?: string;
}

export const PYTHON_INPUT_FILE_PREFIX = "/input";
export const R_INPUT_FILE_PREFIX = "/input";

export const DEFAULT_PYTHON_SCRIPT = `import pandas as pd
import decentriq_util

"""
1) Select datasets and computation results in the "Available data" dropdown to the right.
2) Load them from /input using any read file function or use decentriq_util to read tabular data into a Pandas dataframe.
3) Process the data
4) Write the data to /output using any write file function.
"""

# Import table "my_table" as a Pandas dataframe including column names
df_table = decentriq_util.read_tabular_data("/input/my_table")

# Process data 
df_results = pd.DataFrame([[1,2]], columns=["a", "b"])

# Write results to /output
df_results.to_csv("/output/my_result.csv", index=False, header=True)
`;

export const DEFAULT_R_SCRIPT = `# 1) Select datasets and computation results in the 'Available data' dropdown to the right.
# 2) Load them from /input using any read file function.
# 3) Process the data
# 4) Write the data to /output using any write file function.

# Import table "my_table" as a data frame and set the column names
df_table <- read.csv(file = "/input/my_table/dataset.csv", sep = ",", header = FALSE)
names(df_table) <- c("column_name_1", "column_name_2")

# Process data
df_results <- data.frame(a = 1, b = 2)

# Write results to /output
write.csv(df_results, "/output/my_result.csv", row.names = FALSE)
`;

export const DEFAULT_SCRIPT = new Map<ScriptingLanguage, string>([
  [ScriptingLanguage.Python, DEFAULT_PYTHON_SCRIPT],
  [ScriptingLanguage.R, DEFAULT_R_SCRIPT],
]);

export const scriptingWorkerTypeToScriptingLanguage = new Map<
  WorkerTypeShortName,
  ScriptingLanguage
>([
  [WorkerTypeShortName.Python, ScriptingLanguage.Python],
  [WorkerTypeShortName.R, ScriptingLanguage.R],
]);
