import { Backdrop, CircularProgress, type Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo } from "react";
import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Mainbar } from "components";
import DraftDataRoomTabs from "containers/DraftDataRoomTabs/DraftDataRoomTabs";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDraftDataRoomQuery } from "hooks/__generated-new";
import { DraftDataRoomTab, draftDataRoomTabs } from "models";

interface DraftDataRoomProps {
  dataRoomId: string;
}

const useBackdropStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const defaultTab = DraftDataRoomTab.data;

const DraftDataRoom: React.FC<DraftDataRoomProps> = ({ dataRoomId }) => {
  const { classes: backdropClasses } = useBackdropStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
  const {
    data,
    loading: isDataRoomLoading,
    error,
  } = useDraftDataRoomQuery({
    onError: (error) => {
      enqueueDataRoomSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Draft data clean room could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    variables: {
      dataRoomId,
    },
  });
  // Warning about the native-sql -> sqlite upconversion
  useEffect(() => {
    if (data?.draftDataRoom?.hasImportedNativeSql) {
      enqueueSnackbar({
        context: (
          <>
            <p>
              Existing computations might need a few adaptations to be
              compatible with the standard SQLite syntax. The following
              functions are not fully supported:
            </p>
            <ul>
              <li>
                'k-anonymity privacy filter': not supported in this version
              </li>
              <li>'fuzzymatchstr': not supported</li>
              <li>'percentile_cont': not supported</li>
              <li>'random': only non-cryptographic RNG supported</li>
            </ul>
            <p>
              Please contact support@decentriq.com in case of questions or help
              with adapting your SQL computations.
            </p>
          </>
        ),
        message:
          "This data clean room contains deprecated Native SQL computations which have been automatically converted to SQLite.",
        persist: true,
        variant: "default",
      });
    }
  }, [enqueueSnackbar, data?.draftDataRoom?.hasImportedNativeSql]);
  const ownerEmail = data?.draftDataRoom?.owner?.email;
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const tabs = useMemo(
    () => [
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "/",
      },
      ...draftDataRoomTabs.map((tab) => ({
        element: (
          <DraftDataRoomTabs
            activeTab={tab}
            baseUrl={url}
            ownerEmail={ownerEmail}
          />
        ),
        path: tab,
      })),
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "*",
      },
    ],
    [url, ownerEmail]
  );
  const activeTab = useRoutes(tabs);
  return isDataRoomLoading ? (
    <Backdrop
      classes={backdropClasses}
      invisible={true}
      open={isDataRoomLoading}
    >
      <CircularProgress color="inherit" size="2.5rem" thickness={1} />
    </Backdrop>
  ) : error ? (
    <Navigate replace={true} to="/datarooms" />
  ) : data ? (
    <Fragment>
      <Mainbar />
      {activeTab}
    </Fragment>
  ) : (
    <Navigate replace={true} to="/datarooms" />
  );
};

export default DraftDataRoom;
