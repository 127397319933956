import { memo, useCallback } from "react";
import { ExternalConnectionType } from "features/datasets/components/ExternalConnections";
import {
  EXTERNAL_DATA_IMPORT_STEPS,
  useImportExternalDataDialog,
} from "features/datasets/components/ImportExternalData";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useCreateDatasetImportMutation } from "hooks/__generated-new";
import {
  type CreateDatasetImportMutation,
  type CreateDatasetImportMutationVariables,
  DataSourceType,
} from "types/__generated-new";
import {
  AzureForm,
  GoogleCloudStorageForm,
  PermutiveForm,
  S3BucketForm,
  SalesforceForm,
  SnowflakeForm,
} from "./components";

const ExternalDataSourceForm: React.FC = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    selectedDataSource,
    setDatasetImportId,
    setIsImportCreated,
    setImportStep,
    setDatasetImportError,
  } = useImportExternalDataDialog();

  const [createDatasetImportMutation] = useCreateDatasetImportMutation();

  const submitFormHandler = useCallback(
    (createDatasetImportVariables: CreateDatasetImportMutationVariables) => {
      createDatasetImportMutation({
        onCompleted: ({ createDatasetImport }: CreateDatasetImportMutation) => {
          setDatasetImportId(createDatasetImport?.datasetImport?.id);
          setTimeout(() => setIsImportCreated(true), 3000);
        },
        onError: (error) => {
          setDatasetImportError(error?.message);
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The importing process could not get started. Please try again by refreshing the page."
            )
          );
        },
        variables: createDatasetImportVariables,
      });
      setImportStep(EXTERNAL_DATA_IMPORT_STEPS.IMPORT_STATUS);
    },
    [
      createDatasetImportMutation,
      setImportStep,
      setDatasetImportId,
      setIsImportCreated,
      setDatasetImportError,
      enqueueSnackbar,
    ]
  );

  const cancelFormHandler = useCallback(() => {
    setImportStep(EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE);
  }, [setImportStep]);

  switch (selectedDataSource) {
    case DataSourceType.S3:
      return (
        <S3BucketForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
          type={ExternalConnectionType.IMPORT}
        />
      );
    case DataSourceType.Snowflake:
      return (
        <SnowflakeForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
        />
      );
    case DataSourceType.Azure:
      return (
        <AzureForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
          type={ExternalConnectionType.IMPORT}
        />
      );
    case DataSourceType.GoogleCloudStorage:
      return (
        <GoogleCloudStorageForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
          type={ExternalConnectionType.IMPORT}
        />
      );
    case DataSourceType.Salesforce:
      return (
        <SalesforceForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
        />
      );
    case DataSourceType.Permutive:
      return (
        <PermutiveForm
          cancelFormHandler={cancelFormHandler}
          submitFormHandler={submitFormHandler}
        />
      );
  }
  return null;
};

ExternalDataSourceForm.displayName = "ExternalDataSourceForm";

export default memo(ExternalDataSourceForm);
