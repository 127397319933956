import { data_science } from "@decentriq/core";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import {
  DraftComputeNodeTypenameDocument,
  type MutationPublishDraftDataRoomArgs,
  type PublishDraftDataRoomLocalPayload,
  PublishDraftDataRoomRemoteDocument,
  type PublishDraftDataRoomRemoteMutation,
  type PublishDraftDataRoomRemoteMutationVariables,
} from "types/__generated-new";
import { buildAndPublishDraftDataRoom } from "../../../helpers";
import { type LocalResolverContext } from "../../../models";

export const makePublishDraftDataRoomResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: any,
    {
      input: { password, id, validate, keepTestData },
    }: MutationPublishDraftDataRoomArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<PublishDraftDataRoomLocalPayload> => {
    const getNodeById = (computeNodeId: string) => {
      return context.cache.readQuery({
        query: DraftComputeNodeTypenameDocument,
        variables: {
          computeNodeId,
        },
      });
    };
    try {
      const sdkSession = await sessionManager.get();
      let dcrSecretIdBase64;
      if (password) {
        const dcrSecretId = await sdkSession.retrieveDcrSecretId(password);
        if (!dcrSecretId) {
          throw new Error("Retrieved `dcrSecretId` is invalid");
        } else {
          dcrSecretIdBase64 = forge.util.binary.base64.encode(dcrSecretId);
        }
      }
      if (validate) {
        const validationDcrHash = await buildAndPublishDraftDataRoom(
          id,
          context.client,
          client,
          sdkSession,
          true
        );
        const dataScienceDataRoom =
          await sdkSession.retrieveDataScienceDataRoom(validationDcrHash);
        const wrapper = data_science.createDataScienceDataRoomWrapper(
          validationDcrHash,
          dataScienceDataRoom!,
          sdkSession
        );
        await wrapper.testAllComputations();
      }
      const finalDcrHash = await buildAndPublishDraftDataRoom(
        id,
        context.client,
        client,
        sdkSession,
        false,
        dcrSecretIdBase64
      );
      const driverAttestationHash =
        sdkSession.metaData.driverAttestationSpecificationHash;
      const result = await context.client.mutate<
        PublishDraftDataRoomRemoteMutation,
        PublishDraftDataRoomRemoteMutationVariables
      >({
        mutation: PublishDraftDataRoomRemoteDocument,
        variables: {
          input: {
            id,
            publishedDataRoomDriverAttestationHash: driverAttestationHash,
            publishedDataRoomEnclaveId: finalDcrHash,
          },
        },
      });
      return { id: result.data?.draftDataRoom.publish.record.id! };
    } catch (err) {
      const node = getNodeById(err?.extraInfo?.nodeId);
      const errorMessage =
        err?.extraInfo?.nodeName || node?.draftNode?.name
          ? `${err?.extraInfo?.nodeName || node?.draftNode?.name}: ${
              err.message
            }`
          : err.message;
      throw new Error(errorMessage);
    }
  };
