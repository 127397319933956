import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDataLabContext, useDataLabSnackbar } from "features/dataLabs";
import { getEffectiveErrorMessage } from "utils";

interface DataLabDeleteDialogProps {
  id: string;
  open: boolean;
  onCancel?: () => void;
}

const DataLabDeleteDialog: React.FC<DataLabDeleteDialogProps> = ({
  id,
  onCancel,
  open,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useDataLabSnackbar();
  const { deleteDataLab } = useDataLabContext();
  const handleDataLabDeleting = useCallback(async () => {
    setIsDeleting(true);
    try {
      await deleteDataLab();
    } catch (error) {
      enqueueSnackbar(`Data Lab could not be deleted.`, {
        context: getEffectiveErrorMessage(error),
        persist: true,
        variant: "error",
      });
      setIsDeleting(false);
    }
  }, [deleteDataLab, setIsDeleting, enqueueSnackbar]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={isDeleting ? undefined : onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to delete this Data Lab?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          You will not be able anymore to use it in the Media DCR.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={isDeleting} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={isDeleting}
          loadingPosition="start"
          onClick={handleDataLabDeleting}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DataLabDeleteDialog.displayName = "DataLabDeleteDialog";

export default DataLabDeleteDialog;
