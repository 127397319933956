import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import { memo } from "react";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { DQLogo, WideArrowRight } from "features/datasets/icons";
import {
  DataImportExportStatus,
  type DatasetExportResult,
  type DatasetImportResult,
  type DataSourceType,
  type DataTargetType,
} from "types/__generated-new";

export enum EXTERNAL_CONNECTIONS_TYPES {
  EXPORT,
  IMPORT,
}

type ExternalConnectionStatusProps = {
  isImportExportCreated: boolean;
  onClose: () => void;
  onPreviousStepClick: () => void;
  result?: DatasetImportResult | DatasetExportResult | undefined | null;
  selectedConnection: DataSourceType | DataTargetType;
  status?: DataImportExportStatus;
  type: EXTERNAL_CONNECTIONS_TYPES;
  connectionError: string | null;
};

const ExternalConnectionStatusIcon = styled(FontAwesomeIcon)({
  background: "white",
  left: 0,
  margin: "auto",
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
});

// Shared component that is used on the 3rd step of Import/Export flow
const ExternalConnectionStatus: React.FC<ExternalConnectionStatusProps> = ({
  isImportExportCreated,
  onClose,
  onPreviousStepClick,
  result,
  selectedConnection,
  status = DataImportExportStatus.Pending,
  connectionError = "",
  type,
}) => {
  return (
    <>
      <Box
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection:
              type === EXTERNAL_CONNECTIONS_TYPES.IMPORT
                ? "row"
                : "row-reverse",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <ExternalConnectionsIcon
            connectionType={selectedConnection}
            size={ExternalConnectionsIconSize.lg}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              marginLeft: "8px",
              marginRight: "8px",
              position: "relative",
            }}
          >
            <WideArrowRight />
            {status === DataImportExportStatus.Success && (
              <ExternalConnectionStatusIcon
                color="#289090"
                icon={faCircleCheck}
                size="lg"
              />
            )}
            {(status === DataImportExportStatus.Failed || connectionError) && (
              <ExternalConnectionStatusIcon
                color="#F44336"
                icon={faCircleXmark}
                size="lg"
              />
            )}
          </Box>
          <DQLogo />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }}>
          {status === DataImportExportStatus.Pending && !connectionError && (
            <>
              <Typography
                mb={1}
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={24} sx={{ marginRight: "8px" }} />
                {type === EXTERNAL_CONNECTIONS_TYPES.IMPORT
                  ? "Importing"
                  : "Exporting"}{" "}
                in progress...
              </Typography>
              <Typography textAlign="center" variant="body2">
                {isImportExportCreated
                  ? `You can close this window and check the status in the Datasets page "Imports/Exports" tab.`
                  : "Connecting to external source. Please don't close this window yet."}
              </Typography>
            </>
          )}
          {status === DataImportExportStatus.Success && (
            <>
              <Typography mb={1} textAlign="center">
                {type === EXTERNAL_CONNECTIONS_TYPES.IMPORT
                  ? "Importing"
                  : "Exporting"}{" "}
                has completed
              </Typography>
              <Typography textAlign="center" variant="body2">
                {type === EXTERNAL_CONNECTIONS_TYPES.IMPORT
                  ? "Your dataset is ready to be provisioned to data clean rooms. The encryption key is stored in your Keychain."
                  : "Your dataset is ready to be used on the chosen destination"}
              </Typography>
            </>
          )}
          {(status === DataImportExportStatus.Failed || connectionError) && (
            <>
              <Typography mb={1} textAlign="center">
                {type === EXTERNAL_CONNECTIONS_TYPES.IMPORT
                  ? "Importing"
                  : "Exporting"}{" "}
                did not complete
              </Typography>
              {(result?.error || connectionError) && (
                <Typography textAlign="center" variant="body2">
                  <span>Error:</span> {result?.error || connectionError}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        {status === DataImportExportStatus.Failed && (
          <Button color="inherit" onClick={onPreviousStepClick}>
            Back
          </Button>
        )}
        <Button
          color="inherit"
          disabled={
            !(
              connectionError ||
              isImportExportCreated ||
              status === DataImportExportStatus.Failed
            )
          }
          onClick={onClose}
          sx={{ marginLeft: "auto" }}
        >
          Close
        </Button>
      </Box>
    </>
  );
};

ExternalConnectionStatus.displayName = "ExternalConnectionStatus";

export default memo(ExternalConnectionStatus);
