import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftScriptingComputeNodeQuery,
  usePublishedScriptingComputeNodeQuery,
  useScriptingNodeInteractivityPublishedDependenciesQuery,
} from "hooks/__generated-new";
import { type ComputeNodeTypeNames } from "models";
import { type PublishedNode } from "types";
import {
  type DraftNode,
  type DraftNodeConnection,
  type DraftScript,
  type DraftScriptingNode,
  type PublishedNodeConnection,
  type PublishedScript,
  type PublishedScriptingNode,
  type ScriptingLanguage,
} from "types/__generated-new";

const useScriptingComputeNode = (
  computeNodeId: string,
  skip?: boolean
): {
  name: string | undefined;
  isLoading: boolean;
  scriptingLanguage: ScriptingLanguage | undefined;
  scripts: (DraftScript | PublishedScript)[] | undefined;
  dependencies: (DraftNode | PublishedNode)[] | undefined;
  output: string | null | undefined;
  __typename: keyof typeof ComputeNodeTypeNames | undefined;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished, dataRoomId } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const shouldUseDraft = !isPublished || isInteractivityContext;
  // draft branch
  const { data: draftData, loading: isDraftLoading } =
    useDraftScriptingComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !shouldUseDraft || skip,
      variables: { computeNodeId },
    });
  // published branch
  const { data: publishedData, loading: isPublishedLoading } =
    usePublishedScriptingComputeNodeQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraft || skip,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  const { data: interactivityData } =
    useScriptingNodeInteractivityPublishedDependenciesQuery({
      skip: !isInteractivityContext || skip,
      variables: {
        id: dataRoomId,
      },
    });
  const draftNode = draftData?.draftNode as DraftScriptingNode | undefined;
  const publishedNode = publishedData?.publishedNode as
    | PublishedScriptingNode
    | undefined;
  return {
    __typename: shouldUseDraft
      ? draftNode?.__typename
      : publishedNode?.__typename,
    dependencies: shouldUseDraft
      ? (draftNode?.dependencies?.nodes
          ?.map((n) =>
            n.__typename === "DraftNodeConnection"
              ? (n as DraftNodeConnection).draftNode
              : interactivityData?.publishedDataRoom?.publishedNodes?.find(
                  (pN) => pN.id === (n as PublishedNodeConnection).computeNodeId
                )
          )
          ?.filter(Boolean) as (DraftNode | PublishedNode)[])
      : publishedNode?.dependencies,
    isLoading: isDraftLoading || isPublishedLoading,
    name: shouldUseDraft ? draftNode?.name : publishedNode?.name,
    output: shouldUseDraft ? draftNode?.output : publishedNode?.output,
    scriptingLanguage: shouldUseDraft
      ? draftNode?.scriptingLanguage
      : publishedNode?.scriptingLanguage,
    scripts: shouldUseDraft
      ? draftNode?.scripts?.nodes?.map((s) => ({
          ...s,
          __typename: "DraftScript",
        }))
      : publishedNode?.scripts?.map((s) => ({
          ...s,
          __typename: "PublishedScript",
        })),
  };
};

export default useScriptingComputeNode;
