import { InfoTooltip } from "@decentriq/components";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import uniqBy from "lodash/uniqBy";
import {
  type FocusEventHandler,
  memo,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { normalizeEmail } from "features/mediaDataRoom/utils";

const ConfigureStepRoleContainer = styled(FormControl)({
  backgroundColor: "transparent",
  borderColor: "rgba(0, 0, 0, 0.12)",
  borderRadius: "6px",
  borderStyle: "solid",
  borderWidth: "1px",
  marginLeft: "-4px",
  marginRight: 0,
  padding: "8px 12px",
});

interface ConfigureStepRoleProps {
  onEmailAdd: (email: string) => void;
  onEmailRemove: (email: string) => void;
  icon: IconDefinition;
  roleTagLabel: ReactNode;
  emailValues: string[];
  emailPlaceholder?: string;
  fieldName: string;
  mainParticipantValue?: string;
  onMainParticipantChange?: (email: SetStateAction<string>) => void;
}

const ConfigureStepRole: React.FC<ConfigureStepRoleProps> = memo(
  ({
    icon,
    roleTagLabel,
    emailValues = [],
    emailPlaceholder = "Type in user email and press 'Enter'",
    onEmailAdd,
    onEmailRemove,
    fieldName,
    onMainParticipantChange = () => {},
    mainParticipantValue,
  }) => {
    const {
      control: formControl,
      resetField,
      getFieldState,
    } = useFormContext();
    // Add participant to the list to show in chips then
    const handleAddEmailOnKeyDown = useCallback(
      (event: any) => {
        const { target, keyCode } = event || {};
        const { error } = getFieldState(target.name);
        if (keyCode !== 13 || !target.value || error?.message) return;
        onEmailAdd(normalizeEmail(target.value));
        resetField(target.name);
      },
      [getFieldState, onEmailAdd, resetField]
    );
    const handleAddEmailOnBlur = useCallback<
      FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
      (event) => {
        const { target } = event || {};
        const { error } = getFieldState(target.name);
        if (!target.value || error?.message) return;
        onEmailAdd(normalizeEmail(target.value));
        resetField(target.name);
      },
      [getFieldState, onEmailAdd, resetField]
    );

    // Main Participant selector
    const domainsList: { label: string; value: string }[] = useMemo(() => {
      const participants = emailValues.map((email) => {
        const [, emailDomain] = email.split("@");
        return {
          label: emailDomain,
          value: email,
        };
      });
      return uniqBy(participants, "label");
    }, [emailValues]);

    // Main participant email is required only if more than 1 email and they are in different domains (uniqBy in map)
    const isMainParticipantSelectVisible = domainsList.length > 1;

    useEffect(() => {
      const isParticipantExist = domainsList.find(
        ({ value }) => value === mainParticipantValue
      );
      if (!isMainParticipantSelectVisible || !isParticipantExist) {
        onMainParticipantChange(domainsList[0]?.value || "");
      }
    }, [
      domainsList,
      isMainParticipantSelectVisible,
      mainParticipantValue,
      onMainParticipantChange,
    ]);

    return (
      <ConfigureStepRoleContainer sx={{ borderRadius: null, width: "100%" }}>
        <Grid container={true}>
          <Grid container={true} flexDirection="column" item={true} xs={3}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "fit-content",
              }}
            >
              <FontAwesomeIcon icon={icon} size="2x" />
              <Typography sx={{ marginLeft: 1 }}>{roleTagLabel}</Typography>
            </Box>
            {isMainParticipantSelectVisible && (
              <Box sx={{ alignItems: "center", display: "flex", marginTop: 1 }}>
                <Select
                  displayEmpty={true}
                  fullWidth={true}
                  onChange={(event) => {
                    onMainParticipantChange(event.target.value);
                  }}
                  renderValue={(value) => {
                    const participantEmail = domainsList.find(
                      (domain) => domain.value === value
                    )?.label;
                    return participantEmail || "Select organization";
                  }}
                  size="small"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "4px 32px 4px 4px !important",
                    },
                    background: "transparent",
                    maxWidth: "80%",
                  }}
                  value={mainParticipantValue}
                  variant="standard"
                >
                  {domainsList.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <InfoTooltip
                  tooltip="Please select the organization the represents the publisher/advertiser in this DCR"
                  top="1px"
                />
              </Box>
            )}
          </Grid>
          <Grid item={true} xs={9}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Controller
                control={formControl}
                name={fieldName}
                render={({ field, fieldState }) => {
                  const emailError = fieldState?.error?.message;
                  return (
                    <TextField
                      autoComplete="off"
                      error={!!emailError}
                      helperText={emailError}
                      onKeyDown={handleAddEmailOnKeyDown}
                      placeholder={emailPlaceholder}
                      size="small"
                      sx={{ width: "300px" }}
                      variant="standard"
                      {...field}
                      onBlur={(event) => {
                        field.onBlur();
                        handleAddEmailOnBlur(event);
                      }}
                    />
                  );
                }}
              />
              <Box mt={emailValues.length ? 1 : 0}>
                {emailValues.map((email) => (
                  <Chip
                    key={email}
                    label={email}
                    onDelete={() => onEmailRemove(email)}
                    sx={({ shape }) => ({
                      borderRadius: `${shape.borderRadius}px !important`,
                      marginRight: 1,
                    })}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ConfigureStepRoleContainer>
    );
  }
);

ConfigureStepRole.displayName = "ConfigureStepRole";

export default ConfigureStepRole;
