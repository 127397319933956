import { type ApolloError } from "@apollo/client";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { useActiveMarketsQuery } from "hooks/__generated-new";
import { type ActiveMarketsQuery } from "types/__generated-new";

export type ActiveMarket = Omit<
  ActiveMarketsQuery["publisherMarkets"]["nodes"][number],
  "__typename"
>;

interface UseActiveMarketsResult {
  loading: boolean;
  activeMarkets: ActiveMarket[];
  error: ApolloError | undefined;
}

const useActiveMarkets = (): UseActiveMarketsResult => {
  const { data, loading, error } = useActiveMarketsQuery();
  const activeMarkets = useMemo(() => {
    const allMarkets = data?.publisherMarkets.nodes || [];
    const rankedMarkets = sortBy(
      allMarkets.filter(({ rank }) => rank != null),
      "rank"
    );
    const unrankedMarkets = sortBy(
      allMarkets.filter(({ rank }) => rank == null),
      "name"
    );
    return [...rankedMarkets, ...unrankedMarkets];
  }, [data?.publisherMarkets?.nodes]);
  return {
    activeMarkets,
    error,
    loading,
  };
};

export default useActiveMarkets;
