import {
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
} from "@mui/material";
import { sortBy, xor } from "lodash";
import {
  memo,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useAudienceOverlapInsightsData } from "features/mediaDataRoom/hooks";

const StyledMenuItem = styled(MenuItem)({
  disabled: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    opacity: "1 !important",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": { color: "#289090" },
});

type SegmentsSelectorProps = {
  selectedAudienceType: string;
  selectedSegments: string[];
  setSelectedSegments: (segments: SetStateAction<string[]>) => void;
};

const SegmentsSelector: React.FC<SegmentsSelectorProps> = ({
  selectedAudienceType,
  selectedSegments,
  setSelectedSegments,
}) => {
  const { overlapInsights } =
    useAudienceOverlapInsightsData(selectedAudienceType);

  // Set default 5 segments with the highest net propensity
  const defaultSelectedSegments: string[] = useMemo(
    () =>
      sortBy(overlapInsights, ["netPropensity"])
        .slice(-5)
        .map(({ segment }) => segment),
    [overlapInsights]
  );
  useEffect(() => {
    setSelectedSegments(defaultSelectedSegments);
  }, [defaultSelectedSegments, setSelectedSegments]);

  const availableSegments: string[] = overlapInsights.map(
    ({ segment }) => segment
  );

  const handleSelectAll = useCallback(() => {
    if (availableSegments.length !== selectedSegments.length) {
      setSelectedSegments(availableSegments);
    } else {
      setSelectedSegments([]);
    }
  }, [availableSegments, selectedSegments.length, setSelectedSegments]);

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<string[] | string>) => {
      const { value } = event.target;
      const effectiveValue =
        typeof value === "string" ? value.split(",") : value;
      const [focusedValue] = xor(effectiveValue, selectedSegments || []);
      if (!focusedValue) {
        return;
      }
      if (focusedValue === "all") {
        handleSelectAll();
        return;
      }
      if (selectedSegments.includes(focusedValue)) {
        // Remove from array of selected
        const newArray = selectedSegments.filter(
          (segment) => segment !== focusedValue
        );
        setSelectedSegments(newArray);
      } else {
        // Add to array
        setSelectedSegments([...selectedSegments, focusedValue]);
      }
    },
    [handleSelectAll, selectedSegments, setSelectedSegments]
  );

  return (
    <FormControl sx={{ maxWidth: "250px", minWidth: "250px" }}>
      <FormLabel component="legend">Publisher Segments</FormLabel>
      <Select
        defaultValue={defaultSelectedSegments}
        displayEmpty={true}
        fullWidth={true}
        multiple={true}
        onChange={handleSelectChange}
        renderValue={(selected) =>
          `${selected.length} of ${availableSegments.length} segments selected`
        }
        size="small"
        style={{ background: "transparent" }}
        value={selectedSegments}
        variant="standard"
      >
        {overlapInsights.length > 1 ? (
          <StyledMenuItem value="all">
            <StyledCheckbox
              checked={overlapInsights.length === selectedSegments.length}
            />
            Select all
          </StyledMenuItem>
        ) : null}
        {availableSegments.map((segment) => (
          <StyledMenuItem key={segment} value={segment}>
            <StyledCheckbox checked={selectedSegments.includes(segment)} />
            {segment}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SegmentsSelector.displayName = "SegmentsSelector";

export default memo(SegmentsSelector);
