import {
  faBan,
  faEye,
  faEyeSlash,
  faGaugeMax,
  faLink,
  faListCheck,
  faStar,
  faStarHalf,
} from "@fortawesome/pro-light-svg-icons";
import { IconButton, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Actions, DataRoomHideDialog, DataRoomStopDialog } from "components";
import {
  DataRoomActionTypes,
  IS_ADD_TO_FAVORITES_ENABLED,
  USE_DATA_ROOM_HIDE_DIALOG,
} from "features/dataRoom";
import { MediaDataRoomViewConfigurationDialog } from "features/mediaDataRoom";
import PerformanceReportDownloadDialog from "features/mediaDataRoomV2/components/PerformanceReportDownloadDialog/PerformanceReportDownloadDialog";
import {
  useLookalikeMediaDataRoomData,
  usePublishedLookalikeMediaDataRoom,
} from "features/mediaDataRoomV2/contexts";
import {
  usePublishedDataRoomCopyLink,
  usePublishedDataRoomIsHidden,
  usePublishedDataRoomStop,
} from "features/publishedDataRoom";
import { usePublishedDataRoomIsFavorite } from "features/publishedDataRoom/components/PublishedDataRoomActions/hooks";
import {
  mapErrorToGeneralSnackbar,
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import { usePublishedLookalikeMediaDataRoomActionsQuery } from "hooks/__generated-new";
import { DataRoomTypeNames } from "models";

export enum MediaDataRoomActionTypes {
  ViewConfiguration,
  PerformanceReport,
}

interface MediaDataRoomActionsProps {
  id: string;
  withPerformanceReport?: boolean;
  [key: string]: any;
}

const MediaDataRoomActions: React.FC<MediaDataRoomActionsProps> = memo(
  ({
    id,
    withPerformanceReport,
    actions: actionsFunc = (actions: any) => actions,
    inline = false,
    moreIcon,
  }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [
      isMediaDataRoomViewConfigurationDialogOpen,
      {
        setTrue: openMediaDataRoomViewConfigurationDialog,
        setFalse: closeMediaDataRoomViewConfigurationDialog,
      },
    ] = useBoolean(false);
    const [
      isPerformanceReportDownloadDialogOpen,
      {
        setTrue: openPerformanceReportDownloadDialog,
        setFalse: closePerformanceReportDownloadDialog,
      },
    ] = useBoolean(false);
    const { data, loading: isDataRoomLoading } =
      usePublishedLookalikeMediaDataRoomActionsQuery({
        variables: {
          id,
        },
      });
    const createdAt = data?.publishedLookalikeMediaDataRoom?.createdAt;
    const name = data?.publishedLookalikeMediaDataRoom?.name;
    const owner = data?.publishedLookalikeMediaDataRoom?.owner;
    const isStopped = data?.publishedLookalikeMediaDataRoom?.isStopped;
    const driverAttestationHash =
      data?.publishedLookalikeMediaDataRoom?.driverAttestationHash;
    const { isObserver } = usePublishedLookalikeMediaDataRoom();

    const { performanceReportEnabled = false } =
      usePublishedLookalikeMediaDataRoom().supportedFeatures ?? {};

    const publishedAtDate = parseISO(createdAt);

    // Copy link to data clean room
    const { publishedDataRoomCopyLink } = usePublishedDataRoomCopyLink({
      __typename: DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      id,
    });
    const { hasRequiredData } = useLookalikeMediaDataRoomData();
    const dataRoomCopyLink = useCallback(
      () =>
        publishedDataRoomCopyLink()
          .then((wasCopied) => {
            if (wasCopied) {
              enqueueSnackbar("Data clean room link copied to the clipboard.");
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                "Data clean room link could not be generated."
              )
            );
          }),
      [publishedDataRoomCopyLink, enqueueSnackbar]
    );
    // Favorite/unfavorite data clean room
    const {
      publishedDataRoomIsFavorite: isFavorite,
      publishedDataRoomIsFavoriteLoading: isDataRoomIsFavoriteLoading,
      setPublishedDataRoomIsFavorite: setIsFavorite,
      setPublishedDataRoomIsFavoriteLoading: isSetDataRoomIsFavoriteLoading,
    } = usePublishedDataRoomIsFavorite({
      __typename: DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      id,
      skip: isDataRoomLoading,
    });
    const toggleDataRoomIsFavorite = () =>
      setIsFavorite(!isFavorite)
        .then(() => {
          enqueueSnackbar(
            `Data clean room was ${
              !isFavorite ? "added to" : "removed from"
            } Favorites`
          );
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room could not be ${
                !isFavorite ? "added to" : "removed from"
              } Favorites`
            )
          );
        });
    // Archive/restore (hide/unhide) data clean room
    const {
      isHidden,
      loading: isDataRoomIsHiddenLoading,
      setIsHidden,
      setting: isSetDataRoomIsHiddenLoading,
    } = usePublishedDataRoomIsHidden({
      __typename: DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      id,
      skip: isDataRoomLoading,
    });
    const [
      isDataRoomHideDialogOpen,
      { setTrue: openDataRoomHideDialog, setFalse: closeDataRoomHideDialog },
    ] = useBoolean(false);
    const toggleDataRoomIsHidden = useCallback(
      () =>
        setIsHidden(!isHidden)
          .then(() => {
            closeDataRoomHideDialog();
            if (!isHidden) {
              navigate("/datarooms");
            }
            enqueueSnackbar(
              `Data clean room was ${!isHidden ? "hidden" : "unhidden"}`
            );
          })
          .catch((error) => {
            closeDataRoomHideDialog();
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                `Can't ${!isHidden ? "hide" : "unhide"} data clean room`
              )
            );
          }),
      [
        closeDataRoomHideDialog,
        navigate,
        enqueueSnackbar,
        isHidden,
        setIsHidden,
      ]
    );
    // Stop data clean room
    const [
      isDataRoomStopDialogOpen,
      { setTrue: openDataRoomStopDialog, setFalse: closeDataRoomStopDialog },
    ] = useBoolean(false);
    const { isDataRoomStopLoading, stopDataRoomMutation } =
      usePublishedDataRoomStop({
        __typename: DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
        dataRoomId: id,
      });
    const dataRoomStop = useCallback(() => {
      stopDataRoomMutation()
        .then(() => {
          enqueueSnackbar("Data clean room was stopped");
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Can't stop data clean room"
            )
          );
        })
        .finally(() => closeDataRoomStopDialog());
    }, [closeDataRoomStopDialog, enqueueSnackbar, stopDataRoomMutation]);
    const actions = actionsFunc({
      buttons: [
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
          hidden: !IS_ADD_TO_FAVORITES_ENABLED || !isFavorite,
          hover: {
            icon: faStarHalf,
            onClick: toggleDataRoomIsFavorite,
            tooltipTitle: "Remove from Favorites",
          },
          icon: faStar,
          loading:
            isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
          onClick: toggleDataRoomIsFavorite,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: "Favorited",
          type: DataRoomActionTypes.ToggleFavorite,
        },
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
          hidden: !isHidden,
          hover: {
            icon: faEye,
            isRed: false,
            onClick: toggleDataRoomIsHidden,
            tooltipTitle: "Unhide",
          },
          icon: faEyeSlash,
          isRed: false,
          loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
          onClick: toggleDataRoomIsHidden,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: "Hidden",
          type: DataRoomActionTypes.RestoreOrDelete,
        },
      ],
      menuLists: [
        [
          {
            disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
            hidden: !IS_ADD_TO_FAVORITES_ENABLED,
            icon: isFavorite ? faStarHalf : faStar,
            loading:
              isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
            name: isFavorite ? "Remove from Favorites" : "Add to Favorites",
            onClick: toggleDataRoomIsFavorite,
            type: DataRoomActionTypes.ToggleFavorite,
          },
          {
            disabled: false,
            icon: faLink,
            name: "Copy link",
            onClick: dataRoomCopyLink,
            type: DataRoomActionTypes.CopyLink,
          },
          {
            disabled: false,
            icon: faListCheck,
            isRed: false,
            name: "View configuration",
            onClick: openMediaDataRoomViewConfigurationDialog,
            tooltipTitle: "View configuration",
            type: MediaDataRoomActionTypes.ViewConfiguration,
          },
          {
            disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
            icon: isHidden ? faEye : faEyeSlash,
            isRed: false,
            loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
            name: isHidden ? "Unhide" : "Hide",
            onClick: isHidden
              ? toggleDataRoomIsHidden
              : USE_DATA_ROOM_HIDE_DIALOG
                ? openDataRoomHideDialog
                : toggleDataRoomIsHidden,
            type: DataRoomActionTypes.HideRestoreOrDelete,
          },
          {
            hidden: !performanceReportEnabled || !hasRequiredData || isStopped,
            icon: faGaugeMax,
            name: "Performance Report",
            onClick: openPerformanceReportDownloadDialog,
            type: MediaDataRoomActionTypes.PerformanceReport,
          },
          {
            disabled: false,
            hidden: isStopped || isObserver,
            icon: faBan,
            isRed: true,
            loading: false,
            name: "Stop data clean room",
            onClick: openDataRoomStopDialog,
            type: DataRoomActionTypes.Stop,
          },
        ],
        [
          {
            name: (
              <div
                style={{
                  lineHeight: "1.1875rem",
                  opacity: 0.75,
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {isValid(publishedAtDate) ? (
                  <div>
                    <div style={{ marginTop: 4 }}>
                      <strong>Published by</strong>
                    </div>
                    <div>{owner?.email}</div>
                    <div>
                      on {format(publishedAtDate, "dd-MM-yyyy HH:mm:ss")}
                    </div>
                  </div>
                ) : null}
              </div>
            ),
            type: DataRoomActionTypes.Details,
          },
        ],
      ],
    });
    return (
      <Actions
        actions={actions}
        inline={inline}
        moreIcon={moreIcon}
        moreTooltipTitle=""
      >
        <Portal>
          <DataRoomHideDialog
            loading={isSetDataRoomIsHiddenLoading}
            name={name}
            onCancel={closeDataRoomHideDialog}
            onConfirm={toggleDataRoomIsHidden}
            open={isDataRoomHideDialogOpen}
          />
          <MediaDataRoomViewConfigurationDialog
            driverAttestationHash={driverAttestationHash}
            id={id}
            onCancel={closeMediaDataRoomViewConfigurationDialog}
            open={isMediaDataRoomViewConfigurationDialogOpen}
            typename={DataRoomTypeNames.PublishedLookalikeMediaDataRoom}
          />
          <DataRoomStopDialog
            loading={isDataRoomStopLoading}
            name={name}
            onCancel={closeDataRoomStopDialog}
            onConfirm={dataRoomStop}
            open={isDataRoomStopDialogOpen}
          />
          <PerformanceReportDownloadDialog
            onCancel={closePerformanceReportDownloadDialog}
            open={isPerformanceReportDownloadDialogOpen}
          />
        </Portal>
      </Actions>
    );
  }
);

export default MediaDataRoomActions;
