import {
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
  useRequestsVars,
} from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import {
  usePublishedDataRoomParticipantsQuery,
  useRequestComputeNodePermissionsQuery,
  useSubmittedRequestComputeNodePermissionsQuery,
} from "hooks/__generated-new";

const useRequestAnalysts = (computeNodeId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { context } = useRequestsVars();
  const { commitId } = useRequest();
  const shouldUseDraft = context === "draft";
  const { data: publishedData } = usePublishedDataRoomParticipantsQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Data clean room participants could not be retrieved."
        )
      );
    },
    variables: { dataRoomId },
  });
  const { data: requestPermissionsData } =
    useRequestComputeNodePermissionsQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Data clean room participants could not be retrieved."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { computeNodeId },
    });
  const { data: submittedRequestPermissionsData } =
    useSubmittedRequestComputeNodePermissionsQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Data clean room participants could not be retrieved."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  const publishedParticipants =
    publishedData?.publishedDataRoom?.participants?.map(
      ({ permissions, ...participant }) => ({
        ...participant,
        permissions: permissions?.map(({ node }) => ({ ...node })),
      })
    ) || [];
  return {
    __typename: shouldUseDraft
      ? requestPermissionsData?.draftNode.__typename
      : submittedRequestPermissionsData?.publishedNode?.__typename,
    participants: publishedParticipants,
    permissions: shouldUseDraft
      ? requestPermissionsData?.draftNode?.permissions?.nodes || []
      : submittedRequestPermissionsData?.publishedNode?.permissions || [],
    readonly: !shouldUseDraft,
  };
};

export default useRequestAnalysts;
