import { useApolloClient } from "@apollo/client";
import { type Key } from "@decentriq/utils";
import { type media_insights_request } from "ddc";
import * as forge from "node-forge";
import { useCallback } from "react";
import { useApiCore } from "contexts";
import {
  DeprovisionDataLabFromMediaInsightsDcrDocument,
  ProvisionDataLabToMediaInsightsDcrDocument,
} from "types/__generated-new";

interface UseMediaInsightsDcrStateQueryPayload {
  dataRoomId: string;
  driverAttestationHash: string;
}

interface DataRoomInput {
  datasetHash: string;
  encryptionKey: Key;
}

export interface UseMediaInsightsDcrStateQueryResult {
  publishAdvertiserDataset: (props: {
    dataset: DataRoomInput;
  }) => Promise<void>;
  unpublishAdvertiserDataset: () => Promise<void>;
  publishPublisherDatasets: (props: {
    dataLabId: string;
    usersDataset: DataRoomInput;
    segmentsDataset?: DataRoomInput;
    demographicsDataset?: DataRoomInput;
    embeddingsDataset?: DataRoomInput;
  }) => Promise<void>;
  unpublishPublisherDatasets: (props: {
    withDemographicsDataset: boolean;
    withEmbeddingsDataset: boolean;
  }) => Promise<void>;
}

const useMediaInsightsDcrStateQuery = ({
  dataRoomId,
  driverAttestationHash,
}: UseMediaInsightsDcrStateQueryPayload): UseMediaInsightsDcrStateQueryResult => {
  const { client, sessionManager } = useApiCore();
  const apolloClient = useApolloClient();

  const publishAdvertiserDataset: UseMediaInsightsDcrStateQueryResult["publishAdvertiserDataset"] =
    useCallback(
      async ({ dataset }) => {
        const [scopeId, session] = await Promise.all([
          client.ensureDcrDataScope(dataRoomId),
          sessionManager.get({
            driverAttestationHash,
          }),
        ]);
        const encryptionKey: Uint8Array = dataset.encryptionKey.material;
        const request: media_insights_request.MediaInsightsRequest = {
          publishAdvertiserDataset: {
            dataRoomIdHex: dataRoomId,
            datasetHashHex: dataset.datasetHash,
            encryptionKeyHex: forge.util.binary.hex.encode(encryptionKey),
            scopeIdHex: scopeId,
          },
        };
        const response = await session.sendMediaInsightsRequest(request);
        if (!("publishAdvertiserDataset" in response)) {
          throw new Error("Expected publishAdvertiserDataset response");
        }
      },
      [client, dataRoomId, driverAttestationHash, sessionManager]
    );

  const unpublishAdvertiserDataset: UseMediaInsightsDcrStateQueryResult["unpublishAdvertiserDataset"] =
    useCallback(async () => {
      const session = await sessionManager.get({ driverAttestationHash });
      let request: media_insights_request.MediaInsightsRequest = {
        unpublishAdvertiserDataset: {
          dataRoomIdHex: dataRoomId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      if (!("unpublishAdvertiserDataset" in response)) {
        throw new Error("Expected unpublishAdvertiserDataset response");
      }
    }, [dataRoomId, driverAttestationHash, sessionManager]);

  const unpublishPublisherDatasets: UseMediaInsightsDcrStateQueryResult["unpublishPublisherDatasets"] =
    useCallback(
      async ({ withDemographicsDataset, withEmbeddingsDataset }) => {
        const [, session] = await Promise.all([
          client.ensureDcrDataScope(dataRoomId),
          sessionManager.get({
            driverAttestationHash,
          }),
        ]);

        const usersRequest: media_insights_request.MediaInsightsRequest = {
          unpublishPublisherUsersDataset: {
            dataRoomIdHex: dataRoomId,
          },
        };
        const usersResponse =
          await session.sendMediaInsightsRequest(usersRequest);
        if (!("unpublishPublisherUsersDataset" in usersResponse)) {
          throw new Error("Expected unpublishPublisherUsersDataset response");
        }

        const segmentsRequest: media_insights_request.MediaInsightsRequest = {
          unpublishSegmentsDataset: {
            dataRoomIdHex: dataRoomId,
          },
        };
        const segmentsResponse =
          await session.sendMediaInsightsRequest(segmentsRequest);
        if (!("unpublishSegmentsDataset" in segmentsResponse)) {
          throw new Error("Expected unpublishSegmentsDataset response");
        }

        if (withDemographicsDataset) {
          const demographicsRequest: media_insights_request.MediaInsightsRequest =
            {
              unpublishDemographicsDataset: {
                dataRoomIdHex: dataRoomId,
              },
            };
          const demographicsResponse =
            await session.sendMediaInsightsRequest(demographicsRequest);
          if (!("unpublishDemographicsDataset" in demographicsResponse)) {
            throw new Error("Expected unpublishDemographicsDataset response");
          }
        }

        if (withEmbeddingsDataset) {
          const embeddingsRequest: media_insights_request.MediaInsightsRequest =
            {
              unpublishEmbeddingsDataset: {
                dataRoomIdHex: dataRoomId,
              },
            };
          const embeddingsResponse =
            await session.sendMediaInsightsRequest(embeddingsRequest);
          if (!("unpublishEmbeddingsDataset" in embeddingsResponse)) {
            throw new Error("Expected unpublishEmbeddingsDataset response");
          }
        }
        await apolloClient.mutate({
          mutation: DeprovisionDataLabFromMediaInsightsDcrDocument,
          variables: {
            dataRoomId,
          },
        });
      },
      [apolloClient, client, dataRoomId, driverAttestationHash, sessionManager]
    );

  const publishPublisherDatasets: UseMediaInsightsDcrStateQueryResult["publishPublisherDatasets"] =
    useCallback(
      async ({
        dataLabId,
        usersDataset,
        segmentsDataset,
        demographicsDataset,
        embeddingsDataset,
      }) => {
        const [scopeId, session] = await Promise.all([
          client.ensureDcrDataScope(dataRoomId),
          sessionManager.get({
            driverAttestationHash,
          }),
        ]);

        const usersRequest: media_insights_request.MediaInsightsRequest = {
          publishPublisherUsersDataset: {
            dataRoomIdHex: dataRoomId,
            datasetHashHex: usersDataset.datasetHash,
            encryptionKeyHex: forge.util.binary.hex.encode(
              usersDataset.encryptionKey.material
            ),
            scopeIdHex: scopeId,
          },
        };
        const usersResponse =
          await session.sendMediaInsightsRequest(usersRequest);
        if (!("publishPublisherUsersDataset" in usersResponse)) {
          throw new Error("Expected publishPublisherUsersDataset response");
        }

        if (segmentsDataset !== null && segmentsDataset !== undefined) {
          const segmentsRequest: media_insights_request.MediaInsightsRequest = {
            publishSegmentsDataset: {
              dataRoomIdHex: dataRoomId,
              datasetHashHex: segmentsDataset.datasetHash,
              encryptionKeyHex: forge.util.binary.hex.encode(
                segmentsDataset.encryptionKey.material
              ),
              scopeIdHex: scopeId,
            },
          };
          const segmentsResponse =
            await session.sendMediaInsightsRequest(segmentsRequest);
          if (!("publishSegmentsDataset" in segmentsResponse)) {
            throw new Error("Expected publishSegmentsDataset response");
          }
        }

        if (demographicsDataset !== null && demographicsDataset !== undefined) {
          const demographicsRequest: media_insights_request.MediaInsightsRequest =
            {
              publishDemographicsDataset: {
                dataRoomIdHex: dataRoomId,
                datasetHashHex: demographicsDataset.datasetHash,
                encryptionKeyHex: forge.util.binary.hex.encode(
                  demographicsDataset.encryptionKey.material
                ),
                scopeIdHex: scopeId,
              },
            };
          const demographicsResponse =
            await session.sendMediaInsightsRequest(demographicsRequest);
          if (!("publishDemographicsDataset" in demographicsResponse)) {
            throw new Error("Expected publishDemographicsDataset response");
          }
        }

        if (embeddingsDataset !== null && embeddingsDataset !== undefined) {
          const embeddingsRequest: media_insights_request.MediaInsightsRequest =
            {
              publishEmbeddingsDataset: {
                dataRoomIdHex: dataRoomId,
                datasetHashHex: embeddingsDataset.datasetHash,
                encryptionKeyHex: forge.util.binary.hex.encode(
                  embeddingsDataset.encryptionKey.material
                ),
                scopeIdHex: scopeId,
              },
            };
          const embeddingsResponse =
            await session.sendMediaInsightsRequest(embeddingsRequest);
          if (!("publishEmbeddingsDataset" in embeddingsResponse)) {
            throw new Error("Expected publishEmbeddingsDataset response");
          }
        }
        await apolloClient.mutate({
          mutation: ProvisionDataLabToMediaInsightsDcrDocument,
          variables: {
            input: {
              dataLabId,
              dataRoomId,
            },
          },
        });
      },
      [apolloClient, client, dataRoomId, driverAttestationHash, sessionManager]
    );

  return {
    publishAdvertiserDataset,
    publishPublisherDatasets,
    unpublishAdvertiserDataset,
    unpublishPublisherDatasets,
  };
};

export default useMediaInsightsDcrStateQuery;
