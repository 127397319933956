import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDeleteConsentlessAudienceMutation } from "hooks/__generated-new";
import { ConsentlessAudiencesDocument } from "types/__generated-new";

type DeleteConsentlessAudienceDialogProps = {
  audienceId: string;
  open: boolean;
  onCancel: () => void;
};

const DeleteConsentlessAudienceDialog: React.FC<
  DeleteConsentlessAudienceDialogProps
> = ({ audienceId, onCancel, open = false }) => {
  const { dcrHash, driverAttestationHash } = usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [deleteConsentlessAudienceMutation] =
    useDeleteConsentlessAudienceMutation({
      onCompleted: () => {
        enqueueSnackbar("Audience has been successfully deleted");
        onCancel();
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, "Cannot delete an audience")
        );
        onCancel();
      },
    });
  const mediaDeleteConsentlessAudience = useCallback(() => {
    deleteConsentlessAudienceMutation({
      refetchQueries: [
        {
          query: ConsentlessAudiencesDocument,
          variables: {
            id: dcrHash,
            input: {
              dataRoomId: dcrHash,
              driverAttestationHash,
            },
          },
        },
      ],
      variables: {
        input: {
          audienceId,
          dataRoomId: dcrHash,
          driverAttestationHash,
        },
      },
    });
  }, [
    audienceId,
    dcrHash,
    deleteConsentlessAudienceMutation,
    driverAttestationHash,
  ]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to delete this audience?</strong>
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="error"
          onClick={mediaDeleteConsentlessAudience}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangleRegular} />}
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteConsentlessAudienceDialog.displayName = "DeleteConsentlessAudienceDialog";

export default memo(DeleteConsentlessAudienceDialog);
