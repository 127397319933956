import { faBoxArchive } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { OrganizationArchiveDialog } from "components";
import { useOrganizationLicenseQuery } from "hooks/__generated-new";
import { OrganizationState } from "types/__generated-new";

interface OrganizationArchiveButtonProps {
  organizationId: string;
}

const OrganizationArchiveButton: React.FC<OrganizationArchiveButtonProps> = ({
  organizationId,
}) => {
  const [
    isOrganizationArchiveDialogOpen,
    {
      setTrue: openOrganizationArchiveDialog,
      setFalse: closeOrganizationArchiveDialog,
    },
  ] = useBoolean(false);
  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationState = organizationLicenseData?.organization?.state;
  const disabled =
    !organizationState ||
    [OrganizationState.Archived, OrganizationState.Passive].includes(
      organizationState
    );
  return (
    <>
      <Button
        TouchRippleProps={{ center: false }}
        color="error"
        disabled={disabled}
        onClick={openOrganizationArchiveDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faBoxArchive} />}
        sx={{ height: "fit-content" }}
      >
        Archive organization
      </Button>
      <Portal>
        <OrganizationArchiveDialog
          onCancel={closeOrganizationArchiveDialog}
          open={isOrganizationArchiveDialogOpen}
          organizationId={organizationId}
        />
      </Portal>
    </>
  );
};

OrganizationArchiveButton.displayName = "OrganizationArchiveButton";

export default memo(OrganizationArchiveButton);
