import { type FetchResult } from "@apollo/client";
import {
  useCreatePublishedDataRoomIsFavoriteMutation,
  usePublishedDataRoomIsFavoriteQuery,
  usePublishedLookalikeMediaDataRoomIsFavoriteQuery,
  usePublishedMediaDataRoomIsFavoriteQuery,
  usePublishedMediaInsightsDcrIsFavoriteQuery,
  useUpdatePublishedDataRoomIsFavoriteMutation,
} from "hooks/__generated-new";
import { DataRoomTypeNames } from "models";
import {
  type CreatePublishedDataRoomIsFavoriteMutation,
  DataRoomUserSettingsFragment,
  type UpdatePublishedDataRoomIsFavoriteMutation,
} from "types/__generated-new";

interface usePublishedDataRoomIsFavoriteArgs {
  id: string;
  __typename: DataRoomTypeNames;
  skip?: boolean;
}

interface usePublishedDataRoomIsFavoriteResult {
  publishedDataRoomIsFavorite: boolean;
  publishedDataRoomIsFavoriteLoading: boolean;
  setPublishedDataRoomIsFavorite: (
    isFavorite: boolean
  ) =>
    | Promise<FetchResult<UpdatePublishedDataRoomIsFavoriteMutation>>
    | Promise<FetchResult<CreatePublishedDataRoomIsFavoriteMutation>>;
  setPublishedDataRoomIsFavoriteLoading: boolean;
}

const usePublishedDataRoomIsFavorite = ({
  id: dataRoomId,
  __typename,
  skip = false,
}: usePublishedDataRoomIsFavoriteArgs): usePublishedDataRoomIsFavoriteResult => {
  const { data: dcrData, loading: isDcrDataLoading } =
    usePublishedDataRoomIsFavoriteQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedDataRoom,
      variables: { id: dataRoomId },
    });
  const { data: mediaDcrData, loading: isMediaDcrDataLoading } =
    usePublishedMediaDataRoomIsFavoriteQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedMediaDataRoom,
      variables: { id: dataRoomId },
    });
  const {
    data: lookalikeMediaDcrData,
    loading: isLookalikeMediaDcrDataLoading,
  } = usePublishedLookalikeMediaDataRoomIsFavoriteQuery({
    skip:
      skip || __typename !== DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
    variables: { id: dataRoomId },
  });
  const { data: mediaInsightsDcrData, loading: isMediaInsightsDcrDataLoading } =
    usePublishedMediaInsightsDcrIsFavoriteQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedMediaInsightsDcr,
      variables: { id: dataRoomId },
    });
  const userSettingsId =
    dcrData?.publishedDataRoom?.userSettings?.id ||
    mediaDcrData?.publishedMediaDataRoom?.userSettings?.id ||
    lookalikeMediaDcrData?.publishedLookalikeMediaDataRoom?.userSettings?.id ||
    mediaInsightsDcrData?.publishedMediaInsightsDcr?.userSettings?.id;
  const isFavorite =
    dcrData?.publishedDataRoom?.userSettings?.isFavorite ||
    mediaDcrData?.publishedMediaDataRoom?.userSettings?.isFavorite ||
    lookalikeMediaDcrData?.publishedLookalikeMediaDataRoom?.userSettings
      ?.isFavorite ||
    mediaInsightsDcrData?.publishedMediaInsightsDcr?.userSettings?.isFavorite;
  const [
    createPublishedDataRoomIsFavoriteMutation,
    { loading: createPublishedDataRoomIsFavoriteLoading },
  ] = useCreatePublishedDataRoomIsFavoriteMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          userSettings: () => {
            const userSettingsRef = cache.writeFragment({
              data: data?.publishedDataRoom.createUserSettings,
              fragment: DataRoomUserSettingsFragment,
            });
            return userSettingsRef;
          },
        },
        id: cache.identify({
          __typename,
          id: dataRoomId,
        }),
      });
    },
  });
  const [
    updatePublishedDataRoomIsFavoriteMutation,
    { loading: updatePublishedDataRoomIsFavoriteLoading },
  ] = useUpdatePublishedDataRoomIsFavoriteMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          isFavorite: () =>
            data?.publishedDataRoom.updateUserSettings.isFavorite || false,
        },
        id: cache.identify({
          __typename: "DataRoomUserSettings",
          id: data?.publishedDataRoom.updateUserSettings.id,
        }),
      });
    },
  });
  const setIsFavorite = (isFavorite: boolean) => {
    return userSettingsId
      ? updatePublishedDataRoomIsFavoriteMutation({
          variables: { id: userSettingsId, isFavorite },
        })
      : createPublishedDataRoomIsFavoriteMutation({
          variables: { isFavorite, publishedDataRoomId: dataRoomId },
        });
  };
  const setIsFavoriteLoading = userSettingsId
    ? updatePublishedDataRoomIsFavoriteLoading
    : createPublishedDataRoomIsFavoriteLoading;
  return {
    publishedDataRoomIsFavorite: Boolean(isFavorite),
    publishedDataRoomIsFavoriteLoading:
      isDcrDataLoading ||
      isMediaDcrDataLoading ||
      isLookalikeMediaDcrDataLoading ||
      isMediaInsightsDcrDataLoading,
    setPublishedDataRoomIsFavorite: setIsFavorite,
    setPublishedDataRoomIsFavoriteLoading: setIsFavoriteLoading,
  };
};

export default usePublishedDataRoomIsFavorite;
