import { InfoTooltip } from "@decentriq/components";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  styled,
  type Theme,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { PlainTextEditorField } from "components";
import { type ReportErrorInput, useReportError } from "hooks";
import { logError } from "utils";

export enum ErrorReportPriority {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

interface ErrorReportDialogProps {
  open: boolean;
  generateReport: (details: string) => Promise<ReportErrorInput>;
  onCancel: () => void;
  onFinish: () => void;
  defaultDetails: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const useErrorReportDialogStyles = makeStyles()((theme) => ({
  root: {
    zIndex: 2000,
  },
}));

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  border: "1px solid #E0DFDA",
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 1),
}));

const StyledTextarea = styled("textarea")(({ theme }) => ({
  border: "none",
  color: "inherit",
  fontFamily: "inherit",
  resize: "none",
  width: "100%",
}));

const ErrorReportDialog: React.FC<ErrorReportDialogProps> = ({
  open,
  onCancel,
  onFinish,
  generateReport,
  defaultDetails,
  header,
  footer,
}) => {
  const { classes: errorReportDialog } = useErrorReportDialogStyles();
  const reportError = useReportError();
  const [sending, setSending] = useState(false);
  const [priority, setPriority] = useState<ErrorReportPriority>(
    ErrorReportPriority.Medium
  );
  const [moreDetails, setMoreDetails] = useState<string>("");
  const baseDetails = `Priority: ${priority}\n${defaultDetails}`;
  const details = moreDetails.trim()
    ? `${moreDetails}\n-------------------------\n${baseDetails}`
    : baseDetails;
  const handleReportSending = useCallback(async () => {
    try {
      setSending(true);
      const report = await generateReport(details || "");
      await reportError(report);
      onFinish();
    } catch (error) {
      logError(error);
    } finally {
      setSending(false);
    }
  }, [details, reportError, onFinish, generateReport]);
  return (
    <Dialog
      classes={errorReportDialog}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={onCancel}
      open={open}
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Report issue to Decentriq</span>
        <IconButton color="inherit" onClick={onCancel}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel component="legend" sx={{ display: "flex" }}>
            Priority{" "}
            <InfoTooltip
              tooltip={
                <p style={{ margin: 0 }}>
                  High - this issue is blocking your current workflow.
                  <br />
                  Medium - this issue is happening, but it's still possible to
                  continue with your workflow.
                  <br />
                  Low - this is happened but does not impact your current
                  workflow.
                </p>
              }
            />
          </FormLabel>
          <Select
            onChange={({ target }) => {
              setPriority(target.value as ErrorReportPriority);
            }}
            size="small"
            style={{
              background: "transparent",
              minWidth: "200px",
            }}
            value={priority}
            variant="standard"
          >
            {Object.entries(ErrorReportPriority).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {header}
        <StyledBox>
          <Typography variant="body2">
            <StyledTextarea
              onChange={(event) => setMoreDetails(event.target.value)}
              placeholder="More details about the error and context (optional)"
              rows={3}
              value={moreDetails}
            />
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography
            sx={{ marginBottom: (theme) => theme.spacing(1) }}
            variant="body1"
          >
            Error message (this is the full message that will be sent to
            Decentriq)
          </Typography>
          <PlainTextEditorField
            editorOptions={{
              dynamicHeight: false,
              height: 325,
              readOnly: true,
              resizable: true,
              wordWrap: "on",
            }}
            value={details}
          />
        </StyledBox>
        {footer}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="inherit"
          loading={sending}
          loadingPosition="start"
          onClick={handleReportSending}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          variant="contained"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ErrorReportDialog.displayName = "ErrorReportDialog";

export default memo(ErrorReportDialog);
