import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useIsDraftDataRoomOwnerQuery,
  useIsPublishedDataRoomOwnerQuery,
} from "hooks/__generated-new";

const useIsDataRoomOwner = (): boolean => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isPublished } = useDataRoom();
  const { data: isDraftOwner } = useIsDraftDataRoomOwnerQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Data clean room creator could not be determined."
        )
      );
    },
    skip: isPublished,
    variables: { dataRoomId },
  });
  const { data: isPublishedOwner } = useIsPublishedDataRoomOwnerQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Data clean room creator could not be determined."
        )
      );
    },
    skip: !isPublished,
    variables: { dataRoomId },
  });
  const isOwner = isPublished
    ? isPublishedOwner?.publishedDataRoom?.isOwner || false
    : isDraftOwner?.draftDataRoom?.isOwner || false;
  return isOwner;
};

export default useIsDataRoomOwner;
