import { type ApiCoreContextInterface } from "contexts";
import {
  type MutationUnlockDataRoomArgs,
  PublishedDataRoomPasswordFragment,
  type UnlockDataRoomPayload,
} from "types/__generated-new";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeUnlockDataRoomResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    parent = null,
    args: MutationUnlockDataRoomArgs,
    context: LocalResolverContext
  ): Promise<UnlockDataRoomPayload> => {
    const { id, dcrHash, driverAttestationHash, password } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    try {
      await sdkSession.useDataRoomSecret(password);
      await sdkSession.retrieveDataRoomStatus(dcrHash);
      context.client.writeFragment({
        data: { password },
        fragment: PublishedDataRoomPasswordFragment,
        id: context.client.cache.identify({
          __typename: "PublishedDataRoom",
          id,
        }),
      });
      return { id };
    } finally {
      sdkSession.resetDataRoomSecret();
    }
  };
