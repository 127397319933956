import { createContext, type SetStateAction, useContext } from "react";
import { type Audience } from "features/MediaInsightsDcr/models";

export const AdvertiserAudienceGeneratorContext = createContext<{
  isAdvertiserAudienceQualityDrawerOpen: boolean;
  openAdvertiserAudienceQualityDrawer: () => void;
  closeAdvertiserAudienceQualityDrawer: () => void;
  reach: number;
  setReach: (reach: SetStateAction<number>) => void;
  openExcludeSeedAudienceInfoDialog: () => void;
  closeExcludeSeedAudienceInfoDialog: () => void;
  excludeSeedAudience: boolean;
  generateLookalikeAudienceLoading: boolean;
  setExcludeSeedAudience: (
    excludeSeedAudience: SetStateAction<boolean>
  ) => void;
  isExcludeSeedAudienceInfoDialogVisible: boolean;
  availableAudiences: any;
  selectedAudienceType: string | undefined;
  setSelectedAudienceType: (
    audienceType: SetStateAction<string | undefined>
  ) => void;
  selectedLookalikeAudience: any;
  estimatedAudienceSize: number;
  activatedLookalikeAudiences: Audience[];
  generateLookalikeAudience: any;
  selectedAudienceQuality: any;
}>({
  activatedLookalikeAudiences: [],
  availableAudiences: {},
  closeAdvertiserAudienceQualityDrawer: () => {},
  closeExcludeSeedAudienceInfoDialog: () => {},
  estimatedAudienceSize: 0,
  excludeSeedAudience: false,
  generateLookalikeAudience: () => {},
  generateLookalikeAudienceLoading: false,
  isAdvertiserAudienceQualityDrawerOpen: false,
  isExcludeSeedAudienceInfoDialogVisible: false,
  openAdvertiserAudienceQualityDrawer: () => {},
  openExcludeSeedAudienceInfoDialog: () => {},
  reach: 1,
  selectedAudienceQuality: {},
  selectedAudienceType: undefined,
  selectedLookalikeAudience: {},
  setExcludeSeedAudience: () => {},
  setReach: () => {},
  setSelectedAudienceType: () => {},
});

export const AdvertiserAudienceGeneratorProvider =
  AdvertiserAudienceGeneratorContext.Provider;

export const useAdvertiserAudienceGenerator = () =>
  useContext(AdvertiserAudienceGeneratorContext);
