import {
  faLinkSlash as faLinkSlashRegular,
  faUpload as faUploadRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import {
  MediaDataRoomUploadDataDialog,
  MediaDataRoomUserRole,
} from "features/mediaDataRoom";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useAudienceInsightsAdvertiserQuery,
  useAudienceInsightsAdvertiserStatisticsQuery,
  useMediaUnpublishAdvertiserDatasetMutation,
} from "hooks/__generated-new";
import MediaDataRoomParticipantAvatar from "./MediaDataRoomParticipantAvatar";

interface AudienceInsightsAdvertiserProps {
  id: string;
  [key: string]: any;
}

const AudienceInsightsAdvertiser: React.FC<AudienceInsightsAdvertiserProps> =
  memo(({ id }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { advertiserOrganization, isStopped, isDeactivated, isAdvertiser } =
      usePublishedMediaDataRoom();
    const {
      data: audienceInsightsAdvertiserData,
      loading: audienceInsightsAdvertiserLoading,
      called: audienceInsightsAdvertiserCalled,
    } = useAudienceInsightsAdvertiserQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Media data clean room could not be retrieved. Please try again by refreshing the page.`
          )
        );
      },
      variables: { id },
    });
    const { publishedMediaDataRoom } = audienceInsightsAdvertiserData || {};
    const {
      driverAttestationHash,
      advertiserDatasetHash,
      publisherDatasetHash,
    } = publishedMediaDataRoom || {};
    const {
      data: audienceInsightsAdvertiserStatisticsData,
      loading: audienceInsightsAdvertiserStatisticsLoading,
    } = useAudienceInsightsAdvertiserStatisticsQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Advertiser audience insights could not be retrieved.`
          )
        );
      },
      // TODO: Remove dataset hashes from `skip` and handle error instead
      skip:
        !id ||
        !driverAttestationHash ||
        !advertiserDatasetHash ||
        !publisherDatasetHash,
      variables: {
        input: {
          dataRoomId: id,
          driverAttestationHash,
        },
      },
    });
    const { mediaCalculateStatistics } =
      audienceInsightsAdvertiserStatisticsData || {};
    const { advertiserNumberOfAudiences } = mediaCalculateStatistics || {};
    const [
      isMediaDataRoomUploadDataDialogOpen,
      {
        setTrue: openMediaDataRoomUploadDataDialog,
        setFalse: closeMediaDataRoomUploadDataDialog,
      },
    ] = useBoolean(false);
    // TODO: Export `formatter` from /utils for consistency?
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    const [
      mediaUnpublishAdvertiserDatasetMutation,
      { loading: mediaUnpublishAdvertiserDatasetLoading },
    ] = useMediaUnpublishAdvertiserDatasetMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Dataset could not be deprovisioned from the data clean room."
          )
        );
      },
    });
    const mediaUnpublishAdvertiserDataset = useCallback(() => {
      mediaUnpublishAdvertiserDatasetMutation({
        variables: {
          dataRoomId: id,
          driverAttestationHash,
        },
      });
    }, [driverAttestationHash, id, mediaUnpublishAdvertiserDatasetMutation]);
    return (
      <Box mb={2}>
        <Typography variant="subtitle1">Advertiser</Typography>
        <Grid container={true} spacing={2}>
          <Grid alignItems="center" container={true} item={true} xs={5}>
            <MediaDataRoomParticipantAvatar
              avatarText={advertiserOrganization?.name}
              logoBase64={advertiserOrganization?.logo}
            />
          </Grid>
          <Grid flexDirection="column" item={true} xs={7}>
            {audienceInsightsAdvertiserLoading ? (
              <Skeleton variant="rectangular">
                <Typography color="inherit" />
              </Skeleton>
            ) : (
              <Typography>{advertiserOrganization?.name}</Typography>
            )}
            <Typography color="inherit" mb="6px" mt="2px" variant="subtitle2">
              {audienceInsightsAdvertiserLoading ||
              audienceInsightsAdvertiserStatisticsLoading ||
              audienceInsightsAdvertiserData === undefined ? (
                <Skeleton variant="rectangular" />
              ) : advertiserNumberOfAudiences ? (
                `${formatter.format(advertiserNumberOfAudiences)} audience${
                  advertiserNumberOfAudiences > 1 ? "s" : ""
                }`
              ) : advertiserDatasetHash ? (
                "Customer list provisioned"
              ) : !isStopped && !isDeactivated ? (
                "Waiting for customer list"
              ) : null}
            </Typography>
            {isAdvertiser ? (
              audienceInsightsAdvertiserCalled &&
              (audienceInsightsAdvertiserLoading ||
                audienceInsightsAdvertiserData === undefined) ? (
                <Skeleton height={32} variant="rectangular" width={122} />
              ) : advertiserDatasetHash ? (
                <LoadingButton
                  color="error"
                  disabled={mediaUnpublishAdvertiserDatasetLoading}
                  loading={mediaUnpublishAdvertiserDatasetLoading}
                  onClick={mediaUnpublishAdvertiserDataset}
                  startIcon={
                    <FontAwesomeIcon
                      fixedWidth={true}
                      icon={faLinkSlashRegular}
                    />
                  }
                >
                  Deprovision data
                </LoadingButton>
              ) : isStopped || isDeactivated ? null : (
                <Button
                  color="primary"
                  onClick={openMediaDataRoomUploadDataDialog}
                  startIcon={
                    <FontAwesomeIcon fixedWidth={true} icon={faUploadRegular} />
                  }
                  variant="contained"
                >
                  Import data
                </Button>
              )
            ) : null}
          </Grid>
        </Grid>
        <MediaDataRoomUploadDataDialog
          id={id}
          onCancel={closeMediaDataRoomUploadDataDialog}
          open={isMediaDataRoomUploadDataDialogOpen}
          role={MediaDataRoomUserRole.Advertiser}
        />
      </Box>
    );
  });

AudienceInsightsAdvertiser.displayName = "AudienceInsightsAdvertiser";

export default AudienceInsightsAdvertiser;
