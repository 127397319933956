import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DataLabsWrapper } from "features/dataLabs";
import { DataLabConnectionTable } from "features/mediaDataRoomV2";

interface DataLabConnectDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  retrieveDatasets: () => Promise<void>;
}

const DataLabConnectDialog: React.FC<DataLabConnectDialogProps> = ({
  dataRoomId,
  open,
  onCancel,
  retrieveDatasets,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth={"xl"} open={open} scroll="paper">
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Provision from existing Data Lab</span>
        <IconButton color="inherit" onClick={onCancel}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DataLabsWrapper>
          <DataLabConnectionTable
            dataRoomId={dataRoomId}
            onCancel={onCancel}
            retrieveDatasets={retrieveDatasets}
          />
        </DataLabsWrapper>
      </DialogContent>
    </Dialog>
  );
};

DataLabConnectDialog.displayName = "DataLabConnectDialog";

export default DataLabConnectDialog;
