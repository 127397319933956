import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { useDeleteUserMutation } from "hooks/__generated-new";

interface UserDeleteDialogProps {
  open: boolean;
  userId: string;
  onCancel: () => void;
  closeUserDetailsDialog: () => void;
}

const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({
  open,
  userId,
  onCancel,
  closeUserDetailsDialog,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const [deleteUser, { loading: isDeleteUserLoading }] = useDeleteUserMutation({
    onCompleted: () => {
      enqueueSnackbar(`User has been successfully deleted.`);
      onCancel();
      closeUserDetailsDialog();
    },
    onError: (error) => {
      onCancel();
      enqueueSnackbar(`User could not be deleted.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
    update: (cache) => {
      cache.evict({
        id: cache.identify({
          __typename: "User",
          id: userId,
        }),
      });
      cache.gc();
    },
    variables: {
      userId,
    },
  });
  return (
    <Dialog
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={!isDeleteUserLoading ? onCancel : undefined}
      open={open}
      role="delete-dialog"
    >
      <DialogTitle>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangleRegular}
            size="4x"
          />
        </Box>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="h6">
          <strong>Are you sure you want to delete this user?</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" sx={{ textWrap: "balance" }} variant="body2">
          This action will remove this user and all data uploaded by this user.
          Also, this action will deactivate all data clean rooms created by this
          user, rendering them unusable for all participants therein.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={isDeleteUserLoading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Box sx={{ color: "common.white" }}>
          <LoadingButton
            color="inherit"
            loading={isDeleteUserLoading}
            loadingPosition="start"
            onClick={() => deleteUser()}
            startIcon={
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faExclamationTriangleRegular}
              />
            }
            sx={{ color: "error.main" }}
            variant="contained"
          >
            Delete user
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
UserDeleteDialog.displayName = "UserDeleteDialog";

export default memo(UserDeleteDialog);
