import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftSyntheticNodeDependencyQuery,
  useDraftSyntheticNodeMaskingQuery,
  usePublishedSyntheticNodeDependencyQuery,
  usePublishedSyntheticNodeMaskingQuery,
} from "hooks/__generated-new";
import {
  type DraftMatchNode,
  type DraftSqlNode,
  type DraftSyntheticNode,
  type DraftTableLeafNode,
  type DraftTableLeafNodeColumn,
  type PublishedMatchNode,
  type PublishedSqlNode,
  type PublishedSyntheticNode,
  type PublishedTableLeafNode,
  type PublishedTableLeafNodeColumn,
  type SyntheticNodeColumn,
} from "types/__generated-new";

const useSyntheticNodeMasking = (
  computeNodeId: string,
  dependencyId?: string
): {
  node: PublishedSyntheticNode | DraftSyntheticNode | undefined;
  maskedColumns: SyntheticNodeColumn[] | undefined;
  originalColumns:
    | (PublishedTableLeafNodeColumn | DraftTableLeafNodeColumn)[]
    | undefined;
  isTabularDependency: boolean;
  shouldUseDraft: boolean;
  dependency:
    | PublishedSqlNode
    | DraftSqlNode
    | PublishedTableLeafNode
    | DraftTableLeafNode
    | PublishedMatchNode
    | DraftMatchNode
    | undefined;
  dependencyUpdatedAt?: string;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  const shouldUseDraftDependency = !isPublished;
  // draft & development
  const { data: draftData } = useDraftSyntheticNodeMaskingQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation mask settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  const { data: draftDependencyData } = useDraftSyntheticNodeDependencyQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation data source settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraftDependency || !dependencyId,
    variables: { id: dependencyId! },
  });
  // published
  const { data: publishedData } = usePublishedSyntheticNodeMaskingQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation mask settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  const { data: publishedDependencyData } =
    usePublishedSyntheticNodeDependencyQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation data source settings could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraftDependency || !dependencyId,
      variables: {
        commitId: undefined,
        computeNodeId: dependencyId!,
        dcrHash,
        driverAttestationHash,
      },
    });
  const publishedNode = publishedData?.publishedNode as
    | PublishedSyntheticNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftSyntheticNode | undefined;
  const dependency = shouldUseDraftDependency
    ? (draftDependencyData?.draftNode as
        | DraftSqlNode
        | DraftMatchNode
        | DraftTableLeafNode
        | undefined)
    : (publishedDependencyData?.publishedNode as
        | PublishedSqlNode
        | PublishedMatchNode
        | PublishedTableLeafNode
        | undefined);
  const isTabularDependency =
    dependency?.__typename === "PublishedTableLeafNode" ||
    dependency?.__typename === "DraftTableLeafNode";
  const originalColumns = isTabularDependency
    ? shouldUseDraftDependency
      ? [
          ...((dependency as DraftTableLeafNode | undefined)?.columns?.nodes ||
            []),
        ].sort(
          (a, b) =>
            (
              (dependency as DraftTableLeafNode | undefined)?.columnsOrder || []
            ).indexOf(a.id) -
            (
              (dependency as DraftTableLeafNode | undefined)?.columnsOrder || []
            ).indexOf(b.id)
        )
      : (dependency as PublishedTableLeafNode | undefined)?.columns
    : undefined;
  return {
    dependency,
    dependencyUpdatedAt: draftNode?.dependencyUpdatedAt,
    isTabularDependency,
    maskedColumns: shouldUseDraft
      ? draftNode?.columns?.nodes
      : publishedNode?.columns,
    node: shouldUseDraft ? draftNode : publishedNode,
    originalColumns,
    shouldUseDraft,
  };
};

export default useSyntheticNodeMasking;
