import {
  useDatasetExportQuery,
  useDatasetImportQuery,
} from "hooks/__generated-new";

type ConnectionTooltipData = {
  bucket: string | undefined;
  objectKey: string | undefined;
  region: string | undefined;
};

const useS3ConnectionConfigurationData = (
  connectionId: string,
  typename: string
): ConnectionTooltipData => {
  const {
    data: { datasetImport: { config: datasetImportConfig = {} } = {} } = {},
  } = useDatasetImportQuery({
    skip: !connectionId || typename !== "DatasetImport",
    variables: { id: connectionId },
  });

  const {
    data: { datasetExport: { config: datasetExportConfig = {} } = {} } = {},
  } = useDatasetExportQuery({
    skip: !connectionId || typename !== "DatasetExport",
    variables: { id: connectionId },
  });

  return {
    bucket: datasetImportConfig?.bucket || datasetExportConfig?.bucket,
    objectKey: datasetImportConfig?.objectKey || datasetExportConfig?.key,
    region: datasetImportConfig?.region || datasetExportConfig?.region,
  };
};

export default useS3ConnectionConfigurationData;
