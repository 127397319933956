import { WorkerTypeShortName } from "types/__generated-new";

export const WORKER_TYPE_COLOR: Record<WorkerTypeShortName, string> = {
  [WorkerTypeShortName.Post]: "#D7D7D7",
  [WorkerTypeShortName.Preview]: "#A6F8CC",
  [WorkerTypeShortName.S3]: "#D7D7D7",
  [WorkerTypeShortName.Python]: "#F8E1A6",
  [WorkerTypeShortName.Match]: "#F6D0B1",
  [WorkerTypeShortName.R]: "#FCD4CB",
  [WorkerTypeShortName.Sql]: "#C1E7F7",
  [WorkerTypeShortName.Sqlite]: "#C1E7F7",
  [WorkerTypeShortName.Synthetic]: "#BEE6B9",
};
