import { type Reference } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { memo, useCallback } from "react";
import { DataRoomPermissionsSelect } from "components";
import { useDataRoom, usePermissionsVars } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import {
  useDraftParticipantCreateAnalystPermissionMutation,
  useDraftParticipantDeleteAnalystPermissionMutation,
} from "hooks/__generated-new";
import { ComputeNodeTypeNames } from "models";
import { DraftComputationNodeNameFragment } from "types/__generated-new";

interface ComputeNodesPermissionsSelectProps {
  participant?: {
    id: string;
    permissions: [
      { id: string; name: string; __typename: ComputeNodeTypeNames },
    ];
  };
}

const ComputeNodesPermissionsSelect: React.FC<ComputeNodesPermissionsSelectProps> =
  memo(({ participant }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { isPublished } = useDataRoom();
    const { canEditPermissions } = usePermissionsVars();
    const { nodes, loading } = useNodes();
    const computeNodes = nodes.filter(({ __typename }) =>
      (Object.values(ComputeNodeTypeNames) as string[]).includes(__typename!)
    );
    const permissions =
      participant?.permissions
        ?.filter((node) => Object.keys(node).length)
        ?.filter(({ __typename }) =>
          (Object.values(ComputeNodeTypeNames) as string[]).includes(
            typeof __typename === "string"
              ? __typename
              : (__typename as { __typename: ComputeNodeTypeNames }).__typename
          )
        )
        ?.map(({ id }) => id) || [];
    const computeNodeOptions = computeNodes.map(({ __typename, id, name }) => ({
      __typename,
      title: name,
      value: id,
    }));
    const [draftParticipantCreateAnalystPermissionMutation] =
      useDraftParticipantCreateAnalystPermissionMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Analyst permission could not be added to the participant."
            )
          );
        },
      });
    const [draftParticipantDeleteAnalystPermissionMutation] =
      useDraftParticipantDeleteAnalystPermissionMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Analyst permission could not be removed from the participant."
            )
          );
        },
      });
    const handleAddDataNodePermission = useCallback(
      (computeNodeId: string) => {
        draftParticipantCreateAnalystPermissionMutation({
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                permissions: (existing = {}) => {
                  const permissionRef = cache.writeFragment({
                    data: data?.draftParticipant?.createAnalystPermission?.node,
                    fragment: DraftComputationNodeNameFragment,
                  });
                  return {
                    ...existing,
                    nodes: [permissionRef, ...(existing?.nodes || [])],
                  };
                },
              },
              id: cache.identify({
                __typename: "DraftParticipant",
                id: participant?.id,
              }),
            });
          },
          variables: {
            draftNodeId: computeNodeId,
            draftParticipantId: participant?.id!,
          },
        });
      },
      [draftParticipantCreateAnalystPermissionMutation, participant?.id]
    );
    const handleRemoveComputeNodePermission = useCallback(
      (computeNodeId: string) => {
        draftParticipantDeleteAnalystPermissionMutation({
          update: (cache, data) => {
            cache.modify({
              fields: {
                permissions: (existing = {}, { readField }) => {
                  const incoming = {
                    ...existing,
                    nodes:
                      existing?.nodes?.filter(
                        ({ node: nodesDataRoomRef }: { node: Reference }) =>
                          readField("id", nodesDataRoomRef) !== computeNodeId
                      ) || [],
                  };
                  return incoming;
                },
              },
              id: cache.identify({
                __typename: "DraftParticipant",
                id: participant?.id!,
              }),
            });
          },
          variables: {
            computeNodeId,
            participantId: participant?.id!,
          },
        });
      },
      [participant?.id, draftParticipantDeleteAnalystPermissionMutation]
    );
    if (loading) {
      return (
        <Box alignItems="center" display="flex" justifyContent="flex-start">
          <CircularProgress color="inherit" size="1.5rem" thickness={1} />
        </Box>
      );
    }
    return (
      <DataRoomPermissionsSelect
        dataKey="computation"
        disableSelectAll={isPublished}
        disabled={!canEditPermissions || !computeNodeOptions.length}
        onAddPermission={handleAddDataNodePermission}
        onRemovePermission={handleRemoveComputeNodePermission}
        options={computeNodeOptions}
        permissions={permissions}
      />
    );
  });

ComputeNodesPermissionsSelect.displayName = "ComputeNodesPermissionsSelect";

export default ComputeNodesPermissionsSelect;
