import {
  useDatasetExportQuery,
  useDatasetExportResultQuery,
} from "hooks/__generated-new";
import {
  DataImportExportStatus,
  type DatasetExportResult,
} from "types/__generated-new";

type DatasetExportResultHookReturn = {
  status: DataImportExportStatus | undefined;
  result: DatasetExportResult | undefined | null;
};

// Hook that encapsulates both useDatasetExportQuery's status for rendering status icons on the last step
// and useDatasetExportResultQuery from enclave to render error text
const useDatasetExportResult = (
  datasetExportId: string
): DatasetExportResultHookReturn => {
  // Returns DataImportExportStatus
  const { data: datasetExportData } = useDatasetExportQuery({
    skip: !datasetExportId,
    variables: { id: datasetExportId as string },
  });
  const {
    datasetExport: {
      status: datasetExportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetExportData || {};

  // Returns result state from Enclave
  const { data: datasetExportResult } = useDatasetExportResultQuery({
    variables: { id: datasetExportId },
  });

  const { datasetExport: { result: exportResult = {} } = {} } =
    datasetExportResult || {};

  return {
    result: exportResult,
    status: datasetExportStatus,
  };
};

export default useDatasetExportResult;
