import { faShield as faShieldSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, styled, Tooltip, useTheme } from "@mui/material";
import { memo } from "react";
import { WORKER_TYPE_COLOR } from "models";
import {
  type DraftSqlNode,
  type PublishedSqlNode,
  WorkerTypeShortName,
} from "types/__generated-new";
import useComputeNodeType, { WORKER_TYPE_LABEL } from "./useComputeNodeType";

interface ComputeNodeTypeProps {
  computeNodeId: string;
  className?: string;
}

const ComputeNodeType: React.FC<ComputeNodeTypeProps> = memo(
  ({ computeNodeId, className }) => {
    const { palette } = useTheme();
    const node = useComputeNodeType(computeNodeId);
    const computationType = node?.computationType!;
    return (
      <div className={className}>
        <Box style={{ margin: "0 8px", paddingTop: "1px" }}>
          <Chip
            label={WORKER_TYPE_LABEL[computationType].toUpperCase()}
            size="small"
            sx={{
              backgroundColor:
                WORKER_TYPE_COLOR[
                  computationType as keyof typeof WORKER_TYPE_COLOR
                ],
              borderRadius: "4px",
              height: "22px",
              marginTop: "-2px",
            }}
          />
        </Box>
        {computationType === WorkerTypeShortName.Sql &&
        !!(node as PublishedSqlNode | DraftSqlNode).privacyFilter
          ?.minimumRows ? (
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            placement="top"
            title={"This computation has a privacy filter enabled"}
          >
            <FontAwesomeIcon
              color={palette?.grey[600]}
              icon={faShieldSolid}
              width=".85rem"
            />
          </Tooltip>
        ) : null}
      </div>
    );
  }
);

ComputeNodeType.displayName = "ComputeNodeType";

const StyledComputeNodeType = styled(ComputeNodeType)({
  alignItems: "center",
  display: "flex",
});

export default StyledComputeNodeType;
