import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { memo, type MouseEventHandler } from "react";

type CustomAdminDialogProps = {
  title: React.ReactNode;
  disabled?: boolean;
  confirmButtonText?: string;
  withConfirmButton?: boolean;
  withCancelButton?: boolean;
  onConfirm?: () => void;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
};

type AdminDialogProps = CustomAdminDialogProps &
  Omit<DialogProps, "onClose" | "title">;

const AdminDialog: React.FC<AdminDialogProps> = ({
  open,
  title,
  loading = false,
  onClose,
  children,
  onConfirm,
  disabled = false,
  confirmButtonText = "Create",
  withConfirmButton = true,
  withCancelButton = true,
  maxWidth = "xs",
  ...restDialogProps
}) => (
  <Dialog
    fullWidth={true}
    maxWidth={maxWidth}
    onClose={onClose}
    open={open}
    {...restDialogProps}
  >
    <DialogTitle
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{title}</span>
      <IconButton color="inherit" onClick={onClose}>
        <FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />
      </IconButton>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {withCancelButton && (
        <Button color="inherit" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
      )}
      {withConfirmButton && (
        <Button
          color="inherit"
          disabled={disabled || loading}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
AdminDialog.displayName = "AdminDialog";

export default memo(AdminDialog);
