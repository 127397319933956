import {
  useDatasetImportQuery,
  useDatasetImportResultQuery,
} from "hooks/__generated-new";
import {
  DataImportExportStatus,
  type DatasetImportResult,
} from "types/__generated-new";

type DatasetImportResultHookReturn = {
  status: DataImportExportStatus | undefined;
  result: DatasetImportResult | undefined | null;
};

// Hook that encapsulates both useDatasetImportQuery's status for rendering status icons on the last step
// and useDatasetImportResultQuery from enclave to render error text
const useDatasetImportResult = (
  datasetImportId: string
): DatasetImportResultHookReturn => {
  // Returns DataImportExportStatus
  const { data: datasetImportData } = useDatasetImportQuery({
    skip: !datasetImportId,
    variables: { id: datasetImportId as string },
  });
  const {
    datasetImport: {
      status: datasetImportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetImportData || {};

  // Returns result state from Enclave
  const { data: datasetImportResult } = useDatasetImportResultQuery({
    variables: { id: datasetImportId },
  });

  const { datasetImport: { result: importResult } = {} } =
    datasetImportResult || {};

  return {
    result: importResult,
    status: datasetImportStatus,
  };
};

export default useDatasetImportResult;
