import { useAuth0 } from "@auth0/auth0-react";
import { z } from "zod";
import { useDataRoomSnackbar } from "hooks";
import {
  ActivationType,
  type MediaPublishDraftDataRoomInput,
} from "types/__generated-new";
import { type PublishLookalikeMediaDataRoomInput } from "./usePublishLookalikeMediaDataRoom";

// TODO: Extend schema
const configurationSchema = z.union([
  z.object({
    activationDownloadByAdvertiser: z.boolean(),
    activationDownloadByPublisher: z.boolean(),
    activationType: z.literal(null),
  }),
  z.object({
    activationDownloadByAdvertiser: z.boolean(),
    activationDownloadByPublisher: z.boolean(),
    activationType: z.literal(ActivationType.Direct),
  }),
  z
    .object({
      activationDownloadByAdvertiser: z.boolean(),
      activationDownloadByPublisher: z.boolean(),
      activationType: z.literal(ActivationType.Consentless),
    })
    .refine((data) => {
      return (
        data.activationDownloadByAdvertiser ||
        data.activationDownloadByPublisher
      );
    }, "At least one of the download permissions must be selected."),
]);

// TODO: Add `.lt()` for column indexes
const prepareDataSchema = z.object({
  activationType: z
    .enum([ActivationType.Direct, ActivationType.Consentless])
    .nullable(),
});

const useValidateMediaDataRoom = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};

  const validateMediaDataRoom = ({
    activationDownloadByAdvertiser,
    activationDownloadByPublisher,
    activationType,
    advertiserUserEmails,
    agencyUserEmails,
    mainPublisherUserEmail,
    mainAdvertiserUserEmail,
    name,
    observerUserEmails,
    publisherUserEmails,
  }: MediaPublishDraftDataRoomInput) => {
    const {
      success: configurationSchemaSuccess,
      error: configurationSchemaError = {},
    } = configurationSchema.safeParse({
      activationDownloadByAdvertiser,
      activationDownloadByPublisher,
      activationType,
    });
    const {
      success: prepareDataSchemaSuccess,
      error: prepareDataSchemaError = {},
    } = prepareDataSchema.safeParse({
      activationType,
    });

    const allParticipants = [
      ...publisherUserEmails,
      ...advertiserUserEmails,
      ...observerUserEmails,
      ...agencyUserEmails,
    ];

    if (!name) {
      enqueueSnackbar("Please fill in the data clean room's name.", {
        variant: "error",
      });
      return false;
    }
    if (!configurationSchemaSuccess) {
      (configurationSchemaError?.issues || []).map(
        ({ message: errorMessage = "" }) => {
          return enqueueSnackbar(errorMessage, { variant: "error" });
        }
      );
      return false;
    }
    if (!prepareDataSchemaSuccess) {
      (prepareDataSchemaError?.issues || []).map(
        ({ message: errorMessage = "" }) => {
          return enqueueSnackbar(errorMessage, { variant: "error" });
        }
      );
      return false;
    }
    if (!publisherUserEmails.length) {
      enqueueSnackbar("At least one Publisher participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainPublisherUserEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Publisher organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!advertiserUserEmails.length) {
      enqueueSnackbar("At least one Advertiser participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainAdvertiserUserEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Advertiser organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!allParticipants.includes(currentUserEmail)) {
      enqueueSnackbar(
        "Your user must also be a participant of this data clean room.",
        { variant: "error" }
      );
      return false;
    }

    return true;
  };

  const validateLookalikeMediaDataRoom = ({
    advertiserEmails,
    agencyEmails,
    mainPublisherEmail,
    mainAdvertiserEmail,
    name,
    observerEmails,
    publisherEmails,
    matchingIdFormat,
  }: PublishLookalikeMediaDataRoomInput) => {
    const allParticipants = [
      ...publisherEmails,
      ...advertiserEmails,
      ...observerEmails,
      ...agencyEmails,
    ];

    if (!matchingIdFormat) {
      enqueueSnackbar("Please select the matching ID.", {
        variant: "error",
      });
      return false;
    }
    if (!name) {
      enqueueSnackbar("Please fill in the data clean room's name.", {
        variant: "error",
      });
      return false;
    }
    if (!publisherEmails.length) {
      enqueueSnackbar("At least one Publisher participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainPublisherEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Publisher organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!advertiserEmails.length) {
      enqueueSnackbar("At least one Advertiser participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainAdvertiserEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Advertiser organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!allParticipants.includes(currentUserEmail)) {
      enqueueSnackbar(
        "Your user must also be a participant of this data clean room.",
        { variant: "error" }
      );
      return false;
    }

    return true;
  };

  return {
    validateLookalikeMediaDataRoom,
    validateMediaDataRoom,
  };
};

export default useValidateMediaDataRoom;
