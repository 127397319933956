import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "features/datasets/components/ImportExternalData";
import {
  S3BucketFormConfigurationFields,
  S3BucketFormCredentials,
} from "./components";

type S3BucketFormProps = (
  | ImportExternalDataFormProps
  | ExportDatasetFormProps
) & {
  type: ExternalConnectionType;
};

const s3BucketFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    filePath: yup.string().trim().required("File path is required"),
    region: yup.string().trim().required("Region is required"),
    url: yup.string().trim().required("S3 Bucket URL is required"),
  }),
  credentials: yup.object({
    accessKey: yup.string().trim().required("Access Key is required"),
    secretKey: yup.string().trim().required("Secret Key is required"),
  }),
  datasetName: yup.string(),
});

// TODO: Consider creating separate forms for import and export
const S3BucketForm: React.FC<S3BucketFormProps> = ({
  submitFormHandler,
  cancelFormHandler,
  submitButtonText = "Import",
  defaultValues,
  type = ExternalConnectionType.IMPORT,
}) => {
  const s3Form = useForm({
    defaultValues: {
      configuration: {
        filePath:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName,
        region: "",
        url: "",
      },
      credentials: {
        accessKey: "",
        secretKey: "",
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(s3BucketFormValidationSchema),
  });
  const { control, handleSubmit, reset } = s3Form;

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: any = {}) => {
      const {
        configuration: { url = "", region = "", filePath = "" } = {},
        credentials: { accessKey = "", secretKey = "" } = {},
        datasetName,
      } = formValues;

      const config = {
        bucket: url.trim(),
        objectKey: filePath.trim(),
        region: region.trim(),
      };

      const s3BucketConfigVariables =
        type === ExternalConnectionType.IMPORT
          ? { sourceConfig: config }
          : { targetConfig: config };

      submitFormHandler({
        input: {
          ...(type === ExternalConnectionType.IMPORT
            ? { datasetName: datasetName?.trim() || filePath?.trim() }
            : {}),
          s3: {
            credentials: {
              accessKey: accessKey.trim(),
              secretKey: secretKey.trim(),
            },
            ...s3BucketConfigVariables,
          },
        },
      });
      reset();
    },
    [reset, submitFormHandler, type]
  );

  return (
    <Box>
      <FormProvider {...s3Form}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <S3BucketFormConfigurationFields />
          <Controller
            control={control}
            name="configuration.filePath"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.filePath;
              return (
                <TextField
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": { padding: "7px 0" },
                      "&:before": { borderBottomStyle: "solid" },
                    },
                  }}
                  error={!isEmpty(fieldError)}
                  fullWidth={true}
                  helperText={fieldError?.message}
                  label="Object name (Key)"
                  placeholder="Example: my-file.csv"
                  size="small"
                  sx={{ mb: 1 }}
                  variant="standard"
                  {...field}
                />
              );
            }}
          />
          {type === ExternalConnectionType.IMPORT && (
            <Controller
              control={control}
              name="datasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasetName;
                return (
                  <TextField
                    InputProps={{
                      sx: {
                        "& .MuiInput-input": { padding: "7px 0" },
                        "&:before": { borderBottomStyle: "solid" },
                      },
                    }}
                    error={!isEmpty(fieldError)}
                    fullWidth={true}
                    helperText={fieldError?.message}
                    label="Stored dataset name (optional)"
                    placeholder="Example: DQ_dataset_name"
                    size="small"
                    sx={{ mb: 1 }}
                    variant="standard"
                    {...field}
                  />
                );
              }}
            />
          )}
        </Box>
        <Box mb={2} mt={2}>
          <S3BucketFormCredentials />
        </Box>
      </FormProvider>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button color="inherit" onClick={handlePreviousStepClick}>
          Back
        </Button>
        <Button color="inherit" onClick={handleSubmit(handleFormSubmit)}>
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

S3BucketForm.displayName = "S3BucketForm";

export default memo(S3BucketForm);
