import { testIds } from "@decentriq/utils";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useBoolean } from "ahooks";
import { memo, useCallback, useState } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { ConfigurationDeleteDialog } from "features/mediaPortalShared";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";

interface DeleteUsageConfigurationButtonProps {
  id: string;
  name: string;
}

const DeleteUsageConfigurationButton: React.FC<
  DeleteUsageConfigurationButtonProps
> = ({ id, name }) => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  const [deleting, setDeleting] = useState(false);
  const { deleteUsageConfiguration } = useDataPartnerPortal();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DATA_PARTNER_PORTAL,
  });
  const handleDelete = useCallback(async () => {
    try {
      setDeleting(true);
      await deleteUsageConfiguration(id!);
    } catch (error) {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Cannot delete Data usage configuration"
        )
      );
    } finally {
      setDeleting(false);
    }
  }, [deleteUsageConfiguration, enqueueSnackbar, setDeleting, id]);
  return (
    <>
      <Button
        color="inherit"
        data-testid={
          testIds.dataPartnerPortal.usageConfigurations.recordDeleteButton
        }
        onClick={openDialog}
        startIcon={<FontAwesomeIcon fixedWidth={true} icon={faXmarkRegular} />}
        sx={{ marginRight: 2 }}
        variant="text"
      >
        Delete
      </Button>
      <ConfigurationDeleteDialog
        confirmationTestId={
          testIds.dataPartnerPortal.usageConfigurations
            .deleteDialogConfirmationButton
        }
        description="You can't restore the information, all data will be lost."
        loading={deleting}
        onCancel={closeDialog}
        onConfirm={handleDelete}
        open={isDialogOpen}
        title={`Are you sure you want to delete ${name}?`}
      />
    </>
  );
};

DeleteUsageConfigurationButton.displayName = "DeleteUsageConfigurationButton";

export default memo(DeleteUsageConfigurationButton);
