import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftPostComputeNodeQuery,
  usePublishedPostComputeNodeQuery,
} from "hooks/__generated-new";
import { type PublishedNode } from "types";
import {
  type DraftNode,
  type DraftNodeConnection,
  type DraftPostNode,
  type PublishedPostNode,
} from "types/__generated-new";

const usePostComputeNode = (
  computeNodeId: string
): {
  node: PublishedPostNode | DraftPostNode | undefined;
  dependency: PublishedNode | DraftNode | undefined;
  isLoading: boolean;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData, loading: draftLoading } =
    useDraftPostComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { computeNodeId },
    });
  // published
  const { data: publishedData, loading: publishedLoading } =
    usePublishedPostComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  const publishedNode = publishedData?.publishedNode as
    | PublishedPostNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftPostNode | undefined;
  return {
    dependency: shouldUseDraft
      ? (draftNode?.dependency as DraftNodeConnection)?.draftNode ||
        draftNode?.dependency
      : publishedNode?.dependency,
    isLoading: publishedLoading || draftLoading,
    node: shouldUseDraft ? draftNode : publishedNode,
  };
};

export default usePostComputeNode;
