import { faPersonDigging } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, type Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useUnderMaintenance = makeStyles()((theme: Theme) => ({
  containerCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  contentText: {
    display: "block",
    marginBlockEnd: "0.7em",
    marginBlockStart: "0.3em",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
    maxWidth: "700px",
    textAlign: "center",
  },
  maintenanceIcon: {
    height: "5em",
    marginBottom: "0.5em",
    width: "5em !important",
  },
}));

const UnderMaintenancePage = () => {
  const { classes: underMaintenanceClasses } = useUnderMaintenance();
  return (
    <Container
      className={underMaintenanceClasses.containerCenter}
      maxWidth={false}
    >
      <FontAwesomeIcon
        className={underMaintenanceClasses.maintenanceIcon}
        fixedWidth={true}
        height="5em !important"
        icon={faPersonDigging}
        width={24}
      />
      <Typography variant="h5">
        <strong>We are improving our platform</strong>
      </Typography>
      <Typography className={underMaintenanceClasses.contentText}>
        Apologies for the inconvenience, we are doing some maintenance work and
        should be back soon.
      </Typography>
      <Button
        color="primary"
        href="mailto:support@decentriq.com"
        sx={{ backgroundColor: "#289090", color: "#fff" }}
        variant="contained"
      >
        CONTACT US
      </Button>
    </Container>
  );
};

export default UnderMaintenancePage;
