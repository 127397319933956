import {
  type ApiCoreSessionManager,
  type MediaInsightsComputeLatest,
} from "@decentriq/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { type media_insights_request, type media_insights_response } from "ddc";
import { useParams } from "react-router-dom";
import { useApiCore } from "contexts";
import { usePublishedMediaInsightsDcrQuery as usePublishedMediaInsightsDcrApolloQuery } from "hooks/__generated-new";

interface PublishedMediaInsightsDcr {
  definition: MediaInsightsComputeLatest | null;
  rawMediaInsightsDcr: media_insights_response.MediaInsightsDcr;
  supportedFeatures: string[];
}

type UsePublishedMediaInsightsDcrDefinitionResult =
  UseQueryResult<PublishedMediaInsightsDcr>;

export const fetchPublishedMediaInsightsDcrDefinition =
  (sessionManager: ApiCoreSessionManager) =>
  async ({
    driverAttestationHash,
    dataRoomId,
  }: {
    driverAttestationHash: string;
    dataRoomId: string;
  }): Promise<PublishedMediaInsightsDcr> => {
    const request: media_insights_request.MediaInsightsRequest = {
      retrieveDataRoom: {
        dataRoomIdHex: dataRoomId,
      },
    };
    const session = await sessionManager.get({ driverAttestationHash });
    const response = await session.sendMediaInsightsRequest(request);
    if (!("retrieveDataRoom" in response)) {
      throw new Error("Expected retrieveDataRoom response");
    }
    const mediaDcr = response.retrieveDataRoom.dataRoom;
    const supportedFeatures =
      session.compiler.mediaInsights.getFeatures(mediaDcr);
    const latestLmDcr =
      session.compiler.mediaInsights.convertToLatest(mediaDcr);
    return {
      definition: latestLmDcr.v0.compute?.v3 ?? null,
      rawMediaInsightsDcr: latestLmDcr,
      supportedFeatures,
    };
  };

const usePublishedMediaInsightsDcrDefinition = ({
  queryKeyPrefix,
}: {
  queryKeyPrefix: string[];
}): UsePublishedMediaInsightsDcrDefinitionResult => {
  const { dataRoomId } = useParams();
  const {
    data: {
      publishedMediaInsightsDcr: { driverAttestationHash = null } = {},
    } = {},
  } = usePublishedMediaInsightsDcrApolloQuery({
    variables: { id: dataRoomId! },
  });
  // TODO @matyasfodor - figure out session management. Could be created once and passed down to all hooks
  const { sessionManager } = useApiCore();
  return useQuery({
    enabled: !!driverAttestationHash,
    queryFn: async () => {
      if (!driverAttestationHash) {
        return null;
      }
      return fetchPublishedMediaInsightsDcrDefinition(sessionManager)({
        dataRoomId: dataRoomId!,
        driverAttestationHash,
      });
    },
    queryKey: [
      ...queryKeyPrefix,
      "definition",
      dataRoomId,
      driverAttestationHash,
    ],
  });
};

export default usePublishedMediaInsightsDcrDefinition;
