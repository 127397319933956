import { usePublishedDataRoom } from "contexts";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useStartJobPollingMutation } from "hooks/__generated-new";
import { ComputeJobAutoFetching } from "types/__generated-new";

interface JobPollingHookArgs {
  computeNodeId: string;
  jobId: string;
  enclaveJobId: string;
  autoFetching: ComputeJobAutoFetching;
  dataRoomHash?: string;
  driverAttestationHash: string;
}

const useJobPolling = (args: JobPollingHookArgs) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const publishedDataRoom = usePublishedDataRoom();
  const driverAttestationHash =
    args.driverAttestationHash || publishedDataRoom.driverAttestationHash;
  const dcrHash = args.dataRoomHash || publishedDataRoom.dcrHash;
  const [startJobPolling] = useStartJobPollingMutation({
    onCompleted:
      args.autoFetching === ComputeJobAutoFetching.Validation ||
      args.autoFetching === ComputeJobAutoFetching.AllValidations
        ? () => {
            enqueueSnackbar(`Validation passed successfully.`);
          }
        : undefined,
    onError: (error) => {
      enqueueSnackbar(
        ...mapDataRoomErrorToSnackbar(
          error,
          `The computation could not be run.`
        )
      );
    },
    variables: {
      input: {
        ...args,
        dcrHash,
        driverAttestationHash,
      },
    },
  });
  return {
    startJobPolling,
  };
};

export default useJobPolling;
