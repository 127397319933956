import { useCallback } from "react";
import { useDataRoom } from "contexts";
import useTestDatasets from "features/dataNodes/hooks/useTestDatasets";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useTestDraftComputationMutation } from "hooks/__generated-new";
import { type ComputeNodeTypeNames } from "models";
import { type TestDataset } from "types/__generated-new";
import { useCheckScriptingNodeInputs } from "..";

interface TestDraftComputationHookArgs {
  computationType: ComputeNodeTypeNames | undefined;
  computeNodeId: string;
}

const useTestDraftComputation = ({
  computeNodeId,
  computationType,
}: TestDraftComputationHookArgs) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const checkScriptingNodeInputs = useCheckScriptingNodeInputs(
    computeNodeId,
    computationType
  );
  const { dataRoomId } = useDataRoom();
  const [testDraftComputeRunMutation, { loading }] =
    useTestDraftComputationMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDataRoomErrorToSnackbar(
            error,
            `The computation could not be run.`
          )
        );
      },
    });
  const fetchTestDatasets = useTestDatasets();
  const testDraftComputeNode = useCallback(async () => {
    checkScriptingNodeInputs();
    const testDatasets: TestDataset[] = await fetchTestDatasets();
    return testDraftComputeRunMutation({
      variables: {
        input: {
          computeNodeId,
          id: dataRoomId,
          testDatasets,
        },
      },
    });
  }, [
    testDraftComputeRunMutation,
    fetchTestDatasets,
    computeNodeId,
    dataRoomId,
    checkScriptingNodeInputs,
  ]);
  return {
    loading,
    testDraftComputeNode,
  };
};

export default useTestDraftComputation;
