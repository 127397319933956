import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftSyntheticNodeDataSourceQuery,
  usePublishedSyntheticNodeDataSourceQuery,
  useSyntheticNodeInteractivityPublishedDependenciesQuery,
} from "hooks/__generated-new";
import {
  type DraftNodeConnection,
  type DraftSqlNode,
  type DraftSyntheticNode,
  type DraftTableLeafNode,
  type PublishedNodeConnection,
  type PublishedSqlNode,
  type PublishedSyntheticNode,
  type PublishedTableLeafNode,
} from "types/__generated-new";

const useSyntheticNodeDataSource = (
  computeNodeId: string
): {
  dependency:
    | PublishedSqlNode
    | DraftSqlNode
    | PublishedTableLeafNode
    | DraftTableLeafNode
    | undefined;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished, dataRoomId } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const shouldUseDraft = !isPublished || isInteractivityContext;
  // draft & development
  const { data: draftData } = useDraftSyntheticNodeDataSourceQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation data source settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  const { data: interactivityData } =
    useSyntheticNodeInteractivityPublishedDependenciesQuery({
      skip: !isInteractivityContext,
      variables: {
        id: dataRoomId,
      },
    });
  // published
  const { data: publishedData } = usePublishedSyntheticNodeDataSourceQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation data source settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  return {
    dependency: shouldUseDraft
      ? (draftData?.draftNode as DraftSyntheticNode)?.dependency?.__typename ===
        "DraftNodeConnection"
        ? ((
            (draftData?.draftNode as DraftSyntheticNode)
              ?.dependency as DraftNodeConnection
          )?.draftNode as DraftSqlNode | DraftTableLeafNode | undefined)
        : (interactivityData?.publishedDataRoom?.publishedNodes?.find(
            (n) =>
              n.id ===
              (
                (draftData?.draftNode as DraftSyntheticNode)
                  ?.dependency as PublishedNodeConnection
              )?.computeNodeId
          ) as PublishedSqlNode | PublishedTableLeafNode | undefined)
      : ((publishedData?.publishedNode as PublishedSyntheticNode)
          ?.dependency as
          | PublishedSqlNode
          | PublishedTableLeafNode
          | undefined),
  };
};

export default useSyntheticNodeDataSource;
