import { memo, useCallback } from "react";
import {
  EXPORT_DATASET_STEPS,
  useDatasetExportResult,
  useExportDatasetDialog,
} from "features/datasets/components/ExportDataset";
import {
  EXTERNAL_CONNECTIONS_TYPES,
  ExternalConnectionStatus,
} from "features/datasets/components/ExternalConnections";
import { DataImportExportStatus } from "types/__generated-new";

type ExportDatasetStatusProps = {
  onClose: () => void;
};

const ExportDatasetStatus: React.FC<ExportDatasetStatusProps> = ({
  onClose,
}) => {
  const {
    selectedDataTarget,
    setDatasetExportId,
    datasetExportId,
    setExportStep,
    setIsExportCreated,
    isExportCreated,
    datasetExportError,
  } = useExportDatasetDialog();
  const {
    status: datasetExportStatus = DataImportExportStatus.Pending,
    result: datasetExportResult,
  } = useDatasetExportResult(datasetExportId as string);

  const handlePreviousStepClick = useCallback(() => {
    setDatasetExportId(null);
    setIsExportCreated(false);
    setExportStep(EXPORT_DATASET_STEPS.TARGET_FORM);
  }, [setDatasetExportId, setExportStep, setIsExportCreated]);

  return (
    <ExternalConnectionStatus
      connectionError={datasetExportError}
      isImportExportCreated={isExportCreated}
      onClose={onClose}
      onPreviousStepClick={handlePreviousStepClick}
      result={datasetExportResult}
      selectedConnection={selectedDataTarget}
      status={datasetExportStatus}
      type={EXTERNAL_CONNECTIONS_TYPES.EXPORT}
    />
  );
};

ExportDatasetStatus.displayName = "ExportDatasetStatus";

export default memo(ExportDatasetStatus);
