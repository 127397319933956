import { type ApolloCache } from "@apollo/client";
import { type Session } from "@decentriq/core";
import { PublishedDataRoomPasswordRequirementsFragment } from "types/__generated-new";

const maybeUseDataRoomSecret = async (
  sdkSession: Session,
  apolloCache: ApolloCache<object>,
  id: String
): Promise<void> => {
  const passwordRequirementsData = apolloCache.readFragment({
    fragment: PublishedDataRoomPasswordRequirementsFragment,
    id: apolloCache.identify({ __typename: "PublishedDataRoom", id }),
  });
  const { requirePassword, password } = passwordRequirementsData || {};
  if (requirePassword && !!password) {
    await sdkSession.useDataRoomSecret(password);
  } else {
    await sdkSession.resetDataRoomSecret();
  }
};

export default maybeUseDataRoomSecret;
