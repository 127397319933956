import { type ApolloCache, type FetchResult } from "@apollo/client";
import { useCallback } from "react";
import {
  useCreateDraftDataRoomIsHiddenMutation,
  useDraftDataRoomIsHiddenQuery,
  useUpdateDraftDataRoomIsHiddenMutation,
} from "hooks/__generated-new";
import {
  type CreateDraftDataRoomIsHiddenMutation,
  DataRoomUserSettingsFragment,
  MyDataRoomsDocument,
  type MyDataRoomsQuery,
  type UpdateDraftDataRoomIsHiddenMutation,
} from "types/__generated-new";

interface useDraftDataRoomIsHiddenArgs {
  id: string;
  skip?: boolean;
}

interface useDraftDataRoomIsHiddenResult {
  isHidden: boolean;
  setting: boolean;
  setIsHidden: (
    isArchived: boolean
  ) =>
    | Promise<FetchResult<UpdateDraftDataRoomIsHiddenMutation>>
    | Promise<FetchResult<CreateDraftDataRoomIsHiddenMutation>>;
  loading: boolean;
}

const useDraftDataRoomIsHidden = ({
  id: dataRoomId,
  skip = false,
}: useDraftDataRoomIsHiddenArgs): useDraftDataRoomIsHiddenResult => {
  const { data, loading } = useDraftDataRoomIsHiddenQuery({
    skip,
    variables: { id: dataRoomId },
  });
  const { draftDataRoom } = data || {};
  const { userSettings } = draftDataRoom || {};
  const { id: userSettingsId, isHidden = false } = userSettings || {};
  const updateCache = useCallback(
    (cache: ApolloCache<any>, isHidden: boolean) => {
      if (!isHidden) {
        return;
      }
      cache.updateQuery<MyDataRoomsQuery>(
        { query: MyDataRoomsDocument },
        (data) => {
          return data
            ? {
                ...data,
                dataRooms: {
                  ...data.dataRooms,
                  nodes: data?.dataRooms.nodes.filter(
                    ({ id }) => id !== dataRoomId
                  ),
                },
              }
            : null;
        }
      );
    },
    [dataRoomId]
  );
  const [
    createDraftDataRoomIsHiddenMutation,
    { loading: createDraftDataRoomIsHiddenLoading },
  ] = useCreateDraftDataRoomIsHiddenMutation({
    update: (cache, { data }) => {
      const isHidden =
        data?.draftDataRoom.createUserSettings.isArchived || false;
      cache.modify({
        fields: {
          userSettings: () => {
            const userSettingsRef = cache.writeFragment({
              data: data?.draftDataRoom.createUserSettings,
              fragment: DataRoomUserSettingsFragment,
            });
            return userSettingsRef;
          },
        },
        id: cache.identify({
          __typename: "DraftDataRoom",
          id: dataRoomId,
        }),
      });
      updateCache(cache, isHidden);
    },
  });
  const [
    updateDraftDataRoomIsHiddenMutation,
    { loading: updateDraftDataRoomIsHiddenLoading },
  ] = useUpdateDraftDataRoomIsHiddenMutation({
    update: (cache, { data }) => {
      const isHidden = data?.draftDataRoom.updateUserSettings.isHidden || false;
      cache.modify({
        fields: {
          isArchived: () => isHidden,
        },
        id: cache.identify({
          __typename: "DataRoomUserSettings",
          id: data?.draftDataRoom.updateUserSettings.id,
        }),
      });
      updateCache(cache, isHidden);
    },
  });
  const setIsHidden = (isHidden: boolean) => {
    return userSettingsId
      ? updateDraftDataRoomIsHiddenMutation({
          refetchQueries: isHidden
            ? undefined
            : [{ query: MyDataRoomsDocument }],
          variables: { id: userSettingsId, isHidden },
        })
      : createDraftDataRoomIsHiddenMutation({
          refetchQueries: isHidden
            ? undefined
            : [{ query: MyDataRoomsDocument }],
          variables: { draftDataRoomId: dataRoomId, isHidden },
        });
  };
  const setting = userSettingsId
    ? updateDraftDataRoomIsHiddenLoading
    : createDraftDataRoomIsHiddenLoading;
  return {
    isHidden,
    loading,
    setIsHidden,
    setting: setting,
  };
};

export default useDraftDataRoomIsHidden;
