import { type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { groupBy } from "lodash";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { RawSupportedFeatures } from "features/MediaInsightsDcr/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import usePublishedMediaInsightsDcrDefinition from "../contexts/MediaInsightsDcrContext/hooks/usePublishedMediaInsightsDcrDefinition";
import { type OverlapInsightsCacheKey } from "../models";
import useQueryMediaInsightsComputeJob, {
  type QueryMediaInsightsComputeJobHookResult,
} from "./useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";

const availableAudiencesSchema = z.object({
  available_audiences: z.array(
    z.object({
      activation_type: z.enum(["lookalike", "retarget"]),
      audience_type: z.string(),
      reach: z.number().nullable(),
      size: z.number(),
    })
  ),
});

type AvailableAudiences = z.infer<typeof availableAudiencesSchema>;

interface QueryAvailableAudiencesHookPayload {
  createCacheKeyString: (key: OverlapInsightsCacheKey) => Promise<string>;
  key?: OverlapInsightsCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

export type AvailableLookalikeAudiences = Map<string, Map<number, number>>;

export interface QueryAvailableAudiences {
  availableAudiences: AvailableLookalikeAudiences;
  audienceTypes: string[];
}

export type QueryAvailableAudiencesHookResult =
  QueryMediaInsightsComputeJobHookResult<QueryAvailableAudiences>;

/**
 * useQueryAvailableAudiences - powers the audience generation for lookalike audiences
 * Should only be enabled for the advertiser.
 */
const useQueryAvailableAudiences = ({
  createCacheKeyString,
  key,
  session,
  skip,
}: QueryAvailableAudiencesHookPayload): QueryAvailableAudiencesHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const { data: dataRoomDefinition } = usePublishedMediaInsightsDcrDefinition({
    queryKeyPrefix: ["mi"],
  });
  const enableExtendedLalQualityStats = (
    dataRoomDefinition?.supportedFeatures || []
  ).includes(RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS);

  const jobType = "MEDIA_INSIGHTS_AVAILABLE_AUDIENCES";

  const transform = useCallback(
    async (result: Uint8Array): Promise<QueryAvailableAudiences> => {
      const zip = await loadAsync(result);

      const availableAudiencesFile = zip.file("available_audiences.json");
      if (availableAudiencesFile === null) {
        throw new Error("available_audiences.json not found in zip");
      }

      const availableAudiencesRaw: AvailableAudiences = JSON.parse(
        await availableAudiencesFile.async("string")
      );

      let availableAudiences;

      if (enableExtendedLalQualityStats) {
        availableAudiences = availableAudiencesRaw?.available_audiences;
      } else {
        // In order to keep data from v2 and v3 versions in the same shape,
        // v2 audiences data shape is transformed to v3
        const { lookalike, retarget } = groupBy(
          availableAudiencesRaw.available_audiences,
          "activation_type"
        );
        const transformedLookalike = Object.entries(
          groupBy(lookalike, "audience_type")
        ).map(([audience_type, filtered_audiences]) => ({
          audience_type,
          filtered_audiences,
        }));

        availableAudiences = {
          exclusion_targeting: [],
          lookalike: transformedLookalike,
          retarget,
        };
      }

      const audienceTypes =
        (availableAudiences?.lookalike || []).map(
          ({ audience_type }) => audience_type
        ) || [];

      return {
        audienceTypes,
        availableAudiences,
      };
    },
    [enableExtendedLalQualityStats]
  );

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    jobCacheKey: key ?? undefined,
    jobName: "computeAvailableAudiences",
    jobType,
    queryKeyPrefix: ["mi", jobType],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Available audiences`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useQueryAvailableAudiences;
