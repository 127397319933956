import {
  useDatasetExportQuery,
  useDatasetImportQuery,
} from "hooks/__generated-new";

type ConnectionTooltipData = {
  storageAccount: string;
  blobName: string;
  storageContainer: string;
};

const useAzureConnectionConfigurationData = (
  connectionId: string,
  typename: string
): ConnectionTooltipData => {
  const {
    data: { datasetImport: { config: datasetImportConfig = {} } = {} } = {},
  } = useDatasetImportQuery({
    skip: !connectionId || typename !== "DatasetImport",
    variables: { id: connectionId },
  });

  const {
    data: { datasetExport: { config: datasetExportConfig = {} } = {} } = {},
  } = useDatasetExportQuery({
    skip: !connectionId || typename !== "DatasetExport",
    variables: { id: connectionId },
  });

  return {
    blobName: datasetImportConfig?.blobName || datasetExportConfig?.blobName,
    storageAccount:
      datasetImportConfig?.storageAccount ||
      datasetExportConfig?.storageAccount,
    storageContainer:
      datasetImportConfig?.storageContainer ||
      datasetExportConfig?.storageContainer,
  };
};

export default useAzureConnectionConfigurationData;
