import { type ApiCoreContextInterface } from "contexts";
import {
  type MutationRetrieveComputeJobResultFileAsCsvArgs,
  type RetrieveComputeJobResultFileAsCsvPayload,
} from "types/__generated-new";
import { convertTabularResultToCsvFile } from "utils/apicore";
import { fetchJobResult } from "wrappers/ApolloWrapper/helpers/fetchJobResult";
import { getNodeById, parseErrorMessage } from "../../../helpers";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveComputeJobResultFileAsCsvResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrieveComputeJobResultFileAsCsvArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RetrieveComputeJobResultFileAsCsvPayload> => {
    const { computeNodeId } = args.input;
    try {
      const computeNodeResult = await fetchJobResult(
        args,
        context,
        sessionManager
      );
      const fileName = `${new Date().toLocaleString()}-${
        args.input.resultFileName
      }.csv`;
      const file = await convertTabularResultToCsvFile(
        computeNodeResult,
        fileName
      );
      const fileReference = store.push(file);
      return {
        resultCsv: fileReference,
      };
    } catch (error) {
      const errorMessage = parseErrorMessage(context.cache, error, args);
      const computeNode = getNodeById(context, computeNodeId, args);
      throw new Error(
        `${computeNode ? computeNode?.name + ". " : ""}${errorMessage}`
      );
    }
  };
