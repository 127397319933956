import { InfoTooltip } from "@decentriq/components";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash";
import { memo, useCallback } from "react";
import {
  Controller,
  type FieldError,
  type FieldValues,
  type Merge,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type ActiveMarket } from "../../hooks/useActiveMarkets/useActiveMarkets";

interface DisplayConfigurationActiveMarketsFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  activeMarkets: ActiveMarket[];
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type DisplayConfigurationActiveMarketsFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationActiveMarketsFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationActiveMarketsField = memo(
  <F extends FieldValues>({
    fieldName,
    activeMarkets,
    tooltip,
    testId,
    optionTestId,
  }: DisplayConfigurationActiveMarketsFieldProps<F>) => {
    const { control: formControl, watch } = useFormContext<F>();
    const selectedActiveMarkets = watch(fieldName);
    const handleRenderValue = useCallback(
      (selectedIds: string[]): React.ReactNode => {
        const selected = activeMarkets
          .filter(({ id }) => selectedIds.includes(id))
          .map(({ name }) => name);
        if (selected.length === 0) return "Select market";
        if (selected.length <= 2) return selected.join(", ");
        return (
          <Tooltip placement="top" title={selected.join(", ")}>
            <span>{`${selected.slice(0, 2).join(", ")} + ${selected.length - 2} more`}</span>
          </Tooltip>
        );
      },
      [activeMarkets]
    );
    return (
      <Controller
        control={formControl}
        name={fieldName}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.[fieldName] as
            | Merge<FieldError, (FieldError | undefined)[]>
            | undefined;
          const withError = !isEmpty(fieldError);
          return (
            <FormControl
              error={withError}
              sx={{ marginBottom: 1, width: "100%" }}
            >
              <FormLabel
                component="legend"
                sx={{ fontSize: "12px", paddingLeft: "2px" }}
              >
                Active markets
                {tooltip && <InfoTooltip tooltip={tooltip} />}
              </FormLabel>
              <Select
                MenuProps={{
                  sx: { maxHeight: "370px" },
                }}
                displayEmpty={true}
                error={withError}
                fullWidth={true}
                multiple={true}
                renderValue={handleRenderValue}
                sx={{ background: "transparent" }}
                variant="filled"
                {...field}
                data-testid={testId}
              >
                {activeMarkets.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    sx={({ spacing }) => ({ padding: spacing(1, 2) })}
                    value={id}
                    {...(optionTestId
                      ? { "data-testid": `${optionTestId}${id.toLowerCase()}` }
                      : {})}
                  >
                    <Checkbox
                      checked={selectedActiveMarkets?.includes(id)}
                      sx={{
                        "&.Mui-checked": { color: "#289090" },
                        marginRight: 1,
                        padding: 0,
                      }}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              {withError && (
                <FormHelperText error={withError} sx={{ margin: "4px 4px 0" }}>
                  {fieldError?.message || ""}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
);

DisplayConfigurationActiveMarketsField.displayName =
  "DisplayConfigurationActiveMarketsField";

export default DisplayConfigurationActiveMarketsField as DisplayConfigurationActiveMarketsFieldComponent;
