import {
  type DatasetIngestionDefinition,
  type DatasetStatisticsInterface,
} from "features/datasets/models";

export * from "./statistics";

export function formatSize(bytes: number) {
  const units = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte",
  ];
  const unit = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    style: "unit",
    unit: units[unit],
    unitDisplay: "narrow",
  }).format(bytes / 1024 ** unit);
}

export function formatRows(num: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
  });
  return formatter.format(num);
}

export const datasetDefinitionToDatasetStatistics = ({
  rows: numberOfRows,
  numberOfNotNullableRows,
  fillRatio,
}: DatasetIngestionDefinition): DatasetStatisticsInterface => ({
  fillRatio,
  numberOfNotNullableRows,
  numberOfRows,
});
