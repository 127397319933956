import { DqTable } from "@decentriq/components";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import React, { memo, useMemo } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { replaceDecentriqOrgName } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useDirectAudiencesQuery } from "hooks/__generated-new";
import AdvertiserDirectMediaDataRoomActivationButton from "./AdvertiserDirectMediaDataRoomActivationButton";

interface AdvertiserDirectMediaDataRoomActivationProps {
  id: string;
}

const AdvertiserDirectMediaDataRoomActivation: React.FC<
  AdvertiserDirectMediaDataRoomActivationProps
> = () => {
  const { dcrHash, driverAttestationHash, publisherOrganization } =
    usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { data, loading, error } = useDirectAudiencesQuery({
    fetchPolicy: "cache-and-network",
    onError(error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Failed to get direct audiences"
        )
      );
    },
    variables: {
      id: dcrHash,
      input: {
        dataRoomId: dcrHash,
        driverAttestationHash,
      },
    },
  });
  const audiences = data?.mediaRetrieveDirectAudiences?.nodes || [];
  const publisherDownloadingPermission =
    data?.publishedMediaDataRoom?.activationDownloadByPublisher || false;
  type Audience = Omit<(typeof audiences)[number], "__typename">;
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      {
        Cell: ({ cell }) => (
          <Typography sx={{ my: "auto" }}>{cell.getValue<string>()}</Typography>
        ),
        accessorKey: "name",
        header: "Audience name",
        id: "name",
        minSize: 150,
      },
      {
        Cell: ({ cell }) => (
          <Typography sx={{ my: "auto" }}>{cell.getValue<string>()}</Typography>
        ),
        accessorFn: (row) => {
          if (!row.size) {
            return "Empty audience";
          }
          if (!row.activated) {
            return publisherDownloadingPermission ? "Ready to activate" : "";
          }
          if (row.downloaded) {
            return `Downloaded by ${replaceDecentriqOrgName(
              publisherOrganization?.name,
              "Publisher"
            )} for activation`;
          }
          return `Available for download by ${replaceDecentriqOrgName(
            publisherOrganization?.name,
            "Publisher"
          )}`;
        },
        header: "Status",
        id: "status",
        minSize: 200,
      },
    ],
    [publisherOrganization?.name, publisherDownloadingPermission]
  );
  return (
    <>
      <Typography gutterBottom={true} variant="h6">
        Audiences
      </Typography>
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress color="inherit" size="1.5rem" thickness={1} />
        </Box>
      ) : error ? (
        <Alert severity="error">
          {error.message ||
            "Audiences could not be retrieved. Please try again by refreshing the page."}
        </Alert>
      ) : (
        <DqTable
          columns={columns}
          data={audiences}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              size: 350,
            },
          }}
          enableRowActions={true}
          localization={{
            actions: "",
          }}
          muiTablePaperProps={{ elevation: 0 }}
          renderRowActions={({ row }) => {
            const { activated, name: audienceType, size } = row?.original || {};
            if (!size) {
              return <></>;
            }
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {publisherDownloadingPermission && (
                  <AdvertiserDirectMediaDataRoomActivationButton
                    audienceType={audienceType}
                    isActivated={activated}
                  />
                )}
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

AdvertiserDirectMediaDataRoomActivation.displayName =
  "AdvertiserDirectMediaDataRoomActivation";

export default memo(AdvertiserDirectMediaDataRoomActivation);
