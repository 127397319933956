import { memo } from "react";
import { Chip } from "components";

type AffinityRatioChipProps = {
  value: number;
};

const affinityRatioChipBackgroundMap = new Map<string, string>([
  ["0.5", "#EEF6F6"],
  ["0.6", "#EAF4F4"],
  ["0.7", "#E7F3F2"],
  ["0.8", "#E3F1F0"],
  ["0.9", "#E0F0EF"],
  ["1.0", "#DDEEED"],
  ["1.1", "#D9ECEB"],
  ["1.2", "#D6EBE9"],
  ["1.3", "#D3E9E7"],
  ["1.4", "#CFE7E5"],
  ["1.5", "#CCE6E3"],
  ["1.6", "#C8E4E1"],
  ["1.7", "#C4E2DF"],
  ["1.8", "#C1E1DD"],
  ["1.9", "#BEDFDB"],
  ["2.0", "#BADDD9"],
]);

const AffinityRatioChip: React.FC<AffinityRatioChipProps> = ({ value }) => {
  return value >= 0.5 ? (
    <Chip
      label={`${value.toFixed(1)}x`}
      sx={{
        "& .MuiChip-label": { color: "#289090", padding: "0 8px" },
        // For values exceeding 2, the background should remain the same
        background: affinityRatioChipBackgroundMap.get(
          value > 2 ? "2.0" : value.toFixed(1)
        ),
        fontSize: "14px",
      }}
    />
  ) : (
    <span style={{ marginLeft: "4px" }}>{`${value.toFixed(1)}x`}</span>
  );
};

AffinityRatioChip.displayName = "AffinityRatioChip";

export default memo(AffinityRatioChip);
