import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FilledInput, FormHelperText } from "@mui/material";
import {
  type ChangeEventHandler,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  useAccessTokensNamesQuery,
  useCreateAccessTokenMutation,
} from "hooks/__generated-new";
import { ApiTokenFragment } from "types/__generated-new";

const AccessTokensConstructorForm: React.FC = () => {
  const { data: tokenNamesData } = useAccessTokensNamesQuery({
    fetchPolicy: "cache-only",
  });
  const { tokenNames, userId } = useMemo(
    () => ({
      tokenNames:
        tokenNamesData?.myself?.apiTokens?.nodes?.map(({ name }) => name) || [],
      userId: tokenNamesData?.myself?.id,
    }),
    [tokenNamesData?.myself?.apiTokens?.nodes, tokenNamesData?.myself?.id]
  );
  const [createAccessTokenMutation] = useCreateAccessTokenMutation();
  const handleAddToken = useCallback(
    (tokenName: string) => {
      createAccessTokenMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              apiTokens: (existing = { nodes: [] }) => {
                if (!data?.apiToken?.create) {
                  return existing;
                }
                const accessTokenRef = cache.writeFragment({
                  data: data!.apiToken!.create!,
                  fragment: ApiTokenFragment,
                });
                return {
                  ...existing,
                  nodes: [...existing.nodes, accessTokenRef],
                };
              },
            },
            id: cache.identify({
              __typename: "User",
              id: userId,
            }),
          });
        },
        variables: {
          input: {
            name: tokenName,
          },
        },
      });
    },
    [createAccessTokenMutation, userId]
  );
  const [value, setValue] = useState("");
  const error = useMemo(() => {
    return value.trim().length > 0
      ? tokenNames.includes(value)
        ? "Token name must be unique"
        : undefined
      : undefined;
  }, [tokenNames, value]);
  const onChange = useCallback<
    ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((event) => {
    setValue(event.target.value);
  }, []);
  const onKeyDown = useCallback<
    KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (event) => {
      if (event.key === "Enter" && value.length > 0 && !error) {
        handleAddToken(value);
        setValue("");
      }
    },
    [error, handleAddToken, value]
  );
  return (
    <Box>
      <FilledInput
        autoFocus={true}
        data-testid="new_access_token_btn"
        disableUnderline={true}
        error={Boolean(error)}
        fullWidth={true}
        hiddenLabel={true}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Add new access token"
        startAdornment={
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faPlusRegular}
            style={{ marginRight: 4, opacity: 0.5 }}
          />
        }
        sx={{
          "& .MuiFilledInput-input": {
            height: "1.5rem",
            lineHeight: "1.5rem",
            pb: 0.5,
            pt: 0.5,
          },
          pl: 0.5,
          pr: 0.5,
        }}
        value={value}
      />
      {error ? <FormHelperText error={true}>{error}</FormHelperText> : null}
    </Box>
  );
};

AccessTokensConstructorForm.displayName = "AccessTokensConstructorForm";

export default memo(AccessTokensConstructorForm);
