import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/material";
import { useCallback } from "react";
import { usePublishedMediaDataRoom } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { useActivateDirectAudienceMutation } from "hooks/__generated-new";

interface AdvertiserDirectMediaDataRoomActivationButtonProps {
  isActivated: boolean;
  audienceType: string;
}

const AdvertiserDirectMediaDataRoomActivationButton: React.FC<
  AdvertiserDirectMediaDataRoomActivationButtonProps
> = ({ isActivated, audienceType }) => {
  const { dcrHash, driverAttestationHash } = usePublishedMediaDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [activateAudienceMutation, { loading: activating }] =
    useActivateDirectAudienceMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Audience could not be activated."
          )
        );
      },
      update: (cache, { data }) => {
        const id = data!.mediaActivateDirectAudience.audienceTypes[0];
        cache.modify({
          fields: {
            activated: () => true,
          },
          id: cache.identify({
            __typename: "DirectAudience",
            name: id,
          }),
        });
      },
    });
  const activateAudience = useCallback(
    (audienceType: string) =>
      activateAudienceMutation({
        variables: {
          input: {
            audienceTypes: [audienceType],
            dataRoomId: dcrHash,
            driverAttestationHash,
          },
        },
      }),
    [activateAudienceMutation, dcrHash, driverAttestationHash]
  );
  return activating ? (
    <CircularProgress color="inherit" size={16} thickness={2} />
  ) : (
    <Button
      color="inherit"
      disabled={isActivated}
      onClick={() => activateAudience(audienceType)}
      startIcon={
        <FontAwesomeIcon icon={isActivated ? faCheck : faBullseyePointer} />
      }
      variant="text"
    >
      {isActivated ? "Available to Publisher" : "Make available to Publisher"}
    </Button>
  );
};

export default AdvertiserDirectMediaDataRoomActivationButton;
