import { type ApolloError } from "@apollo/client";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { useOrganizationUsersQuery } from "hooks/__generated-new";
import { type OrganizationUsersQuery } from "types/__generated-new";

export type OrganizationUser = Omit<
  OrganizationUsersQuery["organization"]["users"]["nodes"][number],
  "__typename"
>;

interface UseOrganizationUsersPayload {
  organizationId: string | undefined;
}
interface UseOrganizationUsersResult {
  organizationUsers: OrganizationUser[];
  loading: boolean;
  error: ApolloError | undefined;
}

const useOrganizationUsers = ({
  organizationId,
}: UseOrganizationUsersPayload): UseOrganizationUsersResult => {
  const { data, loading, error } = useOrganizationUsersQuery({
    skip: !organizationId,
    variables: {
      organizationId: organizationId!,
    },
  });
  const organizationUsers = useMemo(
    () => sortBy(data?.organization?.users?.nodes ?? [], "email"),
    [data?.organization?.users?.nodes]
  );
  return {
    error,
    loading,
    organizationUsers,
  };
};

export default useOrganizationUsers;
