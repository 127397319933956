import {
  ClientApiError,
  type media_insights_request,
  type Session,
} from "@decentriq/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as forge from "node-forge";
import { useCallback, useEffect, useMemo } from "react";
import { useApiCore } from "contexts";
import {
  useCreateMediaInsightsComputeJobMutation,
  useGetMediaInsightsComputeJobLazyQuery,
} from "hooks/__generated-new";
import { logDebug, parseMediaDataRoomError } from "utils";

export interface CacheKeyBase {
  dataRoomId: string;
}

type JobName =
  | "computeInsights"
  | "computeAvailableAudiences"
  | "computeOverlapStatistics"
  | "getAudiencesForPublisher"
  | "getAudiencesForAdvertiser"
  | "ingestAudiencesReport"
  | "retrieveModelQualityReport";

export interface QueryComputeJobHookPayload<T, U extends CacheKeyBase> {
  jobCacheKey?: U;
  session?: Session | null;
  skip: boolean;
  jobName: JobName;
  // TODO perhaps use a strict type for jobType
  jobType: string;
  queryKeyPrefix: string[];
  createCacheKeyString: (key: U) => Promise<string>;
  transform: (data: Uint8Array) => Promise<T>;
}

export interface QueryMediaInsightsComputeJobHookResult<T> {
  loading: boolean;
  computeResults?: T;
  error: string | undefined;
  retry: () => Promise<void>;
  status: "COMPUTING" | "FETCHING" | "COMPLETED";
}

const useQueryMediaInsightsComputeJob = <T, U extends CacheKeyBase>({
  createCacheKeyString,
  jobName,
  jobCacheKey,
  jobType,
  queryKeyPrefix,
  session,
  skip,
  transform,
}: QueryComputeJobHookPayload<
  T,
  U
>): QueryMediaInsightsComputeJobHookResult<T> => {
  const { client } = useApiCore();
  const queryClient = useQueryClient();
  const [createMediaInsightsComputeJobMutation] =
    useCreateMediaInsightsComputeJobMutation();

  const [getMediaInsightsComputeJob] = useGetMediaInsightsComputeJobLazyQuery();

  const { data: existingJob, isLoading: existingJobLoading } = useQuery({
    enabled: Boolean(jobCacheKey) && !skip,
    queryFn: async () => {
      if (!jobCacheKey) {
        return null;
      }
      const getJobResult = await getMediaInsightsComputeJob({
        variables: {
          // This is specific to Media Insights media DCR
          // But can stay here for the time being.
          // All compute jobs are created this way
          input: {
            cacheKey: await createCacheKeyString(jobCacheKey),
            jobType,
            publishedDataRoomId: jobCacheKey.dataRoomId,
          },
        },
      });
      const mediaComputeJob = getJobResult?.data?.mediaComputeJob;
      const existingJob = mediaComputeJob
        ? {
            computeNodeName: mediaComputeJob.computeNodeName,
            jobIdHex: mediaComputeJob.jobIdHex,
          }
        : null;
      return existingJob;
    },
    queryKey: [...queryKeyPrefix, "existingJob", jobCacheKey],
  });

  const createJob = useCallback(
    async ({
      dataRoomId,
      session,
    }: {
      dataRoomId: string;
      session: Session;
    }) => {
      const scopeId = await client.ensureDcrDataScope(dataRoomId);
      // jobName should match any props of media_insights_request.MediaInsightsRequest that
      // have the value { dataRoomIdHex: string, scopeIdHex: string }
      // and any props of media_insights_response.MediaInsightsResponse that
      // have the value { computeNodeName: string, jobIdHex: string }
      // Could not find a convenient way to extract them from the types
      // @ts-ignore
      const request: media_insights_request.MediaInsightsRequest = {
        [jobName]: {
          dataRoomIdHex: dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      if (!(jobName in response)) {
        throw new Error(`Expected ${jobName} response`);
      }
      // @ts-ignore
      const { computeNodeName, jobIdHex } = response[jobName];
      return {
        computeNodeName,
        jobIdHex,
      };
    },
    [client, jobName]
  );

  const { mutate: createJobMutation } = useMutation({
    mutationFn: async ({
      jobCacheKey,
      session,
    }: {
      jobCacheKey: U;
      session: Session;
    }) => {
      const cacheKey = await createCacheKeyString(jobCacheKey);
      const createResponse = await createMediaInsightsComputeJobMutation({
        variables: {
          input: {
            ...(await createJob({
              dataRoomId: jobCacheKey.dataRoomId,
              session,
            })),
            cacheKey,
            jobType,
            publishedDataRoomId: jobCacheKey.dataRoomId,
          },
        },
      });
      return createResponse.data?.mediaComputeJob;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        [...queryKeyPrefix, "existingJob", jobCacheKey],
        {
          computeNodeName: data?.create.record.computeNodeName,
          jobIdHex: data?.create.record.jobIdHex,
        }
      );
    },
  });

  // If existingJob is null, we need to create a new job
  useEffect(() => {
    if (
      !skip &&
      existingJob == null &&
      !existingJobLoading &&
      !!jobCacheKey &&
      !!session
    ) {
      logDebug("creating job");
      createJobMutation({ jobCacheKey, session });
    }
  }, [
    createJobMutation,
    existingJob,
    existingJobLoading,
    session,
    jobCacheKey,
    skip,
  ]);

  // Polling for job status
  const { data: jobStatus, isLoading: jobStatusIsLoading } = useQuery({
    enabled: Boolean(session) && Boolean(existingJob),
    queryFn: async () => {
      if (!existingJob || !session) {
        return null;
      }
      const statusResponse = await session?.getComputationStatus(
        existingJob?.jobIdHex
      );

      const isCompleted = statusResponse.completeComputeNodeIds?.includes(
        existingJob.computeNodeName
      );

      return isCompleted ? "COMPLETED" : "COMPUTING";
    },
    queryKey: [
      ...queryKeyPrefix,
      "jobStatus",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
    refetchInterval: (query) => {
      if (existingJob && query.state.data === "COMPUTING") {
        // TODO @matyasfodor - polling interval should be configurable
        return 1000;
      }
    },
  });

  // Query the results
  const {
    data: computeResults,
    isLoading: resultsFetchingLoading,
    error: resultsFetchingError,
  } = useQuery({
    enabled: jobStatus === "COMPLETED" && !skip,
    queryFn: async () => {
      try {
        if (!existingJob) {
          throw new Error("Compute job not found");
        }
        logDebug("existing job found, fetching results");
        const result = await session?.getJobResults(
          forge.util.binary.hex.decode(existingJob.jobIdHex),
          existingJob.computeNodeName
        );

        if (!result) {
          throw new Error("No result");
        }

        return await transform(result);
      } catch (error) {
        throw parseMediaDataRoomError(error);
      }
    },
    queryKey: [
      ...queryKeyPrefix,
      "fetchResults",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
  });

  const error = useMemo(
    () =>
      resultsFetchingError === null
        ? undefined
        : resultsFetchingError instanceof ClientApiError
          ? resultsFetchingError?.message
          : `${resultsFetchingError}`,
    [resultsFetchingError]
  );

  const retry = useCallback(async () => {
    if (!jobCacheKey || !session) {
      throw new Error("No key or session");
    }
    return createJobMutation({ jobCacheKey, session });
  }, [jobCacheKey, createJobMutation, session]);

  const status = useMemo(
    () =>
      jobStatusIsLoading || jobStatus !== "COMPLETED"
        ? "COMPUTING"
        : resultsFetchingLoading
          ? "FETCHING"
          : "COMPLETED",
    [jobStatus, jobStatusIsLoading, resultsFetchingLoading]
  );

  return {
    computeResults,
    error,
    loading: status !== "COMPLETED",
    retry,
    status,
  };
};

export default useQueryMediaInsightsComputeJob;
