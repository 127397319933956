import { DqCard, DqCardContent } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useOrganizationPreferences } from "hooks";
import { DataRoomType, dataRoomTypeCardPresentation } from "models";

interface DataRoomTypeSelectDialogProps {
  open: boolean;
  onConfirm: (type: DataRoomType) => void;
  onCancel: () => void;
}

const DataRoomTypeSelectDialog: React.FC<DataRoomTypeSelectDialogProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const {
    hasAnalyticsFeatures,
    hasAdvertiserFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
    canViewMeasurements,
  } = useOrganizationPreferences();

  const options = useMemo<DataRoomType[]>(
    () => [
      ...(hasAdvertiserFeatures ||
      hasPublisherFeatures ||
      hasDataPartnerFeatures
        ? [DataRoomType.MediaInsights]
        : []),
      ...(canViewMeasurements ? [DataRoomType.Measurement] : []),
      ...(hasAnalyticsFeatures ? [DataRoomType.DataScience] : []),
    ],
    [
      hasAnalyticsFeatures,
      hasAdvertiserFeatures,
      hasDataPartnerFeatures,
      hasPublisherFeatures,
      canViewMeasurements,
    ]
  );
  const [dataRoomType, setDataRoomType] = useState<DataRoomType | null>(null);
  useEffect(() => {
    if (!open) {
      setDataRoomType(null);
    }
  }, [open, setDataRoomType]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={() => onCancel()}
      open={open}
    >
      <DialogTitle>Choose the type of data clean room</DialogTitle>
      <DialogContent>
        <Typography fontWeight="fontWeightSemiBold" mb={0.5} variant="body1">
          Clean room type
        </Typography>
        <Typography mb={1} variant="body2">
          Select the clean room type for your use-case
        </Typography>
        <Grid columnSpacing={2} container={true}>
          {options.map((type) => {
            const option = dataRoomTypeCardPresentation[type];
            return (
              <Grid item={true} key={type} xs={options.length === 2 ? 6 : 4}>
                <DqCard
                  data-testid={
                    testIds.dataroom.dataRoomTypeSelectDialog.helper + type
                  }
                  onSelect={() => setDataRoomType(type)}
                  selected={type === dataRoomType}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <DqCardContent>
                    <FontAwesomeIcon icon={option.icon} size="3x" />
                    <Typography fontWeight="600" mt={1}>
                      {option.title}
                    </Typography>
                  </DqCardContent>
                </DqCard>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "auto",
        }}
      >
        <Button color="inherit" onClick={onCancel} variant="text">
          Back
        </Button>
        <Button
          color="primary"
          disabled={!dataRoomType}
          onClick={() => {
            if (!dataRoomType) {
              return;
            }
            onConfirm(dataRoomType);
          }}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DataRoomTypeSelectDialog.displayName = "DataRoomTypeSelectDialog";

export default memo(DataRoomTypeSelectDialog);
