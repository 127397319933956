import { type lookalike_media_request, type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type CreateMediaComputeJobInput } from "types/__generated-new";
import { type OverlapInsightsCacheKey } from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";
import useQueryMediaInsightsComputeJob, {
  type QueryComputeJobHookPayload,
} from "../useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";

export const audienceSizeSchema = z.object({
  audience_sizes: z.array(
    z.object({
      audience_type: z.string(),
      reach: z.number(),
      size: z.number(),
    })
  ),
});

export interface UseLookalikeMediaDataRoomAudienceSizesReturnValue {
  computeResults?: Map<string, Map<number, number>>;
  error?: string;
  retry: () => Promise<void>;
  status: "COMPUTING" | "COMPLETED" | "FETCHING";
}

interface AudienceSizesHookPayload {
  createCacheKeyString: (key: OverlapInsightsCacheKey) => Promise<string>;
  key?: OverlapInsightsCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

const useLookalikeMediaDataRoomAudienceSizes = ({
  createCacheKeyString,
  key,
  session,
  skip,
}: AudienceSizesHookPayload): UseLookalikeMediaDataRoomAudienceSizesReturnValue => {
  const jobType = "LOOKALIKE_MEDIA_AUDIENCE_SIZES";
  const { client } = useApiCore();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const createJob: QueryComputeJobHookPayload<
    null,
    OverlapInsightsCacheKey
  >["createJob"] = useCallback(
    async ({
      key,
      jobCacheKeyString,
      session,
    }): Promise<CreateMediaComputeJobInput> => {
      const scopeId = await client.ensureDcrDataScope(key.dataRoomId);
      const request: lookalike_media_request.LookalikeMediaRequest = {
        computeAudienceSizes: {
          dataRoomIdHex: key.dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendLookalikeMediaRequest(request);
      if (!("computeAudienceSizes" in response)) {
        throw new Error("Expected computeAudienceSizes response");
      }
      const computeNodeName = response.computeAudienceSizes.computeNodeName;
      const jobIdHex = response.computeAudienceSizes.jobIdHex;
      return {
        cacheKey: jobCacheKeyString,
        computeNodeName,
        jobIdHex,
        jobType,
        publishedDataRoomId: key.dataRoomId,
      };
    },
    [client]
  );

  const transform = useCallback(async (result: Uint8Array) => {
    const zip = await loadAsync(result);

    const audienceSizesFile = zip.file("audience_sizes.json");
    if (audienceSizesFile === null) {
      throw new Error("audience_sizes.json not found in zip");
    }
    const sizes = audienceSizeSchema.parse(
      JSON.parse(await audienceSizesFile.async("string"))
    );

    const audienceSizes: Map<
      string,
      Map<number, number>
    > = sizes.audience_sizes.reduce((acc, { audience_type, reach, size }) => {
      const audienceTypeMap = acc.get(audience_type) ?? new Map();
      audienceTypeMap.set(reach, size);
      acc.set(audience_type, audienceTypeMap);

      return acc;
    }, new Map());

    return audienceSizes;
  }, []);

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    createJob,
    jobCacheKey: key ?? undefined,
    jobType,
    queryKeyPrefix: ["lal-dcr-audience-sizes"],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Lookalike Media DCR audience sizes`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useLookalikeMediaDataRoomAudienceSizes;
