import { type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type AudienceOverlapStatisticsRaw,
  type OverlapInsightsCacheKey,
  type OverlapStatistic,
  type OverlapStatisticsCollection,
} from "../models";
import useQueryMediaInsightsComputeJob, {
  type QueryMediaInsightsComputeJobHookResult,
} from "./useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";

interface QueryOverlapStatisticsHookPayload {
  createCacheKeyString: (key: OverlapInsightsCacheKey) => Promise<string>;
  key?: OverlapInsightsCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

export interface QueryOverlapStatistics {
  statistics: AudienceOverlapStatisticsRaw[];
  isEmpty: boolean;
  totalPublisherCount: number;
  withNoiseCalculation: boolean;
}

export type QueryOverlapStatisticsHookResult =
  QueryMediaInsightsComputeJobHookResult<QueryOverlapStatistics>;

/**
 * useQueryOverlapStatistics - powers the overlap on the data tab
 * Should always be activated when both parties provision data
 */
const useQueryOverlapStatistics = ({
  createCacheKeyString,
  key,
  session,
  skip,
}: QueryOverlapStatisticsHookPayload): QueryOverlapStatisticsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const jobType = "MEDIA_INSIGHTS_OVERLAP_STATISTICS";

  const transform = useCallback(
    async (result: Uint8Array): Promise<QueryOverlapStatistics> => {
      const zip = await loadAsync(result);

      const overlapInsightsStatisticsFile = zip.file("overlap.json");
      if (overlapInsightsStatisticsFile === null) {
        throw new Error("overlap.json not found in zip");
      }
      const overlapInsightsStatistics: OverlapStatisticsCollection = JSON.parse(
        await overlapInsightsStatisticsFile.async("string")
      );

      const formattedStatistics: AudienceOverlapStatisticsRaw[] = (
        overlapInsightsStatistics?.overlap_statistics || []
      ).map((overlap: OverlapStatistic) => ({
        advertiserSize: overlap.advertiser_size,
        audienceType: overlap.audience_type,
        overlapSize: overlap.overlap_size,
      }));
      return {
        isEmpty: formattedStatistics.every(
          ({ overlapSize }) => overlapSize === 0
        ),
        statistics: formattedStatistics,
        totalPublisherCount: overlapInsightsStatistics?.total_publisher,
        withNoiseCalculation: !!overlapInsightsStatistics?.total_publisher,
      };
    },
    []
  );

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    jobCacheKey: key ?? undefined,
    jobName: "computeOverlapStatistics",
    jobType,
    queryKeyPrefix: ["mi", jobType],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Overlap statistics`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useQueryOverlapStatistics;
