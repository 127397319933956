import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { InlineEditor } from "@decentriq/components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  styled,
  Tooltip,
} from "@mui/material";
import { type FC } from "react";
import { DragHandleButton } from "components";
import { useDataRoom } from "contexts";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import {
  castFormatTypeToPrimitiveType,
  castPrimitiveTypeToFormatType,
} from "models";
import {
  type ColumnDataType,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
} from "types/__generated-new";
import { sanitizeIdentifier } from "utils/validation";
import {
  dataRoomTableFormatTypeOptions,
  dataRoomTablePrimitiveTypeOptions,
} from "./DataNodeConstructorModels";

interface TableNodeColumnEditableTileProps {
  id: string;
  name?: string;
  formatType?: TableColumnFormatType;
  hashWith?: TableColumnHashingAlgorithm;
  primitiveType?: ColumnDataType;
  nullable?: boolean;
  disabled: boolean;
  onOutcomeDialogOpen?: () => void;
  validate?: (value: string) => string | null;
  canDelete?: boolean;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(even)": {
    background: "whitesmoke",
  },
}));

const TableNodeColumnEditableTile: FC<TableNodeColumnEditableTileProps> = ({
  id,
  name,
  primitiveType,
  formatType,
  hashWith,
  nullable,
  disabled,
  validate,
  onOutcomeDialogOpen,
  canDelete = true,
}) => {
  const { isPublished } = useDataRoom();
  const {
    handleTableColumnDelete,
    handleTableColumnDataTypeUpdate,
    handleTableColumnHashWithUpdate,
    handleTableColumnNameUpdate,
    handleTableColumnNullableUpdate,
  } = useDataNodeActions();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    display: "flex",
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };
  const isHashWithDisabled =
    !formatType || TableColumnFormatType.HashSha256Hex === formatType;
  return (
    <StyledListItem ref={setNodeRef} style={style} sx={{ p: 0.5 }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          gap: 0.5,
          width: 0,
        }}
      >
        <InlineEditor
          cancelEditingButtonEnabled={false}
          fullWidth={true}
          onChange={(value: any) => {
            handleTableColumnNameUpdate(
              id,
              SANITIZE_IDENTIFIER_INPUT ? sanitizeIdentifier(value) : value
            );
          }}
          placeholder="Name"
          readOnly={disabled}
          saveEditingButtonEnabled={false}
          saveEditingOnClickAway={true}
          startAdornment={
            <Tooltip
              disableFocusListener={true}
              disableTouchListener={true}
              enterDelay={500}
              placement="top"
              title="" // Drag to re-order
            >
              <div style={{ alignSelf: "center", display: "flex" }}>
                <DragHandleButton
                  {...attributes}
                  {...listeners}
                  // @ts-ignore
                  style={{ margin: 0, padding: "0.25rem" }}
                />
              </div>
            </Tooltip>
          }
          style={{ flex: 1 }}
          validate={validate}
          value={name}
        />
        <Box
          sx={{
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            mb: -0.5,
            mt: -0.5,
          }}
        >
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            enterDelay={500}
            placement="top"
            title="" // Data type
          >
            <Select
              IconComponent={(props) => (
                <FontAwesomeIcon icon={faCaretDownSolid} {...props} />
              )}
              // @ts-ignore
              disableUnderline={true}
              disabled={disabled}
              onChange={(event) => {
                if (!isPublished) {
                  const formatType = event.target
                    .value as TableColumnFormatType;
                  const primitiveType =
                    castFormatTypeToPrimitiveType(formatType);
                  handleTableColumnDataTypeUpdate(
                    id,
                    primitiveType,
                    formatType
                  ).then(() => onOutcomeDialogOpen?.());
                }
              }}
              size="small"
              sx={(theme) => ({
                "& .MuiSelect-select": {
                  backgroundColor: "transparent",
                  paddingBottom: `${theme.spacing(0.875)} !important`,
                  paddingLeft: `${theme.spacing(3)} !important`,
                  paddingRight: `${theme.spacing(3)} !important`,
                },
                backgroundColor: "transparent",
                borderBottom: "solid #CCC 1px",
                minWidth: 124,
                textAlign: "center",
                width: 240,
              })}
              value={
                !isPublished
                  ? formatType || castPrimitiveTypeToFormatType(primitiveType)
                  : formatType || primitiveType
              }
              variant="standard"
            >
              {!isPublished || (isPublished && formatType)
                ? dataRoomTableFormatTypeOptions?.map(
                    ({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )
                : dataRoomTablePrimitiveTypeOptions?.map(
                    ({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
            </Select>
          </Tooltip>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              minWidth: 184,
              width: 184,
            }}
          >
            <Checkbox
              checked={hashWith === TableColumnHashingAlgorithm.Sha256Hex}
              disabled={isHashWithDisabled}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event: any) => {
                handleTableColumnHashWithUpdate(id, event.target.checked).then(
                  onOutcomeDialogOpen
                );
              }}
              size="small"
              sx={{
                flex: 1,
                p: 0,
                ...(isHashWithDisabled ? {} : { color: "#498E8F" }),
              }}
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              minWidth: 180,
              width: 180,
            }}
          >
            <Checkbox
              checked={nullable}
              disabled={disabled}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event: any) => {
                handleTableColumnNullableUpdate(id, event.target.checked).then(
                  onOutcomeDialogOpen
                );
              }}
              size="small"
              sx={{
                color: "#498E8F",
                flex: 1,
                p: 0,
              }}
            />
          </div>
          <Tooltip
            disableFocusListener={true}
            disableTouchListener={true}
            enterDelay={500}
            placement="top"
            title="" // Delete
          >
            <IconButton
              disabled={disabled || canDelete === false}
              onClick={() =>
                handleTableColumnDelete(id).then(onOutcomeDialogOpen)
              }
              size="small"
              style={{ borderRadius: 4, padding: 4 }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </StyledListItem>
  );
};

export default TableNodeColumnEditableTile;
