import { Typography } from "@mui/material";
import { memo } from "react";
import { useDatasetExportQuery } from "hooks/__generated-new";

type MetaConnectionConfigurationProps = {
  connectionId: string;
};

const MetaConnectionConfiguration: React.FC<
  MetaConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { datasetExport: { config: datasetExportConfig = {} } = {} } = {},
  } = useDatasetExportQuery({
    variables: { id: connectionId },
  });
  const { adAccountId, audienceName } = datasetExportConfig || {};
  return (
    <div>
      <Typography variant="body2">
        <strong>Account ID:</strong> {adAccountId}
      </Typography>
      <Typography variant="body2">
        <strong>Audience name:</strong> {audienceName}
      </Typography>
    </div>
  );
};

MetaConnectionConfiguration.displayName = "MetaConnectionConfiguration";

export default memo(MetaConnectionConfiguration);
