import { type FetchResult } from "@apollo/client";
import {
  useDraftDataRoomRequirePasswordQuery,
  useUpdateDraftDataRoomRequirePasswordMutation,
} from "hooks/__generated-new";
import {
  DraftDataRoomPasswordFragment,
  type UpdateDraftDataRoomRequirePasswordMutation,
} from "types/__generated-new";

interface useDraftDataRoomRequirePasswordArgs {
  id: string;
  skip?: boolean;
}

interface useDraftDataRoomRequirePasswordResult {
  draftDataRoomRequirePassword: boolean;
  draftDataRoomRequirePasswordLoading: boolean;
  setDraftDataRoomRequirePassword: (
    isFavorite: boolean
  ) => Promise<
    FetchResult<
      UpdateDraftDataRoomRequirePasswordMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  setDraftDataRoomRequirePasswordLoading: boolean;
}

const useDraftDataRoomRequirePassword = ({
  id: dataRoomId,
  skip = false,
}: useDraftDataRoomRequirePasswordArgs): useDraftDataRoomRequirePasswordResult => {
  const { data, loading: requirePasswordLoading } =
    useDraftDataRoomRequirePasswordQuery({
      skip,
      variables: { id: dataRoomId },
    });
  const { draftDataRoom } = data || {};
  const { requirePassword = false } = draftDataRoom || {};
  const [
    updateDraftDataRoomRequirePasswordMutation,
    { loading: updateDraftDataRoomRequirePasswordLoading },
  ] = useUpdateDraftDataRoomRequirePasswordMutation();
  const setRequirePassword = (requirePassword: boolean) => {
    return updateDraftDataRoomRequirePasswordMutation({
      optimisticResponse: {
        draftDataRoom: {
          __typename: "DraftDataRoomMutations",
          update: {
            __typename: "DraftDataRoom",
            id: dataRoomId,
            requirePassword,
          },
        },
      },
      update: (cache, result) => {
        const requirePassword =
          result?.data?.draftDataRoom?.update?.requirePassword;
        if (!requirePassword) {
          cache.writeFragment({
            data: { password: null },
            fragment: DraftDataRoomPasswordFragment,
            id: cache.identify({
              __typename: "DataRoom",
              id: dataRoomId,
            }),
          });
        }
      },
      variables: {
        id: dataRoomId,
        requirePassword,
      },
    });
  };
  const setRequirePasswordLoading = updateDraftDataRoomRequirePasswordLoading;
  return {
    draftDataRoomRequirePassword: requirePassword,
    draftDataRoomRequirePasswordLoading: requirePasswordLoading,
    setDraftDataRoomRequirePassword: setRequirePassword,
    setDraftDataRoomRequirePasswordLoading: setRequirePasswordLoading,
  };
};

export default useDraftDataRoomRequirePassword;
