import { DqTable } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataRoomParticipantAvatar } from "components";
import { OrganizationLicenseTypesTooltip } from "containers";
import { useOrganizationsQuery } from "hooks/__generated-new";
import {
  type OrganizationsQuery,
  OrganizationState,
} from "types/__generated-new";
import { HideArchivedOrganizationsCheckbox } from "./components";

type Organization = OrganizationsQuery["organizations"]["nodes"][number];

const OrganizationsList: React.FC = () => {
  const { data: organizationsData, loading } = useOrganizationsQuery();
  const navigate = useNavigate();
  const [hideArchivedOrganizations, setHideArchivedOrganizations] =
    useState<boolean>(true);
  const { organizationsList, totalCount } = useMemo<{
    organizationsList: Organization[];
    totalCount: number;
  }>(() => {
    if (!organizationsData) {
      return {
        organizationsList: [],
        totalCount: 0,
      };
    }
    const organizationsList = (
      organizationsData!.organizations.nodes || []
    ).filter(({ state }) =>
      hideArchivedOrganizations ? state !== OrganizationState.Archived : true
    );
    return {
      organizationsList,
      totalCount: organizationsList.length,
    };
  }, [organizationsData, hideArchivedOrganizations]);

  const organizationsListColumns = useMemo<MRT_ColumnDef<Organization>[]>(
    () => [
      {
        Cell: ({ cell, row }) => {
          const avatarImage = cell.getValue();
          return avatarImage ? (
            <Avatar
              src={`data:image;base64,${avatarImage}`}
              sx={{ height: "32px", margin: "2px 0", width: "32px" }}
            />
          ) : (
            <DataRoomParticipantAvatar
              name={row?.original?.name || ""}
              size="32px"
            />
          );
        },
        accessorKey: "logo",
        header: "",
        id: "avatar",
        maxSize: 50,
      },
      {
        Footer: <div>Total: {totalCount}</div>,
        accessorKey: "name",
        header: "Name",
        id: "name",
      },
      {
        Cell: ({ cell }) => cell.getValue<string>()?.substring(0, 6),
        accessorKey: "id",
        header: "Id",
        id: "id",
        maxSize: 100,
      },
      {
        Header: (
          <div>
            License
            <OrganizationLicenseTypesTooltip />
          </div>
        ),
        accessorKey: "state",
        header: "License status",
        id: "license",
      },
      {
        Footer: () => {
          const usersTotalCount = organizationsList.reduce(
            (totalCount, organization) =>
              totalCount + organization.users.totalCount || 0,
            0
          );
          return <div>Total: {usersTotalCount}</div>;
        },
        accessorKey: "users.totalCount",
        header: "# of users",
        id: "usersTotalCount",
      },
      {
        Footer: () => {
          const dataRoomsTotalCount = organizationsList.reduce(
            (totalCount, organization) =>
              totalCount + organization.participatingDataRooms.totalCount || 0,
            0
          );
          return <div>Total: {dataRoomsTotalCount}</div>;
        },
        accessorKey: "participatingDataRooms.totalCount",
        header: "# of DCRs",
        id: "dataRoomsTotalCount",
      },
    ],
    [organizationsList, totalCount]
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      >
        <CircularProgress color="inherit" size={16} thickness={3} />
      </div>
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <DqTable
        columns={organizationsListColumns}
        data={organizationsList}
        enableGlobalFilter={true}
        enableSorting={true}
        enableTopToolbar={true}
        initialState={{
          showGlobalFilter: true,
        }}
        localization={{
          noRecordsToDisplay: "No organizations found",
        }}
        muiSearchTextFieldProps={{
          InputProps: {
            // @ts-ignore
            "data-testid": testIds.adminPortal.organizations.search,
            sx: {
              margin: 1,
              minWidth: "260px",
            },
          },
          placeholder: "Search organizations",
          size: "medium",
          variant: "standard",
        }}
        muiTableBodyRowProps={({ row }) => ({
          "data-testid":
            testIds.adminPortal.organizations.recordHelper +
            row.original.name.toLowerCase(),
          onClick: () => navigate(`/admin/organizations/${row?.original?.id}`),
          sx: {
            "&:hover td:after": {
              backgroundColor: "primary.light",
              content: '""',
              height: "100%",
              left: 0,
              opacity: 0.125,
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: -1,
            },
            cursor: "pointer",
          },
        })}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            flex: "0 0 auto",
            minHeight: "auto",
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            borderBottom: "1px solid",
            borderColor: "divider",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <HideArchivedOrganizationsCheckbox
            checked={hideArchivedOrganizations}
            setHideArchivedOrganizations={setHideArchivedOrganizations}
          />
        )}
      />
    </Box>
  );
};

OrganizationsList.displayName = "OrganizationsList";

export default memo(OrganizationsList);
