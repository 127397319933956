import { type ApolloCache, type NormalizedCacheObject } from "@apollo/client";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type MatchNodeDeleteMutationOptions,
  type PostNodeDeleteMutationOptions,
  type PreviewNodeDeleteMutationOptions,
  type S3NodeDeleteMutationOptions,
  type ScriptingNodeDeleteMutationOptions,
  type SqliteNodeDeleteMutationOptions,
  type SqlNodeDeleteMutationOptions,
  type SyntheticNodeDeleteMutationOptions,
  useDraftNodeTypenameQuery,
  useMatchNodeDeleteMutation,
  usePostNodeDeleteMutation,
  usePreviewNodeDeleteMutation,
  useS3NodeDeleteMutation,
  useScriptingNodeDeleteMutation,
  useSqliteNodeDeleteMutation,
  useSqlNodeDeleteMutation,
  useSyntheticNodeDeleteMutation,
} from "hooks/__generated-new";
import { ComputeNodeTypeNames } from "models";

type ComputeNodeDeleteMutationOptions =
  | MatchNodeDeleteMutationOptions
  | PostNodeDeleteMutationOptions
  | PreviewNodeDeleteMutationOptions
  | ScriptingNodeDeleteMutationOptions
  | SqliteNodeDeleteMutationOptions
  | SqlNodeDeleteMutationOptions
  | SyntheticNodeDeleteMutationOptions
  | S3NodeDeleteMutationOptions;

const useComputeNodeDeleteMutation = (
  mutationOptions: ComputeNodeDeleteMutationOptions
) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { variables } = mutationOptions || {};
  const { id } = variables || {};
  const { data: draftNodeTypenameData } = useDraftNodeTypenameQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    variables: { id },
  });
  const { draftNode } = draftNodeTypenameData || {};
  const { __typename } = draftNode || {};
  mutationOptions.onError = (error) => {
    enqueueSnackbar(
      ...mapDraftDataRoomErrorToSnackbar(
        error,
        "The computation could not be deleted."
      )
    );
  };
  mutationOptions.update = (cache: ApolloCache<NormalizedCacheObject>) => {
    cache.evict({ id: cache.identify({ __typename, id }) });
    cache.gc();
  };
  const joinComputeNodeDeleteMutation = useMatchNodeDeleteMutation(
    mutationOptions as MatchNodeDeleteMutationOptions
  );
  const sqliteComputeNodeDeleteMutation = useSqliteNodeDeleteMutation(
    mutationOptions as SqliteNodeDeleteMutationOptions
  );
  const sqlComputeNodeDeleteMutation = useSqlNodeDeleteMutation(
    mutationOptions as SqlNodeDeleteMutationOptions
  );
  const postComputeNodeDeleteMutation = usePostNodeDeleteMutation(
    mutationOptions as PostNodeDeleteMutationOptions
  );
  const previewComputeNodeDeleteMutation = usePreviewNodeDeleteMutation(
    mutationOptions as PreviewNodeDeleteMutationOptions
  );
  const scriptingComputeNodeDeleteMutation = useScriptingNodeDeleteMutation(
    mutationOptions as ScriptingNodeDeleteMutationOptions
  );
  const s3ComputeNodeDeleteMutation = useS3NodeDeleteMutation(
    mutationOptions as S3NodeDeleteMutationOptions
  );
  const syntheticComputeNodeDeleteMutation = useSyntheticNodeDeleteMutation(
    mutationOptions as SyntheticNodeDeleteMutationOptions
  );
  switch (__typename as ComputeNodeTypeNames) {
    case ComputeNodeTypeNames.DraftMatchNode:
      return joinComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftSqliteNode:
      return sqliteComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftSqlNode:
      return sqlComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftPostNode:
      return postComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftPreviewNode:
      return previewComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftScriptingNode:
      return scriptingComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftS3SinkNode:
      return s3ComputeNodeDeleteMutation;
    case ComputeNodeTypeNames.DraftSyntheticNode:
      return syntheticComputeNodeDeleteMutation;
    default:
      return [() => {}, {}];
  }
};

export default useComputeNodeDeleteMutation;
