import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import {
  EXTERNAL_DATA_IMPORT_STEPS,
  useImportExternalDataDialog,
} from "features/datasets/components/ImportExternalData";
import { DataSourceType } from "types/__generated-new";
import {
  ExternalDataImportStatus,
  ExternalDataSourceForm,
  ExternalDataSourceSelector,
} from "./components";

type ImportExternalDataDialogProps = {
  open: boolean;
  onCancel: () => void;
};

// Each step of a form must have its own DialogActions buttons
// as confirm button of a form on step 2 must be rendered in a context of react-hook-form
// There is no actual reason to wrap entire dialog in the context just to have DialogActions in the same place
// In addition, it is easier to handle button's names and onClick properties, multiple if-else
// that adjust buttons logic are avoided
const ImportExternalDataDialog: React.FC<ImportExternalDataDialogProps> = ({
  open,
  onCancel = () => {},
}) => {
  const {
    importStep,
    setImportStep,
    setDatasetImportId,
    setSelectedDataSource,
    setIsImportCreated,
    selectedDataSource,
  } = useImportExternalDataDialog();

  const handleDialogClose = useCallback(() => {
    onCancel();
    setSelectedDataSource(DataSourceType.S3);
    setImportStep(EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE);
    setDatasetImportId(null);
    setIsImportCreated(false);
  }, [
    onCancel,
    setDatasetImportId,
    setImportStep,
    setIsImportCreated,
    setSelectedDataSource,
  ]);

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogTitle>Import data from external source</DialogTitle>
      <DialogContent>
        {/* Step 1 */}
        {importStep === EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE && (
          <ExternalDataSourceSelector onClose={handleDialogClose} />
        )}
        {/* Step 2 */}
        {importStep === EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM && (
          <>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                marginBottom: "16px",
              }}
            >
              <ExternalConnectionsIcon
                connectionType={selectedDataSource}
                size={ExternalConnectionsIconSize.sm}
              />
              <Typography sx={{ marginLeft: "4px" }}>
                {dataSourceTypePresentation.get(selectedDataSource)}
              </Typography>
            </Box>
            <ExternalDataSourceForm />
          </>
        )}
        {/* Step 3 */}
        {importStep === EXTERNAL_DATA_IMPORT_STEPS.IMPORT_STATUS && (
          <ExternalDataImportStatus onClose={handleDialogClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

ImportExternalDataDialog.displayName = "ImportExternalDataDialog";

export default memo(ImportExternalDataDialog);
