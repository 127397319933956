import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo as faCircleInfoSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ResponsiveBar } from "@nivo/bar";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "papaparse";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useApiCore, usePublishedMediaDataRoom } from "contexts";
import {
  type AudienceOverlap,
  getAudiencePresentation,
} from "features/mediaDataRoom/index";
import { entireOverlapKey, sortAudiences } from "features/mediaDataRoom/types";
import { replaceDecentriqOrgName } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  useAudienceInsightsStatisticsQuery,
  useMediaDataRoomActivationTypeQuery,
  useMediaDataRoomHashesQuery,
} from "hooks/__generated-new";

const OverlapNotAvailableAlert = styled(Alert)(({ theme }) => ({
  "& .MuiAlert-icon": {
    "& > svg": {
      margin: 1,
    },
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    lineHeight: "14px",
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0),
  },
  "& .MuiAlert-message": {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    gap: "0.25rem",
    justifyContent: "space-between",
    lineHeight: "16px",
    padding: theme.spacing(0.5, 0),
  },
  borderRadius: 0,
  padding: theme.spacing(0.5, 1),
  [theme.breakpoints.down("md")]: {
    marginTop: 4,
  },
}));
interface AudienceInsightsStatisticsProps {
  id: string;
  [key: string]: any;
}

const AudienceInsightsStatistics: React.FC<AudienceInsightsStatisticsProps> =
  memo(({ id }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { store } = useApiCore();
    const { data: activationTypeData, loading: activationTypeLoading } =
      useMediaDataRoomActivationTypeQuery({
        fetchPolicy: "cache-and-network",
        onError: (error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              `Media data clean room activation type could not be determined.`
            )
          );
        },
        variables: { id },
      });
    const { publishedMediaDataRoom: publishedMediaDataRoomActivationType } =
      activationTypeData || {};
    const { activationType } = publishedMediaDataRoomActivationType || {};
    const {
      data: mediaDataRoomHashesData,
      loading: mediaDataRoomHashesLoading,
    } = useMediaDataRoomHashesQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Media data clean room hashes could not be determined.`
          )
        );
      },
      variables: { id },
    });
    const { publishedMediaDataRoom } = mediaDataRoomHashesData || {};
    const { driverAttestationHash } = publishedMediaDataRoom || {};
    const { advertiserOrganization, publisherOrganization } =
      usePublishedMediaDataRoom();
    const {
      data: audienceInsightsStatisticsData,
      loading: audienceInsightsStatisticsLoading,
      error: audienceInsightsStatisticsError,
    } = useAudienceInsightsStatisticsQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            `Audience insights could not be retrieved. Please try again by refreshing the page.`
          )
        );
      },
      variables: {
        dataRoomId: id,
        driverAttestationHash,
      },
    });
    const { mediaCalculateStatistics, mediaCalculateOverlapByAudience } =
      audienceInsightsStatisticsData || {};
    const { advertiserSize } = mediaCalculateStatistics || {};
    const { overlapCsv: overlapCsvReference } =
      mediaCalculateOverlapByAudience || {};
    const [audienceOverlaps, setAudienceOverlaps] = useState<AudienceOverlap[]>(
      []
    );
    const [selectedOverlapType, setSelectedOverlapType] = useState<string>("");
    const selectedOverlapStatistics = useMemo<AudienceOverlap>(() => {
      return (
        audienceOverlaps.find(({ type }) => type === selectedOverlapType) || {
          notMatching: 0,
          overlap: 0,
          total: 0,
          type: "__default__",
        }
      );
    }, [audienceOverlaps, selectedOverlapType]);
    const barData = useMemo(
      () => [
        {
          key: "total",
          value: selectedOverlapStatistics.total || 0,
        },
        {
          key: "overlap",
          value: selectedOverlapStatistics.overlap,
        },
      ],
      [selectedOverlapStatistics]
    );
    const overlapPercentage = useMemo(
      () =>
        (
          (selectedOverlapStatistics?.overlap /
            selectedOverlapStatistics?.total) *
          100
        ).toFixed(1),
      [selectedOverlapStatistics]
    );
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    useEffect(() => {
      if (overlapCsvReference) {
        const overlapFile = store.get(overlapCsvReference);
        // Result is Array of Arrays
        // Each row represented by an array with 3 values in it:
        // array[0] - overlap type, array[1] - total of items, array[2] - overlap
        const { data: parsedOverlapData } = parseCsv<string[]>(overlapFile);
        const overlapData = parsedOverlapData
          .filter((overlap) => overlap.some(Boolean))
          .map(([overlapType, total, overlap]) => ({
            notMatching: parseInt(total) - parseInt(overlap),
            overlap: parseInt(overlap),
            total: parseInt(total),
            type: overlapType,
          }));
        setAudienceOverlaps(overlapData);
        setSelectedOverlapType(
          sortAudiences(overlapData.map((d) => d.type))[0]
        );
      }
    }, [advertiserSize, overlapCsvReference, store]);
    const allAudiencesHaveZeroOverlap = audienceOverlaps.every(
      (audience) => audience.overlap === 0
    );
    const exportOverlapStatistics = useCallback(() => {
      if (!overlapCsvReference) {
        return;
      }
      const fileContent = audienceOverlaps.reduce(
        (csv, { type, overlap, total }) => {
          const matchRate = ((overlap / total) * 100).toFixed(1);
          return `${csv}\n${type},${overlap},${total},${matchRate}%`;
        },
        "Advertiser audience,Individuals in the overlap,Total customers in audience,Match Rate"
      );
      const fileName = `Basic_overlap_${publisherOrganization?.name}_${advertiserOrganization?.name}_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}.csv`;
      const file = new File([fileContent], fileName, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, [
      overlapCsvReference,
      audienceOverlaps,
      publisherOrganization?.name,
      advertiserOrganization?.name,
    ]);
    if (audienceInsightsStatisticsError) {
      return (
        <OverlapNotAvailableAlert
          icon={<FontAwesomeIcon fixedWidth={true} icon={faCircleInfoSolid} />}
          severity="error"
          sx={{ alignSelf: "center" }}
        >
          Overlap match rate not available.
        </OverlapNotAvailableAlert>
      );
    }
    const isDownloadStatisticsButtonVisible =
      !audienceInsightsStatisticsLoading &&
      !audienceInsightsStatisticsError &&
      !allAudiencesHaveZeroOverlap &&
      selectedOverlapStatistics?.overlap !== 0;
    return activationTypeLoading ||
      mediaDataRoomHashesLoading ||
      audienceInsightsStatisticsLoading ? (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" size={64} thickness={1} />
      </Box>
    ) : allAudiencesHaveZeroOverlap ? (
      <OverlapNotAvailableAlert
        icon={<FontAwesomeIcon fixedWidth={true} icon={faCircleInfoSolid} />}
        severity="info"
        sx={{ alignSelf: "center" }}
      >
        Overlap match rate not available. All Advertiser audiences are too
        small.
      </OverlapNotAvailableAlert>
    ) : (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        {activationType !== null ? (
          <Box
            sx={{
              alignItems: "flex-start",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ maxWidth: "350px", minWidth: "350px" }}>
              <FormLabel component="legend" sx={{ paddingLeft: "2px" }}>
                Advertiser audience
              </FormLabel>
              <Select
                disabled={audienceOverlaps.length === 2}
                displayEmpty={true}
                fullWidth={true}
                multiple={false}
                onChange={(event) => {
                  setSelectedOverlapType(event.target.value as string);
                }}
                renderValue={getAudiencePresentation}
                sx={{
                  ...(audienceOverlaps.length === 2
                    ? {
                        "& .MuiSelect-icon": { display: "none" },
                        "&.Mui-disabled": { background: "white" },
                      }
                    : {}),
                  background: "transparent",
                }}
                value={selectedOverlapType}
                variant="standard"
              >
                {[...audienceOverlaps]
                  .sort((a, b) => (a.type === entireOverlapKey ? 1 : -1))
                  .map((audience) => (
                    <MenuItem
                      disabled={audience.overlap === 0}
                      key={audience.type}
                      value={audience.type}
                    >
                      <ListItemText
                        primary={getAudiencePresentation(audience.type)}
                        secondary={
                          audience.overlap === 0 ? "Overlap is too small" : ""
                        }
                        secondaryTypographyProps={{
                          sx: {
                            maxWidth: "340px",
                            whiteSpace: "normal",
                          },
                        }}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {isDownloadStatisticsButtonVisible && (
              <Tooltip
                disableFocusListener={true}
                disableTouchListener={true}
                placement="top-start"
                title="Export match rate of all audiences to CSV"
              >
                <div style={{ display: "flex" }}>
                  <IconButton
                    onClick={exportOverlapStatistics}
                    sx={{ padding: 0.5 }}
                  >
                    <FontAwesomeIcon
                      fixedWidth={true}
                      icon={faDownloadRegular}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </Box>
        ) : null}
        <Box
          mt={2}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {selectedOverlapStatistics?.overlap === 0 ? (
            <OverlapNotAvailableAlert
              icon={
                <FontAwesomeIcon fixedWidth={true} icon={faCircleInfoSolid} />
              }
              severity="error"
            >
              Overlap match rate not available. This is either because the
              selected Advertiser audience is too small, or for privacy reasons
              it's not displayed.
            </OverlapNotAvailableAlert>
          ) : (
            <Fragment>
              <Typography sx={{ color: grey[700] }}>
                There are{" "}
                <span>
                  {formatter.format(selectedOverlapStatistics?.overlap)}
                </span>{" "}
                individuals in the overlap. This is a match rate of{" "}
                <span>
                  {isNaN(overlapPercentage) ??
                  selectedOverlapStatistics?.overlap ??
                  selectedOverlapStatistics?.total
                    ? "Calculating the "
                    : overlapPercentage}
                </span>
                %.
              </Typography>
              <Box sx={{ height: "48px", margin: "10px 0", width: "100%" }}>
                <ResponsiveBar
                  animate={true}
                  axisBottom={undefined}
                  axisLeft={undefined}
                  axisTop={undefined}
                  colors={(k) => {
                    if (k.indexValue === "total") {
                      return "#289090";
                    }
                    return grey[700];
                  }}
                  data={barData}
                  enableGridY={false}
                  enableLabel={false}
                  groupMode="grouped"
                  indexBy="key"
                  isInteractive={false}
                  layout="horizontal"
                  maxValue={selectedOverlapStatistics?.total}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography sx={{ color: "#289090" }}>
                  There are{" "}
                  <span>
                    {formatter.format(selectedOverlapStatistics?.total)}
                  </span>{" "}
                  customers in{" "}
                  <span>
                    {replaceDecentriqOrgName(
                      advertiserOrganization?.name,
                      "Advertiser"
                    )}
                    's
                  </span>{" "}
                  <span>
                    {selectedOverlapType !== entireOverlapKey
                      ? ` ${selectedOverlapType}`
                      : ""}
                  </span>{" "}
                  audience.
                </Typography>
              </Box>
            </Fragment>
          )}
        </Box>
      </Box>
    );
  });

AudienceInsightsStatistics.displayName = "AudienceInsightsStatistics";

export default AudienceInsightsStatistics;
