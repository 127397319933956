import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  useDraftPrivacyFilterQuery,
  usePublishedPrivacyFilterQuery,
} from "hooks/__generated-new";
import {
  type DraftSqlNode,
  type PublishedSqlNode,
} from "types/__generated-new";

const usePrivacyFilter = (computeNodeId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftPrivacyFilterQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The privacy filter settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedPrivacyFilterQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The privacy filter settings could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  return (
    (draftData?.draftNode as DraftSqlNode | undefined) ||
    (publishedData?.publishedNode as PublishedSqlNode | undefined)
  );
};

export default usePrivacyFilter;
