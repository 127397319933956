// Helper for parsing node hash and replacing it with its node name
import { type InMemoryCache } from "@apollo/client";
import {
  DraftNodesTypenamesDocument,
  type DraftNodesTypenamesQuery,
  PublishedNodesTypenamesDocument,
  type PublishedNodesTypenamesQuery,
} from "types/__generated-new";

const getNodesWithNameAndId = (
  cache: InMemoryCache,
  mutationArgs: any
): { id: string; name: string }[] | null => {
  const { dcrHash, computeNodeId } = mutationArgs.input;
  const publishedNodes = cache.readQuery<PublishedNodesTypenamesQuery>({
    query: PublishedNodesTypenamesDocument,
    variables: {
      dataRoomId: dcrHash,
    },
  });
  if (publishedNodes) {
    return publishedNodes.publishedDataRoom.publishedNodes.map(
      ({ id, name }) => ({ id, name })
    );
  }
  const ids = Object.keys(cache.extract())
    .filter((ref) => ref.startsWith("DraftDataRoom"))
    .map((ref) => ref.split(":")[1]);

  for (const id of ids) {
    const draftNodes = cache.readQuery<DraftNodesTypenamesQuery>({
      query: DraftNodesTypenamesDocument,
      variables: {
        dataRoomId: id,
      },
    });

    const nodes = draftNodes?.draftDataRoom.draftNodes.nodes;

    if (nodes?.some((node) => node.id === computeNodeId)) {
      return nodes.map(({ id, name }) => ({
        id,
        name,
      }));
    }
  }

  return null;
};

const parseErrorMessage = (
  cache: InMemoryCache,
  error: unknown,
  mutationArgs: any
): string | null => {
  // Get list of published nodes and then their ids
  const nodes = getNodesWithNameAndId(cache, mutationArgs);
  const nodeIds = nodes?.map(({ id }) => id);
  // Create string for regex that holds _leaf node ids
  const nodeIdsLeavesString = `${nodeIds?.join("_leaf|")}_leaf` || "";
  // Create string for regex that holds _verification node ids
  const nodeIdsVerificationString =
    `${nodeIds?.join("_verification|")}_verification` || "";
  // Create string for regex that holds _container node ids
  const nodeIdsContainerString =
    `${nodeIds?.join("_container|")}_container` || "";
  // Create string for regex that holds _match_node node ids
  const nodeIdsMatchNodeString =
    `${nodeIds?.join("_match_node|")}_match_node` || "";
  // Create string for regex that holds _validation_check node ids
  const nodeIdsValidationCheckNodeString =
    `${nodeIds?.join("_validation_check|")}_validation_check` || "";
  // Create string for regex that holds _validation node ids
  const nodeIdsValidationNodeString =
    `${nodeIds?.join("_validation|")}_validation` || "";
  // Create regex from strings
  const nodeIdsLeavesVerificationString = `${nodeIdsLeavesString}|${nodeIdsVerificationString}|${nodeIdsContainerString}|${nodeIdsMatchNodeString}|${nodeIdsValidationCheckNodeString}|${nodeIdsValidationNodeString}|${nodeIds?.join(
    "|"
  )}`;
  const nodeIdsLeavesVerificationRegex = new RegExp(
    nodeIdsLeavesVerificationString,
    "g"
  );
  // Create regex purely with ids
  const nodeIdsString = nodeIds?.join("|") || "";
  const nodeIdsRegex = new RegExp(nodeIdsString);
  // Generate error message
  const errorMessage = error?.message?.replaceAll(
    nodeIdsLeavesVerificationRegex,
    (nodeLeafId: string) => {
      const [nodeId] = nodeLeafId.match(nodeIdsRegex);
      const node = nodes?.find(({ id }) => id === nodeId);
      return node?.name || "";
    }
  );
  return errorMessage;
};

export default parseErrorMessage;
