import { useNodes } from "hooks";
import { type DataRoomSyntheticDataSourceOption } from "models";
import {
  type DraftMatchNode,
  type DraftSqlNode,
  type DraftTableLeafNode,
  type PublishedTableLeafNode,
} from "types/__generated-new";

const useSyntheticNodeDataSources = (): DataRoomSyntheticDataSourceOption[] => {
  const { nodes } = useNodes();
  const computeNodes = nodes.filter(
    ({ __typename }) =>
      __typename === "DraftSqlNode" ||
      __typename === "PublishedSqlNode" ||
      __typename === "DraftMatchNode" ||
      __typename === "PublishedMatchNode"
  );
  const dataNodes = nodes.filter(
    ({ __typename }) =>
      __typename === "DraftTableLeafNode" ||
      __typename === "PublishedTableLeafNode"
  );
  const dataSources = [
    ...dataNodes.map(
      (node) =>
        ({
          computeNodeId: node?.id,
          name: node?.name,
          namedColumns:
            node?.__typename === "PublishedTableLeafNode"
              ? (node as PublishedTableLeafNode)?.columns || []
              : (node as DraftTableLeafNode)?.columns?.nodes || [],
          type: "tabular",
          typename: node?.__typename,
        }) as DataRoomSyntheticDataSourceOption
    ),
    ...computeNodes.map(
      (node) =>
        ({
          computeNodeId: node?.id,
          name: node?.name,
          namedColumns: [],
          type: "computation",
          typename: node?.__typename,
          updatedAt:
            node?.__typename === "PublishedSqlNode" ||
            node?.__typename === "PublishedMatchNode"
              ? undefined
              : (node as DraftSqlNode | DraftMatchNode)?.updatedAt,
        }) as DataRoomSyntheticDataSourceOption
    ),
  ];
  return dataSources;
};

export default useSyntheticNodeDataSources;
