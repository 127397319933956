import { ApolloError } from "@apollo/client";
import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import * as forge from "node-forge";
import { type ApiCoreContextInterface } from "contexts";
import {
  type ComputeJob,
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  CreateComputeJobDocument,
  type CreateComputeJobMutation,
  DevComputeNodeJobDocument,
  type DevComputeNodeJobQuery,
  DraftComputeNodeTypenameDocument,
  type DraftComputeNodeTypenameQuery,
  type MutationTestDraftComputationArgs,
} from "types/__generated-new";
import { parseDataRoomComputationError } from "utils";
import {
  buildAndPublishDraftDataRoom,
  parseErrorMessage,
} from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeDraftTestComputationResolver =
  (
    client: ApiCoreContextInterface["client"],
    sessionManager: ApiCoreContextInterface["sessionManager"],
    store: ApiCoreContextInterface["store"]
  ) =>
  async (
    _obj: null,
    args: MutationTestDraftComputationArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob>> => {
    const {
      id: dataRoomId,
      computeNodeId,
      testDatasets: testDatasetsInput,
    } = args.input;
    const sdkSession = await sessionManager.get();
    const getNodeById = (computeNodeId: string) => {
      return context.cache.readQuery<DraftComputeNodeTypenameQuery>({
        query: DraftComputeNodeTypenameDocument,
        variables: {
          computeNodeId,
        },
      });
    };
    const computeNode = getNodeById(computeNodeId);
    try {
      const id = await buildAndPublishDraftDataRoom(
        dataRoomId,
        context.client,
        client,
        sdkSession,
        true
      );
      const dataScienceDataRoom =
        await sdkSession.retrieveDataScienceDataRoom(id);
      const wrapper = data_science.createDataScienceDataRoomWrapper(
        id,
        dataScienceDataRoom!,
        sdkSession
      );
      const testDatasets = new Map<string, TestDataset>();
      for (const dataset of testDatasetsInput || []) {
        testDatasets.set(dataset.leafNodeId, {
          key: store.pop(dataset.encryptionKey)!,
          manifestHash: dataset.manifestHash,
        });
      }
      const dataScienceJobId = await wrapper.createJob([computeNodeId], {
        dryRun: {
          testDatasets,
        },
      });
      const encodedDataScienceJobHandle = forge.util.binary.base64.encode(
        new TextEncoder().encode(JSON.stringify(dataScienceJobId))
      );
      const result = await context.client.mutate<CreateComputeJobMutation>({
        mutation: CreateComputeJobDocument,
        variables: {
          input: {
            computeNodeId: {
              draft: computeNodeId,
            },
            dataRoomHash: id,
            driverAttestationHash:
              sdkSession.metaData.driverAttestationSpecificationHash,
            enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
            enclaveComputeJobId: dataScienceJobId.jobId,
            purpose: ComputeJobPurpose.Test,
          },
        },
      });
      const computeJob = result.data?.computeJob.create.record;
      if (computeJob) {
        context.client.writeQuery<DevComputeNodeJobQuery>({
          data: {
            draftNode: {
              __typename: computeNode?.draftNode?.__typename,
              id: computeNode?.draftNode?.id!,
              job: {
                __typename: "ComputeJob",
                autoFetching: ComputeJobAutoFetching.TestResult,
                createdAt: computeJob.createdAt,
                dataRoomHash: computeJob.dataRoomHash,
                driverAttestationHash: computeJob.driverAttestationHash,
                enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
                enclaveComputeJobId: computeJob.enclaveComputeJobId,
                id: computeJob.id,
                purpose: ComputeJobPurpose.Test,
                status: computeJob.status,
              },
            },
          },
          query: DevComputeNodeJobDocument,
        });
        return computeJob;
      } else {
        throw new ApolloError({
          errorMessage: "Failed to create job",
          graphQLErrors: result.errors,
        });
      }
    } catch (error) {
      throw parseDataRoomComputationError(
        parseErrorMessage(context.cache, error, args),
        computeNode?.draftNode?.name
      );
    }
  };
