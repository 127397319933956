import { import_dataset } from "@decentriq/core";
import { type ApiCoreContextInterface } from "contexts";
import {
  type DatasetImport,
  type DatasetImportResult,
} from "types/__generated-new";
import { type LocalResolverContext } from "../../models";

export function makeDatasetImportResolvers(
  client: ApiCoreContextInterface["client"],
  _sessionManager: ApiCoreContextInterface["sessionManager"],
  _store: ApiCoreContextInterface["store"]
) {
  return {
    result: async (
      parent: Partial<DatasetImport>,
      _args: null,
      _context: LocalResolverContext,
      _info: any
    ): Promise<DatasetImportResult> => {
      if (parent.id === undefined) {
        throw new Error(
          "In order to query the result of a DatasetImport, its id needs to be available"
        );
      }

      const enclaveSpecifications = await client.getEnclaveSpecifications();
      const datasetImportId = parent.id;

      try {
        await import_dataset.getDatasetImportResult(datasetImportId, client, {
          additionalEnclaveSpecs: enclaveSpecifications,
        });
        return {
          error: null,
          success: true,
        };
      } catch (e) {
        return {
          error: e.message,
          success: false,
        };
      }
    },
  };
}
